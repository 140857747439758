import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  talentPoolCreateReqSchema,
  type TalentPoolCreateReq,
  type TalentPoolUpdateReq,
} from '@f4s/types';

import { getWorkspaceIdFromSlug } from '@/modules/workspace/queries';

import { talentProfileQuery } from '../profile/queries';
import {
  fetchPublicTalentPool,
  fetchWorkspaceTalentPool,
  getPoolIdFromSlug,
  publicPoolQuery,
  publicPoolsQuery,
  type TalentPoolDetails,
} from './queries';

// Create new talent pool
export const createTalentPoolAction = async ({ request, params }: ActionFunctionArgs) => {
  const body = await request.json();
  const data = talentPoolCreateReqSchema.parse(body);

  const { workspaceSlug } = z.object({ workspaceSlug: z.string() }).parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) throw new Error('Workspace ID is undefined');

  // TODO: zod parse data
  const talentPool = (await apiClient.post(
    `/api/v4/workspaces/${workspaceId}/talent/pools`,
    data,
  )) as TalentPoolDetails;

  await queryClient.invalidateQueries({
    queryKey: ['talent', 'pools', 'workspace', workspaceId, 'list'],
  });
  if (talentPool.isPublic) {
    await queryClient.invalidateQueries({
      queryKey: ['talent', 'pools', 'public', 'list'],
    });
  }

  // Redirect to newly created pool
  return redirect(`../${talentPool.id}`);
};

export function useCreateTalentPool() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof createTalentPoolAction>>>();
  return {
    ...fetcher,
    submit: (data: TalentPoolCreateReq) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
      }),
  };
}

// Update talent pool
export const updateTalentPoolAction = async ({ request, params }: ActionFunctionArgs) => {
  const { poolId, workspaceId } = await getPoolIdFromSlug(params);
  if (!workspaceId) throw new Error('Workspace ID is undefined');

  // DELETE
  if (request.method === 'DELETE') {
    const pool = (await apiClient.delete(
      `/api/v4/workspaces/${workspaceId}/talent/pools/${poolId}`,
    )) as TalentPoolDetails;
    await queryClient.invalidateQueries({
      queryKey: ['talent', 'pools', 'workspace', workspaceId, 'list'],
    });

    if (pool.isPublic) {
      await queryClient.invalidateQueries({
        queryKey: ['talent', 'pools', 'public', 'list'],
      });
    }

    return redirect('..');
  }

  const data = await request.json();
  // TODO: zod parse data
  const talentPool = (await apiClient.patch(
    `/api/v4/workspaces/${workspaceId}/talent/pools/${poolId}`,
    data,
  )) as TalentPoolDetails;
  // Update the cache with the returned pool.
  queryClient.setQueryData(
    ['talent', 'pools', 'workspace', workspaceId, poolId],
    talentPool,
  );

  // Handle public query cache
  if (talentPool.isPublic) {
    queryClient.setQueryData(['talent', 'pools', 'public', poolId], talentPool);
  } else {
    await queryClient.invalidateQueries({
      queryKey: ['talent', 'pools', 'public', poolId],
    });
  }
  // If isPublic this needs invalidating.
  // TODO: could be more granular in only updating with the diff and detecting a change here.
  await queryClient.invalidateQueries({
    queryKey: ['talent', 'pools', 'public', 'list'],
  });

  return talentPool;
};

export function useUpdateTalentPool() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateTalentPoolAction>>>();
  return {
    ...fetcher,
    submit: ({
      data = null,
      method = 'POST',
    }:
      | {
          data: TalentPoolUpdateReq;
          method?: 'POST';
        }
      | {
          data?: null;
          method: 'DELETE';
        }) =>
      fetcher.submit(data, {
        method,
        encType: 'application/json',
      }),
  };
}

export const joinTalentPoolAction = async ({ request, params }: ActionFunctionArgs) => {
  const { poolId, workspaceId } = await getPoolIdFromSlug(params);

  let pool: TalentPoolDetails | undefined;
  let profileIdToInvalidate: number | undefined;
  if (request.method === 'DELETE') {
    const talentPool = workspaceId
      ? await fetchWorkspaceTalentPool({ poolId, workspaceId })
      : await fetchPublicTalentPool({ poolId });

    // have to grab this before the deletion
    profileIdToInvalidate = talentPool?.users?.[0]?.profile.id;
    pool = await apiClient.delete(`/api/v4/talent/pools/${talentPool.id}/join`);
  } else {
    const { profileId } = z
      .object({ profileId: z.number().optional() })
      .parse(await request.json());
    // If profileId is undefined, the server will findFirst or throw.
    pool = await apiClient.post(`/api/v4/talent/pools/${poolId}/join`, { profileId });
    profileIdToInvalidate = pool?.users?.[0]?.profile.id;
  }

  if (profileIdToInvalidate) {
    await queryClient.invalidateQueries(
      talentProfileQuery({ profileId: profileIdToInvalidate }),
    );
  } else {
    // invalidate all just in case
    await queryClient.invalidateQueries({ queryKey: ['talent', 'profiles'] });
  }

  // Update individual cache
  queryClient.setQueryData(
    publicPoolQuery({ poolId: pool?.id ?? poolId }).queryKey,
    pool,
  );
  // Invalidate list cache
  await queryClient.invalidateQueries(publicPoolsQuery);

  return pool;
};
export function useJoinTalentPool() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof joinTalentPoolAction>>>();
  return {
    ...fetcher,
    submit: ({
      data = {},
      method = 'POST',
    }:
      | {
          data: { profileId?: number };
          method?: 'POST';
        }
      | {
          data?: null;
          method: 'DELETE';
        }) =>
      fetcher.submit(data, {
        method,
        encType: 'application/json',
        action: './join',
      }),
  };
}
