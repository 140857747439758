import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import type { MinimalUser } from '../user/schema';

export type InvitationType =
  | 'organization'
  | 'organization_admin'
  | 'organization_consultant'
  | 'organization_support'
  // The following types are deprecated
  | 'connection'
  | 'group'
  | 'org_and_group'
  | 'request_org';

type InvitationStatus = 'accepted' | 'pending' | 'declined';

export type Invitation = {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: InvitationType;
  status: InvitationStatus;
  organizations: {
    id: number;
    logo: string;
    name: string;
    permission: { autoApproveJoinRequest: boolean; memberViewEachOther: boolean };
  } | null;
  members: {
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
    initials?: string;
  }[];
  fromUser: MinimalUser | null;
  toUser: MinimalUser | null;
  sentToEmailAddress: string;
  previewMembers: { firstName: string; avatarUrl: string }[];
  memberCount: number | null;
};
export type InvitationWithOrg = Omit<Invitation, 'organizations'> & {
  organizations: {
    id: number;
    logo: string;
    name: string;
    permission: { autoApproveJoinRequest: boolean; memberViewEachOther: boolean };
  };
};

const mapInvite = (invite: Invitation) => ({
  ...invite,
  fromUser: invite.fromUser
    ? {
        ...invite.fromUser,
        fullName: formatName(invite.fromUser),
        initials: formatInitials(invite.fromUser),
      }
    : null,
  toUser: invite.toUser
    ? {
        ...invite.toUser,
        fullName: formatName(invite.toUser),
        initials: formatInitials(invite.toUser),
      }
    : null,
});

// Received invites

export const invitesReceivedQuery = {
  queryKey: ['invites', 'received', 'list'],
  queryFn: async () => {
    return apiClient.get(`/api/v4/invitations/received`) as Promise<Invitation[]>;
  },
};

export const fetchInvitesReceived = () => queryClient.fetchQuery(invitesReceivedQuery);
export const useInvitesReceived = () => useQuery(invitesReceivedQuery);

export const inviteReceivedQuery = (inviteId: number) => ({
  queryKey: ['invites', 'received', inviteId],
  queryFn: async () => {
    return apiClient.get(
      `/api/v4/invitations/received/${inviteId}`,
    ) as Promise<Invitation>;
  },
});

export const fetchInviteReceived = (inviteId: number) =>
  queryClient.fetchQuery(inviteReceivedQuery(inviteId));
export const useInviteReceived = (inviteId: number) =>
  useQuery(inviteReceivedQuery(inviteId));

// Sent invites

export const invitesSentQuery = (params?: { workspaceId?: number }) => ({
  queryKey: ['invites', 'sent', `${params?.workspaceId ?? 'me'}`, 'list'],
  queryFn: async () => {
    const invites = (await apiClient.get(
      params?.workspaceId
        ? `/api/v4/workspaces/${params.workspaceId}/invites`
        : '/api/v4/invitations/sent',
    )) as Invitation[];

    return invites.map(mapInvite);
  },
});

export const fetchInvitesSent = (params?: { workspaceId?: number }) =>
  queryClient.fetchQuery(invitesSentQuery(params));
export const useInvitesSent = (params?: { workspaceId?: number }) =>
  useQuery(invitesSentQuery(params));

export const inviteSentQuery = ({
  inviteId,
  workspaceId,
}: {
  inviteId: number;
  workspaceId?: number;
}) => ({
  queryKey: ['invites', 'sent', `${workspaceId ?? 'me'}`, inviteId],
  queryFn: async () => {
    const invite = (await apiClient.get(
      workspaceId
        ? `/api/v4/workspaces/${workspaceId}/invites/${inviteId}`
        : `/api/v4/invitations/sent/${inviteId}`,
    )) as Invitation;

    return mapInvite(invite);
  },
});

export const fetchInviteSent = (params: { inviteId: number; workspaceId?: number }) =>
  queryClient.fetchQuery(inviteSentQuery(params));
export const useInviteSent = (params: { inviteId: number; workspaceId?: number }) =>
  useQuery(inviteSentQuery(params));

// LocalStorage token handling
const INVITE_TOKEN_KEY = 'inviteToken';
export const storeInviteToken = (token: string) => {
  localStorage.setItem(INVITE_TOKEN_KEY, token);
  return token;
};
export const getInviteToken = () => {
  return localStorage.getItem(INVITE_TOKEN_KEY);
};
export const clearInviteToken = () => localStorage.removeItem(INVITE_TOKEN_KEY);

const MAGIC_TOKEN_KEY = 'magicToken';
export const storeMagicToken = (token: string) => {
  localStorage.setItem(MAGIC_TOKEN_KEY, token);
  return token;
};
export const getMagicToken = () => {
  return localStorage.getItem(MAGIC_TOKEN_KEY);
};

export const clearMagicToken = () => localStorage.removeItem(MAGIC_TOKEN_KEY);

const JOIN_REQUEST_KEY = 'joinRequestSpaceId';
export const storeJoinRequestSpaceId = (spaceId: number) => {
  localStorage.setItem(JOIN_REQUEST_KEY, String(spaceId));
  return spaceId;
};
export const getJoinRequestSpaceId = () => {
  return z.coerce.number().nullable().parse(localStorage.getItem(JOIN_REQUEST_KEY));
};

export const clearJoinRequestSpaceId = () => localStorage.removeItem(JOIN_REQUEST_KEY);
