import { useCallback, useEffect, useMemo, useState, type ClipboardEvent } from 'react';

import { useMaybeUser } from '@f4s/api-client';
import {
  Badge,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Icon,
  InputMultiselect,
  useDebounce,
} from '@f4s/ui';

import { usePersonalConnections } from '@/modules/_legacy/connections/queries';
import { useLegacyGroups } from '@/modules/_legacy/groups/queries';
import { useSearchUserV4Results } from '@/modules/user/queries';

import { ProfileAvatar } from './avatar';

const POPULAR_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'icloud.com',
];

// const RFC_5322_REGEX = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:\\[\x00-\x7F]|[^\x00-\x7F"])*")@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/;
const EMAIL_REGEX = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;

const SEPARATOR_REGEX = /\s*,\s*|\s+/;

type ValueUser = {
  type: 'user' | 'connection';
  userId: number;
  avatarUrl?: string | null;
  emailAddress?: string | null;
  isConnected?: boolean;
};
type ValueGroup = {
  type: 'group';
  groupId: number;
  avatarUrl?: string | null;
  members: { label: string; key: string; value: ValueUser }[];
};
type ValueEmail = { type: 'email'; emailAddress: string; avatarUrl?: string | null };
export type Value = ValueUser | ValueEmail | ValueGroup;
export type Option = { label: string; key: string; value: Value };
type UserOption = { label: string; key: string; value: ValueUser };

export const InviteMultiselect = ({
  selections: externalSelections,
  onSelectionChange,
}: {
  selections?: Option[];
  onSelectionChange?: (selections: Option[]) => void;
}) => {
  const { user } = useMaybeUser();

  // Personal connections
  const { data: connections } = usePersonalConnections();
  const connectionSuggestions = useMemo(
    () =>
      connections?.map((c) => ({
        label: c.fullName,
        key: `${c.fullName} ${c.id}`,
        value: {
          type: 'connection',
          userId: c.id,
          avatarUrl: c.avatarUrl,
          emailAddress: c.emailAddress,
        } satisfies ValueUser,
      })) ?? [],
    [connections],
  );

  // Legacy Groups
  const { data: groups } = useLegacyGroups();
  const groupSuggestions: Option[] = useMemo(
    () =>
      groups?.map((g) => ({
        label: g.name,
        key: `${g.name} ${g.id}`,
        value: {
          type: 'group',
          groupId: g.id,
          members: g.members.flatMap((m) =>
            m.isMe
              ? []
              : {
                  label: m.user.fullName,
                  key: `${m.user.fullName} ${m.userId}`,
                  value: { type: 'user', userId: m.user.id, avatarUrl: m.user.avatarUrl },
                },
          ),
        },
      })) ?? [],
    [groups],
  );
  const staticSuggestions = useMemo(
    () => [...connectionSuggestions, ...groupSuggestions],
    [connectionSuggestions, groupSuggestions],
  );

  const [selections, setSelections] = useState<Option[]>(externalSelections ?? []);
  const [value, setValue] = useState<string>('');
  const [debouncedQuery] = useDebounce(value, 100);

  useEffect(() => {
    setSelections(externalSelections ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(externalSelections)]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selections);
    }
  }, [selections, onSelectionChange]);

  const handleSelectionAdd = useCallback((selection: Option) => {
    setSelections((prev) => {
      return selection.value.type === 'group'
        ? [
            ...prev,
            // Wrap in any group members who arent already in the active selection
            ...selection.value.members.filter((m) => !prev.some((p) => p.key === m.key)),
          ]
        : !prev.some((p) => p.key === selection.key)
          ? [...prev, selection]
          : prev;
    });
  }, []);

  const handleSelectionRemove = useCallback((selection: Option) => {
    setSelections((prev) => prev.filter((p) => p.key !== selection.key));
  }, []);

  const handleValueChange = useCallback((val: string) => {
    setValue(val);
  }, []);

  const [searchSuggestions, setSearchSuggestions] = useState<UserOption[]>([]);
  const { data: searchResults } = useSearchUserV4Results({
    query: debouncedQuery,
  });
  useEffect(() => {
    // Prevent render flashing during loading
    if (searchResults !== undefined) {
      setSearchSuggestions(searchResults);
    }
  }, [searchResults]);

  const emailSuggestions: Option[] | null = useMemo(() => {
    if (value.includes('@') && value.split('@')[0]?.trim()) {
      if (EMAIL_REGEX.test(value)) {
        // The email is valid, suggest it
        return [
          { label: value, key: value, value: { type: 'email', emailAddress: value } },
        ];
      } else {
        const userDomain = user?.emailAddress.split('@')[1] ?? 'gmail.com';
        const suggestedDomains = [
          userDomain,
          ...POPULAR_DOMAINS.filter((d) => d != userDomain),
        ];
        const prefix = value.split('@')[0];

        return suggestedDomains.map((d) => {
          const emailAddress = `${prefix}@${d}`;
          return {
            label: emailAddress,
            key: emailAddress,
            value: { type: 'email', emailAddress },
          };
        });
      }
    } else {
      return [];
    }
  }, [user?.emailAddress, value]);

  const dynamicSuggestions = useMemo(() => {
    // Filter any static connectionSuggestions out of searchSuggestions
    const dedupedSearchSuggestions =
      searchSuggestions?.filter(
        (s) => !connectionSuggestions.some((cs) => cs.value.userId === s.value.userId),
      ) ?? [];
    return [...emailSuggestions, ...dedupedSearchSuggestions];
  }, [connectionSuggestions, emailSuggestions, searchSuggestions]);

  const badgeRenderer = useCallback(
    (selectionsToRender: Option[]) => {
      return selectionsToRender.map((selection) => (
        <Badge
          key={selection.key}
          variant="outline"
          size="sm"
          className="h-7 gap-2 !pl-1 !pr-2"
        >
          {selection.value.avatarUrl ? (
            <ProfileAvatar
              name={selection.label}
              avatarUrl={selection.value.avatarUrl}
              size="xxs"
              className="h-5 max-h-5 w-5 max-w-5 text-xs"
            />
          ) : selection.value.type === 'user' ? (
            <Icon.User size={16} className="mx-0.5" />
          ) : selection.value.type === 'email' ? (
            <Icon.Envelope size={16} />
          ) : null}
          {selection.label}
          <button
            className="ring-offset-background focus:ring-ring flex h-4 w-4 items-center justify-center rounded-full outline-none focus:ring-2 focus:ring-offset-2"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSelectionRemove(selection);
              }
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              handleSelectionRemove(selection);
              e.stopPropagation();
            }}
          >
            <Icon.X className="text-muted-foreground hover:text-foreground h-3 w-3" />
          </button>
        </Badge>
      ));
    },
    [handleSelectionRemove],
  );

  const suggestionRenderer = useCallback(
    ({
      suggestions,
      handleSelection,
    }: {
      suggestions: Option[];
      handleSelection: (key: string) => void;
    }) => {
      const typeMap = new Map<Value['type'], Option[]>();
      for (const suggestion of suggestions) {
        const typedSuggestions = typeMap.get(suggestion.value.type);
        if (typedSuggestions) {
          typedSuggestions.push(suggestion);
        } else {
          typeMap.set(suggestion.value.type, [suggestion]);
        }
      }

      return (
        <>
          {debouncedQuery.length >= 3 && (
            <CommandEmpty>No results found. Invite via email</CommandEmpty>
          )}
          {[...typeMap.entries()].map(([type, typedSuggestions]) => (
            <CommandGroup
              key={type}
              heading={
                type === 'connection'
                  ? 'Connections'
                  : type === 'user'
                    ? 'People'
                    : type === 'group'
                      ? 'Groups'
                      : 'Invite by email'
              }
            >
              {typedSuggestions.map((suggestion) => (
                <CommandItem
                  key={suggestion.key}
                  value={suggestion.key}
                  onSelect={handleSelection}
                  className="gap-2 hover:cursor-pointer"
                >
                  {suggestion.value.type === 'connection' ||
                  suggestion.value.type === 'user' ||
                  suggestion.value.avatarUrl ? (
                    <ProfileAvatar
                      name={suggestion.label}
                      avatarUrl={suggestion.value.avatarUrl}
                      size="xs"
                    />
                  ) : type === 'group' ? (
                    <Icon.UsersThree size={18} weight="duotone" />
                  ) : type === 'email' ? (
                    <Icon.Envelope size={18} />
                  ) : null}
                  {suggestion.label}
                </CommandItem>
              ))}
            </CommandGroup>
          ))}
        </>
      );
    },
    [],
  );

  const handleInputPaste = useCallback(
    (event: ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      // We are expecting a comma separated list of emails, or names to map to existing connections;
      const data = event.clipboardData.getData('text');
      const values = data.split(SEPARATOR_REGEX).map((val) => val.trim());
      const pastedSelections: Option[] = [];
      for (const val of values) {
        // Does it look like an email address?
        if (val.includes('@')) {
          if (EMAIL_REGEX.test(val)) {
            // Value is a valid email
            const foundSuggestion = connectionSuggestions.find(
              (s) => s.value.emailAddress?.toLowerCase() === val.toLowerCase(),
            );
            if (foundSuggestion) {
              pastedSelections.push(foundSuggestion);
            } else {
              pastedSelections.push({
                label: val,
                key: val,
                value: { type: 'email', emailAddress: val },
              });
            }
          }
        } else {
          // Else is it a name that may exist in our suggestions?
          const foundSuggestion = connectionSuggestions.find(
            (s) => s.label.toLowerCase() === val.toLowerCase(),
          );
          if (foundSuggestion) {
            pastedSelections.push(foundSuggestion);
          }
        }
      }
      if (pastedSelections.length > 0) {
        setSelections((prev) => {
          return [
            ...prev,
            ...pastedSelections.filter((s) => !prev.some((p) => p.key === s.key)),
          ];
        });
      }
    },
    [connectionSuggestions],
  );

  return (
    <InputMultiselect
      selections={selections}
      suggestions={staticSuggestions}
      extraSuggestions={dynamicSuggestions}
      onSelectionAdd={handleSelectionAdd}
      onSelectionRemove={handleSelectionRemove}
      value={debouncedQuery}
      onValueChange={handleValueChange}
      badgeRenderer={badgeRenderer}
      suggestionRenderer={suggestionRenderer}
      onInputPaste={handleInputPaste}
      placeholder="Search by name or email..."
    />
  );
};
