import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { ToastAction, useToast } from '@f4s/ui';

const UPDATE_CHECK_INTERVAL = 10 * 60 * 1000; // 10 minutes

const forceReloadEventHandler = (event: MessageEvent) => {
  if (event.data && event.data.type === 'FORCE_RELOAD') {
    window.location.reload();
  }
};

export const usePWAUpdatePrompt = () => {
  const { toast } = useToast();

  const {
    needRefresh: [hasUpdateReady],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_url, registration) {
      setInterval(() => {
        // Check for new version
        registration?.update();
      }, UPDATE_CHECK_INTERVAL);
    },
  });

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', forceReloadEventHandler);
      return () =>
        navigator.serviceWorker.removeEventListener('message', forceReloadEventHandler);
    }
  }, []);

  useEffect(() => {
    const handleUpdate = async () => {
      await updateServiceWorker(false);

      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'USER_CONFIRMED_UPDATE' });
      }
    };

    if (hasUpdateReady) {
      toast({
        variant: 'default',
        title: 'New version available',
        description:
          "A new version of the app has been installed. Click 'Update now' to refresh.",
        action: (
          <ToastAction altText="Update now" onClick={handleUpdate}>
            Update now
          </ToastAction>
        ),
      });
    }
  }, [hasUpdateReady, toast, updateServiceWorker]);
};
