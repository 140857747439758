import { type RouteObject } from 'react-router-dom';

import { dismissNotificationAction } from './actions';
import { notificationsLoader } from './loaders';

export const notificationRoutes: RouteObject = {
  path: 'notifications',
  handle: { crumb: { title: 'Notifications' } },
  loader: notificationsLoader,
  async lazy() {
    const { NotificationsPage } = await import('./pages/page');
    return { Component: NotificationsPage };
  },
  children: [
    {
      path: ':notificationId',
      children: [
        {
          path: 'dismiss',
          action: dismissNotificationAction,
        },
      ],
    },
  ],
};
