import { redirect, useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { MagicLinkType, type Organization } from '@f4s/types';
import { toast } from '@f4s/ui';

import { sendEvent } from '@/lib/analytics';
import {
  fetchPersonalConnections,
  personalConnectionsQuery,
} from '@/modules/_legacy/connections/queries';

import { allSentInvitesQuery, fetchMagicLink, magicLinkQuery } from '../invite/queries';
import { invitesSentQuery } from '../invites/queries';
import { membersQuery } from '../member/queries';
import { getOnboardingProgressQuery } from '../onboarding/queries';
import { mapTeam, teamListQuery, type TeamDetail } from '../team/queries';
import {
  clearNewWorkspaceUsers,
  fetchWorkspace,
  fetchWorkspaces,
  getWorkspaceFromParams,
  workspaceInvitePendingConnectedQuery,
  workspaceQuery,
  workspacesQuery,
  type Workspace,
} from './queries';

export type UpdatePermissionRequest = {
  permission: string;
  value: boolean;
};

export type UserIdEmail =
  | { userId: number; emailAddress?: string }
  | { userId?: number; emailAddress: string };

export type WorkspaceCreation = {
  name: string;
  file: File;
  members: UserIdEmail[];
};

export async function updateSettingsAction({ request, params }: ActionFunctionArgs) {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return;

  const { permissions = {}, isDiscoverable } = z
    .object({ isDiscoverable: z.boolean(), permissions: z.record(z.boolean()) })
    .partial()
    .parse(await request.json());

  const { magicLink, ...otherPermissions } = permissions;
  if (magicLink !== undefined) {
    if (magicLink) {
      await apiClient.post('/api/v3/magic-links', {
        type: MagicLinkType.organization,
        id: workspace.id,
      });
    } else {
      const link = await fetchMagicLink({
        type: MagicLinkType.organization,
        id: workspace.id,
        enabled: true,
      });
      if (link) await apiClient.post(`/api/v3/magic-links/${link.token}/disable`, {});
    }
    await queryClient.invalidateQueries({
      queryKey: magicLinkQuery({
        type: MagicLinkType.organization,
        id: workspace.id,
        enabled: magicLink,
      }).queryKey,
    });
  }

  if (isDiscoverable !== undefined || Object.keys(otherPermissions).length > 0) {
    await apiClient.patch(`/api/v4/workspaces/${workspace.id}`, {
      permission: otherPermissions,
      isDiscoverable,
    });
    await queryClient.invalidateQueries(workspaceQuery(workspace.slug));
  }

  return true;
}

export async function createWorkspace(formData: FormData): Promise<
  | { error: string }
  | {
      error: null;
      workspace: Workspace;
    }
> {
  const name = formData.get('name');
  const companyName = formData.get('companyName') ?? undefined;
  const members = formData.get('members');
  const file = formData.get('file');
  const allowedDomain = formData.get('allowedDomain') ?? undefined;

  const isDiscoverableString = formData.get('isDiscoverable');
  const isDiscoverable =
    isDiscoverableString === 'false' ? false : !!isDiscoverableString;

  if (!name || typeof name !== 'string') {
    return { error: 'Please enter a name for your space' };
  }

  let membersArray: UserIdEmail[] = [];
  if (typeof members === 'string') {
    try {
      const membersJson: unknown = JSON.parse(members);
      // TODO: The following zod parse is failing, fix the schema + type
      // membersArray = OrgCreationSearchUserSchema.array().parse(membersJson);
      membersArray = membersJson as UserIdEmail[];
    } catch {
      return { error: 'Error parsing workspace members' };
    }
  }

  // Create the organization
  let organization = (await apiClient.post('/api/v4/workspaces', {
    name,
    members: membersArray,
    billingEmail: undefined,
    type: 'space',
    allowedDomain,
    companyName,
    isDiscoverable,
  })) as Organization;

  // Handle image uploading
  if (file instanceof File && file.name) {
    const imageFormData = new FormData();
    imageFormData.append('file', file);

    try {
      const updatedOrganization = (await apiClient.post(
        `/api/v4/workspaces/${organization.id}/avatar`,
        imageFormData,
        { isFormData: true },
      )) as Organization;
      organization = updatedOrganization;
    } catch (error) {
      console.error('Error uploading image for workspace logo', error);
      toast({ title: 'Could not upload image for workspace' });
    }
  }

  await setAsMigrated(membersArray);

  // Clear the 'new users' query state
  clearNewWorkspaceUsers();

  await queryClient.invalidateQueries(workspacesQuery);

  const workspaces = await fetchWorkspaces();
  const newSpace = workspaces.find((w) => w.id === organization.id);

  if (!newSpace) {
    return { error: 'Could not find space' };
  }

  sendEvent('trigger_workspace_created', {});

  return {
    error: null,
    workspace: newSpace,
  };
}

export async function createDemoWorkspaceAction() {
  let organization: Organization | undefined;
  try {
    organization = (await apiClient.post('/api/v4/workspaces/demo', {})) as Organization;
    await queryClient.invalidateQueries({ queryKey: ['workspaces'] });
  } catch (error) {
    console.error('Error creating demo organization', error);
  }

  const workspaces = await fetchWorkspaces();

  // Find the newly created space
  let demoSpace: Workspace | undefined;
  if (organization) {
    demoSpace = workspaces.find((w) => w.id === organization.id);
  }

  // Fall back to checking for an existing Demo Space
  if (!demoSpace) {
    demoSpace = workspaces.find((w) => w.slug === 'demo-space');
  }

  return redirect(demoSpace ? `/spaces/${demoSpace.slug}` : '/spaces');
}

export async function createWorkspaceAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const shouldRedirect = formData.get('shouldRedirect') === 'true';
  formData.delete('shouldRedirect');
  const redirectTo = formData.get('redirectTo');
  formData.delete('redirectTo');

  const response = await createWorkspace(formData);
  if (response.error !== null) return response;

  return shouldRedirect
    ? redirect(redirectTo ? `${redirectTo}` : `/spaces/${response.workspace.slug}`)
    : response;
}

export type WorkspaceCreateActionData = Exclude<
  Awaited<ReturnType<typeof createWorkspaceAction>>,
  Promise<Response> | Response
>;

export async function updateWorkspaceAction({ request, params }: ActionFunctionArgs) {
  const { workspaceSlug } = params as { workspaceSlug: string };
  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) throw new Error('Workspace does not exist');

  let updatedOrg: Organization | undefined;
  const formData = await request.formData();

  const data: { name?: string; companyName?: string } = {};
  const name = formData.get('name');
  if (name && typeof name === 'string') {
    data.name = name;
  }

  const companyName = formData.get('companyName');
  if (companyName && typeof companyName === 'string') {
    data.companyName = companyName;
  }

  if (Object.keys(data).length > 0)
    updatedOrg = (await apiClient.patch(
      `/api/v4/workspaces/${workspace.id}`,
      data,
    )) as Organization;

  // Handle image uploading
  const file = formData.get('file');
  if (file instanceof File && file.name) {
    const imageFormData = new FormData();
    imageFormData.append('file', file);

    try {
      updatedOrg = (await apiClient.post(
        `/api/v4/workspaces/${workspace.id}/avatar`,
        imageFormData,
        { isFormData: true },
      )) as Organization;
    } catch (error) {
      console.error('Error uploading image for workspace logo', error);
      toast({ title: 'Could not upload image for workspace' });
    }
  }

  if (updatedOrg) {
    await queryClient.invalidateQueries(workspacesQuery);
    const workspaces = await fetchWorkspaces();
    const updatedSpace = workspaces.find((w) => w.id === updatedOrg.id);

    if (updatedSpace) {
      // Invalidate both old and new slug
      await queryClient.invalidateQueries(workspaceQuery(workspace.slug));
      if (updatedSpace.slug !== workspace.slug) {
        await queryClient.invalidateQueries(workspaceQuery(updatedSpace.slug));
      }

      return redirect(`/spaces/${updatedSpace.slug}/settings/details`);
    }
    // We should not get to here, but just in case
    return redirect('/spaces');
  }

  return null;
}

export async function inviteWorkspaceMember({ request, params }: ActionFunctionArgs) {
  const { workspaceSlug } = params as { workspaceSlug: string };
  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) throw new Error('Workspace does not exist');

  const { data, shouldRedirect } = (await request.json()) as {
    data: UserIdEmail[];
    shouldRedirect?: boolean;
  };

  const invites = (await apiClient.post(
    `/api/v4/workspaces/${workspace.id}/members`,
    data,
  )) as { sendToEmailAddress: string | null; sentToUserId: number | null }[];

  await setAsMigrated(data);
  // Clear the 'new users' query state
  clearNewWorkspaceUsers();

  // Invalidate caches, copied from old invite action
  // TODO: Refine this
  await queryClient.invalidateQueries(getOnboardingProgressQuery(workspace.id));
  await queryClient.invalidateQueries(allSentInvitesQuery);
  // Clear sent invitations caches
  await queryClient.invalidateQueries(invitesSentQuery({ workspaceId: workspace.id }));
  await queryClient.invalidateQueries({ queryKey: ['invitations', 'sent'] });

  // Handle pending membership invalidation for workspace admins
  // TODO: refine this cache clearing for workspace admins only
  // Invalidate workspace membership
  await queryClient.invalidateQueries(workspaceInvitePendingConnectedQuery(workspace.id));
  await queryClient.invalidateQueries(workspaceQuery(workspaceSlug));
  await queryClient.invalidateQueries(membersQuery({ workspaceSlug }));

  return shouldRedirect ? redirect(`/spaces/${workspaceSlug}/people/members`) : invites;
}
export type WorkspaceInviteMemberActionData = Exclude<
  Awaited<ReturnType<typeof inviteWorkspaceMember>>,
  Promise<Response> | Response
>;

export const useInviteWorkspaceMember = () => {
  const submit = useSubmit();
  return (
    data: { data: UserIdEmail[]; shouldRedirect?: boolean },
    action: string = '.',
  ) =>
    submit(data, {
      action,
      method: 'POST',
      encType: 'application/json',
      navigate: false,
    });
};

const setAsMigrated = async (invited: UserIdEmail[]) => {
  try {
    const personalConnections = await fetchPersonalConnections();
    // userIds to migrate
    const userIds = personalConnections.flatMap((c) =>
      !c.migratedToSpace && invited.some((i) => i.userId === c.userId) ? c.userId : [],
    );
    await apiClient.patch('/api/v4/users/me/connections', {
      userIds,
      migratedToSpace: true,
    });
    // Invalidate legacy connections cache to update progress
    await queryClient.invalidateQueries(workspacesQuery);
    await queryClient.invalidateQueries(personalConnectionsQuery);
  } catch (error) {
    console.error(
      'An error occured while trying to update personal connection migration state',
      error,
    );
  }
};

export const workspaceFavoriteAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { workspaceSlug } = z.object({ workspaceSlug: z.string() }).parse(params);
  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) throw new Error('Workspace does not exist');
  await (request.method === 'DELETE'
    ? apiClient.delete(`/api/v4/workspaces/${workspace.id}/favorite`)
    : apiClient.post(`/api/v4/workspaces/${workspace.id}/favorite`, {}));
  // Invalidate workspace list cache
  queryClient.invalidateQueries({ queryKey: ['workspaces'] });
  return null;
};

export const useWorkspaceFavorite = () => {
  const submit = useSubmit();
  return ({
    workspaceSlug,
    method,
  }: {
    workspaceSlug: string;
    method: 'POST' | 'DELETE';
  }) =>
    submit(
      {},
      {
        action: `/spaces/${workspaceSlug}/favorite`,
        method,
        encType: 'application/json',
        navigate: false,
      },
    );
};

export const workspaceDemoInviteAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw new Error('Workspace does not exist');

  if (request.method === 'POST') {
    const res = (await apiClient.post(`/api/v4/workspaces/${workspace.id}/demo`, {})) as {
      teams: TeamDetail[];
      memberIds: number[];
    };
    // Invalidate membership and teams
    await queryClient.invalidateQueries(workspaceQuery(workspace.slug));
    await queryClient.invalidateQueries(membersQuery({ workspaceSlug: workspace.slug }));
    await queryClient.invalidateQueries(teamListQuery({ workspaceId: workspace.id }));
    return { teams: res.teams.map(mapTeam), memberIds: res.memberIds };
  }
  return null;
};
export type WorkspaceDemoInviteActionData = Exclude<
  Awaited<ReturnType<typeof workspaceDemoInviteAction>>,
  Promise<Response> | Response
>;
