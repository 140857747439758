import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { getWorkspaceFromParams } from '../workspace/queries';
import { fetchAccessList } from './queries';

export const accessListLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');

  const accessList = await fetchAccessList(workspace.id);
  return { workspace, accessList };
};

export const accessLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  const { accessId } = z.object({ accessId: z.coerce.number() }).parse(params);
  const accessList = await fetchAccessList(workspace.id);
  const access = accessList.find((a) => a.id === accessId);
  if (!access) return redirect('..');

  return { workspace, access };
};
