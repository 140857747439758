import { useFetcher } from 'react-router-dom';

import { useMaybeUser } from '@f4s/api-client';
import { languageCodeSchema } from '@f4s/types';
import { cn, Combobox, Icon } from '@f4s/ui';

import { setLocale, useLocale } from '@/providers/i18n';

const localeOptions = [
  { value: 'en-us', label: 'English' },
  { value: 'fr-fr', label: 'French' },
  ...(import.meta.env.MODE !== 'production'
    ? [{ value: 'en-psaccent', label: 'Pseudo Accent' }]
    : []),
] as const;

export const LocaleSelector = ({
  portalContainer,
  className,
  align = 'start',
  iconOnly = false,
}: {
  portalContainer?: HTMLElement | null;
  className?: string;
  align?: 'start' | 'end' | 'center';
  iconOnly?: boolean;
}) => {
  const { user } = useMaybeUser();
  const localeQuery = useLocale();
  const fetcher = useFetcher<string | boolean>();
  // const label = localeOptions.find((l) => l.value === localeQuery.data)?.label;

  // Temporarily remove local selector
  return null;

  // TODO: Find way to prevent combobox from unselecting a locale
  // Comment: Combobox is a rather nasty and overly complicated component at the moment
  return (
    <Combobox
      portalContainer={portalContainer}
      selections={localeOptions}
      value={localeQuery.data}
      variant="outline"
      align={align}
      onValueChange={(value) => {
        const result = languageCodeSchema.safeParse(value);
        if (result.success) {
          setLocale(result.data);

          if (user) {
            // If the user is logged in, update the database
            fetcher.submit(
              { languageCode: result.data },
              {
                action: '/user/language',
                method: 'PUT',
                encType: 'application/json',
              },
            );
          } else {
            // Otherwise, we still need to force a refresh of routes
            // Calling an action causes loaders to reload = components re-render
            fetcher.submit(true, {
              action: '/refresh',
              method: 'POST',
              encType: 'application/json',
            });
          }
        }
      }}
      className={cn('', className)}
    >
      {iconOnly && <Icon.Languages size={18} />}
    </Combobox>
  );
};
