import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { type Motivation, type MotivationDetails } from '@f4s/types';
import { motivationDefinitions, type MotivationInfo } from '@f4s/ui';
import type { DistributionData } from '@f4s/widgets';

import { fetchLocalPreference } from '@/providers/local-preference';

import { normalizeDistribution } from '../dashboard/utils';
import { fetchTeam } from '../team/queries';
import { fetchWorkspace, getWorkspaceIdFromSlug } from '../workspace/queries';

/* -------------------------------------------------------------------------- */
/*                             New Motivation APIs                            */
/* -------------------------------------------------------------------------- */

export type MotivationResUserData = {
  userId: number;
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl?: string | null;
    gender?: 'm' | 'f' | 't' | null;
  };
  motivations: MotivationRes[];
};

export type MotivationUserData = Omit<MotivationResUserData, 'motivations'> & {
  motivations: MotivationData[];
};

export type MotivationData = MotivationInfo & {
  id: number;
  sortIndex: number;
  sampleSize: number;
  score: number;
  median: number;
  meaning: string;
  examples?: string | null;

  quartiles?: [number, number, number];
  distribution?: [score: number, count: number][];
  relativeDistribution?: [percentile: number, count: number][];
  ks?: number;
};

export const mapMotivations = (motivations: MotivationRes[]): MotivationData[] => {
  return motivations
    .flatMap((d) => {
      const motivationIndex = motivationDefinitions.findIndex(
        (m) => m.code === d.pattern,
      );
      const motivation = motivationDefinitions[motivationIndex];
      if (!motivation) return [];

      return {
        ...motivation,
        sortIndex: motivationIndex,
        id: d.id,
        sampleSize: d.size ?? 1,
        score: d.score,
        median: d.percentile,
        quartiles: d.relativeQuartiles ?? [d.percentile, d.percentile, d.percentile],
        ks: d.ks,
        relativeDistribution: d.relativeDistribution,
        distribution: d.distribution,
        meaning: d.meaning,
        examples: d.examples,
      };
    })
    .sort((a, b) => a.sortIndex - b.sortIndex);
};

// Return definition information about motivations.
const motivationDetailsQuery = {
  queryKey: ['motivations'],
  queryFn: async () => {
    return (await apiClient.get(`/api/v3/modeling/motivations`)) as MotivationDetails;
  },
};
export const fetchMotivationDetails = () =>
  queryClient.fetchQuery(motivationDetailsQuery);
export const useMotivationDetails = () => useQuery(motivationDetailsQuery);

const motivationGroupDetailsQuery = {
  queryKey: ['motivations', 'group'],
  queryFn: async () => {
    const motivations = await fetchMotivationDetails();
    const motivationGroupMap = new Map<
      number,
      (typeof motivations)[0]['motivationGroup']
    >(motivations.map((m) => [m.motivationGroup.id, m.motivationGroup]));
    return [...motivationGroupMap.values()].sort(
      (a, b) => a.displayOrder - b.displayOrder,
    );
  },
};
export const fetchMotivationGroupDetails = () =>
  queryClient.fetchQuery(motivationGroupDetailsQuery);
export const useMotivationGroupDetails = () => useQuery(motivationGroupDetailsQuery);

export const injectMotivationDetails = async <T extends { motivationId: number }>(
  array: T[],
) => {
  const details = await fetchMotivationDetails();
  return array.flatMap((a) => {
    const detail = details.find((d) => d.id === a.motivationId);
    if (!detail) return [];
    return { ...a, motivation: detail };
  });
};

type MotivationReqBody = {
  userIds?: number[];
  atDate?: Date | number;
  modelId?: number;
  cultureCode?: string;
};

export type MotivationRes = {
  // From DB
  id: number; // Motivation ID
  questionnaireId: number;
  pattern: string;
  score: number; // Absolute score
  // Computed
  relative: number; // Relative score
  percentile: number; // Percentile
  rating: 'very_low' | 'low' | 'average' | 'high' | 'very_high';
  meaning: string;
  examples: string | null;
  aliasName: string;

  // Aggregate info
  size?: number; // Size of the sample
  distribution?: [score: number, count: number][]; // Absolute distribution
  relativeDistribution?: [percentile: number, count: number][]; // Percentile distribution
  quartiles?: [number, number, number]; // Absolute quartiles
  relativeQuartiles?: [number, number, number]; // Percentile quartiles
  ks?: number; // Kolmogorov-Smirnov statistic (distribution fit)
  // Deprecated
  stddev?: number; // Standard deviation of the sample
  stddevRatio?: number; // Ratio of stddev to the culture stddev
};

// Fetch user motivations.
const userMotivationsQuery = (data: MotivationReqBody = {}) => ({
  queryKey: ['motivations', data],
  queryFn: async () => {
    const userMotivations = (await apiClient.post('/api/v4/motivations', {
      ...data,
      atDate: data.atDate ? Number(data.atDate) : undefined,
    })) as MotivationResUserData[];

    return userMotivations.map((um) => ({
      userId: um.userId,
      user: um.user,
      motivations: mapMotivations(um.motivations),
    })) as MotivationUserData[];
  },
});
export const fetchUserMotivations = (data?: MotivationReqBody) =>
  queryClient.fetchQuery(userMotivationsQuery(data));
export const useUserMotivations = (data?: MotivationReqBody) =>
  useQuery(userMotivationsQuery(data));

const userAggregateQuery = (data: MotivationReqBody = {}) => ({
  queryKey: ['motivations', 'aggregate', data],
  queryFn: async () => {
    const motivations = (await apiClient.post('/api/v4/motivations/aggregate', {
      ...data,
      atDate: data.atDate ? Number(data.atDate) : undefined,
    })) as MotivationRes[];
    return mapMotivations(motivations);
  },
});
export const fetchUserAggregate = (data?: MotivationReqBody) =>
  queryClient.fetchQuery(userAggregateQuery(data));
export const useUserAggregate = (data?: MotivationReqBody) =>
  useQuery(userAggregateQuery(data));

const userOverTimeDatesQuery = (userId?: number | null) => ({
  queryKey: ['motivations', 'over-time', userId],
  queryFn: async () =>
    (userId
      ? apiClient.post('/api/v4/motivations/over-time', { userId })
      : []) as Promise<(string | null)[]>,
  enabled: !!userId,
});
export const fetchUserOverTimeDates = (userId?: number | null) =>
  queryClient.fetchQuery(userOverTimeDatesQuery(userId));
export const useUserOverTimeDates = (userId?: number | null) =>
  useQuery(userOverTimeDatesQuery(userId));

// Fetch aggregate workspace motivations.
const workspaceMotivationsQuery = ({
  workspaceId,
  data = {},
}: {
  workspaceId: number;
  data?: MotivationReqBody;
}) => ({
  queryKey: ['motivations', 'workspace', workspaceId, data],
  queryFn: async () => {
    const motivations = (await apiClient.post(
      `/api/v4/workspaces/${workspaceId}/motivations`,
      { ...data, atDate: data.atDate ? Number(data.atDate) : undefined },
    )) as MotivationRes[];
    return mapMotivations(motivations);
  },
});
export const fetchWorkspaceMotivations = ({
  workspaceId,
  data,
}: {
  workspaceId: number;
  data?: MotivationReqBody;
}) => queryClient.fetchQuery(workspaceMotivationsQuery({ workspaceId, data }));
export const useWorkspaceMotivations = ({
  workspaceId,
  data,
}: {
  workspaceId: number;
  data?: MotivationReqBody;
}) => useQuery(workspaceMotivationsQuery({ workspaceId, data }));

const motivationCompletionQuery = {
  queryKey: ['motivations', 'user', 'completion'],
  queryFn: async () => {
    const userMotivations = await fetchUserMotivations();
    const meMotivations = userMotivations[0]?.motivations;
    const definitions = await fetchMotivationDetails();
    // Map between
    const meMotivationCodes = meMotivations?.map((m) => m.code as string) ?? [];
    const missingMotivations = definitions.filter(
      (d) => !meMotivationCodes.includes(d.code),
    );
    const missingMotivationGroups = [
      ...new Map(
        missingMotivations.map((m) => [m.motivationGroupId, m.motivationGroup]),
      ).values(),
    ];
    return { missingMotivations, missingMotivationGroups };
  },
};
export const fetchMotivationCompletion = () =>
  queryClient.fetchQuery(motivationCompletionQuery);
export const useMotivationCompletion = () => useQuery(motivationCompletionQuery);

/* -------------------------------------------------------------------------- */
/*                        Deprecated Questionnaire APIs                       */
/* -------------------------------------------------------------------------- */

export type OverTimeData = {
  date: string;
  motivations: Motivation[];
};
// TODO: This should also be ported to the new motivation service, making use of atDate
// But for now the points of interest being questionnaire creation makes sense
export const overTimeQuery = {
  queryKey: ['questionnaire', 'over-time'],
  queryFn: async () => {
    // This only returns COMPLETED questionnaires over time
    const overTimeResponse = (await apiClient.get(
      `/api/v3/questionnaires/over-time`,
    )) as OverTimeData[];

    const overTimeData = overTimeResponse.map((d) => ({
      date: d.date,
      motivations: d.motivations
        .flatMap((m) => {
          const motivationIndex = motivationDefinitions.findIndex(
            (md) => md.code === m.pattern,
          );
          const motivation = motivationDefinitions[motivationIndex];
          if (!motivation) return [];

          return {
            ...motivation,
            sortIndex: motivationIndex,
            sampleSize: m.size,
            absolute: m.score,
            relative: m.relative,
            median: m.relative,
            quartiles: (m.relativeQuartiles ?? [m.relative, m.relative, m.relative]) as [
              number,
              number,
              number,
            ],
            ks: m.ks,
            relativeDistribution: m.relativeDistribution,
            distribution: m.distribution,
            meaning: m.meaning,
            examples: m.examples,
          };
        })
        .sort((a, b) => a.sortIndex - b.sortIndex),
    }));
    return { overTimeData };
  },
};

export const fetchOverTimeData = () => queryClient.fetchQuery(overTimeQuery);
export const useOverTimeData = () => useQuery(overTimeQuery);

export const teamMotivationsQuery = ({
  teamId,
  workspaceId,
  data = {},
}: {
  teamId: number;
  workspaceId: number;
  data?: MotivationReqBody;
}) => ({
  queryKey: ['motivations', 'workspace', workspaceId, 'team', teamId],
  queryFn: async () => {
    const motivations = (await apiClient.post(
      `/api/v4/workspaces/${workspaceId}/teams/${teamId}/motivations`,
      { ...data, atDate: data.atDate ? Number(data.atDate) : undefined },
    )) as MotivationRes[];
    return mapMotivations(motivations);
  },
});
export const fetchTeamMotivations = ({
  teamId,
  workspaceId,
  data,
}: {
  teamId: number;
  workspaceId: number;
  data?: MotivationReqBody;
}) => queryClient.fetchQuery(teamMotivationsQuery({ teamId, workspaceId, data }));
export const useTeamMotivations = ({
  teamId,
  workspaceId,
  data,
}: {
  teamId: number;
  workspaceId: number;
  data?: MotivationReqBody;
}) => useQuery(teamMotivationsQuery({ teamId, workspaceId, data }));

// TODO: Could shift this calculation to a cached query
export const getTeamDistribution = async ({
  teamId,
  workspaceSlug,
}: {
  teamId?: number;
  workspaceSlug?: string;
}) => {
  let distributions: DistributionData[] = [];
  let motivations: MotivationData[] = []; // Workspace like an individual, using medians

  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (workspaceId && teamId) {
    const team = await fetchTeam({
      workspaceId,
      teamId,
    });
    const teamMotivations = await fetchTeamMotivations({ teamId: team.id, workspaceId });
    const normalizationMode = await fetchLocalPreference({
      preferenceName: 'distributionStyle',
      defaultValue: 'standard',
    });
    distributions = teamMotivations.flatMap((m) =>
      m.relativeDistribution
        ? {
            name: team.name,
            code: m.code,
            quartiles: m.quartiles,
            distribution: normalizeDistribution({
              relativeDistribution: m.relativeDistribution,
              sampleSize: m.sampleSize,
              normalizationMode: normalizationMode ?? undefined,
            }),
          }
        : [],
    );
    motivations = teamMotivations;
  }

  return { distributions, motivations };
};

export const workspaceDistributionQuery = ({
  workspaceSlug,
}: {
  workspaceSlug?: string;
}) => ({
  queryKey: ['motivations', 'workspace', 'distributions', workspaceSlug],
  queryFn: async () => {
    let distributions: DistributionData[] = [];
    let motivations: MotivationData[] = []; // Workspace like an individual, using medians
    if (workspaceSlug) {
      const workspace = await fetchWorkspace(workspaceSlug);
      if (workspace) {
        const workspaceMotivations = await fetchWorkspaceMotivations({
          workspaceId: workspace.id,
        });
        const normalizationMode = await fetchLocalPreference({
          preferenceName: 'distributionStyle',
          defaultValue: 'standard',
        });
        distributions = workspaceMotivations.flatMap((m) =>
          m.relativeDistribution
            ? {
                name: workspace.name,
                code: m.code,
                quartiles: m.quartiles,
                distribution: normalizeDistribution({
                  relativeDistribution: m.relativeDistribution,
                  sampleSize: m.sampleSize,
                  normalizationMode: normalizationMode ?? undefined,
                }),
              }
            : [],
        );

        motivations = workspaceMotivations;
      }
    }
    return { distributions, motivations };
  },
});

export const fetchWorkspaceDistribution = (
  params: Parameters<typeof workspaceDistributionQuery>[0],
) => queryClient.fetchQuery(workspaceDistributionQuery(params));
export const useWorkspaceDistribution = (
  params: Parameters<typeof workspaceDistributionQuery>[0],
) => useQuery(workspaceDistributionQuery(params));
