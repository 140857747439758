import { useCallback, useMemo, type ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  cn,
  Icon,
  InputDropdownDrawer,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import { NavLinkButton } from '@/components/nav-link';
// import { useCredits } from '@/modules/credits/queries';
// import { showIntercom } from '@/lib/intercom';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useWorkspaces, type Workspace } from '@/modules/workspace/queries';
import { setLocalPreference } from '@/providers/local-preference';

export const WORKSPACE_LOCAL_PREFERENCE_NAME = 'workspace';

type SwitcherOption = { id: number | string; pathname: string; workspace?: Workspace };

const CreateSpaceOption = () => (
  <>
    <div className="bg-muted flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-md">
      <Icon.Plus size={16} />
    </div>
    Create a space
  </>
);

// Needs to be external state controlled
export const WorkspaceSelect = ({
  workspaces,
  selected,
  onSelect,
  isCollapsed,
  variant = 'ghost',
  size,
  hideCreate,
}: {
  workspaces: Workspace[];
  selected: Workspace | undefined;
  onSelect: (option?: Workspace) => void;
  isCollapsed?: boolean;
  variant?: ComponentProps<typeof Button>['variant'];
  size?: ComponentProps<typeof Button>['size'];
  hideCreate?: boolean;
}) => {
  const options = useMemo(() => {
    const matches: SwitcherOption[] =
      workspaces?.map((w) => ({
        id: w.id,
        pathname: `/spaces/${w.slug}`,
        workspace: w,
      })) ?? [];
    if (!hideCreate) {
      matches.push({ id: 'create', pathname: '/spaces/create', workspace: undefined });
    }
    return matches;
  }, [hideCreate, workspaces]);

  const handleSelect = useCallback(
    (option: SwitcherOption) => {
      onSelect(option.workspace);
    },
    [onSelect],
  );

  const optionRender = useCallback(
    (option: SwitcherOption) => {
      return (
        <NavLinkButton
          to={option.pathname}
          preventDefault
          variant="ghost"
          className={cn(
            'h-auto w-full justify-between gap-2 py-1 !pl-2 !pr-3',
            option.id === (selected?.id ?? 'create') && 'font-semibold',
          )}
        >
          {option.workspace ? (
            <>
              <div className="flex items-center gap-2 truncate">
                <ProfileAvatar
                  avatarUrl={option.workspace.avatarUrl}
                  initials={option.workspace.name[0]}
                  className={cn('h-6 min-h-6 w-6 min-w-6 shrink-0 rounded-md')}
                />
                <span className="truncate">{option.workspace.name}</span>
              </div>
              {option.workspace.isFavorite && (
                <Icon.Star
                  size={16}
                  className="shrink-0 fill-yellow-400 stroke-yellow-500"
                />
              )}
            </>
          ) : (
            !hideCreate && (
              <div className="flex items-center gap-2">
                <CreateSpaceOption />
              </div>
            )
          )}
        </NavLinkButton>
      );
    },
    [hideCreate, selected?.id],
  );

  return (
    <Tooltip disableHoverableContent>
      <InputDropdownDrawer
        asChild
        options={options}
        optionRender={optionRender}
        optionsAsChildren
        handleSelect={handleSelect}
        alignPopover={isCollapsed ? 'start' : undefined}
      >
        <TooltipTrigger asChild>
          <Button
            variant={variant}
            size={size}
            className={cn(
              'relative h-auto w-full justify-between gap-2 px-1.5 py-1.5',
              isCollapsed && '!pr-0.5',
            )}
          >
            <div className="flex flex-1 items-center gap-2 truncate p-px">
              {selected ? (
                <>
                  <ProfileAvatar
                    key={selected.id}
                    className="h-6 min-h-6 w-6 min-w-6 shrink-0 rounded-md"
                    avatarUrl={selected.avatarUrl}
                    initials={selected.name[0]}
                  />
                  <div
                    className={cn(
                      'flex h-full flex-1 flex-col items-start truncate',
                      isCollapsed && 'hidden',
                    )}
                  >
                    <span className="w-full truncate text-left leading-tight">
                      {selected.name}
                    </span>
                  </div>
                </>
              ) : (
                !hideCreate && <CreateSpaceOption />
              )}
            </div>
            <div
              className={cn(
                'flex h-6 w-6 shrink-0 items-center justify-center',
                isCollapsed && 'hidden',
              )}
            >
              <Icon.ChevronsUpDown size={18} className="opacity-60" />
            </div>
          </Button>
        </TooltipTrigger>
      </InputDropdownDrawer>
      <TooltipContent
        side="right"
        className={cn('pointer-events-none z-[100]', !isCollapsed && 'hidden')}
      >
        {selected ? selected.name : 'Create a space'}
      </TooltipContent>
    </Tooltip>
  );
};

export const WorkspaceSwitcher = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const navigate = useNavigate();
  const { data: workspaces } = useWorkspaces();
  const match = useMatchedWorkspace();

  const handleSelect = useCallback(
    (workspace?: Workspace) => {
      // Only set local preference when the option is a real workspace
      if (workspace) {
        setLocalPreference({
          preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
          value: Number(workspace.id),
        });
        navigate(`/spaces/${workspace.slug}`);
      } else {
        navigate('/spaces/create');
      }
    },
    [navigate],
  );

  return (
    <WorkspaceSelect
      isCollapsed={isCollapsed}
      workspaces={workspaces ?? []}
      selected={match.workspace}
      onSelect={handleSelect}
      variant="secondary"
    />
  );
};
