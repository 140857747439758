import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';
import type { DistributionData } from '@f4s/widgets';

import { fetchDashboardTemplates } from '../dashboard/queries';
import {
  fetchUserMotivations,
  fetchWorkspaceDistribution,
  getTeamDistribution,
} from '../motivation/queries';
import { fetchTeam } from '../team/queries';
import { fetchWorkspace } from '../workspace/queries';
import { fetchMembers } from './queries';

export const membersLoader = async ({ params }: LoaderFunctionArgs) => {
  const user = await authProvider.getUser();
  const { workspaceSlug, teamId } = params as { workspaceSlug?: string; teamId?: string };
  const workspace = await fetchWorkspace(workspaceSlug);
  const team =
    workspace && teamId
      ? await fetchTeam({ workspaceId: workspace.id, teamId: Number(teamId) })
      : undefined;
  const members = await fetchMembers({ workspaceSlug, teamId });

  return { user, members, team, workspace };
};

export const memberLoader = async ({ params }: LoaderFunctionArgs) => {
  const user = await authProvider.getUser();
  const { memberId, workspaceSlug, teamId } = params as {
    memberId: string;
    workspaceSlug?: string;
    teamId?: string;
  };

  const workspace = await fetchWorkspace(workspaceSlug);
  const team =
    workspace && teamId
      ? await fetchTeam({ workspaceId: workspace.id, teamId: Number(teamId) })
      : undefined;
  const [member] = (await fetchMembers({ memberId, workspaceSlug, teamId })) ?? [
    undefined,
  ];

  if (!member?.id) return redirect('..');
  const userMotivations = await fetchUserMotivations({ userIds: [member.userId] });

  const distributions: DistributionData[] = [];
  // Put distributions behind workspace entitlements
  if (
    (workspace?.entitlements['comparison-limit'] ?? 2) < 0 &&
    (workspace?.isAdmin || workspace?.permissions.memberViewEachOther)
  ) {
    const { distributions: teamDistributions } = await getTeamDistribution({
      teamId: Number(teamId),
      workspaceSlug,
    });
    distributions.push(...teamDistributions);

    const { distributions: workspaceDistributions } = await fetchWorkspaceDistribution({
      workspaceSlug,
    });
    distributions.push(...workspaceDistributions);
  }

  return {
    user,
    member,
    team,
    workspace,
    userMotivations,
    distributions,
    motivationData: userMotivations[0]?.motivations ?? [], // TODO: remove this and just use the userMotivations shape
  };
};

export const memberResultsLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const memberLoaderResponse = await memberLoader({ request, params });
  if (memberLoaderResponse instanceof Response) return memberLoaderResponse;
  const dashboardTemplates = await fetchDashboardTemplates();
  return { ...memberLoaderResponse, dashboardTemplates };
};

export type MembersLoaderData = Exclude<
  Awaited<ReturnType<typeof membersLoader>>,
  Promise<Response> | Response
>;

export type MemberLoaderData = Exclude<
  Awaited<ReturnType<typeof memberLoader>>,
  Promise<Response> | Response
>;
