import { useMemo } from 'react';

import { ordinalize } from '@f4s/shared';
import type { MotivationGroupSlug } from '@f4s/types';
import { cn, palette } from '@f4s/ui';

import { TooltipDrawer } from '@/components/tooltip-drawer';

import {
  calculateData,
  type WidgetComponents,
  type WidgetFC,
  type WidgetPreviewFC,
} from '../widget-common';

export const Bubble = ({
  motivationData,
  isActive,
  hideTooltips,
  className,
  referenceValue = 50,
}: {
  motivationData: {
    group: MotivationGroupSlug;
    name: string;
    meaning?: string;
    median: number;
  };
  isActive?: boolean;
  hideTooltips?: boolean;
  className?: string;
  referenceValue?: number;
}) => {
  const height = `round(down, ${motivationData.median * 0.9 + 10}%, 2px`;
  const midHeight = `round(down, ${referenceValue * 0.9 + 10}%, 2px)`;

  const bubble = useMemo(
    () => (
      <div className="group aspect-square max-h-full min-h-0 w-full min-w-0 max-w-full hover:cursor-pointer">
        <div className="relative mx-auto flex aspect-square max-h-full min-h-0 min-w-0 max-w-full items-center justify-center">
          <div
            className={cn(
              palette(motivationData.group),
              'bg-palette-300 group-hover:bg-palette-500 border-border/50 dark:bg-palette-700 absolute box-border aspect-square max-h-full rounded-full border transition-colors',
              isActive && 'bg-palette-500',
              className,
            )}
            style={{ height }}
          />
          <div
            className="border-border/50 pointer-events-none absolute box-border aspect-square rounded-full border border-dashed"
            style={{ height: midHeight }}
          />
        </div>
      </div>
    ),
    [className, height, isActive, midHeight, motivationData.group],
  );

  return hideTooltips ? (
    bubble
  ) : (
    <TooltipDrawer
      asChild
      content={
        <>
          <div className="text-sm">
            <span className="font-semibold">{motivationData.name}:</span>{' '}
            <span className="">{ordinalize(motivationData.median)} percentile</span>
          </div>
          {motivationData.meaning && (
            <div className="mt-2 text-sm">{motivationData.meaning}</div>
          )}
        </>
      }
    >
      {bubble}
    </TooltipDrawer>
  );
};

const Subtitle: WidgetFC = () => null;

const Visualization: WidgetFC = ({ widget, isLoading }) => {
  if (isLoading) {
    return null;
  }
  const motivations = calculateData({ widget, isLoading });
  const firstData = widget.datasets.find((d) => d.userData || d.aggregateData);
  const motivationData =
    firstData?.userData?.motivations ?? firstData?.aggregateData ?? [];

  return (
    <div className="grid h-full max-h-full min-h-0 grid-cols-6 items-center gap-1 sm:grid-cols-8">
      {motivations.flatMap((motivation) => {
        const m = motivationData.find((md) => md.code === motivation.code);
        if (!m) return [];
        return <Bubble key={m.id} motivationData={m} />;
      })}
    </div>
  );
};

const Preview: WidgetPreviewFC = ({ widget, isLoading, hideTitle }) => {
  if (isLoading) {
    return null;
  }
  const motivations = calculateData({ widget, isLoading });
  const firstData = widget.datasets.find((d) => d.userData || d.aggregateData);
  const motivationData =
    firstData?.userData?.motivations ?? firstData?.aggregateData ?? [];

  return (
    <div className="flex max-h-full flex-1 flex-col gap-1">
      {!hideTitle && (
        <div className="text-[0.5rem] font-semibold leading-[0.5rem]">{widget.title}</div>
      )}
      <div className="grid min-h-0 flex-1 grid-cols-8 items-center gap-1">
        {motivations.flatMap((motivation) => {
          const m = motivationData.find((md) => md.code === motivation.code);
          if (!m) return [];
          const height = `${Math.floor(m.median * 0.8 + 20)}%`;
          return (
            <div
              key={m.id}
              className="aspect-square max-h-full min-h-0 w-full min-w-0 max-w-full"
            >
              <div className="relative mx-auto flex aspect-square max-h-full min-h-0 min-w-0 max-w-full items-center justify-center">
                <div
                  className={cn(
                    palette(m.group),
                    'bg-palette-300 border-border/50 dark:bg-palette-700 absolute box-border aspect-square max-h-full rounded-full border transition-colors',
                  )}
                  style={{ height }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Description: WidgetFC = () => null;

export const BubbleComponents: WidgetComponents = {
  Subtitle,
  Visualization,
  Preview,
  Description,
};
