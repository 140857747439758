import type { RouteObject } from 'react-router-dom';

export const integrationRoutes: RouteObject = {
  path: 'integrations',

  children: [
    {
      path: 'slack',
      async lazy() {
        const { InstallSlack } = await import('./pages/slack');
        return { Component: InstallSlack };
      },
    },
  ],
};
