import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'cva';
import * as React from 'react';

import { cn } from '../lib/utils';

const labelVariants = cva(
  'text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

const Label = React.forwardRef<
  React.ComponentRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
