import { useCallback, useEffect, useState } from 'react';
import { useFetcher, useNavigate } from 'react-router-dom';

import { useMaybeUser } from '@f4s/api-client';
import { type Assessment, type Question } from '@f4s/types/common/question';
import { useAssessment } from '@f4s/ui/modules/assessment';

export const useAssessmentWeb = (args: {
  assessment: Assessment;
  question: Question;
  questionNo: number;

  nextStep: string;
}) => {
  const { user } = useMaybeUser();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(fetcher.state !== 'idle');
  }, [fetcher.state]);

  const handleSubmit = useCallback(
    (savedAnswers: { questionNumber: number; answerOrder: number[] }[]) => {
      // The user may or may not be logged in, only post the data when they are.
      if (!user) return navigate(args.nextStep);
      fetcher.submit(
        { redirectTo: args.nextStep, data: savedAnswers },
        {
          method: 'POST',
          encType: 'application/json',
          action: '.',
        },
      );
    },
    [user, navigate, args.nextStep, fetcher],
  );

  const { questionTitle, handleNext, answerList } = useAssessment({
    assessment: args.assessment,
    question: args.question,
    questionNo: args.questionNo,

    onNextStep: () => navigate(args.nextStep),
    onSubmit: handleSubmit,
  });

  return {
    questionTitle,
    handleNext,
    answerList,
    isLoading,
  };
};
