import type { RouteObject } from 'react-router-dom';

import {
  samlCreateAction,
  samlUpdateAction,
  scimCreateAction,
  scimUpdateAction,
} from './actions';
import {
  samlListLoader,
  samlLoader,
  samlNewLoader,
  scimListLoader,
  scimLoader,
  scimNewLoader,
} from './loaders';

export const workspaceSamlRoutes: RouteObject = {
  path: 'saml',
  children: [
    {
      index: true,
      loader: samlListLoader,

      async lazy() {
        const { SamlList } = await import('./pages/saml-list');
        return { Component: SamlList };
      },
    },
    {
      path: 'new',
      loader: samlNewLoader,
      action: samlCreateAction,
      async lazy() {
        const { SamlPage } = await import('./pages/saml-page');
        return { Component: SamlPage };
      },
    },
    {
      path: ':samlId',
      loader: samlLoader,
      action: samlUpdateAction,
      async lazy() {
        const { SamlPage } = await import('./pages/saml-page');
        return { Component: SamlPage };
      },
    },
  ],
};

export const workspaceScimRoutes: RouteObject = {
  path: 'scim',
  children: [
    {
      index: true,
      loader: scimListLoader,
      async lazy() {
        const { ScimList } = await import('./pages/scim-list');
        return { Component: ScimList };
      },
    },
    {
      path: 'new',
      loader: scimNewLoader,
      action: scimCreateAction,
      async lazy() {
        const { ScimPage } = await import('./pages/scim-page');
        return { Component: ScimPage };
      },
    },
    {
      path: ':scimId',
      loader: scimLoader,
      action: scimUpdateAction,
      async lazy() {
        const { ScimPage } = await import('./pages/scim-page');
        return { Component: ScimPage };
      },
    },
  ],
};
