import { authProvider } from '@f4s/api-client';

import { fetchPersonalConnections } from './queries';
import { fetchWorkspaces } from '@/modules/workspace/queries';

export const legacyConnectionsLoader = async () => {
  const user = await authProvider.getUser();
  const connections = await fetchPersonalConnections();
  const workspaces = await fetchWorkspaces();
  return { user, connections, workspaces };
};
