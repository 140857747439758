import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { Slot } from '@radix-ui/react-slot';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import { forwardRef, Fragment, useCallback, useRef, type ReactNode } from 'react';

import { cn } from '../lib/utils';
import { ScrollBar } from './scroll-area';

export const DataGridRow = ({
  children,
  className,
  asChild,
}: {
  children: ReactNode;
  className?: string;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      className={cn(
        'hover:bg-secondary after:bg-muted relative col-span-full grid h-12 grid-cols-subgrid items-center px-4 after:absolute after:bottom-[-0.5px] after:z-0 after:h-px after:w-full last:after:hidden',
        className,
      )}
    >
      {children}
    </Comp>
  );
};

export const DataGridHeader = ({
  children,
  className,
  asChild,
}: {
  children: ReactNode;
  className?: string;
  asChild?: boolean;
}) => (
  <DataGridRow
    className={cn(
      'bg-secondary before:bg-muted sticky top-0 z-10 grid h-8 w-[calc(100%-10px)] rounded-t-lg py-1 font-semibold before:absolute before:top-[-0.5px] before:z-20 before:h-px before:w-full',
      className,
    )}
    asChild={asChild}
  >
    {children}
  </DataGridRow>
);

export const DataGridItem = ({
  children,
  className,
  asChild,
}: {
  children?: ReactNode;
  className?: string;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'div';
  return <Comp className={cn('truncate px-2', className)}>{children}</Comp>;
};

export const DataGridHeaderItem = ({
  children,
  className,
  asChild,
}: {
  children?: ReactNode;
  className?: string;
  asChild?: boolean;
}) => {
  return (
    <DataGridItem
      className={cn('text-muted-foreground text-xs font-medium', className)}
      asChild={asChild}
    >
      {children}
    </DataGridItem>
  );
};

export const DataGridContainer = forwardRef<
  React.ComponentRef<typeof ScrollAreaPrimitive.Viewport>,
  { className?: string; containerClassName?: string; children?: ReactNode }
>(({ className, containerClassName, children }, ref) => {
  return (
    <ScrollAreaPrimitive.Root
      className={cn('!absolute h-full min-h-0 w-full', containerClassName)}
    >
      <ScrollAreaPrimitive.Viewport
        className={cn(
          'h-full w-full rounded-[inherit] [&>div]:!flex [&>div]:!min-h-full [&>div]:!flex-col',
        )}
        ref={ref}
      >
        <div className={cn('grid', className)}>{children}</div>
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar className="z-20" />
    </ScrollAreaPrimitive.Root>
  );
});
DataGridContainer.displayName = 'DataGridContainer';

export const DataGrid = <
  T extends
    | { key: string | number; id?: string | number }
    | { id: string | number; key?: string | number },
>({
  rows,
  rowHeader,
  rowHeight,
  rowRender,
  className,
  containerClassName,
}: {
  rows: T[];
  rowHeader: ReactNode;
  rowHeight: number;
  rowRender: (data: T) => ReactNode;
  className?: string;
  containerClassName?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => containerRef.current,
    count: rows.length,
    estimateSize: () => rowHeight,
    overscan: 5,
  });

  const renderRows = useCallback(() => {
    const virtualRows = rowVirtualizer.getVirtualItems();
    const Rows = virtualRows.flatMap((virtualRow) => {
      const row = rows[virtualRow.index];
      if (!row) return [];
      return <Fragment key={row.id ?? row.key}>{rowRender(row)}</Fragment>;
    });
    const [before, after] =
      virtualRows.length > 0
        ? [
            notUndefined(virtualRows[0]).start - rowVirtualizer.options.scrollMargin,
            rowVirtualizer.getTotalSize() - notUndefined(virtualRows.at(-1)).end,
          ]
        : [0, 0];
    const Before = before > 0 ? <div style={{ height: before }} /> : null;
    const After = after > 0 ? <div style={{ height: after }} /> : null;
    return { Rows, Before, After };
  }, [rowRender, rowVirtualizer, rows]);

  const { Rows, Before, After } = renderRows();

  return (
    <DataGridContainer
      ref={containerRef}
      className={className}
      containerClassName={containerClassName}
    >
      {rowHeader}
      {Before}
      {Rows}
      {After}
    </DataGridContainer>
  );
};
