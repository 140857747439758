import type { LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { fetchUserMotivations, fetchWorkspaceDistribution } from '../motivation/queries';

export const myResultsLoader = async ({ params }: LoaderFunctionArgs) => {
  const user = await authProvider.getUser();
  const { workspaceSlug } = params as {
    workspaceSlug?: string;
  };
  const userMotivations = await fetchUserMotivations();
  const { distributions, motivations: workspaceMotivations } =
    await fetchWorkspaceDistribution({
      workspaceSlug,
    });
  return { user, distributions, workspaceMotivations, userMotivations };
};
