import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';

export type TalentPoolTag = {
  id: number;
  name: string;
  displayName?: string;
};

const talentPoolsQuery = {
  queryKey: ['talent', 'pools'],
  queryFn: async () =>
    apiClient.get(`/api/v3/modeling/talent/tags`) as Promise<TalentPoolTag[]>,
};
export const fetchTalentPools = () => queryClient.fetchQuery(talentPoolsQuery);
export const useTalentPools = () => useQuery(talentPoolsQuery);
