import {
  captureConsoleIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  wrapCreateBrowserRouterV6,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const getSampleRate = () => {
  if (window.F4S_ENV === 'production') {
    return 1;
  }
  if (window.F4S_ENV === 'local') {
    return 0;
  }
  return 0.25;
};

export const initSentry = () => {
  init({
    dsn: 'https://490848ee138a451bbd0f2be0b24ecc45@sentry.io/247715',
    environment: window.F4S_ENV,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({ levels: ['error'] }),
    ],
    release: window.SENTRY_RELEASE?.id,
    sampleRate: getSampleRate(),
    tracesSampler: (_samplingContext) => getSampleRate() * 0.25,
    ignoreErrors: [
      'devicePropertyAllowlist must be an array',
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
      'Illegal invocation',
      'Userback widget already loading',
      // HTML5 video
      'The play() request was interrupted by',
      'play() failed because the user',
      "The fetching process for the media resource was aborted by the user agent at the user's request",
      'not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    ],
  });
};

export const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter);
