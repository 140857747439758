import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { type WorkspaceModelUpdate } from '@f4s/types';

import { fetchWorkspace } from '@/modules/workspace/queries';
import { fetchLocalPreference } from '@/providers/local-preference';

import { fetchMotivationDetails, fetchWorkspaceMotivations } from '../motivation/queries';
import { fetchModelData, fetchWorkspaceModelList } from './queries';

export const baseModelLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug: string };

  let modelData = await fetchModelData();
  const motivationDetails = await fetchMotivationDetails();
  if (workspaceSlug) {
    const workspace = await fetchWorkspace(workspaceSlug);
    if (!workspace) return redirect('..');
    const workspaceModels = await fetchWorkspaceModelList({ workspaceId: workspace.id });
    modelData = [...workspaceModels, ...modelData];
    return { workspace, modelData, motivationDetails };
  }
  // motivationData = await fetchWorkspaceMotivations({ workspaceId: workspace.id });

  return { workspace: null, modelData, motivationDetails };
};
export type BaseModelLoaderData = Exclude<
  Awaited<ReturnType<typeof baseModelLoader>>,
  Promise<Response> | Response
>;

export const modelLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const baseModelLoaderResponse = await baseModelLoader({ request, params });
  if (baseModelLoaderResponse instanceof Response) return baseModelLoaderResponse;
  const { workspace, modelData, motivationDetails } = baseModelLoaderResponse;

  const { modelId } = params as { modelId: string };
  if (!workspace) return redirect('..');
  const workspaceModel = modelData.find((m) => m.id === Number(modelId));
  if (!workspaceModel) return redirect('..');
  const localBackup = await fetchLocalPreference<WorkspaceModelUpdate>({
    preferenceName: `modelBackup-${modelId}`,
  });
  const motivationData = await fetchWorkspaceMotivations({ workspaceId: workspace.id });

  return {
    workspace,
    modelData,
    workspaceModel,
    motivationData,
    motivationDetails,
    localBackup,
  };
};
export type ModelLoaderData = Exclude<
  Awaited<ReturnType<typeof modelLoader>>,
  Promise<Response> | Response
>;

export const modelListLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const baseModelLoaderResponse = await baseModelLoader({ request, params });
  if (baseModelLoaderResponse instanceof Response) return baseModelLoaderResponse;
  const { workspace, modelData } = baseModelLoaderResponse;
  if (!workspace) return redirect('..');
  const workspaceModels = await fetchWorkspaceModelList({ workspaceId: workspace?.id });

  return { workspace, modelData, workspaceModels };
};
export type ModelListLoaderData = Exclude<
  Awaited<ReturnType<typeof modelListLoader>>,
  Promise<Response> | Response
>;
