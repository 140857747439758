import * as React from 'react';

import { cn } from '../lib/utils';
import { Card } from './card';

type PixelValue = `${number}px`;
type GridConfig = {
  [key in PixelValue | 'default']?: {
    cols: number;
    rows?: number;
  };
};

export const EmptyCard: React.FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <Card
    className={cn(
      'bg-muted ring-border/[0.03] flex aspect-[4/3] w-full min-w-0 shrink-0 flex-col items-center justify-center ring-inset',
      // "[mask-image:linear-gradient(to_bottom,rgba(0,0,0,1)_0%,rgba(0,0,0,0)_100%)]"
      className,
    )}
  >
    {children}
  </Card>
);

export const EmptyState: React.FC<{
  message?: string;
  heading?: string;
  actions?: React.ReactElement;
  gridConfig?: GridConfig;
}> = ({
  message,
  heading,
  actions,
  gridConfig = {
    'default': { cols: 2, rows: 2 },
    '680px': { cols: 3, rows: 2 },
    '1000px': { cols: 4, rows: 2 },
    '1280px': { cols: 5, rows: 2 },
  },
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const getGridConfig = React.useCallback(
    (width: number) => {
      // Find the largest matching breakpoint
      const pixelBreakpoints = Object.entries(gridConfig)
        .filter(([key]) => key.endsWith('px'))
        .map(([key]) => Number.parseInt(key))
        .sort((a, b) => b - a);

      // Check pixel breakpoints
      for (const breakpoint of pixelBreakpoints) {
        if (width >= breakpoint) {
          const key = `${breakpoint}px` as PixelValue;
          return gridConfig[key];
        }
      }

      // Then check named breakpoints (default will be caught here)
      return gridConfig.default;
    },
    [gridConfig],
  );

  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        const width = entry.contentRect.width;
        setContainerWidth(width);
      }
    });

    observer.observe(container);
    return () => observer.disconnect();
  }, []);

  const currentConfig = getGridConfig(containerWidth);
  const numberOfCards = (currentConfig?.cols ?? 2) * (currentConfig?.rows ?? 2);

  return (
    <>
      <div ref={containerRef} className="@container relative flex flex-1 flex-col gap-3">
        <div
          className={cn(
            'grid w-full min-w-0 flex-1 gap-3',
            '[mask-image:linear-gradient(to_bottom,rgba(0,0,0,1)_0%,rgba(0,0,0,0.2)_100%)]',
          )}
          style={{
            gridTemplateColumns: `repeat(${currentConfig?.cols ?? 2}, 1fr)`,
          }}
        >
          {Array.from({ length: numberOfCards }).map((_, i) => (
            <EmptyCard key={`placeholder-${i}`} />
          ))}
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center">
            {heading && <h3 className="mb-2 font-semibold">{heading}</h3>}
            {message && (
              <p className="text-muted-foreground text-balance text-center">{message}</p>
            )}
            {actions && <div className="mt-4">{actions}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
