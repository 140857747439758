/* eslint-disable sonarjs/no-duplicate-string */
import type { MotivationGroupSlug, MotivationPattern } from '@f4s/types';

export type MotivationInfo = {
  group: MotivationGroupSlug;
  code: MotivationPattern;
  name: string;
  slug: string;
  benefits: readonly string[];
  drawbacks: readonly string[];
  video: string;
  language: { increase: readonly string[]; decrease: readonly string[] };
  description: string;
};

export type MotivationGroup = {
  name: string;
  slug: MotivationGroupSlug;
  order: number;
  motivations: MotivationInfo[];
  scoreIntervals: number;
  consistent: boolean;
  meaning: string;
};

export const motivationDefinitions = [
  {
    group: 'action-level',
    code: 'OF1P',
    name: 'Initiation',
    slug: 'initiation',
    benefits: ['Leadership', 'Resilience', 'Energy'],
    drawbacks: ['Patience', 'Planning', 'Follow-through'],
    video: 'io3i8jvwfo',
    language: {
      increase: [
        'Initiate',
        'Take the first step',
        "Let's just do it",
        "Let's get going",
        'Just start',
      ],
      decrease: ['Sit with', 'Wait', 'Pause', 'Observe', 'Have patience', 'In good time'],
    },
    description:
      "Your level of energy for action, for starting and getting things going, for 'thinking on your feet'.",
  },
  {
    group: 'action-level',
    code: 'OF1M',
    name: 'Reflection + Patience',
    slug: 'reflection-and-patience',
    benefits: ['Thoughtful decisions', 'Sustained energy', 'Strategy'],
    drawbacks: ['Missed opportunities', 'Risk averse', 'Delay'],
    video: '7rqjzkrajc',
    language: {
      increase: [
        'Patience',
        'Wait',
        'Right timing',
        'Observe',
        'Let it happen organically',
      ],
      decrease: ['Start immediately', 'Jump straight in', 'Decide quickly', 'Move fast'],
    },
    description:
      'Your level of patience to comfortably wait, pause, observe or reflect without needing to take action.',
  },
  {
    group: 'action-direction',
    code: 'OF2P',
    name: 'Goals',
    slug: 'goal-orientation',
    benefits: ['Business Visioning', 'Strategic Thinking', 'Inspiring Others'],
    drawbacks: ['Obstacle Averse', 'Single-Minded', 'Enjoy the Journey'],
    video: '62s93hdxfg',
    language: {
      increase: [
        'Attain',
        'Get',
        'Gain',
        'Achieve',
        'Dreams',
        'Desires',
        'Goals',
        'Targets',
      ],
      decrease: [
        'Problems',
        'Errors',
        'Concerns',
        'Issues',
        'Pain',
        'Avoid',
        'Stay clear of',
      ],
    },
    description:
      'Level of motivation you derive from targets, visions and your level of energy to stay focused on business goals over time.',
  },
  {
    group: 'action-direction',
    code: 'OF2M',
    name: 'Problems',
    slug: 'away-from-problems',
    benefits: ['Caution', 'Resilience', 'Reliable'],
    drawbacks: ['Unsupportive', 'Negative', 'Need pressure'],
    video: '6ybzhuwncb',
    language: {
      increase: [
        'Problems',
        'Errors',
        'Concerns',
        'Issues',
        'Pain',
        'Avoid',
        'Stay clear of',
      ],
      decrease: [
        'Attain',
        'Get',
        'Gain',
        'Achieve',
        'Dreams',
        'Desires',
        'Goals',
        'Targets',
      ],
    },
    description:
      'Your level of energy and interest in predicting, preventing or solving problems, business challenges and difficulties.',
  },
  {
    group: 'authority',
    code: 'OF3P',
    name: 'Internal Reference',
    slug: 'internal-reference',
    benefits: ['Confidence', 'Decisiveness', 'Leadership'],
    drawbacks: ['External Reference', 'Creativity', 'Obstinance'],
    video: 'z4e7tj4zye',
    language: {
      increase: ['You know', 'You will decide', 'Your opinion', 'Your criteria'],
      decrease: [
        'Check with others',
        'Get advice',
        'Feedback',
        'Do some research',
        'Look at the data',
      ],
    },
    description:
      'Level of motivation for using your own criteria to form opinions and to make business decisions.',
  },
  {
    group: 'authority',
    code: 'OF3M',
    name: 'External Reference',
    slug: 'external-reference',
    benefits: ['Open-mindedness ', 'Thoughtful decisions', 'Improvement'],
    drawbacks: ['Confidence ', 'Delays ', 'Unpopular decisions'],
    video: '7uj10he0f4',
    language: {
      increase: ['Check with others', 'Get advice', 'Feedback', 'Do some research'],
      decrease: ['You know', 'You will decide', 'Your opinion', 'Your criteria'],
    },
    description:
      'Your level of priority and importance to seek external references of data, research, feedback, advisors and stakeholders for business decision making.',
  },
  {
    group: 'task-direction',
    code: 'OF4P',
    name: 'Alternatives',
    slug: 'alternatives',
    benefits: ['Perspective', 'Invention', 'Problem solver'],
    drawbacks: ['Indecision', 'Rule-breaker', 'Heedless'],
    video: 'x0zb36v9s1',
    language: {
      increase: [
        'Options',
        'Possibilities',
        'Alternatives',
        'Choices',
        'Flexible',
        'Adaptable',
        'Create',
        'Generate',
      ],
      decrease: [
        'Procedures',
        'Script',
        'Recipe',
        'Method',
        'Process',
        'Strategy',
        'Right way',
      ],
    },
    description:
      'Your level of desire to find alternate ways, create options and invent possibilities in business.',
  },
  {
    group: 'task-direction',
    code: 'OF4M',
    name: 'Procedures',
    slug: 'procedures',
    benefits: ['Dependable', 'Results Oriented', 'Eagle-eyed'],
    drawbacks: ['Cautious', 'Inflexible', 'Status Quo'],
    video: '3ya4r9bu77',
    language: {
      increase: [
        'Procedures',
        'Script',
        'Recipe',
        'Method',
        'Process',
        'Strategy',
        'Right way',
      ],
      decrease: [
        'Options',
        'Possibilities',
        'Alternatives',
        'Choices',
        'Flexible',
        'Adaptable',
        'Create',
        'Generate',
      ],
    },
    description:
      "Your level of energy to follow a proven step by step process, script, recipe or method of 'best practice'.",
  },
  {
    group: 'scope',
    code: 'OF5P',
    name: 'Breadth',
    slug: 'breadth',
    benefits: ['Patterns', 'Innovation', 'Ambition'],
    drawbacks: ['Focus ', 'Saying ‘yes’', 'Realism '],
    video: 'ffsngupeim',
    language: {
      increase: [
        'Overview',
        'Synopsis',
        'Big picture',
        'Bullet points',
        'Gist',
        'Sketch',
        'Macro',
        'Breadth',
      ],
      decrease: [
        'Details',
        'Specifics',
        'Concrete',
        'Technicalities',
        'Micro',
        'Fine points',
        'In depth',
      ],
    },
    description:
      'Your level of motivation for abstract, macro, global, big picture thinking and communication.',
  },
  {
    group: 'scope',
    code: 'OF5M',
    name: 'Depth',
    slug: 'depth',
    benefits: ['Work quality', 'Efficiency', 'Trust'],
    drawbacks: ['Prioritization', 'Analysis paralysis', 'Micromanagement '],
    video: 'jokxsojz6h',
    language: {
      increase: [
        'Details',
        'Specifics',
        'Concrete',
        'Technicalities',
        'Micro',
        'Fine points',
        'In depth',
      ],
      decrease: [
        'Overview',
        'Synopsis',
        'Big picture',
        'Bullet points',
        'Gist',
        'Sketch',
        'Macro',
        'Breadth',
      ],
    },
    description:
      'Your level of motivation for detailed information and working directly with specifics, sequences and for being exact.',
  },
  {
    group: 'communication',
    code: 'OF6P',
    name: 'Affective Communication',
    slug: 'affective-communication',
    benefits: ['Charismatic', 'Expressive', 'Perceptive'],
    drawbacks: ['Assumptive', 'Hunches', 'Indecision'],
    video: 'u7ug5yxn4o',
    language: {
      increase: ['Smiling', 'Head nodding', 'Sparkling eyes', 'Eye contact'],
      decrease: [
        'Keep to the facts',
        'Remove emotion from it',
        'Remain neutral',
        'Meaning of the words',
      ],
    },
    description:
      'Level of sensitivity and importance you place on tone of voice, gestures and other non-verbal expressions and communication.',
  },
  {
    group: 'communication',
    code: 'OF6M',
    name: 'Neutral Communication',
    slug: 'neutral-communication',
    benefits: ['Precision', 'Writing talent', 'Neutrality'],
    drawbacks: ['Restraint', 'Reticence', 'Distant'],
    video: 'ueks64dhds',
    language: {
      increase: [
        'Keep to the facts',
        'Remove emotion from it',
        'Remain neutral',
        'Meaning of the words',
      ],
      decrease: ['Smiling', 'Head nodding', 'Sparkling eyes', 'Eye contact'],
    },
    description:
      'Your level of sensitivity to focus in on specific words and their meaning and during communication.',
  },
  {
    group: 'environment',
    code: 'OF7P',
    name: 'Group Environment',
    slug: 'group-environment',
    benefits: ['Likability ', 'Open office', 'Growth'],
    drawbacks: ['Loneliness ', 'Remote work', 'Interruption '],
    video: 'ar9hcnsrso',
    language: {
      increase: [
        'Draw on energy of the group',
        'Work with others',
        'Contact with people',
      ],
      decrease: [
        'Own space',
        'Private',
        'Work alone',
        'Without interruptions',
        'No distractions',
      ],
    },
    description:
      'Level of motivation and preference for engaging with and having contact with others within your immediate work space.',
  },
  {
    group: 'environment',
    code: 'OF7M',
    name: 'Solo Environment',
    slug: 'solo-environment',
    benefits: ['Focus', 'Progress', 'Remote Natural'],
    drawbacks: ['Visibility ', 'Social Interaction', 'Adaptability '],
    video: 'hsdg00ga52',
    language: {
      increase: [
        'Own space',
        'Private',
        'Work alone',
        'Without interruptions',
        'No distractions',
      ],
      decrease: [
        'Draw on energy of the group',
        'Work with others',
        'Contact with people',
      ],
    },
    description:
      'Your level of motivation and preference for working alone without other people or social interaction in your immediate work space.',
  },
  {
    group: 'responsibility',
    code: 'OF8P',
    name: 'Sole Responsibility',
    slug: 'sole-responsibility',
    benefits: ['Responsible', 'Autonomous', 'Role Clarity'],
    drawbacks: ['Collaboration', 'Delegation', 'Sharing Info'],
    video: 'ivb4yolhit',
    language: {
      increase: [
        'Be in charge',
        'Sole responsibility',
        'Defined scope',
        'Have ownership for...',
        'Person remit',
        'Accountable for...',
      ],
      decrease: ['Pitch in', 'Share', 'Work together', 'Collaboration', 'Partnership'],
    },
    description:
      'Your level of motivation for clearly defined and assigned roles, scope of tasks or areas that you or others are solely accountable for at work.',
  },
  {
    group: 'responsibility',
    code: 'OF8M',
    name: 'Shared Responsibility',
    slug: 'shared-responsibility',
    benefits: ['Team Player', 'Collaborative Decision-Maker', 'Transparent'],
    drawbacks: ['Autonomous', 'Uncertain', 'Leadership'],
    video: 'l7jplrbj4v',
    language: {
      increase: ['Pitch in', 'Share', 'Work together', 'Collaboration', 'Partnership'],
      decrease: [
        'Be in charge',
        'Sole responsibility',
        'Defined scope',
        'Have ownership for...',
        'Person remit',
        'Accountable for...',
      ],
    },
    description:
      'Level of motivation for your motivation for shared ownership of goals, tasks and projects in business and work.',
  },
  {
    group: 'change',
    code: 'So1',
    name: 'Sameness',
    slug: 'sameness',
    benefits: ['Consistency', 'Reliability', 'Insight'],
    drawbacks: ['Unreactive', 'Rigidity', 'Risk-averse'],
    video: 'vg6599f35q',
    language: {
      increase: [
        'In common',
        'Connections',
        'Same',
        'Similar',
        'Alike',
        'Patterns',
        'Consistency',
        'Stability',
      ],
      decrease: ['Change', "Don't get bored", 'Variety', 'Improvement'],
    },
    description:
      'Your level of interest and energy for keeping things the same including routine, consistency, stability and agreements.',
  },
  {
    group: 'change',
    code: 'So2',
    name: 'Evolution',
    slug: 'evolution',
    benefits: ['Long-term success', 'Accomplishments', 'Reliable'],
    drawbacks: ['Slow to start', 'Hidden genius', 'Prone to tinkering'],
    video: 'v713zdynbw',
    language: {
      increase: [
        'Evolution',
        'Improvement',
        'Innovation',
        'Incremental change',
        'Variety',
        'Similar but different',
        'Momentum',
        'Progressive',
        'Iteration',
      ],
      decrease: [
        'Keep things as they are',
        'Radical change',
        'All or nothing',
        'Perfection',
      ],
    },
    description:
      'Your level of energy for incremental and gradual change and improvement in over time.',
  },
  {
    group: 'change',
    code: 'So3',
    name: 'Difference',
    slug: 'difference',
    benefits: ['Idea machine', 'Kickstarter', 'Quick to react'],
    drawbacks: ['Change for change’s sake', 'Inconsistent', 'Unpredictable'],
    video: 'c2d4o207xo',
    language: {
      increase: [
        'New',
        'Different',
        'Revolutionary',
        'Unique',
        'Start from scratch',
        'Blank canvas',
        'Switch',
        'Flip',
      ],
      decrease: ['Same', 'Gradual', 'Consistent', 'What you know', 'Minor change'],
    },
    description:
      'Level of energy for radical change and things that are new, different and unique.',
  },
  {
    group: 'work-approach',
    code: 'WA1',
    name: 'Use',
    slug: 'use',
    benefits: ['Results', 'Timeliness', 'Boldness'],
    drawbacks: ['Impulse', 'Delegation ', 'Risk'],
    video: 'lvu9vym95e',
    language: {
      increase: [
        'Do',
        'Implement',
        'Get on with',
        'Take action',
        'Implement',
        'Roll up sleeves and get into it',
      ],
      decrease: ['Conceptualize', 'Theorize', 'Plan', 'Organize'],
    },
    description:
      "Your level of desire to just start, get into active 'doing' in new tasks and projects.",
  },
  {
    group: 'work-approach',
    code: 'WA2',
    name: 'Concept',
    slug: 'concept',
    benefits: ['Motivation', 'Presentations', 'Open-mindedness'],
    drawbacks: ['Action', 'Realism ', 'Structure'],
    video: 'ugw7pwe8jx',
    language: {
      increase: [
        'Conceptualize',
        'Explore why',
        'Analyze',
        'Theorize',
        'Thesis',
        'Understand',
        'Philosophy',
      ],
      decrease: ['Act', 'Do', 'Start', 'Plan', 'Organize'],
    },
    description:
      'Your level of energy for conceptualizing, analyzing and theorizing when engaging in projects and tasks.',
  },
  {
    group: 'work-approach',
    code: 'WA3',
    name: 'Structure',
    slug: 'structure',
    benefits: ['Organization ', 'Planning', 'Risk reduction'],
    drawbacks: ['Adaptability', 'Delayed action', 'Frustration'],
    video: 'hx595fq0ad',
    language: {
      increase: [
        'Organize',
        'Plan',
        'Structure',
        'Coordinate',
        'Identify relationship between parts',
      ],
      decrease: ['Conceptualize', 'Thesis', 'Understanding', 'Action', 'Get started'],
    },
    description:
      'Your level of energy and priority on planning, organizing, ordering and establishing a relationship between all the resources and parts of a project or task.',
  },
  {
    group: 'time',
    code: 'TP1',
    name: 'Past',
    slug: 'past',
    benefits: ['Thoughtful decisions', 'Insight', 'Reliability'],
    drawbacks: ['Critical', 'Negative', 'Stuck'],
    video: 'hmztlqxkgh',
    language: {
      increase: [
        'Past',
        'Experience',
        'History',
        'Evidence',
        'Proof',
        'Learn from the past',
      ],
      decrease: ['Present', 'Now', 'Future', 'Long term'],
    },
    description:
      'Level of your desire to use your past experience and learnings and those of others in your work.',
  },
  {
    group: 'time',
    code: 'TP2',
    name: 'Present',
    slug: 'present',
    benefits: ['Realism', 'Competence', 'Reliability'],
    drawbacks: ['Negative', 'Dismissive', 'Short-term thinking'],
    video: '9jyq9k6nwq',
    language: {
      increase: [
        'Here and now',
        'In the moment',
        'What matters today',
        "Let's get real",
        'Be realistic',
      ],
      decrease: ['Past', 'Learn from mistakes', 'Look to the future', 'Long term'],
    },
    description:
      "Your motivation to reference the present moment, the 'here and now' in your work and in business.",
  },
  {
    group: 'time',
    code: 'TP3',
    name: 'Future',
    slug: 'future',
    benefits: ['Inspiring', 'Insightful', 'Planning'],
    drawbacks: ['Misinformed', 'Dreamy', 'Unrealistic'],
    video: 'wtksz8opil',
    language: {
      increase: ['Future', 'Plan', 'Foresight', 'Dream', 'Vision', 'Long term view'],
      decrease: ['Past', 'Experience', 'History', 'Reality'],
    },
    description:
      'Level of future orientation: long term thinking, dreaming about and hoping for the future.',
  },
  {
    group: 'influence',
    code: 'Mo1',
    name: 'Power',
    slug: 'power',
    benefits: ['Leadership', 'Ambition', 'Efficiency '],
    drawbacks: ['Competition', 'Collaboration ', 'Pressure'],
    video: 'kmppsqpvs7',
    language: {
      increase: ['In control', 'Boss', 'Authority', 'Hierarchy', 'In charge', 'Respect'],
      decrease: ['No authority', 'No respect', 'No chain of command'],
    },
    description:
      'Level of motivation to be the person in charge, in a position to influence and have control or resources, people and things.',
  },
  {
    group: 'influence',
    code: 'Mo2',
    name: 'Affiliation',
    slug: 'affiliation',
    benefits: ['Emotional intelligence', 'Personal connection', 'Trust'],
    drawbacks: ['Prioritization', 'Tough decisions ', 'Groupthink'],
    video: '53juitk779',
    language: {
      increase: [
        'Belong',
        'Friendship',
        'In the group',
        'Be a member',
        'Included',
        'Connection',
      ],
      decrease: ['Achievement', 'Position', 'Authority', 'Results'],
    },
    description:
      'Level of energy for bonding, belonging and building personal relationships at work.',
  },
  {
    group: 'influence',
    code: 'Mo3',
    name: 'Achievement',
    slug: 'achievement',
    benefits: ['Results ', 'Confidence ', 'Leadership '],
    drawbacks: ['Avoidance ', 'Obsession ', 'Competition '],
    video: 'e1ivl3vg1i',
    language: {
      increase: [
        'Achieve',
        'Succeed',
        'Challenge',
        'Intelligence',
        'Accomplishments',
        'Recognition',
        'Competition',
      ],
      decrease: ['Connect', 'Bond', 'Authority', 'Control'],
    },
    description:
      'Your level of focus on achieving results, objectives, accomplishment, recognition and rewards.',
  },
  {
    group: 'rules',
    code: 'N1',
    name: 'Assertiveness',
    slug: 'assertiveness',
    benefits: ['Proactive', 'Leader', 'Principled'],
    drawbacks: ['Rigid', 'Black and white', 'Authoritarian'],
    video: 'plqqs35sja',
    language: {
      increase: [
        'Set the standards',
        'Principles',
        'Rules',
        'Rituals',
        'Tell others what to do',
      ],
      decrease: ['Acceptance', 'Do not want to impose', 'Not telling others what to do'],
    },
    description:
      'Level of importance to know the rules and standards and your comfort levels with telling others what is expected of them.',
  },
  {
    group: 'rules',
    code: 'N2',
    name: 'Indifference',
    slug: 'indifference',
    benefits: ['Creativity', 'Groundbreaker', 'Inspiring'],
    drawbacks: ['Rule breaker', 'Incivility', 'Unreliable'],
    video: 'vdtcoaum78',
    language: {
      increase: [
        'Rebel',
        "Don't follow other's rules",
        'There are no rules or boundaries',
        'Beat to your own drum',
      ],
      decrease: ['Care', 'Meet expectations', "Other's customs", "Other's criteria"],
    },
    description:
      "Your comfort level to 'follow the beat of your own drum', including not needing to meet the rules and expectations of others.",
  },
  {
    group: 'rules',
    code: 'N3',
    name: 'Compliance',
    slug: 'compliance',
    benefits: ['Team Player', 'Reliable', 'Diligence'],
    drawbacks: ['Status Quo', 'Miss opportunities', 'Conventional'],
    video: 'bsf56ceec3',
    language: {
      increase: [
        'Comply',
        'Team player',
        'Values driven culture',
        'Adapt and adjust to what is needed',
      ],
      decrease: ['Follow your own standards', 'Determine what is best'],
    },
    description:
      'Your motivation levels for following the rules, values and customs of the company.',
  },
  {
    group: 'rules',
    code: 'N4',
    name: 'Tolerance',
    slug: 'tolerance',
    benefits: ['Accommodating', 'Flexible', 'Compassionate'],
    drawbacks: ['Too forgiving', 'Unwilling to criticize', 'Indecisive'],
    video: 'moqa0x7jep',
    language: {
      increase: [
        'Different strokes for different folks',
        'Tolerate',
        'Respect',
        'Acceptance',
      ],
      decrease: ['Unacceptable', 'Inappropriate', 'Breaking the rules'],
    },
    description:
      'Level of acceptance and appreciation for the unique styles, values and rules of each person including your own.',
  },
  {
    group: 'convincer-input',
    code: 'Co1',
    name: 'Seeing',
    slug: 'seeing',
    benefits: ['Retention', 'Accessibility ', 'Comprehension '],
    drawbacks: ['Concentration', 'Details', 'Distractions'],
    video: 'i67eaytlzp',
    language: {
      increase: ['See', 'Clarity', 'Visualize', 'Vision', 'Looks right'],
      decrease: [
        'Blind',
        'Nothing to show',
        'Cannot see it',
        'Out of sight',
        'Not clear',
      ],
    },
    description:
      'The level of importance for you to see something in order for you to be convinced and make a decision about it.',
  },
  {
    group: 'convincer-input',
    code: 'Co2',
    name: 'Hearing',
    slug: 'hearing',
    benefits: ['Perceptiveness', 'Availability', 'Retention'],
    drawbacks: ['Disruption', 'Reading speed', 'Focus'],
    video: 'xqzvmj93ts',
    language: {
      increase: [
        'Hear',
        'Talk',
        'Sounds like...',
        'Listen',
        'Discuss',
        'Talk through...',
      ],
      decrease: ['Not listening', 'Not hearing', 'No discussion'],
    },
    description:
      'The level of importance for you to hear something in order for you to be convinced and make a decision about it.',
  },
  {
    group: 'convincer-input',
    code: 'Co3',
    name: 'Reading',
    slug: 'reading',
    benefits: ['Remote Work', 'Data', 'Documentation'],
    drawbacks: ['Uncertainty', 'Context', 'Busy Work'],
    video: '6g9kijmot7',
    language: {
      increase: [
        'Read',
        'Go through the documents',
        'See the facts and stats',
        'See the reports',
        'Read about it',
      ],
      decrease: ['No data', 'Ignore the facts', 'Nothing to read', 'No reports'],
    },
    description:
      'Level of importance for you to read something that makes sense to you in order for you to be convinced and make a decision about it.',
  },
  {
    group: 'convincer-input',
    code: 'Co4',
    name: 'Doing',
    slug: 'doing',
    benefits: ['Comprehensiveness', 'Confidence', 'Retention'],
    drawbacks: ['Efficiency', 'Uncertainty', 'Stubborn'],
    video: 'wfwe25tcqy',
    language: {
      increase: [
        'Experience',
        'Gut feel',
        'Do it',
        'Use',
        'Practice',
        'Experiment',
        'Try on',
      ],
      decrease: ['See', 'Hear', 'Make sense of'],
    },
    description:
      'Level of importance for you to experience something that feels right in order for you to be convinced and make a decision about it.',
  },
  {
    group: 'convincer-process',
    code: 'Co5',
    name: 'Examples',
    slug: 'examples',
    benefits: ['Patterns', 'Certainty', 'Consistency'],
    drawbacks: ['Slow decisions', 'Mistrust', 'Intuition'],
    video: 'buozlnza4b',
    language: {
      increase: ['Examples', 'Enough times', 'Samples', 'Proof', 'Evidence'],
      decrease: ['Just one time', 'Automatic', 'Trust'],
    },
    description:
      'Level of importance for you to have multiple examples or exposures to something in order to be convinced about it.',
  },
  {
    group: 'convincer-process',
    code: 'Co6',
    name: 'Automatically',
    slug: 'automatically',
    benefits: ['Quick decisions ', 'Creativity ', 'Relationships'],
    drawbacks: ['Misguided decisions', 'Commitment ', 'Rash '],
    video: 'erdqlc01e1',
    language: {
      increase: [
        'Intuitive',
        'Gut feel',
        'Take for granted',
        'Assume',
        'Trust',
        'Know immediately',
      ],
      decrease: ['Take time', 'Always check', 'Look for multiple examples'],
    },
    description:
      'Your level of comfort to trust or draw a conclusion after just partial or little exposure to something.',
  },
  {
    group: 'convincer-process',
    code: 'Co7',
    name: 'Consistency',
    slug: 'consistency',
    benefits: ['Accuracy ', 'Customer Service', 'Thoroughness '],
    drawbacks: ['Analysis Paralysis', 'Critical ', 'Closed-minded '],
    video: 'sd4vpkm3jc',
    language: {
      increase: [
        'Consistently check',
        'Review again and again',
        'Go over and over',
        'Each time',
        'Never assume',
      ],
      decrease: ['Automatic', 'Just one time', 'Only a couple of examples'],
    },
    description:
      'The level of importance for you to consistently check your conclusions and decisions.',
  },
  {
    group: 'convincer-process',
    code: 'Co8',
    name: 'Period of Time',
    slug: 'period-of-time',
    benefits: ['Information ', 'Consequences', 'Thoughtful decisions'],
    drawbacks: ['Slow decision-making', 'Continuing issues', 'Frustration'],
    video: 'n3ovd81qjj',
    language: {
      increase: [
        'Take time',
        'Enough time',
        'Over time',
        'Take the needed time',
        'What ever time you need',
        'Know in time',
      ],
      decrease: ['Instant decision', 'Decide now', 'Just trust'],
    },
    description:
      'Level of importance for a specific period of time to pass with something in order for you to be convinced.',
  },
  {
    group: 'interest-filters',
    code: 'IF1',
    name: 'People',
    slug: 'people',
    benefits: ['Trust ', 'Emotional intelligence', 'Likeability '],
    drawbacks: ['Difficult decisions', 'Burnout', 'Sacrifice'],
    video: 'gysds8ucdl',
    language: {
      increase: [
        'Who',
        'People',
        "Use people's names",
        "People's feelings",
        'Individuals',
      ],
      decrease: ['Feelings are not important', 'Just another resource'],
    },
    description:
      'Your level of interest to work directly with people and to attend to their inner world of thoughts and feelings.',
  },
  {
    group: 'interest-filters',
    code: 'IF2',
    name: 'Tools',
    slug: 'tools',
    benefits: ['Productivity ', 'Early adoption ', 'Objectivity '],
    drawbacks: ['Shiny object syndrome', 'Availability ', 'Dependency '],
    video: 'f8pcbwm910',
    language: {
      increase: ['Gadgets', 'Tools', 'Lists', 'Technology', 'Apps'],
      decrease: ['No tools', 'Low tech'],
    },
    description:
      'Your level of interest in instruments, apps, technology and other tools that assist you or your team in their work.',
  },
  {
    group: 'interest-filters',
    code: 'IF3',
    name: 'Systems',
    slug: 'systems',
    benefits: ['Problem solving', 'Vision', 'Optimization'],
    drawbacks: ['Overwhelm', 'Creativity', 'Efficiency'],
    video: 'd2dsw0lde0',
    language: {
      increase: [
        'Holistic',
        'Systemic',
        'Systems',
        'Interconnections',
        'Interrelationships',
      ],
      decrease: ['Disconnected', 'Separate', 'Individuated', 'Disparate'],
    },
    description:
      'Your level of interest in interrelationships that are found within systems.',
  },
  {
    group: 'interest-filters',
    code: 'IF4',
    name: 'Information',
    slug: 'information',
    benefits: ['Thorough understanding', 'Thoughtful decisions ', 'Trust '],
    drawbacks: ['Overwhelm ', 'Accessibility ', 'Innovation'],
    video: 'bm5xbmhjgc',
    language: {
      increase: ['Facts', 'Knowledge', 'Stats', 'Data', 'Latest information'],
      decrease: ['No data', 'Out of date', 'Missing facts'],
    },
    description:
      'Your level of interest in accumulating more information, fact, stats, data and knowledge.',
  },
  {
    group: 'interest-filters',
    code: 'IF5',
    name: 'Money',
    slug: 'money',
    benefits: ['Planning', 'Decision-making', 'Entrepreneurship'],
    drawbacks: ['Passion', 'Risk', 'Tunnel vision'],
    video: '40mrhzznj7',
    language: {
      increase: [
        'Commercial case',
        'Financial model',
        'Monetization',
        'Margins',
        'Profit + Loss',
      ],
      decrease: ['No budget', 'No tracking', 'No revenue model'],
    },
    description:
      'Your level of interest to work with money, finances and the commercial side of products, services and business.',
  },
  {
    group: 'interest-filters',
    code: 'IF6',
    name: 'Place',
    slug: 'place',
    benefits: ['Comfort', 'Communication', 'Awareness'],
    drawbacks: ['Inflexibility ', 'Distraction ', 'Prestige'],
    video: 'ip110rvncj',
    language: {
      increase: ['Framing', 'Placement', 'Position', 'Location'],
      decrease: [
        'Anywhere',
        'No desk for you',
        'No clear position',
        'Location does not matter',
      ],
    },
    description:
      'Your level of interest in where you are personally located in your work; physically in geography and also conceptually in society or an organization.',
  },
  {
    group: 'interest-filters',
    code: 'IF7',
    name: 'Time',
    slug: 'time',
    benefits: ['Punctuality ', 'Efficient meetings ', 'Boundaries '],
    drawbacks: ['Stress', 'Impatience', 'Follow-through'],
    video: 'd8fx5ctnzx',
    language: {
      increase: [
        'When',
        'Minutes',
        'Schedules',
        'Start on time',
        'Meet the deadline',
        'Not wasting time',
      ],
      decrease: ['Whenever', 'Later', 'Wasting time', 'Late'],
    },
    description:
      'Your level of focus on when something will occur, schedules and allotting of time.',
  },
  {
    group: 'interest-filters',
    code: 'IF8',
    name: 'Activity',
    slug: 'activity',
    benefits: ['Productivity ', 'Creativity ', 'Mindfulness'],
    drawbacks: ['Burnout ', 'Perspective ', 'Frustration '],
    video: '7er9s7dp4z',
    language: {
      increase: ['Busy', 'Active', 'Working on goals', 'Not being idle'],
      decrease: ['Idle', 'Dormant', 'Inactive', 'Sluggish', 'Lazy'],
    },
    description:
      'Your level of motivation to keep active and keep busy at work and in business.',
  },
] as const satisfies readonly MotivationInfo[];
export const motivationCodes = Object.keys(motivationDefinitions);

export const motivationGroupDefinitions = [
  {
    name: 'Action Level',
    slug: 'action-level',
    order: 0,
    motivations: ['OF1P', 'OF1M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your preference for quickly starting new projects',
  },
  {
    name: 'Action Direction',
    slug: 'action-direction',
    order: 1,
    motivations: ['OF2P', 'OF2M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your interest in achieving goals vs finding problems',
  },
  {
    name: 'Authority',
    slug: 'authority',
    order: 2,
    motivations: ['OF3P', 'OF3M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your preference for following your gut feel vs seeking others opinions',
  },
  {
    name: 'Task Direction',
    slug: 'task-direction',
    order: 3,
    motivations: ['OF4P', 'OF4M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your preference for following procedures vs finding alternatives',
  },
  {
    name: 'Scope',
    slug: 'scope',
    order: 4,
    motivations: ['OF5P', 'OF5M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your interest in big picture thinking vs delving into details',
  },
  {
    name: 'Communication',
    slug: 'communication',
    order: 5,
    motivations: ['OF6P', 'OF6M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'How much you focus on tone of voice vs choosing the right words',
  },
  {
    name: 'Environment',
    slug: 'environment',
    order: 6,
    motivations: ['OF7P', 'OF7M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your preference for being around people vs working alone',
  },
  {
    name: 'Responsibility',
    slug: 'responsibility',
    order: 7,
    motivations: ['OF8P', 'OF8M'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning:
      'Your interest in solo accountability vs sharing responsibility within the team',
  },
  {
    name: 'Change',
    slug: 'change',
    order: 8,
    motivations: ['So1', 'So2', 'So3'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your approach to change in your work',
  },
  {
    name: 'Work Approach',
    slug: 'work-approach',
    order: 9,
    motivations: ['WA1', 'WA2', 'WA3'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your approach to work analysis organization or just do it',
  },
  {
    name: 'Time',
    slug: 'time',
    order: 10,
    motivations: ['TP1', 'TP2', 'TP3'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: false,
    meaning: 'Your period of time preference past present or future',
  },
  {
    name: 'Influence',
    slug: 'influence',
    order: 11,
    motivations: ['Mo1', 'Mo2', 'Mo3'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'Your preference for influencing others',
  },
  {
    name: 'Rules',
    slug: 'rules',
    order: 12,
    motivations: ['N1', 'N2', 'N3', 'N4'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'The way you deal with rules in the workplace',
  },
  {
    name: 'Convincer Input',
    slug: 'convincer-input',
    order: 13,
    motivations: ['Co1', 'Co2', 'Co3', 'Co4'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'The way you are convinced about something new',
  },
  {
    name: 'Convincer Process',
    slug: 'convincer-process',
    order: 14,
    motivations: ['Co5', 'Co6', 'Co7', 'Co8'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 16,
    consistent: true,
    meaning: 'The way you make a decision',
  },
  {
    name: 'Interest Filters',
    slug: 'interest-filters',
    order: 15,
    motivations: ['IF1', 'IF2', 'IF3', 'IF4', 'IF5', 'IF6', 'IF7', 'IF8'].flatMap(
      (code) => motivationDefinitions.find((m) => m.code === code) ?? [],
    ),
    scoreIntervals: 20,
    consistent: false,
    meaning: 'The aspects of work you enjoy the most',
  },
] as const satisfies readonly MotivationGroup[];

export const motivationGroupSlugs = motivationGroupDefinitions.map((g) => g.slug);
