import { useCallback, useEffect, useState, type FC } from 'react';
import {
  isRouteErrorResponse,
  useRouteError,
  type ErrorResponse,
} from 'react-router-dom';

import { Button } from '@f4s/ui';

import { Page } from '@/components/page';
import { PageHeader } from '@/components/page-header';
import { clearCache } from '@/lib/cache-clear';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

export const ErrorPage: FC = () => {
  const { workspace, pathname } = useMatchedWorkspace();
  const error = useRouteError();
  const handleRefresh = useCallback(() => {
    const redirectTo = import.meta.env.BASE_URL + (workspace ? pathname : '/');
    clearCache(redirectTo).catch((error_) => {
      console.error('Error calling clearCache', error_);
      location.replace(redirectTo);
    });
  }, [pathname, workspace]);

  const [routeError, setRouteError] = useState<ErrorResponse | undefined>();
  useEffect(() => {
    console.error('Error boundary shown', error);
    if (isRouteErrorResponse(error)) {
      setRouteError(error);
    }
  }, [error]);

  const [timeout, setTimeout] = useState<number>(5);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout((previous) => {
        if (previous === 1) {
          handleRefresh();
        }
        return previous - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [handleRefresh]);

  return (
    <Page>
      <PageHeader title="Oops! Unexpected Error" />
      <p>
        An error occurred while loading the page, we will attempt to automatically refresh
        the page in {timeout} seconds...
      </p>
      <Button onClick={handleRefresh}>Refresh now</Button>
      <p>{routeError?.statusText}</p>
    </Page>
  );
};
