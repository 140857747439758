import type { RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { inviteWorkspaceAccess, workspaceAccessAction } from './actions';
import { accessListLoader, accessLoader } from './loaders';

export const workspaceAccessRoutes: RouteObject = {
  path: 'access',
  handle: handle({ type: 'access', breadcrumb: SimpleBreadcrumb({ title: 'Access' }) }),
  children: [
    {
      index: true,
      loader: accessListLoader,
      action: inviteWorkspaceAccess,
      async lazy() {
        const { WorkspaceAccessList } = await import('./pages/list');
        return { Component: WorkspaceAccessList };
      },
    },
    {
      path: ':accessId',
      loader: accessLoader,
      action: workspaceAccessAction,
      async lazy() {
        const { WorkspaceAccessPage } = await import('./pages/page');
        return { Component: WorkspaceAccessPage };
      },
    },
  ],
};
