import { type RouteObject } from 'react-router-dom';

import { handle } from '@/components/breadcrumbs';

import { legacyGroupLoader, legacyGroupsLoader } from './loaders';
import { GroupBreadcrumb, GroupsBreadcrumb } from './pages/handle';

export const legacyGroupRoutes: RouteObject = {
  path: 'teams',
  handle: handle({
    type: 'groups',
    breadcrumb: GroupsBreadcrumb,
  }),
  loader: legacyGroupsLoader,
  async lazy() {
    const { LegacyGroupsList } = await import('./pages/list');
    return { Component: LegacyGroupsList };
  },
  children: [
    {
      path: ':groupId',
      handle: handle({ type: 'group', breadcrumb: GroupBreadcrumb }),
      async lazy() {
        const { LegacyGroupPage } = await import('./pages/page');
        return { Component: LegacyGroupPage };
      },
      loader: legacyGroupLoader,
    },
  ],
};
