import { useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import { personalConnectionsQuery } from '../_legacy/connections/queries';
import { accessListQuery } from '../workspace-access/queries';
import { getWorkspaceFromParams, workspaceQuery } from '../workspace/queries';
import { requestsListQuery } from './queries';

export async function workspaceRequestAction({ request, params }: ActionFunctionArgs) {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw new Error('Workspace does not exist');
  const { requestId } = z.object({ requestId: z.coerce.number() }).parse(params);

  if (request.method === 'POST') {
    // Accept the request entry
    const res = await apiClient.post(
      `/api/v4/workspaces/${workspace.id}/requests/${requestId}`,
      {},
    );

    // Invalidate the requests list
    await queryClient.invalidateQueries(requestsListQuery(workspace.id));
    // On approval we need to invalidate the workspace membership as well
    await queryClient.invalidateQueries(accessListQuery(workspace.id));
    // TODO: create a utility for membership change invalidations
    await queryClient.invalidateQueries(workspaceQuery(workspace.slug));
    await queryClient.invalidateQueries({
      queryKey: ['connections', 'members', workspace.slug],
    });
    // Also clear the legacy connected users query cache, to update the 'mutual spaces'
    await queryClient.invalidateQueries(personalConnectionsQuery);

    return res;
  }

  if (request.method === 'DELETE') {
    // Delete the request entry
    const res = await apiClient.delete(
      `/api/v4/workspaces/${workspace.id}/requests/${requestId}`,
    );
    // Invalidate the requests list
    await queryClient.invalidateQueries(requestsListQuery(workspace.id));
    return res;
  }

  return null;
}

export const useDeclineWorkspaceRequest = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}) => {
  const submit = useSubmit();
  return (requestId: number) =>
    submit(null, {
      action: `/spaces/${workspaceSlug}/people/requests/${requestId}`,
      method: 'DELETE',
      encType: 'application/json',
      navigate: false,
    });
};

export const useApproveWorkspaceRequest = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}) => {
  const submit = useSubmit();
  return (requestId: number) =>
    submit(null, {
      action: `/spaces/${workspaceSlug}/people/requests/${requestId}`,
      method: 'POST',
      encType: 'application/json',
      navigate: false,
    });
};
