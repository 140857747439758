import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import {
  cn,
  Divider,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  useMediaQuery,
  type ButtonProps,
} from '@f4s/ui';

import { NavLinkButton } from './nav-link';
import { ScrollContainer } from './scroll-container';

export type DropdownDrawerItemProps = {
  key: number | string;
  linkTo?: string;
  onClick?: () => void;
  asChild?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  variant?: ButtonProps['variant'];
  group?: string;
};

export const DropdownDrawer = ({
  children,
  content,
  items,
  asChild,
  className,
  triggerClassName,
  dropdownClassName,
  linkClassName,
  dropdownAlign,
  drawerClassName,
  open: externalOpen,
  onOpenChange,
}: {
  children?: ReactNode;
  content?: ReactNode;
  items: DropdownDrawerItemProps[];
  asChild?: boolean;
  className?: string;
  triggerClassName?: string;
  dropdownClassName?: string;
  dropdownAlign?: 'start' | 'center' | 'end';
  drawerClassName?: string;
  linkClassName?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  const [open, setOpen] = useState<boolean>(externalOpen ?? false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (externalOpen != undefined) {
      setOpen(externalOpen);
    }
  }, [externalOpen]);

  useEffect(() => {
    onOpenChange?.(open);
  }, [onOpenChange, open]);

  const prepareGroupItems = useCallback((gi: DropdownDrawerItemProps[]) => {
    // First, filter out any items without a group
    const validItems = gi.filter((item) => item !== null && item !== undefined);

    const groups = validItems.reduce(
      (acc, item) => {
        const group = item.group || 'default';
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(item);
        return acc;
      },
      {} as Record<string, DropdownDrawerItemProps[]>,
    );

    // Only return groups that actually have items
    return Object.entries(groups).filter(([_, groupItems]) => groupItems.length > 0);
  }, []);

  const { dropdownItems, drawerItems } = useMemo(
    () => ({
      dropdownItems: isMobile
        ? []
        : prepareGroupItems(items).map(([group, groupItems], groupIndex) => (
            <Fragment key={group}>
              {groupIndex > 0 && <DropdownMenuSeparator />}
              <DropdownMenuGroup>
                {/* {group !== 'default' && (
                  <DropdownMenuLabel className="text-muted-foreground px-2 text-xs font-medium">
                    {group}
                  </DropdownMenuLabel>
                )} */}
                {groupItems.map((item) => (
                  <DropdownMenuItem
                    key={item.key}
                    asChild
                    disabled={item.disabled}
                    className="h-auto"
                  >
                    {item.asChild ? (
                      item.children
                    ) : (
                      <NavLinkButton
                        variant={item.variant ?? 'secondary'}
                        className={cn(
                          'h-auto flex-col gap-2 overflow-visible whitespace-normal px-2 py-1.5 focus-visible:ring-0 data-[disabled]:opacity-100',
                          linkClassName,
                          item.variant ? '' : 'mb-3',
                        )}
                        linkClassName="opacity-100"
                        to={item.linkTo ?? '#'}
                        disabled={item.disabled}
                        onClick={item.onClick}
                      >
                        {item.children}
                      </NavLinkButton>
                    )}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuGroup>
            </Fragment>
          )),
      drawerItems: isMobile
        ? prepareGroupItems(items).map(([group, groupItems], groupIndex) => (
            <Fragment key={group}>
              {groupIndex > 0 && <Divider className="mx-2 my-2 w-auto" />}
              {/* {group !== 'default' && (
                <div className="text-muted-foreground px-2 py-1.5 text-xs font-medium">
                  {group}
                </div>
              )} */}
              {groupItems.map((item) => (
                <DrawerClose key={item.key} asChild disabled={item.disabled}>
                  {item.asChild ? (
                    item.children
                  ) : (
                    <NavLinkButton
                      variant={item.variant ?? 'secondary'}
                      className={cn(
                        'h-auto flex-col gap-2 overflow-visible whitespace-normal px-2 py-1.5 data-[disabled]:opacity-50',
                        linkClassName,
                        item.variant ? '' : 'mb-3',
                      )}
                      linkClassName="opacity-100"
                      to={item.linkTo ?? '#'}
                      disabled={item.disabled}
                      onClick={item.onClick}
                    >
                      {item.children}
                    </NavLinkButton>
                  )}
                </DrawerClose>
              ))}
            </Fragment>
          ))
        : [],
    }),
    [isMobile, items, linkClassName, prepareGroupItems],
  );

  if (isMobile) {
    // Use a drawer
    return (
      <Drawer open={open} onOpenChange={setOpen} noBodyStyles handleOnly>
        <DrawerTrigger asChild={asChild} className={triggerClassName}>
          {children}
        </DrawerTrigger>
        <DrawerContent className="mt-24 max-h-[calc(100%_-_6rem)] flex-col overflow-hidden">
          <ScrollContainer>
            <div
              className={cn(
                'mx-auto flex w-full flex-col gap-0 px-3 pb-3 pt-6',
                className,
                drawerClassName,
              )}
            >
              {content}
              <Divider className="mx-2 my-2 w-auto" />
              {drawerItems}
            </div>
          </ScrollContainer>
        </DrawerContent>
      </Drawer>
    );
  }
  // Use a dropdown
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild={asChild} className={triggerClassName}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          className={cn(
            'bg-popover text-popover-foreground ring-border/5 flex max-h-[calc(var(--radix-popper-available-height)_-_16px)] min-w-60 flex-col gap-3 overflow-auto ring-1',
            className,
            dropdownClassName,
          )}
          align={dropdownAlign}
        >
          {content && (
            <>
              <DropdownMenuGroup>{content}</DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuGroup>{dropdownItems}</DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};
