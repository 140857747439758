import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { getWorkspaceFromParams } from '../workspace/queries';
import { fetchSamlList, fetchScimList } from './queries';

export const samlListLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');

  const samlList = await fetchSamlList(workspace.id);
  return { workspace, samlList };
};

export const samlLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  const { samlId } = z.object({ samlId: z.coerce.number() }).parse(params);
  const samlList = await fetchSamlList(workspace.id);
  const saml = samlList.find((a) => a.id === samlId);
  if (!saml) return redirect('..');

  return { workspace, saml };
};

export const samlNewLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  return { workspace, saml: null };
};

export const scimListLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');

  const scimList = await fetchScimList(workspace.id);
  return { workspace, scimList };
};

export const scimLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  const { scimId } = z.object({ scimId: z.string() }).parse(params);
  const scimList = await fetchScimList(workspace.id);
  const scim = scimList.find((a) => a.id === scimId);
  if (!scim) return redirect('..');

  return { workspace, scim };
};

export const scimNewLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  return { workspace, scim: null };
};
