import { fetchPendingInvites, fetchPendingSentInvites } from '../invite/queries';
import { fetchNotifications } from './queries';

export const notificationsLoader = async () => {
  const pendingInvites = await fetchPendingInvites();
  const pendingSentInvites = await fetchPendingSentInvites();
  const notifications = await fetchNotifications();

  const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const unseen = notifications.filter(({ readAt }) => !readAt);
  const thisWeek = notifications.filter(
    ({ readAt, createdAt }) => readAt && new Date(Date.parse(createdAt)) >= lastWeek,
  );
  const earlier = notifications.filter(
    ({ readAt, createdAt }) => readAt && new Date(Date.parse(createdAt)) < lastWeek,
  );

  return {
    pendingInvites,
    pendingSentInvites,
    unseen,
    thisWeek,
    earlier,
  };
};

export type NotificationsLoaderData = Exclude<
  Awaited<ReturnType<typeof notificationsLoader>>,
  Promise<Response> | Response
>;
