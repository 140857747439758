import { type ReactNode } from 'react';

import { cn } from '@f4s/ui';

export const Page = ({
  children,
  className,
}: {
  children?: ReactNode | ReactNode[];
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'relative flex h-full min-h-0 w-full flex-1 flex-col gap-4 pt-4 lg:pt-5',
        className,
      )}
    >
      {children}
    </div>
  );
};
