import React, { type MouseEvent, type ReactNode } from 'react';

import { Card, Icon } from '@f4s/ui';

import { NavLink, NavLinkButton } from '@/components/nav-link';
import { Page } from '@/components/page';
import { PageHeader, PageHeaderTitle } from '@/components/page-header';
import { Section } from '@/components/section';
import { SectionHeader } from '@/components/section-header';
import { useDataFromLoader } from '@/lib/hooks/loader';
import { DashboardPreviewGrid } from '@/modules/dashboard/components/dashboards/previews';
import { MemberGrid } from '@/modules/member/components/member-grid';
import { OnboardingCarousel } from '@/modules/workspace/components/onboarding-carousel';

import { useMatchedWorkspace } from '../hooks/use-workspace-match';
import { type WorkspaceLandingLoaderData } from '../loaders';

export const WorkspaceLandingPage = ({ children }: { children?: ReactNode }) => {
  const { progress, jtbdQuery } = useDataFromLoader<WorkspaceLandingLoaderData>();
  const { pathname } = useMatchedWorkspace();
  const { workspace } = useMatchedWorkspace();

  return (
    <Page>
      <Card className="from-background/60 to-card flex flex-col gap-6 bg-gradient-to-b p-6">
        <PageHeader
          title={
            <PageHeaderTitle
              className="!overflow-visible !whitespace-normal"
              title={`Welcome to ${workspace?.name}!`}
            />
          }
          description="This is a shared space where you can organize and understand yourself and other people."
        >
          {progress.assessment < 1 && (
            <NavLinkButton variant="secondary" to="./assessment">
              Complete the motivation analysis
              <Icon.ArrowRight />
            </NavLinkButton>
          )}
        </PageHeader>
        <OnboardingCarousel progress={progress} jtbdQuery={jtbdQuery} />
      </Card>

      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <Section className="flex flex-col">
          <SectionHeader
            title="Members"
            actions={
              <NavLinkButton variant="link" size="icon" to={`${pathname}/people/members`}>
                <Icon.ArrowRight />
              </NavLinkButton>
            }
          />
          <MemberGrid excludeMe={true} />
        </Section>
        <Section className="flex flex-col">
          <SectionHeader
            title="Recent Boards"
            actions={
              <NavLinkButton variant="link" size="icon" to={`${pathname}/boards`}>
                <Icon.ArrowRight />
              </NavLinkButton>
            }
          />
          <DashboardPreviewGrid exactly={4} />
        </Section>
      </div>
      {children}
    </Page>
  );
};

export const LandingGuideListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    progress?: number;
    isDone?: boolean;
    linkTo: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
    copy: string;
    completeCopy?: string;
  }
>(({ progress, isDone, linkTo, onClick, copy, completeCopy }, ref) => {
  return (
    <li>
      <NavLink
        className={`hover:before:bg-palette-500/5 relative flex w-full items-center gap-x-3 gap-y-2 py-3 hover:before:absolute hover:before:-left-2 hover:before:-right-2 hover:before:-top-0 hover:before:z-0 hover:before:h-full hover:before:rounded-lg ${
          isDone ? 'text-palette-800/60' : 'text-palette-800'
        }`}
        to={linkTo}
        onClick={onClick}
        ref={ref}
      >
        {isDone ? (
          <>
            <Icon.CheckCircle weight="duotone" size={18} />
            <div className="line-through">{completeCopy ?? copy}</div>
          </>
        ) : (
          <>
            <Icon.ArrowRight weight="duotone" size={18} />
            <div className="flex-1">{copy}</div>
            {progress ? (
              <span className="bg-palette-600 text-palette-100 rounded-full px-2 py-0.5 text-xs">
                {Math.floor(progress * 100)}%
              </span>
            ) : null}
          </>
        )}
      </NavLink>
    </li>
  );
});
LandingGuideListItem.displayName = 'LandingGuideListItem';
