import { useCallback, useEffect, useState, type FC } from 'react';

import {
  DataGrid,
  DataGridHeader,
  DataGridHeaderItem,
  DataGridItem,
  DataGridRow,
  Icon,
  Input,
} from '@f4s/ui';

import { useTalentPoolList } from '../components/talentpool-list';

import { NavLinkButton } from '@/components/nav-link';
import { Page } from '@/components/page';
import { PageHeader } from '@/components/page-header';
import { useDataFromLoader } from '@/lib/hooks/loader';

import type { talentPoolsLoader } from '../loaders';
import type { TalentPool } from '../queries';

export const TalentPoolList: FC = () => {
  const { talentPools } = useDataFromLoader<typeof talentPoolsLoader>();
  const { list, filter } = useTalentPoolList({ talentPools });

  return (
    <Page>
      <PageHeader title="Talent Pools">
        <div className="flex gap-2">
          {filter}
        </div>
      </PageHeader>
      <div className="relative min-h-[50vh] flex-1">
        {list}
      </div>
    </Page>
  );
};

export const TalentPoolWorkspaceList: FC = () => {
  const { talentPools, workspaceId } = useDataFromLoader<typeof talentPoolsLoader>();
  const [filteredPools, setFilteredPools] = useState(talentPools);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    if (filter) {
      setFilteredPools(
        talentPools.filter((p) => p.name.toLowerCase().includes(filter.toLowerCase())),
      );
    } else {
      setFilteredPools(talentPools);
    }
  }, [filter, talentPools]);

  const renderRow = useCallback((row: TalentPool) => {
    return (
      <DataGridRow asChild>
        <NavLinkButton
          className="relative justify-start gap-0 rounded-none px-4"
          variant="ghost"
          to={String(row.externalId)}
        >
          <DataGridItem>{row.name}</DataGridItem>
          <DataGridItem>{row.hasJoined ? 'Joined' : null}</DataGridItem>
        </NavLinkButton>
      </DataGridRow>
    );
  }, []);

  return (
    <Page>
      <PageHeader title="Talent Pools">
        <div className="flex gap-2">
          <Input
            placeholder="Search pools"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            className="max-w-60"
            icon={<Icon.MagnifyingGlass size={18} />}
          />
          {workspaceId !== undefined && <NavLinkButton to="new">Create</NavLinkButton>}
        </div>
      </PageHeader>
      <div className="relative min-h-[50vh] flex-1">
        <DataGrid
          rowHeight={48}
          rows={filteredPools ?? []}
          className="min-h-0 grid-cols-[minmax(auto,1fr)_minmax(auto,1fr)_minmax(auto,1fr)]"
          rowHeader={
            <DataGridHeader>
              <DataGridHeaderItem>Name</DataGridHeaderItem>
              <DataGridHeaderItem>Joined</DataGridHeaderItem>
            </DataGridHeader>
          }
          rowRender={renderRow}
        />
      </div>
    </Page>
  );
};
