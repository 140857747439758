export const cultures = [
  {
    code: 'AU',
    name: 'Australia',
  },
  {
    code: 'BE',
    name: 'Belgium',
  },
  {
    code: 'CA',
    name: 'Canada',
  },
  {
    code: 'CN',
    name: 'China',
  },
  {
    code: 'FR',
    name: 'France',
  },
  {
    code: 'IN',
    name: 'India',
  },
  {
    code: 'IT',
    name: 'Italy',
  },
  {
    code: 'JP',
    name: 'Japan',
  },
  {
    code: 'KR',
    name: 'Korea',
  },
  {
    code: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'NL',
    name: 'Netherland',
  },
  {
    code: 'PH',
    name: 'Philippines',
  },
  {
    code: 'RU',
    name: 'Russia',
  },
  {
    code: 'SG',
    name: 'Singapore',
  },
  {
    code: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'TH',
    name: 'Thailand',
  },
  {
    code: 'GB',
    name: 'UK',
  },
  {
    code: 'US',
    name: 'USA',
  },
] as const;
