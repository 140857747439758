import React, { useMemo, type ReactNode } from 'react';
import { NavLink, useMatches, type UIMatch } from 'react-router-dom';

import { Icon } from '@f4s/ui';

import type { ComponentData } from '@/lib/hooks/loader';

export type BreadcrumbMatch<T> = UIMatch<ComponentData<T>, typeof handle>;

export type BreadcrumbProps<T> = {
  pathname: string;
  data: ComponentData<T>;
};
export type Breadcrumb<T> = ({ pathname, data }: BreadcrumbProps<T>) => ReactNode;

export type HandleProps<T> = {
  type?: string;
  breadcrumb?: Breadcrumb<T>;
};
export const handle = <T,>({ type, breadcrumb }: HandleProps<T>) => ({
  type,
  breadcrumb,
});

export const SimpleBreadcrumb = ({ title }: { title: string }) =>
  function SimpleBreadcrumbInner({ pathname }: BreadcrumbProps<unknown>) {
    return (
      <NavLink to={pathname} className="truncate">
        {title}
      </NavLink>
    );
  };

export const Breadcrumbs = React.memo(() => {
  const matches = useMatches();

  const matchesWithCrumbs = useMemo(() => {
    return matches.flatMap((m) => {
      if (m.handle !== null && typeof m.handle === 'object' && 'breadcrumb' in m.handle) {
        return m as UIMatch<ComponentData<unknown>, HandleProps<unknown>>;
      }
      return [];
    });
  }, [matches]);

  return matchesWithCrumbs.flatMap((match, index, arr) => {
    if (!match.handle.breadcrumb) {
      return [];
    }

    const crumb = (
      <match.handle.breadcrumb
        key={match.pathname}
        pathname={match.pathname.replace(/\/$/, '')}
        data={match.data}
      />
    );
    return index < arr.length - 1
      ? [
          crumb,
          <Icon.CaretRight
            className="text-muted-foreground"
            key={match.pathname + 'separator'}
          />,
        ]
      : [crumb];
  });
});
Breadcrumbs.displayName = 'Breadcrumbs';
