import { apiClient, queryClient, type User } from '@f4s/api-client';

import { analyticsSetUser } from '@/lib/analytics';
import { updateFlagContext } from '@/providers/feature-flags';
import { setLocalPreference } from '@/providers/local-preference';

import { fetchMagicLinkByToken } from '../invite/queries';
import {
  clearInviteToken,
  clearJoinRequestSpaceId,
  clearMagicToken,
  getInviteToken,
  getJoinRequestSpaceId,
  getMagicToken,
  type Invitation,
} from '../invites/queries';
import { WORKSPACE_LOCAL_PREFERENCE_NAME } from '../workspace/components/workspace-switcher';
import { workspacesQuery } from '../workspace/queries';
import { clearPreAuthData } from './utils';

export const postLoginActions = async (user: User) => {
  console.log('Running post-login actions');
  analyticsSetUser(user);
  updateFlagContext({
    userId: String(user.id),
    properties: { emailAddress: user.emailAddress },
  }).catch((error) => console.warn('Error fetching personalized feature flags', error));

  // Handle post-login actions

  let acceptedSpaceInvite = false;
  // Direct invites
  try {
    const inviteToken = getInviteToken();
    if (inviteToken) {
      // Get the invite by token;
      const invite = (await apiClient.get(
        `/api/v4/invitations/token/${inviteToken}`,
      )) as Invitation | null;

      if (invite) {
        console.log('Accepting direct invite');
        // Accept the invite
        await apiClient.get(`/api/v4/invitations/token/${inviteToken}/accept`);
        clearInviteToken();

        if (invite?.organizations) {
          // Set the space to redirect to on onboarding completion
          setLocalPreference<number>({
            preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
            value: invite.organizations.id,
          });
          acceptedSpaceInvite = true;
        }
      }
    }
  } catch (error) {
    console.error('There was an error accepting a direct invite on login', error);
  }

  // Magic link invites
  try {
    const magicToken = getMagicToken();
    if (magicToken) {
      const link = await fetchMagicLinkByToken({ token: magicToken });
      if (link) {
        console.log('Accepting magic link invite');
        // Accept the invite
        await apiClient.post(`/api/v3/magic-links/${magicToken}/accept`, {});
        clearMagicToken();

        if (link.organizations) {
          // Set the space to redirect to on onboarding completion
          setLocalPreference<number>({
            preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
            value: link.organizations.id,
          });
          acceptedSpaceInvite = true;
        }
      }
    }
  } catch (error) {
    console.error('There was an error accepting a magic link invite on login', error);
  }

  // Space join request
  try {
    const joinRequestSpaceId = getJoinRequestSpaceId();
    if (joinRequestSpaceId) {
      // Create a join request, currently only supports space member access.
      await apiClient.post(`/api/v4/workspaces/requests/${joinRequestSpaceId}`, {});
    }
    clearJoinRequestSpaceId();
  } catch (error) {
    console.error('There was an error accepting a magic link invite on login', error);
  }

  if (acceptedSpaceInvite) {
    // For good measure, clear the workspaces cache.
    await queryClient.invalidateQueries(workspacesQuery);
  }
};

export const postLogoutActions = async () => {
  // Clear out some localStorage
  sessionStorage.clear();
  clearPreAuthData();
  localStorage.removeItem('assessmentBlockData');
  clearInviteToken();
  clearMagicToken();
  analyticsSetUser(null);
};
