import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import type { ReportCreateRequestData } from '@f4s/types';
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  cn,
  Icon,
  palette,
  type colors,
} from '@f4s/ui';

import AskAboutYourTeam from '@/assets/ask-about-your-team.png';
import AskAboutYourself from '@/assets/ask-about-yourself.png';
import CompareWithOthers from '@/assets/compare-with-others.png';
import CompleteYourMigration from '@/assets/complete-your-migration.png';
import GrowYourSpace from '@/assets/grow-your-space.png';
import SeeYourResults from '@/assets/see-your-results.png';
import { PromptButton } from '@/components/prompt-button';
import { useShowMigrationHub } from '@/modules/hubs/components/migrate-buttons';
import type { Query } from '@/modules/queries/queries';
import { useReportCreateAction } from '@/modules/report/actions';
import { InviteModal } from '@/modules/workspace/components/workspace-invite-modal';

import { type WorkspaceLandingLoaderData } from '../loaders';

const cards: {
  badge: string;
  icon: React.ReactElement;
  title: string;
  description?: string;
  completed?: (progress: WorkspaceLandingLoaderData['progress']) => boolean;
  linkTo?: string | ((progress: WorkspaceLandingLoaderData['progress']) => string);
  image?: string;
  color: keyof typeof colors;
}[] = [
  {
    badge: 'Migrate',
    icon: <Icon.Queue size={18} weight="duotone" />,
    title: 'Complete your migration',
    description: 'Migrate your connections to this space.',
    linkTo: '/migrate/',
    image: CompleteYourMigration,
    color: 'elderberry',
  },
  {
    badge: 'Board',
    icon: <Icon.SquaresFour size={18} weight="duotone" />,
    title: 'See your results',
    description: 'Understand your results and how to improve your mental health.',
    completed: (progress) => progress.dashboardMyResults === 1,
    linkTo: (progress) =>
      progress.dashboardMyResults === 1
        ? './boards/' + progress.dashboardMyResultsId
        : './boards/templates/individual-results',
    image: SeeYourResults,
    color: 'action-direction',
  },
  {
    badge: 'Query',
    icon: <Icon.Sparkles size={18} weight="duotone" />,
    title: 'Ask about yourself',
    description: 'Ask Marlee about your results',
    completed: (progress) => progress.asked === 1,
    linkTo: './queries',
    image: AskAboutYourself,
    color: 'scope',
  },
  {
    badge: 'Board',
    icon: <Icon.SquaresFour size={18} weight="duotone" />,
    title: 'Compare with others',
    description: '',
    completed: (progress) => progress.dashboardTeamCulture === 1,
    linkTo: (progress) =>
      progress.dashboardTeamCulture === 1
        ? './boards/' + progress.dashboardTeamCultureId
        : './boards/templates/team-culture',
    image: CompareWithOthers,
    color: 'action-direction',
  },
  {
    badge: 'Query',
    icon: <Icon.Sparkles size={18} weight="duotone" />,
    title: 'Ask about your team',
    description:
      'Complete the assessment to unlock Marlee and learn to understand yourself.',
    completed: (progress) => progress.askedOther === 1,
    linkTo: './queries',
    image: AskAboutYourTeam,
    color: 'scope',
  },
  {
    badge: 'Grow',
    icon: <Icon.Users size={18} weight="duotone" />,
    title: 'Grow your space',
    description: 'Invite your friends and family to join you in your journey.',
    completed: (progress) => progress.invitedOther === 1,
    image: GrowYourSpace,
    color: 'elderberry',
  },
];

const OnboardingCard = ({
  badge,
  // icon,
  title,
  // description,
  className,
  // completed,
  linkTo,
  progress,
  image,
  color,
}: {
  badge: string;
  // icon: React.ReactElement;
  title: string;
  // description?: string;
  className?: string;
  // completed: boolean;
  linkTo?: string | ((progress: WorkspaceLandingLoaderData['progress']) => string);
  progress: WorkspaceLandingLoaderData['progress'];
  image?: string;
  color: keyof typeof colors;
}) => {
  const href = typeof linkTo === 'function' ? linkTo(progress) : linkTo;

  const CardContent = () => (
    <div className="flex flex-col items-start justify-between pb-px">
      <div className="flex w-full flex-col gap-2 text-pretty px-4 pb-1 pt-4">
        <h3 className="text-palette-900 dark:text-palette-100 w-32 text-balance font-serif text-lg font-semibold leading-tight">
          {title}
        </h3>
        {/* {description && <p className="text-palette-100 text-sm">{description}</p>} */}
      </div>
      {image && <img src={image} alt={title} className="h-auto w-full" />}
    </div>
  );

  const card = (
    <Card
      asChild={Boolean(href)}
      className={cn(
        palette(color),
        'dark:from-palette-900 dark:to-palette-800 from-palette-50 to-palette-100 h-full rounded-lg bg-gradient-to-b ring-inset transition-all duration-300 hover:shadow-lg',
        'flex flex-col gap-2 overflow-hidden',
        className,
        href && 'cursor-pointer',
      )}
    >
      {href ? (
        <Link to={href}>
          <CardContent />
        </Link>
      ) : (
        <CardContent />
      )}
    </Card>
  );

  // Wrap with InviteModal if badge is "Grow"
  if (badge === 'Grow') {
    return <InviteModal trigger={card} />;
  }

  return card;
};

export function OnboardingCarousel({
  progress,
  jtbdQuery,
}: {
  progress: WorkspaceLandingLoaderData['progress'];
  jtbdQuery?: WorkspaceLandingLoaderData['jtbdQuery'];
}) {
  const showMigrationHub = useShowMigrationHub();
  const createReport = useReportCreateAction();

  // Use useMemo to derive filtered cards
  const filteredCards = React.useMemo(() => {
    return showMigrationHub ? cards : cards.filter((card) => card.badge !== 'Migrate');
  }, [showMigrationHub]);

  const handleJtbdClick = useCallback(
    (item: Query) => {
      const placeholder: ReportCreateRequestData = item.context.some(
        (c) => c.type === 'placeholder',
      )
        ? { status: 'pending', pendingReason: 'invite' }
        : {};
      createReport({ ...placeholder, prompt: item.prompt, askMarleeTemplateId: item.id });
    },
    [createReport],
  );

  return (
    <div className="relative -ml-6 -mr-6 flex flex-col items-center justify-center overflow-x-clip py-px pl-6 pr-6">
      <Carousel className="w-full" opts={{ align: 'start' }}>
        <CarouselContent
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${filteredCards.length + (jtbdQuery ? 1 : 0)}, 240px)`,
          }}
        >
          {jtbdQuery && (
            <CarouselItem>
              <PromptButton
                query={jtbdQuery}
                onClick={handleJtbdClick}
                variant="highlight"
                className={cn(
                  palette('elderberry'),
                  'dark:from-palette-600 dark:to-palette-500 from-palette-500 to-palette-700 h-full rounded-lg bg-gradient-to-b ring-inset transition-all duration-300 hover:shadow-lg',
                  'flex flex-col gap-2 overflow-hidden',
                  'cursor-pointer',
                )}
                titleClassName="text-lg"
              />
            </CarouselItem>
          )}
          {filteredCards.map((card) => (
            <CarouselItem key={card.title}>
              <OnboardingCard
                {...card}
                // completed={card.completed ? card.completed(progress) : false}
                progress={progress}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      {/* <div className="dark:from-background from-card via-card pointer-events-none absolute inset-y-0 left-0 w-6 bg-gradient-to-r"></div>
      <div className="dark:from-background from-card via-card pointer-events-none absolute inset-y-0 right-0 w-6 bg-gradient-to-l"></div> */}
    </div>
  );
}
