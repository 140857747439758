import type { ReactNode } from 'react';

import { Icon } from '@f4s/ui';

import { NavLink } from '@/components/nav-link';
import { PageHeaderTitle } from '@/components/page-header';
import { useDataFromLoader } from '@/lib/hooks/loader';

import { HubLayout } from '../components/hub-layout';
import { SpaceButton } from '../components/space-button';
import type { hubLoader } from '../loaders';

export const TalentLayout = ({ children }: { children?: ReactNode }) => {
  const { workspaces } = useDataFromLoader<typeof hubLoader>();
  return (
    <HubLayout
      title={
        <PageHeaderTitle
          title="Talent Hub"
          className="truncate text-lg underline-offset-4 group-hover:underline"
        />
      }
      buttons={<SpaceButton workspaces={workspaces} />}
      nav={
        <nav className="flex flex-wrap gap-6">
          <NavLink
            to="."
            end
            activeClassName="text-foreground"
            className="text-muted-foreground hover:text-foreground flex items-center gap-2 font-medium"
          >
            <Icon.Home weight="duotone" size={16} />
            Home
          </NavLink>
          {/* <NavLink
            to="pools"
            activeClassName="text-foreground"
            className="text-muted-foreground hover:text-foreground flex items-center gap-2 font-medium"
          >
            <Icon.ShootingStar weight="duotone" size={16} />
            Talent Pools
          </NavLink> */}
          <NavLink
            to="profiles"
            activeClassName="text-foreground"
            className="text-muted-foreground hover:text-foreground flex items-center gap-2 font-medium"
          >
            <Icon.ReadCvLogo weight="duotone" size={16} />
            Profiles
          </NavLink>
          <NavLink
            to="my-results"
            activeClassName="text-foreground"
            className="text-muted-foreground hover:text-foreground flex items-center gap-2 font-medium"
          >
            <Icon.SlidersHorizontal weight="duotone" size={16} />
            Analysis Results
          </NavLink>
        </nav>
      }
    >
      {children}
    </HubLayout>
  );
};
