import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';

import { fetchAndClearAutoAsk } from '../ask-marlee/queries';
import { fetchMembers } from '../member/queries';
import {
  fetchAggregateCultures,
  fetchCultureData,
  fetchModelData,
} from '../modeling/queries';
import { fetchMotivationGroupDetails, fetchOverTimeData } from '../motivation/queries';
import { fetchTeamList } from '../team/queries';
import { fetchWorkspace, getWorkspaceIdFromParams } from '../workspace/queries';
import {
  fetchDashboard,
  fetchDashboards,
  fetchDashboardTemplate,
  fetchDashboardTemplates,
  fetchWidgetTemplates,
} from './queries';
import { getTemplateConstraints } from './schema';

/* -------------------------------------------------------------------------- */
/*                                 Dashboards                                 */
/* -------------------------------------------------------------------------- */

export const dashboardsLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const dashboards = await fetchDashboards({ workspaceId });
  const dashboardTemplates = await fetchDashboardTemplates();

  return { dashboards, dashboardTemplates };
};

export const dashboardLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { dashboardId } = z.object({ dashboardId: z.coerce.number() }).parse(params);

  const dashboard = await fetchDashboard({ workspaceId, dashboardId });
  if (!dashboard) return redirect('..');
  const user = await authProvider.getUser();
  const widgetTemplates = await fetchWidgetTemplates();

  sendEvent('dashboard_viewed', {
    templateSlug: dashboard.template?.slug ?? undefined,
    dashboardTitle: dashboard.title,
    includesSelf: dashboard.widgets.some((w) =>
      w.data.some((d) => d.selectionType === 'user' && d.userId === user.id),
    ),
  });

  const shareCode = dashboard.instances
    .flatMap(
      (instance) => instance.shares?.find((s) => s.scope === 'anyoneWithLink')?.code,
    )
    .find(Boolean);
  const shareLink = shareCode
    ? `${window.location.origin}/app/users/${user.id}/dashboard/${shareCode}`
    : null;

  return { user, dashboard, widgetTemplates, shareLink };
};

export const newDashboardLoader = async () => {
  return { dashboard: null, shareLink: null };
};

/* -------------------------------------------------------------------------- */
/*                              Dashboard Widgets                             */
/* -------------------------------------------------------------------------- */

export const dashboardWidgetIndexLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const dashboardLoaderResponse = await dashboardLoader({ request, params });
  if (dashboardLoaderResponse instanceof Response) return dashboardLoaderResponse;
  return redirect(dashboardLoaderResponse.dashboard.widgets[0]?.id?.toString() ?? '..');
};

export const dashboardWidgetLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { widgetId, workspaceSlug } = params as {
    widgetId: string;
    workspaceSlug?: string;
  };
  const dashboardLoaderResponse = await dashboardLoader({ request, params });
  if (dashboardLoaderResponse instanceof Response) return dashboardLoaderResponse;

  const widgetIndex = dashboardLoaderResponse.dashboard.widgets.findIndex(
    (w) => w.id === Number(widgetId),
  );

  if (widgetIndex === -1) return redirect('../..');

  const widgetTotal = dashboardLoaderResponse.dashboard.widgets.length;
  const widgetPosition = widgetIndex + 1;
  const widget = dashboardLoaderResponse.dashboard.widgets[widgetIndex];

  const nextWidget = dashboardLoaderResponse.dashboard.widgets[widgetIndex + 1];
  const previousWidget = dashboardLoaderResponse.dashboard.widgets[widgetIndex - 1];

  if (!widget) return redirect('../..');

  // Used for user selection
  const members = await fetchMembers({ workspaceSlug });
  const workspace = await fetchWorkspace(workspaceSlug);
  // Used for motivation group widget selection
  const motivationGroups = await fetchMotivationGroupDetails();
  // Model data
  const models = await fetchModelData();
  const insights = models.filter((m) => m.type === 'insight');
  const xfactors = models.filter((m) => m.type === 'xfactor');
  const roles = models.filter((m) => m.type === 'role');
  const cultures = await fetchCultureData();

  const aggregateCultures = await fetchAggregateCultures();
  const aggregateGenerationCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('culture-generation'),
  );
  const aggregateRoleCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('role-'),
  );
  const teams = workspace ? await fetchTeamList({ workspaceId: workspace.id }) : [];
  const workspaces = workspace ? [workspace] : [];

  // Used to auto-trigger the ask marlee response
  const autoAsk = fetchAndClearAutoAsk();

  sendEvent('dashboard_widgetViewed', {
    templateSlug: dashboardLoaderResponse.dashboard.template?.slug ?? undefined,
    dashboardTitle: dashboardLoaderResponse.dashboard.title,
    includesSelf: widget.data.some(
      (d) => d.selectionType === 'user' && d.userId === dashboardLoaderResponse.user.id,
    ),
    widgetTitle: widget.title,
    widgetType: widget.widgetTemplate.slug,
    datasetCount: widget.data.length,
  });

  return {
    ...dashboardLoaderResponse,
    autoAsk,
    // Widget and pagination
    widget,
    widgetTotal,
    widgetPosition,
    nextWidget,
    previousWidget,

    members,
    motivationGroups,
    insights,
    xfactors,
    roles,
    cultures,
    aggregateCultures,
    aggregateGenerationCultures,
    aggregateRoleCultures,
    workspaces,
    teams,
  };
};

export const newWidgetLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as {
    widgetId: string;
    workspaceSlug?: string;
  };
  const dashboardLoaderResponse = await dashboardLoader({ request, params });
  if (dashboardLoaderResponse instanceof Response) return dashboardLoaderResponse;

  // Used for user selection
  const members = await fetchMembers({ workspaceSlug });
  const workspace = await fetchWorkspace(workspaceSlug);
  // Used for motivation group widget selection
  const motivationGroups = await fetchMotivationGroupDetails();
  // Model data
  const models = await fetchModelData();
  const insights = models.filter((m) => m.type === 'insight');
  const xfactors = models.filter((m) => m.type === 'xfactor');
  const roles = models.filter((m) => m.type === 'role');
  const cultures = await fetchCultureData();
  const aggregateCultures = await fetchAggregateCultures();
  const aggregateGenerationCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('culture-generation'),
  );
  const aggregateRoleCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('role-'),
  );
  const workspaces = workspace ? [workspace] : [];
  const teams = workspace ? await fetchTeamList({ workspaceId: workspace.id }) : [];

  return {
    ...dashboardLoaderResponse,
    autoAsk: false,
    widget: undefined,
    widgetTotal: undefined,
    widgetPosition: undefined,
    nextWidget: undefined,
    previousWidget: undefined,
    members,
    motivationGroups,
    insights,
    xfactors,
    roles,
    cultures,
    aggregateCultures,
    aggregateGenerationCultures,
    aggregateRoleCultures,
    workspaces,
    teams,
  };
};

/* -------------------------------------------------------------------------- */
/*                             Dashboard Templates                            */
/* -------------------------------------------------------------------------- */

export const dashboardTemplatesLoader = async () => {
  // TODO: handle workspace defined templates
  const dashboardTemplates = await fetchDashboardTemplates();
  return { dashboardTemplates };
};

export const dashboardTemplateLoader = async ({ params }: LoaderFunctionArgs) => {
  const { templateId: templateIdString, workspaceSlug } = params as {
    templateId: string;
    workspaceSlug?: string;
  };
  // Temporary redirect of old slug
  if (templateIdString === 'my-results')
    return redirect('../templates/individual-results');

  const dashboardTemplates = await fetchDashboardTemplates();
  // Support both numeric ids and slugs
  const templateId = Number.isNaN(Number(templateIdString))
    ? dashboardTemplates.find((t) => t.slug === templateIdString)?.id
    : dashboardTemplates.find((t) => t.id === Number(templateIdString))?.id;
  if (!templateId) return redirect('..');

  const dashboardTemplate = await fetchDashboardTemplate(templateId);
  if (!dashboardTemplate) return redirect('..');

  const user = await authProvider.getUser();

  const workspace = await fetchWorkspace(workspaceSlug);
  const members = await fetchMembers({ workspaceSlug });
  const widgetTemplates = await fetchWidgetTemplates();
  // Model data
  const models = await fetchModelData();
  const insights = models.filter((m) => m.type === 'insight');
  const xfactors = models.filter((m) => m.type === 'xfactor');
  const roles = models.filter((m) => m.type === 'role');
  const aggregateCultures = await fetchAggregateCultures();
  const aggregateGenerationCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('culture-generation'),
  );
  const aggregateRoleCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('role-'),
  );

  const workspaces = workspace ? [workspace] : [];
  const teams = workspace ? await fetchTeamList({ workspaceId: workspace?.id }) : [];

  const constraints = getTemplateConstraints(dashboardTemplate.slug);

  if (constraints.minAssessmentCount) {
    const { overTimeData } = await fetchOverTimeData();
    if (overTimeData.length < constraints.minAssessmentCount) {
      // TODO: Workspace is always defined with our routing now, we could update to be non nullish later
      return redirect(workspace ? `/spaces/${workspace.slug}/assessment` : '/spaces');
    }
  }

  sendEvent('dashboard_templateViewed', {
    templateSlug: dashboardTemplate?.slug ?? undefined,
  });

  return {
    dashboardTemplate,
    user,
    members,
    widgetTemplates,
    insights,
    xfactors,
    roles,
    aggregateCultures,
    workspace,
    workspaces,
    teams,
    aggregateGenerationCultures,
    aggregateRoleCultures,
    constraints,
  };
};
