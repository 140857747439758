import { z } from 'zod';

export const SetPropertyBodySchema = z.object({
  property: z.string(),
  value: z.union([z.string(), z.boolean()]),
});

export type GetUserOnboardingCompletionRes = z.infer<
  typeof GetUserOnboardingCompletionRes
>;
export const GetUserOnboardingCompletionRes = z.object({
  assessment: z.number(),
  asked: z.number(),
  askedOther: z.number(),
  invitedOther: z.number(),
  dashboardMyResults: z.number(),
  dashboardMyResultsId: z.number().optional(),
  dashboardSoftSkills: z.number(),
  dashboardSoftSkillsId: z.number().optional(),
  dashboardTeamCulture: z.number(),
  dashboardTeamCultureId: z.number().optional(),
  percentComplete: z.number().min(0).max(100),
});
