import { DirectlyConnectedBadge } from '@/components/badge';
import type { BreadcrumbProps } from '@/components/breadcrumbs';
import { NavLink } from '@/components/nav-link';

import type { legacyGroupLoader } from '../loaders';

export const GroupsBreadcrumb = ({ pathname }: BreadcrumbProps<unknown>) => {
  return (
    <NavLink to={pathname} className="flex items-center gap-2 truncate">
      <DirectlyConnectedBadge className="relative bottom-auto right-auto h-6 max-h-6 w-6 max-w-6" />
      Groups
    </NavLink>
  );
};

export const GroupBreadcrumb = ({
  pathname,
  data,
}: BreadcrumbProps<typeof legacyGroupLoader>) => {
  return (
    <NavLink to={pathname} className="truncate">
      {data.group.name}
    </NavLink>
  );
};
