import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { getWorkspaceIdFromSlug } from '@/modules/workspace/queries';

import { fetchWorkspaceTalentOpening, fetchWorkspaceTalentOpenings } from './queries';

export const talentOpeningsLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug?: string };
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) return redirect('..');
  const talentOpenings = await fetchWorkspaceTalentOpenings({ workspaceId });
  return { talentOpenings };
};

export const talentOpeningLoader = async ({ params }: LoaderFunctionArgs) => {
  const { openingId, workspaceSlug } = params as {
    openingId: string;
    workspaceSlug?: string;
  };
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) return redirect('..');

  const talentOpening = await fetchWorkspaceTalentOpening({
    openingId: Number(openingId),
    workspaceId,
  });
  return { talentOpening };
};

export const talentOpeningNewLoader = async () => {
  return { talentOpening: undefined };
};
