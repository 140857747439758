import type { ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import { outgoingRequestsQuery } from '@/modules/workspace-requests/queries';
import {
  fetchSuggestedWorkspaces,
  suggestedWorkspacesQuery,
  workspacesQuery,
} from '@/modules/workspace/queries';

export const onboardingSpaceJoinAction = async ({ request }: ActionFunctionArgs) => {
  const json = await request.json();
  const data = z.object({ workspaceId: z.number() }).parse(json);
  const workspaces = await fetchSuggestedWorkspaces();
  const workspace = workspaces.find((w) => w.id === data.workspaceId);

  // Join directly
  const response = workspace?.permissions.autoApproveJoinRequest
    ? await apiClient.post(`/api/v4/workspaces/suggestions/${data.workspaceId}`, {})
    : await apiClient.post(`/api/v4/workspaces/requests/${data.workspaceId}`, {});

  // Invalidate caches
  await queryClient.invalidateQueries(suggestedWorkspacesQuery);
  await queryClient.invalidateQueries(workspacesQuery);
  await queryClient.invalidateQueries(outgoingRequestsQuery);
  await queryClient.invalidateQueries({
    queryKey: ['workspaces', 'suggestions', 'search'],
  }); // Invalidate the space searches

  return response;
};
