'use client';

import { QueryClientProvider as TanstackQueryClientProvider } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

import { apiClient, type ApiClient } from './api-client';
import { queryClient } from './query-client';

type ApiClientProviderProps = {
  children: React.ReactNode;
};

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

export const ApiClientProvider = ({ children }: ApiClientProviderProps) => (
  <ApiClientContext.Provider value={apiClient}>
    <TanstackQueryClientProvider client={queryClient}>
      {children}
    </TanstackQueryClientProvider>
  </ApiClientContext.Provider>
);

export const useApiClient = () => {
  const client = useContext(ApiClientContext);

  if (!client) {
    throw new Error('No ApiClient set, use ApiClientProvider to set one');
  }

  return client;
};
