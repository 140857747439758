import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { userCreditAddSchema, type UserCreditAdd } from '@f4s/types';

export const userMasqueradeAction = async ({ params }: ActionFunctionArgs) => {
  const { userId } = params as { userId: string };
  const response = await apiClient.get(`/api/v4/users/${userId}/masquerade`);
  await authProvider.setUser({ response });
  return redirect('/');
};

export const useMasquerade = () => {
  const fetcher = useFetcher();
  return () =>
    fetcher.submit(null, {
      method: 'POST',
      encType: 'application/json',
      action: './masquerade',
    });
};

// Mounted on root /unmasquerade
export const unmasqueradeAction = async ({ request }: ActionFunctionArgs) => {
  const { userId } = (await request.json()) as { userId: string };
  const response = await apiClient.get(`/api/v4/users/unmasquerade`);
  const user = await authProvider.setUser({ response });

  if (user?.roles.includes('global_admin')) {
    return redirect(`/admin/users/${userId}`);
  }
  return redirect('/');
};

export const useUnmasquerade = () => {
  const fetcher = useFetcher();
  return () =>
    fetcher.submit(null, {
      method: 'POST',
      encType: 'application/json',
      action: '/unmasquerade',
    });
};

export const userCreditAddAction = async ({ params, request }: ActionFunctionArgs) => {
  const { userId } = params as { userId: string };
  const body = await request.json();
  const data = userCreditAddSchema.parse(body);

  await apiClient.post(`/api/v4/users/${userId}/credits`, data);

  await queryClient.invalidateQueries({ queryKey: ['admin', 'user', userId] });
  return null;
};

export const useCreditAdd = () => {
  const fetcher = useFetcher<Awaited<ReturnType<typeof userCreditAddAction>>>();
  return {
    ...fetcher,
    submit: (data: UserCreditAdd) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
        action: './credits',
      }),
  };
};
