import { UserbackProvider } from '@userback/react';
import { useCallback, useState, type FC } from 'react';
import { Outlet } from 'react-router-dom';

import { TooltipProvider } from '@f4s/ui';

import { useTrackPageviews } from '@/lib/analytics';
import { usePWAUpdatePrompt } from '@/lib/hooks/use-pwa-update-prompt';

import { LoadingSpinner } from '../components/loading-spinner';
import type { RootContextType } from '../types';

export const GlobalLayout: FC = () => {
  useTrackPageviews();
  usePWAUpdatePrompt();

  const [modalPortal, setModalPortal] = useState<HTMLDivElement | null>(null);
  const modalCallbackRef = useCallback(
    (el: HTMLDivElement | null) => setModalPortal(el),
    [],
  );

  return (
    <UserbackProvider
      token="37572|73353|kMeOppV5ZpQQqoMszpAL09DOA"
      widgetSettings={{ position: 'se' }}
    >
      <TooltipProvider delayDuration={300}>
        <Outlet context={{ modalPortal, modalCallbackRef } satisfies RootContextType} />
        <LoadingSpinner />
      </TooltipProvider>
    </UserbackProvider>
  );
};
