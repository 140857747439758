import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';

export function entitlementQuery(entitlementId: string, organizationId?: number) {
  return queryOptions({
    queryKey: ['subscriptions.getEntitlement', entitlementId, organizationId],
    queryFn: () => {
      let query = '';
      if (organizationId) query = `?organizationId=${organizationId}`;
      return apiClient.get(`/api/v3/entitlements/${entitlementId}${query}`) as Promise<
        string | number | boolean | string[]
      >;
    },
  });
}
export const fetchEntitlement = (entitlementId: string, organizationId?: number) =>
  queryClient.fetchQuery(entitlementQuery(entitlementId, organizationId));

export const useEntitlement = (entitlementId: string, organizationId?: number) =>
  useQuery(entitlementQuery(entitlementId, organizationId));
