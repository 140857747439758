import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  GetMiniAssessmentsSchema,
  type AssessmentLanguageCode,
  type Question,
} from '@f4s/types';
import { type QuestionAnswer } from '@f4s/types/common/questionnaire';

export type Assessment = Awaited<
  ReturnType<ReturnType<typeof assessmentsQuery>['queryFn']>
>[0];

// Get all assessment questions in the selected language
export const questionsQuery = (language: AssessmentLanguageCode = 'en-us') => ({
  queryKey: ['assessment', 'questions', language],
  queryFn: async () =>
    apiClient.get(`/api/v3/public/questions/?language=${language}`) as Promise<
      Question[]
    >,
});
export const fetchQuestions = (language?: AssessmentLanguageCode) =>
  queryClient.fetchQuery(questionsQuery(language));
export const useQuestions = (language?: AssessmentLanguageCode) =>
  useQuery(questionsQuery(language));

// Get assessment definitions
export const assessmentsQuery = (language: AssessmentLanguageCode = 'en-us') => ({
  queryKey: ['assessment', 'mini-assessment', language],
  queryFn: async () => {
    const assessmentDefinitions = await apiClient
      .get('/api/v3/public/mini-assessments/')
      .then(GetMiniAssessmentsSchema.parse);

    const questions = await fetchQuestions(language);

    return assessmentDefinitions.map((a) => ({
      ...a,
      questions: questions.filter((q) =>
        a.questionNumbers.includes(q.number),
      ) as (Question & { isAnswered?: boolean; isSaved?: boolean })[],
    }));
  },
});
export const fetchAssessments = (language?: AssessmentLanguageCode) =>
  queryClient.fetchQuery(assessmentsQuery(language));

export const useAssessments = (language?: AssessmentLanguageCode) =>
  useQuery(assessmentsQuery(language));

// Get the user's current answers
export const answersQuery = {
  queryKey: ['assessment', 'answers'],
  queryFn: async () =>
    apiClient.get('/api/v3/questions/answers') as Promise<QuestionAnswer[]>,
};
export const fetchAnswers = () => queryClient.fetchQuery(answersQuery);
export const useAnswers = () => useQuery(answersQuery);
