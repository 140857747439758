import { z } from 'zod';

import { sendEvent } from '@/lib/analytics';

import type { QueryTemplate } from '../queries/queries';

export const jtbdCategorySchema = z.enum([
  'collaborate',
  'motivate',
  'connect',
  'develop',
  'hiring',
  'behired',
]);
export type JtbdCategory = z.infer<typeof jtbdCategorySchema>;
export const _typecheck: (type: true) => void = (
  _: JtbdCategory extends QueryTemplate['questionType'] ? true : never,
) => {};

const SIGNUP_STARTED_KEY = 'onboarding_signup_started';
export const setSignupStarted = (hasStarted: boolean) => {
  sessionStorage.setItem(SIGNUP_STARTED_KEY, JSON.stringify(hasStarted));
};
export const checkSignupStarted = ({ signUpStep }: { signUpStep: string }) => {
  try {
    const hasStarted = z
      .boolean()
      .parse(JSON.parse(sessionStorage.getItem(SIGNUP_STARTED_KEY) ?? 'false'));
    if (!hasStarted) {
      setSignupStarted(true);
      sendEvent('signUp_started', { signUpStep });
    }
    return true;
  } catch {
    return false;
  }
};

const SIGNUP_CREATED_KEY = 'onboarding_signup_created';
export const setSignupCreated = (hasCreated: boolean) => {
  sessionStorage.setItem(SIGNUP_CREATED_KEY, JSON.stringify(hasCreated));
};
export const checkSignupCreated = ({
  signUpConnection,
  signUpStep,
}: {
  signUpConnection?: string;
  signUpStep?: string;
}) => {
  try {
    const hasCreated = z
      .boolean()
      .parse(JSON.parse(sessionStorage.getItem(SIGNUP_CREATED_KEY) ?? 'false'));
    if (!hasCreated) {
      setSignupCreated(true);
      sendEvent('signUp_created', { signUpConnection, signUpStep });
    }
    return true;
  } catch {
    return false;
  }
};

const IS_NEW_KEY = 'onboarding_is_new';
export const setIsNew = (isNew: boolean) => {
  sessionStorage.setItem(IS_NEW_KEY, JSON.stringify(isNew));
};
export const checkIsNew = () => {
  try {
    return z.boolean().parse(JSON.parse(sessionStorage.getItem(IS_NEW_KEY) ?? 'false'));
  } catch {
    return false;
  }
};

const MINI_ANALYSIS_SLUG = 'onboarding_mini_analysis';
export const setOnboardingAnalysisSlug = (slug: string) => {
  sessionStorage.setItem(MINI_ANALYSIS_SLUG, slug);
};
export const getOnboardingAnalysisSlug = () => sessionStorage.getItem(MINI_ANALYSIS_SLUG);

const JTBD_SLUG = 'onboarding_jtbd_slug';
export const setOnboardingJtbdSlug = (slug: JtbdCategory) => {
  sessionStorage.setItem(JTBD_SLUG, slug);
};
export const getOnboardingJtbdSlug = () => {
  try {
    return jtbdCategorySchema.nullable().parse(sessionStorage.getItem(JTBD_SLUG));
  } catch (error) {
    console.warn('Could not parse the session stored jtbd slug', error);
    return null;
  }
};
export const clearOnboardingJtbdSlug = () => sessionStorage.removeItem(JTBD_SLUG);

const JTBD_QUERY_IDS = 'onboarding_jtbd_query_ids';
export const setOnboardingJtbdQueryIds = (queryIds: number[]) => {
  sessionStorage.setItem(JTBD_QUERY_IDS, JSON.stringify(queryIds));
};
export const getOnboardingJtbdQueryIds = () => {
  try {
    return z
      .number()
      .array()
      .parse(JSON.parse(sessionStorage.getItem(JTBD_QUERY_IDS) ?? '[]'));
  } catch (error) {
    console.warn('Could not parse the session stored jtbd slug', error);
    return [];
  }
};
export const clearOnboardingJtbdQueryIds = () =>
  sessionStorage.removeItem(JTBD_QUERY_IDS);

const DEFER_ANALYSIS_KEY = 'onboarding_defer_analysis';
export const setAnalysisDeferred = () => {
  sessionStorage.setItem(DEFER_ANALYSIS_KEY, 'true');
};
export const checkAnalysisDeferred = () => {
  try {
    return z
      .boolean()
      .parse(JSON.parse(sessionStorage.getItem(DEFER_ANALYSIS_KEY) ?? 'false'));
  } catch {
    return false;
  }
};

// uses localStorage to persist longer
const LOGIN_METHOD_KEY = 'onboarding_login_method';
export const setLoginMethod = (loginMethod: string) => {
  localStorage.setItem(LOGIN_METHOD_KEY, loginMethod);
};
export const getLoginMethod = () => localStorage.getItem(LOGIN_METHOD_KEY);
