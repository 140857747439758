import { redirect, useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { type MagicLinkType } from '@f4s/types';
import { type InviteSource } from '@f4s/types/analytics';
import { type UserIdOrEmail, type UserOrEmail } from '@f4s/types/common/user';

import { personalConnectionsQuery } from '@/modules/_legacy/connections/queries';
// import { sendEvent } from '@/lib/analytics';
import { getOnboardingProgressQuery } from '@/modules/onboarding/queries';

import { membersQuery } from '../member/queries';
import { notificationsQuery } from '../notifications/queries';
import { type UserIdEmail } from '../workspace/actions';
import {
  getWorkspaceSlugFromId,
  workspaceInvitePendingConnectedQuery,
  workspaceQuery,
  workspacesQuery,
} from '../workspace/queries';
import { Messages } from './invite.messages';
import { allSentInvitesQuery, magicLinkQuery, pendingInvitesQuery } from './queries';

const AddOrgMemberSchema = z.object({
  isAdmin: z.boolean(),
  isMember: z.boolean(),
  emailAddress: z.optional(z.string()),
  userId: z.optional(z.number()),
});

export type AddOrgMember = z.infer<typeof AddOrgMemberSchema>;

export function mapUserOrEmailToInviteRequest(list: UserOrEmail[]): UserIdEmail[] {
  return list.map((p) =>
    p.type === 'email' ? { emailAddress: p.email } : { userId: p.user.userId },
  );
}

type SendInvitesRequest = {
  source?: InviteSource | null;
  invitees: UserIdOrEmail[];
  workspaceId?: number | null;
};

// TODO: Move to workspace actions
export async function sendInvitesAction({ request }: ActionFunctionArgs) {
  const data = (await request.json()) as SendInvitesRequest;

  // Map to shape for API
  const invitees: UserIdEmail[] = data.invitees.map((i) =>
    i.type === 'email' ? { emailAddress: i.email } : { userId: i.user.userId },
  );

  // TODO: figure out invites
  // sendEvent('invite_sent', {
  //   inviteType: 'connection',
  //   inviteDisplay: 'modal',
  //   inviteSource: data.source ?? 'topButton',
  //   inviteTargetEmail: '',
  //   inviteTargetUserType: 'new',
  //   // existingUsersInvited: data.invitees.filter((u) => 'userId' in u).length,
  //   // newUsersInvited: data.invitees.filter((u) => 'emailAddress' in u).length,
  // });

  let invitations: { id: number }[] = [];

  if (data.workspaceId) {
    // We are inviting people to a space
    try {
      invitations = (await apiClient.post(
        `/api/v4/workspaces/${data.workspaceId}/members`,
        invitees,
      )) as { id: number }[];
    } catch {
      return { error: Messages.inviteError() };
    }
  } else {
    // Personal connections
    try {
      invitations = (await apiClient.post('/api/v3/invitations/connect', {
        invitees,
      })) as { id: number }[];
    } catch {
      return { error: Messages.inviteError() };
    }
  }

  queryClient.invalidateQueries(getOnboardingProgressQuery(data.workspaceId));
  await queryClient.invalidateQueries(allSentInvitesQuery);
  // Clear sent invitations caches
  await queryClient.invalidateQueries({ queryKey: ['invitations', 'sent'] });

  // Handle pending membership invalidation for workspace admins
  // TODO: refine this cache clearing for workspace admins only
  if (data.workspaceId) {
    // TODO: this is annoying
    const workspaceSlug = await getWorkspaceSlugFromId(data.workspaceId);
    if (workspaceSlug) {
      // Invalidate workspace membership
      await queryClient.invalidateQueries(
        workspaceInvitePendingConnectedQuery(data.workspaceId),
      );
      await queryClient.invalidateQueries(workspaceQuery(workspaceSlug));
      await queryClient.invalidateQueries(membersQuery({ workspaceSlug }));
    }
  }

  return { error: null, invitations };
}

export const useInviteAction = () => {
  const submit = useSubmit();
  return (data: SendInvitesRequest) =>
    submit(data, {
      action: '/invite/send',
      method: 'POST',
      encType: 'application/json',
      navigate: false,
    });
};

export type SendInviteActionData = Exclude<
  Awaited<ReturnType<typeof sendInvitesAction>>,
  Promise<Response> | Response
>;

export type ProcessInviteRequest = { token: string };

export async function acceptInvite(token: string) {
  await apiClient.post(`/api/v3/public/invitations/${token}/accept`, {});
  queryClient.invalidateQueries(pendingInvitesQuery);
  queryClient.invalidateQueries(notificationsQuery);
  queryClient.invalidateQueries(workspacesQuery);
  queryClient.invalidateQueries(personalConnectionsQuery);
}

export async function acceptInviteAction({ request }: ActionFunctionArgs) {
  try {
    const { token } = (await request.json()) as ProcessInviteRequest;
    await acceptInvite(token);
  } catch (error) {
    return { error };
  }
  return {};
}

export async function rejectInvite(token: string) {
  await apiClient.post(`/api/v3/public/invitations/${token}/decline`, {});
  await queryClient.invalidateQueries(pendingInvitesQuery);
}

export async function rejectInviteAction({ request }: ActionFunctionArgs) {
  try {
    const { token } = (await request.json()) as ProcessInviteRequest;
    await rejectInvite(token);
  } catch (error) {
    return { error };
  }
  return {};
}

export async function enableMagicLink(type: MagicLinkType, id: number) {
  await apiClient.post(`/api/v3/magic-links`, {
    type,
    id,
  });
  await queryClient.invalidateQueries(magicLinkQuery({ type, id }));
}

export async function enableMagicLinkAction({ request }: ActionFunctionArgs) {
  try {
    const { type, id } = (await request.json()) as {
      type: MagicLinkType;
      id: number;
    };
    await enableMagicLink(type, id);
  } catch (error) {
    return { error };
  }
  return {};
}

export async function disableMagicLink(token: string) {
  await apiClient.post(`/api/v3/magic-links/${token}/disable`, {});
  await queryClient.invalidateQueries({ queryKey: ['magicLinks'] });
}

export async function disableMagicLinkAction({ request }: ActionFunctionArgs) {
  try {
    const { token } = (await request.json()) as {
      token: string;
    };
    await disableMagicLink(token);
  } catch (error) {
    return { error };
  }
  return {};
}

export async function acceptMagicLink(token: string) {
  await apiClient.post(`/api/v3/magic-links/${token}/accept`, {});
}

export async function acceptMagicLinkAction({ params }: ActionFunctionArgs) {
  try {
    const { token } = params;
    if (token) await acceptMagicLink(token);
    return redirect('../connected');
  } catch (error) {
    return { error };
  }
}
