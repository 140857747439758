import { useDataFromLoader } from '@/lib/hooks/loader';

import { JtbdDetailPage } from '../components/jtbd-detail-page';
import type { signupLoader } from '../loaders';

export const SignupMatchPage = () => {
  const { category, queries, selectedQueryIds } =
    useDataFromLoader<typeof signupLoader>();

  return (
    <JtbdDetailPage
      title="Great! You're looking for your dream job."
      subtitle="Now let's refine that, what areas would help you stand out and put your best foot forward? Select as many as you'd like."
      category={category}
      queries={queries}
      selectedQueryIds={selectedQueryIds}
    />
  );
};
