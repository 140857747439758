import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';
import { rootLoader } from '@/modules/root/loaders';
import { fetchSuggestedWorkspaces, fetchWorkspaces } from '@/modules/workspace/queries';

/* -------------------------------------------------------------------------- */
/*                              SPACE ONBOARDING                              */
/* -------------------------------------------------------------------------- */
// Layout
export const onboardingSpaceLayoutLoader = async () => {
  const user = await authProvider.getMaybeUser();
  return { user };
};

// Entrypoint
export const onboardingSpacesIndexLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const workspaces = await fetchWorkspaces();

  if (workspaces.length > 0) {
    // Can we skip space onboarding entirely
    return redirect('complete');
  }
  return redirect('join');
};

export const onboardingSpacesJoinLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const workDomains = rootLoaderResponse.user.emailAddresses.flatMap(
    (e) => (e.type === 'work' && e.isVerified && e.emailAddress.split('@')[1]) || [],
  );

  const [workspaces, suggested] = await Promise.all([
    fetchWorkspaces(),
    fetchSuggestedWorkspaces(),
  ]);

  sendEvent('signUp_next', { signUpStep: 'spaces-join' });
  return {
    workspaces,
    suggested,
    user: rootLoaderResponse.user,
    workDomains,
  };
};

export const onboardingSpacesCreateLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const workDomains = rootLoaderResponse.user.emailAddresses.flatMap(
    (e) => (e.type === 'work' && e.isVerified && e.emailAddress.split('@')[1]) || [],
  );

  const [workspaces, suggested] = await Promise.all([
    fetchWorkspaces(),
    fetchSuggestedWorkspaces(),
  ]);

  sendEvent('signUp_next', { signUpStep: 'spaces-create' });
  return {
    workspaces,
    suggested,
    user: rootLoaderResponse.user,
    workDomains,
  };
};

// Space onboarding done
export const onboardingSpacesCompleteLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  // Space onboarding complete, where to next?
  return redirect('../../profile');
};
