import type { RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { underlyingDataChildRouter } from '../motivation/routes';
import { myResultsLoader } from './loaders';

export const myResultsRoute: RouteObject = {
  path: 'my-results',
  handle: handle({
    type: 'results',
    breadcrumb: SimpleBreadcrumb({ title: 'My Results' }),
  }),
  children: [
    {
      index: true,
      loader: myResultsLoader,
      async lazy() {
        const { MyResultsPage } = await import('./pages/my-results');
        return { Component: MyResultsPage };
      },
    },
    {
      loader: myResultsLoader,
      async lazy() {
        const { MyResultsPage } = await import('./pages/my-results');
        return { Component: MyResultsPage };
      },
      children: [{ path: 'motivation', children: underlyingDataChildRouter }],
    },
  ],
};
