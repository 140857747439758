import { useCallback, useState, type ReactNode } from 'react';
import { useFetcher } from 'react-router-dom';

import { Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { sendEvent } from '@/lib/analytics';
import { type ComponentData } from '@/lib/hooks/loader';

import type { signupLoader } from '../loaders';
import { QuerySelectionButtons } from './query-buttons';

export const JtbdDetailPage = ({
  title,
  subtitle,
  category,
  queries,
  selectedQueryIds,
  children,
}: Pick<
  ComponentData<typeof signupLoader>,
  'category' | 'queries' | 'selectedQueryIds'
> & {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}) => {
  const [selected, setSelected] = useState<number[]>(selectedQueryIds);

  const fetcher = useFetcher();
  const handleNext = useCallback(() => {
    fetcher.submit(
      { queryIds: selected },
      { encType: 'application/json', method: 'POST' },
    );
  }, [fetcher, selected]);
  const handleBack = useCallback(() => {
    sendEvent('signUp_back', { signUpStep: 'jtbd_intro' });
  }, []);

  return (
    <div className="flex w-full max-w-xl flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h2 className="text-balance text-center font-serif text-2xl font-semibold">
          {title ?? category?.title}
        </h2>
        <p className="text-pretty text-center">{subtitle}</p>
        {children}
      </div>
      <QuerySelectionButtons
        queries={queries}
        selected={selected}
        setSelected={setSelected}
      />
      <div className="flex justify-between">
        <NavLinkButton
          to={'/signup'}
          variant="secondary"
          onClick={handleBack}
          size="icon"
        >
          <span className="sr-only">Back</span>
          <Icon.ChevronLeft size={18} />
        </NavLinkButton>
        <NavLinkButton
          to={'/signup/create'}
          onClick={handleNext}
          preventDefault
          disabled={
            fetcher.state !== 'idle' || !queries.some((q) => selected.includes(q.id))
          }
          data-testid="onboarding-jtbd-detail-next"
        >
          Continue
        </NavLinkButton>
      </div>
    </div>
  );
};
