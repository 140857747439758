import type { ReactNode } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { Card, ScrollArea } from '@f4s/ui';

import { OnboardingHeader } from '@/components/onboarding-header';
import { useScrollRestoration } from '@/lib/hooks/use-scroll-restoration';
import type { RootContextType } from '@/modules/root/types';

export const HubLayout = ({
  title,
  buttons,
  nav,
  children,
}: {
  title?: ReactNode;
  buttons?: ReactNode;
  nav?: ReactNode;
  children?: ReactNode;
}) => {
  const scrollRef = useScrollRestoration();
  const context = useOutletContext<RootContextType>();

  return (
    <ScrollArea
      ref={scrollRef}
      className="h-full w-full flex-1"
      scrollBarClassName="z-50"
    >
      <OnboardingHeader title={title} buttons={buttons} className="max-w-screen-xl" />
      <div
        className="m-auto flex h-full w-full max-w-screen-xl flex-1 flex-col gap-5 px-4 py-5 lg:px-10"
        ref={context.modalCallbackRef}
      >
        {nav}
        <main className="relative flex min-w-0 max-w-full flex-1 flex-col">
          <Card className="bg-secondary flex h-full w-full flex-1 flex-col px-4 pb-4 md:px-5 md:pb-5">
            {children ?? <Outlet context={context} />}
          </Card>
        </main>
      </div>
    </ScrollArea>
  );
};
