import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigation } from 'react-router-dom';

import { cn, Icon } from '@f4s/ui';

export const LoadingSpinner = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (navigation.state === 'idle') {
      setLoading(false);
    } else {
      timeout = setTimeout(() => setLoading(true), 200);
    }
    return () => {
      clearTimeout(timeout);
      setLoading(false);
    };
  }, [navigation.state]);

  return createPortal(
    <div
      className={cn(
        'bg-foreground/50 text-background fixed left-1/2 top-1/2 z-[9999] hidden h-24 w-24 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-md backdrop-blur-sm',
        loading && 'flex',
      )}
    >
      <Icon.SpinnerSmall className="h-7 w-7 animate-spin" />
    </div>,
    document.body,
  );
};
