import type { WidgetTypes } from '../queries';
import { type WidgetComponents } from './widget-common';
import { BubbleComponents } from './widgets/bubbles';
import { RankingComponents } from './widgets/ranking';
import { SliderComponents } from './widgets/slider';

export const WidgetComponentMap: Partial<Record<WidgetTypes, WidgetComponents>> = {
  slider: SliderComponents,
  bubbles: BubbleComponents,
  ranking: RankingComponents,
};
