import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider } from '@f4s/api-client';

export const onboardingProfileUpdateAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();

  const redirectTo = z.string().nullish().parse(formData.get('redirectTo'));

  const postData = z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      gender: z.enum(['m', 'f', 't']),
      location: z.string(),
      countryCode: z.string(),
      properties: z
        .object({ jobArea: z.string(), jobTitle: z.string() })
        .partial()
        .optional(),
    })
    .partial()
    .parse(JSON.parse(formData.get('postData') as string));

  const avatar = formData.get('avatar');
  if (avatar) {
    const uploadFormData = new FormData();
    uploadFormData.append('file', avatar);
    try {
      await authProvider.updateUserAvatar(uploadFormData);
    } catch (error) {
      console.error('Error uploading new avatar image', error);
    }
  }

  if (Object.keys(postData).length > 0) {
    // update user details
    try {
      await authProvider.updateUser(postData);
    } catch (error) {
      console.error('Error update user details', error);
    }
  }

  return redirect(redirectTo ?? '/');
};
