import { Fragment, useCallback, useEffect, useMemo, useState, type FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useMaybeUser } from '@f4s/api-client';
import { ordinalize } from '@f4s/shared';
import { formatInitials, type ModelMotivation, type ReportSection } from '@f4s/types';
import {
  Button,
  cn,
  // Divider,
  Icon,
  motivationGroupDefinitions,
  palette,
  type MotivationInfo,
} from '@f4s/ui';
import { Distributions, type DistributionData } from '@f4s/widgets';

import { ProfileAvatar } from '@/components/avatar';
import { NavLinkButton } from '@/components/nav-link';
import { TooltipDrawer } from '@/components/tooltip-drawer';
import { countryDetails } from '@/lib/country-codes';
import { usePersonalConnections } from '@/modules/_legacy/connections/queries';
import { setAutoAsk } from '@/modules/ask-marlee/queries';
import { CreditsDialog } from '@/modules/credits/components/credits-dialog';
import { useCredits } from '@/modules/credits/queries';
import { Messages } from '@/modules/dashboard/dashboard.messages.ts';
import { useCultureData } from '@/modules/modeling/queries';
import { getPublicCTA } from '@/modules/public-profile/utils';
import { AskMarleeResponse } from '@/modules/report/components/ask-marlee-response';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useFeatureFlag } from '@/providers/feature-flags';
import { useLocalPreference } from '@/providers/local-preference';
import { useDarkMode } from '@/providers/theme';

import { useDashboardRefresh } from '../../actions';
import { type WidgetDataset, type WidgetWithData } from '../../queries';
import { normalizeDistribution } from '../../utils';
import {
  calculateData,
  DefaultLockout,
  type WidgetComponents,
  type WidgetDescriptionFC,
  type WidgetFC,
  type WidgetPreviewFC,
} from '../widget-common';

const labels = [
  { value: 0, text: 'Low' },
  { value: 25, text: '' },
  { value: 50, text: '' },
  { value: 75, text: 'High' },
  { value: 100, text: undefined },
];

export const HorizontalAxis: FC = () => {
  return (
    <div className="z-10 flex h-4 items-center justify-between text-xs">
      {labels.map((label, index) => (
        <Fragment key={index}>
          <div className="relative h-full w-0">
            <div
              className={cn(
                'text-muted-foreground absolute top-0',
                label.value === 0
                  ? 'left-0'
                  : label.value === 100
                    ? 'right-0'
                    : 'left-1/2 -translate-x-1/2',
              )}
            >
              {label.value}
            </div>
          </div>
          {label.text !== undefined && (
            <div key={`text-${index}`} className="relative h-full w-0">
              <div className="text-muted-foreground/50 absolute left-0 top-0 line-clamp-1 w-10 -translate-x-1/2 text-center sm:w-20">
                {label.text}
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

const HorizontalCultureZone: FC = () => {
  return (
    <div className="bg-background pointer-events-none absolute bottom-px left-1/4 right-1/4 top-px -z-20">
      <div className="border-foreground/20 absolute bottom-0 left-1/2 top-0 w-0 -translate-x-1/2 border-l border-dotted"></div>
    </div>
  );
};

const HorizontalCenteredAxis: FC = () => {
  return (
    <div className="bg-border/[0.08] pointer-events-none absolute left-2 right-2 top-1/2 -z-10 h-px -translate-y-1/2" />
  );
};

export const SimpleLegend = () => {
  const { user } = useMaybeUser();
  const { data: cultures = [] } = useCultureData() ?? [];

  let averageRange = 'Average Range';
  let cultureString = user?.cultureId
    ? cultures.find((c) => c.id === user.cultureId)?.name
    : undefined;
  if (!cultureString) {
    const countryDetail = countryDetails.find(
      (c) => c.code.toLowerCase() === (user?.cultureCode ?? 'AU').toLowerCase(),
    );
    if (countryDetail) {
      cultureString = countryDetail?.adjective ?? countryDetail?.name ?? 'Australian';
    }
  }

  if (cultureString) {
    averageRange = `Average Range (${cultureString} population)`;
  }

  return (
    <div className="flex w-full justify-between gap-3">
      <TooltipDrawer
        triggerClassName="flex flex-[2] min-w-0 items-center gap-1"
        tooltipAlign="start"
        className="p-4"
        content={
          <div className="flex flex-col gap-2 text-sm">
            <h3 className="font-semibold">{averageRange}</h3>
            <p>{Messages.interquartileTip1()}</p>
            <p>{Messages.interquartileTip2()}</p>
            <p>{Messages.interquartileTip3()}</p>
          </div>
        }
      >
        <div className="bg-muted h-3 min-w-4 rounded-sm" />
        <div className="text-muted-foreground decoration-primary/20 truncate text-left text-xs underline decoration-dotted underline-offset-2">
          {averageRange}
        </div>
      </TooltipDrawer>
      <TooltipDrawer
        triggerClassName="flex flex-1 min-w-0 items-center gap-1 justify-end"
        className="p-4"
        content={
          <div className="flex flex-col gap-2 text-sm">
            <h3 className="font-semibold">{Messages.howResultsWorkTitle()}</h3>
            <p>{Messages.percentileTip1()}</p>
            <p>{Messages.percentileTip2()}</p>
            <p>{Messages.percentileTip3()}</p>
          </div>
        }
      >
        <div className="text-muted-foreground decoration-primary/20 truncate text-xs underline decoration-dotted underline-offset-2">
          {Messages.howResultsWorkTitle()}
        </div>
      </TooltipDrawer>
    </div>
  );
};

const Legend: WidgetFC = ({ widget, user }) => {
  const { data: cultures = [] } = useCultureData() ?? [];
  let averageRange = 'Average Range';

  const cultureModel =
    (widget.cultureModelId && cultures.find((c) => c.id === widget.cultureModelId)) ||
    cultures.find((c) => c.isActive);
  if (cultureModel) {
    averageRange = `Average Range (${cultureModel.name ?? cultureModel.uniqueName ?? 'Culture'} population)`;
  } else {
    const culture = countryDetails.find(
      (c) => c.code.toLowerCase() === (user?.cultureCode ?? 'AU').toLowerCase(),
    );
    averageRange = `Average Range (${culture?.adjective ?? culture?.name ?? 'Australian'} population)`;
  }
  const distributions = widget.datasets.filter((d) => d.aggregateData);

  return (
    <div className="flex flex-col gap-1 sm:gap-0">
      <div className="flex w-full justify-start gap-3">
        <TooltipDrawer
          triggerClassName="flex min-w-0 items-center gap-1"
          className="p-4"
          content={
            <div className="flex flex-col gap-2 text-sm">
              <h3 className="font-semibold">{averageRange}</h3>
              <p>{Messages.interquartileTip1()}</p>
              <p>{Messages.interquartileTip2()}</p>
              <p>{Messages.interquartileTip3()}</p>
            </div>
          }
        >
          <div className="bg-muted h-3 min-w-4 rounded-sm" />
          <div className="text-muted-foreground decoration-foreground/30 truncate text-left text-xs underline decoration-dotted underline-offset-2">
            {averageRange}
          </div>
        </TooltipDrawer>
        {widget.datasets.some((d) => d.model) && (
          <TooltipDrawer
            triggerClassName="flex min-w-0 items-center gap-1 justify-end"
            className="p-4"
            content={
              <div className="flex flex-col gap-2 text-sm">
                <h3 className="font-semibold">Match Zone</h3>
                <p>
                  Results falling in the Match Zone indicate attitudes that align with
                  success in a given area.
                </p>
              </div>
            }
          >
            <div className="hidden min-w-0 flex-1 items-center justify-start gap-1 sm:flex">
              <div className="h-3 w-4 rounded-sm bg-green-500/20"></div>
              <div className="text-muted-foreground decoration-foreground/30 truncate text-xs underline decoration-dotted underline-offset-2">
                Match Zone
              </div>
            </div>
          </TooltipDrawer>
        )}
        {distributions.length >= 2 && (
          <>
            {distributions.map((dataset, index) => (
              <div
                key={index}
                className="hidden min-w-0 flex-1 items-center justify-start gap-1 sm:flex"
              >
                <div
                  className={`border-border/50 h-3 min-w-4 rounded-sm border ${index === 0 ? '' : 'border-dashed'}`}
                ></div>
                <div className="text-muted-foreground truncate text-xs">
                  {dataset.definition?.model?.name?.replace('Generation: ', '') ?? ''}
                </div>
              </div>
            ))}
          </>
        )}

        <div className="flex-1" />
        <TooltipDrawer
          triggerClassName="flex min-w-0 items-center gap-1 justify-end"
          className="p-4"
          content={
            <div className="flex flex-col gap-2 text-sm">
              <h3 className="font-semibold">{Messages.howResultsWorkTitle()}</h3>
              <p>{Messages.percentileTip1()}</p>
              <p>{Messages.percentileTip2()}</p>
              <p>{Messages.percentileTip3()}</p>
            </div>
          }
        >
          <div className="text-muted-foreground decoration-foreground/30 truncate text-xs underline decoration-dotted underline-offset-2">
            {Messages.howResultsWorkTitle()}
          </div>
        </TooltipDrawer>
      </div>
      {distributions.length >= 2 && (
        <div className="flex w-full gap-3">
          {distributions.map((dataset, index) => (
            <div
              key={index}
              className="flex min-w-0 items-center justify-start gap-1 sm:hidden"
            >
              <div
                className={`border-border/50 h-3 min-w-4 rounded-sm border ${index === 0 ? '' : 'border-dashed'}`}
              ></div>
              <div className="text-muted-foreground truncate text-xs">
                {dataset.definition?.model?.name?.replace('Generation: ', '') ?? ''}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Subtitle: WidgetFC = ({ widget, user, isLoading }) => {
  if (isLoading) return null;
  const dataset = widget.datasets[0];
  if (!dataset) return null;
  const datasetUser = dataset.userData?.user;

  // TODO: Model match copy - needs to account for other users
  // This should probably be determined by the server and wrapped into the API response
  const model = dataset.model;
  if (user && datasetUser && model && model.match) {
    const prefix =
      user.id === datasetUser.id
        ? 'You are'
        : (datasetUser.firstName || 'This user') + ' is';
    return `${prefix} a ${model.match.score}% match to the ${model.name} model.`;
  }

  const motivations = dataset.userData?.motivations;
  if (!motivations) return null;

  if (
    widget.widgetTemplate.slug === 'slider-top-5' ||
    widget.widgetTemplate.slug === 'slider-bottom-5'
  ) {
    const sortedMotivations = [...motivations].sort((a, b) => b.median - a.median);
    const prefix =
      user?.id === datasetUser?.id
        ? 'Your'
        : `${datasetUser?.firstName || 'This user'}'s`;
    if (widget.widgetTemplate.slug === 'slider-top-5') {
      const topMotivation = sortedMotivations.at(0);
      return topMotivation
        ? `${prefix} highest motivation is ${topMotivation.name}: ${topMotivation.meaning}`
        : null;
    } else {
      const bottomMotivation = sortedMotivations.at(-1);
      return bottomMotivation
        ? `${prefix} lowest motivation is ${bottomMotivation.name}: ${bottomMotivation.meaning}`
        : null;
    }
  }

  if (widget.motivationGroup) {
    const motivationGroup = motivationGroupDefinitions.find(
      (g) => g.slug === widget.motivationGroup!.slug,
    );
    return motivationGroup ? (
      <>
        <span className="font-semibold">{motivationGroup.name}:</span>{' '}
        {motivationGroup.meaning}
      </>
    ) : null;
  }

  return null;
};

const Visualization: WidgetFC = ({ widget, isLoading, user, detailView, darkMode }) => {
  const [data, setData] = useState({
    datasets: widget.datasets,
    motivations: calculateData({ widget, isLoading }),
  });
  useEffect(() => {
    if (!isLoading) {
      setData({ datasets: widget.datasets, motivations: calculateData({ widget }) });
    }
  }, [isLoading, widget]);

  const sliders = useMemo(() => {
    if (data.motivations.length > 5 && !detailView) {
      return (
        <>
          {data.motivations.slice(0, 4).map((motivation) => (
            <Fragment key={motivation.code}>
              <WidgetSlider
                motivation={motivation}
                datasets={data.datasets}
                detailView={detailView}
                darkMode={darkMode}
              />
              {/* <Divider /> */}
            </Fragment>
          ))}
          <div className="relative z-10 flex h-full min-h-16 flex-1 items-center justify-center">
            <NavLinkButton variant="outline" to={`widgets/${widget.id}`}>
              + {data.motivations.length - 4} more motivations
            </NavLinkButton>
          </div>
          {/* <Divider /> */}
        </>
      );
    }
    return data.motivations.map((motivation) => (
      <Fragment key={motivation.code}>
        <WidgetSlider
          motivation={motivation}
          datasets={data.datasets}
          detailView={detailView}
          darkMode={darkMode}
          halfHeight={data.motivations.length === 1}
        />
        {/* <Divider /> */}
      </Fragment>
    ));
  }, [darkMode, data.datasets, data.motivations, detailView, widget.id]);

  return (
    <div className="flex h-full flex-1 flex-col justify-evenly">
      <div className="border-foreground/10 relative flex h-full flex-1 flex-col justify-end gap-4">
        {sliders}
        <div className="relative flex flex-col gap-3">
          <HorizontalAxis />
          <Legend widget={widget} user={user} />
        </div>
        {/* <HorizontalCultureZone /> */}
      </div>
    </div>
  );
};

const Preview: WidgetPreviewFC = ({ widget, isLoading, darkMode, hideTitle }) => {
  const [data, setData] = useState({
    datasets: widget.datasets,
    motivations: calculateData({ widget, isLoading }),
  });
  useEffect(() => {
    if (!isLoading) {
      setData({ datasets: widget.datasets, motivations: calculateData({ widget }) });
    }
  }, [isLoading, widget]);

  const sliders = useMemo(() => {
    if (data.motivations.length > 5) {
      return (
        <>
          {data.motivations.slice(0, 4).map((motivation) => (
            <WidgetSliderPreview
              key={motivation.code}
              motivation={motivation}
              datasets={data.datasets}
              darkMode={darkMode}
            />
          ))}
          <div className="flex flex-1 justify-center px-2 text-[0.4rem]">
            <span className="max-w-full truncate text-center">
              Plus {data.motivations.length - 4} more motivations...
            </span>
          </div>
        </>
      );
    }
    return data.motivations.map((motivation) => (
      <WidgetSliderPreview
        key={motivation.code}
        motivation={motivation}
        datasets={data.datasets}
        darkMode={darkMode}
      />
    ));
  }, [darkMode, data.datasets, data.motivations]);

  return (
    <div className="flex flex-1 flex-col">
      {!hideTitle && (
        <div className="border-border/5 min-h-[16px] truncate border-b px-1.5 py-1 text-[0.4rem] font-medium leading-[0.5rem] tracking-tight">
          {widget.title}
        </div>
      )}
      <div className="divide-foreground/5 relative flex flex-1 flex-col divide-y px-2">
        {sliders}
      </div>
    </div>
  );
};

const Lockout: WidgetFC = ({ widget, isLoading }) => {
  if (widget.widgetTemplate.slug === 'slider-top-5') return null;
  return <DefaultLockout widget={widget} isLoading={isLoading} />;
};

export const getScoreBuckets = ({
  motivation,
  datasets,
}: {
  motivation: MotivationInfo;
  datasets: WidgetDataset[];
}) => {
  const scoreBucketMap = new Map<
    number,
    {
      user: UserData['user'] & { atDate: Date | null };
      motivation: UserData['motivations'][0];
    }[]
  >();
  for (const data of datasets) {
    if (!data.userData) continue;
    const userMotivation = data.userData.motivations.find(
      (m) => m.code === motivation.code,
    );

    let atDate = data.definition.atDate ? new Date(data.definition.atDate) : null;
    if (atDate && Number.isNaN(atDate?.getTime())) {
      atDate = null;
    }

    if (!userMotivation) continue;
    const bucketSize = 5;
    const bucketKey = Math.floor(userMotivation.median / bucketSize) * bucketSize;
    const bucket =
      scoreBucketMap.get(bucketKey) ?? scoreBucketMap.set(bucketKey, []).get(bucketKey)!;
    bucket.push({ user: { ...data.userData.user, atDate }, motivation: userMotivation });
    bucket.sort(({ motivation: { median: a } }, { motivation: { median: b } }) => a - b);
  }
  return [...scoreBucketMap.entries()];
};

const useSliderData = ({
  motivation,
  datasets,
}: {
  motivation: MotivationInfo;
  datasets: WidgetDataset[];
}) => {
  const distributionMode = useLocalPreference({
    preferenceName: 'distributionStyle',
    defaultValue: 'standard',
  });

  return useMemo(() => {
    const scoreBuckets = getScoreBuckets({ motivation, datasets });
    const distributions: DistributionData[] = datasets.flatMap((d) => {
      const motivationAggregate = d.aggregateData?.find(
        (a) => a.code === motivation.code,
      );
      if (!motivationAggregate || !motivationAggregate.relativeDistribution) return [];

      const normBinDist = normalizeDistribution({
        relativeDistribution: motivationAggregate.relativeDistribution,
        sampleSize: motivationAggregate.sampleSize,
        normalizationMode: distributionMode ?? undefined,
      });

      let name: string | undefined;
      switch (d.definition.selectionType) {
        case 'culture':
        case 'role': {
          name = d.definition.model?.name;
          break;
        }
        case 'workspace': {
          name = d.definition.workspace?.name;
          break;
        }
        case 'team': {
          name = d.definition.team?.name;
          break;
        }
      }

      return {
        name,
        code: motivationAggregate.code,
        distribution: normBinDist,
        quartiles: motivationAggregate.quartiles,
      };
    });
    const models = datasets.flatMap(
      (d) =>
        (['insight', 'xfactor', 'role'].includes(d.definition.selectionType) &&
          d.model?.modelMotivations.find(
            (mm) => mm.motivation.code === motivation.code,
          )) ||
        [],
    );

    // Determine whether an 'over-time' arrow should be displayed
    // Check that there are exactly 2 'user' datasets for 1 user.
    const userDatasets = datasets.filter((d) => d.definition.selectionType === 'user');
    let overTimeArrow:
      | {
          left: number;
          right: number;
          pointer: 'left' | 'right' | 'same';
          showPointer: boolean;
        }
      | undefined;
    const showOverTimeArrow =
      userDatasets.length === 2 &&
      new Set(userDatasets.flatMap((d) => d.userData?.userId ?? [])).size === 1;

    if (showOverTimeArrow) {
      const [first, second] = scoreBuckets
        .flatMap(([_, d]) => d)
        .sort((a, b) => {
          if (!a.user.atDate) return -1;
          if (!b.user.atDate) return 1;
          return Number(b.user.atDate) - Number(a.user.atDate);
        })
        .map((d) => d.motivation.median);
      if (first !== undefined && second !== undefined) {
        if (first > second) {
          overTimeArrow = {
            left: second,
            right: first,
            pointer: 'right',
            showPointer: true,
          };
        } else if (second > first) {
          overTimeArrow = {
            left: first,
            right: second,
            pointer: 'left',
            showPointer: true,
          };
        } else {
          overTimeArrow = {
            left: first,
            right: second,
            pointer: 'same',
            showPointer: false,
          };
        }
      }
    }

    return {
      distributions,
      scoreBuckets,
      models,
      overTimeArrow,
    };
  }, [datasets, distributionMode, motivation]);
};

export const WidgetSliderPreview = ({
  motivation,
  datasets,
  darkMode,
}: {
  motivation: MotivationInfo;
  datasets: WidgetDataset[];
  darkMode?: boolean;
}) => {
  const { distributions, scoreBuckets, models, overTimeArrow } = useSliderData({
    motivation,
    datasets,
  });
  return (
    <div className="pointer-events-none z-10 flex h-full flex-1 items-center py-1">
      <div className="pointer-events-none relative z-10 flex h-full flex-1 items-center">
        {distributions.length > 0 && (
          <Distributions
            distributionData={distributions}
            darkMode={darkMode}
            className="pointer-events-none absolute left-0 right-0 h-full"
          />
        )}

        {overTimeArrow && (
          <div
            className={cn(
              'from-foreground/20 to-foreground absolute top-1/2 z-20 flex h-[2px] -translate-y-1/2 items-center',
              overTimeArrow.pointer === 'left' && 'bg-gradient-to-l',
              overTimeArrow.pointer === 'right' && 'bg-gradient-to-r',
            )}
            style={{
              left: `clamp(1.25rem, calc(${overTimeArrow.left}% + 0.75rem), calc(100% - 0.75rem))`,
              right: `clamp(1.25rem, calc(${100 - overTimeArrow.right}% + 0.75rem), calc(100% - 0.75rem))`,
            }}
          >
            {overTimeArrow.showPointer && (
              <div
                className={cn(
                  'border-foreground absolute h-1.5 w-1.5 border-b border-r',
                  overTimeArrow.pointer === 'left' && 'left-0 rotate-[135deg]',
                  overTimeArrow.pointer === 'right' && 'right-0 -rotate-45',
                )}
              />
            )}
          </div>
        )}

        <div className="absolute left-0 right-0 h-full w-full">
          {scoreBuckets.map(([median, userData]) => (
            <div
              className="pointer-events-auto absolute top-1/2 z-20 flex h-full -translate-x-1/2 -translate-y-1/2 items-center"
              key={median}
              style={{
                left: `clamp(calc(0% + ${(0.5 + 0.25 * userData.length) * 1.25}rem), ${median}%, calc(100% - ${(0.5 + 0.25 * userData.length) * 1.25}rem))`,
              }}
            >
              {userData.map((data, index) => (
                <ProfileAvatar
                  key={`${data.user.id}-${index}`}
                  size="xxs"
                  avatarUrl={data.user.avatarUrl}
                  initials={formatInitials(data.user)}
                  className={cn(
                    palette(motivation.group),
                    'border-palette-500 bg-palette-100 text-palette-700 dark:border-palette-800 dark:bg-palette-800 dark:text-palette-200 border font-normal',
                  )}
                />
              ))}
            </div>
          ))}

          {models.map((i) => (
            <div
              key={i.id}
              className="absolute flex h-full items-center"
              style={{
                left: `${i.greenLow}%`,
                right: `${100 - (i.greenHigh ?? 100)}%`,
              }}
            >
              <div className="h-full max-h-12 w-full bg-green-500/20" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Slider = ({
  motivation,
  distributions,
  scoreBuckets,
  models,
  className,
  overTimeArrow,
}: {
  motivation: MotivationInfo;
  scoreBuckets: ReturnType<typeof getScoreBuckets>;
  distributions: DistributionData[];
  models: ModelMotivation[];
  className?: string;
  overTimeArrow?: {
    left: number;
    right: number;
    pointer: 'left' | 'right' | 'same';
    showPointer: boolean;
  };
}) => {
  const darkMode = useDarkMode();
  return (
    <div
      className={cn(
        'ring-border/5 relative z-10 flex h-full flex-1 items-center rounded-md ring-1 ring-inset',
        className,
      )}
    >
      <TooltipDrawer
        className="p-4"
        content={
          <div>
            <div className="text-sm">
              <span className="font-semibold">{motivation.name}:</span>{' '}
              {motivation.description}
            </div>
          </div>
        }
        tooltipAlign="start"
        triggerClassName={cn(
          palette(motivation.group),
          'text-palette-700 dark:text-palette-300 bg-palette-200/30 dark:bg-palette-700/30 absolute left-2 top-2 z-10 rounded-sm px-2 py-0.5 text-xs',
        )}
      >
        {motivation.name}
      </TooltipDrawer>
      {distributions.length > 0 && (
        <Distributions
          distributionData={distributions}
          darkMode={darkMode}
          className="absolute left-0 right-0 h-full"
        />
      )}

      {overTimeArrow && (
        <div
          className={cn(
            'from-foreground/20 to-foreground absolute top-1/2 z-20 flex h-0.5 -translate-y-1/2 items-center',
            overTimeArrow.pointer === 'left' && 'bg-gradient-to-l',
            overTimeArrow.pointer === 'right' && 'bg-gradient-to-r',
          )}
          style={{
            left: `clamp(1.625rem, calc(${overTimeArrow.left}% + 1rem), calc(100% - 1rem))`,
            right: `clamp(1.625rem, calc(${100 - overTimeArrow.right}% + 1rem), calc(100% - 1rem))`,
          }}
        >
          {/* TODO: show change */}
          {/* <div className="absolute left-1/2 mb-4 -translate-x-1/2 -translate-y-1/2">
            {overTimeArrow.pointer === 'left'
              ? `${Math.round(overTimeArrow.left - overTimeArrow.right)}`
              : `+${Math.round(overTimeArrow.right - overTimeArrow.left)}`}
          </div> */}
          {overTimeArrow.showPointer && (
            <div
              className={cn(
                'border-foreground absolute h-2 w-2 border-b-2 border-r-2',
                overTimeArrow.pointer === 'left' && 'left-0 rotate-[135deg]',
                overTimeArrow.pointer === 'right' && 'right-0 -rotate-45',
              )}
            />
          )}
        </div>
      )}

      <div className="pointer-events-none absolute left-0 right-0 h-full w-full">
        {scoreBuckets.map(([median, userData]) => (
          <div
            className="pointer-events-auto absolute top-1/2 z-20 flex h-full -translate-x-1/2 -translate-y-1/2 items-center"
            key={median}
            style={{
              left: `clamp(calc(0% + ${(0.5 + 0.25 * userData.length) * 1.25}rem), ${median}%, calc(100% - ${(0.5 + 0.25 * userData.length) * 1.25}rem))`,
            }}
          >
            {distributions.length > 0 && (
              <div className="absolute top-1/2 flex h-1/2 w-full justify-center">
                <div
                  className={cn(
                    'h-full w-0.5',
                    palette(motivation.group),
                    'bg-palette-500 dark:bg-palette-600',
                  )}
                />
                <div
                  className={cn(
                    'absolute bottom-0 left-1/2 h-[6px] w-[6px] -translate-x-1/2 translate-y-1/2 rounded-full',
                    palette(motivation.group),
                    'bg-palette-500 dark:bg-palette-600',
                  )}
                />
              </div>
            )}
            {userData.map((data, i) => (
              <TooltipDrawer
                triggerClassName={cn(i > 0 && '-ml-2.5')}
                className="p-4"
                key={`${data.user.id}-${i}`}
                content={
                  <>
                    <div className="text-sm">
                      <span className="font-semibold">
                        {`${data.user.firstName}${data.user.atDate ? ' ' + data.user.atDate.toLocaleDateString() : ''}`}
                      </span>{' '}
                      <span>{ordinalize(data.motivation.median)} percentile</span>
                    </div>
                    <div className="mt-2 text-sm">{data.motivation.meaning}</div>
                  </>
                }
              >
                <ProfileAvatar
                  size="xs"
                  avatarUrl={data.user.avatarUrl}
                  initials={formatInitials(data.user)}
                  className={cn(
                    palette(motivation.group),
                    'border-palette-500 bg-palette-500 dark:border-palette-600 dark:bg-palette-600 dark:text-palette-200 border font-normal text-white',
                  )}
                />
              </TooltipDrawer>
            ))}
          </div>
        ))}

        {models.map((i) => (
          <div
            key={i.id}
            className="absolute flex h-full items-center"
            style={{
              left: `${i.greenLow}%`,
              right: `${100 - (i.greenHigh ?? 100)}%`,
            }}
          >
            <div className="h-full w-full rounded-md bg-green-400/20" />
          </div>
        ))}
      </div>
      <HorizontalCultureZone />
      {!distributions?.length && <HorizontalCenteredAxis />}
    </div>
  );
};

type UserData = NonNullable<WidgetDataset['userData']>;
export const WidgetSlider = ({
  motivation,
  datasets,
  detailView,
  halfHeight = false,
}: {
  motivation: MotivationInfo;
  datasets: WidgetDataset[];
  detailView?: boolean;
  darkMode?: boolean;
  halfHeight?: boolean;
}) => {
  const { distributions, scoreBuckets, models, overTimeArrow } = useSliderData({
    motivation,
    datasets,
  });

  const slider = (
    <Slider
      className={cn(detailView && 'min-h-16', halfHeight && 'h-1/2')}
      motivation={motivation}
      distributions={distributions}
      scoreBuckets={scoreBuckets}
      models={models}
      overTimeArrow={overTimeArrow}
    />
  );

  if (halfHeight) {
    return <div className="flex-1">{slider}</div>;
  }

  return slider;
};

export const WidgetDescriptionDialog = ({
  widget,
  disabled,
  autoAsk,
  detailView,
  onDone,
}: {
  widget: WidgetWithData;
  disabled?: boolean;
  autoAsk?: boolean;
  detailView?: boolean;
  onStart?: () => void;
  onDone?: () => void;
}) => {
  const { workspace } = useMatchedWorkspace();
  const { data: credits } = useCredits('askmarlee');
  const { user, isLoggedIn } = useMaybeUser();
  const bypassComingSoon = useFeatureFlag('MarleeWeb.Dashboards.BypassComingSoon');
  const [description, setDescription] = useState<string | undefined>(widget.description);

  // Sync autoAsk to internal state
  const [shouldStart, setShouldStart] = useState<boolean>(autoAsk ?? false);
  useEffect(() => {
    if (autoAsk !== undefined) {
      setShouldStart(autoAsk);
    }
  }, [autoAsk]);

  const refresh = useDashboardRefresh();
  const navigate = useNavigate();

  const motivations = calculateData({ widget });

  const handleStart = useCallback(() => {
    if (widget.id) {
      if (detailView) {
        setShouldStart(true);
        return;
      }
      setAutoAsk(true);
      navigate(`widgets/${widget.id}`, { preventScrollReset: true });
    }
  }, [detailView, navigate, widget.id]);

  const handleDone = useCallback(
    (_questionId?: number, sections?: Partial<ReportSection>[]) => {
      setShouldStart(false);
      setDescription(sections?.map((s) => s.body).join('\n'));
      onDone?.();
      refresh();
    },
    [onDone, refresh],
  );

  useEffect(() => {
    // Link dynamic updates to the description to the component state
    setDescription(widget.description);
  }, [widget.description]);

  const body = useMemo(() => {
    if (!description && !shouldStart) return null;

    // TODO: fix the ask marlee streamed response component.
    // This is a bit hacky as is and we need a nicer way of handling marlee streaming responses generally.
    // preact signals might be an idea
    return (
      description || (
        <AskMarleeResponse
          questionData={{
            prompt: 'Dashboard widget query',
            questionId: widget.askMarleeQuestion?.id,
            widgetId: widget.id,
            motivationGroupId: widget.motivationGroup?.id,
            modelIds: widget.data.flatMap((d) => d.modelId ?? []),
            motivationList: motivations.map((m) => m.code),
          }}
          autoAsk={shouldStart}
          onDone={handleDone}
          animationMode="letters"
        />
      )
    );
  }, [
    description,
    handleDone,
    motivations,
    shouldStart,
    widget.askMarleeQuestion?.id,
    widget.data,
    widget.id,
    widget.motivationGroup?.id,
  ]);

  const shareUser = widget.datasets[0]?.userData?.user;
  let isConnected = false;

  const { data: personalConnections } = usePersonalConnections();
  if (user) {
    isConnected =
      (shareUser &&
        personalConnections?.some((connection) => connection.id === shareUser.id)) ||
      false;
  }

  const { destination: ctaDestination, buttonCopy: ctaButtonCopy } = getPublicCTA({
    user,
    shareUser,
    isLoggedIn,
    isConnected,
  });

  const unsupported = useMemo(() => {
    if (bypassComingSoon) return false;

    // Really not supported as marlee has no way of getting motivations at certain dates yet. (over-time board)
    if (widget.data.some((d) => d.selectionType === 'user' && d.atDate)) return true;

    // We do not currently more than 1 non-user dataset
    if (widget.data.filter((d) => d.comparisonType === 'aggregate')?.length > 1)
      return true;

    // We do not support aggregate when compared to a model
    if (
      widget.data.some((d) => d.comparisonType === 'model') &&
      widget.data.some((d) => d.comparisonType === 'aggregate')
    )
      return true;

    // We do not support more than five users and an aggregate comparison
    if (
      widget.data.filter((d) => d.selectionType === 'user')?.length > 5 &&
      widget.data.some((d) => d.comparisonType === 'aggregate')
    )
      return true;

    // We do not support more than one user with a model for a group of motivations
    return (
      widget.motivationGroupId &&
      widget.data.some((d) => d.comparisonType === 'model') &&
      widget.data.filter((d) => d.selectionType === 'user')?.length > 1
    );
  }, [bypassComingSoon, widget.data, widget.motivationGroupId]);

  if (!body) {
    return (
      <div className="flex h-[5.75rem] max-h-[5.75rem] w-full items-center justify-center px-5 py-4">
        {isLoggedIn ? (
          credits && credits.totalCredits === 0 ? (
            <CreditsDialog source="dashboardWidget" />
          ) : (
            <Button
              variant="default"
              className={cn(shouldStart && 'hidden', 'gap-3 !pl-2.5 !pr-1.5')}
              onClick={handleStart}
              disabled={disabled || !workspace || !!unsupported}
            >
              <div className="flex items-center gap-2">
                <Icon.Sparkles size={18} />
                <span>Generate Insight</span>
              </div>
              <div className="bg-elderberry-600 flex items-center gap-1 rounded-sm px-2 py-1 shadow-inner">
                {unsupported ? (
                  'Coming soon!'
                ) : (
                  <>
                    <Icon.Coins size={14} />
                    <span className="text-xs">1</span>
                  </>
                )}
              </div>
            </Button>
          )
        ) : (
          <NavLinkButton to={ctaDestination} className="w-fit">
            {ctaButtonCopy}
            <Icon.ArrowRight />
          </NavLinkButton>
        )}
      </div>
    );
  }

  if (detailView) {
    return (
      <div className="text-foreground h-full min-h-24 w-full whitespace-pre-wrap text-left text-base">
        {body}
      </div>
    );
  }

  return (
    <div className="flex h-[5.75rem] max-h-[5.75rem] w-full items-center justify-center px-5">
      <div className="text-muted-foreground relative line-clamp-3 max-h-full w-full text-left text-sm leading-[1.125rem]">
        {body}
        <div className="bg-secondary absolute bottom-0 right-0">
          ...{' '}
          <NavLink
            to={`widgets/${widget.id}`}
            className="text-foreground"
            preventScrollReset
          >
            Read More
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const Description: WidgetDescriptionFC = ({
  widget,
  isLoading,
  disabled,
  autoAsk,
  detailView,
  onDone,
}) => {
  return (
    <WidgetDescriptionDialog
      // Note: We need this to re-render on widget change to force the ask marlee component back to its default state.
      key={widget.id}
      widget={widget}
      disabled={isLoading || disabled}
      autoAsk={autoAsk}
      detailView={detailView}
      onDone={onDone}
    />
  );
};

export const SliderComponents: WidgetComponents = {
  Subtitle,
  Visualization,
  Preview,
  Lockout,
  Description,
};
