import { forwardRef } from 'react';
import { NavLink as NavLinkPrimitive, type NavLinkProps } from 'react-router-dom';

import { Button, cn, type ButtonProps } from '@f4s/ui';

const NavLink = forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, 'className'> & {
    variant?: ButtonProps['variant'];
    activeClassName?: string;
    childFocus?: boolean;
    className?: string;
  }
>(({ className, childFocus = true, activeClassName, ...props }, ref) => (
  <NavLinkPrimitive
    ref={ref}
    className={(classProps) =>
      cn(
        'outline-none',
        childFocus
          ? '[&:focus-visible_>_*]:ring-elderberry-700 [&:focus-visible_>_*]:ring-1'
          : 'focus-visible:ring-elderberry-700 focus-visible:ring-1',
        className,
        classProps.isActive && activeClassName,
      )
    }
    {...props}
  />
));
NavLink.displayName = 'Navlink';

const NavLinkButton = forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, 'children' | 'className'> & {
    children?: React.ReactNode | React.ReactNode[];
    variant?: ButtonProps['variant'];
    width?: ButtonProps['width'];
    size?: ButtonProps['size'];
    disabled?: boolean;
    activeClassName?: string;
    childFocus?: boolean;
    className?: string;
    linkClassName?: string;
    preventDefault?: boolean;
  }
>(
  (
    {
      className,
      linkClassName,
      children,
      variant,
      width,
      size,
      disabled,
      onClick,
      preventDefault,
      ...props
    },
    ref,
  ) => (
    <Button
      className={cn(className)}
      variant={variant}
      width={width}
      size={size}
      // asChild causes this components props to be merged into the first child (the below div)
      // This allows us to merge button styles into a dive component
      asChild
      disabled={disabled}
    >
      <NavLink
        ref={ref}
        variant={variant}
        childFocus={false}
        className={cn(disabled && 'pointer-events-none opacity-50', linkClassName)}
        // pointer-events-none should prevent this, but just in case, e.preventDefault()
        onClick={(e) => {
          if (disabled || preventDefault) {
            e.preventDefault();
          }
          if (!disabled) {
            onClick?.(e);
          }
        }}
        {...props}
      >
        {children}
      </NavLink>
    </Button>
  ),
);
NavLinkButton.displayName = 'NavlinkButton';

export { NavLink, NavLinkButton };
