import { useFetcher, type ActionFunctionArgs } from 'react-router-dom';

export async function refreshAction({ request }: ActionFunctionArgs) {
  const refresh = (await request.json()) as true;
  console.log('Refreshing loaders');
  return refresh;
}

export const useRefresh = () => {
  const fetcher = useFetcher();
  return () =>
    fetcher.submit(true, {
      method: 'POST',
      encType: 'application/json',
      action: '/refresh',
    });
};
