import { useMemo, useState } from 'react';

import { InputAutocomplete, type TestAttributes } from '@f4s/ui';

type Selection = {
  label: string;
  key: string;
  value: string;
};

const latestYear = new Date().getFullYear() - 16;
export const YEARS: Selection[] = Array.from(
  { length: latestYear - 1900 },
  (_, index) => {
    const y = String(latestYear - index);
    return {
      label: y,
      key: y,
      value: y,
    };
  },
);

interface YearOfBirthInputProps extends TestAttributes {
  year: Selection | string | null | undefined;
  onYearChange: (year: Selection | null) => void;
}

export const YearOfBirthInput = ({
  year,
  onYearChange,
  ...props
}: YearOfBirthInputProps) => {
  const [yearSearch, setYearSearch] = useState<string>('');
  const filteredYearSelections = useMemo(() => {
    const rgx = new RegExp('^' + yearSearch, 'i');
    return YEARS.filter((c) => rgx.test(c.value));
  }, [yearSearch]);

  const yearSelection =
    (typeof year === 'string' ? YEARS.find((y) => y.value === year.slice(0, 4)) : year) ??
    null;

  return (
    <InputAutocomplete
      onValueChange={setYearSearch}
      selection={yearSelection}
      selections={filteredYearSelections}
      placeholder="Select a year..."
      onSelectionChange={onYearChange}
      hideIcon
      {...props}
    />
  );
};
