import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider } from '@f4s/api-client';

import { fetchWorkspaces } from '@/modules/workspace/queries';

import { fetchLegacyGroups } from './queries';

export const legacyGroupsLoader = async () => {
  const user = await authProvider.getUser();
  const groups = await fetchLegacyGroups();
  const workspaces = await fetchWorkspaces();
  return { user, groups, workspaces };
};

export const legacyGroupLoader = async ({ params }: LoaderFunctionArgs) => {
  const { groupId } = z.object({ groupId: z.coerce.number() }).parse(params);
  const groups = await fetchLegacyGroups();
  const group = groups.find((g) => g.id === groupId);
  if (!group) return redirect('..');
  return { group };
};
