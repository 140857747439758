import { useMemo, type ReactNode } from 'react';

import { Icon } from '@f4s/ui';

import AnggiaAvatarUrl from '@/assets/Anggia_Meisesari-TransTrack_id.jpeg';
import cliffAvatarUrl from '@/assets/Cliff_Obrecht-Canva.jpeg';
import SynthesiaLogo from '@/assets/synthesia.svg?react';
import { ProfileAvatar } from '@/components/avatar';
import { NavLinkButton } from '@/components/nav-link';
import { PageHeaderTitle } from '@/components/page-header';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';

import type { onboardingAssessmentProgressLoader } from '../loaders';

export const OnboardingAnalysisProgressPage = () => {
  const { progressNo, nextStep, previousStep } =
    usePersistedDataFromLoader<typeof onboardingAssessmentProgressLoader>();

  const data = useMemo(() => {
    const d: { title?: ReactNode; body?: ReactNode } = {};
    switch (progressNo) {
      case 1: {
        d.title =
          'Congratulations! You are one quarter of the way through the motivational analysis.';
        d.body = (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-8">
              <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0">
                <p>
                  You&apos;re killing it - Marlee is learning a lot about you, and soon
                  will be able to share all that knowledge with you. Keep it up! If
                  you&apos;re anything like Cliff, you&apos;re going to learn a lot about
                  yourself!
                </p>
              </div>
              <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0 delay-150">
                <div className="flex items-start gap-4">
                  <blockquote className="border-muted-foreground border-l-2 pl-4 italic">
                    I have personally gotten a lot from Marlee. It has really helped me
                    understand the way I work, as well as my blind spots. It helps me work
                    better with others, as I now understand their key drivers and
                    strengths.
                  </blockquote>
                </div>
                <div className="flex items-center gap-4">
                  <ProfileAvatar
                    avatarUrl={cliffAvatarUrl}
                    size="xl"
                    name="Cliff Obrecht"
                    initials="CO"
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold">Cliff Obrecht</span>
                    <span className="text-muted-foreground">Co-founder & COO, Canva</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      }
      case 2: {
        d.title = 'Well done! You are halfway through the motivational analysis.';

        d.body = (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="animate-fade-in-and-up opacity-0">
                Did you know, Marlee was voted one of the best AI tools by Synthesia? Keep
                going to find out why!
              </div>
              <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0 delay-150">
                <blockquote className="border-muted-foreground border-l-2 pl-4 italic">
                  One of the best AI tools... With a focus on resourcefulness, emotional
                  intelligence, creativity, and adaptability, Marlee helps users enjoyably
                  achieve their goals.
                </blockquote>
                <SynthesiaLogo />
              </div>
            </div>
          </div>
        );
        break;
      }
      case 3: {
        d.title =
          'Congratulations! You are three quarters of the way through the analysis.';
        d.body = (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-8">
              <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0">
                <p>
                  Only a few questions to go. Anggia found some important insights for
                  herself once she spoke with Marlee.
                </p>
              </div>

              <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0 delay-150">
                <div className="flex gap-4">
                  <blockquote className="border-muted-foreground border-l-2 pl-4 italic">
                    I can now identify my strengths and also uncover my blind spots. This
                    will help me improve my instincts related to a business decision.
                    Marlee helped me to know more about my team and gain insights on how
                    to succeed.
                  </blockquote>
                </div>
                <div className="flex items-center gap-4">
                  <ProfileAvatar
                    avatarUrl={AnggiaAvatarUrl}
                    size="xl"
                    name="Anggia Meisesari"
                    initials="AM"
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold">Anggia Meisesari</span>
                    <span className="text-muted-foreground">Founder, TransTRACK.Id</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      }
    }
    return d;
  }, [progressNo]);

  return (
    <div className="flex min-h-[488px] flex-col gap-4 p-4 sm:p-6">
      <PageHeaderTitle title={data.title} className="whitespace-normal" />
      {data.body}
      <div className="flex flex-1 flex-col justify-end">
        <div className="flex justify-between gap-3">
          <NavLinkButton
            to={previousStep}
            variant="secondary"
            size="icon"
            className="shrink-0"
            data-testid="onboarding-analysis-progress-back-button"
          >
            <Icon.ChevronLeft className="text-foreground/60" size={18} />
          </NavLinkButton>
          <NavLinkButton
            to={nextStep}
            data-testid="onboarding-analysis-progress-next-button"
          >
            Next
          </NavLinkButton>
        </div>
      </div>
    </div>
  );
};
