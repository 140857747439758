import { useMemo, useState } from 'react';

import { Button, cn, Icon, Progress } from '@f4s/ui';

import { DropdownDrawer } from '@/components/dropdown-drawer';
import { useOnboardingProgress } from '@/modules/onboarding/queries';
import { InviteModal } from '@/modules/workspace/components/workspace-invite-modal';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { Messages } from './user-setup.messages';

export const SetupProgress = ({ value }: { value: number }) => {
  return (
    <Progress
      value={value}
      className="h-[3px] bg-black/10"
      containerClassName="bg-white mt-0 p-px ring-0 max-w-[100px] w-full"
    />
  );
};

export const SetupGuideCard = ({
  title,
  body,
  isDone,
}: {
  title: string;
  body: string;
  isDone: boolean;
}) => {
  return (
    <div className="grid w-full items-center !gap-x-3 !gap-y-2 p-2 [grid-template-columns:auto_minmax(auto,1fr)_auto]">
      <div
        className={cn(
          'ring-border/5 h-4 w-4 rounded-full ring-1',
          isDone && 'bg-elderberry-500',
        )}
      >
        {isDone && <Icon.Check className="text-white" />}
      </div>
      <div className={cn('font-semibold', isDone && 'opacity-50')}>{title}</div>
      <div>
        <Icon.ArrowRight className={cn(isDone && 'opacity-50')} />
      </div>
      <div />
      <div className={cn('col-span-2 opacity-70', isDone && 'opacity-50')}>{body}</div>
    </div>
  );
};

export const SetupGuide = ({ type = 'button' }: { type?: 'button' | 'banner' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInviteOpen, setInviteOpen] = useState<boolean>(false);
  const { workspace, pathname } = useMatchedWorkspace();
  const { data: progress, isSuccess } = useOnboardingProgress(workspace?.id);

  const { percentComplete, menuItems } = useMemo(() => {
    if (!progress) return { percentComplete: 0, menuItems: [] };

    return {
      percentComplete: Math.round(
        100 *
          ((progress.asked +
            progress.askedOther +
            progress.invitedOther +
            progress.dashboardMyResults +
            progress.assessment) /
            5),
      ),
      menuItems: [
        {
          key: 'assessment',
          linkTo: `${pathname}/assessment/full`,
          onClick: () => {
            setIsOpen(false);
          },
          disabled: progress.assessment === 1,
          children: (
            <SetupGuideCard
              title={Messages.card1Title()}
              body={Messages.card1Body()}
              isDone={progress.assessment === 1}
            />
          ),
        },
        {
          key: 'asked',
          onClick: () => {
            setIsOpen(false);
          },
          disabled: progress.asked === 1,
          linkTo: `${pathname}/queries`,
          children: (
            <SetupGuideCard
              title={Messages.card2Title()}
              body={Messages.card2Body()}
              isDone={progress.asked === 1}
            />
          ),
        },
        {
          key: 'invitedOther',
          onClick: () => {
            setIsOpen(false);
            setInviteOpen(true);
          },
          disabled: progress.invitedOther === 1,
          children: (
            <SetupGuideCard
              title={Messages.card3Title()}
              body={Messages.card3Body()}
              isDone={progress.invitedOther === 1}
            />
          ),
        },
        {
          key: 'askedOther',
          onClick: () => {
            setIsOpen(false);
          },
          linkTo: `${pathname}/queries`,
          disabled: progress.askedOther === 1,
          children: (
            <SetupGuideCard
              title={Messages.card4Title()}
              body={Messages.card4Body()}
              isDone={progress.askedOther === 1}
            />
          ),
        },
        {
          key: 'dashboardMyResults',
          onClick: () => {
            setIsOpen(false);
          },
          linkTo:
            progress.dashboardMyResults === 1
              ? `${pathname}/boards/${progress.dashboardMyResultsId}`
              : `${pathname}/boards/templates/individual-results`,

          children: (
            <SetupGuideCard
              title={Messages.card5Title()}
              body={Messages.card5Body()}
              isDone={progress.dashboardMyResults === 1}
            />
          ),
        },
      ],
    };
  }, [pathname, progress]);

  if (!isSuccess) return null;
  if (percentComplete >= 100) return null;

  return (
    <>
      {/* Note the deliberate trigger={null} */}
      <InviteModal open={isInviteOpen} onOpenChange={setInviteOpen} trigger={null} />
      <DropdownDrawer
        open={isOpen}
        onOpenChange={setIsOpen}
        asChild={type === 'button'}
        dropdownAlign="end"
        dropdownClassName="p-6 w-[30rem] bg-secondary"
        triggerClassName={cn(
          type === 'button'
            ? 'hidden md:flex'
            : 'dark:bg-elderberry-900 bg-background/90 sticky top-16 z-40 flex w-full items-center gap-3 rounded-none px-4 py-2 shadow-sm backdrop-blur-lg md:hidden',
        )}
        content={
          <div className="flex w-full flex-col gap-8">
            <div className="flex flex-col gap-5">
              <div>
                <h2 className="font-serif text-xl font-semibold">
                  {Messages.setupGuide()}
                </h2>
                <p className="text-muted-foreground mt-2 text-sm">
                  {Messages.followActions()}
                </p>
              </div>
              <Progress
                value={percentComplete}
                className="bg-black/20"
                containerClassName="bg-white mt-0 mb-4"
              />
            </div>
          </div>
        }
        items={menuItems}
      >
        {type === 'button' ? (
          <Button variant="secondary">
            <div className="flex items-center gap-2">
              {Messages.setupProgress()}
              <div className="bg-primary text-primary-foreground rounded-full px-1.5 py-0.5 text-xs font-medium">
                {percentComplete}%
              </div>
              {/* <div className="absolute mt-[2px] w-full">
              <SetupProgress value={completeValue} />
            </div> */}
            </div>
          </Button>
        ) : (
          <div className={cn('flex w-full items-center')}>
            <div>{Messages.setupProgress()}</div>
            <div className="flex flex-1 items-center justify-end gap-3">
              <div className="bg-primary text-primary-foreground rounded-full px-1.5 py-0.5 text-xs font-medium">
                {percentComplete}%
              </div>
              <Icon.ChevronDown size={18} />
            </div>
          </div>
        )}
      </DropdownDrawer>
    </>
  );
};
