/* eslint-disable unicorn/prefer-query-selector */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-var */
/* eslint-disable unicorn/prefer-date-now */
import { LoadClientEnv } from '../env';

LoadClientEnv();

if (window.GTM_ID) {
  (
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
      var f = d.getElementsByTagName(s)[0]!,
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' +
        i +
        dl +
        (window.GTM_ENV_CODE || '');
      f.parentNode!.insertBefore(j, f);
    }) as GtagFn
  )(window, document, 'script', 'dataLayer', window.GTM_ID);
}

type GtagFn = (w: Window, d: Document, s: 'script', l: 'dataLayer', i: string) => void;

declare global {
  interface Window {
    dataLayer: any[];
    gtag: Gtag.Gtag;
  }
}

export {};
