import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider, queryClient, type User } from '@f4s/api-client';
import { QuestionAnswerSchema } from '@f4s/types/common/questionnaire';

import { assessmentUpdate } from '@/modules/assessment/actions';

export const onboardingAnalysisUpdateAction = async ({ request }: ActionFunctionArgs) => {
  const { redirectTo, data } = z
    .object({ redirectTo: z.string(), data: QuestionAnswerSchema.array() })
    .parse(await request.json());
  try {
    await assessmentUpdate(data);
  } catch (error) {
    console.error('Error submitting onboarding analysis results', error);
  }

  return redirect(redirectTo);
};

export const onboardingAnalysisCompleteAction = async ({
  request,
}: ActionFunctionArgs) => {
  const { redirectTo, cultureCode, yearOfBirth } = z
    .object({
      redirectTo: z.string(),
      cultureCode: z.string(),
      yearOfBirth: z.string().length(4),
    })
    .parse(await request.json());

  const user = await authProvider.getUser();
  const updateData: Partial<User> = {};
  if (user.cultureCode !== cultureCode) {
    updateData.cultureCode = cultureCode;
  }
  if (!user.dateOfBirth?.startsWith(`${yearOfBirth}-01-01`)) {
    updateData.dateOfBirth = new Date(`${yearOfBirth}-01-01`).toISOString();
  }

  if (Object.keys(updateData).length > 0) {
    await authProvider.updateUser(updateData);

    if (updateData.cultureCode) {
      // Invalidate motivation data caches
      // TODO: Rationalize motivation data cache refreshing
      await queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
      await queryClient.invalidateQueries({ queryKey: ['motivations'] });
      await queryClient.invalidateQueries({ queryKey: ['dashboard', 'data'] });
      await queryClient.invalidateQueries({ queryKey: ['modeling'] });
    }
  }

  return redirect(redirectTo);
};
