import { useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import type { UserIdEmail } from '../workspace/actions';
import { getWorkspaceFromParams } from '../workspace/queries';
import { accessListQuery, accessPendingListQuery } from './queries';

export async function inviteWorkspaceAccess({ request, params }: ActionFunctionArgs) {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw new Error('Workspace does not exist');

  const data = (await request.json()) as {
    accessType: 'admin' | 'consultant' | 'support';
    data: UserIdEmail[];
  };
  const invites = apiClient.post(`/api/v4/workspaces/${workspace.id}/access`, data);

  await queryClient.invalidateQueries(accessPendingListQuery(workspace.id));
  return invites;
}

export type WorkspaceInviteAccessActionData = Exclude<
  Awaited<ReturnType<typeof inviteWorkspaceAccess>>,
  Promise<Response> | Response
>;

export const useInviteWorkspaceAccess = () => {
  const submit = useSubmit();
  return (data: {
    accessType: 'admin' | 'consultant' | 'support';
    data: UserIdEmail[];
  }) =>
    submit(data, {
      action: '.',
      method: 'POST',
      encType: 'application/json',
      navigate: false,
    });
};

export async function workspaceAccessAction({ request, params }: ActionFunctionArgs) {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw new Error('Workspace does not exist');
  const { accessId } = z.object({ accessId: z.coerce.number() }).parse(params);

  if (request.method === 'DELETE') {
    // Delete the access entry
    const access = await apiClient.delete(
      `/api/v4/workspaces/${workspace.id}/access/${accessId}`,
    );
    // Invalidate teh access list
    await queryClient.invalidateQueries(accessListQuery(workspace.id));
    return access;
  }

  return null;
}

export const useRemoveWorkspaceAccess = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}) => {
  const submit = useSubmit();
  return (accessId: number) =>
    submit(null, {
      action: `/spaces/${workspaceSlug}/people/access/${accessId}`,
      method: 'DELETE',
      encType: 'application/json',
      navigate: false,
    });
};
