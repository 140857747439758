import { useCallback, useMemo, useState } from 'react';
import { useFetcher } from 'react-router-dom';

import { Button, cn, DocumentUploader, Input, InputAutocomplete, Label } from '@f4s/ui';

import { CityAutocomplete } from '@/components/city-autocomplete';
import { PageHeader } from '@/components/page-header';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';
import { availabilityTypeMap } from '@/modules/talent/profile/queries';

import type { onboardingTalentLoader } from '../loaders';

type Selection = {
  label: string;
  key: string;
  value: string;
};

const availabilitySelections = availabilityTypeMap.map((t) => ({ ...t, key: t.value }));

export const OnboardingTalentProfilePage = () => {
  const { user, profile } = usePersistedDataFromLoader<typeof onboardingTalentLoader>();

  // Availability
  const availabilitySelection =
    availabilitySelections.find((g) => g.value === profile?.availability) ??
    availabilitySelections[0];
  const [availability, setAvailability] = useState<Selection | null>(
    availabilitySelection ?? null,
  );
  const [availabilitySearch, setAvailabilitySearch] = useState<string>('');
  const filteredAvailabilitySelections = useMemo(() => {
    const rgx = new RegExp(availabilitySearch, 'i');
    return availabilitySelections.filter((c) => rgx.test(c.label) || rgx.test(c.value));
  }, [availabilitySearch]);

  const [file, setFile] = useState<File | null>(null);
  const handleFileSelected = useCallback((selectedFile: File) => {
    setFile(selectedFile);
  }, []);

  const [firstName, setFirstName] = useState<string>(user.firstName ?? '');
  const handleFirstName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const [lastName, setLastName] = useState<string>(user.lastName ?? '');
  const handleLastName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);

  const [location, setLocation] = useState<string>(profile?.location ?? '');
  const handleLocation = useCallback((newLocation: string | null) => {
    setLocation(newLocation ?? '');
  }, []);

  const [country, setCountry] = useState<string>(
    profile?.countryCode ?? user.countryCode ?? '',
  );
  const handleCountry = useCallback((newCountry: string | null) => {
    setCountry(newCountry ?? '');
  }, []);

  const fetcher = useFetcher();
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const userData: Partial<{
        firstName: string;
        lastName: string;
        countryCode: string;
      }> = {};

      const profileData: Partial<{
        location: string;
        countryCode: string;
        availability: string | null;
      }> = {};

      // User data
      if (firstName !== user.firstName) {
        userData.firstName = firstName;
      }
      if (lastName !== user.lastName) {
        userData.lastName = lastName;
      }
      if (country && country !== user.countryCode) {
        userData.countryCode = country;
      }

      // Profile data
      if (availability && availability.value !== profile?.availability) {
        profileData.availability =
          availability.value === 'null' ? null : availability.value;
      }
      if (location && location !== profile?.location) {
        profileData.location = location;
      }
      if (country && country !== profile?.countryCode) {
        profileData.countryCode = country;
      }

      const formData = new FormData();
      formData.append('redirectTo', '../analysis');
      formData.append('userData', JSON.stringify(userData));
      formData.append('profileData', JSON.stringify(profileData));
      if (file) {
        formData.append('file', file);
      }
      if (profile) {
        formData.append('profileId', String(profile.id));
      }

      return fetcher.submit(formData satisfies FormData, {
        method: 'POST',
        encType: 'multipart/form-data',
      });
    },
    [
      availability,
      country,
      fetcher,
      file,
      firstName,
      lastName,
      location,
      profile,
      user.countryCode,
      user.firstName,
      user.lastName,
    ],
  );

  const disabled =
    fetcher.state !== 'idle' ||
    (!file && !profile?.uploadName) ||
    !firstName ||
    !lastName ||
    !location ||
    !availability;

  const fileName = file?.name ?? profile?.uploadName;

  return (
    <div className="flex flex-col gap-6 p-4 sm:p-6">
      <PageHeader title="Update your talent profile" />
      <form className="flex w-full flex-col items-center gap-6" onSubmit={handleSubmit}>
        <div className="flex w-full flex-col gap-6 sm:flex-row">
          <div className="ring-border/5 relative flex max-h-[228px] w-full flex-col items-center justify-evenly rounded-lg p-4 ring-1 sm:w-1/3">
            <DocumentUploader
              onFileSelected={handleFileSelected}
              currentFile={profile?.uploadName ? { name: profile.uploadName } : undefined}
              className="bg-card text-muted-foreground aspect-square h-28 w-28 rounded-full shadow-none ring-inset"
              labelledBy="resume-label"
              // hideIcon
            />
            <div className="pointer-events-none flex max-w-full flex-col p-2">
              {/* <div
                className={cn(
                  'flex flex-1 items-center justify-center pt-5',
                  !fileName && 'text-muted-foreground',
                )}
              >
                {fileName ? (
                  fileName.endsWith('.pdf') ? (
                    <Icon.FilePdf size="32" />
                  ) : (
                    <Icon.FileDoc size="32" />
                  )
                ) : (
                  <Icon.Plus size="32" />
                )}
              </div> */}
              <Label
                id="resume-label"
                className={cn(
                  'line-clamp-2 w-full text-balance break-words text-center font-normal',
                  !fileName && 'text-muted-foreground',
                )}
              >
                {fileName ?? (
                  <>
                    Upload <br />
                    your CV *
                  </>
                )}
              </Label>
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-6 sm:w-2/3">
            <div className="flex w-full flex-col gap-1">
              <Label>
                Name <span className="text-muted-foreground">*</span>
              </Label>
              <div className="flex flex-row gap-2">
                <Label className="sr-only">First Name</Label>
                <Input
                  type="text"
                  value={firstName}
                  onChange={handleFirstName}
                  placeholder="First"
                  required
                />
                <Label className="sr-only">Last Name</Label>
                <Input
                  type="text"
                  value={lastName}
                  onChange={handleLastName}
                  placeholder="Last"
                  required
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-1">
              <Label>
                Work Availability <span className="text-muted-foreground">*</span>
              </Label>
              <InputAutocomplete
                onValueChange={setAvailabilitySearch}
                selection={availability}
                selections={filteredAvailabilitySelections}
                onSelectionChange={setAvailability}
                hideIcon
              />
            </div>

            <div className="flex w-full flex-col gap-1">
              <Label>
                Location <span className="text-muted-foreground">*</span>
              </Label>
              <CityAutocomplete
                location={location}
                onLocationChange={handleLocation}
                onCountryCodeChange={handleCountry}
                hideIcon
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Button type="submit" disabled={disabled}>
            {fetcher.state === 'submitting' ? 'Updating' : 'Update'}
          </Button>
        </div>
      </form>
    </div>
  );
};
