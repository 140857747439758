import { redirect, type RouteObject } from 'react-router-dom';

import { userCreditAddAction, userMasqueradeAction } from './actions';
import { adminLoader, adminUserListLoader, adminUserLoader } from './loaders';

export const adminRoutes: RouteObject = {
  path: 'admin',
  handle: { crumb: { title: 'Admin' } },
  loader: adminLoader,
  async lazy() {
    const { AdminLayout } = await import('./pages/layout');
    return { Component: AdminLayout };
  },
  children: [
    { index: true, loader: () => redirect('users') },
    {
      path: 'users',
      children: [
        {
          index: true,
          loader: adminUserListLoader,
          async lazy() {
            const { AdminUserList } = await import('./pages/user-list');
            return { Component: AdminUserList };
          },
        },
        {
          path: ':userId',

          children: [
            {
              index: true,
              loader: adminUserLoader,
              async lazy() {
                const { AdminUserPage } = await import('./pages/user-page');
                return { Component: AdminUserPage };
              },
            },
            {
              path: 'masquerade',
              action: userMasqueradeAction,
            },
            {
              path: 'credits',
              action: userCreditAddAction,
            },
          ],
        },
      ],
    },
  ],
};
