import type { RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { workspaceInviteAction } from './actions';
import { workspaceInviteLoader, workspaceInvitesLoader } from './loaders';

export const workspaceInviteRoutes: RouteObject = {
  path: 'invites',
  handle: handle({ type: 'invites', breadcrumb: SimpleBreadcrumb({ title: 'Invites' }) }),
  children: [
    {
      index: true,
      loader: workspaceInvitesLoader,
      async lazy() {
        const { WorkspaceInviteList } = await import('./pages/list');
        return { Component: WorkspaceInviteList };
      },
    },
    {
      path: ':inviteId',
      loader: workspaceInviteLoader,
      action: workspaceInviteAction,
      async lazy() {
        const { WorkspaceInvitePage } = await import('./pages/page');
        return { Component: WorkspaceInvitePage };
      },
    },
  ],
};
