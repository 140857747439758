import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { talentProfileCreateReqSchema, talentProfileUpdateReqSchema } from '@f4s/types';
import { toast } from '@f4s/ui';

import type { TalentProfileDetails } from './queries';

const parseFormDataToJson = (formData: FormData) => {
  return Object.fromEntries(
    [...formData.entries()].map(([key, value]) => {
      try {
        // Attempt to JSON parse each value
        return [key, JSON.parse(value as string)];
      } catch {
        // If parsing fails, fall back to original value (e.g., for non-JSON strings)
        return [key, value];
      }
    }),
  );
};

const uploadDocument = async ({
  profileId,
  file,
}: {
  profileId: number;
  file: FormDataEntryValue;
}) => {
  if (file instanceof File && file.name) {
    const imageFormData = new FormData();
    imageFormData.append('file', file);

    try {
      return apiClient.post(
        `/api/v4/talent/profiles/${profileId}/upload`,
        imageFormData,
        { isFormData: true },
      ) as Promise<TalentProfileDetails>;
    } catch (error) {
      console.error('Error uploading document for talent profile', error);
      toast({ title: 'Could not upload document for profile' });
    }
  }
};

// Create new talent profile
export const createTalentProfileAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  // Handle file separately
  const file = formData.get('file');
  formData.delete('file');

  const data = talentProfileCreateReqSchema.parse(parseFormDataToJson(formData));
  let talentProfile = (await apiClient.post(
    `/api/v4/talent/profiles`,
    data,
  )) as TalentProfileDetails;

  if (file) {
    const updatedProfile = await uploadDocument({ profileId: talentProfile.id, file });
    if (updatedProfile) {
      talentProfile = updatedProfile;
    }
  }
  // Update the cache with the returned profile.
  queryClient.setQueryData(['talent', 'profiles', talentProfile.id], talentProfile);

  await queryClient.invalidateQueries({
    queryKey: ['talent', 'profiles', 'list'],
  });
  // Redirect to newly created profile
  return redirect(`../${talentProfile.id}`);
};

export function useCreateTalentProfile() {
  // form encoded fetcher
  const fetcher = useFetcher<Awaited<ReturnType<typeof createTalentProfileAction>>>();
  return {
    ...fetcher,
    submit: ({ data }: { data: FormData }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'multipart/form-data',
      }),
  };
}

// Update talent profile
export const updateTalentProfileAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { profileId } = z.object({ profileId: z.coerce.number() }).parse(params);

  // DELETE
  if (request.method === 'DELETE') {
    const deletedProfile = (await apiClient.delete(
      `/api/v4/talent/profiles/${profileId}`,
    )) as TalentProfileDetails;

    await queryClient.invalidateQueries({
      queryKey: ['talent', 'profiles', 'list'],
    });

    if (deletedProfile.pools.length > 0) {
      await queryClient.invalidateQueries({
        queryKey: ['talent', 'pools', 'public', 'list'],
      });
      for (const { pool } of deletedProfile.pools) {
        await queryClient.invalidateQueries({
          queryKey: ['talent', 'pools', 'public', pool.id],
        });
      }
    }

    return redirect('..');
  }

  const formData = await request.formData();
  // Handle file separately
  const file = formData.get('file');
  formData.delete('file');

  const d = parseFormDataToJson(formData);
  const data = talentProfileUpdateReqSchema.parse(d);

  // TODO: zod parse data
  let talentProfile = (await apiClient.patch(
    `/api/v4/talent/profiles/${profileId}`,
    data,
  )) as TalentProfileDetails;
  if (file) {
    const updatedProfile = await uploadDocument({ profileId: talentProfile.id, file });
    if (updatedProfile) {
      talentProfile = updatedProfile;
    }
  }
  // Update the cache with the returned profile.
  queryClient.setQueryData(['talent', 'profiles', profileId], talentProfile);
  await queryClient.invalidateQueries({
    queryKey: ['talent', 'profiles', 'list'],
  });

  return talentProfile;
};

export function useUpdateTalentProfile() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateTalentProfileAction>>>();
  return {
    ...fetcher,
    submit: ({
      data = null,
      method = 'POST',
    }:
      | {
          data: FormData | null;
          method?: 'POST';
        }
      | {
          data?: null;
          method: 'DELETE';
        }) =>
      fetcher.submit(data, {
        method,
        encType: 'multipart/form-data',
      }),
  };
}

export const removeLegacyTagAction = async ({ request, params }: ActionFunctionArgs) => {
  const { tagId } = z.object({ tagId: z.coerce.number() }).parse(params);
  if (request.method === 'DELETE') {
    await apiClient.delete(`/api/v4/talent/profiles/tags/${tagId}`);
    await queryClient.invalidateQueries({
      queryKey: ['talent', 'tags'],
    });
    return null;
  }
};

export function useRemoveLegacyTag() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof removeLegacyTagAction>>>();
  return {
    ...fetcher,
    submit: ({ tagId }: { tagId: number }) =>
      fetcher.submit(null, {
        method: 'DELETE',
        encType: 'multipart/form-data',
        action: `/talent/profile/tags/${tagId}`,
      }),
  };
}
