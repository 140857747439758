import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import React from 'react';

import { cn } from '../lib/utils';

const ScrollBar = React.forwardRef<
  React.ComponentRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={cn(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-px',
      orientation === 'horizontal' && 'h-2.5 border-t border-t-transparent p-px',
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="bg-border/5 relative flex-1 rounded-full" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export type ScrollAreaProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaPrimitive.Root
> & {
  justify?: 'start' | 'end' | 'center';
  gap?: 'sm' | 'md' | 'lg';
  scrollBarClassName?: string;
  orientation?: 'vertical' | 'horizontal';
  viewportClassName?: string;
};

// TODO: clean up scroll to use CVA
const ScrollArea = React.forwardRef<
  React.ComponentRef<typeof ScrollAreaPrimitive.Root>,
  ScrollAreaProps
>(
  (
    {
      className,
      viewportClassName,
      scrollBarClassName,
      children,
      justify,
      gap,
      orientation,
      ...props
    },
    ref,
  ) => (
    <ScrollAreaPrimitive.Root
      className={cn('!static flex min-h-0 flex-col overflow-hidden', className)}
      {...props}
    >
      {/* [&>div]:!flex [&>div]:!min-h-full [&>div]:!flex-col [&>div]:!overflow-hidden */}
      <ScrollAreaPrimitive.Viewport
        className={cn(
          'h-full min-h-0 w-full flex-col rounded-[inherit] [&>div]:!flex [&>div]:!min-h-full [&>div]:!flex-col',
          justify === 'start'
            ? '[&>div]:justify-start'
            : justify === 'end'
              ? '[&>div]:justify-end'
              : justify === 'center' && '[&>div]:justify-center',
          gap === 'sm'
            ? '[&>div]:gap-1'
            : gap === 'md'
              ? '[&>div]:gap-2'
              : gap === 'lg'
                ? '[&>div]:gap-4'
                : undefined,
          orientation === 'horizontal' && '!overflow-x-auto !overflow-y-hidden',
          viewportClassName,
        )}
        ref={ref}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar
        className={cn('z-[100]', scrollBarClassName)}
        orientation={orientation ?? 'vertical'}
      />
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  ),
);
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

export { ScrollArea, ScrollBar };
