import { Outlet, useOutletContext, useRouteError } from 'react-router-dom';

import type { RootContextType } from '@/modules/root/types';

// TODO Possibly need to make a scrollable public layout as assessment requires a fixed height

export const PublicLayout = () => {
  const error = useRouteError();
  const context = useOutletContext<RootContextType>();

  return (
    <div ref={context.modalCallbackRef} className="bg-sand-25">
      {error ? <pre>{JSON.stringify(error, null, 2)}</pre> : <Outlet context={context} />}
    </div>
  );
};
