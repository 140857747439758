import { camelCase, upperFirst } from 'lodash-es';
import { type FetcherWithComponents } from 'react-router-dom';

import { type Json } from '@f4s/types/utils';

const andRegex = /( ?& ?)/g;
const plusRegex = /( ?\+ ?)/g;
const slugRegex = /[^\d A-Za-z]+/g;
const spaceRegex = / +/g;
export const slugify = (str: string) =>
  str
    .replaceAll(andRegex, ' and ')
    .replaceAll(plusRegex, ' plus ')
    .replaceAll(slugRegex, '')
    .trim()
    .replaceAll(spaceRegex, '-')
    .toLowerCase();

export const extractIdFromSlug = (str: string) => Number(str.split('-').slice(-1));
export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

// Searches backwards from match point; if it finds sentence boundary or
// start of string, it capitalizes the replacement

// TODO: Consider something more simple
const replaceWithSentenceBoundaries =
  (vars: { [x: string]: string }) =>
  (_m: string, key: string, ...args: unknown[]) => {
    // Note: see replacer type definition, number of arguments is non-deterministic
    const original = args.pop() as string;
    const matchPos = args.pop() as number;

    let pos = matchPos - 1;
    while (pos >= 0) {
      if (pos === 0 || ['.', '!', '?'].includes(original.charAt(pos))) {
        return capitalize(vars[key] ?? '');
      }
      if (!/\s/.test(original.charAt(pos))) return vars[key] ?? '';
      pos--;
    }
    return vars[key] ?? '';
  };

/** Replaces ${}/{{}} template values in a given string  */
export const interpolate = (string: string, vars: { [x: string]: string }) => {
  const replaceFunc = replaceWithSentenceBoundaries(vars);
  return string
    .replaceAll(/\${(\w+)}/g, replaceFunc)
    .replaceAll(/{{(\w+)}}/g, replaceFunc);
};

/** Formats a number with an ordinal suffix -`st,nd,rd,th` */
export const ordinalize = (number: number) =>
  // prettier-ignore
  String(number) + (
    Math.floor(number % 100 / 10) === 1 ? 'th'
    : number % 10 === 1 ? 'st'
    : number % 10 === 2 ? 'nd'
    : number % 10 === 3 ? 'rd'
    : 'th'
  );

// Title case
export const titleCase = (str: string) =>
  str.toLowerCase().replaceAll(/\b\w/g, (s) => s.toUpperCase());

export const pascalCase = (str: string) => upperFirst(camelCase(str));

/**
 * Shuffle using the fisher-yates algorithm
 */
export const shuffle = <T>(array: T[]): T[] => {
  const length = array == null ? 0 : array.length;
  if (!length) return [];
  let index = -1;
  const lastIndex = length - 1;
  const result = [...array];
  while (++index < length) {
    const rand = index + Math.floor(Math.random() * (lastIndex - index + 1));
    const value = result[rand];
    result[rand] = result[index]!;
    result[index] = value!;
  }
  return result;
};

export function callAction<T>(
  action: string,
  request: Json,
  fetcher: FetcherWithComponents<T>,
  method?: 'get' | 'post' | 'put' | 'delete',
) {
  fetcher.submit(request, {
    method: method ?? 'post',
    action,
    encType: 'application/json',
  });
}

export const stddev = (arr: number[]) => {
  const n = arr.length;
  if (n === 0) return 0;
  const mean = arr.reduce((a, b) => a + b) / n;
  return Math.sqrt(arr.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
};

export const approximateAge = (time: Date) => {
  const age = (Date.now() - time.getTime()) / (60 * 1000);
  const minutes = Math.floor(age);
  const hours = Math.floor(age / 60);
  const days = Math.floor(age / (24 * 60));
  const months = Math.floor(age / (30 * 24 * 60));
  const years = Math.floor(age / (365 * 24 * 60));
  if (minutes < 5) return 'Now';
  if (hours < 1) return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  if (days < 1) return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  if (months < 1) return `${days} day${days === 1 ? '' : 's'} ago`;
  if (years < 1) return `${months} month${months === 1 ? '' : 's'} ago`;
  return `${years} year${years === 1 ? '' : 's'} ago`;
};

// partial fisher-yates shuffle
export const randomSample = <T>(arr: T[], n: number): T[] => {
  const result: T[] = Array.from({ length: n });
  let len = arr.length;
  const taken: number[] = Array.from({ length: len });
  if (n > len) throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[taken[x] ?? x]!;
    len -= 1;
    taken[x] = taken[len] ?? len;
  }
  return result;
};
