import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { fetchInviteSent, fetchInvitesSent } from '../invites/queries';
import { getWorkspaceFromParams } from '../workspace/queries';

export const workspaceInvitesLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw redirect('/spaces'); // this should not happen

  const invites = await fetchInvitesSent({ workspaceId: workspace.id });
  return { workspace, invites };
};

export const workspaceInviteLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw redirect('/spaces'); // this should not happen

  const { inviteId } = z.object({ inviteId: z.coerce.number() }).parse(params);
  const invite = await fetchInviteSent({ inviteId, workspaceId: workspace?.id });

  return { workspace, invite };
};
