import { authProvider } from '@f4s/api-client';

import { updateQueryFavorites } from '../queries/actions';
import {
  clearOnboardingJtbdQueryIds,
  clearOnboardingJtbdSlug,
  getOnboardingJtbdQueryIds,
  getOnboardingJtbdSlug,
} from './utils';

export const updateUserFromPreSignup = async () => {
  const user = await authProvider.getMaybeUser();
  if (!user) return;

  const jtbdCategory = getOnboardingJtbdSlug();
  if (jtbdCategory) {
    try {
      await authProvider.updateUser({ properties: { jtbdCategory } });
      clearOnboardingJtbdSlug();
    } catch (error) {
      console.error('Error updating onboardingJtbdSlug for user', error);
    }
  }

  const jtbdQueryIds = getOnboardingJtbdQueryIds();
  if (jtbdQueryIds.length > 0) {
    try {
      await updateQueryFavorites({ add: jtbdQueryIds, remove: [] });
      clearOnboardingJtbdQueryIds();
    } catch (error) {
      console.error('Error updating onboardingJtbdQueryIds for user', error);
    }
  }
};
