import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';

import { cn } from '../lib/utils';

type ProgressProps = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  indicatorClassName?: string;
  containerClassName?: string;
};

const Progress = React.forwardRef<
  React.ComponentRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, indicatorClassName, containerClassName, ...props }, ref) => (
  <div
    className={cn(
      'ring-border/5 mt-4 w-full rounded-full bg-white/10 p-1 shadow-sm ring-1',
      containerClassName,
    )}
  >
    <ProgressPrimitive.Root
      ref={ref}
      className={cn(
        'bg-muted relative h-2 w-full overflow-hidden rounded-full',
        className,
      )}
      {...props}
    >
      <ProgressPrimitive.Indicator
        className={cn(
          'h-full w-full flex-1 rounded-full shadow-sm transition-all',
          'bg-gradient-to-r from-indigo-900 via-pink-600 to-orange-500',
          indicatorClassName,
        )}
        // style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        style={{ clipPath: `inset(0% ${100 - (value || 0)}% 0% 0% round 0.375rem)` }}
      />
    </ProgressPrimitive.Root>
  </div>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
