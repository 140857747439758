import { type LoaderFunction, type RouteObject } from 'react-router-dom';

import { ErrorPage } from '@/modules/root/pages/error';

import {
  motivationGroupLoader,
  motivationLoader,
  overTimeLoader,
  questionnaireLoader,
  underlyingDataIndexLoader,
  underlyingDataMotivationGroupLoader,
  underlyingDataMotivationLoader,
} from './loaders';

export const motivationsRouter = ({
  path,
  handle,
  loader = questionnaireLoader,
}: {
  path: string;
  handle?: { [key: string]: unknown };
  loader?: LoaderFunction;
}): RouteObject => ({
  path,
  async lazy() {
    const { MotivationsLayout } = await import('./pages/layout');
    return { Component: MotivationsLayout };
  },
  ErrorBoundary: ErrorPage,
  handle,
  loader,
  children: [
    {
      path: ':motivationGroupId',
      loader: motivationGroupLoader,
      async lazy() {
        const { MotivationGroup } = await import('./pages/motivation-group-layout');
        return { Component: MotivationGroup };
      },
      handle: { crumb: { type: 'motivationGroup' } },
      children: [
        {
          path: ':motivationId',
          loader: motivationLoader,
          async lazy() {
            const { Motivation } = await import('./pages/motivation');
            return { Component: Motivation };
          },
          handle: { crumb: { type: 'motivation' } },
        },
      ],
    },
  ],
});

export const overTimeRouter = ({
  path,
  handle,
  loader = overTimeLoader,
}: {
  path: string;
  handle?: { [key: string]: unknown };
  loader?: LoaderFunction;
}): RouteObject => ({
  path,
  handle,
  loader,
  async lazy() {
    const { OverTime } = await import('./pages/over-time');
    return { Component: OverTime };
  },
});

export const underlyingDataChildRouter: RouteObject[] = [
  {
    path: ':motivationGroupSlug',
    loader: underlyingDataMotivationGroupLoader,
    async lazy() {
      const { UnderlyingDataMotivationGroupHandle } = await import(
        './pages/underlying-data'
      );
      return {
        handle: UnderlyingDataMotivationGroupHandle(),
      };
    },
    children: [
      {
        index: true,
        loader: underlyingDataMotivationGroupLoader,
        async lazy() {
          const { UnderlyingDataMotivationGroup } = await import(
            './pages/underlying-data'
          );
          return {
            Component: UnderlyingDataMotivationGroup,
          };
        },
      },
      {
        path: ':motivationSlug',
        loader: underlyingDataMotivationLoader,
        async lazy() {
          const { UnderlyingDataMotivation, UnderlyingDataMotivationHandle } =
            await import('./pages/underlying-data');
          return {
            Component: UnderlyingDataMotivation,
            handle: UnderlyingDataMotivationHandle(),
          };
        },
      },
    ],
  },
];

export const underlyingDataRouter = ({
  path = 'underlying-data',
  handle,
}: {
  path?: string;
  handle?: { [key: string]: unknown };
}): RouteObject => ({
  path,
  handle,
  async lazy() {
    const { UnderlyingDataLayout } = await import('./pages/underlying-data');
    return { Component: UnderlyingDataLayout };
  },
  children: [
    {
      index: true,
      loader: underlyingDataIndexLoader,
    },
    ...underlyingDataChildRouter,
  ],
});
