import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { fetchMembers } from '@/modules/member/queries';
import { fetchWorkspace } from '@/modules/workspace/queries';

import { fetchWorkspaceDistribution, getTeamDistribution } from '../motivation/queries';
import { fetchTeam, fetchTeamList } from './queries';

export const teamListLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug: string };
  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) return redirect('..');

  const members = await fetchMembers({ workspaceSlug });
  const teams = await fetchTeamList({ workspaceId: workspace?.id });
  return { workspace, members, teams };
};

export const teamLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug, teamId } = params as {
    workspaceSlug: string;
    teamId: string;
  };
  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) return redirect('..');

  const team = await fetchTeam({
    workspaceId: workspace?.id,
    teamId: teamId,
  });

  const teamMembers = await fetchMembers({
    workspaceSlug,
    teamId: String(team.id),
  });
  const filteredTeamMembers = teamMembers.flatMap((m) =>
    m.id === null ? [] : { ...m, id: m.id },
  );

  const workspaceMembers = await fetchMembers({ workspaceSlug });
  const filteredWorkspaceMembers = workspaceMembers.flatMap((m) =>
    m.id === null ? [] : { ...m, id: m.id },
  );

  // Team motivation details
  const { motivations, distributions } = await getTeamDistribution({
    teamId: Number(teamId),
    workspaceSlug,
  });

  const { distributions: workspaceDistributions } = await fetchWorkspaceDistribution({
    workspaceSlug,
  });
  distributions.push(...workspaceDistributions);

  return {
    workspace,
    workspaceMembers: filteredWorkspaceMembers,
    team,
    teamMembers: filteredTeamMembers,
    distributions,
    motivations,
  };
};

export const teamCreateLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as {
    workspaceSlug: string;
  };

  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) return redirect('..');

  const workspaceMembers = await fetchMembers({ workspaceSlug });
  // Filter out any invited new users
  const filteredMembers = workspaceMembers.flatMap((m) =>
    m.id === null ? [] : { ...m, id: m.id },
  );

  return {
    workspace,
    workspaceMembers: filteredMembers,
    team: undefined,
    teamMembers: [],
    distributions: [],
    motivations: [],
  };
};
