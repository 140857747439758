import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useEffect, useState, type ReactNode } from 'react';

import { cn } from '../lib/utils';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { ScrollArea } from './scroll-area';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip';

const PopoverWithTooltip = ({
  tooltipText,
  children,
  tooltipClassName,
}: {
  tooltipText: string;
  children: ReactNode;
  tooltipClassName?: string;
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
      </TooltipTrigger>
      <TooltipContent className={tooltipClassName}>{tooltipText}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);
export interface ComboboxProps extends React.ComponentPropsWithoutRef<typeof Button> {
  selections: readonly { value: string; label: string }[];
  placeholder?: string;
  searchPlaceholder?: string;
  emptyString?: string;
  value?: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  prefix?: string;
  size?: 'sm' | 'lg' | 'icon';
  align?: 'start' | 'end' | 'center';
  titleClassName?: string;
  /**
   * Warning: Do not trust value will be the same as selections, values are lowercased and trimmed,
   * also values can be empty string.
   * - See: https://github.com/pacocoursey/cmdk/issues/150
   * */
  onValueChange?: (_value: string) => void;
  inputClassName?: string;
  itemClassName?: string;
  contentClassName?: string;
  portalContainer?: HTMLElement | null;
  tooltipText?: string;
  tooltipClassName?: string;
  showActiveSelection?: boolean;
  showListFilter?: boolean;
  popoverPosition?: string;
}

export const Combobox = ({
  selections = [],
  prefix,
  placeholder = 'Select',
  searchPlaceholder = 'Search',
  emptyString = 'No option found.',
  value: externalValue = '',
  disabled = false,
  className,
  children,
  size,
  align = 'start',
  titleClassName,
  variant,
  onValueChange,
  inputClassName,
  itemClassName,
  contentClassName,
  portalContainer,
  tooltipText,
  tooltipClassName,
  showActiveSelection = false,
  showListFilter = false,
}: ComboboxProps) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(externalValue);

  useEffect(() => {
    setValue(externalValue);
  }, [externalValue]);

  let title = placeholder;
  const activeSelection = selections.find((selection) => selection.value === value);
  if (activeSelection) {
    title = [prefix, activeSelection.label].filter(Boolean).join(' ');
  }

  const renderButton = () => (
    <Button
      variant={variant ?? 'outline'}
      role="combobox"
      aria-expanded={open}
      className={cn(
        'bg-card hover:bg-card hover:ring-border/20 flex items-center gap-2',
        className,
      )}
      disabled={disabled}
      size={size}
    >
      {children ?? (
        <>
          <div className={cn('flex-1 truncate', titleClassName)}>{title}</div>
          <CaretSortIcon className="h-4 w-4 shrink-0 opacity-50" />
        </>
      )}
    </Button>
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {tooltipText ? (
        <PopoverWithTooltip tooltipText={tooltipText} tooltipClassName={tooltipClassName}>
          {renderButton()}
        </PopoverWithTooltip>
      ) : (
        <PopoverTrigger asChild>{renderButton()}</PopoverTrigger>
      )}
      <PopoverContent
        className={cn(
          'bg-popover z-[100] max-h-[calc(var(--radix-popper-available-height)_-_1rem)] overflow-hidden p-0',
          contentClassName,
        )}
        align={align}
        avoidCollisions={true}
        collisionPadding={16}
        asChild
        portalContainer={portalContainer}
      >
        <Command className="h-auto">
          {showListFilter && (
            <CommandInput
              placeholder={searchPlaceholder}
              className={cn('h-8', inputClassName)}
              wrapperClassName="flex h-8 items-center gap-2 px-2 py-1 border-b"
            />
          )}

          <ScrollArea className="flex flex-col">
            <CommandList>
              <CommandEmpty className="p-2">{emptyString}</CommandEmpty>
              {activeSelection && showActiveSelection && (
                <>
                  <CommandGroup className="p-2" heading="Currently Selected">
                    <CommandItem
                      value={activeSelection.value}
                      onSelect={(currentValue) => {
                        const newValue = currentValue === value ? '' : currentValue;
                        setValue(newValue);
                        onValueChange?.(newValue);
                        setOpen(false);
                      }}
                      className={cn('rounded-sm hover:cursor-pointer', itemClassName)}
                    >
                      {activeSelection.label}
                      <CheckIcon className={cn('ml-auto h-4 w-4')} />
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}
              <CommandGroup>
                {selections.map((selection) => (
                  <CommandItem
                    key={selection.value}
                    value={selection.value}
                    onSelect={(currentValue) => {
                      const newValue = currentValue === value ? '' : currentValue;
                      setValue(newValue);
                      onValueChange?.(newValue);
                      setOpen(false);
                    }}
                    className={cn('rounded-sm hover:cursor-pointer', itemClassName)}
                  >
                    {selection.label}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        value === selection.value ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
