import { type ReactNode } from 'react';

import { cn } from '@f4s/ui';

type PageHeaderProps =
  | {
      title: ReactNode;
      children?: ReactNode;
      description?: string;
      className?: string;
      style?: 'banner';
      contentClassName?: string;
    }
  | {
      title?: undefined;
      children: ReactNode;
      description?: string;
      className?: string;
      style?: 'banner';
      contentClassName?: string;
    };

export const PageHeaderTitle = ({
  title,
  className,
}: {
  title: ReactNode;
  className?: string;
}) => {
  return <h1 className={cn('truncate text-2xl font-semibold tracking-tight', className)}>{title}</h1>;
};

export const PageHeader = ({
  title,
  className,
  description,
  children,
  style,
  contentClassName,
}: PageHeaderProps) => {
  return (
    <header
      className={cn(
        'flex min-h-9 w-full shrink-0 flex-col items-start justify-center gap-2',
        style === 'banner' && 'bg-secondary rounded-lg px-6 py-8 md:px-8',
        className,
      )}
    >
      <div
        className={cn(
          'flex w-full shrink-0 flex-col justify-between gap-2 sm:flex-row sm:items-center sm:gap-8',
          contentClassName,
        )}
      >
        {title && (typeof title === 'string' ? <PageHeaderTitle title={title} /> : title)}
        {children}
      </div>
      {description && (
        <div className="text-muted-foreground max-w-lg text-pretty">{description}</div>
      )}
    </header>
  );
};
