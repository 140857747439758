import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { createTalentOpeningAction, updateTalentOpeningAction } from './actions';
import {
  talentOpeningLoader,
  talentOpeningNewLoader,
  talentOpeningsLoader,
} from './loaders';

export const talentOpeningRoutes: RouteObject = {
  path: 'openings',
  handle: handle({
    breadcrumb: SimpleBreadcrumb({ title: 'Openings' }),
  }),
  children: [
    {
      index: true,
      loader: talentOpeningsLoader,
      async lazy() {
        const { TalentOpeningList } = await import('./pages');
        return { Component: TalentOpeningList };
      },
    },
    {
      path: 'new',
      handle: handle({
        breadcrumb: SimpleBreadcrumb({ title: 'New' }),
      }),
      loader: talentOpeningNewLoader,
      action: createTalentOpeningAction,
      async lazy() {
        const { TalentOpeningPage } = await import('./pages');
        return { Component: TalentOpeningPage };
      },
    },
    {
      path: ':openingId',
      loader: talentOpeningLoader,
      action: updateTalentOpeningAction,
      async lazy() {
        const { TalentOpeningPage } = await import('./pages');
        return { Component: TalentOpeningPage };
      },
    },
  ],
};
