import { z } from 'zod';

import { type QuestionOptions } from '@f4s/types';

export const saveAndReturnQuestionAnswers = (
  questionNumber: number,
  newAnswers: QuestionOptions[],
) => {
  const answerOrder = newAnswers.map((a) => a.position);
  try {
    const localStorageData = localStorage.getItem('assessmentBlockData');

    // Zod parse
    const savedAnswers = z
      .object({ questionNumber: z.number(), answerOrder: z.number().array() })
      .array()
      .parse(JSON.parse(localStorageData ?? '[]'));

    const index = savedAnswers.findIndex((a) => a.questionNumber === questionNumber);

    let updatedAnswers = [...savedAnswers];

    if (index === -1) {
      updatedAnswers = [...savedAnswers, { questionNumber, answerOrder }];
    } else {
      updatedAnswers[index]!.answerOrder = answerOrder;
    }

    localStorage.setItem('assessmentBlockData', JSON.stringify(updatedAnswers));

    return updatedAnswers;
  } catch (error) {
    console.error('Error merging answers with localStorage', error);
    const updatedAnswers = [{ questionNumber, answerOrder }];
    localStorage.setItem('assessmentBlockData', JSON.stringify(updatedAnswers));
    return updatedAnswers;
  }
};
