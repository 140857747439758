import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { queryClient } from '@f4s/api-client';
import { formatName } from '@f4s/types';
import { toast } from '@f4s/ui';

import { Messages } from '@/modules/invite/invite.messages';
import { getWorkspaceSlugFromId } from '@/modules/workspace/queries';

import { notificationsQuery } from '../notifications/queries';
import { rejectInvite } from './actions';
import { fetchInvitation, pendingInvitesQuery } from './queries';

export const invitationLoader = async ({ params }: LoaderFunctionArgs) => {
  const { token } = params as { token: string };
  if (!token) throw new Error('missing :token in route params');

  const invitation = await fetchInvitation(token);

  return { invitation, token };
};

export type InvitationLoaderData = Exclude<
  Awaited<ReturnType<typeof invitationLoader>>,
  Promise<Response> | Response
>;

export const magicLinkLoader = async ({ params }: LoaderFunctionArgs) => {
  const { token } = params as { token?: string };
  return redirect(token ? `/invites/link/${token}` : '/');

  // Old invite handling
  // const user = await authProvider.getMaybeUser();
  // const link = await fetchMagicLinkByToken({ token: token ?? '' });

  // let isConnected = false;
  // if (user) {
  //   const personalConnections = await fetchPersonalConnections();
  //   isConnected = personalConnections.some(({ id }) => id === link?.userId);
  // }

  // return { link, isConnected };
};

export type MagicLinkLoaderData = Exclude<
  Awaited<ReturnType<typeof magicLinkLoader>>,
  Promise<Response> | Response
>;

export const connectedInviteLoader = async (args: LoaderFunctionArgs) => {
  const { invitation } = await invitationLoader(args);
  if (!invitation) throw new Error("Can't find invitation for this token");
  if (invitation.type === 'connection')
    return {
      message: Messages.nowConnectedTo(formatName(invitation.fromUser!)),
      userId: invitation.fromUser?.id,
      avatar: invitation.fromUser?.avatarUrl,
    };
  if (invitation.type === 'organization') {
    const workspaceSlug = await getWorkspaceSlugFromId(invitation.organizations.id);
    return {
      message: Messages.welcomeTo(invitation.organizations.name),
      workspaceSlug,
      avatar: invitation.organizations.logo,
    };
  }
  throw new Error('Unsupported invitation type');
};

export type ConnectedInviteLoaderData = Exclude<
  Awaited<ReturnType<typeof connectedInviteLoader>>,
  Promise<Response> | Response
>;

export const invitationTokenLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const { action } = params as { token?: string; action?: string };
  let { token } = params as { token?: string; action?: string };

  // BUG WITH EMAIL LINKS
  // TODO: Fix these emails so we don't need to do this
  // ?type=org is being url encoded as part of the path
  let type = new URL(request.url).searchParams.get('type');
  if (token && !type) {
    // Check for malformed token
    try {
      const tempUrl = new URL(token, window.location.origin);
      type = tempUrl.searchParams.get('type');
      if (type) {
        token = tempUrl.pathname.replaceAll('/', '');
      }
    } catch (error) {
      console.log('Error parsing invite token', error);
    }
  }

  // Allow declining an invite from the link in an email without logging in
  if (token && action === 'reject') {
    try {
      await rejectInvite(token);
      toast({
        title: `Connection invite declined`,
        duration: 10_000,
      });
      return redirect('/');
    } catch (error: unknown) {
      if (error instanceof Error === false) throw error;
      toast({
        title: `Error declining invite: ${error.message}`,
        variant: 'destructive',
      });
    }
    await Promise.all([
      queryClient.invalidateQueries(pendingInvitesQuery),
      queryClient.invalidateQueries(notificationsQuery),
    ]);
    // TODO: where do we redirect to after declining the invite?
    return;
  }

  return redirect(`/invites/token/${token}`);

  // The following is old handling

  // if (token && (type === 'org' || type === 'group' || type === 'connection')) {
  //   // We have a valid looking invite request at this point

  //   const user = await authProvider.getMaybeUser();
  //   if (user) {
  //     // If the user is logged in, we can perform the update immediately
  //     await apiClient.post('/api/v3/invitations/update/user', [{ token, type }]);
  //     // And for connection invites we auto-accept
  //     // NOTE: This currently relies on the above update to happen first. Will change when doing more invites stuff
  //     if (type === 'connection') {
  //       try {
  //         await acceptInvite(token);
  //         toast({
  //           title: `You have accepted the connection invite`,
  //           duration: 10_000,
  //         });
  //         return redirect(`/invite/view/${token}/connected`);
  //       } catch (error: unknown) {
  //         if (error instanceof Error === false) throw error;
  //         toast({
  //           title: `Error accepting invite: ${error.message}`,
  //           variant: 'destructive',
  //         });
  //       }
  //     }

  //     // Clear the caches and session storage object and redirect to their notifications
  //     await Promise.all([
  //       queryClient.invalidateQueries(pendingInvitesQuery),
  //       queryClient.invalidateQueries(notificationsQuery),
  //     ]);
  //     sessionStorage.removeItem(OnboardingSessionKeys.Invite);
  //   } else {
  //     // This is a new sign-up or logged-out user and we need to perform the update later

  //     // Get any other pending invite tokens from the session (should always be empty)
  //     const inviteTokens = JSON.parse(
  //       sessionStorage.getItem(OnboardingSessionKeys.Invite) ?? '{}',
  //     ) as Record<string, 'org' | 'group' | 'connection'>;

  //     // Add the current token handled by this route.
  //     inviteTokens[token] = type;

  //     // Save the updated inviteTokens object back to session storage
  //     sessionStorage.setItem(OnboardingSessionKeys.Invite, JSON.stringify(inviteTokens));
  //   }
  // }
  // return redirect(`/invite/view/${token}`);
};
