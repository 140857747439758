import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';
import { Icon } from '@f4s/ui';

import { postLogoutActions } from '../queries';

export const LogoutPage = () => {
  const hasBeenCalled = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Very simple strict-mode handling
    // We only want to call signout once
    if (hasBeenCalled.current) return;
    hasBeenCalled.current = true;

    authProvider
      .signout()
      .then(() => postLogoutActions())
      .then(() => navigate('/login'))
      .catch((error) => {
        console.error(error);
        window.location.pathname = import.meta.env.BASE_URL;
      });
  }, [navigate]);

  return (
    <div className="bg-foreground/50 text-background fixed left-1/2 top-1/2 z-[9999] flex h-24 w-24 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-md backdrop-blur-sm">
      <Icon.SpinnerSmall className="h-7 w-7 animate-spin" />
    </div>
  );
};
