import { redirect, type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';
import { billingRoutes } from '@/modules/billing/routes';

import {
  addEmailAction,
  primaryEmailAction,
  primaryEmailCodeAction,
  primaryEmailRequestAction,
  setPreferences,
  setSlackWorkspace,
  updateProfileAction,
  uploadAvatarAction,
  verifyEmailAction,
  verifyEmailCodeAction,
  verifyEmailRequestAction,
} from './actions';
import {
  preferencesLoader,
  primaryEmailLoader,
  profileLoader,
  slackLinkLoader,
  slackWorkspaceLoader,
  verifyEmailLoader,
} from './loaders';

export const accountRoutes: RouteObject = {
  path: 'account',
  handle: handle({
    type: 'settings',
    breadcrumb: SimpleBreadcrumb({ title: 'My Account' }),
  }),
  children: [
    { index: true, loader: () => redirect('profile') },
    billingRoutes,
    {
      path: 'profile',
      handle: handle({
        type: 'profile',
        breadcrumb: SimpleBreadcrumb({ title: 'Profile' }),
      }),
      loader: profileLoader,
      async lazy() {
        const { ProfileComponent } = await import('./pages/profile');
        return { Component: ProfileComponent };
      },
      action: updateProfileAction,
      children: [
        {
          path: 'avatar',
          action: uploadAvatarAction,
        },
      ],
    },
    {
      path: 'preferences',
      handle: handle({
        type: 'preferences',
        breadcrumb: SimpleBreadcrumb({ title: 'Preferences' }),
      }),
      loader: preferencesLoader,
      async lazy() {
        const { PreferencesPage } = await import('./pages/preferences');
        return { Component: PreferencesPage };
      },
      children: [
        {
          path: 'set',
          action: setPreferences,
        },
      ],
    },
    {
      path: 'slack/workspace/:team',
      loader: slackWorkspaceLoader,
      async lazy() {
        const { SlackWorkspace } = await import('./pages/slack-workspace');
        return { Component: SlackWorkspace };
      },
      action: setSlackWorkspace,
    },
    {
      path: 'slack/link/:token',
      loader: slackLinkLoader,
      async lazy() {
        const { SlackLink } = await import('./pages/slack-link');
        return { Component: SlackLink };
      },
    },
    { path: 'emails', action: addEmailAction },

    { path: 'emails/verify/request', action: verifyEmailRequestAction },
    { path: 'emails/verify', loader: verifyEmailLoader, action: verifyEmailAction },
    { path: 'emails/verify/code', action: verifyEmailCodeAction },

    { path: 'emails/primary/request', action: primaryEmailRequestAction },
    { path: 'emails/primary', loader: primaryEmailLoader, action: primaryEmailAction },
    { path: 'emails/primary/code', action: primaryEmailCodeAction },
  ],
};
