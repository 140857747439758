import { type RouteObject } from 'react-router-dom';

import {
  modelCreateAction,
  modelCreateFromCultureAction,
  modelModifyAction,
} from './actions';
import { baseModelLoader, modelListLoader, modelLoader } from './loaders';

export const modelRoutes: RouteObject = {
  path: 'modeling',
  handle: { crumb: { title: 'Modeling' } },
  children: [
    {
      index: true,
      loader: modelListLoader,
      async lazy() {
        const { Models } = await import('./pages/list');
        return { Component: Models };
      },
    },
    {
      path: 'new',
      async lazy() {
        const { Model } = await import('./pages/new');
        return { Component: Model };
      },
      loader: baseModelLoader,
      action: modelCreateAction,
      handle: { crumb: { title: 'New' } },
    },
    {
      path: 'new/culture',
      action: modelCreateFromCultureAction,
    },
    {
      path: ':modelId',
      async lazy() {
        const { ModelPage } = await import('./pages/page');
        return { Component: ModelPage };
      },
      loader: modelLoader,
      action: modelModifyAction,
      handle: { crumb: { type: 'model' } },
    },
  ],
};
