import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';

export type TalentOpening = { id: number; name: string };
export type TalentOpeningDetails = TalentOpening;

export const workspaceTalentOpeningsQuery = ({
  workspaceId,
}: {
  workspaceId: number;
}) => ({
  queryKey: ['talent', 'openings', 'workspace', workspaceId],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${workspaceId}/talent/openings`) as Promise<
      TalentOpening[]
    >,
});
export const fetchWorkspaceTalentOpenings = ({ workspaceId }: { workspaceId: number }) =>
  queryClient.fetchQuery(workspaceTalentOpeningsQuery({ workspaceId }));
export const useWorkspaceTalentOpenings = ({ workspaceId }: { workspaceId: number }) =>
  useQuery(workspaceTalentOpeningsQuery({ workspaceId }));

export const workspaceTalentOpeningQuery = ({
  workspaceId,
  openingId,
}: {
  workspaceId: number;
  openingId: number;
}) => ({
  queryKey: ['talent', 'openings', 'workspace', workspaceId, openingId],
  queryFn: async () =>
    apiClient.get(
      `/api/v4/workspaces/${workspaceId}/talent/openings/${openingId}`,
    ) as Promise<TalentOpeningDetails>,
});
export const fetchWorkspaceTalentOpening = (params: {
  workspaceId: number;
  openingId: number;
}) => queryClient.fetchQuery(workspaceTalentOpeningQuery(params));
export const useWorkspaceTalentOpening = (params: {
  workspaceId: number;
  openingId: number;
}) => useQuery(workspaceTalentOpeningQuery(params));
