import { type ReactNode } from 'react';

import { Button, cn, Icon, type ButtonProps } from '@f4s/ui';

interface Props {
  className?: string;
  value: number | string;
  title?: string;
  onClick?: (value: number | string) => void;
  isSelected: boolean;
  variant?: ButtonProps['variant'];
  selectedVariant?: ButtonProps['variant'];
  withCheckbox?: boolean;
  children?: ReactNode;
  asChild?: boolean;
  disabled?: boolean;
  testId?: string;
}

export const SelectableButton = ({
  className,
  value,
  title,
  onClick,
  isSelected,
  withCheckbox = false,
  children,
  asChild,
  disabled,
  testId,
}: Props) => {
  return (
    <Button
      className={cn(
        'h-auto max-w-full whitespace-normal py-3 text-left active:ring-2',
        className,
        // isSelected && 'ring-primary ring-2',
        withCheckbox && 'pl-3',
      )}
      variant="secondary"
      asChild={asChild}
      onClick={() => onClick?.(value)}
      disabled={disabled}
      data-testid={testId}
    >
      <div className="flex gap-3 font-normal">
        {withCheckbox && (
          <div
            className={cn(
              'ring-border/5 mt-0.5 h-4 w-4 min-w-4 rounded-sm shadow-sm ring-1',
              isSelected && 'bg-primary',
            )}
          >
            {isSelected && <Icon.Check className="text-primary-foreground size-full" />}
          </div>
        )}

        {children ?? title ?? value}
      </div>
    </Button>
  );
};
