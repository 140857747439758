// Matches titles in the form of **Title text** <Emoji>:
// But is able to handle broken formatting, with stray * and : characters
export const titleRegex = /.*?\*\*(.*?)(\*\W*\*\W*)/g;
const markdownBoldRegex = /(\*+)(\S)(.*?\S)??\1/g;

export type Section = { order: number; title?: string; body?: string };

export const extractTitleBody = (text: string) => {
  const matches = [...text.matchAll(titleRegex)];
  // The matches should each have their main match plus an inner capturing group
  // We only care about the first match per part, for now
  const [match, innerTitle, trailing] = matches[0] ?? [];
  let body = match ? text.replace(match, '').trim() : text;
  // Replace stray bold emphasis
  body = body.replaceAll(markdownBoldRegex, '$2$3');

  const trailingEmoji = trailing?.replaceAll(/[\s*:]/g, '');
  const title = [innerTitle?.replaceAll(/[*:]/g, '').trim(), trailingEmoji]
    .filter(Boolean)
    .join(' ');

  return { title, body };
};

export const splitIntoSections = (data: string) => {
  const sections: Section[] = [];
  let order = 0;
  const rawSections = data.split('\n\n');
  for (const rawSection of rawSections) {
    const parts = rawSection
      .split('\n')
      .map((p) => p.trim())
      .filter(Boolean);
    for (const part of parts) {
      const { title, body } = extractTitleBody(part);
      sections.push({
        order: order++,
        title,
        body,
      });
    }
  }
  return sections;
};
