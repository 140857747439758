import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';
import {
  type UserOrEmail,
  type UserSearchResult,
  type UserSearchV4Result,
} from '@f4s/types/common/user';

import type { Option } from '@/components/invite-multiselect';

import type { MinimalUser, User } from './schema';

type SearchUsersParameters = {
  query: string;
  excludeMembers: UserOrEmail[];
  limit?: number;
  orgId?: number;
};

function searchUsersQuery({
  query,
  excludeMembers,
  limit,
  orgId,
}: SearchUsersParameters) {
  return {
    queryKey: ['search', query, JSON.stringify(excludeMembers), orgId, limit],
    queryFn: async () => {
      if (query.length < 3) return [];

      // TODO: consider using v4 search
      const { users: results } = await (apiClient.post('/api/v3/users/search', {
        limit,
        includes: ['connections'],
        searchText: query,
        filters: [],
        orgId,
      }) as Promise<{ users: UserSearchResult[] }>);

      const excludeMemberIds = excludeMembers.map((member) =>
        member.type === 'email' ? member.email : member.user.userId,
      );

      return (results ?? []).filter((member) => !excludeMemberIds.includes(member.id));
    },
  };
}

export const fetchSearchUserResults = (params: SearchUsersParameters) =>
  queryClient.fetchQuery(searchUsersQuery(params));
export const useSearchUserResults = (params: SearchUsersParameters) =>
  useQuery(searchUsersQuery(params));

type SearchUsersV4Parameters = {
  query: string;
  excludeMembers?: UserOrEmail[];
};

function searchUsersV4Query({ query }: SearchUsersV4Parameters) {
  return {
    queryKey: ['search', query],
    queryFn: async () => {
      if (query.length < 3) return [];

      const results = (await apiClient.post(
        '/api/v4/users',
        query.includes('@') ? { emailAddress: query } : { name: query },
      )) as UserSearchV4Result[];

      // Lets cache the mapping too
      return results.map((r) => ({
        label: r.fullName,
        key: `${r.fullName} ${r.id}`,
        value: {
          type: 'user',
          userId: r.id,
          avatarUrl: r.avatarUrl,
        },
      })) satisfies Option[];
    },
    keepPreviousData: true,
  };
}

export const fetchSearchUserV4Results = (params: SearchUsersV4Parameters) =>
  queryClient.fetchQuery(searchUsersV4Query(params));
export const useSearchUserV4Results = (params: SearchUsersV4Parameters) =>
  useQuery(searchUsersV4Query(params));

function journalsQuery(enabled: boolean = false) {
  return {
    queryKey: ['journals'],
    queryFn: async () =>
      apiClient.get('/api/v2/user/journals') as Promise<{ text: string }[]>,
    enabled,
  };
}

export const useJournals = (enabled?: boolean) => useQuery(journalsQuery(enabled));

const demoUsersQuery = {
  queryKey: ['users', 'demo'],
  queryFn: async (): Promise<User[]> => {
    const demoUsers = (await apiClient.get('/api/v4/users/demo')) as MinimalUser[];
    return demoUsers.map((c) => ({
      ...c,
      userId: c.id, // TODO: remove need for this
      fullName: formatName(c),
      initials: formatInitials(c),
    }));
  },
};
export const fetchDemoUsers = () => queryClient.fetchQuery(demoUsersQuery);
export const useDemoUsers = () => useQuery(demoUsersQuery);

const demoGroupsQuery = {
  queryKey: ['groups', 'demo'],
  queryFn: async () => {
    const demoGroups = (await apiClient.get('/api/v4/users/demo-groups')) as {
      name: string;
      members: MinimalUser[];
    }[];
    return demoGroups.map((g) => ({
      ...g,
      members: g.members.map((m) => ({
        ...m,
        userId: m.id, // TODO: remove need for this
        fullName: formatName(m),
        initials: formatInitials(m),
      })),
    }));
  },
};
export const fetchDemoGroups = () => queryClient.fetchQuery(demoGroupsQuery);
export const useDemoGroups = () => useQuery(demoGroupsQuery);

export const entitlementQuery = (organizationId?: number) => ({
  queryKey: ['entitlements', organizationId],
  queryFn: () =>
    apiClient.get('/api/v3/entitlements/') as Promise<
      Record<string, boolean | number | string[]>
    >,
});

export const fetchEntitlements = (organizationId?: number) =>
  queryClient.fetchQuery(entitlementQuery(organizationId));
export const useEntitlements = (organizationId?: number) =>
  useQuery(entitlementQuery(organizationId));
