import { useQuery } from '@tanstack/react-query';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import type { Goal } from '@f4s/types';

export const goalsQuery = {
  queryKey: ['goals', 'me'],
  queryFn: async () => {
    const { id: userId } = await authProvider.getUser();
    return apiClient.get(`/api/v3/coaching/goals/${userId}`) as Promise<Goal[]>;
  },
};

export const fetchGoals = () => queryClient.fetchQuery(goalsQuery);
export const useGoals = () => useQuery(goalsQuery);
