import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { fetchModelData, fetchWorkspaceModelList } from '@/modules/modeling/queries';
import { fetchWorkspace } from '@/modules/workspace/queries';

import { fetchTalentPools } from './queries';

export const talentMatchLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug: string };

  const talentPools = await fetchTalentPools();
  let modelData = await fetchModelData();
  if (workspaceSlug) {
    const workspace = await fetchWorkspace(workspaceSlug);
    if (!workspace) return redirect('..');
    const workspaceModels = await fetchWorkspaceModelList({ workspaceId: workspace.id });
    modelData = [...workspaceModels, ...modelData];
    return { workspace, modelData, talentPools };
  }
  // motivationData = await fetchWorkspaceMotivations({ workspaceId: workspace.id });

  return { workspace: null, modelData, talentPools };
};
export type TalentMatchLoaderData = Exclude<
  Awaited<ReturnType<typeof talentMatchLoader>>,
  Promise<Response> | Response
>;
