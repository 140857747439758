// This module provides invite accepting behavior, for invite sending behavior see the workspace module

import { redirect, useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import { fetchMagicLinkByToken } from '../invite/queries';
import { getWorkspaceSlugFromId, workspacesQuery } from '../workspace/queries';
import { clearInviteToken, clearMagicToken } from './queries';

export const acceptPersonalInviteAction = async ({ params }: ActionFunctionArgs) => {
  const { token } = z.object({ token: z.string() }).parse(params);
  // Accept the invite by token
  const invite = (await apiClient.get(`/api/v4/invitations/token/${token}/accept`)) as {
    organizationId?: number;
  };
  clearInviteToken(); // Just in case
  // Invalidate the workspaces cache (we just joined one)
  queryClient.invalidateQueries(workspacesQuery);
  // Find the slug and redirect to it
  const slug = await getWorkspaceSlugFromId(invite.organizationId);

  return redirect(slug ? `/spaces/${slug}/people/members` : '/');
};

export const rejectPersonalInviteAction = async () => {};

export const acceptMagicInviteAction = async ({ params }: ActionFunctionArgs) => {
  const { token } = z.object({ token: z.string() }).parse(params);
  // TODO: the magic link accept API should return the link details so we know where to go
  // For now grab the link first
  const link = await fetchMagicLinkByToken({ token });
  if (!link) return redirect('/'); // TODO: error handling

  // Accept the magic link by token
  await apiClient.post(`/api/v3/magic-links/${token}/accept`, {});
  clearMagicToken(); // Just in case
  // Invalidate the workspaces cache (we just joined one)
  queryClient.invalidateQueries(workspacesQuery);
  // Find the slug and redirect to it
  const slug = await getWorkspaceSlugFromId(link.organizationId);

  return redirect(slug ? `/spaces/${slug}/people/members` : '/');
};

export const useAcceptInvite = () => {
  const submit = useSubmit();
  return () =>
    submit(
      {},
      {
        action: '.',
        method: 'POST',
        encType: 'application/json',
        navigate: false,
      },
    );
};

export const createJoinRequestAction = async () => {};
