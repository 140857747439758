import { Card, cn, Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { PageHeader } from '@/components/page-header';
import { useDataFromLoader } from '@/lib/hooks/loader';
import { useWorkspaceInvite } from '@/modules/workspace/hooks/use-workspace-invite';

import type { onboardingSpacesCreateLoader } from '../loaders';

export const OnboardingSpaceCreatePage = () => {
  const { suggested } = useDataFromLoader<typeof onboardingSpacesCreateLoader>();
  const { title, subtitle, body, button } = useWorkspaceInvite({
    shouldRedirect: true,
    redirectTo: '/onboarding/spaces/complete', // Bypass normal redirect to space
    hideSwitcher: true,
  });

  const hasSuggested = suggested.some((w) => !w.isMember);

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        <PageHeader title={title} />
        {subtitle}
      </div>
      <Card className="bg-background sm:bg-card sm:ring-border/5 flex w-full flex-col gap-10 rounded-none p-0 shadow-none ring-0 sm:max-w-xl sm:rounded-xl sm:p-12 sm:shadow-sm">
        {body}
        <div className={cn('flex justify-end gap-3', hasSuggested && 'justify-between')}>
          {hasSuggested && (
            <NavLinkButton to="/onboarding/spaces/join" variant="secondary" size="icon">
              <span className="sr-only">Back</span>
              <Icon.ChevronLeft size={18} className="text-muted-foreground" />
            </NavLinkButton>
          )}
          {button}
        </div>
      </Card>
    </>
  );
};
