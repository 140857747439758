import { type ReactNode } from 'react';

import { cn } from '../lib/utils';

export const IconWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      'lofiNav:[&_*]:!text-foreground flex h-6 w-6 shrink-0 items-center justify-center rounded-lg',
      className,
    )}
  >
    {children}
  </div>
);
