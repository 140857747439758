import { Outlet, useOutletContext } from 'react-router-dom';

import { cn, TooltipProvider } from '@f4s/ui';

import { ScrollContainer } from '@/components/scroll-container';

export const AuthBackgroundLayout = () => {
  return (
    <div className="bg-background relative h-full w-full">
      <Outlet context={useOutletContext()} />
    </div>
  );
};

export function AuthLayout({ className }: { className: string }) {
  return (
    <TooltipProvider delayDuration={300}>
      <ScrollContainer>
        <div className={cn('mx-auto w-full md:max-w-min px-4', className)}>
          <Outlet context={useOutletContext()} />
        </div>
      </ScrollContainer>
    </TooltipProvider>
  );
}
