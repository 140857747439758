import { useQuery } from '@tanstack/react-query';

import { queryClient } from '@f4s/api-client';

export const setLocalPreference = <T>({
  preferenceName,
  value,
}: {
  preferenceName: string;
  value: T;
}) => {
  localStorage.setItem(preferenceName, JSON.stringify(value));
  queryClient.setQueryData(['localPreference', preferenceName], value);
};

export const deleteLocalPreference = ({ preferenceName }: { preferenceName: string }) => {
  localStorage.removeItem('preferenceName');
  queryClient.setQueryData(['localPreference', preferenceName], null);
};

const localPreferenceQuery = <T>({
  preferenceName,
  defaultValue = null,
}: {
  preferenceName: string;
  defaultValue?: T | null;
}) => ({
  queryKey: ['localPreference', preferenceName],
  queryFn: () => {
    const localStringValue = localStorage.getItem(preferenceName);
    let parsedValue: T | undefined = undefined;
    if (localStringValue) {
      try {
        parsedValue = JSON.parse(localStringValue);
      } catch (error) {
        console.warn('Error parsing JSON string', error);
      }
    }
    return parsedValue;
  },
  initialData: () => {
    const localStringValue = localStorage.getItem(preferenceName);
    let parsedValue: T | null = null;
    if (localStringValue) {
      try {
        parsedValue = JSON.parse(localStringValue);
      } catch (error) {
        console.warn('Error parsing JSON string', error);
        parsedValue = defaultValue;
      }
    }

    if (parsedValue === null) {
      parsedValue = defaultValue;
    }

    localStorage.setItem(preferenceName, JSON.stringify(parsedValue));

    return parsedValue;
  },
});

export const useLocalPreference = <T>({
  preferenceName,
  defaultValue,
}: {
  preferenceName: string;
  defaultValue?: T;
}): T | null | undefined =>
  useQuery(
    localPreferenceQuery({
      preferenceName,
      defaultValue,
    }),
  ).data;

export const fetchLocalPreference = <T>({
  preferenceName,
  defaultValue,
}: {
  preferenceName: string;
  defaultValue?: T;
}): Promise<T | null | undefined> =>
  queryClient.fetchQuery(
    localPreferenceQuery({
      preferenceName,
      defaultValue,
    }),
  );
