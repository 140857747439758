import { Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { PageHeaderTitle } from '@/components/page-header';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';
import { AssessmentLanguageSelector } from '@/modules/assessment/components/assessment/language-selector';
import { useAssessmentWeb } from '@/modules/assessment/hooks/use-assessment-web';

import type { onboardingAnalysisBlockQuestionLoader } from '../loaders';

export const OnboardingAnalysisQuestionPage = () => {
  const {
    assessment,
    currentQuestion,
    currentQuestionNo,
    nextStep,
    previousStep,
    assessmentLanguage,
  } = usePersistedDataFromLoader<typeof onboardingAnalysisBlockQuestionLoader>();
  const { answerList, handleNext, isLoading, questionTitle } = useAssessmentWeb({
    assessment,
    question: currentQuestion,
    questionNo: currentQuestionNo,
    nextStep,
  });

  return (
    <div className="flex flex-col gap-4 p-4 sm:p-6">
      <div className="flex flex-col gap-2">
        <PageHeaderTitle
          title={`Question ${currentQuestionNo} of ${assessment.questions.length}`}
          className="whitespace-normal"
        />
        <p className="text-pretty">
          Drag and drop these options in order of your most preferred to least preferred.
          When answering,{' '}
          <span className="font-semibold">think of your ideal work environment.</span>
        </p>
      </div>
      <div key={currentQuestion.number} className="flex flex-col gap-3">
        <div className="animate-fade-in-and-up font-serif text-lg font-semibold opacity-0 delay-0">
          {questionTitle}:
        </div>
        <div className="animate-fade-in-and-up opacity-0 delay-150">{answerList}</div>
      </div>
      <div className="flex justify-between gap-3">
        <div className="flex gap-3">
          <NavLinkButton
            to={previousStep}
            variant="secondary"
            size="icon"
            className="shrink-0"
            data-testid="onboarding-analysis-back-button"
          >
            <Icon.ChevronLeft className="text-foreground/60" size={18} />
          </NavLinkButton>
          <AssessmentLanguageSelector
            language={assessmentLanguage}
            className="w-9 sm:w-auto"
          />
        </div>
        <NavLinkButton
          to={nextStep}
          onClick={handleNext}
          preventDefault
          disabled={isLoading}
          data-testid="onboarding-analysis-next-button"
        >
          {currentQuestionNo === assessment.questions.length ? 'Complete' : 'Next'}
        </NavLinkButton>
      </div>
    </div>
  );
};
