import { redirect, type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';
import { assessmentCompleteRoute } from '@/modules/assessment/routes';
import { membersRoutes } from '@/modules/member/routes';
import { talentMatchRoutes } from '@/modules/talent/match/routes';

import { billingRoutes } from '../billing/routes';
import { coachingRoutes } from '../coaching/routes';
import { dashboardRoutes } from '../dashboard/routes';
import { modelRoutes } from '../modeling/routes';
import { reportRoutes } from '../report/routes';
import { MobileMoreNav } from '../root/components/nav';
import { ErrorPage } from '../root/pages/error';
import { MissingPage } from '../root/pages/missing';
import { accountRoutes } from '../settings/routes';
import { workspaceTalentRoutes } from '../talent/routes';
import { teamRoutes } from '../team/routes';
import { myResultsRoute } from '../user/routes';
import { workspaceAccessRoutes } from '../workspace-access/routes';
import { workspaceInviteRoutes } from '../workspace-invites/routes';
import { workspaceRequestRoutes } from '../workspace-requests/routes';
import { workspaceSamlRoutes, workspaceScimRoutes } from '../workspace-sso/routes';
import {
  createDemoWorkspaceAction,
  createWorkspaceAction,
  inviteWorkspaceMember,
  updateSettingsAction,
  updateWorkspaceAction,
  workspaceDemoInviteAction,
  workspaceFavoriteAction,
} from './actions';
import {
  createWorkspaceLoader,
  workspaceIntegrationsLoader,
  workspaceLandingLoader,
  workspaceLoader,
  workspaceMyProfileLoader,
  workspaceRedirect,
  workspaceSettingsIndexLoader,
  workspaceSettingsLoader,
  workspaceSlackIntegrationAuthLoader,
  workspacesLoader,
} from './loaders';

export const workspaceIndexRoute: RouteObject = {
  index: true,
  async lazy() {
    const { Workspaces } = await import('./pages/list');
    return { Component: Workspaces };
  },
  handle: { crumb: { title: 'Workspaces' }, type: 'workspaces' },
  loader: workspacesLoader,
};

export const workspaceRedirectRoutes: RouteObject[] = [
  {
    path: '/assessment',
    loader: workspaceRedirect('assessment'),
  },
  {
    path: '/boards',
    loader: workspaceRedirect('boards'),
  },
  {
    path: '/queries',
    loader: workspaceRedirect('queries'),
  },
  {
    path: '/coaching',
    loader: workspaceRedirect('coaching'),
  },
  {
    path: '/people',
    loader: workspaceRedirect('people'),
  },
  {
    path: '/people/me',
    loader: workspaceRedirect('people/me'),
  },
];

export const workspaceCreateRoute: RouteObject = {
  path: 'create',
  handle: { crumb: { title: 'New Workspace' }, type: 'workspaceCreate' },
  async lazy() {
    const { WorkspaceCreatePage } = await import('./pages/create');
    return { Component: WorkspaceCreatePage };
  },
  loader: createWorkspaceLoader,
  action: createWorkspaceAction,
  children: [{ path: 'demo', action: createDemoWorkspaceAction }],
};

export const workspaceInviteRoute: RouteObject = {
  path: 'invite',
  async lazy() {
    const { WorkspaceInvitePage } = await import('./pages/invite');
    return { Component: WorkspaceInvitePage };
  },
  loader: createWorkspaceLoader,
  action: inviteWorkspaceMember,
};

export const workspaceRoutes: RouteObject = {
  path: 'spaces',
  ErrorBoundary: ErrorPage,
  children: [
    {
      path: ':workspaceSlug',
      loader: workspaceLoader,
      handle: handle({ type: 'workspace' }),
      children: [
        {
          index: true,
          loader: workspaceLandingLoader,
          handle: handle({
            breadcrumb: SimpleBreadcrumb({ title: 'Home' }),
          }),
          async lazy() {
            const { WorkspaceLandingPage } = await import('./pages/page');
            return { Component: WorkspaceLandingPage };
          },
        },

        {
          path: 'settings',
          handle: handle({
            type: 'settings',
            breadcrumb: SimpleBreadcrumb({ title: 'Settings' }),
          }),
          children: [
            { index: true, loader: workspaceSettingsIndexLoader },
            {
              path: 'details',
              handle: handle({
                type: 'details',
                breadcrumb: SimpleBreadcrumb({ title: 'Details' }),
              }),
              loader: workspaceSettingsLoader,
              action: updateWorkspaceAction,
              async lazy() {
                const { WorkspaceDetails } = await import('./pages/settings');
                return { Component: WorkspaceDetails };
              },
            },
            {
              path: 'preferences',
              handle: handle({
                type: 'preferences',
                breadcrumb: SimpleBreadcrumb({ title: 'Preferences' }),
              }),
              loader: workspaceSettingsLoader,
              action: updateSettingsAction,
              async lazy() {
                const { WorkspaceSettings } = await import('./pages/settings');
                return { Component: WorkspaceSettings };
              },
            },
            billingRoutes,
            workspaceTalentRoutes,
            modelRoutes,
            {
              path: 'billing/upgrade',
              loader: workspaceLoader,
              async lazy() {
                const { WorkspaceUpgrade } = await import('./pages/upgrade');
                return { Component: WorkspaceUpgrade };
              },
            },
            workspaceSamlRoutes,
            workspaceScimRoutes,
          ],
        },
        assessmentCompleteRoute,
        {
          path: 'integrations',
          children: [
            {
              index: true,
              loader: workspaceIntegrationsLoader,
              async lazy() {
                const { WorkspaceIntegrations } = await import('./pages/integrations');
                return { Component: WorkspaceIntegrations };
              },
            },
            {
              path: 'slack/auth',
              loader: workspaceSlackIntegrationAuthLoader,
              async lazy() {
                const { WorkspaceError } = await import('./pages/error');
                return { Component: WorkspaceError };
              },
            },
          ],
        },
        { path: 'favorite', action: workspaceFavoriteAction },
        {
          path: 'people',
          handle: { type: 'people' },
          children: [
            { index: true, loader: () => redirect('members') },
            { path: 'me', loader: workspaceMyProfileLoader },
            membersRoutes,
            teamRoutes,
            workspaceAccessRoutes,
            workspaceInviteRoutes,
            workspaceRequestRoutes,
          ],
        },
        talentMatchRoutes,
        dashboardRoutes,
        reportRoutes,
        coachingRoutes,
        myResultsRoute,
        accountRoutes,

        { path: 'demo', action: workspaceDemoInviteAction },

        { path: 'more', Component: MobileMoreNav },
        // Handle workspace slug routed 404s
        {
          path: '*',
          Component: MissingPage,
        },
      ],
    },
  ],
};
