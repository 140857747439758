import { useQuery } from '@tanstack/react-query';
import { sample } from 'lodash-es';

import { apiClient, authProvider, queryClient, type User } from '@f4s/api-client';
import { formatName, type AskMarleeContext, type MotivationDetails } from '@f4s/types';

import { randomSample } from '@/lib/utils';

import { JobTitlesSortedByRoles } from '../../lib/onboarding-constants';
import {
  fetchSuggestions,
  type MentionData,
  type MentionSelection,
  type MentionUser,
  type MentionWorkspace,
} from '../ask-marlee/queries';
import { fetchAssessments } from '../assessment/queries';
import { fetchMotivationCompletion, fetchMotivationDetails } from '../motivation/queries';
import { getPreAuthData } from '../root/utils';
import { Messages } from './messages';

type ContextWithAvatar =
  | (AskMarleeContext & { avatarUrl?: string })
  | { type: 'placeholder'; placeholderType: 'user' | 'team' };

export type QueryTemplate = {
  id: number;
  ownerId: number;
  slug: string | null;
  questionType:
    | 'connect'
    | 'collaborate'
    | 'motivate'
    | 'develop'
    | 'debrief'
    | 'hiring'
    | 'behired'
    | null;
  selectionType: null;
  prompt: string;
  promptOverride: string | null;
  available: boolean;
  favourites: { userId: number; templateId: number; hasUsed: boolean }[];
  includesSelf?: boolean | null;
  motivationGroupId: number | null;
  motivationGroup?: { slug: string };
};

export type Query = QueriesByCategory[0]['queries'][0];

const categoryMetadata: { [key: string]: { title: string; order: number } } = {
  favourites: { title: Messages.yourPriorityQueries(), order: 1 },
  connect: { title: Messages.connect(), order: 2 },
  motivate: { title: Messages.motivate(), order: 3 },
  collaborate: { title: Messages.collaborate(), order: 4 },
  develop: { title: Messages.develop(), order: 5 },
  debrief: { title: Messages.debrief(), order: 6 },
  hiring: { title: Messages.hiring(), order: 7 },
  behired: { title: Messages.behired(), order: 8 },
} as const;

export const getQueries = {
  queryKey: ['queries', 'user'],
  queryFn: async () => apiClient.get(`/api/v4/queries`) as Promise<QueryTemplate[]>,
};
export const fetchQueries = () => queryClient.fetchQuery(getQueries);

export const getPublicQueriesRaw = {
  queryKey: ['queries', 'public'],
  queryFn: async () =>
    apiClient.get(`/api/v3/public/queries`) as Promise<QueryTemplate[]>,
};
export const fetchPublicQueriesRaw = () => queryClient.fetchQuery(getPublicQueriesRaw);

export const getPublicQueries = {
  queryKey: ['queries', 'public', 'replaced'],
  queryFn: async () => {
    const queries = await fetchPublicQueriesRaw();
    let { roleName: role } = getPreAuthData();
    if (!role || role === 'Other') {
      role = sample(sample(Object.values(JobTitlesSortedByRoles))) || 'Software Engineer';
    }

    return queries.map((q) => {
      const replacement = sample(['@friend', '@teammate']);
      const prompt = q.prompt
        .replaceAll('{{person}}', replacement)
        .replaceAll('{{role}}', role)
        .replaceAll('{{team}}', '@team');
      return { ...q, prompt };
    });
  },
};
export const fetchPublicQueries = () => queryClient.fetchQuery(getPublicQueries);

export const processQuerySubstitutions = ({
  user,
  query,
  manualSuggestions,
  suggestions,
  motivations,
  missingMotivationGroups,
}: {
  user: User;
  query: QueryTemplate;
  suggestions: {
    connectedUsers: MentionUser[];
    teams: MentionSelection[];
    workspaces: MentionWorkspace[];
  };
  motivations: MotivationDetails;
  missingMotivationGroups: {
    id: number;
    name: string;
    description: string;
  }[];
  manualSuggestions?: MentionData[];
}) => {
  const context: ContextWithAvatar[] = [];
  let prompt = query.prompt;

  const randomMotivation = sample(motivations);
  const randomRole = sample(sample(Object.values(JobTitlesSortedByRoles)));

  const persons = [...prompt.matchAll(/{{person}}/g)];

  if (persons.length > 0) {
    const suggestedUsers: MentionUser[] =
      manualSuggestions?.flatMap((m) => (m.type === 'user' ? m : [])) ?? [];
    const suggestedUserIds = suggestedUsers.map((u) => u.user.id);
    const otherConnections =
      suggestedUserIds.length > 0
        ? suggestions.connectedUsers.filter((c) => !suggestedUserIds.includes(c.user.id))
        : suggestions.connectedUsers;

    if (suggestedUsers.length < persons.length) {
      const otherConnectedUsers = randomSample(
        otherConnections,
        Math.min(persons.length - suggestedUsers.length, otherConnections.length),
      );
      suggestedUsers.push(...otherConnectedUsers);
    }
    const randomPlaceholder = randomSample(['@friend', '@teammate'], 2);

    let i = 0;
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    prompt = prompt.replace(/{{person}}/g, () => {
      const suggestedUser = suggestedUsers[i++];
      if (suggestedUser) {
        // Add selection to the context of the query
        context.push({
          type: 'user',
          id: suggestedUser.user.id,
          firstName: suggestedUser.user.firstName,
          lastName: suggestedUser.user.lastName,
          gender: suggestedUser.user.gender,
          avatarUrl: suggestedUser.user.avatarUrl,
        } as ContextWithAvatar);
        return `@[${suggestedUser.display}](${suggestedUser.id})`;
      }

      const placeholder = randomPlaceholder[i % randomPlaceholder.length]!;
      context.push({
        type: 'placeholder',
        placeholderType: 'user',
      } as ContextWithAvatar);

      return `@[${placeholder}](placeholder-user-${i})`;
    });
  }

  const teams = [...prompt.matchAll(/{{team}}/g)];
  if (teams.length > 0) {
    const suggestedGroups: (MentionSelection | MentionWorkspace)[] =
      manualSuggestions?.flatMap((m) =>
        m.type === 'selection' || m.type === 'workspace' ? m : [],
      ) ?? [];
    const manuallySuggestedGroupIds = suggestedGroups.map((s) => s.id);
    const otherSuggestedGroups =
      suggestedGroups.length > 0
        ? [...suggestions.teams, ...suggestions.workspaces].filter(
            (s) => !manuallySuggestedGroupIds.includes(s.id),
          )
        : [...suggestions.teams, ...suggestions.workspaces];

    if (suggestedGroups.length < teams.length) {
      const sampledSuggestedGroups = randomSample(
        otherSuggestedGroups,
        Math.min(teams.length - suggestedGroups.length, otherSuggestedGroups.length),
      );
      suggestedGroups.push(...sampledSuggestedGroups);
    }
    const randomPlaceholder = randomSample(['@team', '@workspace'], 2);

    let i = 0;
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    prompt = prompt.replace(/{{team}}/g, () => {
      const suggestedGroup = suggestedGroups[i++];
      if (suggestedGroup) {
        if (suggestedGroup.type === 'selection') {
          context.push({
            type: 'selection',
            displayName: suggestedGroup.selection.name,
            id: suggestedGroup.selection.id,
            avatarUrl: suggestedGroup.selection.avatarUrl,
          } as ContextWithAvatar);
        } else {
          context.push({
            type: 'workspace',
            id: suggestedGroup.workspace.id,
            displayName: suggestedGroup.workspace.name,
            avatarUrl: suggestedGroup.workspace.avatarUrl,
          } as ContextWithAvatar);
        }
        return `@[${suggestedGroup.display}](${suggestedGroup.id})`;
      }

      const placeholder = randomPlaceholder[i % randomPlaceholder.length]!;
      context.push({
        type: 'placeholder',
        placeholderType: 'team',
      } as ContextWithAvatar);

      return `@[${placeholder}](placeholder-team-${i})`;
    });
  }

  // Push self if needed
  if (query.includesSelf) {
    context.push({
      id: user.id,
      displayName: formatName(user),
      type: 'user',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      gender: user.gender ?? 't',
      isMe: true,
      avatarUrl: user.avatarUrl ?? undefined,
    });
  }

  let canAsk = true;
  if (
    query.includesSelf &&
    ((query.motivationGroupId === null && missingMotivationGroups.length > 0) ||
      (query.motivationGroupId &&
        missingMotivationGroups.some((g) => g.id === query.motivationGroupId)))
  ) {
    canAsk = false;
  }
  // if (randomGroupMember) {
  //   query.prompt = query.prompt.replaceAll(
  //     '{{member}}',
  //     `@[${randomGroupMember.firstName} ${randomGroupMember.lastName}](user-${randomGroupMember.userId})`,
  //   );
  //   // Add context
  // }

  // if (randomGroup) {
  //   query.prompt = query.prompt.replaceAll(
  //     '{{team}}',
  //     `@[${randomGroup.name}](team-${randomGroup.groupId})`,
  //   );
  //   // Add context
  // }

  prompt = prompt
    .replaceAll('{{role}}', randomRole ?? '')
    .replaceAll('{{motivation}}', randomMotivation?.name ?? '');

  return { ...query, canAsk, prompt, context };
};

export const getQueriesByCategory = (manualSuggestions?: MentionData[]) => ({
  queryKey: [
    'queries',
    'user',
    'category',
    manualSuggestions
      ?.slice(0, 10)
      .map((m) => m.id)
      .join('+'), // We only really care about the top set of results being different
  ],
  queryFn: async () => {
    const user = await authProvider.getUser();
    const queries = await fetchQueries();
    const suggestions = await fetchSuggestions();
    const motivations = await fetchMotivationDetails();
    const userMotivations = await fetchMotivationCompletion();

    const queryMap = new Map<string, ReturnType<typeof processQuerySubstitutions>[]>();

    for (const query of queries) {
      const category = query.questionType ?? 'other';
      const mapEntry = queryMap.get(category);
      if (!mapEntry) queryMap.set(category, []);

      const processedQuery = processQuerySubstitutions({
        user,
        query,
        manualSuggestions,
        suggestions,
        motivations,
        missingMotivationGroups: userMotivations.missingMotivationGroups,
      });
      queryMap.get(category)?.push(processedQuery);

      if (processedQuery.favourites.length > 0) {
        if (queryMap.has('favourites')) {
          queryMap.get('favourites')?.push(processedQuery);
        } else {
          queryMap.set('favourites', [processedQuery]);
        }
      }
    }

    return Array.from(queryMap, ([key, value]) => ({
      name: key,
      queries: value
        .map((q) => ({
          ...q,
          categoryTitle: q.questionType
            ? categoryMetadata[q.questionType]?.title
            : undefined,
        }))
        .sort((a, b) => {
          // Sort favourites; debrief queries first if motivations missing, otherwise, debrief last
          if (key === 'favourites') {
            const order =
              (userMotivations.missingMotivations?.length ?? 0) > 0
                ? ['debrief', '']
                : ['', 'debrief'];

            const wildcardIndex = order.indexOf('');
            const ai = order.indexOf(a.questionType ?? '');
            const bi = order.indexOf(b.questionType ?? '');
            return (ai === -1 ? wildcardIndex : ai) - (bi === -1 ? wildcardIndex : bi);
          } else {
            // Sort questions about self only first
            // Self + other next
            // Other(s) last
            return (
              (a.includesSelf ? 0 : 100) -
              (b.includesSelf ? 0 : 100) +
              (a.context.length - b.context.length)
            );
          }
        }),
      ...(categoryMetadata[key] ?? { title: Messages.other(), order: 6 }),
    })).sort((a, b) => a.order - b.order);
  },
});

export const fetchQueriesByCategory = (manualSuggestions?: MentionData[]) =>
  queryClient.fetchQuery(getQueriesByCategory(manualSuggestions));
export const useQueriesByCategory = (manualSuggestions?: MentionData[]) =>
  useQuery(getQueriesByCategory(manualSuggestions));

export type QueriesByCategory = Awaited<ReturnType<typeof fetchQueriesByCategory>>;

const suggestedMotivationQueriesQuery = {
  queryKey: ['queries', 'user', 'motivations'],
  queryFn: async () => {
    const { missingMotivationGroups } = await fetchMotivationCompletion();

    // Find completions
    const assessments = await fetchAssessments();
    const queriesCategories = await fetchQueriesByCategory();
    const debriefQueries = queriesCategories.find((q) => q.name === 'debrief')?.queries;

    const completions = missingMotivationGroups.flatMap((m) => {
      const assessment = assessments.find((a) => a.name === m.name);
      const query = debriefQueries?.find((q) => q.motivationGroupId === m.id);
      if (assessment && query) {
        return { motivationGroup: m, assessment, query };
      }
      return [];
    });
    // Queries to guide people through assessment completion
    const randomDebriefQueries = randomSample(completions, completions.length);

    // One random non debrief favourite
    const faveQueries =
      queriesCategories
        .find((q) => q.name === 'favourites')
        ?.queries.filter((q) => q.questionType !== 'debrief')
        .map((q) => ({ motivationGroup: undefined, assessment: undefined, query: q })) ??
      [];
    const randomFavQuery = faveQueries.length > 0 ? randomSample(faveQueries, 1) : [];

    const suggestedQueries = [...randomFavQuery, ...randomDebriefQueries];

    // Make sure we have at least 3 suggested queries. Fall back to a random selection
    if (suggestedQueries.length < 3) {
      const restQueries = randomSample(
        queriesCategories.flatMap((c) => c.queries),
        3 - suggestedQueries.length,
      ).map((q) => ({ motivationGroup: undefined, assessment: undefined, query: q }));
      suggestedQueries.push(...restQueries);
    }

    return suggestedQueries;
  },
};
export const fetchSuggestedMotivationQueries = () =>
  queryClient.fetchQuery(suggestedMotivationQueriesQuery);
export const useSuggestedMotivationQueries = () =>
  useQuery(suggestedMotivationQueriesQuery);

/**
 * Gets the query template value from local storage
 * passed from the SEO site when a user clicks on a query tile.
 *
 * Maps to a query template id from the database.
 * If none is found, returns undefined.
 * */
export const getReferredQuery = async () => {
  const referredQueryPrompt = window.localStorage.getItem('seoReferredQuery');
  let referredQueryTemplate: number | undefined = undefined;
  if (referredQueryPrompt) {
    const queries = await fetchPublicQueriesRaw();
    referredQueryTemplate = queries.find((q) => q.prompt === referredQueryPrompt)?.id;
  }
  return referredQueryTemplate;
};
