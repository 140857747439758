import { z } from 'zod';

import { JobRoles } from '../../lib/onboarding-constants';

const preAuthDataSchema = z
  .object({
    email: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    gender: z.enum(['m', 'f', 't']).nullish(),
    roleType: z.enum(JobRoles).nullish(),
    roleName: z.string().nullish(),
    queries: z.number().array(),
    jtbdCategory: z.string(),
  })
  .partial();

export type UserPreAuthData = z.infer<typeof preAuthDataSchema>;

export const saveAndReturnPreAuthData = (data: UserPreAuthData): UserPreAuthData => {
  const storedData = getPreAuthData();
  const newData = preAuthDataSchema.parse({ ...storedData, ...data });
  localStorage.setItem('userPreAuthData', JSON.stringify(newData));

  return newData;
};

export const getPreAuthData = (initialUserData?: UserPreAuthData): UserPreAuthData => {
  const localData = localStorage.getItem('userPreAuthData');
  if (!localData && initialUserData) {
    const parsedInitialUserData = preAuthDataSchema.parse(initialUserData);
    localStorage.setItem('userPreAuthData', JSON.stringify(parsedInitialUserData));
    return initialUserData;
  }

  return preAuthDataSchema.parse(
    JSON.parse(localStorage.getItem('userPreAuthData') ?? '{}'),
  );
};

export const clearPreAuthData = () => localStorage.removeItem('userPreAuthData');
