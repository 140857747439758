import { useEffect, useMemo, useRef, useState } from 'react';

import { Button, Card, EmptyCard, EmptyState, Icon } from '@f4s/ui';

import { NavLink, NavLinkButton } from '@/components/nav-link';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { useDashboards, useDashboardTemplates, type Dashboard } from '../../queries';
import { WidgetPreview } from '../widget-preview';
import { AllTemplatesModal } from './all-templates-modal';

export const DashboardPreview = ({ dashboard }: { dashboard: Dashboard }) => {
  const widgets = useMemo(
    () =>
      dashboard.widgets
        .slice(0, 4)
        .map((w) => <WidgetPreview widget={w} key={w.id} dashboard={dashboard} />),
    [dashboard],
  );

  return (
    <div className="flex h-full w-full flex-col gap-3">
      <div className="flex flex-col gap-1">
        <h3 className="truncate font-medium">{dashboard.title}</h3>
      </div>

      <div className="grid grid-cols-2 gap-1.5 [grid-template-rows:1fr_1fr]">
        {widgets}
      </div>
    </div>
  );
};

export const DashboardPreviewGrid = ({
  dashboards,
  useTemplates = false,
  userId,
  defaultUserIds,
  defaultWorkspaceIds,
  exactly,
}: {
  dashboards?: Dashboard[];
  useTemplates?: boolean;
  userId?: number;
  defaultUserIds?: number[];
  defaultWorkspaceIds?: number[];
  exactly?: number;
}) => {
  const { pathname = '/personal', workspace } = useMatchedWorkspace() ?? {};
  const { data: defaultDashboards } = useDashboards({ workspaceId: workspace?.id });

  const { previews, hasRealPreviews } = useMemo(() => {
    const previewDashboards = dashboards ?? defaultDashboards ?? [];
    const limitedDashboards = exactly
      ? previewDashboards.slice(0, exactly)
      : previewDashboards;

    const dashboardPreviews = limitedDashboards.flatMap((d) => (
      <NavLinkButton
        key={d.id}
        to={`${pathname}/boards/${d.id}`}
        className="bg-secondary aspect-[4/3] h-auto items-start overflow-hidden rounded-lg p-4 transition-shadow duration-300 hover:shadow-lg"
        variant="outline"
      >
        {/* <div className="w-full pt-8 [mask-image:linear-gradient(to_bottom,black_80%,transparent_90%)]"> */}
        <div className="w-full">
          <DashboardPreview dashboard={d} />
        </div>
      </NavLinkButton>
    ));

    // Add placeholder divs if we have a limit and need padding
    if (exactly && dashboardPreviews.length < exactly) {
      const placeholders = Array.from(
        { length: exactly - dashboardPreviews.length },
        (_, i) => <EmptyCard key={`placeholder-${i}`} />,
      );
      return {
        previews: [...dashboardPreviews, ...placeholders],
        hasRealPreviews: dashboardPreviews.length > 0,
      };
    }

    return {
      previews: dashboardPreviews,
      hasRealPreviews: dashboardPreviews.length > 0,
    };
  }, [dashboards, defaultDashboards, pathname, exactly]);

  if (!hasRealPreviews && useTemplates) {
    return (
      <DashboardTemplatePreviewGrid
        userId={userId}
        defaultUserIds={defaultUserIds}
        defaultWorkspaceIds={defaultWorkspaceIds}
      />
    );
  }

  if (!hasRealPreviews) {
    return (
      <EmptyState
        heading="You don't have any boards yet"
        message="To get started select a template."
        actions={
          <div className="flex flex-col gap-2 @[420px]:flex-row">
            <Button variant="secondary" size="default" asChild>
              <a
                href="https://getmarlee.com/faq/category/boards"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Learn about boards</span>
                <Icon.ArrowUpRight />
              </a>
            </Button>
            <AllTemplatesModal
              trigger={
                <Button variant="secondary" size="default">
                  View board templates
                </Button>
              }
            />
          </div>
        }
      />
    );
  }

  return (
    <div className="@container flex flex-1">
      <div className="@[540px]:grid-cols-2 @[768px]:grid-cols-3 @[1024px]:grid-cols-4 @[1280px]:grid-cols-5 grid w-full auto-rows-fr grid-cols-2 gap-3">
        {previews}
      </div>
    </div>
  );
};

interface TemplateCoverProps {
  template: {
    id: number;
    title: string | null;
    slug: string | null;
  };
}

const TemplateCover = ({ template }: TemplateCoverProps) => {
  return (
    <>
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={`/app/board-templates/${template.slug}.png`}
          // alt={template.title}
          className="h-full w-full object-contain object-bottom"
        />
      </div>
    </>
  );
};

export const DashboardTemplatePreviewGrid = ({
  dashboardTemplates,
}: {
  dashboardTemplates?: Dashboard[];
  userId?: number;
  defaultUserIds?: number[];
  defaultWorkspaceIds?: number[];
}) => {
  const { data: defaultTemplates } = useDashboardTemplates();
  const { pathname = '/personal' } = useMatchedWorkspace() ?? {};
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0]?.contentRect.width ?? 0;
      setContainerWidth(width);
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, []);

  const previews = useMemo(() => {
    const templates = dashboardTemplates ?? defaultTemplates ?? [];

    return templates.flatMap((template) => {
      const widget = template.widgets.find((w) => w.widgetTemplate.type !== 'separator');
      if (!widget) return [];

      return (
        <NavLink
          key={template.id}
          to={`${pathname}/boards/templates/${template.slug ?? template.id}`}
          className="before:bg-muted group relative z-0 flex h-auto flex-col gap-3 before:absolute before:-inset-1 before:-z-10 before:hidden before:rounded-[calc(var(--radius)_+_0.25rem_-_2px)] before:content-[''] hover:before:block"
          variant="outline"
        >
          <Card className="bg-secondary relative aspect-[3/4] overflow-hidden rounded-lg transition-shadow group-hover:shadow-lg">
            <TemplateCover template={template} />

            <div className="absolute left-0 right-0 top-0 flex w-full items-end justify-start p-3">
              <span className="text-pretty text-xs font-medium">{template.title}</span>
            </div>
          </Card>
        </NavLink>
      );
    });
  }, [dashboardTemplates, defaultTemplates, pathname]);

  const limitedPreviews = useMemo(() => {
    let maxItems = 2;
    if (containerWidth >= 384) maxItems = 3;
    if (containerWidth >= 448) maxItems = 4;
    if (containerWidth >= 512) maxItems = 4;
    if (containerWidth >= 576) maxItems = 5;
    if (containerWidth >= 768) maxItems = 6;
    if (containerWidth >= 896) maxItems = 7;

    return previews.slice(0, maxItems);
  }, [previews, containerWidth]);

  return (
    <Card className="@container bg-secondary from-background/60 to-card flex flex-col gap-4 bg-gradient-to-b p-4 md:gap-6 md:px-12 md:py-10">
      <div className="flex flex-row items-start justify-between gap-1">
        <div>
          <h2 className="font-serif text-base font-semibold">Templates</h2>
          <p className="text-muted-foreground">
            Create a new board with one of these templates
          </p>
        </div>
        <AllTemplatesModal
          trigger={
            <Button variant="secondary" size="default" className="shrink-0">
              <span>See all</span>
            </Button>
          }
        ></AllTemplatesModal>
      </div>
      <div
        ref={containerRef}
        className="@sm:grid-cols-3 @md:grid-cols-4 @lg:grid-cols-4 @xl:grid-cols-5 @3xl:grid-cols-6 @4xl:grid-cols-7 grid grid-cols-2 gap-4"
      >
        {limitedPreviews}
      </div>
    </Card>
  );
};
