import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import type { MinimalUser } from '@/modules/user/schema';
import { fetchWorkspaces } from '@/modules/workspace/queries';

export const personalConnectionsQuery = {
  queryKey: ['users', 'me', 'connections'],
  queryFn: async () => {
    const connections = (await apiClient.get(
      '/api/v4/users/me/connections',
    )) as (MinimalUser & {
      migratedToSpace: boolean;
      workspaceIds: number[];
      pendingWorkspaceIds: number[];
    })[];
    const workspaces = await fetchWorkspaces();

    return connections.map((c) => ({
      ...c,
      userId: c.id, // TODO: remove need for this
      fullName: formatName(c),
      initials: formatInitials(c),
      workspaces: c.workspaceIds.flatMap(
        (id) => workspaces.find((w) => w.id == id) ?? [],
      ),
      pendingWorkspaces: c.pendingWorkspaceIds.flatMap(
        (id) => workspaces.find((w) => w.id == id) ?? [],
      ),
    }));
  },
};
export const fetchPersonalConnections = () =>
  queryClient.fetchQuery(personalConnectionsQuery);
export const usePersonalConnections = () => useQuery(personalConnectionsQuery);
