import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { toast } from '@f4s/ui';

import {
  getWorkspaceSlugFromId,
  workspaceInvitePendingConnectedQuery,
  workspaceQuery,
} from '@/modules/workspace/queries';

import { personalConnectionsQuery } from '../_legacy/connections/queries';
import { invitesSentQuery } from '../invites/queries';

export const memberAction = async ({ request }: ActionFunctionArgs) => {
  const data = await request.json();
  const { workspaceId, memberId } = z
    .object({ workspaceId: z.number(), memberId: z.number() })
    .parse(data);

  if (request.method === 'DELETE') {
    try {
      if (memberId < 0) {
        // This is actually the userId of a pseudo-member (pending invite).
        await apiClient.post(`/api/v4/workspaces/${workspaceId}/invites/delete`, {
          userId: -1 * memberId,
        });
        await queryClient.invalidateQueries(
          workspaceInvitePendingConnectedQuery(workspaceId),
        );
      } else {
        await apiClient.delete(`/api/v4/workspaces/${workspaceId}/members/${memberId}`);
      }

      const workspaceSlug = await getWorkspaceSlugFromId(workspaceId);
      await queryClient.invalidateQueries(workspaceQuery(workspaceSlug));
      // Side-effect. Removal from a space causes removal from teams, broad invalidation of workspace teams here.
      // TODO: Clean up specificity pattern of caches now that workspaces are at the top level.
      await queryClient.invalidateQueries({
        queryKey: ['team', `workspace-${workspaceId}`],
      });
      // TODO: Clean up specificity pattern of caches now that workspaces are at the top level.
      await queryClient.invalidateQueries({
        queryKey: ['connections', 'members', workspaceSlug],
      });
      // Also clear the legacy connected users query cache, to update the 'mutual spaces'
      await queryClient.invalidateQueries(personalConnectionsQuery);
      await queryClient.invalidateQueries(invitesSentQuery({ workspaceId })); // Also need to clear invite list of pseudo members. TODO: Cleanup

      toast({ title: 'Member removed successfully' });
    } catch {
      toast({ title: 'Error removing member', color: 'red' });
    }
  }

  return redirect('..');
};

export const useDeleteMember = () => {
  const fetcher = useFetcher<Awaited<ReturnType<typeof memberAction>>>();
  return {
    ...fetcher,
    submit: (data: { workspaceId: number; memberId: number }) =>
      fetcher.submit(data, {
        method: 'DELETE',
        encType: 'application/json',
        action: `${data.memberId}`,
      }),
  };
};
