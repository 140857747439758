import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient, useUser } from '@f4s/api-client';
import {
  updateWidgetSchema,
  type CreateWidgetDataSchema,
  type UpdateWidgetSchema,
} from '@f4s/types';
import { type JsonObject } from '@f4s/types/utils';

import { getOnboardingProgressQuery } from '../onboarding/queries';
import { getWorkspaceIdFromParams } from '../workspace/queries';
import {
  dashboardQuery,
  dashboardsQuery,
  fetchWidgetData,
  type Dashboard,
} from './queries';

export const createDashboardFromTemplate = async ({
  data,
  workspaceId,
}: {
  data: {
    templateId: number;
    title?: string;
    userId?: number;
    initialWidgetData?: CreateWidgetDataSchema[];
  };
  workspaceId?: number;
}) => {
  // TODO: zod parse data
  const dashboard = (await apiClient.post(
    workspaceId ? `/api/v4/workspaces/${workspaceId}/dashboards` : `/api/v4/dashboards`,
    data,
  )) as Dashboard;
  // Set the cache to save waiting for another request in the loader
  queryClient.setQueryData(['dashboard', dashboard.id], dashboard);
  // Insert into the list too
  const existingDashboards =
    queryClient.getQueryData<Dashboard[]>(dashboardsQuery({ workspaceId }).queryKey) ??
    [];
  queryClient.setQueryData(dashboardsQuery({ workspaceId }).queryKey, [
    {
      ...dashboard,
      widgets: dashboard.widgets.filter((w) => w.widgetTemplate.type !== 'separator'), // Filter out separators from the list preview, happens on backend usually.
    },
    ...existingDashboards,
  ]);
  queryClient.invalidateQueries(getOnboardingProgressQuery(workspaceId));

  return dashboard;
};

// Create new dashboard
export const createDashboardFromTemplateAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const data = await request.json();
  const workspaceId = await getWorkspaceIdFromParams(params);
  const dashboard = await createDashboardFromTemplate({ data, workspaceId });

  // Redirect to newly created dashboard
  return redirect(`../${dashboard.id}`);
};

export function useCreateDashboardFromTemplate() {
  const fetcher =
    useFetcher<Awaited<ReturnType<typeof createDashboardFromTemplateAction>>>();
  return {
    ...fetcher,
    submit: (data: {
      templateId: number;
      title?: string;
      userId?: number;
      initialWidgetData?: CreateWidgetDataSchema[];
    }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
      }),
  };
}

type DashboardData = {
  title: string;
  description: string;
  layouts?: JsonObject;
};
// Create new dashboard
export const createDashboardAction = async ({ request, params }: ActionFunctionArgs) => {
  const data = await request.json();
  const workspaceId = await getWorkspaceIdFromParams(params);
  const apiPath = workspaceId
    ? `/api/v4/workspaces/${workspaceId}/dashboards`
    : `/api/v4/dashboards`;

  // TODO: zod parse data
  const dashboard = (await apiClient.post(apiPath, data)) as Dashboard;

  // Set the cache to save waiting for another request in the loader
  queryClient.setQueryData(
    dashboardQuery({ dashboardId: data.dashboardId, workspaceId }).queryKey,
    dashboard,
  );
  // Invalidate the list
  await queryClient.invalidateQueries(dashboardsQuery({ workspaceId }));
  // Redirect to newly created dashboard
  return redirect(`../${dashboard.id}`);
};
export function useCreateDashboard() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof createDashboardAction>>>();
  return {
    ...fetcher,
    submit: (data: DashboardData) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
      }),
  };
}

// Update dashboard
export const updateDashboardAction = async ({ request, params }: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { dashboardId } = params as { dashboardId: string };

  const apiPath = workspaceId
    ? `/api/v4/workspaces/${workspaceId}/dashboards/${dashboardId}`
    : `/api/v4/dashboards/${dashboardId}`;

  // DELETE
  if (request.method === 'DELETE') {
    await apiClient.delete(apiPath);
    await queryClient.invalidateQueries(dashboardsQuery({ workspaceId }));
    return redirect('..');
  }

  const data = await request.json();
  // TODO: zod parse data
  const dashboard = (await apiClient.patch(apiPath, data)) as Dashboard;

  queryClient.setQueryData(
    dashboardQuery({ dashboardId: data.dashboardId, workspaceId }).queryKey,
    dashboard,
  );
  await queryClient.invalidateQueries(dashboardsQuery({ workspaceId }));
  return dashboard;
};

export function useUpdateDashboard() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateDashboardAction>>>();
  return {
    ...fetcher,
    submit: ({
      data,
      method = 'POST',
    }: {
      data: Partial<DashboardData>;
      method?: 'POST' | 'DELETE';
    }) =>
      fetcher.submit(data, {
        method,
        encType: 'application/json',
      }),
  };
}

// Publish dashboard

// Delete dashboard

export const refreshDashboard = async ({ params }: ActionFunctionArgs) => {
  const { dashboardId } = z.object({ dashboardId: z.coerce.number() }).parse(params);
  const workspaceId = await getWorkspaceIdFromParams(params);
  await queryClient.invalidateQueries(dashboardQuery({ dashboardId, workspaceId }));

  return null;
};

export const useDashboardRefresh = () => {
  const fetcher = useFetcher();
  return () =>
    fetcher.submit(null, {
      method: 'POST',
      encType: 'application/json',
      action: '../../refresh',
    });
};

export const shareDashboardAction = async ({
  request,
  params: reqParams,
}: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(reqParams);
  const jsonData = await request.json();
  const params = z
    .object({
      userId: z.number(),
      dashboardId: z.number(),
      scope: z.string(),
      data: z.record(z.any()),
    })
    .parse(jsonData);

  await apiClient.post(`/api/v4/dashboards/${params.dashboardId}/share`, {
    data: params.data,
    scope: params.scope,
  });
  await queryClient.invalidateQueries(
    dashboardQuery({ dashboardId: params.dashboardId, workspaceId }),
  );

  return null;
};

export const useShareDashboard = () => {
  const fetcher = useFetcher();
  const { user } = useUser();

  return async (dashboard: Dashboard, userId?: number) => {
    const data = await Promise.all(
      dashboard?.widgets
        .filter((w) => !w.instance)
        .map(async (w) => {
          return [
            w.id,
            await fetchWidgetData({
              widget: w,
              defaultUserId: userId ?? user.id,
            }),
          ];
        }),
    );

    return fetcher.submit(
      {
        userId: user.id,
        dashboardId: dashboard.id,
        scope: 'anyoneWithLink',
        data: Object.fromEntries(data),
      },
      {
        method: 'POST',
        encType: 'application/json',
        action: `./share`,
      },
    );
  };
};

/* -------------------------------------------------------------------------- */
/*                                   Widgets                                  */
/* -------------------------------------------------------------------------- */

export const updateWidgetAction = async ({ request, params }: ActionFunctionArgs) => {
  const data = await request.json();
  const widgetData = updateWidgetSchema.parse(data);
  const workspaceId = await getWorkspaceIdFromParams(params);

  const apiPath = workspaceId
    ? `/api/v4/workspaces/${workspaceId}/dashboards/${widgetData.dashboardId}/widgets`
    : `/api/v4/dashboards/${widgetData.dashboardId}/widgets`;

  if (widgetData.id) {
    if (request.method === 'DELETE') {
      await apiClient.delete(`${apiPath}/${widgetData.id}`);
    } else {
      // If the id exists, we're updating an existing widget
      await apiClient.patch(`${apiPath}/${widgetData.id}`, widgetData);
      await queryClient.invalidateQueries({
        queryKey: ['dashboard', 'widget', widgetData.id],
      });
    }
  } else {
    (await apiClient.post(apiPath, widgetData)) as { id: number };
  }

  await queryClient.invalidateQueries({
    queryKey: ['dashboard', widgetData.dashboardId],
  });

  // Invalidate the list so that previews update
  await queryClient.invalidateQueries({
    queryKey: ['dashboard', 'list'],
  });

  // Redirect to newly created dashboard
  return null;
};

export function useUpdateWidget() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateWidgetAction>>>();
  return {
    ...fetcher,
    submit: ({
      data,
      method = 'POST',
    }: {
      data: UpdateWidgetSchema;
      method?: 'POST' | 'PATCH' | 'DELETE';
    }) =>
      fetcher.submit(data, {
        method,
        encType: 'application/json',
      }),
  };
}
