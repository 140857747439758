/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';

import { useMaybeUser } from '@f4s/api-client';
import { Button, Card, EmptyCard, EmptyState, Icon } from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import { DirectlyConnectedBadge } from '@/components/badge';
// import { GridContainer } from '@/components/card-grid';
import { NavLink } from '@/components/nav-link';
import { InviteModal } from '@/modules/workspace/components/workspace-invite-modal';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useFeatureFlag } from '@/providers/feature-flags';

import { useDemoMemberModal } from '../hooks/use-demo-member-modal';
import { type MembersLoaderData } from '../loaders';

const GRID_CONFIG = {
  'default': { cols: 2, rows: 2 },
  '420px': { cols: 3, rows: 3 },
  '576px': { cols: 4, rows: 4 },
  '768px': { cols: 6, rows: 6 },
  '896px': { cols: 7, rows: 7 },
};

type PixelValue = `${number}px`;
type GridConfig = {
  [key in PixelValue | 'default']?: {
    cols: number;
    rows?: number;
  };
};

export const MemberGrid = ({
  members: propMembers,
  editMenuRender,
  gridLimit,
  excludeMe = false,
  gridConfig = GRID_CONFIG,
}: {
  members?: MembersLoaderData['members'];
  editMenuRender?: ({ memberId }: { memberId: number | null }) => ReactNode;
  gridLimit?: number;
  excludeMe?: boolean;
  gridConfig?: GridConfig;
}) => {
  const { pathname, workspace } = useMatchedWorkspace();
  const showDemoInviteFlag = useFeatureFlag('MarleeWeb.DemoUserInvite');
  const members = propMembers ?? workspace?.members;
  const { user } = useMaybeUser();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { modalTrigger, modalBody } = useDemoMemberModal();

  const getGridConfig = useCallback(
    (width: number) => {
      const pixelBreakpoints = Object.entries(gridConfig)
        .filter(([key]) => key.endsWith('px'))
        .map(([key]) => Number.parseInt(key))
        .sort((a, b) => b - a);

      for (const breakpoint of pixelBreakpoints) {
        if (width >= breakpoint) {
          const key = `${breakpoint}px` as PixelValue;
          return gridConfig[key];
        }
      }

      return gridConfig.default;
    },
    [gridConfig],
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    observer.observe(container);
    return () => observer.disconnect();
  }, []);

  const [currentConfig, setCurrentConfig] = useState<
    | {
        cols: number;
        rows?: number;
      }
    | undefined
  >();
  const [effectiveGridLimit, setEffectiveGridLimit] = useState<number>(gridLimit ?? 2);

  useEffect(() => {
    const config = getGridConfig(containerWidth);
    setCurrentConfig(config);
    // Use provided gridLimit if specified, otherwise use calculated value
    if (!gridLimit) {
      const calculatedGridLimit = (config?.cols ?? 2) * (config?.rows ?? 2);
      setEffectiveGridLimit(calculatedGridLimit);
    }
  }, [containerWidth, getGridConfig, gridLimit]);

  const body = useMemo(() => {
    // Remove the current user from the list if excludeMe is true
    const filteredMembers = excludeMe
      ? members?.filter((m) => m.userId !== user?.id)
      : members;

    if (filteredMembers?.length === 0) {
      return (
        <EmptyState
          heading="You're the only one here!"
          message="Invite other people to your space to start collaborating."
          actions={
            <div className="flex justify-center gap-2">
              <InviteModal
                trigger={
                  <Button variant="secondary">
                    <Icon.UserPlus weight="duotone" size={18} />
                    <span>Invite</span>
                  </Button>
                }
              />
              {showDemoInviteFlag && modalTrigger}
            </div>
          }
          gridConfig={GRID_CONFIG}
        />
      );
    }

    const limitedMembers = filteredMembers?.slice(0, effectiveGridLimit);
    const memberCards =
      limitedMembers?.map((m) => (
        <Card
          key={m.id}
          asChild
          className="flex aspect-[4/3] w-full min-w-0 shrink-0 flex-col items-center justify-center gap-3 rounded-lg p-6 transition-shadow duration-300 hover:shadow-lg"
        >
          <NavLink to={`${pathname}/people/members/${m.id ?? ''}`}>
            <ProfileAvatar
              alt=""
              size="lg"
              avatarUrl={m.user.avatarUrl}
              initials={m.user.initials}
              badge={m.isPending ? <DirectlyConnectedBadge /> : undefined}
            >
              {!m.id && <Icon.Envelope />}
            </ProfileAvatar>
            <div className="relative flex w-full flex-col items-center">
              <span className="w-full truncate text-center font-medium">
                {m.id ? m.user.fullName : m.user.emailAddress}
              </span>
              {m.isPending && (
                <span className="text-muted-foreground text-2xs absolute top-4 z-10 w-full truncate text-center">
                  Invite pending
                </span>
              )}
            </div>
            {editMenuRender && m.userId !== user?.id && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {editMenuRender({ memberId: m.id })}
              </div>
            )}
          </NavLink>
        </Card>
      )) ?? [];

    let placeholderBg = null;
    // Add placeholder cards if needed
    if (effectiveGridLimit && memberCards.length < effectiveGridLimit) {
      const placeholdersNeeded = effectiveGridLimit - memberCards.length;
      const placeholders = Array.from({ length: placeholdersNeeded }).map((_, i) => {
        if (i === placeholdersNeeded - 1) {
          return (
            <InviteModal
              key={`placeholder-${i}`}
              trigger={
                <Button
                  variant="ghost"
                  className="text-muted-foreground flex aspect-[4/3] h-full w-full min-w-0 shrink-0 flex-col items-center justify-center rounded-xl"
                >
                  <div className="flex flex-col items-center gap-3">
                    <Icon.UserPlus weight="duotone" size={24} />
                    <span>Invite members</span>
                  </div>
                </Button>
              }
            />
          );
        }
        return (
          <EmptyCard
            key={`placeholder-${i}`}
            aria-hidden="true"
            className="bg-transparent ring-0"
          />
        );
      });
      memberCards.push(...placeholders);

      placeholderBg = (
        <div className="absolute inset-0 z-0 flex items-center justify-center">
          <EmptyState gridConfig={GRID_CONFIG} />
        </div>
      );
    }
    return (
      <>
        <div
          className="z-10 grid w-full min-w-0 flex-1 gap-3"
          style={{
            gridTemplateColumns: `repeat(${currentConfig?.cols ?? 2}, 1fr)`,
          }}
        >
          {memberCards}
        </div>
        {placeholderBg}
      </>
    );
  }, [
    currentConfig?.cols,
    editMenuRender,
    effectiveGridLimit,
    excludeMe,
    members,
    modalTrigger,
    pathname,
    showDemoInviteFlag,
    user?.id,
  ]);

  // Memoized body so that modalBody is rendered in a stable way
  return (
    <div ref={containerRef} className="relative flex flex-1">
      {body}
      {modalBody}
    </div>
  );
};
