import queryString from 'query-string';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  type CoachingProgram,
  type CoachingProgramRecommendations,
  type CoachingProgramSessionInfo,
  type CoachingQueueItem,
} from '@f4s/types';
import { motivationDefinitions } from '@f4s/ui';

import { type Config } from './utils';

export type ProgramsResponse = {
  allIds: number[];
  byId: { [key: number]: CoachingProgram };
};

export const coachingProgramsQuery = {
  queryKey: ['/api/v3/coaching/programs'],
  queryFn: async () => {
    const data = (await apiClient.get('/api/v3/coaching/programs')) as ProgramsResponse;

    return Object.values(data.byId).map(
      (program) =>
        ({
          ...program,
          reviews: program.reviews.map((review) => ({
            ...review,
            avatar: review.avatar.replace(/\/assets/, ''),
          })),
          colourCode:
            motivationDefinitions.find(({ code }) => code === program.colourCode)
              ?.group ?? 'action-direction',
        }) as CoachingProgram,
    );
  },
};

export type RecommendedProgramsOpts = {
  goalId?: number;
  roleId?: number;
  insightId?: string;
};

export const coachingRecommendationsQuery = (opts: RecommendedProgramsOpts) => ({
  queryKey: ['/api/v3/coaching/programs/recommendations', JSON.stringify(opts)],
  queryFn: async () => {
    return (await apiClient.get(
      `/api/v3/coaching/programs/recommendations?${queryString.stringify(opts)}`,
    )) as CoachingProgramRecommendations;
  },
});

export const fetchCoachingRecommendations = (opts: RecommendedProgramsOpts) =>
  queryClient.fetchQuery(coachingRecommendationsQuery(opts));

export const fetchCoachingPrograms = () => queryClient.fetchQuery(coachingProgramsQuery);

export const coachingQueueQuery = {
  queryKey: ['coaching', 'queue'],
  queryFn: async () => {
    const queueItems = (await apiClient.get(
      '/api/v3/coaching/queue',
    )) as (CoachingQueueItem & {
      program?: CoachingProgram;
      sessionInfo?: CoachingProgramSessionInfo;
    })[];

    const programs = await fetchCoachingPrograms();

    for (const q of queueItems) {
      const program = programs.find((p) => p.id === q.programId);
      q.program = program;
      if (q.status === 'started') {
        q.sessionInfo = await fetchCoachingSessionInfo(q.id);
      }
    }
    return queueItems;
  },
};

export const fetchCoachingQueue = () => queryClient.fetchQuery(coachingQueueQuery);

export const webChatUrlQuery = () => ({
  queryKey: ['/api/v2/content/botpress/webchat-url'],
  queryFn: async () => {
    return await apiClient.get<string>('/api/v2/content/botpress/webchat-url');
  },
});

export const fetchWebChatUrl = () => queryClient.fetchQuery(webChatUrlQuery());

export const coachingConfigQuery = (id: string) => ({
  queryKey: ['/api/v1/coach/bot-config', id],
  queryFn: () => apiClient.get<Config>(`/api/v1/coach/bot-config?id=${id}`),
});

export const fetchCoachingConfig = (id: string) =>
  queryClient.fetchQuery(coachingConfigQuery(id));

export const coachingSessionInfoQuery = (programId: number) => ({
  queryKey: ['coaching', 'queue', 'program', programId],
  queryFn: async () => {
    return (await apiClient.get(
      `/api/v3/coaching/queue/${programId}/session-info`,
    )) as CoachingProgramSessionInfo;
  },
});

export const fetchCoachingSessionInfo = (programId: number) =>
  queryClient.fetchQuery(coachingSessionInfoQuery(programId));
