import { useCallback, useEffect, useState, type FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavLinkButton } from '@/components/nav-link';
import { Page } from '@/components/page';
import { PageHeader } from '@/components/page-header';

const getParentPath = (path: string) =>
  path.replace(/\/$/, '').split('/').slice(0, -1).join('/') || '/';

export const MissingPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parentPath = getParentPath(location.pathname);
  const [timeout, setTimeout] = useState<number>(5);

  const handleRedirect = useCallback(() => {
    navigate(parentPath, { replace: true });
  }, [parentPath, navigate]);

  useEffect(() => {
    if (timeout <= 0) {
      handleRedirect();
    }
  }, [handleRedirect, timeout]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout((prev) => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [handleRedirect]);

  return (
    <Page>
      <PageHeader title="Oops! That page does not exist" />
      <p>
        Sorry, it appears you have navigated to a page that does not exist. Redirecting
        you in {timeout} seconds...
      </p>
      <NavLinkButton to={parentPath} replace={true}>
        Redirect now
      </NavLinkButton>
    </Page>
  );
};
