import type { RouteObject } from 'react-router-dom';

import { handle } from '@/components/breadcrumbs';

import { onboardingAnalysisRoutes } from './analysis/routes';
import { onboardingCompleteLoader, onboardingIndexLoader } from './loaders';
import { onboardingProfileLayoutLoader } from './profile/loaders';
import { LayoutSpaceBackground } from './profile/pages/layout';
import { onboardingProfileRoutes } from './profile/routes';
import { onboardingSpaceLayoutLoader } from './spaces/loaders';
import { LayoutSpaceMinimal } from './spaces/pages/layout';
import { onboardingSpaceRoutes } from './spaces/routes';
import { onboardingTalentLayoutLoader } from './talent/loaders';
import { LayoutTalentBackground } from './talent/pages/layout';
import { onboardingTalentRoutes } from './talent/routes';

export const onboardingRoutes: RouteObject = {
  path: 'onboarding',
  children: [
    // ONBOARDING START
    { index: true, loader: onboardingIndexLoader },

    // SPACE: JOIN OR CREATE
    {
      Component: LayoutSpaceMinimal,
      loader: onboardingSpaceLayoutLoader,
      children: [onboardingSpaceRoutes],
    },

    // SPACE: PROFILE + ANALYSIS
    {
      Component: LayoutSpaceBackground, // Blurred workspace background layout
      loader: onboardingProfileLayoutLoader,
      handle: handle({ type: 'workspace' }),
      children: [
        // PROFILE
        onboardingProfileRoutes,
        onboardingAnalysisRoutes,
      ],
    },

    // TALENT
    {
      Component: LayoutTalentBackground, // Blurred talent hub layout
      loader: onboardingTalentLayoutLoader,
      children: [onboardingTalentRoutes],
    },

    // ONBOARDING COMPLETE
    { path: 'complete', loader: onboardingCompleteLoader },
  ],
};
