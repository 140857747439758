import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';
import { rootLoader } from '@/modules/root/loaders';
import { WORKSPACE_LOCAL_PREFERENCE_NAME } from '@/modules/workspace/components/workspace-switcher';
import { fetchWorkspaces } from '@/modules/workspace/queries';
import { fetchLocalPreference } from '@/providers/local-preference';

import { DUMMY_PROGRESS, DUMMY_WORKSPACE } from '../queries';

/* -------------------------------------------------------------------------- */
/*                             PROFILE ONBOARDING                             */
/* -------------------------------------------------------------------------- */
// Layout
export const onboardingProfileLayoutLoader = async () => {
  const user = await authProvider.getMaybeUser();
  // We do actually use a real workspace in the background if it exists
  const workspaces = await fetchWorkspaces();
  const lastWorkspaceId = await fetchLocalPreference<number>({
    preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
  });
  const workspace =
    (lastWorkspaceId && workspaces.find((w) => w.id === lastWorkspaceId)) ||
    workspaces[0] ||
    DUMMY_WORKSPACE;
  return { user, workspace, progress: DUMMY_PROGRESS };
};

// Entrypoint
export const onboardingProfileIndexLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  return redirect('/onboarding/profile/update');
};
// Space onboarding common loader
export const onboardingProfileLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  sendEvent('signUp_next', { signUpStep: 'profile-update' });
  return { user: rootLoaderResponse.user };
};
// Space onboarding done
export const onboardingProfileCompleteLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  // Space onboarding complete, where to next?
  return redirect('../../analysis');
};
