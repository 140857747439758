import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  type Estimate,
  type EstimatedInvoice,
  type EstimateRequest,
  type Invoice,
  type PaymentMethod,
  type PlanData,
  type SubscriptionResponse,
} from '@f4s/types';

type SubscriptionQueryParams = { type?: string; id?: string | number };
type PaymentMethodQueryParams = { type?: 'user' | 'org'; id?: string | number };
type InvoicesQueryParams = { type?: 'user' | 'org'; id?: string | number };

export const paymentMethodQuery = ({ type, id }: PaymentMethodQueryParams) =>
  queryOptions({
    queryKey: ['/api/v2/payments/subscriptions/payment-card', type, id],
    queryFn: async () => {
      const idParam = id ? `?id=${id}` : '';
      return apiClient
        .get(`/api/v2/payments/subscriptions/payment-card/${type}${idParam}`)
        .catch(() => null) as Promise<PaymentMethod | null>;
    },
  });

export const fetchPaymentMethod = (params: PaymentMethodQueryParams) =>
  queryClient.fetchQuery(paymentMethodQuery(params));

export const usePaymentMethod = (params: PaymentMethodQueryParams) =>
  useQuery(paymentMethodQuery(params));

export const subscriptionQuery = (params?: SubscriptionQueryParams) =>
  queryOptions({
    queryKey: ['/api/v2/payments/subscriptions/', JSON.stringify(params)],
    queryFn: async () => {
      const idParam = params?.id ? `?id=${params.id}` : '';
      return apiClient.get(
        `/api/v2/payments/subscriptions/${params?.type}${idParam}`,
      ) as Promise<SubscriptionResponse>;
    },
  });

export const fetchSubscription = (params?: SubscriptionQueryParams) =>
  queryClient.fetchQuery(subscriptionQuery(params));

export const useSubscription = (params?: SubscriptionQueryParams) =>
  useQuery(subscriptionQuery(params));

export const invoicesQuery = ({ id, type }: InvoicesQueryParams) =>
  queryOptions({
    queryKey: ['/api/v2/payments/subscriptions/invoices', id],
    queryFn: async () => {
      const idParam = id ? `?id=${id}` : '';
      return apiClient.get(
        `/api/v2/payments/subscriptions/invoices/${type}${idParam}`,
      ) as Promise<{
        estimate: EstimatedInvoice;
        invoices: Array<Invoice>;
      }>;
    },
  });

export const fetchInvoices = (params: InvoicesQueryParams) =>
  queryClient.fetchQuery(invoicesQuery(params));

export const useInvoices = (params: InvoicesQueryParams) =>
  useQuery(invoicesQuery(params));

export const availablePlansQuery = () =>
  queryOptions({
    queryKey: ['/api/v2/payments/plans'],
    queryFn: async () => {
      return apiClient.get('/api/v2/payments/plans') as Promise<PlanData[]>;
    },
  });

export const fetchAvailablePlans = () => queryClient.fetchQuery(availablePlansQuery());

export const useAvailablePlans = () => useQuery(availablePlansQuery());

export const estimateQuery = (params: EstimateRequest) =>
  queryOptions({
    queryKey: ['/api/v2/payments/estimate', params.paymentCycle, params.coupons],
    queryFn: async () => {
      return apiClient.post('/api/v2/payments/estimate', params) as Promise<Estimate>;
    },
    retry: false,
  });

export const fetchEstimate = (params: EstimateRequest) =>
  queryClient.fetchQuery(estimateQuery(params));

export const useEstimate = (params: EstimateRequest) => useQuery(estimateQuery(params));
