import type { AnalyticsSchema, EventName } from '@f4s/types/analytics';

import { HTTPError } from './errors';

function getAbsoluteURL(url: string) {
  if (typeof window !== 'undefined') {
    return new URL(url, window.location.href);
  }

  if (process.env['API_HOST'] && process.env['API_PROTOCOL']) {
    const baseURL = `${process.env['API_PROTOCOL']}//${process.env['API_HOST']}`;
    return new URL(url, baseURL);
  }

  // URL is possibly already an absolute URL
  // Throws if no host is present
  return new URL(url);
}

type RequestProps = {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'HEAD';
  url: string;
  body?: unknown;
  abortController?: AbortController | undefined;
  streamed?: boolean | undefined;
  isFormData?: boolean | undefined;
  signal?: AbortSignal;
};

export class ApiClient {
  private static instance: ApiClient;

  async newRequest<T>({
    method,
    url,
    body,
    abortController,
    streamed,
    isFormData = false,
    signal,
  }: RequestProps) {
    const headers = new Headers();
    headers.set('x-site', 'marleeWeb');
    if (typeof window !== 'undefined') {
      headers.set('x-f4s-request-url', window.location.href);
    }

    if (body && !isFormData) headers.set('Content-Type', 'application/json');

    const request = new Request(getAbsoluteURL(url), {
      method,
      signal: signal ?? abortController?.signal ?? null,
      body: (!isFormData ? JSON.stringify(body) : body) as BodyInit | null,
      credentials: 'credentials' in Request.prototype ? 'same-origin' : undefined!,
      headers,
    });
    const response = await fetch(request);

    if (!response.ok) {
      throw new HTTPError({ request, response });
    }

    if (streamed) {
      if (response.status === 202 || !response.body) {
        return response.json();
      }
      return response.body.pipeThrough(new TextDecoderStream());
    }

    return response
      .clone()
      .json()
      .catch(() => response.text()) as T;
  }

  get = <T>(url: string, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'GET', url });
  head = <T>(url: string, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'HEAD', url });
  delete = <T>(url: string, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'DELETE', url });
  post = <T>(url: string, body: unknown, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'POST', url, body });
  patch = <T>(url: string, body: unknown, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'PATCH', url, body });
  put = <T>(url: string, body: unknown, opts?: Partial<RequestProps>): Promise<T> =>
    this.newRequest<T>({ ...opts, method: 'PUT', url, body });

  // Analytics tracking event
  sendEvent = <K extends EventName>(eventName: K, payload: AnalyticsSchema<K>) => {
    this.post(`/api/v3/public/analytics`, { eventName, payload }).catch((error) =>
      console.error('Analytics error', error),
    );
  };

  public static getInstance() {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }
}
export const apiClient = ApiClient.getInstance();
