import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { FileDoc, FilePdf, Plus } from '../icons';
import { cn } from '../lib/utils';

type Props = {
  currentFile?: { name: string };
  onFileSelected?: (file: File) => void;
  label?: string;
  labelledBy?: string;
  className?: string;
  disabled?: boolean;
  hideIcon?: boolean;
};

export const DocumentUploader = ({
  currentFile,
  onFileSelected,
  label,
  labelledBy,
  className,
  disabled,
  hideIcon,
}: Props) => {
  const [file, setFile] = useState<{ name: string } | undefined>(currentFile);
  useEffect(() => {
    if (currentFile) {
      setFile(currentFile);
    }
  }, [currentFile]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [acceptedFile] = acceptedFiles;
      if (acceptedFile) {
        setFile(acceptedFile);
        onFileSelected?.(acceptedFile);
      }
    },
    [onFileSelected],
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': [], // PDF files
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // DOCX files
    },
    onDrop,
    disabled,
  });

  return (
    <div
      className={cn(
        'dropzone-container focus:ring-primary focus-visible:ring-primary bg-card ring-foreground/5 hover:ring-foreground/20 relative flex aspect-square h-16 w-16 shrink-0 items-center justify-center overflow-hidden rounded-full ring-1 focus:ring-2 focus-visible:outline-none',
        className,
      )}
      {...getRootProps({
        'role': 'button',
        'aria-label': labelledBy
          ? undefined
          : (label ?? 'drag and drop avatar image upload'),
        'aria-labelledby': labelledBy,
      })}
    >
      {!hideIcon &&
        (file ? (
          file.name.endsWith('.pdf') ? (
            <FilePdf size={20} />
          ) : (
            <FileDoc size={20} />
          )
        ) : (
          <Plus size={20} />
        ))}

      <input {...getInputProps()} />
    </div>
  );
};

export const DocumentDownloader = ({
  fileName,
  handleDownload,
  label,
  labelledBy,
  className,
  disabled,
}: {
  fileName: string;
  handleDownload: () => void;
  label?: string;
  labelledBy?: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      className={cn(
        'dropzone-container focus:ring-primary focus-visible:ring-primary bg-card ring-foreground/5 hover:ring-foreground/20 relative flex aspect-square h-16 w-16 shrink-0 items-center justify-center overflow-hidden rounded-full ring-1 focus-visible:outline-none',
        className,
      )}
      aria-label={labelledBy ? undefined : (label ?? 'drag and drop avatar image upload')}
      aria-labelledby={labelledBy}
      onClick={handleDownload}
      disabled={disabled}
    >
      {fileName.endsWith('.pdf') ? <FilePdf size="20" /> : <FileDoc size="20" />}
    </button>
  );
};
