import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import type { TalentProfileCreateReq } from '@f4s/types';

export type ExpectedSalaryType = Exclude<
  TalentProfileCreateReq['expectedSalary'],
  undefined
>;
export const expectedSalaryMap = [
  { value: 'null', search: 'Any', label: 'Any' },
  { value: 'level1', search: '50k - 100k', label: '50k - 100k' },
  { value: 'level2', search: '100k - 150k', label: '100k - 150k' },
  { value: 'level3', search: '150k - 250k', label: '150k - 250k' },
  { value: 'level4', search: '250k - 500k', label: '250k - 500k' },
  { value: 'level5', search: '500k +', label: '500k +' },
];
export type ExpectedSalaryCurrencyType = Exclude<
  TalentProfileCreateReq['expectedSalaryCurrency'],
  undefined
>;
export const expectedSalaryCurrencyMap = [
  { value: 'USD', label: 'USD' },
  { value: 'AUD', label: 'AUD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
];
export type WorkLocationType = Exclude<TalentProfileCreateReq['locationType'], undefined>;
export const locationTypeMap = [
  { value: 'null', label: 'Any' },
  { value: 'remote', label: 'Remote' },
  { value: 'hybrid', label: 'Hybrid' },
  { value: 'office', label: 'Office' },
];

export type AvailabilityType = Exclude<TalentProfileCreateReq['availability'], undefined>;
export const availabilityTypeMap = [
  { value: 'null', label: 'Available now' },
  { value: 'two_weeks', label: '2 weeks notice' },
  { value: 'four_weeks', label: '4 weeks notice' },
  { value: 'more', label: 'More than 4 weeks notice' },
];

export type CompanySizeType = Exclude<TalentProfileCreateReq['companySize'], undefined>;
export const companySizeTypeMap = [
  { value: 'null', label: 'Any size' },
  { value: 'small', label: '1-10 employees' },
  { value: 'small_medium', label: '10-50  employees' },
  { value: 'medium', label: '50-250  employees' },
  { value: 'large', label: '250-1000  employees' },
  { value: 'very_large', label: '1000+  employees' },
];

export type TalentProfile = {
  id: number;
  externalId: string;
  name: string;
  userId: number;
  uploadUrl: string | null;
  uploadName: string | null;
  isAuthorized: boolean | null;
  linkedinUrl: string | null;
  countryCode: string | null;
  location: string | null;
  locationType: WorkLocationType;
  expectedSalary: ExpectedSalaryType;
  expectedSalaryCurrency: ExpectedSalaryCurrencyType;
  availability: AvailabilityType;
  companySize: CompanySizeType;
  pools: { pool: { id: number; name: string } }[];
};
export type TalentProfileDetails = TalentProfile;

export type TalentTags = {
  id: number;
  name: string;
  displayName: string | null;
};

export const talentProfilesQuery = {
  queryKey: ['talent', 'profiles', 'list'],
  queryFn: async () =>
    apiClient.get('/api/v4/talent/profiles') as Promise<TalentProfile[]>,
};
export const fetchTalentProfiles = () => queryClient.fetchQuery(talentProfilesQuery);
export const useTalentProfiles = () => useQuery(talentProfilesQuery);

export const talentProfileQuery = ({ profileId }: { profileId: number }) => ({
  queryKey: ['talent', 'profiles', profileId],
  queryFn: async () =>
    apiClient.get(
      `/api/v4/talent/profiles/${profileId}`,
    ) as Promise<TalentProfileDetails>,
});
export const fetchTalentProfile = ({ profileId }: { profileId: number }) =>
  queryClient.fetchQuery(talentProfileQuery({ profileId }));
export const useTalentProfile = ({ profileId }: { profileId: number }) =>
  useQuery(talentProfileQuery({ profileId }));

export const talentProfileDownloadQuery = ({ profileId }: { profileId: number }) => ({
  queryKey: ['talent', 'profiles', profileId, 'download'],
  queryFn: async () =>
    apiClient.get(`/api/v4/talent/profiles/${profileId}/upload`) as Promise<
      string | null
    >,
});
export const fetchTalentProfileDownload = ({ profileId }: { profileId: number }) =>
  queryClient.fetchQuery(talentProfileDownloadQuery({ profileId }));

// TODO: Remove legacy tags after talent pool migration
export const talentLegacyTagsQuery = {
  queryKey: ['talent', 'tags'],
  queryFn: async () =>
    apiClient.get('/api/v4/talent/profiles/tags') as Promise<TalentTags[]>,
};
export const fetchTalentLegacyTags = () => queryClient.fetchQuery(talentLegacyTagsQuery);
export const useTalentLegacyTags = () => useQuery(talentLegacyTagsQuery);

export const setShouldEdit = (shouldEdit: boolean) => {
  queryClient.setQueryData<boolean>(['talent', 'profiles', 'edit'], shouldEdit);
};
export const fetchAndClearShouldEdit = () => {
  const shouldEdit = queryClient.getQueryData<boolean>(['talent', 'profiles', 'edit']);
  if (shouldEdit) {
    setShouldEdit(false);
  }
  return !!shouldEdit;
};
