import { useCallback, type ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import type { MonetizationLockEventSchema } from '@f4s/types/analytics';
import { Alert, cn, Icon } from '@f4s/ui';

import { sendEvent } from '@/lib/analytics';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

interface MonetizationBannerProps extends MonetizationLockEventSchema {
  children?: ReactNode;
  className?: string;
}

export const MonetizationBanner = ({
  type,
  feature,
  name,
  children,
  className,
}: MonetizationBannerProps) => {
  const { pathname } = useMatchedWorkspace();

  const handleVisible = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        sendEvent('monetizationLock_viewed', {
          type,
          feature,
          name,
        });
      }
    },
    [feature, name, type],
  );

  const handleClick = useCallback(() => {
    sendEvent('monetizationLock_clicked', {
      type,
      feature,
      name,
    });
  }, [feature, name, type]);

  return (
    <Alert
      variant="info"
      icon={<Icon.Warning size={18} className="mr-1" />}
      className={cn('flex items-center', className)}
      ref={handleVisible}
    >
      <div className="flex flex-1 flex-wrap items-center justify-between gap-2">
        <div>{children}</div>
        <NavLink
          to={`${pathname}/settings/billing/plans`}
          className="flex flex-row items-center gap-1"
          onClick={handleClick}
        >
          <span>View upgrade options</span>
          <Icon.ArrowRight size="16" />
        </NavLink>
      </div>
    </Alert>
  );
};
