import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import { Command as CommandPrimitive } from 'cmdk';
import { X } from 'lucide-react';
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  type ClipboardEventHandler,
  type ReactNode,
} from 'react';

import { FuzzySearcher } from '../lib/fuzzy-searcher';
import { cn } from '../lib/utils';
import { Badge } from './badge';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from './command';
import { inputVariants } from './input';
import { Popover, PopoverAnchor, PopoverContent } from './popover';
import { ScrollBar } from './scroll-area';

type Option<T> = { label: string; key: string; value: T };

export const InputMultiselect = <T,>({
  suggestions,
  extraSuggestions,
  selections,
  onSelectionAdd,
  onSelectionRemove,
  value: externalValue,
  onValueChange,
  badgeRenderer,
  suggestionRenderer,
  onInputPaste,
  placeholder = 'Search for a value...',
  className,
  disabled,
}: {
  suggestions: Option<T>[];
  extraSuggestions?: Option<T>[]; // These are not filtered inside this component, only ever appended.
  selections: Option<T>[];
  onSelectionAdd: (selection: Option<T>) => void;
  onSelectionRemove: (selection: Option<T>) => void;
  value?: string;
  onValueChange?: (value: string) => void;
  badgeRenderer?: (selections: Option<T>[]) => ReactNode;
  onInputPaste?: ClipboardEventHandler<HTMLInputElement>;
  suggestionRenderer?: (args: {
    suggestions: Option<T>[];
    handleSelection: (key: string) => void;
  }) => ReactNode;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}) => {
  const [value, setValue] = useState<string>(externalValue ?? '');
  const [open, setOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const commandRef = useRef<HTMLInputElement | null>(null);

  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
  const handleRef = useCallback((node: HTMLDivElement | null) => {
    setScrollContainer(node);
  }, []);

  const searcher = useMemo(
    () =>
      new FuzzySearcher({
        array: suggestions,
      }),
    [suggestions],
  );

  const filteredSuggestions = useMemo(() => {
    const searchedSuggestions =
      suggestions.length > 0
        ? searcher.search({
            value,
            limit: !value ? undefined : 10,
          })
        : [];

    return extraSuggestions
      ? [...searchedSuggestions, ...extraSuggestions]
      : searchedSuggestions;
  }, [extraSuggestions, searcher, suggestions.length, value]);

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => scrollContainer,
    count: filteredSuggestions.length,
    estimateSize: () => 36,
    overscan: 5,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const handleValueChange = useCallback(
    (v: string) => {
      setValue(v);
      onValueChange?.(v);
    },
    [onValueChange],
  );

  const handleSelection = useCallback(
    (key: string) => {
      const input = inputRef.current;
      let newSelection = suggestions.find((s) => s.key === key);
      if (!newSelection && extraSuggestions) {
        newSelection = extraSuggestions.find((s) => s.key === key);
      }
      if (newSelection) {
        if (input?.value) {
          handleValueChange('');
          if (scrollContainer) {
            scrollContainer.scrollTop = 0;
          }
        }
        onSelectionAdd(newSelection);
      }
      input?.focus();
    },
    [extraSuggestions, handleValueChange, onSelectionAdd, scrollContainer, suggestions],
  );

  const handleClose = useCallback(() => {
    handleValueChange('');
  }, [handleValueChange]);

  const handleOpen = useCallback(
    (shouldOpen: boolean) => {
      if (!shouldOpen) {
        handleClose();
      }
      setOpen(shouldOpen);
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    },
    [handleClose, scrollContainer],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (!input) return;
      if (input.value === '' && (e.key === 'Delete' || e.key === 'Backspace')) {
        const option = selections.at(-1);
        if (option) {
          if (selections.length === 1 && scrollContainer) {
            scrollContainer.scrollTop = 0;
          }
          onSelectionRemove(option);
        }
      }
      // This is not a default behavior of the <input /> field
      if (e.key === 'Escape') {
        input.blur();
      }
    },
    [onSelectionRemove, scrollContainer, selections],
  );

  const selectionBadges = useMemo(() => {
    if (badgeRenderer) {
      return badgeRenderer(selections);
    }
    return selections.map((selection) => (
      <Badge key={selection.key} variant="secondary" size="xs">
        {selection.label}
        <button
          className="ring-offset-background focus:ring-ring ml-1 rounded-full outline-none focus:ring-2 focus:ring-offset-2"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSelectionRemove(selection);
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            onSelectionRemove(selection);
            e.stopPropagation();
          }}
        >
          <X className="text-muted-foreground hover:text-foreground h-3 w-3" />
        </button>
      </Badge>
    ));
  }, [badgeRenderer, onSelectionRemove, selections]);

  const suggestionList = useMemo(() => {
    if (!open) return null;

    const virtualSuggestions = filteredSuggestions.slice(
      virtualRows.at(0)?.index ?? 0,
      (virtualRows.at(-1)?.index ?? 0) + 1,
    );

    const [before, after] =
      virtualRows.length > 0
        ? [
            notUndefined(virtualRows[0]).start - rowVirtualizer.options.scrollMargin,
            rowVirtualizer.getTotalSize() - notUndefined(virtualRows.at(-1)).end,
          ]
        : [0, 0];

    if (suggestionRenderer) {
      return (
        <>
          {virtualSuggestions.length > 10 ? <div style={{ height: before }} /> : null}
          {suggestionRenderer({ suggestions: virtualSuggestions, handleSelection })}
          {virtualSuggestions.length > 10 ? <div style={{ height: after }} /> : null}
        </>
      );
    }
    return (
      <CommandGroup>
        {before > 0 ? <div style={{ height: before }} /> : null}
        <CommandEmpty>Start typing to see suggestions</CommandEmpty>
        {virtualSuggestions.flatMap((suggestion) => {
          if (selections.some((selection) => selection.key === suggestion.key)) return [];
          return (
            <CommandItem
              key={suggestion.key}
              value={suggestion.key}
              onSelect={handleSelection}
              className="hover:cursor-pointer"
            >
              {suggestion.label}
            </CommandItem>
          );
        })}
        {after > 0 ? <div style={{ height: after }} /> : null}
      </CommandGroup>
    );
  }, [
    filteredSuggestions,
    handleSelection,
    open,
    rowVirtualizer,
    selections,
    suggestionRenderer,
    virtualRows,
  ]);

  return (
    <Popover open={open} onOpenChange={handleOpen}>
      <Command
        shouldFilter={false}
        className="h-auto overflow-visible bg-transparent"
        onKeyDown={handleKeyDown}
        onClick={() => {
          inputRef.current?.focus();
        }}
        ref={commandRef}
      >
        <PopoverAnchor>
          <div
            className={cn(
              inputVariants({ size: 'default' }),
              'h-auto min-h-9 flex-1 flex-wrap justify-start px-1',
            )}
          >
            <div className="flex flex-1 flex-wrap gap-1">
              {selectionBadges}
              {/* Avoid having the "Search" Icon */}
              <CommandPrimitive.Input
                ref={inputRef}
                value={value}
                onValueChange={handleValueChange}
                onFocus={() => setOpen(true)}
                onPaste={onInputPaste}
                placeholder={placeholder}
                disabled={disabled}
                className={cn(
                  'placeholder:text-muted-foreground/60 ml-1 h-7 flex-1 bg-transparent outline-none',
                  className,
                )}
              />
            </div>
          </div>
        </PopoverAnchor>

        {/* This needs to be here to appease cmdk */}
        {!open && <CommandList aria-hidden="true" className="hidden" />}

        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          onWheel={(e) => {
            e.stopPropagation();
          }}
          onInteractOutside={(e) => {
            // Ignore interaction with the CommandInput element
            if (e.target instanceof Element && commandRef.current?.contains(e.target)) {
              e.preventDefault();
            }
          }}
          avoidCollisions
          collisionPadding={{ bottom: 48, top: 48 }}
        >
          <ScrollAreaPrimitive.Root className={cn('h-full w-full overflow-hidden')}>
            <ScrollAreaPrimitive.Viewport
              className="size-full max-h-[--radix-popper-available-height]"
              ref={handleRef}
            >
              <CommandList>{suggestionList}</CommandList>
            </ScrollAreaPrimitive.Viewport>
            <ScrollBar className="z-20" />
          </ScrollAreaPrimitive.Root>
        </PopoverContent>
      </Command>
    </Popover>
  );
};
