import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { apiClient, authProvider } from '@f4s/api-client';

import { JobRoles } from '../../lib/onboarding-constants';
import { fetchWorkspaces } from '../workspace/queries';
import {
  fetchPreferences,
  fetchSecondaryEmails,
  fetchThirdPartyLinks,
  fetchThirdPartyLinksForTokenQuery,
} from './queries';

export const profileLoader = async () => {
  const user = await authProvider.getUser();
  if (!user) return redirect('/');
  const jobRoleTypes = JobRoles;
  const secondaryEmails = await fetchSecondaryEmails();
  return { user, secondaryEmails, jobRoleTypes };
};
export type ProfileLoaderData = Exclude<
  Awaited<ReturnType<typeof profileLoader>>,
  Promise<Response> | Response
>;

export const preferencesLoader = async () => {
  const preferences = await fetchPreferences();
  return { preferences };
};
export type PreferencesLoaderData = Exclude<
  Awaited<ReturnType<typeof preferencesLoader>>,
  Promise<Response> | Response
>;

export const slackLinkLoader = async ({ params: { token } }: LoaderFunctionArgs) => {
  if (!token) throw new Error('No token provided');
  const [link] = await fetchThirdPartyLinksForTokenQuery(token);
  if (!link?.userId) {
    await apiClient.post(`/api/v3/slack/link/${token}`, {});
  }
  return { link };
};
export type SlackLinkLoaderData = Exclude<
  Awaited<ReturnType<typeof slackLinkLoader>>,
  Promise<Response> | Response
>;

export const slackWorkspaceLoader = async ({ params: { team } }: LoaderFunctionArgs) => {
  const allWorkspaces = await fetchWorkspaces();
  // Use only workspaces we are an admin of
  const workspaces = allWorkspaces.filter((w) => w.isAdmin);
  const links = await fetchThirdPartyLinks();
  const link = links.find(
    ({ service, thirdPartyId }) => thirdPartyId === team && service === 'slack',
  );
  return { team, workspaces, link };
};
export type SlackWorkspaceLoaderData = Exclude<
  Awaited<ReturnType<typeof slackWorkspaceLoader>>,
  Promise<Response> | Response
>;

export const verifyEmailLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const redirectTo = url.searchParams.get('redirectTo') ?? '/account';

  if (token) {
    const success = (await apiClient.post('/auth/v3/emails/verify', {
      token,
    })) as boolean;
    if (success) {
      await authProvider.refreshUser();
    } else {
      throw new Error('Error verifying email address');
    }
  }
  return redirect(redirectTo);
};

export const primaryEmailLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const emailAddress = url.searchParams.get('email');
  const redirectTo = url.searchParams.get('redirectTo') ?? '/account';

  if (token) {
    const success = (await apiClient.post('/auth/v3/emails/primary', {
      token,
      emailAddress,
    })) as boolean;
    if (success) {
      await authProvider.refreshUser();
    } else {
      throw new Error('Error changing primary email address');
    }
  }
  return redirect(redirectTo);
};
