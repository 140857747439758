import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient, type User } from '@f4s/api-client';

export type AdminUserSearchResult = {
  id: number;
  emailAddress: string;
  fullName: string;
};

export type UserDetailed = User & {
  emailAddresses: {
    id: number;
    emailAddress: string;
    isPrimary: boolean;
    isVerified: boolean;
  }[];
  talentPoolApplication: {
    id: number;
    uploadedCVName: string | null;
    uploadedCVURL: string | null;
    linkedInProfileURL: string | null;
  } | null;
  credits: {
    bonusCreditsRemaining: number;
    periodicCreditsUsed: number;
    lastPeriod: string;
    type: 'askmarlee';
  }[];
};

const adminUserSearchQuery = (search: string) => ({
  queryKey: ['admin', 'user', 'search', search],
  queryFn: async (): Promise<AdminUserSearchResult[]> => {
    if (Number.isInteger(Number(search))) {
      return apiClient.post('/api/v4/users', { id: search });
    }
    if (search.includes('@')) {
      return apiClient.post('/api/v4/users', { emailAddress: search });
    }

    return apiClient.post('/api/v4/users', { name: search });
  },
  enabled: search.length > 2 || Number.isInteger(Number(search)),
  placeholderData: (previousData: AdminUserSearchResult[] | undefined) => {
    if (search.length > 2 || Number.isInteger(Number(search))) return previousData ?? [];
    return [];
  },
});
export const fetchAdminUserSearch = (search: string) =>
  queryClient.fetchQuery(adminUserSearchQuery(search));
export const useAdminUserSearch = (search: string) =>
  useQuery(adminUserSearchQuery(search));

const adminUserDetailsQuery = (userId: number | string) => ({
  queryKey: ['admin', 'user', String(userId)],
  queryFn: async () => apiClient.get(`/api/v4/users/${userId}`) as Promise<UserDetailed>,
});
export const fetchAdminUserDetails = (userId: number | string) =>
  queryClient.fetchQuery(adminUserDetailsQuery(userId));
export const useAdminUserDetails = (userId: number | string) =>
  useQuery(adminUserDetailsQuery(userId));
