import type { RouteObject } from 'react-router-dom';

import { onboardingAnalysisRoutes } from '../analysis/routes';
import { onboardingTalentProfileUpdateAction } from './actions';
import {
  onboardingTalentCompleteLoader,
  onboardingTalentIndexLoader,
  onboardingTalentLoader,
} from './loaders';
import { OnboardingTalentProfilePage } from './pages/profile';

export const onboardingTalentRoutes: RouteObject = {
  path: 'talent',
  children: [
    { index: true, loader: onboardingTalentIndexLoader },
    {
      path: 'profile',
      loader: onboardingTalentLoader,
      Component: OnboardingTalentProfilePage,
      action: onboardingTalentProfileUpdateAction,
    },
    onboardingAnalysisRoutes,
    {
      path: 'complete',
      loader: onboardingTalentCompleteLoader,
    },
  ],
};
