import { useMaybeUser } from '@f4s/api-client';

import { NavLinkButton } from '@/components/nav-link';

export const UnmasqueradeButton = () => {
  const { user } = useMaybeUser();
  if (!user?.adminUserId) return null;
  return (
    <NavLinkButton
      to="/unmasquerade"
      variant="destructive"
      className="h-9 rounded-lg ring-1 transition-all hover:brightness-110"
    >
      Masquerading: Return to admin
    </NavLinkButton>
  );
};
