import { camelCase } from 'lodash-es';
import { type ActionFunctionArgs, type FetcherWithComponents } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { type JsonObject } from '@f4s/types/utils';

import { preferencesQuery } from './queries';

export type InviteRequest = { emailAddress: string } | { userId: number };

type UpdatePrimaryEmailRequest = {
  email: string;
};

export async function updateProfileAction({ request }: ActionFunctionArgs) {
  const propertyFields = ['jobArea', 'jobTitle'];
  const properties: Record<string, FormDataEntryValue> = {};

  // Gets the data that you submitted to the route this action lives on using react-router-dom <Form>
  const requestData = await request.formData();

  const jsonData = Object.fromEntries(
    [...requestData.entries()]
      .filter(([, val]) => val)
      .map(([key, val]) => [camelCase(key), val]),
  );

  propertyFields.forEach((prop) => {
    if (jsonData[prop] !== undefined) {
      properties[prop] = jsonData[prop] as FormDataEntryValue;
      delete jsonData[prop];
    }
  });
  if ('dateOfBirth' in jsonData) {
    // Reformat date of birth
    jsonData.dateOfBirth = new Date(jsonData.dateOfBirth as string).toISOString();
  }

  if ('password' in jsonData && jsonData.password !== jsonData.confirmPassword) {
    throw new Error('passwords do not match');
  }

  // After returning from an action, loaders for the current route are re-run
  const user = await authProvider.updateUser({
    ...jsonData,
    properties: properties as JsonObject,
  });

  if ('cultureCode' in jsonData) {
    // Invalidate motivation data caches
    await queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
    await queryClient.invalidateQueries({ queryKey: ['motivations'] });
    await queryClient.invalidateQueries({ queryKey: ['dashboard', 'data'] });
    await queryClient.invalidateQueries({ queryKey: ['modeling'] });
  }

  return user;
}

export async function uploadAvatarAction({ request }: ActionFunctionArgs) {
  // Upload the image
  const formData = await request.formData();
  const avatar = formData.get('avatar');
  if (!avatar) return { error: 'Please select a supported image' };

  const uploadFormData = new FormData();
  uploadFormData.append('file', avatar);
  try {
    await authProvider.updateUserAvatar(uploadFormData);
  } catch (error) {
    console.error(error);
    return { error: 'There was an issue uploading your image.' };
  }
  return null;
}

export async function updatePrimaryEmailAction({ request }: ActionFunctionArgs) {
  const data = (await request.json()) as UpdatePrimaryEmailRequest;
  //await queryClient.invalidateQueries({ queryKey: ['selection'] });

  return await apiClient.post('/api/v3/users', data);
}

export function updatePrimaryEmail(
  fetcher: FetcherWithComponents<unknown>,
  request: UpdatePrimaryEmailRequest,
) {
  fetcher.submit(request, {
    method: 'post',
    action: 'email/update-primary',
    encType: 'application/json',
  });
}

export async function setPreferences({ request }: ActionFunctionArgs) {
  const data = await request.json();
  const response = await apiClient.post('/api/v3/preferences/switch-preferences', {
    preferences: data,
  });
  await queryClient.invalidateQueries(preferencesQuery());
  return response;
}

export async function setSlackWorkspace({ request }: ActionFunctionArgs) {
  const data = await request.json();
  try {
    await apiClient.post(`/api/v3/links/organization/${data.organizationId}/slack`, {
      thirdPartyId: data.slackId,
    });
  } catch (error) {
    return { error };
  }
  return data;
}

export async function verifyEmailRequestAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ emailAddress: z.string() }).parse(data);
    await apiClient.post('/auth/v3/emails/verify/request', postData);
    return true;
  }
  return null;
}

export async function verifyEmailAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ token: z.string() }).parse(data);
    const res = (await apiClient.post('/auth/v3/emails/verify', postData)) as boolean;
    if (res) {
      // Refresh user
      await authProvider.refreshUser();
    }
    return res;
  }

  return null;
}

export async function verifyEmailCodeAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ code: z.string() }).parse(data);
    const res = (await apiClient.post(
      '/auth/v3/emails/verify/code',
      postData,
    )) as boolean;
    if (res) {
      // Refresh user
      await authProvider.refreshUser();
    }
    return res;
  }

  return null;
}

export async function addEmailAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ emailAddress: z.string() }).parse(data);
    const res = (await apiClient.post('/auth/v3/emails', postData)) as boolean;
    if (res) {
      // Refresh user
      await authProvider.refreshUser();
    }
    return res;
  }

  return null;
}

export async function primaryEmailRequestAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ emailAddress: z.string() }).parse(data);
    await apiClient.post('/auth/v3/emails/primary/request', postData);
    return true;
  }
  return null;
}

export async function primaryEmailAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z
      .object({ token: z.string(), emailAddress: z.string() })
      .parse(data);
    const res = (await apiClient.post('/auth/v3/emails/primary', postData)) as boolean;
    if (res) {
      // Refresh user
      await authProvider.refreshUser();
    }
    return res;
  }

  return null;
}

export async function primaryEmailCodeAction({ request }: ActionFunctionArgs) {
  const data = await request.json();

  if (request.method === 'POST') {
    const postData = z.object({ code: z.string(), emailAddress: z.string() }).parse(data);
    const res = (await apiClient.post(
      '/auth/v3/emails/primary/code',
      postData,
    )) as boolean;
    if (res) {
      // Refresh user
      await authProvider.refreshUser();
    }
    return res;
  }

  return null;
}
