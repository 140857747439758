import { type ReactNode } from 'react';

import { cn, ScrollArea, type ScrollAreaProps } from '@f4s/ui';

export const ScrollContainer = ({
  children,
  className,
  justify,
}: {
  children?: ReactNode;
  className?: string;
  justify?: ScrollAreaProps['justify'];
}) => {
  return (
    <ScrollArea
      className={cn('h-full w-full min-w-full max-w-full', className)}
      justify={justify}
    >
      {children}
    </ScrollArea>
  );
};
