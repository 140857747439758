import { useDataFromLoader } from '@/lib/hooks/loader';

import { JtbdDetailPage } from '../components/jtbd-detail-page';
import type { signupLoader } from '../loaders';

export const SignupMotivatePage = () => {
  const { category, queries, selectedQueryIds } =
    useDataFromLoader<typeof signupLoader>();

  return (
    <JtbdDetailPage
      title="Great! You're here to boost energy and performance."
      subtitle="Now let's refine that, what would help you and your team stay motivated and productive? Select as many as you'd like."
      category={category}
      queries={queries}
      selectedQueryIds={selectedQueryIds}
    />
  );
};
