import type { VariantProps } from 'cva';
import { useCallback, useEffect, useState, type ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from '@f4s/api-client';
import { Icon, ReactMentionInput, type buttonVariants } from '@f4s/ui';

import { MonetizationBanner } from '@/components/monetization-banner';
import { useCredits } from '@/modules/credits/queries';
import { useReportCreateAction } from '@/modules/report/actions';
import { mentionRegex } from '@/modules/report/utils';
import { useTeamList } from '@/modules/team/queries';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { useMentionSuggestions } from '../hooks/use-mention-suggestions';

type AskMarleeInputProps = {
  onSubmit?: () => void;
  onNavigate?: () => void;
  suggestionClassName?: string;
  onShowSuggestions?: ComponentProps<typeof ReactMentionInput>['onShowSuggestions'];
  submitVariant?: VariantProps<typeof buttonVariants>['variant'];
} & (
  | {
      prompt?: undefined;
      onPromptChange?: undefined;
    }
  | { prompt: string; onPromptChange: (prompt: string) => void }
);

type SupportedLocale = 'en'; // Add other locales as needed

const selfIndicators: Record<
  SupportedLocale,
  {
    pronouns: RegExp[];
    patterns: RegExp[];
  }
> = {
  en: {
    pronouns: [/\b(i|me|my|mine|myself)\b/i, /i['']m\b/i],
    patterns: [
      /\b(am|is|are|was|were|do|does|did|will|would|can|could|should|shall|might|must|have|has|had)\s+i\b/i,
      /\b(about|for|to|with|by)\s+(me|myself)\b/i,
    ],
  },
  // ... other languages ...
};

function isQuestionAboutSelf(question: string, locale: SupportedLocale = 'en') {
  if (!selfIndicators[locale]) {
    console.warn(`Locale '${locale}' not supported, falling back to English`);
    locale = 'en';
  }

  const { pronouns, patterns } = selfIndicators[locale];
  return (
    pronouns.some((regex) => regex.test(question)) ||
    patterns.some((regex) => regex.test(question))
  );
}

export const AskMarleeInput = ({
  prompt: incomingPrompt,
  onPromptChange,
  onSubmit,
  onShowSuggestions,
  onNavigate,
  suggestionClassName,
  submitVariant,
}: AskMarleeInputProps) => {
  const { workspace } = useMatchedWorkspace();
  const { user } = useUser();
  const { data: teams } = useTeamList({ workspaceId: workspace?.id });
  const [prompt, setPrompt] = useState('');
  const [showMonetizationLock, setShowMonetizationLock] = useState<boolean>(false);

  useEffect(() => {
    setPrompt(incomingPrompt ?? '');
  }, [incomingPrompt]);

  const onChange = useCallback(
    (value: string) => {
      setPrompt(value);
      onPromptChange?.(value);
      if (workspace?.entitlements['comparison-limit'] === -1) {
        setShowMonetizationLock(false);
        return;
      }
      // Only do the following check on unpaid spaces
      // const userIdSet = new Set<number>();
      const userIds: number[] = [];
      const isSelfQuestion = isQuestionAboutSelf(value);
      if (isSelfQuestion) {
        userIds.push(user.id);
      }

      const matches = [...value.matchAll(mentionRegex)];
      if (matches.length === 0) {
        setShowMonetizationLock(false);
        return;
      }

      for (const [_a, _b, type, id] of matches) {
        if (!id) continue;
        if (type === 'user') {
          userIds.push(Number(id));
        } else if (type === 'workspace' && workspace) {
          userIds.push(...workspace.members.map((m) => m.userId));
        } else if (type === 'selection') {
          const team = teams?.find((t) => t.id === Number(id));
          if (team) {
            userIds.push(...team.members.map((m) => m.userId));
          }
        }
      }

      const distinctUserIds = [...new Set(userIds)];
      const comparisonLimit = workspace?.entitlements['comparison-limit'] ?? 2;
      if (distinctUserIds.length <= comparisonLimit) {
        setShowMonetizationLock(false);
        return;
      }
      setShowMonetizationLock(true);
    },
    [onPromptChange, teams, user.id, workspace],
  );

  const suggestions = useMentionSuggestions();
  const createReport = useReportCreateAction();
  const { pathname } = useMatchedWorkspace();

  const handleAskMarleeSubmit = useCallback(() => {
    // Handling keyboard submit and failure of button disablement
    if (showMonetizationLock) {
      return;
    }

    onSubmit?.();
    createReport({ prompt });
  }, [createReport, onSubmit, prompt, showMonetizationLock]);

  const { data: credits } = useCredits('askmarlee');

  if ((credits?.totalCredits ?? 0) <= 0) {
    return (
      <div className="ring-ring/5 bg-background flex justify-center gap-2 rounded-xl bg-opacity-80 py-4 pl-3.5 pr-2.5 text-center ring-1">
        <Icon.Sparkles size={18} weight="duotone" />
        <div>
          You&apos;ve used all your credits.{' '}
          <Link
            to={`${pathname}/settings/billing`}
            className="underline"
            onClick={() => onNavigate?.()}
          >
            Upgrade to continue
          </Link>
          .
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      <ReactMentionInput
        data={suggestions}
        placeholder="Ask about yourself and your team"
        prompt={prompt}
        onChange={onChange}
        onAdd={() => {}}
        onSubmit={handleAskMarleeSubmit}
        submitVariant={submitVariant}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey && prompt) {
            e.preventDefault();
            handleAskMarleeSubmit();
          }
        }}
        WrapperProps={{
          containerClassName: 'w-full',
          motionClassName: '',
          motionForegroundClassName: 'mix-blend-screen',
          motionBackgroundClassName: 'mix-blend-luminance',
          className: 'w-full',
        }}
        IconComponent={
          <div className="text-muted-foreground">
            <Icon.Sparkles size={18} weight="duotone" />
          </div>
        }
        suggestionClassName={suggestionClassName}
        onShowSuggestions={onShowSuggestions}
        isButtonDisabled={showMonetizationLock}
      />
      {showMonetizationLock && (
        <MonetizationBanner type="banner" feature="queries">
          Upgrade to compare more than two people
        </MonetizationBanner>
      )}
    </div>
  );
};
