import { redirect, type ActionFunctionArgs } from 'react-router-dom';

import { apiClient, queryClient } from '@f4s/api-client';
import { TeamCreateSchema, TeamDeleteSchema, TeamUpdateSchema } from '@f4s/types';
import { toast } from '@f4s/ui';

import { suggestionsQuery } from '../ask-marlee/queries';
import { membersQuery } from '../member/queries';
import { teamMotivationsQuery } from '../motivation/queries';
import { getWorkspaceSlugFromParams } from '../workspace/queries';
import { mapTeam, teamListQuery, teamQuery, type TeamDetail } from './queries';

export async function teamCreateAction({ request, params }: ActionFunctionArgs) {
  const workspaceSlug = getWorkspaceSlugFromParams(params);
  const formData = await request.formData();
  const shouldRedirect = formData.get('shouldRedirect') === 'true';
  formData.delete('shouldRedirect');

  try {
    const data = formData.get('data');
    if (typeof data !== 'string') {
      throw new TypeError('Incorrect data type in formData');
    }
    const teamData = TeamCreateSchema.parse(JSON.parse(data));

    const newTeam = (await apiClient.post(
      `/api/v4/workspaces/${teamData.workspaceId}/teams/`,
      teamData,
    )) as TeamDetail;

    // Handle image uploading
    const file = formData.get('file');
    if (file instanceof File && file.name) {
      const imageFormData = new FormData();
      imageFormData.append('file', file);

      try {
        const avatarUrl = (await apiClient.post(
          `/api/v4/workspaces/${teamData.workspaceId}/teams/${newTeam.id}/avatar`,
          imageFormData,
          { isFormData: true },
        )) as string;
        newTeam.avatarUrl = avatarUrl;
      } catch (error) {
        console.error('Error uploading image for team logo', error);
        toast({ title: 'Could not upload image for team' });
      }
    }
    // Inject new team into the cache
    queryClient.setQueryData(
      teamQuery({ workspaceId: teamData.workspaceId, teamId: newTeam.id }).queryKey,
      mapTeam(newTeam),
    );

    // Invalidate list cache
    await queryClient.invalidateQueries(
      teamListQuery({ workspaceId: teamData.workspaceId }),
    );

    // Invalidate suggested queries too
    await queryClient.invalidateQueries(suggestionsQuery({ workspaceSlug }));

    return shouldRedirect ? redirect(`../${newTeam.id}`) : { error: null, team: newTeam };
  } catch (error) {
    console.error(error);
    return { error: 'Error creating a new team' };
  }
}

export type TeamCreateActionData = Exclude<
  Awaited<ReturnType<typeof teamCreateAction>>,
  Promise<Response> | Response
>;

export async function teamModifyAction({ request, ...args }: ActionFunctionArgs) {
  if (request.method === 'PATCH') {
    return teamUpdateAction({ request, ...args });
  } else if (request.method === 'DELETE') {
    return teamDeleteAction({ request, ...args });
  }
  return null;
}

export async function teamUpdateAction({ request, params }: ActionFunctionArgs) {
  const workspaceSlug = getWorkspaceSlugFromParams(params);
  const data = TeamUpdateSchema.parse(await request.json());

  const response = await apiClient.patch(
    `/api/v4/workspaces/${data.workspaceId}/teams/${data.teamId}`,
    data,
  );
  // Invalidate caches
  await queryClient.invalidateQueries(
    teamQuery({ workspaceId: data.workspaceId, teamId: data.teamId }),
  );
  await queryClient.invalidateQueries(teamListQuery({ workspaceId: data.workspaceId }));
  await queryClient.invalidateQueries(
    membersQuery({ workspaceSlug: workspaceSlug, teamId: String(data.teamId) }),
  );
  // Invalidate the aggregate data query
  await queryClient.invalidateQueries(
    teamMotivationsQuery({ teamId: data.teamId, workspaceId: data.workspaceId }),
  );
  return response;
}

export async function teamDeleteAction({ request }: ActionFunctionArgs) {
  const data = TeamDeleteSchema.parse(await request.json());

  await apiClient.delete(`/api/v4/workspaces/${data.workspaceId}/teams/${data.teamId}`);

  // Invalidate caches
  await queryClient.invalidateQueries(teamListQuery({ workspaceId: data.workspaceId }));
  return redirect('..');
}
