import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'cva';
import * as React from 'react';

import { cn } from '../lib/utils';

const buttonVariants = cva(
  'focus-visible:ring-ring lofiButtons:font-redacted lofiButtons:!text-muted-foreground inline-flex h-9 items-center justify-center gap-2 rounded-md text-sm transition-all duration-100 focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'from-elderberry-600 to-elderberry-500 text-primary-foreground ring-elderberry-700 lofiButtons:!text-primary-foreground relative overflow-hidden whitespace-nowrap bg-gradient-to-t px-5 shadow-md ring-1 transition-all hover:shadow-lg hover:brightness-125 hover:saturate-[120%]',
        secondary:
          'ring-border/5 bg-card text-card-foreground hover:bg-secondary whitespace-nowrap shadow-sm ring-1 hover:shadow-md',
        destructive:
          'ring-destructive/10 bg-destructive text-destructive-foreground shadow-sm ring-1 hover:brightness-110',
        outline: 'ring-border/5 hover:text-accent-foreground shadow-sm ring-1',
        ghost: 'hover:text-accent-foreground bg-transparent hover:bg-accent',
        link: 'text-foreground underline-offset-4 hover:underline',
        gradient:
          'text-foreground bg-[radial-gradient(ellipse_at_75%_-40%,#db2077,transparent_75%),radial-gradient(circle_at_0_-5%,#372d67,transparent_40%),linear-gradient(to_right,#1f7e81,#1f7e81_20%,#e95c26_90%)] font-normal shadow-[inset_0_0_0_1px_#ffffff30] hover:brightness-110',
        gradientWithYellow:
          'text-foreground bg-indigo-950 bg-[radial-gradient(circle_at_50%_130%,#FBBF24_-10%,transparent_40%),radial-gradient(ellipse_at_10%_200%,#0D9488_30%,transparent_60%),radial-gradient(ellipse_at_150%_100%,#E95B25_40%,transparent_80%),radial-gradient(ellipse_at_80%_0%,#DB2777_30%,transparent_65%)] font-normal shadow-[inset_0_0_0_.7px_#ffffff40] hover:brightness-110',
      },
      width: {
        default: '',
        auto: 'w-auto',
        full: 'w-full',
      },
      size: {
        'default': 'h-9 px-4 py-2 font-[450]',
        'sm': 'h-8 rounded-md px-3 text-xs font-[450]',
        'lg': 'h-10 px-8 py-2 font-[450]',
        'xl': 'h-14 rounded-xl px-8 py-2 text-lg font-[450]',
        'icon': 'h-9 w-9',
        'icon-sm': 'h-8 w-8',
        'icon-xs': 'h-4 w-4',
      },
    },
    defaultVariants: {
      width: 'default',
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, width, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, width, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
