import { NavLinkButton } from '@/components/nav-link';
import type { Workspace } from '@/modules/workspace/queries';

export const SpaceButton = ({ workspaces }: { workspaces: Workspace[] }) => {
  return workspaces.length > 0 ? (
    <NavLinkButton to="/spaces" variant="secondary" className="min-w-9 truncate">
      <span className="truncate">Go to spaces</span>
    </NavLinkButton>
  ) : (
    <NavLinkButton to="/spaces/create" className="min-w-9 truncate">
      <span className="truncate">Create a space</span>
    </NavLinkButton>
  );
};
