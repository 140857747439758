import { useEffect } from 'react';

import { apiClient } from '@f4s/api-client';

import { loadJsFilePromise } from '@/lib/third-party-js-loader';

export type Config = {
  botId: string;
  botpressUserId: string | null;
  disableNotificationSounds: boolean;
  enableReset: boolean;
  enableTranscriptDownload: boolean;
  extraStylesheet: string | null;
  f4sUserUuid: string | null;
  f4sUserSessionData: { [key: string]: unknown };
  host: string;
  showConversationsButton: boolean;
  showPoweredBy: boolean;
  userId: string;
  webChatUrl: string;
  strings?: { [key: string]: string };
  useSessionStorage?: boolean;
};

const botData = {
  url: '/chat',
  tooltip: 'Talk to Coach Marlee!',
  extraStylesheet: `${window.location.origin}/app/coach-marlee.css?v=${window.RELEASE_VERSION}`,
  onMessage: (
    event: { data?: { [key: string]: unknown } },
    opts: { config: Config; sessionInited?: boolean; isLinked?: boolean },
  ) => {
    const hasBPUserId = event.data?.userId;
    const notLinkedUser = !opts.config.botpressUserId;
    const isBPReady = event.data?.name === 'webchatReady';

    if (isBPReady && opts.config !== null && typeof opts.config === 'object') {
      sendBPEvent({
        text: 'session_init',
        args: {
          uuid: opts.config.f4sUserUuid,
          sessionData: opts.config.f4sUserSessionData,
        },
      });
      // modify passed object is not good practise
      // but bp not includes name as "webchatReady" event
      opts.sessionInited = true;
    }

    if (opts.sessionInited && !opts.isLinked && hasBPUserId && notLinkedUser) {
      if (event.data && 'userId' in event.data) {
        linkUser({ userId: event.data.userId as string });
      }
      opts.isLinked = true;
    }
  },
};

function sendBPEvent({
  type = 'proactive-trigger',
  channel = 'web',
  text = 'EVENT',
  args,
}: {
  type?: string;
  channel?: string;
  text: string;
  args: object;
}) {
  window.botpressWebChat?.sendEvent({
    type,
    channel,
    payload: { text, type, ...args },
  });
}

function isBotpressSDKLoaded() {
  return Boolean(window.botpressWebChat);
}

function isMarleeBotInitd() {
  return typeof window.botpressWebChat?.sendEvent === 'function';
}

async function loadBotpressSDK(webChatUrl: string) {
  if (window.botpressWebChat) return;

  await loadJsFilePromise(webChatUrl);
}

function hide() {
  if (!isMarleeBotInitd()) return;
  window.botpressWebChat?.sendEvent({ type: 'hide' });
}

export const MarleeBot = (opts: { config: Config; webChatUrl: string }) => {
  let loading = false;

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const show = () => {
    window.botpressWebChat?.sendEvent({ type: 'show' });
  };

  const init = async () => {
    if (loading) return;
    loading = true;
    if (!isBotpressSDKLoaded()) await loadBotpressSDK(opts.webChatUrl);

    window.botpressWebChat?.init(
      {
        ...opts.config,
        extraStylesheet: botData.extraStylesheet,
        strings: { tooltip: botData.tooltip },
        useSessionStorage: false,
      },
      '#bp-target',
    );

    await waitUntilMarleeChatLoaded();
    (document.querySelector('#bp-web-widget') as HTMLElement).style['height'] = '100%';
    (document.querySelector('#bp-widget') as HTMLElement).style['position'] = 'relative';

    loading = false;
  };

  const open = async () => {
    await init();
    show();

    window.botpressWebChat?.mergeConfig({
      containerWidth: '100%',
      layoutWidth: '100%',
    });

    window.addEventListener('message', async (event) => {
      if (botData.onMessage) botData.onMessage(event, opts);
    });
  };

  const close = async () => {
    hide();
  };

  return {
    close,
    show,
    hide,
    open,
    init,
    updateProfile,
    updateStatus,
  };
};

let hasLoaded = false;
export async function waitUntilMarleeChatLoaded() {
  if (hasLoaded) return true;
  return new Promise((resolve) => {
    const onLoaded = (event?: { data?: { [key: string]: unknown } }) => {
      if (event?.data?.name === 'webchatLoaded') {
        window.removeEventListener('message', onLoaded);
        hasLoaded = true;
        resolve(true);
      }
    };
    window.addEventListener('message', onLoaded);
  });
}

export const useBadHackToGetAroundSafariKeyboard = () =>
  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);
    if (!isIOS || !window.visualViewport) return () => {};
    const initialHeight = window.innerHeight;
    let state = 'closed';
    const resizeHandler = () => {
      if (state === 'open') {
        if (initialHeight === window.innerHeight) state = 'closed';
        return;
      }
      if (initialHeight === window.innerHeight) return;
      state = 'open';
      // initialHeight = window.innerHeight;
      // Scroll all the way to the bottom thanks
      // Note: I tried so many ways to correctly measure how far to scroll and
      // all didn't take into account the hovering address bar in iOS 15, apparently
      // this changed very recently and is about to change soon again, so I give up
      window.scrollTo(0, window.innerHeight);
    };
    window.visualViewport.addEventListener('resize', resizeHandler);
    return () => window.visualViewport?.removeEventListener('resize', resizeHandler);
  }, []);

function updateStatus(type: string, args: unknown) {
  window.botpressWebChat?.sendEvent({
    type: 'proactive-trigger',
    channel: 'web',
    payload: {
      text: 'EVENT',
      type,
      args,
    },
  });
}

function updateProfile() {
  if (!isMarleeBotInitd()) return;
  updateStatus('PROFILE-UPDATED', {});
}

let linkedOnce = false;
async function linkUser(data: { userId: string }) {
  try {
    const bpUserId = data.userId;
    if (linkedOnce || !bpUserId) return;

    await apiClient.post('/api/v1/coach/link-user', { bpUserId });
    linkedOnce = true;
  } catch {
    throw new Error('[chatbot] linkUser: Could not link user');
  }
}
