import type { RouteObject } from 'react-router-dom';

import {
  onboardingAnalysisCompleteAction,
  onboardingAnalysisUpdateAction,
} from './actions';
import {
  onboardingAnalysisBlockCompleteLoader,
  onboardingAnalysisBlockIndexLoader,
  onboardingAnalysisBlockQuestionIndexLoader,
  onboardingAnalysisBlockQuestionLoader,
  onboardingAnalysisCompleteLoader,
  onboardingAnalysisIndexLoader,
  onboardingAssessmentProgressIndexLoader,
  onboardingAssessmentProgressLoader,
} from './loaders';
import { OnboardingAnalysisCompletePage } from './pages/complete';
import { OnboardingAnalysisIntroPage } from './pages/intro';
import { OnboardingAnalysisProgressPage } from './pages/progress';
import { OnboardingAnalysisQuestionPage } from './pages/question';

export const onboardingAnalysisRoutes: RouteObject = {
  path: 'analysis',
  children: [
    {
      index: true,
      loader: onboardingAnalysisIndexLoader,
      Component: OnboardingAnalysisIntroPage,
    },
    {
      path: ':blockSlug',
      children: [
        { index: true, loader: onboardingAnalysisBlockIndexLoader },
        {
          path: 'question',
          children: [
            { index: true, loader: onboardingAnalysisBlockQuestionIndexLoader },
            {
              path: ':questionNo',
              loader: onboardingAnalysisBlockQuestionLoader,
              action: onboardingAnalysisUpdateAction,
              Component: OnboardingAnalysisQuestionPage,
            },
          ],
        },
        {
          path: 'progress',
          children: [
            { index: true, loader: onboardingAssessmentProgressIndexLoader },
            {
              path: ':progressNo',
              loader: onboardingAssessmentProgressLoader,
              Component: OnboardingAnalysisProgressPage,
            },
          ],
        },
        {
          path: 'complete',
          loader: onboardingAnalysisBlockCompleteLoader,
          action: onboardingAnalysisCompleteAction,
          Component: OnboardingAnalysisCompletePage,
        },
      ],
    },
    {
      path: 'complete',
      loader: onboardingAnalysisCompleteLoader,
    },
  ],
};
