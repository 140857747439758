import { useCallback, useEffect, useState, type ReactNode } from 'react';

import {
  cn,
  Dialog,
  DialogHeader,
  DialogTrigger,
  Divider,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTrigger,
  useMediaQuery,
} from '@f4s/ui';

import { ModalDialogContent } from '@/modules/root/components/modal-outlet';

import { ScrollContainer } from './scroll-container';

export const DialogDrawer = ({
  children,
  headerContent,
  bodyContent,
  asChild,
  className,
  triggerClassName,
  dialogClassName,
  drawerClassName,
  open: _open,
  onOpenChange,
}: {
  children?: ReactNode;
  headerContent?: ReactNode;
  bodyContent?: ReactNode;
  asChild?: boolean;
  className?: string;
  triggerClassName?: string;
  dialogClassName?: string;
  drawerClassName?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  const [open, setOpen] = useState<boolean>(_open ?? false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (_open !== undefined) {
      setOpen(_open);
    }
  }, [_open]);

  const handleOpen = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange],
  );

  if (isMobile) {
    // Use a drawer
    return (
      <Drawer open={open} onOpenChange={handleOpen} noBodyStyles handleOnly>
        <DrawerTrigger asChild={asChild} className={triggerClassName}>
          {children}
        </DrawerTrigger>
        <DrawerContent className="mt-24 max-h-[calc(100%_-_6rem)] flex-col overflow-hidden">
          <ScrollContainer>
            <div
              className={cn(
                'mx-auto flex w-full flex-col gap-0 px-3 pb-3 pt-6',
                className,
                drawerClassName,
              )}
            >
              <DrawerHeader>{headerContent}</DrawerHeader>
              <Divider className="mx-2 my-2 w-auto" />
              <div className={cn('px-6 pb-6', className)}>{bodyContent}</div>
            </div>
          </ScrollContainer>
        </DrawerContent>
      </Drawer>
    );
  }

  // Use a dialog
  return (
    <Dialog open={open} onOpenChange={handleOpen}>
      <DialogTrigger asChild={asChild} className={triggerClassName}>
        {children}
      </DialogTrigger>
      <ModalDialogContent className={dialogClassName}>
        <div className="flex flex-col gap-6 p-6">
          <DialogHeader>{headerContent}</DialogHeader>
          <div className={cn('', className)}>{bodyContent}</div>
        </div>
      </ModalDialogContent>
    </Dialog>
  );
};
