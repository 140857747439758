import { useMemo } from 'react';

import { useUser } from '@f4s/api-client';
import { Card } from '@f4s/ui';

import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useDarkMode } from '@/providers/theme';

import { useWidgetData, type Dashboard, type DashboardWidgetMaybeId } from '../queries';
import { DefaultPreview } from './widget-common';
import { WidgetComponentMap } from './widget-map';

export const WidgetPreview = ({
  widget,
  userId,
  defaultUserIds,
  defaultWorkspaceIds,
  hideTitle,
  dashboard,
}: {
  widget: DashboardWidgetMaybeId;
  dashboard?: Dashboard;
  userId?: number;
  defaultUserIds?: number[];
  defaultWorkspaceIds?: number[];
  hideTitle?: boolean;
}) => {
  const { user } = useUser();
  // TODO: Dashboards and widgets need a refactor already. This is a fairly central component
  const matchedWorkspace = useMatchedWorkspace();
  const darkMode = useDarkMode();
  const { data: datasets = [], isLoading: isWidgetDataLoading } = useWidgetData(
    // User selection is not set for template dashboard widgets
    // Default to current user if not set
    {
      widget,
      defaultUserId: userId ?? user.id,
      useDefaultOverTime: (dashboard?.template?.slug ?? dashboard?.slug) === 'over-time',
      defaultUserIds,
      defaultWorkspaceIds,
      workspaceId: matchedWorkspace.workspace?.id,
    },
  );

  const preview = useMemo(() => {
    const isLoading = isWidgetDataLoading;
    const widgetWithData = { ...widget, datasets };
    const { Preview = DefaultPreview } =
      WidgetComponentMap[widget.widgetTemplate.type] ?? {};
    return (
      <Preview
        widget={widgetWithData}
        isLoading={isLoading}
        user={user}
        darkMode={darkMode}
        hideTitle={hideTitle}
      />
    );
  }, [darkMode, datasets, hideTitle, isWidgetDataLoading, user, widget]);

  return (
    <Card className="bg-card flex aspect-[4/3] w-full flex-col rounded-sm shadow-sm h-full overflow-hidden">
      {preview}
    </Card>
  );
};
