import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import type { MinimalUser } from '../user/schema';
import type { AccessTypes } from '../workspace-access/queries';

type Requests = {
  id: number;
  createdAt: string;
  accessType: AccessTypes | 'member';
  userId: number;
  user: MinimalUser;
};

export const requestsListQuery = (workspaceId: number) => ({
  queryKey: ['workspace', workspaceId, 'requests', 'list'],
  queryFn: async () => {
    const requestsList = (await apiClient.get(
      `/api/v4/workspaces/${workspaceId}/requests`,
    )) as Requests[];
    // Wrap fullName and initials in
    return requestsList.map((a) => ({
      ...a,
      user: { ...a.user, fullName: formatName(a.user), initials: formatInitials(a.user) },
    }));
  },
});
export const fetchRequestsList = (workspaceId: number) =>
  queryClient.fetchQuery(requestsListQuery(workspaceId));
export const useRequestsList = (workspaceId: number) =>
  useQuery(requestsListQuery(workspaceId));

type OutgoingRequests = {
  organization: {
    id: number;
    name: string;
    companyName: string | null;
    avatarUrl: string | null;
  };
  id: number;
  createdAt: string;
  accessType: AccessTypes | 'member';
};
export const outgoingRequestsQuery = {
  queryKey: ['requests', 'outgoing'],
  queryFn: async () => {
    return (await apiClient.get(`/api/v4/workspaces/requests`)) as OutgoingRequests[];
  },
};
export const fetchOutgoingRequests = () => queryClient.fetchQuery(outgoingRequestsQuery);
export const useOutgoingRequests = () => useQuery(outgoingRequestsQuery);
