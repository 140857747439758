declare global {
  interface Window {
    RELEASE_VERSION: string;
    F4S_ENV: string;
    // DD_APPLICATION_ID: string;
    // DD_CLIENT_TOKEN: string;
    // DD_ENV: string;
    INTERCOM_APP_ID: string;
    CHARGEBEE_KEY: string;
    CHARGEBEE_SITE: string;
    GTM_ID: string;
    GTM_ENV_CODE: string;
    GOOGLE_API_KEY: string;
    AMPLITUDE_API_KEY: string;
  }
}
const isProduction = import.meta.env.F4S_ENV === 'production';
export const LoadClientEnv = () => {
  window.RELEASE_VERSION = import.meta.env.VITE_RELEASE_VERSION ?? 'development';
  window.F4S_ENV = import.meta.env.F4S_ENV ?? 'local';
  window.INTERCOM_APP_ID = 'zfsh2kd2';
  window.CHARGEBEE_KEY = isProduction
    ? 'live_Szin2GT6OfZk5MOBerbCQE4oMSGsNR8z'
    : 'test_IBbIrb02JMa0HbC7zrjqz8Scd9Xwcefit';
  window.CHARGEBEE_SITE = isProduction
    ? 'fingerprintforsuccess'
    : 'fingerprintforsuccess-test';
  window.GTM_ID = 'GTM-TF7XN9P';
  window.GTM_ENV_CODE =
    window.F4S_ENV !== 'production'
      ? '&gtm_auth=CMKdCLHDCp6xoZMkvZOElw&gtm_preview=env-109&gtm_cookies_win=x'
      : '';
  window.GOOGLE_API_KEY = isProduction
    ? 'AIzaSyBb0gl4kB4RX8WpcsaxEcXyjTipx62gI80'
    : 'AIzaSyABAfSIUMFoYjesAZWT5PyfNp8Epl1sUDU';
  window.AMPLITUDE_API_KEY = isProduction
    ? '44ebdafb127a8a9539dbb78d8d540b17'
    : 'fab16b1fe5e38d7f0e45006946eb6096';

  // Commented because we may soon use these for @datadog/browser-logs
  // window.DD_APPLICATION_ID = 'efc32150-7dd1-408b-a2e2-8d3f07d96001';
  // window.DD_CLIENT_TOKEN = 'pubc9f3746b568feae4c50c0ebc9d14e695';
  // window.DD_ENV = window.F4S_ENV;
};
