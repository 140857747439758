import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import {
  fetchTalentProfiles,
  setShouldEdit,
  talentProfileQuery,
  talentProfilesQuery,
  type TalentProfileDetails,
} from '@/modules/talent/profile/queries';
import { getWorkspaceIdFromSlug } from '@/modules/workspace/queries';

import {
  fetchPublicTalentPool,
  fetchPublicTalentPools,
  fetchWorkspaceTalentPool,
  fetchWorkspaceTalentPools,
  getPoolIdFromSlug,
  publicPoolQuery,
  publicPoolsQuery,
  type TalentPoolDetails,
} from './queries';

export const talentPoolsLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = z
    .object({ workspaceSlug: z.string().optional() })
    .parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  const talentPools = workspaceId
    ? await fetchWorkspaceTalentPools({ workspaceId })
    : await fetchPublicTalentPools();
  return { talentPools, workspaceId };
};

export const talentPoolLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceId, poolId } = await getPoolIdFromSlug(params);
  const talentPool = workspaceId
    ? await fetchWorkspaceTalentPool({ poolId: Number(poolId), workspaceId })
    : await fetchPublicTalentPool({ poolId: Number(poolId) });

  const profiles = await fetchTalentProfiles();

  return { talentPool, workspaceId, profiles };
};

export const talentPoolNewLoader = async ({ params }: LoaderFunctionArgs) => {
  // Only workspaces can create new pools
  const { workspaceSlug } = z
    .object({ workspaceSlug: z.string().optional() })
    .parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) return redirect('..');

  return { talentPool: undefined, profiles: undefined, workspaceId, isEditing: true };
};

export const talentPoolNewProfileLoader = async ({ params }: LoaderFunctionArgs) => {
  const { poolId } = await getPoolIdFromSlug(params);
  const talentProfile = await createProfileAndJoin({ poolId });

  setShouldEdit(true);
  // Redirect to newly created profile
  return redirect(`/talent/profiles/${talentProfile.id}`);
};

export const createProfileAndJoin = async ({ poolId }: { poolId: number | string }) => {
  // Create a default profile
  const talentProfile = (await apiClient.post(`/api/v4/talent/profiles`, {
    name: 'My Talent Profile',
  })) as TalentProfileDetails;

  const pool = (await apiClient.post(`/api/v4/talent/pools/${poolId}/join`, {
    profileId: talentProfile.id,
  })) as TalentPoolDetails;

  // Update individual pool cache
  queryClient.setQueryData(publicPoolQuery({ poolId: pool.id }).queryKey, pool);
  // Invalidate pool list cache
  await queryClient.invalidateQueries(publicPoolsQuery);

  // Update individual profile cache
  queryClient.setQueryData(
    talentProfileQuery({ profileId: talentProfile.id }).queryKey,
    talentProfile,
  );
  // Invalidate profile list cache
  await queryClient.invalidateQueries(talentProfilesQuery);

  return talentProfile;
};
