import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { GetUserOnboardingCompletionRes } from '@f4s/types/api/module-user';

import type { Workspace } from '../workspace/queries';

export const DUMMY_WORKSPACE: Workspace = {
  id: 0,
  slug: 'dummy',
  isDummy: true,
  name: `My dummy space`,
  avatarUrl: null,
  companyName: null,
  isDiscoverable: false,
  creatorId: 0,
  creator: {
    id: 0,
    emailAddress: 'dummy@example.com',
    firstName: 'Dummy',
    lastName: 'User',
    fullName: 'Dummy User',
    initials: 'DU',
    gender: null,
    avatarUrl: null,
  },
  createdAt: '',
  domains: [],
  isFavorite: false,
  isAdmin: true,
  isMember: true,
  isPending: false,
  permissions: {
    allMembersCanInvite: true,
    memberViewEachOther: true,
    autoApproveJoinRequest: true,
  },
  members: [],
  memberCount: 0,
  access: [],
  entitlements: {
    'teams': true,
    'paid': false,
    'comparison-limit': 2,
  },
};

export const DUMMY_PROGRESS: GetUserOnboardingCompletionRes = {
  assessment: 0,
  asked: 0,
  askedOther: 0,
  invitedOther: 0,
  dashboardMyResults: 0,
  dashboardSoftSkills: 0,
  dashboardTeamCulture: 0,
  percentComplete: 0,
};

// TODO: Reconsider
export const getOnboardingProgressQuery = (workspaceId?: number | null) =>
  queryOptions({
    queryKey: ['onboarding-progress', workspaceId],
    queryFn: () =>
      (workspaceId
        ? apiClient.get(`/api/v4/workspaces/${workspaceId}/setup-progress`)
        : apiClient.get('/api/v3/users/onboarding')
      ).then(GetUserOnboardingCompletionRes.parse),
  });

export const useOnboardingProgress = (workspaceId?: number) =>
  useQuery(getOnboardingProgressQuery(workspaceId));

export const fetchOnboardingProgress = (workspaceId?: number) =>
  queryClient.fetchQuery(getOnboardingProgressQuery(workspaceId));
