import type { ReactNode } from 'react';

import { cn, ScrollArea, Separator, TooltipProvider } from '@f4s/ui';

import { LocaleSelector } from '@/components/locale-selector';
import { useScrollRestoration } from '@/lib/hooks/use-scroll-restoration';
import { NavHeader } from '@/modules/root/components/nav';
import { UserNavOnboarding } from '@/modules/user/components/user-menu-onboarding';

const Header = ({ title }: { title?: string }) => {
  return (
    <header className="bg-background/80 ring-border/5 sticky top-0 z-50 flex h-16 w-full flex-none ring-1 backdrop-blur-md lg:z-50">
      <div className="m-auto flex h-full max-h-full min-h-full w-full max-w-screen-2xl flex-1 items-center justify-between px-6 xl:px-10">
        <div className="flex items-center gap-2 md:gap-4">
          <NavHeader
            className="w-auto text-sm"
            hideCollapseButton
            isCollapsed={true}
            clickOut={false}
          />
          {title && (
            <>
              <Separator
                orientation="vertical"
                className="bg-border/20 ml-1 hidden h-6 md:block"
              />
              {typeof title === 'string' ? (
                <h1 className="line-clamp-1 font-serif text-lg font-semibold tracking-tight">
                  {title}
                </h1>
              ) : (
                title
              )}
            </>
          )}
        </div>
        <div className="flex flex-shrink-0 items-center gap-2">
          <LocaleSelector align="end" iconOnly />
          <UserNavOnboarding />
        </div>
      </div>
    </header>
  );
};

export const MinimalLayout = ({
  children,
  hideHeader,
  title,
  className,
}: {
  children?: ReactNode;
  hideHeader?: boolean;
  title?: string;
  className?: string;
}) => {
  const scrollRef = useScrollRestoration();
  return (
    <div className="flex h-full max-h-full w-full flex-col items-center">
      <TooltipProvider delayDuration={300}>
        <ScrollArea
          className="h-full w-full flex-1"
          scrollBarClassName="z-[25]"
          ref={scrollRef}
        >
          {!hideHeader && <Header title={title} />}
          <div
            className={cn('w-full flex-1 sm:py-8', hideHeader && 'sm:py-24', className)}
          >
            <div className="m-auto flex max-h-full justify-center gap-4">{children}</div>
          </div>
        </ScrollArea>
      </TooltipProvider>
    </div>
  );
};
