import { type RouteObject } from 'react-router-dom';

import { assessmentUpdateAction } from './actions';
import {
  assessmentCompleteIndexLoader,
  assessmentCompleteLoader,
  assessmentCompleteQuestionLoader,
  reassessmentLoader,
  startAssessmentLoader,
} from './loaders';

export const assessmentCompleteRoute: RouteObject = {
  path: 'assessment',
  children: [
    {
      index: true,
      loader: reassessmentLoader,
      async lazy() {
        const { Reassessment } = await import('./pages/reassessment');
        return { Component: Reassessment };
      },
    },
    {
      path: 'start',
      loader: startAssessmentLoader,
    },
    {
      path: 'completed',
      loader: assessmentCompleteLoader,
      async lazy() {
        const { Completed } = await import('./pages/completed');
        return { Component: Completed };
      },
    },
    {
      path: ':blockSlug',
      children: [
        { index: true, loader: assessmentCompleteIndexLoader },
        {
          path: 'question/:questionNo',
          loader: assessmentCompleteQuestionLoader,
          action: assessmentUpdateAction,
          async lazy() {
            const { Assessment } = await import('./pages/assessment');
            return { Component: Assessment };
          },
        },
      ],
    },
  ],
};
