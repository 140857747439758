'use client';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { clsx } from 'clsx';

import * as Icon from '../../icons';

type Props = { text: string };

export const AnswerItem = ({ text }: Props) => {
  const sortable = useSortable({ id: text });

  return (
    <div
      className={clsx(
        'text-foreground bg-card ring-border/5 flex !cursor-grab items-center justify-between rounded-md px-4 py-3 ring-1',
        sortable.isDragging && 'opacity-25',
      )}
      ref={sortable.setNodeRef}
      style={{
        transform: CSS.Transform.toString(sortable.transform),
        transition: sortable.transition,
      }}
      {...sortable.attributes}
      {...sortable.listeners}
    >
      <div className="text-sm font-normal first-letter:capitalize">{text}</div>
      <Icon.GripVertical className="text-muted-foreground size-4 flex-shrink-0" />
    </div>
  );
};
