import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient, refetchEvery } from '@f4s/api-client';
import { type Invitation, type MagicLinkType } from '@f4s/types';

export type PendingInvitation = {
  id: number;
  token: string;
  updatedAt: string;
  sentToEmailAddress: string | null;
  type:
    | 'connection'
    | 'group'
    | 'organization'
    | 'org_and_group'
    | 'request_org'
    | 'organization_admin'
    | 'organization_consultant'
    | 'organization_support';
  groups?: { id: number; name: string; imageUrl: string | null };
  organizations?: { id: number; name: string; logo: string | null };
  fromUser?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  };
  toUser?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  };
};

export type MagicLink = {
  id: number;
  createdAt: string;
  updatedAt: string;
  groupId?: number;
  isActive: boolean;
  organizationId?: number;
  token: string;
  type: MagicLinkType;
  userId: number;
  organizations?: {
    id: number;
    name: string;
    logo: string;
    permission: { autoApproveJoinRequest: boolean; memberViewEachOther: boolean };
  };
  user?: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
};
export type MagicLinkWithOrg = Omit<MagicLink, 'organizations'> & {
  organizations: {
    id: number;
    name: string;
    logo: string;
    permission: { autoApproveJoinRequest: boolean; memberViewEachOther: boolean };
  };
};

type MagicLinkArgs = {
  type: MagicLinkType;
  id?: number;
  enabled?: boolean;
};

type MagicLinkByTokenArgs = {
  token: string;
  enabled?: boolean;
};

export const magicLinkQuery = ({ type, id, enabled = false }: MagicLinkArgs) => ({
  enabled,
  queryKey: ['magicLinks', 'byType', type, id],
  queryFn: async () => {
    if (!id) return null;
    const link = await apiClient.get(`/api/v3/public/magic-links/${type}/${id}`);
    if (link === '') return null; // MagicLink API returns 200 empty body when link is not found, instead of 404 as one might expect
    return link as MagicLink;
  },
});

export const useMagicLink = (args: MagicLinkArgs) => useQuery(magicLinkQuery(args));
export const fetchMagicLink = (args: MagicLinkArgs) =>
  queryClient.fetchQuery(magicLinkQuery(args));

export const magicLinkByTokenQuery = (token: string, enabled: boolean = false) => ({
  enabled,
  queryKey: ['magicLinks', 'byToken', token],
  queryFn: async () => {
    const link = await apiClient.get(`/api/v3/public/magic-links/${token}`);
    if (link === '') return null; // MagicLink API returns 200 empty body when link is not found, instead of 404 as one might expect
    return link as MagicLink;
  },
});

export const useMagicLinkByToken = ({ token, enabled }: MagicLinkByTokenArgs) =>
  useQuery(magicLinkByTokenQuery(token, enabled));
export const fetchMagicLinkByToken = ({ token, enabled }: MagicLinkByTokenArgs) =>
  queryClient.fetchQuery(magicLinkByTokenQuery(token, enabled));

// Pending invites sent by the user
export const pendingSentInvitesQuery = {
  queryKey: ['invitations', 'sent', 'pending'],
  queryFn: async () => {
    const invitations = await apiClient.get(`/api/v3/invitations/sent`);
    return invitations as PendingInvitation[];
  },
};
export const fetchPendingSentInvites = () =>
  queryClient.fetchQuery(pendingSentInvitesQuery);
export const usePendingSentInvites = () => useQuery(pendingSentInvitesQuery);

// Pending invites sent by the user
export const allSentInvitesQuery = {
  queryKey: ['invitations', 'sent'],
  queryFn: async () => {
    const invitations = await apiClient.get(`/api/v3/invitations/sent`);
    return invitations as PendingInvitation[];
  },
};
export const fetchAllSentInvites = () => queryClient.fetchQuery(pendingSentInvitesQuery);
export const useAllSentInvites = () => useQuery(pendingSentInvitesQuery);

// Pending invites received by the user
export const pendingInvitesQuery = {
  queryKey: ['invitations', 'received', 'pending'],
  queryFn: async () => {
    const invitations = await apiClient.get(`/api/v3/invitations/`);
    return invitations as PendingInvitation[];
  },
  ...refetchEvery({ minutes: 1 }),
};

export const fetchPendingInvites = () => queryClient.fetchQuery(pendingInvitesQuery);
export const usePendingInvites = () => useQuery(pendingInvitesQuery);

export const invitationQuery = (token: string) => ({
  queryKey: ['invitation', token],
  queryFn: async () => {
    const invite = await apiClient.get(`/api/v3/public/invitations/${token}`);
    return invite as Invitation;
  },
});

export const fetchInvitation = (token: string) =>
  queryClient.fetchQuery(invitationQuery(token));
export const useInvitation = (token: string) => useQuery(invitationQuery(token));
