import { useQuery } from '@tanstack/react-query';

import { authProvider, queryClient } from '@f4s/api-client';

import { fetchPersonalConnections } from '@/modules/_legacy/connections/queries';
import { fetchWorkspace } from '@/modules/workspace/queries';

import { fetchTeam } from '../team/queries';
import { fetchDemoUsers } from '../user/queries';
import { Messages } from './member.messages';
import type { Member } from './schema';

export const membersQuery = ({
  workspaceSlug,
  teamId,
  memberId,
}: {
  workspaceSlug?: string;
  teamId?: string;
  memberId?: string;
}) => ({
  queryKey: ['connections', 'members', workspaceSlug, teamId, memberId],
  queryFn: async () => {
    const user = await authProvider.getMaybeUser();
    let members: Member[] = [];
    const workspace = await fetchWorkspace(workspaceSlug);

    if (workspace && teamId) {
      // NOTE: Teams are selections
      const team = await fetchTeam({
        workspaceId: workspace.id,
        teamId: teamId,
      });
      members = team?.members ?? [];
    } else if (workspace) {
      // Workspace members
      members = workspace.members ?? [];
    } else {
      const personalConnections = await fetchPersonalConnections();
      const demoUsers = await fetchDemoUsers();
      members = [...personalConnections, ...demoUsers].map((c) => ({
        id: c.id,
        userId: c.id,
        user: c,
      }));
    }

    if (memberId) {
      members = members.filter((m) => m.id === Number(memberId));
    }

    return members.map((m) => {
      const isMe = m.userId === user?.id;
      const fullName = isMe ? Messages.me() : m.user.fullName;

      return {
        ...m,
        user: {
          ...m.user,
          fullName,
        },
        isMe,
      };
    });
  },
});

export const fetchMembers = (
  data: {
    workspaceSlug?: string;
    teamId?: string;
    memberId?: string;
  } = {},
) => queryClient.fetchQuery(membersQuery(data));

export const useMembers = (
  data: {
    workspaceSlug?: string;
    teamId?: string;
    memberId?: string;
  } = {},
) => useQuery(membersQuery(data));
