import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  type FormEvent,
} from 'react';
import { useFetcher } from 'react-router-dom';
import { z } from 'zod';

import {
  Button,
  cn,
  Divider,
  Input,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  Label,
} from '@f4s/ui';

import type { LoginActionData, LoginError } from '../actions';
import { OauthIcons } from './oauth-buttons';

const emailSchema = z.string().email();

export const LoginForm = ({
  actionPath = '.',
  redirectTo = '/',
  defaultEmail,
  onSubmit,
}: {
  redirectTo?: string;
  actionPath?: string;
  defaultEmail?: string;
  onSubmit?: () => void;
}) => {
  const refPassword = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const handlePasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const refCode = useRef<HTMLInputElement>(null);
  const [showCode, setShowCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const handleCodeChange = useCallback((value: string) => {
    setCode(value);
  }, []);

  const [emailAddress, setEmailAddress] = useState<string>(defaultEmail ?? '');
  const [isValid, setIsValid] = useState<boolean>(
    defaultEmail ? emailSchema.safeParse(defaultEmail).success : false,
  );
  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newEmailAddress = e.target.value.trim();
    const { success } = emailSchema.safeParse(newEmailAddress);
    setEmailAddress(e.target.value.trim());
    setIsValid(success);

    // Changing the email resets the password and code states
    setPassword('');
    setShowPassword(false);
    setCode('');
    setShowCode(false);
  }, []);

  const [loginError, setLoginError] = useState<LoginError>(null);

  // Login api calls
  const loginFetcher = useFetcher<LoginActionData>();
  const handleLogin = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit?.();
      loginFetcher.submit(
        {
          emailAddress,
          ...(password ? { password } : {}),
          ...(code ? { code } : {}),
          ...(redirectTo ? { redirectTo } : {}),
        },
        {
          action: actionPath,
          method: 'POST',
          encType: 'application/json',
        },
      );
    },
    [actionPath, code, emailAddress, loginFetcher, onSubmit, password, redirectTo],
  );
  useEffect(() => {
    if (loginFetcher.data && loginFetcher.state === 'idle') {
      setPassword('');
      setCode('');
      setLoginError(loginFetcher.data.error);

      if (loginFetcher.data.askForCode) {
        setShowPassword(false);
        setShowCode(true);
        setTimeout(() => {
          refCode.current?.focus();
        }, 10);
      } else if (loginFetcher.data.askForPassword) {
        setShowPassword(true);
        setShowCode(false);
        setTimeout(() => {
          refPassword.current?.focus();
        }, 10);
      } else {
        setShowPassword(false);
        setShowCode(false);
      }
    }
  }, [loginFetcher.data, loginFetcher.state]);

  // Request a code
  const codeFetcher = useFetcher();
  const handleCodeRequest = useCallback(() => {
    codeFetcher.submit(
      { emailAddress },
      {
        action: actionPath.replace(/\/+$/, '') + '/code',
        method: 'POST',
        encType: 'application/json',
      },
    );
  }, [actionPath, codeFetcher, emailAddress]);
  useEffect(() => {
    if (codeFetcher.data && codeFetcher.state === 'idle') {
      // Transition to code input
      setPassword('');
      setCode('');
      setLoginError(null); // Clear out previous error
      setShowPassword(false);
      setShowCode(true);
      requestAnimationFrame(() => refCode.current?.focus());
    }
  }, [codeFetcher.data, codeFetcher.state]);

  const canRequestCode = isValid && (showPassword || (!showCode && loginError !== null));

  const submitDisabled =
    !isValid ||
    loginFetcher.state !== 'idle' ||
    codeFetcher.state !== 'idle' ||
    (showPassword && !password) ||
    (showCode && code.length !== 6) ||
    (!showPassword && !showCode && loginError !== null);

  const buttonMessage =
    loginFetcher.state !== 'idle'
      ? 'Loading...'
      : showCode
        ? 'Continue with code'
        : showPassword
          ? 'Continue with password'
          : 'Continue';

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-row gap-4">
        <OauthIcons size="lg" variant="secondary" className="flex-1" onClick={onSubmit} />
      </div>
      <Divider className="text-muted-foreground max-w-full text-sm" text="or" />

      <form onSubmit={handleLogin} className="flex flex-col">
        <Label htmlFor="emailAddress" className="sr-only">
          Email address
        </Label>
        <Input
          id="emailAddress"
          value={emailAddress}
          onChange={handleEmailChange}
          placeholder="name@example.com"
          type="email"
          autoComplete="email"
          size="lg"
          required
          data-testid="login-input-email"
        />
        <Label htmlFor="password" aria-hidden={!showPassword} className="sr-only">
          Password
        </Label>
        <Input
          ref={refPassword}
          id="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="••••••••••"
          type="password"
          autoComplete="current-password"
          required={showPassword}
          aria-hidden={!showPassword}
          disabled={!showPassword}
          className={cn('mt-4', !showPassword && 'hidden')}
          size="lg"
          data-testid="login-input-password"
        />
        <Label htmlFor="code" aria-hidden={!showCode} className="sr-only">
          One-Time-Code
        </Label>

        <div
          className={cn(
            'text-muted-foreground mt-4 text-center text-sm',
            !loginError && 'hidden',
          )}
        >
          {loginError?.message}
        </div>

        <div
          className={cn(
            'text-muted-foreground mt-4 text-center text-sm',
            !showCode && 'hidden',
          )}
        >
          We&apos;ve emailed you a one-time login code
        </div>

        <div className={cn('mt-4 font-mono', !showCode && 'hidden')}>
          <InputOTP
            ref={refCode}
            id="code"
            name="code"
            // type="text"
            pattern="^\d+$"
            maxLength={6}
            inputMode="numeric"
            autoComplete="one-time-code"
            value={code}
            onChange={handleCodeChange}
            // placeholder="000000"
            required={showCode}
            aria-hidden={!showCode}
            disabled={!showCode}
            data-testid="login-input-otp"
          >
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </div>

        <div className="mt-4 flex w-full gap-4">
          <Button
            type="button"
            disabled={codeFetcher.state !== 'idle' || !canRequestCode}
            variant="secondary"
            aria-hidden={!isValid || !showPassword}
            className={cn('flex-1', !canRequestCode && 'hidden')}
            onClick={handleCodeRequest}
            size="lg"
            data-testid="login-button-otp-request"
          >
            {codeFetcher.state !== 'idle'
              ? 'Sending code...'
              : loginError?.method === 'code'
                ? 'Request another code'
                : 'Request a code'}
          </Button>
          <Button
            type="submit"
            disabled={submitDisabled}
            className={cn(
              'flex-1',
              !showPassword && !showCode && loginError !== null && 'hidden',
            )}
            size="lg"
            data-testid="login-button-submit"
          >
            {buttonMessage}
          </Button>
        </div>
      </form>
    </div>
  );
};
