import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { underlyingDataChildRouter } from '../motivation/routes';
import { memberAction } from './actions';
import { memberLoader, membersLoader } from './loaders';
import { MemberBreadcrumb } from './pages/handle';

export const memberRoutes: RouteObject = {
  path: ':memberId',
  loader: memberLoader,
  action: memberAction,
  async lazy() {
    const { MemberLayout } = await import('./pages');
    return { Component: MemberLayout };
  },
  handle: handle({ type: 'member', breadcrumb: MemberBreadcrumb }),
  children: [
    {
      index: true,
      loader: memberLoader,
      async lazy() {
        const { MemberProfileLayout } = await import('./pages');
        return { Component: MemberProfileLayout };
      },
    },
    {
      loader: memberLoader,
      async lazy() {
        const { MemberProfileLayout } = await import('./pages');
        return { Component: MemberProfileLayout };
      },
      children: [{ path: 'motivation', children: underlyingDataChildRouter }],
    },
  ],
};

export const membersRoutes: RouteObject = {
  path: 'members',
  handle: handle({ type: 'members', breadcrumb: SimpleBreadcrumb({ title: 'Members' }) }),
  loader: membersLoader,
  children: [
    {
      index: true,
      loader: membersLoader,
      async lazy() {
        const { Members } = await import('./pages');
        return { Component: Members };
      },
    },
    memberRoutes,
  ],
};
