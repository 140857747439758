import { Badge, cn, Icon, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@f4s/ui';





export const DirectlyConnectedBadge = ({ className }: { className?: string }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={cn(
              'absolute -bottom-1 -right-1 aspect-square h-1/2 max-h-[50%] w-1/2 max-w-[50%] rounded-full border p-[4px]',
              'border-elderberry-700/40 to-elderberry-100 bg-gradient-to-b from-white bg-clip-padding',
              className,
            )}
          >
            <Icon.Link className="text-elderberry-700 h-full w-full" weight="bold" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-[200px] whitespace-normal text-sm">
            You have a direct connection to this person, can view their data without their
            accepting. Nobody else in this workspace can see their person&apos;s data
            until they have accepted their invite.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const AssessmentNeededBadge = ({
  name,
  className,
}: {
  name?: string | null;
  className?: string;
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={cn(
              'absolute -bottom-1 -right-1 aspect-square h-1/2 max-h-[50%] w-1/2 max-w-[50%] rounded-full border p-[4px]',
              'border-destructive/40 to-destructive/20 bg-gradient-to-b from-white bg-clip-padding',
              className,
            )}
          >
            <Icon.Warning className="text-destructive h-full w-full" weight="bold" />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-[200px] whitespace-normal text-sm">
            {name ?? 'This person'} still needs to complete their motivation analysis
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const InvitePendingBadge = ({
  name,
  className,
}: {
  name?: string | null;
  className?: string;
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge variant="outline" className={cn('gap-2 px-2', className)}>
            {/* <Icon.Info size={16} /> */}
            <span className="text-xs">Invite Pending</span>
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-[200px] whitespace-normal text-sm">
            {name ?? 'This person'} still needs to accept their invite.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};