import { type ActionFunctionArgs, type FetcherWithComponents } from 'react-router-dom';

import { apiClient, queryClient, type HTTPError } from '@f4s/api-client';
import { type SubscriptionCreationDocument } from '@f4s/types';

import {
  getWorkspaceSlugFromParams,
  workspaceQuery,
  workspacesQuery,
} from '../workspace/queries';
import { invoicesQuery, paymentMethodQuery, subscriptionQuery } from './queries';

type UpdatePaymentMethodRequest = {
  cardToken: {
    token: string;
    vaultToken: string;
  };
  organizationId?: number;
};

export async function updatePaymentMethodAction({ request }: ActionFunctionArgs) {
  const data = (await request.json()) as UpdatePaymentMethodRequest;
  const response = await apiClient.put(
    '/api/v2/payments/subscriptions/payment-card',
    data,
  );
  const { queryKey } = paymentMethodQuery({});
  await queryClient.invalidateQueries({ queryKey });
  return response;
}

export const updatePaymentMethod = (
  fetcher: FetcherWithComponents<unknown>,
  request: UpdatePaymentMethodRequest,
) =>
  fetcher.submit(request, {
    method: 'post',
    action: 'update-payment-method',
    encType: 'application/json',
  });

export async function createSubscriptionAction({ request, params }: ActionFunctionArgs) {
  const workspaceSlug = await getWorkspaceSlugFromParams(params);
  const data = (await request.json()) as SubscriptionCreationDocument;

  try {
    const response = await apiClient.post('/api/v2/payments/subscriptions', data);

    const type = data.organization ? 'org' : 'user';
    const id = data.organization ? data.organization.id : undefined;

    // Invalidate and prefetch to ensure latest data
    const subscriptionQueryOptions = subscriptionQuery({
      type: data.organization ? 'org' : undefined,
      id,
    });
    await queryClient.invalidateQueries(subscriptionQueryOptions);
    await queryClient.prefetchQuery(subscriptionQueryOptions);

    await queryClient.invalidateQueries(paymentMethodQuery({ type, id }));
    await queryClient.invalidateQueries(invoicesQuery({ type, id }));

    // Invalidate workspace for new entitlements
    await queryClient.invalidateQueries(workspacesQuery);
    await queryClient.invalidateQueries(workspaceQuery(workspaceSlug));

    // Invalidate all workspace credits after subscription upgrade
    await queryClient.invalidateQueries({ queryKey: ['credits', data.organization?.id] });

    return response;
  } catch (error) {
    // For some reason, this is returning empty string instead of the actual HTTP error
    return { error: (error as HTTPError).response.statusText };
  }
}
