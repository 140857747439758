import { ProfileAvatar } from '@/components/avatar';
import type { BreadcrumbProps } from '@/components/breadcrumbs';
import { NavLink } from '@/components/nav-link';

import type { teamLoader } from '../loaders';

export const TeamBreadcrumb = ({
  pathname,
  data,
}: BreadcrumbProps<typeof teamLoader>) => {
  return (
    <NavLink to={pathname} className="">
      <div className="flex items-center gap-2">
        <ProfileAvatar
          className="h-6 min-h-6 w-6 min-w-6 shrink-0 rounded-md"
          avatarUrl={data.team.avatarUrl}
          initials={data.team.name[0]}
        />
        <span className="truncate">{data.team.name}</span>
      </div>
    </NavLink>
  );
};
