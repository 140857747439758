import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Plus } from '../icons';
import { getCloudflareImageURL } from '../lib/image';
import { cn } from '../lib/utils';

type Props = {
  currentImage?: string;
  onFileSelected?: (file: File) => void;
  label?: string;
  labelledBy?: string;
  className?: string;
};

export const AvatarUploader = ({
  currentImage,
  onFileSelected,
  label,
  labelledBy,
  className,
}: Props) => {
  const [imageFile, setImageFile] = useState<{ preview: string }>();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (imageFile?.preview) window.URL.revokeObjectURL(imageFile.preview);

      if (acceptedFiles[0]) {
        setImageFile(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          }),
        );

        if (typeof onFileSelected === 'function') {
          onFileSelected(acceptedFiles[0]);
        }
      }
    },
    [imageFile?.preview, onFileSelected],
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: { 'image/*': ['.jpeg', '.png'] },
    onDrop,
  });

  const shownImageSrc = useMemo(
    () =>
      imageFile?.preview ||
      getCloudflareImageURL({ src: currentImage, height: 64, width: 64 }),
    [currentImage, imageFile?.preview],
  );
  return (
    <div
      className={cn(
        'dropzone-container focus:ring-primary focus-visible:ring-primary bg-card ring-foreground/5 hover:ring-foreground/20 relative flex aspect-square h-16 w-16 shrink-0 items-center justify-center overflow-hidden rounded-full ring-1 focus-visible:outline-none',
        className,
      )}
      {...getRootProps({
        'role': 'button',
        'aria-label': labelledBy
          ? undefined
          : (label ?? 'drag and drop avatar image upload'),
        'aria-labelledby': labelledBy,
      })}
    >
      {shownImageSrc ? (
        <img
          alt=""
          src={shownImageSrc}
          className="h-full w-full object-cover hover:opacity-50"
        />
      ) : (
        <Plus size={20} />
      )}
      <input {...getInputProps()} />
    </div>
  );
};
