import { useCallback, useEffect, useState } from 'react';

import { type ReportSection } from '@f4s/types';
import { cn } from '@f4s/ui';

export const Section = ({
  section,
  onDone,
  shouldStart,
  disableAnimation = false,
  hideTitle = false,
  type = 'section',
  className,
}: {
  shouldStart: boolean;
  section: Partial<ReportSection>;
  onDone?: () => void;
  disableAnimation?: boolean;
  hideTitle?: boolean;
  type?: 'section' | 'subsection';
  className?: string;
}) => {
  const [target, setTarget] = useState<HTMLDivElement>();
  const [contentDone, setContentDone] = useState<boolean>(false);
  const [childSectionsDone, setchildSectionsDone] = useState<boolean>(false);
  const [subsectionIndex, setSubsectionIndex] = useState<number>(0);
  const [hasStarted, setHasStarted] = useState<boolean>(false);

  const [_isDone, setIsDone] = useState<boolean>(false);

  // Hold on to start trigger
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    let index = 0;

    if (shouldStart && target) {
      setHasStarted((alreadyStarted) => {
        if (!alreadyStarted) {
          if ((section.childSections?.length ?? 0) === 0) {
            setchildSectionsDone(true);
          }

          if (!section.body || disableAnimation) {
            setContentDone(true);
          } else {
            // Kick off the animation
            interval = setInterval(() => {
              if (index === section.body!.length) {
                clearInterval(interval);
                setContentDone(true);
                return;
              }

              target.innerHTML += section.body![index];
              index++;
            }, 10);
          }
          return true;
        }
        return alreadyStarted;
      });
    }
    return () => clearInterval(interval);
  }, [
    section.body,
    section.title,
    section.childSections?.length,
    shouldStart,
    disableAnimation,
    target,
  ]);

  // Handle the done conditions
  useEffect(() => {
    if (contentDone && childSectionsDone) {
      setIsDone(true);
      onDone?.();
    }
  }, [contentDone, onDone, childSectionsDone]);

  const refCallback = useCallback((el: HTMLDivElement) => {
    setTarget(el);
  }, []);

  useEffect(() => {
    if (subsectionIndex === (section.childSections?.length ?? 0)) {
      setchildSectionsDone(true);
    }
  }, [section.childSections?.length, subsectionIndex]);

  const handleDone = useCallback(
    () =>
      setSubsectionIndex((previousIndex) => {
        if (section.childSections && previousIndex < section.childSections.length) {
          return previousIndex + 1;
        }
        return previousIndex;
      }),
    [section.childSections],
  );

  return (
    <section className={cn('flex flex-col gap-2', !hasStarted && 'hidden')}>
      {hasStarted && !hideTitle && (
        <h3 className={cn(type === 'section' ? 'font-semibold' : 'font-medium')}>
          {section.title}
        </h3>
      )}
      <div ref={refCallback} className={className}>
        {disableAnimation && section.body}
      </div>
      {section.childSections && (
        <div className="flex flex-col gap-4">
          {section.childSections.map((subsection, i) => (
            <Section
              key={i}
              shouldStart={hasStarted && contentDone && subsectionIndex === i}
              section={subsection}
              onDone={handleDone}
              type="subsection"
              disableAnimation={disableAnimation}
            />
          ))}
        </div>
      )}
    </section>
  );
};
