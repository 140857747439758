import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { fetchEntitlement } from '@/modules/entitlements/queries';
import { fetchWorkspace, fetchWorkspaces } from '@/modules/workspace/queries';

import {
  fetchAvailablePlans,
  fetchInvoices,
  fetchPaymentMethod,
  fetchSubscription,
} from './queries';

export const billingLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug?: string };

  const workspace = workspaceSlug ? await fetchWorkspace(workspaceSlug) : undefined;
  const workspaceId = workspace ? workspace.id : undefined;
  const credits = (await fetchEntitlement('askmarlee-credits-qty')) as number;

  const subscription = await fetchSubscription({
    type: workspace ? 'org' : undefined,
    id: workspace?.id,
  });

  const data = {
    workspaceId,
    workspaceSlug,
    workspace,
    credits,
    subscription,
    paymentMethod: null as Awaited<ReturnType<typeof fetchPaymentMethod>> | null,
    invoice: null as Awaited<ReturnType<typeof fetchInvoices>> | null,
  };

  if (subscription) {
    data.paymentMethod = await fetchPaymentMethod({
      type: workspaceId ? 'org' : 'user',
      id: workspaceId,
    });
    data.invoice = await fetchInvoices({
      type: workspaceId ? 'org' : 'user',
      id: workspaceId,
    });
  }

  return data;
};

export type BillingLoaderData = Exclude<
  Awaited<ReturnType<typeof billingLoader>>,
  Promise<Response> | Response
>;

export const individualPlanUpgradeLoader = async (_args: LoaderFunctionArgs) => {
  const availablePlans = await fetchAvailablePlans();

  const planId = 'individual';
  const plan = availablePlans.find((ap) => ap.id === planId);
  return { ...plan, planId, quantity: 1 } as const;
};

export type IndividualPlanUpgradeLoader = Exclude<
  Awaited<ReturnType<typeof individualPlanUpgradeLoader>>,
  Promise<Response> | Response
>;

export const teamPlanUpgradeLoader = async () => {
  const workspaces = await fetchWorkspaces();

  // User has no existing workspaces - redirect to create
  // TODO: Consider more guided upgrade path here
  if (workspaces.length === 0) {
    return redirect('/spaces/create');
  }

  // User has only one workspace - redirect to upgrade
  if (workspaces.length === 1) {
    return redirect(`/spaces/${workspaces[0]!.slug}/settings/billing/upgrade`);
  }

  // User has multiple workspaces - show workspace selection
  return { workspaces };
};

export type TeamPlanUpgradeLoaderData = Exclude<
  Awaited<ReturnType<typeof teamPlanUpgradeLoader>>,
  Promise<Response> | Response
>;

export const maybeWorkspaceLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug?: string };
  const workspace = workspaceSlug ? await fetchWorkspace(workspaceSlug) : undefined;
  return { workspace };
};
