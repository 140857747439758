import { queryOptions, useQuery } from '@tanstack/react-query';

import { authProvider, queryClient } from '@f4s/api-client';
import { languageCodeSchema, type LanguageCode } from '@f4s/types';

const localeQueryOptions = queryOptions({
  queryKey: ['i18n', 'locale'],
  queryFn: async () => {
    let lang = languageCodeSchema.parse(document.documentElement.lang.toLowerCase());
    const user = await authProvider.getMaybeUser();
    if (user && user.languageCode !== lang) {
      // Update the document to match the logged in user's preference
      document.documentElement.lang = lang;
      lang = user.languageCode;
    }
    return lang;
  },
  placeholderData: document.documentElement.lang.toLowerCase() as LanguageCode,
});

export const useLocale = () => useQuery(localeQueryOptions);
export const fetchLocale = () => queryClient.fetchQuery(localeQueryOptions);

export const setLocale = (code: LanguageCode) => {
  document.documentElement.lang = code;
  queryClient.setQueryData(localeQueryOptions.queryKey, () => code);
};
