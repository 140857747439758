import { redirect } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

export const loginLoader = async () => {
  // This is actually important, even if we know the user is not logged in, as the API request will cause the creation of a session
  // We need a session to tie the login with code email to.
  const user = await authProvider.getMaybeUser();
  if (user) {
    return redirect('/onboarding');
  }
  return { user };
};
