import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { createSubscriptionAction, updatePaymentMethodAction } from './actions';
import {
  billingLoader,
  individualPlanUpgradeLoader,
  maybeWorkspaceLoader,
  teamPlanUpgradeLoader,
} from './loaders';

export const billingRoutes: RouteObject = {
  path: 'billing',
  handle: handle({
    type: 'billing',
    breadcrumb: SimpleBreadcrumb({ title: 'Billing' }),
  }),
  children: [
    {
      handle: { crumb: { title: 'Billing' } },
      loader: billingLoader,
      async lazy() {
        const { BillingRoot } = await import('./pages/page');
        return { Component: BillingRoot };
      },
      index: true,
    },
    {
      path: 'update-payment-method',
      action: updatePaymentMethodAction,
    },
    {
      path: 'payment',
      children: [
        {
          path: 'individual',
          loader: individualPlanUpgradeLoader,
          async lazy() {
            const { IndividualPayment: IndividualPaymentPage } = await import(
              './pages/payment'
            );
            return { Component: IndividualPaymentPage };
          },
        },
        {
          path: 'team',
          loader: teamPlanUpgradeLoader,
          async lazy() {
            const { TeamPayment: TeamPaymentPage } = await import('./pages/payment');
            return { Component: TeamPaymentPage };
          },
        },
      ],
    },
    {
      path: 'plans',
      loader: maybeWorkspaceLoader,
      async lazy() {
        const { PlansPage } = await import('./pages/plans');
        return { Component: PlansPage };
      },
    },
    {
      path: 'subscription',
      action: createSubscriptionAction,
    },
  ],
};
