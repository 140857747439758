import { forwardRef, type ElementRef, type ReactNode } from 'react';

import { formatName } from '@f4s/types';
import { Avatar, cn, type AvatarProps } from '@f4s/ui';

import { DEFAULT_AVATAR } from '@/modules/user/constants';

type ProfileAvatarProps = Partial<AvatarProps> & {
  avatarUrl?: string | null;
  name?: string;
  initials?: string;
  user?: {
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    avatarUrl?: string | null;
  };
  badge?: ReactNode;
};

export const ProfileAvatar = forwardRef<ElementRef<typeof Avatar>, ProfileAvatarProps>(
  (
    {
      avatarUrl: iAvatarUrl,
      name: iName,
      initials,
      className,
      user,
      size,
      children,
      badge,
    },
    ref,
  ) => {
    const name = user && !iName ? formatName(user) : iName;
    const avatarUrl = user && !iAvatarUrl ? user.avatarUrl : iAvatarUrl;
    const avatarChildren =
      initials ??
      ((name &&
        (!avatarUrl || avatarUrl === DEFAULT_AVATAR.USER) &&
        name
          .split(' ')
          .slice(0, 2)
          .flatMap((slicedName) => slicedName?.[0] ?? [])
          .join('')) ||
        children);
    return (
      <Avatar
        className={cn('relative uppercase', className)}
        size={size}
        ref={ref}
        src={(avatarUrl !== DEFAULT_AVATAR.USER && avatarUrl) || ''}
        alt={'profile image'}
        badge={badge}
      >
        {avatarChildren}
      </Avatar>
    );
  },
);
ProfileAvatar.displayName = 'ProfileAvatar';

export const ProfileAvatarStack = ({
  avatars,
  size = 'sm',
  className,
  wrapperClassName,
}: {
  avatars: { avatarUrl?: string; initials?: string }[];
  size?: AvatarProps['size'];
  className?: string;
  wrapperClassName?: string;
}) => {
  return (
    <div className={cn('flex flex-row-reverse', wrapperClassName)}>
      {avatars.map((a, i) => (
        <ProfileAvatar
          key={i}
          avatarUrl={a.avatarUrl}
          size={size}
          className={cn('bg-muted z-10', i > 0 && '-mr-2', className)}
        >
          {a.initials}
        </ProfileAvatar>
      ))}
    </div>
  );
};
