import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { motivationDefinitions, motivationGroupDefinitions } from '@f4s/ui';
import type { DistributionData } from '@f4s/widgets';

import { fetchMembers } from '@/modules/member/queries';
import { fetchModelData, fetchWorkspaceModelList } from '@/modules/modeling/queries';
import { fetchWorkspace } from '@/modules/workspace/queries';

import {
  fetchOverTimeData,
  fetchUserMotivations,
  fetchWorkspaceDistribution,
  fetchWorkspaceMotivations,
  getTeamDistribution,
  type mapMotivations,
} from './queries';

export const questionnaireLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug, memberId } = params as {
    workspaceSlug?: string;
    teamId?: string;
    memberId?: string;
  };

  let modelData = await fetchModelData();
  let motivationData: ReturnType<typeof mapMotivations> = [];

  if (workspaceSlug) {
    // We're fetching an aggregate
    const workspace = await fetchWorkspace(workspaceSlug);
    if (!workspace) return redirect('..');
    motivationData = await fetchWorkspaceMotivations({ workspaceId: workspace.id });
    const workspaceModels = await fetchWorkspaceModelList({ workspaceId: workspace.id });
    modelData = [...workspaceModels, ...modelData];
  } else if (memberId) {
    // We're fetching another user's results
    const [member] = await fetchMembers({
      workspaceSlug,
      memberId,
    });
    if (!member || member.userId === null) return redirect('..');
    const userMotivations = await fetchUserMotivations({ userIds: [member.userId] });
    motivationData = userMotivations[0]?.motivations ?? [];
  } else {
    const meMotivations = await fetchUserMotivations();
    motivationData = meMotivations[0]?.motivations ?? [];
  }

  return { motivationData, modelData };
};
export type QuestionnaireLoaderData = Exclude<
  Awaited<ReturnType<typeof questionnaireLoader>>,
  Promise<Response> | Response
>;

export const motivationGroupLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { motivationGroupId } = params as {
    motivationGroupId: string;
    teamId?: string;
    workspaceSlug?: string;
  };
  const questionnaireLoaderResponse = await questionnaireLoader({ request, params });
  if (questionnaireLoaderResponse instanceof Response) return questionnaireLoaderResponse;

  const motivationGroup = motivationGroupDefinitions.find(
    (m) => m.slug === motivationGroupId,
  );
  if (!motivationGroup) return redirect('..');

  const motivationData = questionnaireLoaderResponse.motivationData.filter(
    (m) => m.group === motivationGroup.slug,
  );
  return {
    motivationGroup,
    motivationData,
  };
};
export type MotivationGroupLoaderData = Exclude<
  Awaited<ReturnType<typeof motivationGroupLoader>>,
  Promise<Response> | Response
>;

export const motivationLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { motivationId } = params as { motivationId: string };
  const questionnaireLoaderResponse = await questionnaireLoader({ request, params });
  if (questionnaireLoaderResponse instanceof Response) return questionnaireLoaderResponse;

  const motivation = motivationDefinitions.find((m) => m.slug === motivationId);
  if (!motivation) return redirect('..');

  const motivationData = questionnaireLoaderResponse.motivationData.find(
    (m) => m.code === motivation.code,
  );
  return { motivation, motivationData };
};

export type MotivationLoaderData = Exclude<
  Awaited<ReturnType<typeof motivationLoader>>,
  Promise<Response> | Response
>;

export type MotivationData = Exclude<MotivationLoaderData['motivationData'], undefined>;

export const overTimeLoader = async () => {
  const { overTimeData } = await fetchOverTimeData();
  return { overTimeData };
};
export type OverTimeLoaderData = Exclude<
  Awaited<ReturnType<typeof overTimeLoader>>,
  Promise<Response> | Response
>;

export const underlyingDataIndexLoader = async () => {
  return redirect(`${motivationDefinitions[0].group}`);
};

export const underlyingDataMotivationLoader = async ({ params }: LoaderFunctionArgs) => {
  const { motivationSlug, memberId, teamId, workspaceSlug } = params as {
    motivationSlug: string;
    memberId?: string;
    teamId?: string;
    workspaceSlug?: string;
  };

  const currentIndex = motivationDefinitions.findIndex((m) => m.slug === motivationSlug);
  const current = motivationDefinitions.at(currentIndex);
  const previous = motivationDefinitions.at(
    (currentIndex - 1) % motivationDefinitions.length,
  );
  const next = motivationDefinitions.at(
    (currentIndex + 1) % motivationDefinitions.length,
  );
  const currentGroup = motivationGroupDefinitions.find((g) => g.slug === current?.group);
  if (!current || !next || !previous || !currentGroup) return redirect('..');

  const members = memberId ? await fetchMembers({ workspaceSlug, teamId, memberId }) : [];
  const motivations = memberId
    ? await fetchUserMotivations({ userIds: members.map((m) => m.userId) })
    : await fetchUserMotivations();

  // Team motivation details
  const { motivations: teamMotivations, distributions: teamDistributions } =
    await getTeamDistribution({
      teamId: Number(teamId),
      workspaceSlug,
    });

  const { motivations: workspaceMotivations, distributions: workspaceDistributions } =
    await fetchWorkspaceDistribution({
      workspaceSlug,
    });

  // Put distributions behind workspace entitlements
  const distributions: DistributionData[] = [];
  const workspace = await fetchWorkspace(workspaceSlug);
  if (
    (workspace?.entitlements['comparison-limit'] ?? 2) < 0 &&
    (workspace?.isAdmin || workspace?.permissions.memberViewEachOther)
  ) {
    distributions.push(...teamDistributions, ...workspaceDistributions);
  }

  return {
    motivations,
    teamMotivations,
    workspaceMotivations,
    distributions,
    current,
    currentGroup,
    currentPosition: currentIndex + 1,
    length: motivationDefinitions.length,
    next,
    previous,
  };
};

export const underlyingDataMotivationGroupLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const { motivationGroupSlug, memberId, teamId, workspaceSlug } = params as {
    motivationGroupSlug: string;
    memberId?: string;
    teamId?: string;
    workspaceSlug?: string;
  };

  const currentIndex = motivationGroupDefinitions.findIndex(
    (m) => m.slug === motivationGroupSlug,
  );
  const current = motivationGroupDefinitions.at(currentIndex);
  const previous = motivationGroupDefinitions.at(
    (currentIndex - 1) % motivationGroupDefinitions.length,
  );
  const next = motivationGroupDefinitions.at(
    (currentIndex + 1) % motivationGroupDefinitions.length,
  );

  if (!current || !next || !previous) return redirect('..');

  const members = memberId ? await fetchMembers({ workspaceSlug, teamId, memberId }) : [];
  const motivations = memberId
    ? await fetchUserMotivations({ userIds: members.map((m) => m.userId) })
    : await fetchUserMotivations();

  // Team motivation details
  const { motivations: teamMotivations, distributions: teamDistributions } =
    await getTeamDistribution({
      teamId: Number(teamId),
      workspaceSlug,
    });
  const { motivations: workspaceMotivations, distributions: workspaceDistributions } =
    await fetchWorkspaceDistribution({
      workspaceSlug,
    });

  // Put distributions behind workspace entitlements
  const distributions: DistributionData[] = [];
  const workspace = await fetchWorkspace(workspaceSlug);
  if (
    (workspace?.entitlements['comparison-limit'] ?? 2) < 0 &&
    (workspace?.isAdmin || workspace?.permissions.memberViewEachOther)
  ) {
    distributions.push(...teamDistributions, ...workspaceDistributions);
  }

  return {
    motivations,
    teamMotivations,
    workspaceMotivations,
    distributions,
    current,
    currentPosition: currentIndex + 1,
    length: motivationGroupDefinitions.length,
    next,
    previous,
  };
};
