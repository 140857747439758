import { redirect, type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { acceptMagicInviteAction, acceptPersonalInviteAction } from './actions';
import {
  inviteHubLoader,
  inviteReceivedLoader,
  inviteSentLoader,
  invitesReceivedLoader,
  invitesSentLoader,
  inviteTokenLoader,
  magicTokenLoader,
} from './loaders';

export const inviteTokenRoutes: RouteObject = {
  path: 'invites',
  async lazy() {
    const { InviteTokenLayout } = await import('./pages/token-layout');
    const { InviteErrorBoundary } = await import('./pages/_error');
    return { Component: InviteTokenLayout, ErrorBoundary: InviteErrorBoundary };
  },
  children: [
    // Space magic links
    {
      path: 'link/:token',
      loader: magicTokenLoader,
      action: acceptMagicInviteAction,
      async lazy() {
        const { MagicLinkPage } = await import('./pages/magic-link-page');
        return { Component: MagicLinkPage };
      },
    },
    // Space direct invites
    {
      path: 'token/:token',
      loader: inviteTokenLoader,
      action: acceptPersonalInviteAction,
      async lazy() {
        const { InviteTokenPage } = await import('./pages/invite-token-page');
        return { Component: InviteTokenPage };
      },
    },
  ],
};

export const inviteHubRoutes: RouteObject = {
  path: 'invites',
  handle: handle({ type: 'invites', breadcrumb: SimpleBreadcrumb({ title: 'Invites' }) }),
  async lazy() {
    const { InvitesHubLayout } = await import('./pages/hub-layout');
    const { InviteErrorBoundary } = await import('./pages/_error');
    return { Component: InvitesHubLayout, ErrorBoundary: InviteErrorBoundary };
  },
  loader: inviteHubLoader,
  children: [
    { index: true, loader: async () => redirect('received') },
    {
      path: 'received',
      handle: handle({
        type: 'invites-received',
        breadcrumb: SimpleBreadcrumb({ title: 'Received' }),
      }),
      children: [
        {
          index: true,
          loader: invitesReceivedLoader,
          async lazy() {
            const { InvitesReceivedList } = await import('./pages/received-list');
            return { Component: InvitesReceivedList };
          },
        },
        {
          path: ':inviteId',
          loader: inviteReceivedLoader,
          async lazy() {
            const { InviteReceivedPage } = await import('./pages/received-page');
            return { Component: InviteReceivedPage };
          },
        },
      ],
    },
    {
      path: 'sent',
      handle: handle({
        type: 'invites-sent',
        breadcrumb: SimpleBreadcrumb({ title: 'Sent' }),
      }),
      loader: invitesSentLoader,
      children: [
        {
          index: true,
          loader: invitesSentLoader,
          async lazy() {
            const { InvitesSentList } = await import('./pages/sent-list');
            return { Component: InvitesSentList };
          },
        },
        {
          path: ':inviteId',
          loader: inviteSentLoader,
          async lazy() {
            const { InviteSentPage } = await import('./pages/sent-page');
            return { Component: InviteSentPage };
          },
        },
      ],
    },

    {
      path: 'missing',
      async lazy() {
        const { InviteMissingPage } = await import('./pages/_missing');
        return { Component: InviteMissingPage };
      },
    },
  ],
};
