import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { hiringManagerDetailsLoader } from './loaders';
import { talentMatchRoutes } from './match/routes';
import { talentOpeningRoutes } from './opening/routes';
import { talentPoolRoutes, talentPoolWorkspaceRoutes } from './pool/routes';
import { talentProfileRoutes } from './profile/routes';
import { talentPoolsLoader } from './pool/loaders';

export const talentRoutes: RouteObject = {
  path: 'talent',
  handle: { crumb: { title: 'Talent' } },
  children: [
    {
      index: true,
      loader: talentPoolsLoader,
      async lazy() {
        const { UserTalentIndex } = await import('./pages/user-index');
        return { Component: UserTalentIndex };
      },
    },
    talentPoolRoutes,
    talentProfileRoutes,
  ],
};

export const workspaceTalentRoutes: RouteObject = {
  path: 'talent',
  handle: handle({
    breadcrumb: SimpleBreadcrumb({ title: 'Talent' }),
  }),
  children: [
    {
      index: true,
      async lazy() {
        const { WorkspaceTalentIndex } = await import('./pages/workspace-index');
        return { Component: WorkspaceTalentIndex };
      },
    },
    talentPoolWorkspaceRoutes,
    talentOpeningRoutes,
    talentMatchRoutes,
    {
      path: 'hiring-manager',
      handle: { crumb: { type: 'hiringManager' } },
      loader: hiringManagerDetailsLoader,
      async lazy() {
        const { HiringManagerPage } = await import('./hiring-manager/page');
        return { Component: HiringManagerPage };
      },
    },
  ],
};
