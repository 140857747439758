export const colors = {
  'white': {
    DEFAULT: '#ffffff',
    25: '#fcfbf8',
    50: '#f8f6f1',
    100: '#f1ede3',
    200: '#e2d9c6',
    300: '#cfc0a2',
    400: '#bba27c',
    500: '#ad8d62',
    600: '#a07b56',
    700: '#856149',
    800: '#6d523f',
    900: '#594435',
    950: '#2f221b',
  },
  'sand': {
    DEFAULT: '#f8f6f1',
    25: '#fcfbf8',
    50: '#f8f6f1',
    100: '#f1ede3',
    200: '#e2d9c6',
    300: '#cfc0a2',
    400: '#bba27c',
    500: '#ad8d62',
    600: '#a07b56',
    700: '#856149',
    800: '#6d523f',
    900: '#594435',
    950: '#2f221b',
  },
  'elderberry': {
    DEFAULT: '#0c0c30',
    50: '#ecefff',
    100: '#dbe1ff',
    200: '#bfc7ff',
    300: '#98a2ff',
    400: '#7070ff',
    500: '#5b4eff',
    600: '#4e2ffc',
    700: '#4423df',
    800: '#371fb4',
    900: '#31228d',
    950: '#0c0c30',
  },
  'action-level': {
    base: '#feeb55',
    50: '#fffbe6',
    100: '#fff6ca',
    200: '#fef0af',
    300: '#fde17c',
    400: '#fbd052',
    500: '#f7bd33',
    600: '#eeaa1c',
    700: '#da8f0d',
    800: '#a86603',
    900: '#784400',
  },
  'action-direction': {
    base: '#f7bf5d',
    50: '#fff7e9',
    100: '#fde9c9',
    200: '#fbdbaa',
    300: '#f5bb6f',
    400: '#eb9b3f',
    500: '#dc7d1d',
    600: '#c86106',
    700: '#ae4b00',
    800: '#923a00',
    900: '#782c00',
  },
  'authority': {
    base: '#ef8c64',
    50: '#fff2ed',
    100: '#fdd8ca',
    200: '#fcbea7',
    300: '#f68b66',
    400: '#ec5f32',
    500: '#de3c0d',
    600: '#cb2b00',
    700: '#b32300',
    800: '#981c00',
    900: '#801500',
  },
  'task-direction': {
    base: '#e45067',
    50: '#fff2f4',
    100: '#fdd4db',
    200: '#fcb7c1',
    300: '#f68091',
    400: '#ec5269',
    500: '#de304a',
    600: '#cb1934',
    700: '#b30b24',
    800: '#98031a',
    900: '#800013',
  },
  'scope': {
    base: '#fa346a',
    50: '#ffeef3',
    100: '#fdcedb',
    200: '#fcaec3',
    300: '#f67295',
    400: '#ec426f',
    500: '#de1e51',
    600: '#cb073b',
    700: '#b30030',
    800: '#980029',
    900: '#800022',
  },
  'communication': {
    base: '#a72668',
    50: '#ffedf6',
    100: '#fdcde6',
    200: '#fcaed6',
    300: '#f674b7',
    400: '#ec449b',
    500: '#de2183',
    600: '#cb0a6e',
    700: '#b3005c',
    800: '#98004f',
    900: '#800042',
  },
  'environment': {
    base: '#702f6c',
    50: '#fff4fe',
    100: '#fdd8fb',
    200: '#fbbef7',
    300: '#f48bed',
    400: '#ea60e0',
    500: '#da40d0',
    600: '#c429ba',
    700: '#a81a9f',
    800: '#8a1082',
    900: '#6e0b67',
  },
  'responsibility': {
    base: '#442264',
    50: '#f9f2ff',
    100: '#ecd9fd',
    200: '#dec0fb',
    300: '#c490f5',
    400: '#ab68ea',
    500: '#9449db',
    600: '#7f32c6',
    700: '#6922ab',
    800: '#55188e',
    900: '#441173',
  },
  'change': {
    base: '#40c6f1',
    50: '#ddf7ff',
    100: '#bcedfd',
    200: '#9be3fb',
    300: '#5dcef3',
    400: '#2cb9e8',
    500: '#0aa4d7',
    600: '#0090c0',
    700: '#0079a2',
    800: '#006182',
    900: '#004b63',
  },
  'work-approach': {
    base: '#66b1e0',
    50: '#e3f4ff',
    100: '#c4e7fd',
    200: '#a5dafb',
    300: '#6cc0f4',
    400: '#3da7ea',
    500: '#1c91da',
    600: '#067bc4',
    700: '#0068a8',
    800: '#00558a',
    900: '#00446e',
  },
  'time': {
    base: '#057abd',
    50: '#e0f4ff',
    100: '#bfe6fd',
    200: '#9dd9fb',
    300: '#5fbdf4',
    400: '#2da4e8',
    500: '#0a8cd8',
    600: '#007ac1',
    700: '#0068a4',
    800: '#005484',
    900: '#004166',
  },
  'influence': {
    base: '#10427e',
    50: '#e9f3ff',
    100: '#c9e0fd',
    200: '#aacefb',
    300: '#6fabf5',
    400: '#408deb',
    500: '#1d73dd',
    600: '#065ec9',
    700: '#004fb0',
    800: '#004394',
    900: '#00377a',
  },
  'rules': {
    base: '#0f1254',
    50: '#f2f3ff',
    100: '#dddffd',
    200: '#c9cbfb',
    300: '#a1a5f5',
    400: '#7f84ec',
    500: '#636ade',
    600: '#4e54ca',
    700: '#3d42b1',
    800: '#2f3496',
    900: '#252a7d',
  },
  'convincer-input': {
    base: '#215d77',
    50: '#e3f7ff',
    100: '#c7edfd',
    200: '#abe3fb',
    300: '#77cef4',
    400: '#4cbae9',
    500: '#2ca5d8',
    600: '#178ec2',
    700: '#0976a5',
    800: '#035f86',
    900: '#004969',
  },
  'convincer-process': {
    base: '#36918a',
    50: '#defffc',
    100: '#c2fdf8',
    200: '#a7faf3',
    300: '#73f2e8',
    400: '#49e5d8',
    500: '#2ad3c5',
    600: '#15b8ab',
    700: '#08978b',
    800: '#02736a',
    900: '#00524b',
  },
  'interest-filters': {
    base: '#49c89f',
    50: '#e0fff5',
    100: '#c4fdeb',
    200: '#a9fae0',
    300: '#75f2ca',
    400: '#4ae6b4',
    500: '#2bd39e',
    600: '#15b985',
    700: '#09996b',
    800: '#027551',
    900: '#00543a',
  },
} as const;

export const palette = (color: keyof typeof colors) => `palette-${color}`;
