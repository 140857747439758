import { cva, type VariantProps } from 'cva';
import * as React from 'react';
import { type CSSProperties } from 'react';

import { getCloudflareImageURL } from '../lib/image';
import { cn } from '../lib/utils';

const avatarVariants = cva('', {
  variants: {
    size: {
      xxs: ['h-3 min-h-3 w-3 min-w-3 text-center text-[5px]'],
      xs: ['h-6 min-h-6 w-6 min-w-6 text-xs'],
      sm: ['h-7 min-h-7 w-6 min-w-7 text-sm'],
      default: ['h-9 min-h-9 w-9 min-w-9'],
      lg: ['h-10 min-h-10 w-10 min-w-10 text-lg'],
      xl: ['h-14 min-h-14 w-14 min-w-14 text-xl'],
      xxl: ['h-[96px] min-h-[96px] w-[96px] min-w-[96px] text-xl'],
    },
  },
});

const sizeMap: Record<
  Exclude<VariantProps<typeof avatarVariants>['size'], null | undefined>,
  number
> = {
  xxs: 12,
  xs: 24,
  sm: 28,
  default: 36,
  lg: 40,
  xl: 56,
  xxl: 96,
};

export type AvatarProps = {
  src: string | undefined | null;
  size?: 'default' | 'xxs' | 'xs' | 'sm' | 'lg' | 'xl' | 'xxl';
  className?: string;
  alt?: string;
  fallback?: string;
  children?: React.ReactNode;
  badge?: React.ReactNode;
};

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 'default', className, src, alt, fallback, children, badge }, ref) => {
    const avatarSize = 2 * sizeMap[size];
    const srcImage = src
      ? getCloudflareImageURL({ src, height: avatarSize, width: avatarSize })
      : undefined;
    const style = {
      ...(srcImage ? { '--avatar-image': `url(${srcImage})` } : {}),
      ...(fallback ? { '--avatar-fallback': `url(${fallback})` } : {}),
    } as CSSProperties;
    return (
      <div className="relative max-h-min max-w-min font-medium">
        <div
          className={cn(
            'bg-card ring-border/5 relative z-0 aspect-square shrink-0 overflow-hidden rounded-full ring-1 duration-500 animate-in fade-in',
            avatarVariants({ size }),
            className,
          )}
          ref={ref}
        >
          <div className="absolute flex h-full w-full items-center justify-center text-center">
            {children}
          </div>
          <div
            className={cn(
              'bg-avatar absolute z-10 h-full w-full bg-cover transition-all [background-position:center_center]',
            )}
            style={style}
          />
        </div>
        {badge}
        <span className="sr-only">{alt}</span>
      </div>
    );
  },
);
Avatar.displayName = 'Avatar';
