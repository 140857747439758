import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';
import { updateQueryFavorites } from '@/modules/queries/actions';

import {
  clearOnboardingJtbdQueryIds,
  jtbdCategorySchema,
  setOnboardingJtbdQueryIds,
  setOnboardingJtbdSlug,
} from '../utils';

export const signupIndexAction = async ({ request }: ActionFunctionArgs) => {
  const user = await authProvider.getMaybeUser();
  const data = await request.json();
  const { slug, redirectTo } = z
    .object({ slug: jtbdCategorySchema, redirectTo: z.string() })
    .parse(data);

  if (user) {
    // Post to the database
    await authProvider.updateUser({ properties: { jtbdCategory: slug } });
  } else {
    // Save to localstorage and defer update until logged in
    setOnboardingJtbdSlug(slug);
  }

  sendEvent('signUp_next', { signUpStep: `jtbd_${slug}` });
  return redirect(redirectTo);
};

export const signupJtbdQueryAction = async ({ request }: ActionFunctionArgs) => {
  const user = await authProvider.getMaybeUser();
  const data = await request.json();
  const { queryIds } = z.object({ queryIds: z.number().array() }).parse(data);

  if (user) {
    if (queryIds.length > 0) {
      // Post to the database
      try {
        await updateQueryFavorites({ add: queryIds, remove: [] });
        clearOnboardingJtbdQueryIds();
      } catch (error) {
        console.error('Error updating onboardingJtbdQueryIds for user', error);
        setOnboardingJtbdQueryIds(queryIds);
      }
    }

    return redirect('/onboarding');
  } else {
    // Save to localstorage and defer update until logged in
    setOnboardingJtbdQueryIds(queryIds);
  }

  sendEvent('signUp_next', { signUpStep: 'create_account' });
  return redirect('/signup/create');
};
