import { useCallback, useMemo, useState } from 'react';
import { useFetcher } from 'react-router-dom';

import {
  AvatarUploader,
  Button,
  Icon,
  Input,
  InputAutocomplete,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@f4s/ui';

import { CityAutocomplete } from '@/components/city-autocomplete';
import { PageHeader } from '@/components/page-header';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';

import { JobAreaTitle } from '../components/job-area-title';
import type { onboardingProfileLoader } from '../loaders';

type Selection = {
  label: string;
  key: string;
  value: string;
};

const genderSelections = [
  { label: 'They / Them', key: 't', value: 't' },
  { label: 'She / Her', key: 'f', value: 'f' },
  { label: 'He / Him', key: 'm', value: 'm' },
];

export const OnboardingProfileUpdatePage = () => {
  const { user } = usePersistedDataFromLoader<typeof onboardingProfileLoader>();

  const [firstName, setFirstName] = useState<string>(user.firstName ?? '');
  const handleFirstName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const [lastName, setLastName] = useState<string>(user.lastName ?? '');
  const handleLastName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);

  const [location, setLocation] = useState<string>(user.location ?? '');
  const handleLocation = useCallback((newLocation: string | null) => {
    setLocation(newLocation ?? '');
  }, []);

  const [country, setCountry] = useState<string>(user.countryCode ?? '');
  const handleCountry = useCallback((newCountry: string | null) => {
    setCountry(newCountry ?? '');
  }, []);

  const [jobArea, setJobArea] = useState<string>(
    (user.properties.jobArea as string | undefined | null) ?? '',
  );
  const handleJobArea = useCallback((newJobArea: string | null) => {
    setJobArea(newJobArea ?? '');
  }, []);

  const [jobTitle, setJobTitle] = useState<string>(
    (user.properties.jobTitle as string | undefined | null) ?? '',
  );
  const handleJobTitle = useCallback((newJobTitle: string | null) => {
    setJobTitle(newJobTitle ?? '');
  }, []);

  const genderSelection = genderSelections.find((g) => g.value === user.gender);
  const [gender, setGender] = useState<Selection | null>(genderSelection ?? null);
  const [genderSearch, setGenderSearch] = useState<string>('');

  const filteredGenderSelections = useMemo(() => {
    const rgx = new RegExp(genderSearch, 'i');
    return genderSelections.filter((c) => rgx.test(c.label) || rgx.test(c.value));
  }, [genderSearch]);

  const [imageFile, setImageFile] = useState<File | null>(null);

  const fetcher = useFetcher();
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const postData: Partial<{
        firstName: string;
        lastName: string;
        gender: string;
        countryCode: string;
        location: string;
        properties?: {
          jobArea?: string;
          jobTitle?: string;
        };
      }> = {};
      const formData = new FormData();

      formData.append('redirectTo', '../complete');
      if (imageFile) {
        formData.append('avatar', imageFile);
      }
      if (firstName !== user.firstName) {
        postData.firstName = firstName;
      }
      if (lastName !== user.lastName) {
        postData.lastName = lastName;
      }
      if (gender && gender?.value !== user.gender) {
        postData.gender = gender.value;
      }

      if (location && location !== user.location) {
        postData.location = location;
      }
      if (country && country !== user.countryCode) {
        postData.countryCode = country;
      }

      const properties: (typeof postData)['properties'] = {};
      if (jobArea && jobArea !== user.properties.jobArea) {
        properties.jobArea = jobArea;
      }
      if (jobTitle && jobTitle !== user.properties.jobTitle) {
        properties.jobTitle = jobTitle;
      }
      if (Object.keys(properties).length > 0) {
        postData.properties = properties;
      }

      formData.append('postData', JSON.stringify(postData));

      return fetcher.submit(formData satisfies FormData, {
        method: 'POST',
        encType: 'multipart/form-data',
      });
    },
    [
      country,
      fetcher,
      firstName,
      gender,
      imageFile,
      jobArea,
      jobTitle,
      lastName,
      location,
      user.countryCode,
      user.firstName,
      user.gender,
      user.lastName,
      user.location,
      user.properties.jobArea,
      user.properties.jobTitle,
    ],
  );

  const disabled =
    fetcher.state !== 'idle' ||
    !firstName ||
    !lastName ||
    !country ||
    !gender ||
    !jobArea ||
    !jobTitle;

  return (
    <div className="flex flex-col gap-4 p-4 sm:p-6">
      <PageHeader title="Update your profile" />
      <form className="flex w-full flex-col items-center gap-6" onSubmit={handleSubmit}>
        <div className="flex w-full flex-col gap-6 sm:flex-row">
          <div className="ring-border/5 flex max-h-[228px] w-full flex-col items-center justify-evenly rounded-lg p-4 ring-1 sm:w-1/3">
            <AvatarUploader
              currentImage={user.avatarUrl ?? undefined}
              onFileSelected={(file: File) => setImageFile(file)}
              labelledBy="avatar-label"
              className="bg-card text-muted-foreground aspect-square h-28 w-28 rounded-full shadow-none ring-inset"
            />
            <Label
              id="avatar-label"
              className="text-muted-foreground line-clamp-2 text-balance text-center font-normal"
            >
              Choose or add a profile picture
            </Label>
          </div>
          <div className="flex w-full flex-col items-center gap-6 sm:w-2/3">
            <div className="flex w-full flex-col gap-1">
              <Label>
                Name <span className="text-muted-foreground">*</span>
              </Label>
              <div className="flex flex-row gap-2">
                <Label className="sr-only text-right font-normal">First Name</Label>
                <Input
                  type="text"
                  value={firstName}
                  onChange={handleFirstName}
                  placeholder="First"
                  required
                  data-testid="onboarding-profile-update-firstname-input"
                />
                <Label className="sr-only text-right font-normal">Last Name</Label>
                <Input
                  type="text"
                  value={lastName}
                  onChange={handleLastName}
                  placeholder="Last"
                  required
                  data-testid="onboarding-profile-update-lastname-input"
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-1">
              <div className="flex flex-row justify-between gap-2">
                <Label>
                  Pronouns <span className="text-muted-foreground">*</span>
                </Label>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Icon.Info size={16} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      Knowing your pronouns helps us to refer to you correctly in
                      responses.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
              <InputAutocomplete
                onValueChange={setGenderSearch}
                selection={gender}
                selections={filteredGenderSelections}
                onSelectionChange={setGender}
                hideIcon
                data-testid="onboarding-profile-update-gender-autocomplete-input"
              />
            </div>

            <div className="flex w-full flex-col gap-1">
              <div className="flex flex-row justify-between gap-2">
                <Label>
                  Location <span className="text-muted-foreground">*</span>
                </Label>
              </div>
              <CityAutocomplete
                location={location}
                onLocationChange={handleLocation}
                onCountryCodeChange={handleCountry}
                hideIcon
                data-testid="onboarding-profile-update-location-autocomplete-input"
              />
            </div>
            <div className="flex w-full flex-col gap-1">
              <div className="flex flex-row justify-between gap-2">
                <Label>
                  Job Role <span className="text-muted-foreground">*</span>
                </Label>
              </div>
              <JobAreaTitle
                jobArea={jobArea}
                jobTitle={jobTitle}
                onJobAreaChange={handleJobArea}
                onJobTitleChange={handleJobTitle}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Button
            type="submit"
            disabled={disabled}
            data-testid="onboarding-profile-update-submit-button"
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
