import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { underlyingDataRouter } from '../motivation/routes';
import {
  createDashboardAction,
  createDashboardFromTemplateAction,
  refreshDashboard,
  shareDashboardAction,
  updateDashboardAction,
  updateWidgetAction,
} from './actions';
import {
  dashboardLoader,
  dashboardsLoader,
  dashboardTemplateLoader,
  dashboardTemplatesLoader,
  dashboardWidgetIndexLoader,
  dashboardWidgetLoader,
  newDashboardLoader,
  newWidgetLoader,
} from './loaders';

export const newDashboardRoute = (path: string = 'new'): RouteObject => ({
  path,
  async lazy() {
    const { DashboardPage } = await import('./pages');
    return { Component: DashboardPage };
  },
  handle: { crumb: { title: 'New' } },
  loader: newDashboardLoader,
  action: createDashboardAction,
});

export const dashboardSubRoutes = {
  action: updateDashboardAction,
  children: [
    {
      path: 'share',
      action: shareDashboardAction,
    },
    {
      path: 'refresh',
      action: refreshDashboard,
    },
    {
      path: 'widgets',
      loader: dashboardLoader,
      async lazy() {
        const { DashboardWidgetLayout } = await import('./pages');
        return { Component: DashboardWidgetLayout };
      },
      children: [
        {
          index: true,
          loader: dashboardWidgetIndexLoader,
        },
        {
          path: 'new',
          loader: newWidgetLoader,
          action: updateWidgetAction,
          async lazy() {
            const { DashboardWidgetPage, DashboardWidgetHandle } = await import(
              './pages'
            );
            return {
              Component: DashboardWidgetPage,
              handle: DashboardWidgetHandle(),
            };
          },
        },
        {
          path: ':widgetId',
          loader: dashboardWidgetLoader,
          action: updateWidgetAction,
          async lazy() {
            const { DashboardWidgetPage, DashboardWidgetHandle } = await import(
              './pages'
            );
            return {
              Component: DashboardWidgetPage,
              handle: DashboardWidgetHandle(),
            };
          },
        },
      ],
    },
    underlyingDataRouter({}),
  ],
};

export const dashboardRoutes: RouteObject = {
  path: 'boards',
  handle: handle({
    type: 'dashboards',
    breadcrumb: SimpleBreadcrumb({ title: 'Boards' }),
  }),
  children: [
    // Index page for dashboards - list + templates
    {
      index: true,
      async lazy() {
        const { Dashboards } = await import('./pages');
        return { Component: Dashboards };
      },
      loader: dashboardsLoader,
    },

    // Routed modal view of dashboard templates
    {
      async lazy() {
        const { Dashboards } = await import('./pages');
        return { Component: Dashboards };
      },
      loader: dashboardsLoader,
      children: [
        {
          path: 'templates/:templateId',
          async lazy() {
            const { TemplatePage } = await import('./pages');
            return { Component: TemplatePage };
          },
          loader: dashboardTemplateLoader,
          action: createDashboardFromTemplateAction,
        },
      ],
    },
    {
      path: 'templates',
      handle: { crumb: { title: 'Templates' } },
      loader: dashboardTemplatesLoader,
      async lazy() {
        const { TemplateList } = await import('./pages');
        return { Component: TemplateList };
      },
    },
    // Manually created new dashboard (empty)
    newDashboardRoute(),

    // A saved dashboard
    {
      path: ':dashboardId',
      async lazy() {
        const { DashboardPage } = await import('./pages');
        return { Component: DashboardPage };
      },
      loader: dashboardLoader,
      handle: { crumb: { type: 'dashboard' } },
      ...dashboardSubRoutes,
    },
  ],
};
