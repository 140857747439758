import { Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { usePendingInvites } from '@/modules/invite/queries';

export const NotificationButton = () => {
  const { data: personalInvites } = usePendingInvites();
  const showNotificationIndicator = !!personalInvites?.length;

  return (
    <NavLinkButton
      to="/notifications"
      size="icon"
      variant="secondary"
      className="relative shrink-0"
    >
      <Icon.Bell size={18} weight="duotone" />
      {showNotificationIndicator && (
        <div className="absolute right-1 top-1 min-h-2 min-w-2 rounded-full bg-red-600" />
      )}
    </NavLinkButton>
  );
};
