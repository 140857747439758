const cfImagePrefix = 'https://getmarlee.com/cdn-cgi/image';

export type ImageOptions = {
  src?: string;
  format?: 'auto';
  height?: number;
  width?: number;
  quality?: number;
};

export const getCloudflareImageURL = ({
  src,
  format = 'auto',
  quality = 80,
  width,
  height,
}: ImageOptions) => {
  if (!src) return src;

  const fullUrl = new URL(src, window.location.origin);
  if (fullUrl.hostname === 'localhost') return src;

  // Only filter out keys with value === undefined. False & Null could be valid values
  const optionsString = Object.entries({ format, quality, width, height })
    .filter(([, value]) => value !== undefined)
    .map(([option, value]) => `${option}=${value}`)
    .join(',');

  // Replace double slash in nested url to support Ahrefs
  const nestedURL = fullUrl.toString().replace('//', '/');

  return `${cfImagePrefix}/${optionsString}/${nestedURL}`;
};
