import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  WorkspaceModelCreateSchema,
  WorkspaceModelDeleteSchema,
  WorkspaceModelUpdateSchema,
  type WorkspaceModelCreate,
} from '@f4s/types';
import { toast } from '@f4s/ui';

import { deleteLocalPreference, setLocalPreference } from '@/providers/local-preference';

import { fetchWorkspaceMotivations } from '../motivation/queries';

export async function modelCreateAction({ request }: ActionFunctionArgs) {
  const data = WorkspaceModelCreateSchema.parse(await request.json());

  const newModelId = (await apiClient.post(
    `/api/v4/workspaces/${data.workspaceId}/models/`,
    data,
  )) as number;

  // Invalidate caches
  await queryClient.invalidateQueries({ queryKey: ['modeling'] });

  return redirect(`../${newModelId}`);
}

export async function modelCreateFromCultureAction({ request }: ActionFunctionArgs) {
  const { workspaceId } = z
    .object({ workspaceId: z.number() })
    .parse(await request.json());
  const workspaceMotivations = await fetchWorkspaceMotivations({
    workspaceId: workspaceId,
  });
  // Uses culture 25th and 75th percentiles as green zone
  const modelMotivations: WorkspaceModelCreate['modelMotivations'] =
    workspaceMotivations.flatMap((m) => {
      if (!m.quartiles) return [];
      return {
        motivationId: m.id,
        greenHigh: m.quartiles[2],
        greenLow: m.quartiles[0],
        orangeHigh: m.quartiles[2],
        orangeLow: m.quartiles[0],
      };
    });
  const newModelId = (await apiClient.post(`/api/v4/workspaces/${workspaceId}/models/`, {
    workspaceId,
    name: 'Workspace Culture',
    modelMotivations,
  } satisfies WorkspaceModelCreate)) as number;
  await queryClient.invalidateQueries({ queryKey: ['modeling'] });
  return redirect(`../${newModelId}`);
}
export function useCreateFromCulture() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof modelCreateFromCultureAction>>>();
  return {
    ...fetcher,
    submit: (data: { workspaceId: number }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
        action: './new/culture',
      }),
  };
}

export async function modelModifyAction({ request, ...args }: ActionFunctionArgs) {
  if (request.method === 'PATCH') {
    return modelUpdateAction({ request, ...args });
  } else if (request.method === 'DELETE') {
    return modelDeleteAction({ request, ...args });
  }
  return null;
}

export async function modelUpdateAction({ request }: ActionFunctionArgs) {
  const data = WorkspaceModelUpdateSchema.parse(await request.json());

  try {
    await apiClient.patch(
      `/api/v4/workspaces/${data.workspaceId}/models/${data.modelId}`,
      data,
    );
  } catch (error) {
    console.error('Login error', error);
    // Save updates to localstorage for restoration.
    setLocalPreference({ preferenceName: `modelBackup-${data.modelId}`, value: data });
    toast({ title: 'There was an error saving the model' });
    return {
      error: 'Error updating model',
    };
  }
  deleteLocalPreference({ preferenceName: `modelBackup-${data.modelId}` });
  // Invalidate caches
  await queryClient.invalidateQueries({ queryKey: ['modeling'] });
  return { error: null };
}

export async function modelDeleteAction({ request }: ActionFunctionArgs) {
  const data = WorkspaceModelDeleteSchema.parse(await request.json());

  await apiClient.delete(`/api/v4/workspaces/${data.workspaceId}/models/${data.modelId}`);

  // Invalidate caches
  await queryClient.invalidateQueries({ queryKey: ['modeling'] });
  return redirect('..');
}
