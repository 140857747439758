import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { type AssessmentBlock } from '@f4s/types';

import { fetchOverTimeData } from '@/modules/motivation/queries';
import { fetchAssessmentLanguage } from '@/providers/assessment-language';

import { fetchCredits } from '../credits/queries';
import { getWorkspaceFromParams } from '../workspace/queries';
import {
  answersQuery,
  assessmentsQuery,
  fetchAnswers,
  fetchAssessments,
} from './queries';
import { getAssessmentsWithCompletion } from './utils';

// NOTE: These loaders are used outside of this module.
// TODO: May be worth consolidating in the future
export const assessmentsLoader = async () => {
  // This is barely a loader, more like an uncached query - it is not making use of params or request
  const user = await authProvider.getMaybeUser();
  const assessmentLanguage = await fetchAssessmentLanguage();
  const allAssessments = await fetchAssessments(assessmentLanguage);
  const savedAnswers = user ? await fetchAnswers() : [];

  // TODO: the following is performing a mutation on assessments
  // Worth cleaning this up soon
  const assessments = getAssessmentsWithCompletion({
    assessments: allAssessments,
    savedAnswers,
  });

  return {
    user,
    assessments,
    assessmentLanguage,
  };
};

export const assessmentLoader = async ({ params }: LoaderFunctionArgs) => {
  const { blockSlug } = params as { blockSlug: AssessmentBlock };
  const { user, assessments, assessmentLanguage } = await assessmentsLoader();
  const assessment = assessments.find((m) => m.slug === blockSlug);

  // For the full assessment, filter out already saved answers
  if (assessment && blockSlug === 'full') {
    const questions = assessment.questions.filter((q) => !q.isSaved);
    assessment.questions = questions;
  }

  return { user, assessment, assessmentLanguage };
};

export const startAssessmentLoader = async () => {
  await apiClient.post('/api/v3/questionnaires/create', {});
  const assessmentLanguage = await fetchAssessmentLanguage();
  await queryClient.invalidateQueries(assessmentsQuery(assessmentLanguage));
  await queryClient.invalidateQueries(answersQuery);
  return redirect('../full');
};

export const assessmentQuestionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const { questionNo } = params as { questionNo: string };
  const assessmentLoaderResponse = await assessmentLoader({
    request,
    params,
  });
  const { user, assessment, assessmentLanguage } = assessmentLoaderResponse;
  const currentQuestionNo = Number(questionNo);
  const currentQuestion = assessment?.questions[currentQuestionNo - 1];

  return {
    assessment,
    assessmentLanguage,
    currentQuestion,
    currentQuestionNo,
    user,
  };
};

export const assessmentCompleteIndexLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const assessmentLoaderResponse = await assessmentLoader({ request, params });
  if (assessmentLoaderResponse instanceof Response) return assessmentLoaderResponse;
  const { assessment } = assessmentLoaderResponse;
  if (!assessment) return redirect('/');

  const nextQuestionNumber = assessment.questions.findIndex((q) => !q.isAnswered);
  const nextPath = `question/${nextQuestionNumber === -1 ? 1 : nextQuestionNumber + 1}`;
  return redirect(nextPath);
};

export const assessmentCompleteQuestionLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const { questionNo } = params as { questionNo: string };
  const assessmentLoaderResponse = await assessmentLoader({
    request,
    params,
  });
  if (assessmentLoaderResponse instanceof Response) return assessmentLoaderResponse;

  const { user, assessment, assessmentLanguage } = assessmentLoaderResponse;
  const currentQuestionNo = Number(questionNo ?? 1);
  const currentQuestion = assessment?.questions[currentQuestionNo - 1];
  if (!currentQuestion) return redirect('/');
  const previousStep =
    currentQuestionNo > 1 ? `../question/${currentQuestionNo - 1}` : `/`;

  let nextStep =
    currentQuestionNo !== assessment?.questions.length
      ? `../question/${currentQuestionNo + 1}`
      : null;
  if (!nextStep) {
    // Check sessionStorage redirect
    nextStep = sessionStorage.getItem('assessmentRedirectTo');
    sessionStorage.removeItem('assessmentRedirectTo');
  }

  if (!nextStep) nextStep = '../../completed';

  return {
    assessment,
    assessmentLanguage,
    currentQuestion,
    currentQuestionNo,
    user,
    previousStep,
    nextStep,
  };
};

export type AssessmentCompleteQuestionLoaderData = Exclude<
  Awaited<ReturnType<typeof assessmentCompleteQuestionLoader>>,
  Promise<Response> | Response
>;

export const reassessmentLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { user, assessment } = await assessmentLoader({
    request,
    params: { ...params, blockSlug: 'full' },
  });

  // No user, shouldnt continue with other calls
  if (!user) return redirect('/');

  // For now we always expect a space;
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');

  // Unfinished assessment, direct them to it
  if (!assessment?.isAnswered) {
    return redirect('./full');
  }

  const { overTimeData } = await fetchOverTimeData();
  const [lastAssessment] = overTimeData.slice(-1);
  if (!lastAssessment) {
    // If the user does not have a last assessment, we should not  be in the reassessment flow
    return redirect('./full');
  }

  // This is what gets checked on the backend when consuming a credit, so we should use it here.
  const credits = await fetchCredits({ type: 'assessment', workspaceId: workspace?.id });

  return {
    user,
    lastAssessmentDate: lastAssessment?.date,
    hasAssessmentCredit: credits.totalCredits > 0,
    hasAssessmentEntitlement: workspace.entitlements.paid,
  };
};

export const assessmentCompleteLoader = async () => {
  const { overTimeData } = await fetchOverTimeData();
  if (overTimeData.length === 0) {
    return redirect('../full');
  }
  return { overTimeData };
};
