import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { toast } from '@f4s/ui';

import { getWorkspaceFromParams } from '../workspace/queries';
import { samlListQuery, scimListQuery, type Saml, type Scim } from './queries';

/* -------------------------------------------------------------------------- */
/*                                    SCIM                                    */
/* -------------------------------------------------------------------------- */

export async function scimCreateAction({ request, params }: ActionFunctionArgs) {
  try {
    const workspace = await getWorkspaceFromParams(params);
    if (!workspace) throw new Error('Workspace does not exist');
    const data = await request.json();
    const parsedData = z.object({ secret: z.string() }).parse(data);
    const res = (await apiClient.post(
      `/api/v4/workspaces/${workspace.id}/settings/scim`,
      parsedData,
    )) as Scim;
    await queryClient.invalidateQueries(scimListQuery(workspace.id));
    return redirect(`../${res.id}`);
  } catch (error) {
    console.error('Error creating SCIM record', error);
    toast({ title: 'An error occurred, check your formatting' });
  }
  return null;
}

export async function scimUpdateAction({ request, params }: ActionFunctionArgs) {
  try {
    const workspace = await getWorkspaceFromParams(params);
    if (!workspace) throw new Error('Workspace does not exist');

    const { scimId } = z.object({ scimId: z.string() }).parse(params);
    const data = await request.json();
    const parsedData = z.object({ secret: z.string() }).parse(data);

    if (request.method === 'POST') {
      // Accept the request entry
      const res = (await apiClient.patch(
        `/api/v4/workspaces/${workspace.id}/settings/scim/${scimId}`,
        parsedData,
      )) as Scim;

      await queryClient.invalidateQueries(scimListQuery(workspace.id));
      return res;
    }

    if (request.method === 'DELETE') {
      // Delete the request entry
      const res = (await apiClient.delete(
        `/api/v4/workspaces/${workspace.id}/settings/scim/${scimId}`,
      )) as Scim;
      // Invalidate the requests list
      await queryClient.invalidateQueries(scimListQuery(workspace.id));
      return res;
    }
  } catch (error) {
    console.error('Error creating SCIM record', error);
    toast({ title: 'An error occurred, check your formatting' });
  }

  return null;
}

/* -------------------------------------------------------------------------- */
/*                                    SAML                                    */
/* -------------------------------------------------------------------------- */
export async function samlCreateAction({ request, params }: ActionFunctionArgs) {
  try {
    const workspace = await getWorkspaceFromParams(params);
    if (!workspace) throw new Error('Workspace does not exist');
    const data = await request.json();
    const parsedData = z.object({ cert: z.string().optional() }).parse(data);

    const res = (await apiClient.post(
      `/api/v4/workspaces/${workspace.id}/settings/saml`,
      parsedData,
    )) as Scim;
    await queryClient.invalidateQueries(samlListQuery(workspace.id));
    return redirect(`../${res.id}`);
  } catch (error) {
    console.error('Error creating SCIM record', error);
    toast({ title: 'An error occurred, check your formatting' });
  }
  return null;
}

export async function samlUpdateAction({ request, params }: ActionFunctionArgs) {
  try {
    const workspace = await getWorkspaceFromParams(params);
    if (!workspace) throw new Error('Workspace does not exist');
    const { samlId } = z.object({ samlId: z.coerce.number() }).parse(params);
    const data = await request.json();
    const parsedData = z.object({ cert: z.string().optional() }).parse(data);

    if (request.method === 'POST') {
      // Accept the request entry
      const res = (await apiClient.patch(
        `/api/v4/workspaces/${workspace.id}/settings/saml/${samlId}`,
        parsedData,
      )) as Saml;

      await queryClient.invalidateQueries(samlListQuery(workspace.id));
      return res;
    }

    if (request.method === 'DELETE') {
      // Delete the request entry
      const res = (await apiClient.delete(
        `/api/v4/workspaces/${workspace.id}/settings/saml/${samlId}`,
      )) as Saml;
      // Invalidate the requests list
      await queryClient.invalidateQueries(samlListQuery(workspace.id));
      return res;
    }
  } catch (error) {
    console.error('Error creating SCIM record', error);
    toast({ title: 'An error occurred, check your formatting' });
  }
  return null;
}
