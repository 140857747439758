import type { AnalyticsSnippet } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { apiClient } from '@f4s/api-client';
import { type AnalyticsSchema, type EventName } from '@f4s/types/analytics';

import { logError } from '@/lib/log-error';

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
    clarity: (command: string, ...args: Array<string | null>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    braze?: any;
  }
}

export const sendEvent = <K extends EventName>(
  eventName: K,
  payload: AnalyticsSchema<K>,
) => {
  if (eventName.startsWith('trigger_') && 'braze' in window) {
    window.braze.logCustomEvent(eventName, payload);
  }
  apiClient
    .post(`/api/v3/public/analytics`, { eventName, payload })
    .catch((error) => logError(error));
};

// Call this when a user is logged in.
export const analyticsSetUser = (user: { id: number; emailAddress: string } | null) => {
  let attempts = 1;
  const linkUsers = () => {
    try {
      if (user == null) {
        // Logout
        Sentry.setUser(null);
        window.analytics.reset();
        window.amplitude.reset();
        window.clarity?.('identify', null);
        return;
      }
      attempts += 1;
      const { id, emailAddress } = user;
      const idString = id.toString();

      Sentry.setUser({ id: idString, email: emailAddress });

      window.analytics.identify(idString);
      window.amplitude.setUserId(idString);

      window.clarity?.('identify', idString);

      // Test setting various custom tags to see what goes through
      window.clarity?.('set', 'customUserID', idString);
      window.clarity?.('set', 'userID', idString);
      window.clarity?.('set', 'f4sID', idString);
    } catch (error) {
      if (attempts <= 3) setTimeout(linkUsers, 1000);
      else {
        logError(error, {
          tags: { module: 'analytics' },
          user: { id: user?.id, email: user?.emailAddress },
        });
      }
    }
  };
  linkUsers();
};

export function useTrackPageviews() {
  const location = useLocation();
  const isAnalyticsLoaded = !!window.analytics;
  useEffect(() => {
    if (window.analytics) window.analytics.page();
  }, [isAnalyticsLoaded, location.pathname]);
  return null;
}
