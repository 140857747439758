// Implementation adapted from ky's HTTPError
// https://github.com/sindresorhus/ky/blob/3ba40cc6333cf1847c02c51744e22ab7c04407f5/source/errors/HTTPError.ts

export class HTTPError extends Error {
  code: number;
  method: string;
  request: Request;
  response: Response;

  constructor({ request, response }: { request: Request; response: Response }) {
    const code = response.status ?? 0;
    const title = response.statusText || '';
    const status = `${code} ${title}`.trim();
    const method = request.method;
    const reason = status ? `status code ${status}` : 'an unknown error';

    super(`Request failed with ${reason}: ${method} ${request.url}`);

    this.name = 'HTTPError';
    this.code = code;
    this.method = method;
    this.request = request;
    this.response = response;
  }
}

export class NotLoggedInError extends Error {}
