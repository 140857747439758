import { cn } from '../lib/utils';

export const Divider = ({ text, className }: { text?: string; className?: string }) => {
  return (
    <div
      className={cn(
        'border-primary relative flex w-full items-center justify-center',
        className,
      )}
    >
      <hr className="border-border/5 w-full" />
      {text && (
        <>
          <div className="text-nowrap px-4">{text}</div>
          <hr className="border-border/5 w-full" />
        </>
      )}
    </div>
  );
};
