import type { LoaderFunctionArgs } from 'react-router-dom';

import { rootLoader } from '../root/loaders';
import { fetchWorkspaces } from '../workspace/queries';

export const hubLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const { user } = rootLoaderResponse;
  const workspaces = await fetchWorkspaces();

  return { user, workspaces };
};
