import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import type {
  MotivationGroupSlug,
  MotivationPattern,
  Report,
  ReportMinimal,
  ReportSection,
} from '@f4s/types';

export type MotivationReport = Omit<Report, 'type' | 'sections'> & {
  type: 'my_results';
  sections: MotivationReportSection[];
};

type MotivationReportSection = Omit<
  ReportSection,
  'type' | 'motivationGroupId' | 'motivationGroup'
> & {
  type: 'motivation_group';
  motivationGroupId: number;
  motivationGroup: {
    slug: MotivationGroupSlug;
    name: string;
    motivations: { id: number; code: MotivationPattern }[];
  };
  status?: 'answered' | 'next' | 'available' | 'pending';
  isNext?: boolean;
};

export const reportsQuery = ({ workspaceId }: { workspaceId?: number } = {}) => ({
  queryKey: ['reports', 'workspace', workspaceId],
  queryFn: async () =>
    apiClient.get(
      workspaceId ? `/api/v4/workspaces/${workspaceId}/reports` : '/api/v4/reports',
    ) as Promise<ReportMinimal[]>,
});
export const fetchReports = ({ workspaceId }: { workspaceId?: number } = {}) =>
  queryClient.fetchQuery(reportsQuery({ workspaceId }));
export const useReports = ({ workspaceId }: { workspaceId?: number } = {}) =>
  useQuery(reportsQuery({ workspaceId }));

export const reportQuery = ({
  reportId,
  workspaceId,
}: {
  reportId: number;
  workspaceId?: number;
}) => ({
  queryKey: ['report', reportId],
  queryFn: async () =>
    apiClient.get(
      workspaceId
        ? `/api/v4/workspaces/${workspaceId}/reports/${reportId}`
        : `/api/v4/reports/${reportId}`,
    ) as Promise<Report>,
});
export const fetchReport = ({
  reportId,
  workspaceId,
}: {
  reportId: number;
  workspaceId?: number;
}) => queryClient.fetchQuery(reportQuery({ reportId, workspaceId }));
