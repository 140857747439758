import { matchPath, redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { shuffle } from '@/lib/utils';
import {
  fetchPublicQueries,
  fetchPublicQueriesRaw,
  fetchQueries,
  type QueryTemplate,
} from '@/modules/queries/queries';

import { loginLoader } from '../login/loader';
import {
  checkSignupStarted,
  getOnboardingJtbdQueryIds,
  setOnboardingJtbdQueryIds,
  setOnboardingJtbdSlug,
} from '../utils';
import { fetchJtbdCategories } from './queries';

export const signupIndexLoader = async () => {
  const user = await authProvider.getMaybeUser();
  if (user) return redirect('/onboarding');

  const referredQueryPrompt = window.localStorage.getItem('seoReferredQuery');
  if (referredQueryPrompt) {
    const queries = await fetchPublicQueriesRaw();
    const referredQuery = queries.find((q) => q.prompt === referredQueryPrompt);
    if (referredQuery) {
      if (referredQuery.questionType && referredQuery.questionType !== 'debrief') {
        setOnboardingJtbdSlug(referredQuery.questionType);
      }
      setOnboardingJtbdQueryIds([referredQuery.id]);
      return redirect('/signup/create');
    }
  }
  const categories = await fetchJtbdCategories();

  checkSignupStarted({ signUpStep: 'jtbd_intro' });
  return { categories };
};

export const signupLoader = async ({ request }: LoaderFunctionArgs) => {
  const user = await authProvider.getMaybeUser();
  const categories = await fetchJtbdCategories();
  const { pathname } = new URL(request.url);
  const { jtbd: matchedJtbd } =
    matchPath(`${import.meta.env.BASE_URL}/signup/:jtbd`, pathname)?.params ?? {};

  const category = categories.find((c) => (c.path ?? c.slug) === matchedJtbd);
  let queries: QueryTemplate[] = [];
  if (category) {
    const allQueries = await fetchPublicQueries();
    queries = shuffle(allQueries.filter((q) => q.questionType === category.slug));
  }

  let selectedQueryIds = getOnboardingJtbdQueryIds();
  if (user) {
    const savedQueries = await fetchQueries();
    const favorites = savedQueries.flatMap((q) => (q.favourites.length > 0 ? q.id : []));
    selectedQueryIds = [...new Set([...selectedQueryIds, ...favorites])];
  } else {
    checkSignupStarted({ signUpStep: `jtbd_${category?.slug ?? 'unknown'}` });
  }

  return {
    user,
    categories,
    category,
    queries,
    selectedQueryIds,
  };
};

export const signupCreateLoader = async () => {
  const res = await loginLoader();
  if (res instanceof Response) return res;

  checkSignupStarted({ signUpStep: 'jtbd_intro' });
  return res;
};

export const signupCompleteLoader = async () => {
  const user = await authProvider.getMaybeUser();
  return redirect(user ? '/onboarding' : '/signup/create');
};
