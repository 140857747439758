import { useQuery } from '@tanstack/react-query';

import { queryClient } from '@f4s/api-client';

import { useLocalPreference } from '@/providers/local-preference';

let timeout: ReturnType<typeof setTimeout> | undefined;

export const themes = {
  'system': { name: 'System', classNames: ['system'] },
  'sand-serif': { name: 'Sand-Serif', classNames: ['sand-serif'] },
  'sand-serif-dark': { name: 'Sand-Serif Dark', classNames: ['sand-serif', 'dark'] },
  'light': { name: 'Light', classNames: ['light'] },
  'dark': { name: 'Dark', classNames: ['dark'] },
  'lofi': { name: 'Lo-Fi', classNames: ['lofi', 'sand-serif'] },
};
export type ThemeKey = keyof typeof themes;
const themeClassNames = Object.values(themes).flatMap((t) => t.classNames);

export const lofiThemePreferences = {
  lofiNav: { preferenceName: 'lofiNav', defaultValue: false },
  lofiButtons: { preferenceName: 'lofiButtons', defaultValue: false },
  lofiAvatars: { preferenceName: 'lofiAvatars', defaultValue: false },
  lofiHome: { preferenceName: 'lofiHome', defaultValue: false },
  lofiQueries: { preferenceName: 'lofiQueries', defaultValue: false },
  lofiBoards: { preferenceName: 'lofiBoards', defaultValue: false },
  lofiBoard: { preferenceName: 'lofiBoard', defaultValue: false },
  lofiBoardDetail: { preferenceName: 'lofiBoardDetail', defaultValue: false },
  lofiCoaching: { preferenceName: 'lofiCoaching', defaultValue: false },
  lofiCoachingProgram: { preferenceName: 'lofiCoachingProgram', defaultValue: false },
  lofiSettings: { preferenceName: 'lofiSettings', defaultValue: false },
};

export const useLofiThemePreferences = () => {
  const lofiNav = useLocalPreference(lofiThemePreferences.lofiNav);
  const lofiButtons = useLocalPreference(lofiThemePreferences.lofiButtons);
  const lofiAvatars = useLocalPreference(lofiThemePreferences.lofiAvatars);
  const lofiHome = useLocalPreference(lofiThemePreferences.lofiHome);
  const lofiQueries = useLocalPreference(lofiThemePreferences.lofiQueries);
  const lofiBoards = useLocalPreference(lofiThemePreferences.lofiBoards);
  // const lofiBoardsDetail = useLocalPreference(lofiThemePreferences.lofiBoardDetail);
  const lofiBoard = useLocalPreference(lofiThemePreferences.lofiBoard);
  const lofiCoaching = useLocalPreference(lofiThemePreferences.lofiCoaching);
  const lofiCoachingProgram = useLocalPreference(
    lofiThemePreferences.lofiCoachingProgram,
  );
  const lofiSettings = useLocalPreference(lofiThemePreferences.lofiSettings);

  return {
    lofiNav,
    lofiButtons,
    lofiAvatars,
    lofiHome,
    lofiQueries,
    lofiBoards,
    lofiBoard,
    lofiCoaching,
    lofiCoachingProgram,
    lofiSettings,
  };
};

export const handleThemeChange = (themeKey: ThemeKey) => {
  // TODO: Fix sand-serif darkmode
  // const newTheme =
  //   (themeKey === 'system'
  //     ? window.matchMedia('(prefers-color-scheme: dark)')?.matches
  //       ? themes['sand-serif-dark']
  //       : themes['sand-serif']
  //     : themes[themeKey]) ?? themes['sand-serif'];

  // For now always use sand-serif for system default
  const newTheme = themeKey === 'system' ? themes['sand-serif'] : themes[themeKey];
  if (!newTheme) return;

  document.documentElement.classList.add('theme-transition');
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    document.documentElement.classList.remove('theme-transition');
  }, 1000);

  const toRemove = themeClassNames.filter((c) => !newTheme.classNames.includes(c));
  document.documentElement.classList.remove(...toRemove);
  document.documentElement.classList.add(...newTheme.classNames);

  if (themeKey === 'lofi') {
    Object.keys(lofiThemePreferences).forEach((key) => {
      const prefValue = localStorage.getItem(
        lofiThemePreferences[key as keyof typeof lofiThemePreferences].preferenceName,
      );
      if (prefValue === 'true') {
        document.documentElement.classList.add(key);
      } else {
        document.documentElement.classList.remove(key);
      }
    });
  }

  localStorage.setItem('theme', themeKey);
  queryClient.setQueryData(['theme'], themeKey);
  queryClient.setQueryData(['darkMode'], newTheme.classNames.includes('dark'));
};

export const themeListener = (event: StorageEvent) => {
  if (event.key === 'theme') {
    handleThemeChange(event.newValue as ThemeKey);
  }
};

export const prefersColorSchemeListener = (event: MediaQueryListEvent) => {
  // Only recompute the 'system' theme
  if (event.matches && localStorage.getItem('theme') === 'system') {
    handleThemeChange('system');
  }
};

export const useTheme = () =>
  useQuery({
    queryKey: ['theme'],
    queryFn: () => localStorage.getItem('theme') as ThemeKey | null,
    initialData: localStorage.getItem('theme') as ThemeKey | null,
  }).data;

export const useDarkMode = () =>
  useQuery({
    queryKey: ['darkMode'],
    queryFn: () => {
      const themeKey = localStorage.getItem('theme') as ThemeKey | null;
      return themeKey ? themes[themeKey]?.classNames.includes('dark') : false;
    },
    initialData: () => {
      const themeKey = localStorage.getItem('theme') as ThemeKey | null;
      return themeKey ? themes[themeKey]?.classNames.includes('dark') : false;
    },
  }).data;
