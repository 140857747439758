import { type ReactNode } from "react";

type SectionHeaderProps = {
  title: string;
  actions?: ReactNode;
  description?: string;
};
export function SectionHeader({ title, actions, description }: SectionHeaderProps) {
  return (
    <header className="w-full space-y-2">
      <div className="w-full flex justify-between items-center">
        <h2 className="font-serif text-lg font-semibold tracking-tight">{title}</h2>
        <div>{actions}</div>
      </div>
      {description && <p className="prose text-muted-foreground text-sm">{description}</p>}
    </header>
  );
}
