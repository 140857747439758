import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { membersRoutes } from '../member/routes';
import { underlyingDataChildRouter } from '../motivation/routes';
import { teamCreateAction, teamModifyAction } from './actions';
import { teamCreateLoader, teamListLoader, teamLoader } from './loaders';
import { TeamBreadcrumb } from './pages/handle';

export const teamRoutes: RouteObject = {
  path: 'teams',
  handle: handle({ type: 'teams', breadcrumb: SimpleBreadcrumb({ title: 'Teams' }) }),
  loader: teamListLoader,
  children: [
    {
      index: true,
      async lazy() {
        const { Teams } = await import('./pages/list');
        return { Component: Teams };
      },
      loader: teamListLoader,
    },
    {
      async lazy() {
        const { Teams } = await import('./pages/list');
        return { Component: Teams };
      },
      loader: teamListLoader,
      children: [
        {
          path: 'create',
          async lazy() {
            const { CreateTeam: CreateWorkspace } = await import('./pages/create');
            return { Component: CreateWorkspace };
          },
          loader: teamCreateLoader,
          action: teamCreateAction,
          handle: { crumb: { title: 'Create' } },
        },
      ],
    },
    {
      path: ':teamId',
      loader: teamLoader,
      handle: handle({ type: 'team', breadcrumb: TeamBreadcrumb }),
      children: [
        {
          index: true,
          loader: teamLoader,
          action: teamModifyAction,
          async lazy() {
            const { TeamProfilePage } = await import('./pages/page');
            return { Component: TeamProfilePage };
          },
        },
        {
          loader: teamLoader,
          async lazy() {
            const { TeamProfilePage } = await import('./pages/page');
            return { Component: TeamProfilePage };
          },
          children: [
            {
              path: 'add-members',
              loader: teamLoader,
              action: teamModifyAction,
              async lazy() {
                const { TeamMemberAdd } = await import('./pages/add');
                return { Component: TeamMemberAdd };
              },
            },
            { path: 'motivation', children: underlyingDataChildRouter },
          ],
        },
        {
          async lazy() {
            const { TeamLayout } = await import('./pages/layout');
            return { Component: TeamLayout };
          },
          loader: teamLoader,
          children: [membersRoutes],
        },
      ],
    },
  ],
};
