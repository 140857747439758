import * as React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';

import { cn } from '../lib/utils';

const alertVariants = tv({
  slots: {
    base: 'border-border/5 relative flex w-full flex-row gap-3 rounded-lg border px-4 py-3 text-sm',
    icon: 'flex aspect-square h-full max-h-5 max-w-5 items-center justify-center rounded-md',
  },
  variants: {
    variant: {
      default: {
        base: 'bg-background text-foreground',
        icon: 'bg-transparent',
      },
      destructive: {
        base: 'border-destructive/50 text-destructive-foreground dark:border-destructive [&>svg]:text-destructive dark:[&>svg]:text-destructive-foreground',
        icon: 'bg-transparent',
      },
      info: {
        base: 'bg-elderberry-50 dark:bg-elderberry-900 text-elderberry-800 dark:text-elderberry-200',
        icon: 'dark:bg-elderberry-800',
      },
      ghost: {
        base: 'text-foreground text-muted-foreground gap-1 border-none bg-transparent p-0',
        icon: 'bg-transparent',
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

declare global {
  type AlertVariantsType = typeof alertVariants;
}

export interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<AlertVariantsType> {
  icon: React.ReactNode;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, variant, icon, children, ...props }, ref) => {
    const styles = alertVariants({ variant });

    return (
      <div ref={ref} role="alert" className={cn(styles.base(), className)} {...props}>
        {icon && <div className={styles.icon()}>{icon}</div>}
        {children}
      </div>
    );
  },
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('mb-1 font-medium leading-none tracking-tight', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription, alertVariants };
