import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { apiClient, authProvider } from '@f4s/api-client';

import { fetchAdminUserDetails } from './queries';

export const adminLoader = async () => {
  const admin = await authProvider.getMaybeUser();
  if (!admin?.roles.includes('global_admin')) return redirect('/');

  return { admin };
};

export const adminUserListLoader = async () => {
  return {};
};

export const adminUserLoader = async ({ params }: LoaderFunctionArgs) => {
  const { userId } = params as { userId: string };
  // Fetch user details
  const userDetails = await fetchAdminUserDetails(userId);
  return { userDetails };
};

export const unmasqueradeLoader = async () => {
  const previousUser = await authProvider.getUser();
  const response = await apiClient.get(`/api/v4/users/unmasquerade`);
  const user = await authProvider.setUser({ response });
  if (user?.roles.includes('global_admin')) {
    return redirect(`/admin/users/${previousUser.id}`);
  }
  return redirect('/');
};
