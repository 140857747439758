import { dashboardSubRoutes } from '@/modules/dashboard/routes';
import { PublicLayout } from '@/modules/public-profile/page/layout';
import { ErrorPage } from '@/modules/root/pages/error';

import { publicProfileLoader, publicResourceLoader } from './loaders';

export const publicProfileRoutes = {
  path: 'users/:userId',
  ErrorBoundary: ErrorPage,
  Component: PublicLayout,
  children: [
    {
      loader: publicProfileLoader,
      async lazy() {
        const { Profile } = await import('./page/profile');
        return { Component: Profile };
      },
      index: true,
    },
    {
      path: 'report/:code',
      async lazy() {
        const { PublicReport } = await import('@/modules/report/pages/public');
        return { Component: PublicReport };
      },
      loader: publicResourceLoader('report'),
    },
    {
      path: 'dashboard/:code',
      ...dashboardSubRoutes,
      async lazy() {
        const { PublicDashboardPage } = await import('@/modules/dashboard/pages/public');
        return { Component: PublicDashboardPage };
      },
      loader: publicResourceLoader('dashboard'),
    },
  ],
};
