import { useCallback } from 'react';

import { SelectableButton } from '@/components/selectable-button';
import type { QueryTemplate } from '@/modules/queries/queries';

export const QuerySelectionButtons = ({
  queries,
  selected,
  setSelected,
}: {
  queries: QueryTemplate[];
  selected: number[];
  setSelected: (values: number[]) => void;
}) => {
  const handleClick = useCallback(
    (queryId: number) => {
      if (selected.includes(queryId)) setSelected(selected.filter((q) => q !== queryId));
      else setSelected([...(selected ?? []), queryId]);
    },
    [selected, setSelected],
  );

  return (
    <div className="flex max-w-full flex-col gap-2">
      {queries.map((query) => (
        <SelectableButton
          withCheckbox
          isSelected={selected.includes(query.id) ?? false}
          onClick={() => {
            handleClick(query.id);
          }}
          title={query.prompt.replaceAll(/{{(\w+)}}/g, (_, word) => `@${word}`)}
          value={query.id}
          key={query.id}
          className="justify-start"
          testId="onboarding-jtbd-query-button"
        />
      ))}
    </div>
  );
};
