import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button, cn, Icon, ScrollArea } from '@f4s/ui';

import MarleeLogo from '@/assets/marlee-logo.svg?react';
import MarleeLogomark from '@/assets/marlee-logomark.svg?react';
import { NavLink } from '@/components/nav-link';
import { Page } from '@/components/page';
import { clearCache } from '@/lib/cache-clear';
import { AskMarleePopup } from '@/modules/ask-marlee/components/popup';
import { Credits } from '@/modules/credits/components/credits';
import { useShowMigrationHub } from '@/modules/hubs/components/migrate-buttons';
import { WorkspaceSwitcher } from '@/modules/workspace/components/workspace-switcher';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { type Workspace } from '@/modules/workspace/queries';
import { useFeatureFlag } from '@/providers/feature-flags';

import { useSettingsMatch } from '../hooks/use-settings-match';
import { NavItem } from './nav-item';

export const NavHeader = ({
  isCollapsed,
  hideCollapseButton,
  onClick,
  className,
  clickOut = true,
}: {
  isCollapsed?: boolean;
  hideCollapseButton?: boolean;
  onClick?: () => void;
  className?: string;
  clickOut?: boolean;
}) => {
  const logo = isCollapsed ? (
    <MarleeLogomark className="text-elderberry-950 -mr-0.5 ml-0.5 h-7 w-auto dark:text-white" />
  ) : (
    <MarleeLogo className="text-elderberry-950 h-7 w-auto pl-0.5 dark:text-white" />
  );

  return (
    <header
      className={cn(
        'flex shrink-0 items-center justify-between gap-1 pr-1.5',
        !isCollapsed && 'lofi:lg:w-[200px] lg:w-[260px]',
        isCollapsed && 'w-9',
        className,
      )}
    >
      {clickOut ? (
        <Link to="/" className={cn('flex items-center justify-start')}>
          {logo}
        </Link>
      ) : (
        <div className={cn('flex items-center justify-start')}>{logo}</div>
      )}
      {!isCollapsed && !hideCollapseButton && (
        <Button
          variant="link"
          size="icon"
          onClick={onClick}
          className="lofi:lg:hidden hidden h-6 w-6 opacity-60 hover:opacity-100 lg:flex"
        >
          <Icon.Sidebar weight="duotone" size={18} />
        </Button>
      )}
    </header>
  );
};

export const NavAside = ({
  isCollapsed,
  className,
  children,
}: {
  isCollapsed?: boolean;
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <aside
      className={cn(
        'sticky top-16 z-50 hidden h-full max-h-[calc(100dvh_-_64px)] min-h-[calc(100dvh_-_64px)] shrink-0 flex-col py-4 pt-2 lg:py-5',
        'lofiNav:[&_*]:font-redacted lofiNav:[&_*]:text-muted-foreground',
        className,
      )}
    >
      <nav
        className={cn(
          'lofi:w-[200px] flex h-full min-h-0 w-[260px] flex-1 flex-col gap-5',
          isCollapsed && 'w-9',
        )}
      >
        {children}
      </nav>
    </aside>
  );
};

export const MyMigrationStuff = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const shouldShow = useShowMigrationHub();
  if (!shouldShow) return null;

  return (
    <NavLink to="/migrate/connections" className="mt-2">
      <Button
        variant="secondary"
        className={cn(
          'w-full justify-start !pl-1.5 pr-2 shadow-[0_0_0_2px_black] outline-dashed outline-2 outline-yellow-500 hover:shadow-[0_0_0_2px_black]',
          isCollapsed && 'w-full pr-1.5',
        )}
      >
        <div className="flex h-6 w-6 shrink-0 items-center justify-center">🚧</div>
        {/* if !isCollapsed, show the text and icon */}
        {!isCollapsed && (
          <>
            <span className="flex-1 text-left">Complete migration</span>
            <Icon.ArrowRight size={16} weight="bold" className="shrink-0" />
          </>
        )}
      </Button>
    </NavLink>
  );
};

export const NavFooter = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const useTalentHub = useFeatureFlag('MarleeWeb.Talent.Hub');

  return (
    <footer className={cn('flex flex-col')}>
      <NavItem
        to="/feedback"
        preventDefault
        onClick={() => window.Userback?.open()}
        title="Feedback & Support"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.Lifebuoy size={18} weight="duotone" />}
        endOnly
      />
      {useTalentHub && (
        <NavItem
          to="/talent"
          title="Talent Hub"
          isCollapsed={isCollapsed}
          IconComponent={<Icon.ShootingStar size={18} weight="duotone" />}
        />
      )}
    </footer>
  );
};

export const WorkspaceNavItems = ({
  basePath,
  isCollapsed,
}: {
  basePath: string;
  isCollapsed?: boolean;
}) => {
  return (
    <>
      <NavItem
        to={basePath}
        title="Home"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.Home size={18} weight="duotone" />}
        endOnly
      />
      {/* <NavItem
        to={`${basePath}/my-results`}
        title="My Results"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.SlidersHorizontal size={18} weight="duotone" />}
      /> */}
      <NavItem
        to={`${basePath}/boards`}
        title="Boards"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.SquaresFour size={18} weight="duotone" />}
      />
      <NavItem
        to={`${basePath}/queries`}
        title="Queries"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.Sparkles size={18} weight="duotone" />}
      />
      <NavItem
        to={`${basePath}/people`}
        title="People & Teams"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.Users size={18} weight="duotone" />}
      />
      <NavItem
        to={`${basePath}/coaching`}
        title="Coaching"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.Trophy size={18} weight="duotone" />}
      />
    </>
  );
};

export const WorkspaceNav = ({
  basePath,
  isCollapsed,
}: {
  basePath: string;
  isCollapsed?: boolean;
}) => {
  return (
    <>
      <div className="flex flex-1 flex-col">
        <WorkspaceNavItems basePath={basePath} isCollapsed={isCollapsed} />
        <NavItem
          to={`${basePath}/settings`}
          title="Settings"
          isCollapsed={isCollapsed}
          IconComponent={<Icon.Gear size={18} weight="duotone" />}
        />
      </div>
    </>
  );
};

export const AskMarleeSection = ({
  isCollapsed,
  hideCredits,
}: {
  isCollapsed?: boolean;
  hideCredits?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      <div className="z-50">
        <AskMarleePopup isCollapsed={isCollapsed} />
      </div>
      <div className="-mt-2 px-0">
        {!hideCredits && (
          <Credits type="askmarlee" isCollapsed={isCollapsed} className="h-auto pt-4" />
        )}
      </div>
    </div>
  );
};

export const SettingsNavWorkspaceItems = ({
  basePath,
  isCollapsed,
}: {
  basePath: string;
  isCollapsed?: boolean;
}) => {
  const useTalentHub = useFeatureFlag('MarleeWeb.Talent.Hub');
  const useModeling = useFeatureFlag('MarleeWeb.Modeling');
  return (
    <>
      <NavItem
        to={`${basePath}/settings/details`}
        title="Details"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.ImageSquare size={18} weight="duotone" />}
        endOnly
      />
      <NavItem
        to={`${basePath}/settings/preferences`}
        title="Preferences"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.SlidersHorizontal size={18} weight="duotone" />}
        endOnly
      />
      <NavItem
        to={`${basePath}/settings/billing`}
        title="Billing"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.CreditCard size={18} weight="duotone" />}
        endOnly
      />
      {useModeling && (
        <NavItem
          to={`${basePath}/settings/modeling`}
          title="Models"
          isCollapsed={isCollapsed}
          IconComponent={<Icon.Sliders2 size={18} weight="duotone" />}
          endOnly
        />
      )}
      {useTalentHub && (
        <NavItem
          to={`${basePath}/settings/talent`}
          title="Talent"
          isCollapsed={isCollapsed}
          IconComponent={<Icon.ShootingStar size={18} weight="duotone" />}
          endOnly
        />
      )}
    </>
  );
};

export const SettingsNavAccountItems = ({
  basePath,
  isCollapsed,
}: {
  basePath: string;
  isCollapsed?: boolean;
}) => {
  return (
    <>
      <NavItem
        to={`${basePath}/account/profile`}
        title="Account"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.UserCircle size={18} weight="duotone" />}
        endOnly
      />
      <NavItem
        to={`${basePath}/account/preferences`}
        title="Preferences"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.SlidersHorizontal size={18} weight="duotone" />}
        endOnly
      />
      {/* <NavItem
        to={`${basePath}/account/billing`}
        title="Billing"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.CreditCard size={18} weight="duotone" />}
        endOnly
      /> */}
    </>
  );
};

export const SettingsNav = ({
  basePath,
  isCollapsed,
  workspace,
}: {
  basePath: string;
  isCollapsed?: boolean;
  workspace?: Workspace;
}) => {
  return (
    <div className="flex flex-1 flex-col gap-6">
      <NavItem
        to={basePath}
        title="Back to app"
        isCollapsed={isCollapsed}
        IconComponent={<Icon.CaretLeft size={18} />}
        endOnly
      />

      {workspace && workspace.isAdmin && (
        <>
          <div className="flex flex-col">
            <div className="text-foreground/60 flex items-center gap-3 truncate">
              <span
                className={cn(
                  'mb-2 truncate pl-2 text-xs font-medium',
                  isCollapsed && 'hidden',
                )}
              >
                {workspace.name}
              </span>
            </div>
            <SettingsNavWorkspaceItems basePath={basePath} isCollapsed={isCollapsed} />
          </div>
        </>
      )}
      <div className="flex flex-1 flex-col">
        <div className="text-muted-foreground flex items-center gap-3 truncate">
          <span
            className={cn(
              'mb-2 truncate pl-2 text-xs font-medium',
              isCollapsed && 'hidden',
            )}
          >
            My Account
          </span>
        </div>
        <SettingsNavAccountItems basePath={basePath} isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export const DesktopNav = ({
  isCollapsed,
  className,
}: {
  isCollapsed?: boolean;
  className?: string;
}) => {
  const { pathname, workspace } = useMatchedWorkspace();
  const showSettingsNav = useSettingsMatch();

  return (
    <NavAside isCollapsed={isCollapsed} className={className}>
      {showSettingsNav ? (
        <>
          <div className="relative flex min-h-0 flex-1 flex-col">
            <ScrollArea
              className="flex-1"
              viewportClassName="flex flex-1 h-full [&>div]:flex-1"
            >
              <SettingsNav
                workspace={workspace}
                isCollapsed={isCollapsed}
                basePath={pathname}
              />
              <NavFooter isCollapsed={isCollapsed} />
            </ScrollArea>
            <MyMigrationStuff isCollapsed={isCollapsed} />
          </div>
        </>
      ) : (
        <>
          <div className="flex">
            <WorkspaceSwitcher isCollapsed={isCollapsed} />
          </div>
          <AskMarleeSection
            isCollapsed={isCollapsed}
            hideCredits={pathname === '/migrate/wizard/'}
          />
          <div className="relative flex min-h-0 flex-1 flex-col">
            <ScrollArea
              className="flex-1"
              viewportClassName="flex flex-1 h-full [&>div]:flex-1"
            >
              {workspace ? (
                <WorkspaceNav isCollapsed={isCollapsed} basePath={pathname} />
              ) : null}
              <NavFooter isCollapsed={isCollapsed} />
            </ScrollArea>
            <MyMigrationStuff isCollapsed={isCollapsed} />
          </div>
        </>
      )}
    </NavAside>
  );
};

export const MobileNav = () => {
  const { pathname } = useMatchedWorkspace();

  return (
    <nav className="border-border/5 flex h-16 w-full items-center justify-between border-t p-1">
      <NavItem
        to={pathname}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={<Icon.Home size={20} weight="duotone" className="shrink-0" />}
      >
        <div className="text-2xs font-medium leading-3">Home</div>
      </NavItem>

      {/* <NavItem
        to={`${pathname}/my-results`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={
          <Icon.SlidersHorizontal size={20} weight="duotone" className="shrink-0" />
        }
      >
        <div className="text-2xs font-medium leading-3">My Results</div>
      </NavItem> */}

      <NavItem
        to={`${pathname}/boards`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={
          <Icon.SquaresFour size={20} weight="duotone" className="shrink-0" />
        }
      >
        <div className="text-2xs font-medium leading-3">Boards</div>
      </NavItem>

      <NavItem
        to={`${pathname}/queries`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={<Icon.Sparkles size={20} weight="duotone" className="shrink-0" />}
      >
        <div className="text-2xs font-medium leading-3">Queries</div>
      </NavItem>

      <NavItem
        to={`${pathname}/people`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={<Icon.Users size={20} weight="duotone" className="shrink-0" />}
      >
        <div className="text-2xs font-medium leading-3">People</div>
      </NavItem>

      <NavItem
        to={`${pathname}/coaching`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={<Icon.Trophy size={20} weight="duotone" className="shrink-0" />}
      >
        <div className="text-2xs font-medium leading-3">Coaching</div>
      </NavItem>

      <NavItem
        to={`${pathname}/more`}
        className="flex-col gap-2"
        isMobile
        disableActive
        IconComponent={<Icon.Ellipsis size={20} weight="duotone" className="shrink-0" />}
      >
        <div className="text-2xs font-medium leading-3">More</div>
      </NavItem>
    </nav>
  );
};

export const MobileMoreNav = () => {
  const { pathname, workspace } = useMatchedWorkspace();
  return (
    <Page>
      {workspace ? (
        <>
          <div className="flex w-full flex-col">
            <h2 className="text-muted-foreground mb-2 ml-1 text-xs font-medium">
              Workspace
            </h2>
            <WorkspaceNavItems basePath={pathname} />
          </div>
          <div className="flex w-full flex-col">
            <h2 className="text-muted-foreground mb-2 ml-1 text-xs font-medium">
              Settings
            </h2>
            <SettingsNavWorkspaceItems basePath={pathname} />
          </div>
        </>
      ) : null}

      <div className="flex w-full flex-col">
        <h2 className="text-muted-foreground mb-2 ml-1 text-xs font-medium">Account</h2>
        <SettingsNavAccountItems basePath={pathname} />
        <NavItem
          to="/clear-cache"
          preventDefault
          onClick={() => {
            clearCache().catch((error) => console.warn(error));
          }}
          title="Clear cache"
          IconComponent={<Icon.Trash size={18} weight="duotone" />}
        />
        <NavItem
          to="/logout"
          title="Sign out"
          IconComponent={<Icon.SignOut size={18} weight="duotone" />}
        />
      </div>
    </Page>
  );
};
