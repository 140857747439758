import { type RouteObject } from 'react-router-dom';

import { talentMatchLoader } from './loaders';

export const talentMatchRoutes: RouteObject = {
  path: 'match',
  handle: { crumb: { title: 'Talent Match' } },
  children: [
    {
      index: true,
      loader: talentMatchLoader,
      async lazy() {
        const { TalentMatch } = await import('./pages/page');
        return { Component: TalentMatch };
      },
    },
  ],
};
