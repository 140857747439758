import { useQuery } from '@tanstack/react-query';

import { authProvider, queryClient } from '@f4s/api-client';
import { formatName } from '@f4s/types';

import { fetchPersonalConnections } from '@/modules/_legacy/connections/queries';

import { fetchTeamList } from '../team/queries';
import { fetchDemoUsers } from '../user/queries';
import type { User } from '../user/schema';
import { useMatchedWorkspace } from '../workspace/hooks/use-workspace-match';
import { fetchWorkspace, type Workspace } from '../workspace/queries';

type BaseMentionData = {
  type: 'user' | 'selection' | 'team' | 'workspace';
  id: string;
  display: string;
  avatarUrl?: string | null;
};

export type MentionWorkspace = BaseMentionData & {
  type: 'workspace';
  workspace: Workspace;
};

export type MentionTeam = BaseMentionData & {
  type: 'team';
  team: { id: number; avatarUrl?: string | null };
  workspace?: { name: string };
};

export type MentionSelection = BaseMentionData & {
  type: 'selection';
  selection: { id: number; name: string; avatarUrl?: string | null };
  workspace?: { name: string };
};

export type MentionUser = BaseMentionData & {
  type: 'user';
  user: User;
  workspaces: Workspace[];
};

export type MentionSelf = { type: 'self' };

export type MentionData = MentionWorkspace | MentionTeam | MentionSelection | MentionUser;

export const setAutoAsk = (autoAsk: boolean) => {
  queryClient.setQueryData<boolean>(['askMarlee', 'autoAsk'], autoAsk);
};

export const fetchAndClearAutoAsk = () => {
  const autoAsk = queryClient.getQueryData<boolean>(['askMarlee', 'autoAsk']);
  if (autoAsk) {
    setAutoAsk(false);
  }
  return autoAsk;
};

export const suggestionsQuery = ({
  workspaceSlug,
}: { workspaceSlug?: string; teamSlug?: string } = {}) => ({
  queryKey: ['connections', 'suggestions', `workspace-${workspaceSlug}`],
  placeholderData: {
    connectedUsers: [],
    teams: [],
    workspaces: [],
  },
  queryFn: async (): Promise<{
    connectedUsers: MentionUser[];
    teams: MentionSelection[];
    workspaces: MentionWorkspace[];
  }> => {
    const user = await authProvider.getUser();
    const workspace = await fetchWorkspace(workspaceSlug);

    // TODO: Everything should be behind a workspace now
    // Don't waste time fetching in the personal space thats going away
    if (!workspace)
      return {
        connectedUsers: [],
        teams: [],
        workspaces: [],
      };

    const teams = workspace ? await fetchTeamList({ workspaceId: workspace.id }) : [];
    const teamSuggestions: MentionSelection[] = teams.map((t) => ({
      id: `selection-${t.id}`,
      type: 'selection' as const,
      selection: t,
      display: t.name,
      workspace: workspace ? { name: workspace.name } : undefined,
    }));

    const members = workspace ? workspace.members : [];
    const userSuggestions = members.flatMap((m) => {
      if (m.userId === user.id) return []; // Remove self from suggestions

      return {
        id: `user-${m.userId}`,
        display: m.user.fullName,
        avatarUrl: m.user.avatarUrl,
        type: 'user' as const,
        user: m.user,
        workspaces: [], // TODO: remove
      };
    });

    if (!workspace) {
      // TODO: Personal connections and demo users
      const personalConnections = await fetchPersonalConnections();
      const demoUsers = await fetchDemoUsers();
      userSuggestions.push(
        ...[...personalConnections, ...demoUsers].flatMap((demoUser) => ({
          id: `user-${demoUser.id}`,
          display: formatName(demoUser),
          avatarUrl: demoUser.avatarUrl,
          type: 'user' as const,
          user: demoUser,
          workspaces: [],
        })),
      );
    }

    return {
      connectedUsers: userSuggestions,
      teams: teamSuggestions,
      workspaces: workspace
        ? [
            {
              id: `workspace-${workspace.id}`,
              display: workspace.name,
              avatarUrl: workspace.avatarUrl,
              type: 'workspace' as const,
              workspace,
            },
          ]
        : [],
    };
  },
});
export const fetchSuggestions = (data: Parameters<typeof suggestionsQuery>[0] = {}) =>
  queryClient.fetchQuery(suggestionsQuery(data));
export const useSuggestions = (data: Parameters<typeof suggestionsQuery>[0] = {}) => {
  const match = useMatchedWorkspace();
  return useQuery(
    suggestionsQuery({
      workspaceSlug: data.workspaceSlug ?? match.workspace?.slug,
    }),
  );
};
