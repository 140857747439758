import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient, refetchEvery } from '@f4s/api-client';

import type { Invitation } from '../invites/queries';

export type Notification = {
  id: number;
  createdAt: string;
  readAt: string | null;
  message: string;
  invitation: Invitation | null;
  type:
    | 'org_invite'
    | 'org_invite_accepted'
    | 'org_access_admin_invite'
    | 'org_access_consultant_invite'
    | 'org_access_support_invite';
};

export const notificationsUnreadQuery = {
  queryKey: ['notifications', 'unread'],
  queryFn: async () =>
    apiClient.get(`/api/v3/notifications/unread`) as Promise<Notification[]>,
  ...refetchEvery({ minutes: 1 }),
};

export const notificationsQuery = {
  queryKey: ['notifications'],
  queryFn: async () => apiClient.get(`/api/v3/notifications`) as Promise<Notification[]>,
};

export const fetchNotifications = () => queryClient.fetchQuery(notificationsQuery);
export const useNotifications = () => useQuery(notificationsQuery);
