import { useCallback, useState } from 'react';

import { formatInitials, type ReportCreateRequestData } from '@f4s/types';
import { Button, Icon } from '@f4s/ui';

import { ProfileAvatarStack } from '@/components/avatar';
import { useCredits } from '@/modules/credits/queries';
import { useReportCreateAction } from '@/modules/report/actions';

import { CreditsDialog } from '../../credits/components/credits-dialog';
import { type QueriesByCategory } from '../queries';

const QueryListItem = ({
  query,
  category,
  onClick,
}: {
  query: QueriesByCategory[0]['queries'][0];
  category?: QueriesByCategory[0];
  onClick?: (item: QueriesByCategory[0]['queries'][0]) => void;
}) => {
  const persons = query.context.flatMap((c) => (c.type === 'user' ? c : []));
  const placeholders = query.context.filter((c) => c.type === 'placeholder');

  const avatars = persons.map((p) => ({
    avatarUrl: p.avatarUrl,
    initials: formatInitials(p),
  }));
  placeholders.forEach(() => {
    avatars.unshift({
      avatarUrl: undefined,
      initials: '＠',
    });
  });

  return (
    <Button
      variant="outline"
      className="h-auto p-4 shadow-none"
      key={query.id}
      onClick={() => {
        onClick?.(query);
      }}
    >
      <div className="flex w-full items-center gap-4">
        <div className="bg-muted rounded-full p-2">
          <Icon.ChatCircle />
        </div>
        <div className="flex w-full flex-col items-start">
          <div className="text-left">
            {query.prompt.replaceAll(/@\[(.*?)]\(.*?\)/g, '$1')}
          </div>
          <div className="text-muted-foreground">{category?.title}</div>
        </div>
        <div>
          <ProfileAvatarStack avatars={avatars} className="bg-gray-100" />
        </div>
      </div>
    </Button>
  );
};

export const QueryList = ({
  queryCategories,
  onClick,
}: {
  queryCategories: QueriesByCategory;
  onClick?: () => void;
}) => {
  const { data: credits } = useCredits('askmarlee');
  const [dialogOpen, setDialogOpen] = useState(false);

  const createReport = useReportCreateAction();

  const handleQueryClick = useCallback(
    (item: QueriesByCategory[0]['queries'][0]) => {
      if ((credits?.totalCredits ?? 0) <= 0) {
        setDialogOpen(true);
        return;
      }

      onClick?.();
      const placeholder: ReportCreateRequestData = item.context.some(
        (c) => c.type === 'placeholder',
      )
        ? { status: 'pending', pendingReason: 'invite' }
        : {};
      createReport({ ...placeholder, prompt: item.prompt, askMarleeTemplateId: item.id });
    },
    [credits?.totalCredits, onClick, createReport],
  );

  return (
    <div className="flex flex-col gap-2">
      <CreditsDialog
        source="query"
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        trigger={null}
      />
      <h2 className="mt-4">Templates</h2>
      {queryCategories.map((c) => (
        <div key={c.name} className="flex flex-col gap-2">
          {c.queries.map((q) => (
            <QueryListItem key={q.id} query={q} category={c} onClick={handleQueryClick} />
          ))}
        </div>
      ))}
    </div>
  );
};
