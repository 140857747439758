import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { rootLoader } from '../root/loaders';
import { fetchWorkspaces } from '../workspace/queries';
import { fetchPublicTalentPools } from './pool/queries';

export const hiringManagerDetailsLoader = async () => {
  const user = await authProvider.getUser();
  if (!user) return redirect('/');
  return { user };
};

export const talentHubIndexLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const { user } = rootLoaderResponse;
  const workspaces = await fetchWorkspaces();

  const talentPools = await fetchPublicTalentPools();
  return { user, talentPools, workspaces };
};
