import { useMemo } from 'react';

import { useMaybeUser } from '@f4s/api-client';
import { formatName } from '@f4s/types';
import { Button, cn } from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import {
  DropdownDrawer,
  type DropdownDrawerItemProps,
} from '@/components/dropdown-drawer';
import { clearCache } from '@/lib/cache-clear';
import { showIntercom } from '@/lib/intercom';
import { useJournals } from '@/modules/user/queries';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useFeatureFlag } from '@/providers/feature-flags';

export function UserNav({
  showName,
  className,
  size,
}: {
  showName?: boolean;
  className?: string;
  size?: 'default' | 'sm' | 'lg' | 'xl';
}) {
  const { user } = useMaybeUser();
  const { data: journals } = useJournals(!!user);
  const journalsFlag = useFeatureFlag('MarleeWeb.Journals.Download');
  const reassessmentFlag = useFeatureFlag('MarleeWeb.Reassessment');
  const { pathname: matchedPathname } = useMatchedWorkspace();

  const pathname = matchedPathname.replace(/\/+$/, '');

  const menuItems: DropdownDrawerItemProps[] = useMemo(
    () => [
      ...(user?.roles.includes('global_admin')
        ? [
            {
              key: 'admin',
              linkTo: '/admin',
              children: 'Admin Dashboard',
              variant: 'ghost',
              group: 'admin',
            } as const,
          ]
        : []),
      // TODO: Need to add correct link
      {
        key: 'my-profile',
        linkTo: `${pathname}/people/me`,
        children: 'My Profile & Results',
        variant: 'ghost',
        group: 'profile',
      },
      {
        key: 'account',
        linkTo: `${pathname}/account/profile`,
        children: 'Account',
        variant: 'ghost',
        group: 'account',
      },
      {
        key: 'preferences',
        linkTo: `${pathname}/account/preferences`,
        children: 'Preferences',
        variant: 'ghost',
        group: 'account',
      },
      // {
      //   key: 'billing',
      //   linkTo: `${pathname}/settings/billing/`,
      //   variant: 'ghost',
      //   children: 'Billing',
      //   group: 'account',
      // },
      ...(journalsFlag && (journals?.length ?? 0) > 0
        ? [
            {
              key: 'download-journal',
              variant: 'ghost',
              children: 'Download Journal',
              group: 'account',
              onClick: () => {
                window.location.href = '/api/v2/user/journals/download';
              },
            } as const,
          ]
        : []),
      ...(reassessmentFlag
        ? [
            {
              key: 'reassessment',
              variant: 'ghost',
              children: 'Re-take Motivational Analysis',
              linkTo: `${pathname}/assessment`,
              group: 'account',
            } as const,
          ]
        : []),
      {
        key: 'help-support',
        variant: 'ghost',
        children: 'Help & Support',
        group: 'support',
        onClick: () => {
          if (user) {
            showIntercom(user).catch((error) => console.warn(error));
          }
        },
      },
      {
        key: 'clear-cache',
        variant: 'ghost',
        children: 'Clear Cache',
        group: 'support',
        onClick: () => {
          clearCache().catch((error) => console.warn(error));
        },
      },
      {
        key: 'go-to-f4s',
        variant: 'ghost',
        linkTo: 'https://app.fingerprintforsuccess.com',
        children: 'Go to F4S',
        group: 'support',
      },
      {
        key: 'sign-out',
        variant: 'ghost',
        linkTo: '/logout',
        children: 'Sign out',
        group: 'navigation',
      },
    ],
    [pathname, user, journalsFlag, reassessmentFlag, journals?.length],
  );

  if (!user) return null;

  return (
    <DropdownDrawer
      asChild
      dropdownAlign="end"
      content={
        <div className="flex flex-col gap-1 px-2 py-1.5">
          <p className="text-sm font-medium">{formatName(user)}</p>
          <p className="text-muted-foreground truncate text-xs">{user.emailAddress}</p>
        </div>
      }
      dropdownClassName="gap-0 flex-shrink-0"
      linkClassName="w-full items-start"
      items={menuItems}
    >
      <Button
        variant="ghost"
        size="icon"
        width="auto"
        className={cn('relative justify-start gap-3 rounded-full', className)}
      >
        <ProfileAvatar avatarUrl={user.avatarUrl ?? undefined} user={user} size={size} />
        {showName && <span className="truncate">{formatName(user)}</span>}
      </Button>
    </DropdownDrawer>
  );
}
