import { DirectlyConnectedBadge } from '@/components/badge';
import type { BreadcrumbProps } from '@/components/breadcrumbs';
import { NavLink } from '@/components/nav-link';

export const ConnectionsBreadcrumb = ({ pathname }: BreadcrumbProps<unknown>) => {
  return (
    <NavLink to={pathname} className="flex items-center gap-2 truncate font-medium">
      <DirectlyConnectedBadge className="relative bottom-auto right-auto h-6 max-h-6 w-6 max-w-6" />
      Connections
    </NavLink>
  );
};
