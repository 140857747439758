import { z } from 'zod';

import { ReportSchema } from '../common/report';
import { DashboardShareSchema, ReportShareSchema } from '../common/share';

export const ShareReportResponseSchema = ReportShareSchema.extend({
  report: ReportSchema.omit({ id: true }),
  owner: z.object({
    user: z.object({
      id: z.number(),
      userId: z.number(),
      firstName: z.string(),
      lastName: z.string(),
      avatarUrl: z.string().nullable(),
      profileImageUrl: z.string().nullable(),
    }),
  }),
});

export type ShareReportResponse = z.infer<typeof ShareReportResponseSchema>;

export const ShareDashboardResponseSchema = DashboardShareSchema.extend({
  dashboard: z.any(),
  owner: z.object({
    user: z.object({
      id: z.number(),
      userId: z.number(),
      firstName: z.string(),
      lastName: z.string(),
      avatarUrl: z.string().nullable(),
      profileImageUrl: z.string().nullable(),
    }),
  }),
});

export type ShareDashboardResponse = z.infer<typeof ShareDashboardResponseSchema>;
