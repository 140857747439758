import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider, type MaybeUser } from '@f4s/api-client';

import { fetchAnswers } from '../assessment/queries';
import { checkAnalysisDeferred, setIsNew, setLoginMethod } from '../onboarding/utils';
import { postLoginActions } from './queries';

const BASE_URL_REGEX = new RegExp(`^${import.meta.env.BASE_URL}`);

// This check needs to be used both on /login and on all 'rootLoader' routes
export const getRedirect = (request: Request) => {
  const url = new URL(request.url);
  const redirectTo = url.searchParams.get('redirectTo') ?? url.searchParams.get('redir');
  const redirectUrl = redirectTo ? new URL(redirectTo, url) : null;

  // New signup via oauth - redirect to onboarding.
  const oauthLoginType = url.searchParams.get('oauthLogin');
  if (oauthLoginType) {
    setLoginMethod(oauthLoginType); // Save login method to localstorage
    if (url.searchParams.get('isNew')) {
      setIsNew(true);
      // Find where they were originally being redirected to
      // If part-way through onboarding continue, otherwise send them to the start of onboarding.
      if (url.pathname.includes('/onboarding')) {
        return undefined;
      }
      return '/onboarding';
    }
  }
  // Make sure to strip the base path off the redirect, otherwise our routing could break.
  return redirectUrl ? redirectUrl.pathname.replace(BASE_URL_REGEX, '') : undefined;
};

export const globalLoader = async ({ request }: LoaderFunctionArgs) => {
  let user: MaybeUser = null;
  try {
    user = await authProvider.getMaybeUser();
    if (!user) return { user };

    if (authProvider.shouldRunLoginActions()) {
      // Post login actions should be awaited to avoid races.
      await postLoginActions(user);
    }

    const redirectPath = getRedirect(request);
    if (redirectPath) return redirect(redirectPath);
  } catch (error) {
    console.error('Error in global loader', error);
  }

  return { user };
};

export const rootLoader = async (args: LoaderFunctionArgs) => {
  const response = await globalLoader(args);
  if (response instanceof Response) return response;
  const { user } = response;

  const answers = user ? await fetchAnswers() : [];
  const url = new URL(args.request.url);

  if (!user) {
    // Redirect to login
    const params = new URLSearchParams();
    params.set('redirectTo', url.pathname + url.search);
    throw redirect('/login?' + params.toString());
  }

  if (!user.firstName) {
    // Redirect to profile update
    const appPath = url.pathname.replace(BASE_URL_REGEX, '');
    if (appPath.startsWith('/talent')) {
      // We're in the talent hub, redirect to the talent onboarding analysis
      throw redirect('/onboarding/talent/profile');
    } else if (appPath.startsWith('/spaces')) {
      throw redirect('/onboarding/profile/update');
    }
  }

  if (answers.length < 40 && !checkAnalysisDeferred()) {
    const appPath = url.pathname.replace(BASE_URL_REGEX, '');
    // Redirect to onboarding assessment
    if (appPath.startsWith('/talent')) {
      // We're in the talent hub, redirect to the talent onboarding analysis
      throw redirect('/onboarding/talent/analysis');
    } else if (appPath.startsWith('/spaces')) {
      throw redirect('/onboarding/analysis');
    }
  }

  return { user };
};
