import { useCallback, type FC } from 'react';
import { useLocation } from 'react-router-dom';

import type { User } from '@f4s/api-client';
import { Card, cn, Icon, motivationDefinitions, type MotivationInfo } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { stddev } from '@/lib/utils';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { type WidgetWithData } from '../queries';

type WidgetProps = {
  widget: WidgetWithData;
  user?: User;
  isLoading?: boolean;
  darkMode?: boolean;
  detailView?: boolean;
};
export type WidgetFC = FC<WidgetProps>;
export type WidgetPreviewFC = FC<
  WidgetProps & {
    hideTitle?: boolean;
  }
>;
export type WidgetDescriptionFC = FC<
  WidgetProps & {
    disabled?: boolean;
    autoAsk?: boolean;
    onStart?: () => void;
    onDone?: () => void;
  }
>;

const FULL_MOTIVATION_COUNT = 48;

export const DefaultTitle: WidgetFC = ({ widget }) => {
  return typeof widget.title === 'string' ? widget.title : null;
};

export const DefaultSubtitle: WidgetFC = ({ widget }) => {
  return typeof widget.subtitle === 'string' ? widget.subtitle : null;
};

export const DefaultScore: WidgetFC = () => {
  return null;
};

export const DefaultVisualization: WidgetFC = () => {
  return null;
};

export const DefaultDescription: WidgetDescriptionFC = ({ widget }) => {
  return typeof widget.description === 'string' ? widget.description : null;
};

export const DefaultPreview: WidgetPreviewFC = ({ widget }) => {
  return (
    <div className="flex w-full flex-col">
      <div>{widget.title || 'Widget Title'}</div>
    </div>
  );
};

export const DefaultLockout: WidgetFC = ({ widget, isLoading }) => {
  if (isLoading) return null;
  const assessmentLink = getAssessmentNeeded({ widget, isLoading });

  if (!assessmentLink) return null;

  return (
    <Card className="bg-muted backdrop-blur-xs absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center">
      <AssessmentButton assessmentSlug={assessmentLink} />
    </Card>
  );
};

export type WidgetComponents = {
  Title?: typeof DefaultTitle;
  Subtitle?: typeof DefaultSubtitle;
  Score?: typeof DefaultScore;
  Visualization?: typeof DefaultVisualization;
  Description?: typeof DefaultDescription;
  Preview?: typeof DefaultPreview;
  Lockout?: typeof DefaultLockout;
};

export const calculateData = ({
  widget,
  isLoading,
}: {
  widget: WidgetWithData;
  isLoading?: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  if (isLoading || widget.widgetTemplate.type === 'separator') return [];

  let motivations: MotivationInfo[] = [...motivationDefinitions];
  let maxSortedSize = 5; // Use this for the slice size

  // Step 1: First-pass filter of what motivations should show
  // Filter motivations to a selection
  if (widget.motivationGroup) {
    motivations = motivations.filter((m) => m.group === widget.motivationGroup!.slug);
  }

  if (widget.data[0]?.selectionType === 'insight' && widget.datasets[0]?.model) {
    // If the first dataset is an insight, filter to only those motivations included on the insight definition
    const modelMotivations = widget.datasets[0]?.model.modelMotivations.map(
      (mm) => mm.motivation.code,
    );
    motivations = motivations.filter((m) => modelMotivations.includes(m.code));
    maxSortedSize = motivations.length;
  }

  // Handle top/bottom slider types
  if (
    widget.widgetTemplate.slug === 'slider-top-5' ||
    widget.widgetTemplate.slug === 'slider-bottom-5'
  ) {
    maxSortedSize = 5;
    // Use only the first dataset with values
    const firstDataset = widget.datasets.find((d) => d.userData || d.aggregateData);
    if (!firstDataset) return motivations;

    let sortedMotivations = [
      ...(firstDataset?.userData?.motivations ?? firstDataset?.aggregateData ?? []),
    ];
    if (motivations.length < FULL_MOTIVATION_COUNT) {
      // Motivations need to be pre-filtered before the sort.
      sortedMotivations = sortedMotivations.filter((sm) =>
        motivations.some((m) => sm.code === m.code),
      );
    }
    sortedMotivations.sort((a, b) => b.median - a.median);

    const motivationsToUse =
      widget.widgetTemplate.slug === 'slider-top-5'
        ? sortedMotivations.slice(0, maxSortedSize)
        : sortedMotivations.slice(-maxSortedSize).reverse();
    motivations = motivationsToUse.flatMap(
      (m) => motivations.find((mm) => mm.code === m.code) ?? [],
    );
  }

  if (
    widget.widgetTemplate.slug === 'slider-similar' ||
    widget.widgetTemplate.slug === 'slider-different'
  ) {
    maxSortedSize = 5;
    const datasetsWithScores = widget.datasets.filter(
      (d) => d.userData || d.aggregateData,
    );
    const benchmarkModel = widget.datasets.find(
      (d) =>
        d.definition?.selectionType &&
        ['insight', 'xfactor', 'role'].includes(d.definition.selectionType),
    )?.model;
    if (datasetsWithScores.length === 1) {
      // Find the differences between the user and the culture median (50)
      const firstDataset = datasetsWithScores[0]!;
      let sortedMotivations = [
        ...(firstDataset?.userData?.motivations ?? firstDataset?.aggregateData ?? []),
      ];
      if (motivations.length < FULL_MOTIVATION_COUNT) {
        // Motivations need to be pre-filtered before the sort.
        sortedMotivations = sortedMotivations.filter((sm) =>
          motivations.some((m) => sm.code === m.code),
        );
      }

      if (benchmarkModel) {
        // filter to the benchmark model motivations
        sortedMotivations = sortedMotivations.flatMap((sm) => {
          const modelMotivation = benchmarkModel.modelMotivations.find(
            (mm) => mm.motivation.code === sm.code,
          );
          if (!modelMotivation) return [];
          return {
            ...sm,
            sortIndex:
              (sm.median >= modelMotivation.greenLow &&
              sm.median <= modelMotivation.greenHigh
                ? 0
                : 100) +
              Math.abs(
                sm.median - (modelMotivation.greenLow + modelMotivation.greenHigh) / 2,
              ),
          };
        });
        // Sort against the benchmark
        sortedMotivations.sort((a, b) => b.sortIndex - a.sortIndex);
      } else {
        // Sort against the culture median
        sortedMotivations.sort(
          (a, b) => Math.abs(b.median - 50) - Math.abs(a.median - 50),
        );
      }

      const motivationsToUse =
        widget.widgetTemplate.slug === 'slider-different'
          ? sortedMotivations.slice(0, maxSortedSize)
          : sortedMotivations.slice(-maxSortedSize).reverse();

      motivations = motivationsToUse.flatMap(
        (m) => motivations.find((mm) => mm.code === m.code) ?? [],
      );
    } else {
      const diffMap = new Map<string, number[]>();

      // Group the median values together
      for (const dataset of datasetsWithScores) {
        if (!dataset.userData && !dataset.aggregateData) continue;
        for (const m of dataset.userData?.motivations ?? dataset.aggregateData ?? []) {
          // Skip if motivation isnt requried.
          if (
            motivations.length < FULL_MOTIVATION_COUNT &&
            !motivations.some((mm) => mm.code === m.code)
          )
            continue;
          const mapEntry = diffMap.get(m.code);
          if (mapEntry) {
            mapEntry.push(m.median);
          } else {
            diffMap.set(m.code, [m.median]);
          }
        }
      }

      let sortedMotivations = [...diffMap.entries()].flatMap(([code, arr]) => {
        const motivation = motivations.find((m) => m.code === code);
        if (!motivation) return [];
        return {
          code,
          arr,
          average: arr.reduce((p, c) => p + c, 0) / arr.length,
          stddev: stddev(arr),
          sortIndex: 0,
        };
      });

      if (benchmarkModel) {
        // filter to the benchmark model motivations
        sortedMotivations = sortedMotivations.flatMap((sm) => {
          const modelMotivation = benchmarkModel.modelMotivations.find(
            (mm) => mm.motivation.code === sm.code,
          );
          if (!modelMotivation) return [];

          return {
            ...sm,
            sortIndex:
              sm.arr.filter(
                (a) => a < modelMotivation.greenLow || a > modelMotivation.greenHigh,
              ).length *
                100 +
              Math.abs(
                sm.average - (modelMotivation.greenLow + modelMotivation.greenHigh) / 2,
              ),
          };
        });
        // Sort against the benchmark
        sortedMotivations.sort((a, b) => b.sortIndex - a.sortIndex);
      } else {
        // Sort by stddev
        sortedMotivations.sort((a, b) => b.stddev - a.stddev);
      }

      const motivationsToUse =
        widget.widgetTemplate.slug === 'slider-different'
          ? sortedMotivations.slice(0, maxSortedSize)
          : sortedMotivations.slice(-maxSortedSize).reverse();
      motivations = motivationsToUse.flatMap(
        (m) => motivations.find((mm) => mm.code === m.code) ?? [],
      );
    }
  }

  return motivations;
};

export const getAssessmentNeeded = ({
  widget,
  isLoading,
  user,
}: {
  widget: WidgetWithData;
  isLoading?: boolean;
  user?: User;
}) => {
  if (isLoading) return null;

  const assessment = widget.motivationGroup?.slug ?? 'full';

  const motivations = calculateData({ widget, isLoading });

  // None of these datasets contain the current user
  if (!user || !widget.datasets.some((d) => d.userData?.userId === user.id)) {
    return null;
  }

  const userMotivations = widget.datasets.find((d) => d.userData?.userId === user.id)
    ?.userData?.motivations;
  if (!userMotivations) return assessment;

  if (
    (widget.widgetTemplate.slug === 'slider-top-5' ||
      widget.widgetTemplate.slug === 'slider-bottom-5') &&
    userMotivations.length < FULL_MOTIVATION_COUNT
  )
    return assessment;

  if (
    userMotivations &&
    motivations.every((m) => userMotivations.some((um) => um.code === m.code))
  ) {
    return null;
  }

  return assessment;
};

export const AssessmentButton = ({
  assessmentSlug,
  className,
}: {
  assessmentSlug: string;
  className?: string;
}) => {
  const { pathname } = useMatchedWorkspace();
  const location = useLocation();
  const handleClick = useCallback(() => {
    sessionStorage.setItem('assessmentRedirectTo', location.pathname);
  }, [location.pathname]);

  return (
    <NavLinkButton
      variant="secondary"
      onClick={handleClick}
      to={`${pathname}/assessment/${assessmentSlug}`}
      className={cn('', className)}
      preventScrollReset
    >
      <span>Complete Motivation Analysis</span>
      <Icon.ArrowRight size={18} />
    </NavLinkButton>
  );
};
