import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import { CommandList, Command as CommandPrimitive } from 'cmdk';
import { useCallback, useMemo, useState, type ReactNode } from 'react';

import { useMediaQuery } from '../lib/use-media-query';
import { cn } from '../lib/utils';
import { CommandEmpty, CommandGroup, CommandItem } from './command';
import { Drawer, DrawerContent, DrawerHeader, DrawerTrigger } from './drawer';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { ScrollBar } from './scroll-area';

export const InputDropdownDrawer = <T extends { id: string | number }>({
  children,
  asChild,
  options,
  optionRender,
  optionsAsChildren,
  handleSelect,
  alignPopover,
  className,
}: {
  children?: ReactNode;
  asChild?: boolean;
  options: T[];
  optionRender: (data: T) => ReactNode;
  groupHeader?: ReactNode;
  optionsAsChildren?: boolean;
  handleSelect?: (data: T) => void;
  alignPopover?: 'start' | 'end' | 'center';
  className?: string;
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => container,
    count: options.length,
    estimateSize: () => 32,
    overscan: 5,
  });

  const handleRef = useCallback((node: HTMLDivElement | null) => {
    setContainer(node);
  }, []);

  const virtualRows = rowVirtualizer.getVirtualItems();

  const command = useMemo(() => {
    if (!open) return null;
    const Rows = virtualRows.flatMap((virtualRow) => {
      const row = options[virtualRow.index];
      if (!row) return [];
      return (
        <CommandItem
          key={row.id}
          value={String(row.id)}
          onSelect={() => {
            setOpen(false);
            handleSelect?.(row);
          }}
          className="hover:cursor-pointer"
          asChild={optionsAsChildren}
        >
          {optionRender(row)}
        </CommandItem>
      );
    });

    const [before, after] =
      virtualRows.length > 0
        ? [
            notUndefined(virtualRows[0]).start - rowVirtualizer.options.scrollMargin,
            rowVirtualizer.getTotalSize() - notUndefined(virtualRows.at(-1)).end,
          ]
        : [0, 0];

    return (
      <ScrollAreaPrimitive.Root
        className={cn(
          'relative flex h-full max-h-[var(--radix-popper-available-height,50vh)] w-full flex-col',
        )}
      >
        <ScrollAreaPrimitive.Viewport
          className={cn(
            'h-full max-h-full w-full rounded-[inherit] [&>div]:!flex [&>div]:!min-h-full [&>div]:!flex-col',
          )}
          ref={handleRef}
        >
          <CommandList>
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup className={'w-full'}>
              {before > 0 ? <div style={{ height: before }} /> : null}
              {Rows}
              {after > 0 ? <div style={{ height: after }} /> : null}
            </CommandGroup>
          </CommandList>
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar className="z-20" />
      </ScrollAreaPrimitive.Root>
    );
  }, [
    handleRef,
    handleSelect,
    open,
    optionRender,
    options,
    optionsAsChildren,
    rowVirtualizer,
    virtualRows,
  ]);

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={setOpen} noBodyStyles handleOnly>
        <CommandPrimitive shouldFilter={false} className={cn('w-full', className)}>
          <DrawerTrigger asChild={asChild}>{children}</DrawerTrigger>
          <DrawerContent>
            <DrawerHeader>{command}</DrawerHeader>
          </DrawerContent>
        </CommandPrimitive>
      </Drawer>
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <CommandPrimitive shouldFilter={false} className={cn('w-full', className)}>
        <PopoverTrigger asChild={asChild}>{children}</PopoverTrigger>

        <PopoverContent
          className="max-h-[--radix-popper-available-height]"
          align={alignPopover}
          sideOffset={6}
          onWheel={(e) => {
            e.stopPropagation();
          }}
          collisionPadding={{ bottom: 16 }}
        >
          {command}
        </PopoverContent>
      </CommandPrimitive>
    </Popover>
  );
};

// w-[calc(var(--radix-popover-trigger-width,100%)_+_0.5rem)]
