import { useCallback } from 'react';
import { useFetcher } from 'react-router-dom';

import { assessmentLanguages, type AssessmentLanguageCode } from '@f4s/types';
import { Combobox, Icon } from '@f4s/ui';

import { setAssessmentLanguage } from '@/providers/assessment-language';

const selections = assessmentLanguages.map((l) => ({ value: l.code, label: l.display }));

export const AssessmentLanguageSelector = ({
  language,
  className,
}: {
  language: AssessmentLanguageCode;
  className?: string;
}) => {
  const fetcher = useFetcher();
  const handleChange = useCallback(
    (value: string) => {
      setAssessmentLanguage(value);
      fetcher.submit(true, {
        action: '/refresh',
        method: 'POST',
        encType: 'application/json',
      });
    },
    [fetcher],
  );
  return (
    <Combobox
      selections={selections}
      value={language}
      onValueChange={handleChange}
      className={className}
      align="start"
    >
      <span className="hidden truncate sm:inline">
        {selections.find((s) => s.value === language)?.label}
      </span>
      <Icon.Languages className="h-4 w-4 shrink-0 opacity-50" />
    </Combobox>
  );
};
