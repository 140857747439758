export const selectionOptions = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Team',
    value: 'team',
  },
  {
    label: 'Workspace',
    value: 'workspace',
  },
  {
    label: 'Culture',
    value: 'culture',
  },
  {
    label: 'Insight',
    value: 'insight',
  },
  {
    label: 'X-Factor',
    value: 'xfactor',
  },
  {
    label: 'Role Insight',
    value: 'role-insight',
  },
  {
    label: 'Role Culture',
    value: 'role-culture',
  },
] as const;

type SelectionOptionType = (typeof selectionOptions)[number]['value'];
type Constraints = {
  canAdd: boolean;
  canRemove: boolean;
  meOnly: boolean;
  selectionOptionTypes: SelectionOptionType[];
  minAssessmentCount: number;
};
const defaultConstraints: Constraints = {
  canAdd: true,
  canRemove: false,
  meOnly: false,
  selectionOptionTypes: ['user', 'team', 'workspace'],
  minAssessmentCount: 0,
};

const templateConstraints: { [key: string]: Partial<Constraints> } = {
  'individual-results': { canAdd: false, selectionOptionTypes: ['user'] },
  'one-to-one': { canAdd: false, selectionOptionTypes: ['user'] },
  'one-to-many': { selectionOptionTypes: ['user'] },
  'insights': {},
  'over-time': {
    canAdd: false,
    meOnly: true,
    minAssessmentCount: 2,
    selectionOptionTypes: ['user'],
  },
  'team-culture': {},
  'role-comparison': {},
  'generation-gen-z-me': {},
  'generation-millennials-me': {},
  'generation-gen-x-me': {},
  'generation-baby-boomers-me': {},
  'generation-battle': { selectionOptionTypes: ['culture'] },
  'x-factor-startup': {},
  'x-factor-startup-critical': {},
  'x-factor-builder': {},
  'x-factor-builder-critical': {},
};
// This wraps the per template overrides into the default settings
export const getTemplateConstraints = (templateSlug?: string | null) => {
  const constraints = {
    ...defaultConstraints,
    ...templateConstraints[templateSlug ?? ''],
  };
  const filteredOptions = selectionOptions.filter((o) =>
    constraints.selectionOptionTypes.includes(o.value),
  );
  return { ...constraints, selectionOptions: filteredOptions };
};
