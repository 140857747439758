import { useOutlet, useOutletContext } from 'react-router-dom';

import { MinimalLayout } from '@/components/layout-minimal';

export const LayoutSpaceMinimal = () => {
  return (
    <MinimalLayout>
      <div className="flex w-full flex-col gap-8 p-6 !pb-12 sm:max-w-xl sm:px-0">
        {useOutlet(useOutletContext())}
      </div>
    </MinimalLayout>
  );
};
