import { useState } from 'react';

import { Button, cn, Icon } from '@f4s/ui';

import { useCredits } from '../queries';
import { CreditsDialog } from './credits-dialog';

interface CreditsProps {
  type: string;
  isCollapsed?: boolean;
  className?: string;
}

export const Credits = ({ type, isCollapsed, className }: CreditsProps) => {
  const { data: credits, isLoading } = useCredits(type);
  const [open, setOpen] = useState(false);

  const hasUnlimitedCredits = credits?.periodicCredits === Number.MAX_SAFE_INTEGER;
  const hasNoCredits = (credits?.totalCredits ?? 0) <= 0;

  const body = isLoading ? (
    'Loading...'
  ) : (
    <>
      <div className="flex w-9 items-center justify-center">
        {hasNoCredits ? (
          <Icon.Warning size={16} className="text-destructive" />
        ) : (
          <Icon.Coins size={16} weight="duotone" />
        )}
      </div>

      {!isCollapsed && (
        <div className={cn('truncate', hasNoCredits && 'text-destructive')}>
          {credits?.totalCredits} credits remaining
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={cn(hasUnlimitedCredits && 'hidden')}>
        <CreditsDialog
          open={open}
          onOpenChange={(isOpen) => setOpen(isOpen)}
          source="creditInfo"
          trigger={
            <Button
              className={cn(
                'ring-elderberry-900/5 bg-elderberry-50 hover:bg-elderberry-100 text-elderberry-800 hover:text-elderberry-800 dark:bg-elderberry-800 dark:hover:bg-elderberry-700 dark:text-elderberry-100 w-full justify-center gap-0 px-0 py-2 text-xs ring-1 ring-inset dark:ring-white/5',
                credits?.totalCredits === 0 &&
                  'bg-destructive-foreground text-destructive hover:text-destructive hover:bg-destructive-foreground/80',
                className,
              )}
              variant="ghost"
            >
              {body}
            </Button>
          }
        />
      </div>
    </>
  );
};

export const CreditsButton = ({ type, className }: CreditsProps) => {
  const { data: credits } = useCredits(type);
  const [open, setOpen] = useState(false);
  const hasNoCredits = (credits?.totalCredits ?? 0) <= 0;

  const body = (
    <>
      {credits && (
        <div className="absolute right-1 top-1 text-[0.5rem] leading-none">
          {credits.totalCredits}
        </div>
      )}
      {hasNoCredits ? (
        <Icon.Warning size={16} className="text-destructive" />
      ) : (
        <Icon.Coins size={16} weight="duotone" />
      )}
    </>
  );

  return (
    <CreditsDialog
      open={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      source="creditInfo"
      trigger={
        <Button
          className={cn(
            'ring-elderberry-900/5 bg-elderberry-50 hover:bg-elderberry-100 text-elderberry-800 hover:text-elderberry-800 dark:bg-elderberry-800 dark:hover:bg-elderberry-700 dark:text-elderberry-100 relative shrink-0 justify-center ring-1 ring-inset dark:ring-white/5',
            credits?.totalCredits === 0 &&
              'bg-destructive-foreground text-destructive hover:text-destructive hover:bg-destructive-foreground/80',
            className,
          )}
          size="icon"
          variant="ghost"
        >
          {body}
        </Button>
      }
    />
  );
};
