import { useCallback } from 'react';
import { useFetcher } from 'react-router-dom';

import { Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { useDataFromLoader } from '@/lib/hooks/loader';

import type { JtbdCategory } from '../../utils';
import type { signupIndexLoader } from '../loaders';

export const SignupWelcomePage = () => {
  const { categories } = useDataFromLoader<typeof signupIndexLoader>();

  const fetcher = useFetcher();
  const handleNext = useCallback(
    (slug: JtbdCategory) => () => {
      const category = categories.find((c) => c.slug === slug);
      if (category) {
        fetcher.submit(
          { slug: category.slug, redirectTo: category.path ?? category.slug },
          { encType: 'application/json', method: 'POST' },
        );
      }
    },
    [categories, fetcher],
  );

  return (
    <div className="flex w-full max-w-xl flex-col gap-6">
      <div className="flex w-full flex-col gap-2">
        <h2 className="text-balance text-center font-serif text-2xl font-semibold">
          {/* What are you looking to achieve? */}
          {/* What is your number one priority? */}
          Select your top priority
          {/* What are you currently focused on? */}
        </h2>
        <p className="text-pretty text-center">
          Marlee is here to help you achieve your most important work goals. Build
          high-performing teams, unlock your next career opportunity, or foster a more
          connected workplace. Marlee gives you the tools to help you rapidly work better,
          together.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        {/* <h3 className="font-semibold">What are you looking to achieve today?</h3> */}
        {categories.map((c) => (
          <NavLinkButton
            key={c.slug}
            variant="secondary"
            to={c.path ?? c.slug}
            onClick={handleNext(c.slug)}
            preventDefault
            className="h-auto justify-between px-4 py-3 font-normal"
            disabled={fetcher.state !== 'idle'}
            data-testid="onboarding-jtbd-category-link"
          >
            <div className="flex min-w-0 flex-col text-left">
              <div className="font-semibold">{c.title}</div>
              <div className="text-muted-foreground truncate">{c.label}</div>
            </div>
            <Icon.ChevronRight className="shrink-0" size={18} />
          </NavLinkButton>
        ))}
      </div>
    </div>
  );
};
