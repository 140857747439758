import { useMatches, type UIMatch } from 'react-router-dom';

type HandleForSettings = { type: 'settings' };
type SettingsMatch = UIMatch<null, HandleForSettings>;

export const useSettingsMatch = () => {
  const matches = useMatches();

  return matches.some(
    (m): m is SettingsMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'settings',
  );
};
