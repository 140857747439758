import { type RouteObject } from 'react-router-dom';

import {
  createTalentProfileAction,
  removeLegacyTagAction,
  updateTalentProfileAction,
} from './actions';
import {
  talentProfileLoader,
  talentProfileNewLoader,
  talentProfilesLoader,
} from './loaders';

export const talentProfileRoutes: RouteObject = {
  path: 'profiles',
  children: [
    {
      index: true,
      loader: talentProfilesLoader,
      async lazy() {
        const { TalentProfileList } = await import('./pages');
        return { Component: TalentProfileList };
      },
    },
    {
      path: 'new',
      loader: talentProfileNewLoader,
      action: createTalentProfileAction,
      async lazy() {
        const { TalentProfilePage } = await import('./pages');
        return { Component: TalentProfilePage };
      },
    },
    {
      path: ':profileId',
      loader: talentProfileLoader,
      action: updateTalentProfileAction,
      async lazy() {
        const { TalentProfilePage } = await import('./pages');
        return { Component: TalentProfilePage };
      },
    },
    {
      path: 'tags/:tagId',
      action: removeLegacyTagAction,
    },
  ],
};
