// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { redirect, RouterProvider } from 'react-router-dom';

import { ApiClientProvider } from '@f4s/api-client';

import { ErrorPage } from '@/modules/root/pages/error';

import { coachingRoutes } from './modules/coaching/routes';
import { inviteRoutes } from './modules/invite/routes';
import { globalLoader, rootLoader } from './modules/root/loaders';
import { MissingPage } from './modules/root/pages/missing';
import { userCultureAction, userLanguageAction } from './modules/user/actions';
import { prefersColorSchemeListener, themeListener } from './providers/theme';

import './index.css';

import { StrictMode } from 'react';

import { Toaster } from '@f4s/ui';

import { handle, SimpleBreadcrumb } from './components/breadcrumbs';
import { LoadClientEnv } from './lib/env';
import { initSentry, sentryCreateBrowserRouter } from './lib/sentry';
import { storeReferralInfo } from './lib/store-referral-info';
import { connectionRoutes } from './modules/_legacy/connections/routes';
import { legacyGroupRoutes } from './modules/_legacy/groups/routes';
import { testingRoutes } from './modules/_testing/routes';
import { unmasqueradeLoader } from './modules/admin/loaders';
import { adminRoutes } from './modules/admin/routes';
import { dashboardRoutes } from './modules/dashboard/routes';
import { hubLoader } from './modules/hubs/loaders';
import { integrationRoutes } from './modules/integrations/routes';
import { inviteHubRoutes, inviteTokenRoutes } from './modules/invites/routes';
import { migrationFlowLoader } from './modules/marlee-migration-flow/loaders';
import { migrationFlowRoutes } from './modules/marlee-migration-flow/routes';
import { notificationRoutes } from './modules/notifications/routes';
import { onboardingCompleteLoader } from './modules/onboarding/loaders';
import { loginRoutes } from './modules/onboarding/login/routes';
import { onboardingRoutes } from './modules/onboarding/routes';
import { signupRoutes } from './modules/onboarding/signup/routes';
import { publicProfileRoutes } from './modules/public-profile/routes';
import { reportRoutes } from './modules/report/routes';
import { refreshAction } from './modules/root/action';
import { MobileMoreNav } from './modules/root/components/nav';
import { AuthBackgroundLayout, AuthLayout } from './modules/root/pages/auth-layout';
import { GlobalLayout } from './modules/root/pages/global-layout';
import { logoutRoute } from './modules/root/routes';
import { accountRoutes } from './modules/settings/routes';
import { talentPoolRoutes } from './modules/talent/pool/routes';
import { talentProfileRoutes } from './modules/talent/profile/routes';
import { talentRoutes } from './modules/talent/routes';
import { myResultsRoute } from './modules/user/routes';
import { workspaceLoader } from './modules/workspace/loaders';
import {
  workspaceCreateRoute,
  workspaceIndexRoute,
  workspaceInviteRoute,
  workspaceRedirectRoutes,
  workspaceRoutes,
} from './modules/workspace/routes';
import { initUnleash } from './providers/feature-flags';
import { talentHubIndexLoader } from './modules/talent/loaders';

storeReferralInfo();
LoadClientEnv();
initSentry();
initUnleash();

const router = sentryCreateBrowserRouter(
  [
    {
      id: 'globalLayout',
      loader: globalLoader,
      Component: GlobalLayout,
      ErrorBoundary: ErrorPage,
      children: [
        signupRoutes,
        loginRoutes,
        inviteTokenRoutes,
        onboardingRoutes,

        // Migration wizard flow
        // Flow has separate layout to hub, so we mount it beforehand
        {
          path: '/migrate/wizard/:step?',
          loader: migrationFlowLoader,
          handle: handle({
            type: 'migrate',
            breadcrumb: SimpleBreadcrumb({ title: 'Migrate to Marlee' }),
          }),
          async lazy() {
            const { MigrationLayout } = await import(
              './modules/marlee-migration-flow/pages/migration-layout'
            );
            return {
              Component: MigrationLayout,
            };
          },
          children: [migrationFlowRoutes],
        },
        // Migration Hub
        {
          path: '/migrate',
          loader: hubLoader,
          handle: handle({
            type: 'migrate',
            breadcrumb: SimpleBreadcrumb({ title: 'Migration Hub' }),
          }),
          async lazy() {
            const { MigrateLayout } = await import('./modules/hubs/pages/migrate-layout');
            return { Component: MigrateLayout };
          },
          children: [
            {
              index: true,
              loader: async () => redirect('connections'),
            },
            myResultsRoute,
            connectionRoutes,
            legacyGroupRoutes,
            reportRoutes,
            dashboardRoutes,
            // coachingRoutes,
            // talentRoutes,
            accountRoutes,
          ],
        },
        // Talent Hub
        {
          path: '/talent',
          loader: hubLoader,
          handle: handle({
            type: 'talent',
            breadcrumb: SimpleBreadcrumb({ title: 'Talent Hub' }),
          }),
          async lazy() {
            const { TalentLayout } = await import('./modules/hubs/pages/talent-layout');
            return { Component: TalentLayout };
          },
          children: [
            {
              index: true,
              loader: talentHubIndexLoader,
              async lazy() {
                const { TalentHome } = await import('./modules/hubs/pages/talent-home');
                return { Component: TalentHome };
              },
            },
            myResultsRoute,
            talentPoolRoutes,
            talentProfileRoutes,
          ],
        },

        // Invitations Hub
        inviteHubRoutes,
        // Notifications Hub
        {
          path: '/',
          loader: hubLoader,
          handle: handle({
            type: 'notifications',
            breadcrumb: SimpleBreadcrumb({ title: 'Notifications' }),
          }),
          async lazy() {
            const { NotificationsLayout } = await import(
              './modules/hubs/pages/notifications-layout'
            );
            return { Component: NotificationsLayout };
          },
          children: [notificationRoutes],
        },

        // Workspace Select Hub
        {
          path: '/spaces',
          loader: hubLoader,
          async lazy() {
            const { WorkspaceLayout } = await import(
              './modules/hubs/pages/workspace-layout'
            );
            return { Component: WorkspaceLayout };
          },
          children: [
            workspaceIndexRoute,
            workspaceCreateRoute,
            {
              path: ':workspaceSlug',
              loader: workspaceLoader,
              handle: handle({ type: 'workspace' }),
              children: [workspaceInviteRoute],
            },
          ],
        },

        // Main App
        {
          path: '/',
          async lazy() {
            const { RootLayout } = await import('./modules/root/pages/layout');
            return { Component: RootLayout };
          },
          loader: rootLoader,
          children: [
            {
              ErrorBoundary: ErrorPage,
              children: [
                // Index route.
                // Centralize redirect behavior by re-using the onboarding complete loader
                // TODO: Should the onboarding complete step move to the /app route?
                {
                  index: true,
                  loader: onboardingCompleteLoader,
                },

                // Main space routes
                workspaceRoutes, // TODO: Most things will live inside here
                ...workspaceRedirectRoutes,
                integrationRoutes, // TBD
                accountRoutes,

                // TODO: Delete me
                {
                  path: 'personal',
                  handle: handle({
                    type: 'personal',
                    breadcrumb: SimpleBreadcrumb({ title: 'Personal Space' }),
                  }),
                  children: [
                    {
                      index: true,
                      async lazy() {
                        const { LandingPage } = await import(
                          './modules/_legacy/personal/pages/landing-page'
                        );
                        return { Component: LandingPage };
                      },
                    },
                    reportRoutes,
                    dashboardRoutes,
                    talentRoutes,
                    coachingRoutes,

                    myResultsRoute,
                    accountRoutes,
                    { path: 'more', Component: MobileMoreNav },
                  ],
                },

                // Fallback route
                {
                  path: '*', // This is handling 404s
                  Component: MissingPage,
                },
              ],
            },
          ],
        },
        // Chat route has a blank layout so has been lifted to here
        // Root loader ensures you're logged in at least
        {
          Component: AuthBackgroundLayout,
          loader: rootLoader,
          children: [
            {
              path: '/coaching/chat',
              async lazy() {
                const chatModule = import('./modules/coaching/pages/chat');
                const chatLoaderModule = import('./modules/coaching/loaders');
                const { ChatPage } = await chatModule;
                const { chatLoader } = await chatLoaderModule;
                return { Component: ChatPage, loader: chatLoader };
              },
            },
          ],
        },

        {
          Component: AuthBackgroundLayout,
          children: [
            {
              element: <AuthLayout className="max-w-[37.5rem]" />,
              children: [inviteRoutes],
            },
          ],
        },
        logoutRoute,

        publicProfileRoutes,

        // Admin dashboard
        adminRoutes,
        { path: '/unmasquerade', loader: unmasqueradeLoader },

        // User Testing Routes
        ...(import.meta.env.F4S_ENV !== 'production' ? [testingRoutes] : []),

        // Action only routes
        {
          path: '/user',
          children: [
            { path: 'culture', action: userCultureAction },
            { path: 'language', action: userLanguageAction },
          ],
        },
        { path: '/refresh', action: refreshAction },
      ],
    },
  ],
  { basename: import.meta.env.BASE_URL },
);

// Synchronize dark-mode across multiple windows/tabs
addEventListener('storage', themeListener);
// React to changes between system light and dark themes
window
  .matchMedia('(prefers-color-scheme: dark)')
  .addEventListener('change', prefersColorSchemeListener);

createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <ApiClientProvider>
      <Toaster />
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
      {/* {import.meta.env.MODE !== 'production' && <ReactQueryDevtools />} */}
    </ApiClientProvider>
  </StrictMode>,
);
