import { matchPath, redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { fetchLocalPreference } from '@/providers/local-preference';

import { DUMMY_WORKSPACE, fetchOnboardingProgress } from '../onboarding/queries';
import { rootLoader } from '../root/loaders';
import { WORKSPACE_LOCAL_PREFERENCE_NAME } from '../workspace/components/workspace-switcher';
import { fetchWorkspaces } from '../workspace/queries';

// export const migrationFlowBasepath = 'wizard';

export type Step = {
  name: string;
  position: number;
  skipIfF4SUser?: true;
  skipIfMarleeUser?: true;
  skipIfNoSpace?: true;
  skipIfHasSpace?: true;
};

export const migrationFlowLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const { user } = rootLoaderResponse;

  const progress = await fetchOnboardingProgress();

  const workspaces = await fetchWorkspaces();
  const { flowSteps, allSteps } = fetchSteps({
    isMarleeUser: false,
    hasASpace: workspaces.length > 0,
  });

  let { step } = params;
  if (!step) {
    // Because the loader also runs on the root, without any params, parse the
    // step from the URL
    // This is copied from onboarding/loaders
    const { pathname } = new URL(request.url);
    const relativePath = pathname.slice(import.meta.env.BASE_URL.length);
    const pathWithoutStep = relativePath.slice(0, relativePath.lastIndexOf('/'));

    const { step: matchedStep } =
      matchPath(`${pathWithoutStep}/:step/*`, relativePath)?.params ?? {};
    step = matchedStep;
  }
  if (!step) return redirect(flowSteps[0]?.name ?? '/');

  let currentStepIndex = flowSteps.findIndex((s) => s.name === step);
  if (currentStepIndex === -1) {
    // Check if the step exists outside of the personalized flow.
    // First try finding a step with matching position and pretending we are the same step
    // Otherwise redirect to the step that would be
    const missingStep = allSteps.find((s) => s.name === step);
    if (!missingStep) return redirect(`../${flowSteps[0]?.name}`);
    currentStepIndex = flowSteps.findIndex((s) => s.position === missingStep.position);
    if (!currentStepIndex) {
      // Copied from onboarding/queries even though I think this does nothing
      let allStepsIndex = allSteps.findIndex((s) => s.name === step);

      let redirectStep: Step | undefined = undefined;
      while (!redirectStep && allStepsIndex > 0) {
        allStepsIndex--;
        redirectStep = flowSteps.find((s) => s.name === allSteps[allStepsIndex]?.name);
      }
      return redirect(`../${redirectStep?.name ?? flowSteps[0]?.name ?? '/'}`);
    }
  }
  const currentStep = flowSteps[currentStepIndex]!;
  const next = flowSteps[currentStepIndex + 1]?.name;
  const previous = flowSteps[currentStepIndex - 1]?.name;

  const nextStep = next ? `../${next}` : '/';
  const previousStep = previous ? `../${previous}` : '/';

  const stepNumber = currentStep.position;
  const stepCount = flowSteps.length;

  return {
    user,
    stepNumber,
    stepCount,
    previousStep,
    nextStep,
    workspaces,
    workspace: DUMMY_WORKSPACE,
    progress,
  };
};

function fetchSteps({
  isMarleeUser,
  hasASpace,
}: {
  isMarleeUser: boolean;
  hasASpace: boolean;
}) {
  const baseSteps: Step[] = [
    { name: 'welcome', position: 0 },
    { name: 'community', position: 1 },
    // { name: 'changed-to-communities', position: 3, },
    { name: 'percentiles', position: 2 },
    { name: 'distributions', position: 3 },
    { name: 'has-a-space', position: 4, skipIfNoSpace: true },
    { name: 'create-a-space', position: 4, skipIfHasSpace: true },
  ];

  const stepsAfterMarleeUserCheck = isMarleeUser
    ? baseSteps.filter((s) => !s.skipIfMarleeUser)
    : baseSteps.filter((s) => !s.skipIfF4SUser);
  const flowSteps = hasASpace
    ? stepsAfterMarleeUserCheck.filter((s) => !s.skipIfHasSpace)
    : stepsAfterMarleeUserCheck.filter((s) => !s.skipIfNoSpace);

  return { allSteps: baseSteps, flowSteps };
}

export const hasASpaceLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const migrationFlowLoaderResponse = await migrationFlowLoader({ request, params });
  if (migrationFlowLoaderResponse instanceof Response) return migrationFlowLoaderResponse;
  const { workspaces } = migrationFlowLoaderResponse;

  const lastWorkspaceId = await fetchLocalPreference<number>({
    preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
  });
  const workspace =
    (lastWorkspaceId && workspaces.find((w) => w.id === lastWorkspaceId)) ||
    workspaces[0];

  if (!workspace) return redirect('../create-a-space');

  await authProvider.updateUser({ appVersion: 3 }); // User has completed the migration wizard
  return { ...migrationFlowLoaderResponse, workspaces, workspace };
};

export const createASpaceLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const migrationFlowLoaderResponse = await migrationFlowLoader({ request, params });
  if (migrationFlowLoaderResponse instanceof Response) return migrationFlowLoaderResponse;

  await authProvider.updateUser({ appVersion: 3 }); // User has completed the migration wizard

  return migrationFlowLoaderResponse;
};
