import { type RouteObject } from 'react-router-dom';

import { handle } from '@/components/breadcrumbs';
import { memberLoader } from '@/modules/member/loaders';
import { MemberBreadcrumb } from '@/modules/member/pages/handle';
import { underlyingDataChildRouter } from '@/modules/motivation/routes';

import { legacyConnectionsLoader } from './loaders';
import { ConnectionsBreadcrumb } from './pages/handle';

export const connectionRoutes: RouteObject = {
  path: 'connections',
  handle: handle({
    type: 'connections',
    breadcrumb: ConnectionsBreadcrumb,
  }),
  loader: legacyConnectionsLoader,
  async lazy() {
    const { ConnectionsList } = await import('./pages/list');
    return { Component: ConnectionsList };
  },
  children: [
    {
      path: ':memberId',
      loader: memberLoader,
      handle: handle({ type: 'member', breadcrumb: MemberBreadcrumb }),
      async lazy() {
        const { ConnectionPage } = await import('./pages/page');
        return { Component: ConnectionPage };
      },
      children: [{ path: 'motivation', children: underlyingDataChildRouter }],
    },
  ],
};
