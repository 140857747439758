'use client';

import { useCallback, useEffect, useState } from 'react';

import { apiClient } from '@f4s/api-client';
import type { Assessment, Question, QuestionOptions } from '@f4s/types/common/question';

import { AnswerList } from './answer-list';
import { saveAndReturnQuestionAnswers } from './save-assessment';

export const useAssessment = ({
  question,
  questionNo,
  assessment,

  onSubmit,
  onNextStep,

  trackingContext,
}: {
  questionNo: number;

  assessment: Assessment;
  question: Question;

  onSubmit?: (
    savedAnswers: {
      questionNumber: number;
      answerOrder: number[];
    }[],
  ) => void;
  onNextStep: () => void;

  trackingContext?: 'marleeSEO';
}) => {
  const [questionAnswers, setQuestionAnswers] = useState<QuestionOptions[]>(
    question.endings,
  );

  // Keep in sync with language changes
  useEffect(() => {
    setQuestionAnswers(question.endings);
  }, [question.endings]);

  const handleAnswerChange = useCallback(
    (newList: QuestionOptions[]) => {
      setQuestionAnswers(newList);
      saveAndReturnQuestionAnswers(question.number, newList);
    },
    [question.number],
  );

  const handleNext = () => {
    const savedAnswers = saveAndReturnQuestionAnswers(question.number, questionAnswers);
    apiClient.sendEvent('assessment_next', {
      assessmentName: assessment.slug,
      questionId: question.number,
      questionPosition: questionNo,
      percentCompleted: Math.round((100 * questionNo) / assessment.questions.length),
      context: trackingContext,
    });

    if (questionNo === 1) {
      apiClient.sendEvent('assessment_started', {
        assessmentName: assessment.slug,
        context: trackingContext,
      });
    }

    if (questionNo === assessment.questions.length) {
      apiClient.sendEvent('assessment_completed', {
        assessmentName: assessment.slug,
        context: trackingContext,
      });

      onSubmit?.(savedAnswers);
      return;
    }

    onNextStep();
  };

  return {
    handleNext,
    questionTitle: question.question,
    answerList: <AnswerList answers={questionAnswers} onChange={handleAnswerChange} />,
  };
};
