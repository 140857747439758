import { queryOptions, useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { type UserPreference } from '@f4s/types';
import { GetEmailPreferencesURLRes } from '@f4s/types/api/module-emails';

export type SecondaryEmailType = 'work' | 'personal';

/**
 * TODO: Create / link API for arbitrary secondary emails instead of re-wrapping business-email
 */
const secondaryEmailsQuery = () => ({
  queryKey: ['user.emails'],
  queryFn: async () =>
    apiClient
      .get('/api/v3/users/business-email')
      .then((val) =>
        Array.isArray(val) ? val : [{ email: val, type: 'work' }],
      ) as Promise<
      [
        {
          email: string;
          type: 'primary' | SecondaryEmailType;
        },
      ]
    >,
});
export const fetchSecondaryEmails = () => queryClient.fetchQuery(secondaryEmailsQuery());
export const useSecondaryEmails = () => useQuery(secondaryEmailsQuery());

export type ThirdPartyLink = {
  id: number;
  userId?: number;
  organizationId?: number;
  thirdPartyId: string;
  service: string;
  metaData: unknown;
};

const thirdPartyLinksQuery = () => ({
  queryKey: ['/api/v3/links'],
  queryFn: async () => apiClient.get('/api/v3/links') as Promise<ThirdPartyLink[]>,
});

export const fetchThirdPartyLinks = () => queryClient.fetchQuery(thirdPartyLinksQuery());

export const thirdPartyLinksForOrgQuery = (id: number) => ({
  queryKey: ['/api/v3/links/organization', id],
  queryFn: async () =>
    apiClient.get(`/api/v3/links/organization/${id}`) as Promise<ThirdPartyLink[]>,
});

export const fetchThirdPartyLinksForOrg = (id: number) =>
  queryClient.fetchQuery(thirdPartyLinksForOrgQuery(id));

const thirdPartyLinksForTokenQuery = (token: string) => ({
  queryKey: ['/api/v3/links', token],
  queryFn: async () =>
    apiClient.get(`/api/v3/links/${token}`) as Promise<ThirdPartyLink[]>,
});

export const fetchThirdPartyLinksForTokenQuery = (token: string) =>
  queryClient.fetchQuery(thirdPartyLinksForTokenQuery(token));

export const preferencesQuery = () => ({
  queryKey: ['/api/v3/preferences'],
  queryFn: async () => apiClient.get('/api/v3/preferences') as Promise<UserPreference[]>,
});

export const fetchPreferences = () => queryClient.fetchQuery(preferencesQuery());

export const getEmailPreferencesURLQuery = () =>
  queryOptions({
    queryKey: ['/api/v2/emails/preferences-url'],
    retry: false,
    queryFn: () =>
      apiClient
        .get('/api/v2/emails/preferences-url')
        .then(GetEmailPreferencesURLRes.parse),
  });

export const fetchEmailPreferencesURL = () =>
  queryClient.fetchQuery(getEmailPreferencesURLQuery());
