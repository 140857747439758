import { cva, type VariantProps } from 'cva';
import type * as React from 'react';

// @tailwind
const badgeVariants = cva(
  'focus:ring-ring border-border/5 inline-flex items-center whitespace-nowrap rounded-full border transition-colors',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground border-transparent',
        secondary:
          'bg-elderberry-50 text-elderberry-800 dark:bg-elderberry-700 dark:text-elderberry-100',
        destructive: 'bg-destructive text-destructive-foreground border-transparent',
        success: 'bg-green-50 text-green-800 dark:bg-green-800 dark:text-green-50',
        outline: 'text-foreground',
      },
      size: {
        //  Corresponds to font size
        xs: 'px-2.5 py-1 text-xs',
        sm: 'px-3 py-1.5 text-sm',
        default: 'px-4 py-2 text-sm',
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  variant,
  size,
  className,
  ...props
}) => (
  <div className={badgeVariants({ variant, size, className })} {...props}>
    {children}
  </div>
);
