import { type Report } from '@f4s/types';

// NOTE: duplicates mentionRegex in server/modules/ask-marlee/utils.ts
// TODO: move to shared location
export const mentionRegex = /@\[(.*?)]\((.*?)-?(\d+)\)/g;

export function canShareReport(currentUserId: number, report: Report) {
  if (!report.prompt) return false;
  // extract mentions using mentionRegex
  const mentions = [...report.prompt.matchAll(mentionRegex)];
  // check for user other than self
  const includesOtherUsers = mentions.some(
    ([_mention, _displayName, type, id]) =>
      type !== 'user' || id !== String(currentUserId),
  );

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (includesOtherUsers) return false;

  return true;
}
