import { useOutlet, useOutletContext } from 'react-router-dom';

import { ModalOutlet } from '@/modules/root/components/modal-outlet';
import { RootLayout } from '@/modules/root/pages/layout';
import { WorkspaceLandingPage } from '@/modules/workspace/pages/page';

export const LayoutSpaceBackground = () => {
  const outlet = useOutlet(useOutletContext());
  return (
    <RootLayout>
      <WorkspaceLandingPage>
        <div className="fixed inset-0 z-50 backdrop-blur-sm" />
        <ModalOutlet
          useDrawer={false}
          outlet={outlet}
          disableClickOutside
          className="min-h-0"
        />
      </WorkspaceLandingPage>
    </RootLayout>
  );
};
