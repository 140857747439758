import { redirect, type RouteObject } from 'react-router-dom';

import { handle } from '@/components/breadcrumbs';

import { createASpaceLoader, hasASpaceLoader, migrationFlowLoader } from './loaders';

export const migrationFlowRoutes: RouteObject = {
  loader: migrationFlowLoader,
  handle: handle({ type: 'workspace' }),
  children: [
    // When adding a new step component, update the map from onboarding/queries/getOnboardingStepsQuery. the key will be the path of the new step, and the value will be the next step forward
    // Make sure you update the previous step as well, so we can direct the user backwards if needed
    // The onboarding loader contains the function that controls the stepping
    // {
    //   index: true,
    //   loader: migrationFlowLoader,
    // },
    // {
    //   path: ':step'
    //   loader: migrationStepLoader,
    // }
    { index: true, loader: async () => redirect('welcome') },
    {
      path: 'welcome',
      loader: migrationFlowLoader,
      lazy: async () => {
        const { WelcomeStep } = await import('./steps/welcome');
        return { Component: WelcomeStep };
      },
    },
    {
      path: 'percentiles',
      loader: migrationFlowLoader,
      lazy: async () => {
        const { PercentilesStep } = await import('./steps/percentiles');
        return { Component: PercentilesStep };
      },
    },
    {
      path: 'distributions',
      loader: migrationFlowLoader,
      lazy: async () => {
        const { DistributionsStep } = await import('./steps/distributions');
        return { Component: DistributionsStep };
      },
    },
    {
      path: 'community',
      loader: migrationFlowLoader,
      lazy: async () => {
        const { CommunityStep } = await import('./steps/community');
        return { Component: CommunityStep };
      },
    },
    {
      path: 'changed-to-communities',
      loader: migrationFlowLoader,
      lazy: async () => {
        const { CommunityStep } = await import('./steps/community');
        return {
          Component: () =>
            CommunityStep({ title: 'We’re making some changes to how you use Marlee' }),
        };
      },
    },
    {
      path: 'has-a-space',
      loader: hasASpaceLoader,
      lazy: async () => {
        const { HasASpaceStep } = await import('./steps/has-a-space');
        return { Component: HasASpaceStep };
      },
    },
    {
      path: 'create-a-space',
      loader: createASpaceLoader,
      lazy: async () => {
        const { CreateSpaceStep } = await import('./steps/create-a-space');
        return { Component: CreateSpaceStep };
      },
    },
  ],
};
