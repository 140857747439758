import { useSubmit, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import { invitesSentQuery, type Invitation } from '../invites/queries';
import { membersQuery } from '../member/queries';
import {
  getWorkspaceFromParams,
  workspaceInvitePendingConnectedQuery,
  workspaceQuery,
} from '../workspace/queries';

export async function workspaceInviteAction({ request, params }: ActionFunctionArgs) {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) throw new Error('Workspace does not exist');
  const { inviteId } = z.object({ inviteId: z.coerce.number() }).parse(params);

  if (request.method === 'DELETE') {
    // Delete the invite entry
    const invite = (await apiClient.delete(
      `/api/v4/workspaces/${workspace.id}/invites/${inviteId}`,
    )) as Invitation;
    // Invalidate the invite list
    await queryClient.invalidateQueries(invitesSentQuery({ workspaceId: workspace.id }));
    // We also have to invalidate the membership + migrated connected users for pseudo members.
    if (
      invite.type === 'organization' &&
      workspace.members.some((m) => m.userId === invite.toUser?.id)
    ) {
      // This was a pseudo-member, clean up.
      await queryClient.invalidateQueries(
        workspaceInvitePendingConnectedQuery(workspace.id),
      );
      await queryClient.invalidateQueries(workspaceQuery(workspace.slug));
      await queryClient.invalidateQueries(
        membersQuery({ workspaceSlug: workspace.slug }),
      );
    }
    return invite;
  }

  return null;
}

export const useCancelWorkspaceInvite = ({
  workspaceSlug,
}: {
  workspaceSlug: string;
}) => {
  const submit = useSubmit();
  return (inviteId: number) =>
    submit(null, {
      action: `/spaces/${workspaceSlug}/people/invites/${inviteId}`,
      method: 'DELETE',
      encType: 'application/json',
      navigate: false,
    });
};
