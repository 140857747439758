import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import type { JsonObject } from '@f4s/types/utils';

export type Saml = {
  id: number;
  workspaceId: number;
  uuid: string | null;
  cert: string | null;
  config: JsonObject;
  createdAt: string;
  updatedAt: string;
};

export type Scim = {
  id: string;
  createdAt: string;
  updatedAt: string;
  workspaceId: number;
  _count: { scimUsers: number };
};

const splitPem = (str?: string | null) => {
  if (!str) return '';
  const chunkLength = 76;
  const chunks: string[] = ['-----BEGIN CERTIFICATE-----'];
  for (let i = 0; i < str.length; i += chunkLength) {
    chunks.push(str.slice(i, i + chunkLength));
  }
  chunks.push('-----END CERTIFICATE-----');
  return chunks.join('\n');
};

export const samlListQuery = (workspaceId: number) => ({
  queryKey: ['workspace', workspaceId, 'settings', 'saml', 'list'],
  queryFn: async () => {
    const samlList = (await apiClient.get(
      `/api/v4/workspaces/${workspaceId}/settings/saml`,
    )) as Saml[];

    return samlList.map((saml) => ({ ...saml, cert: splitPem(saml.cert) }));
  },
});
export const fetchSamlList = (workspaceId: number) =>
  queryClient.fetchQuery(samlListQuery(workspaceId));
export const useSamlList = (workspaceId: number) => useQuery(samlListQuery(workspaceId));

export const scimListQuery = (workspaceId: number) => ({
  queryKey: ['workspace', workspaceId, 'settings', 'scim', 'list'],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${workspaceId}/settings/scim`) as Promise<Scim[]>,
});
export const fetchScimList = (workspaceId: number) =>
  queryClient.fetchQuery(scimListQuery(workspaceId));
export const useScimList = (workspaceId: number) => useQuery(scimListQuery(workspaceId));
