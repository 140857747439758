import { type QuestionAnswer } from '@f4s/types/common/questionnaire';

import { type fetchAssessments } from './queries';

type Assessment = Awaited<ReturnType<typeof fetchAssessments>>[0];

export const getAssessmentsWithCompletion = ({
  assessments,
  savedAnswers,
}: {
  assessments: Assessment[];
  savedAnswers: QuestionAnswer[];
}) => {
  const localAnswers = JSON.parse(
    localStorage.getItem('assessmentBlockData') ?? '[]',
  ) as QuestionAnswer[];
  const answerMap = new Map<number, QuestionAnswer>();

  // Merge any saved answers with local answers.
  savedAnswers.forEach((a) => answerMap.set(a.questionNumber, a));
  localAnswers.forEach((a) => answerMap.set(a.questionNumber, a));

  if (answerMap.size > 0) {
    assessments.forEach((assessment) => {
      let answeredCount = 0;
      let savedCount = 0;
      assessment.questions = assessment.questions.map((question) => {
        const answer = answerMap.get(question.number);
        if (answer) {
          answeredCount++;
          const isSaved = savedAnswers.some((s) => s.questionNumber === question.number);
          if (isSaved) {
            savedCount++;
          }
          return {
            ...question,
            isAnswered: true,
            isSaved,
            endings: answer.answerOrder.flatMap(
              (item) => question.endings.find((e) => e.position === item) ?? [],
            ),
          };
        }
        return question;
      });
      assessment.isAnswered = answeredCount === assessment.questions.length;
      assessment.isSaved = savedCount === assessment.questions.length;
      assessment.completion = Math.round(
        100 * (answeredCount / assessment.questions.length),
      );
    });
  } else {
    assessments.forEach((assessment) => {
      assessment.isAnswered = false;
      assessment.completion = 0;
    });
  }
  return assessments as (Omit<Assessment, 'isAnswered'> & {
    isAnswered: boolean;
    completion: number;
  })[];
};
