import { type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

export async function dismissNotificationAction({ params }: ActionFunctionArgs) {
  const { notificationId } = z
    .object({ notificationId: z.coerce.number() })
    .parse(params);
  await apiClient.patch(`/api/v3/notifications/${notificationId}/read`, {});
  await queryClient.invalidateQueries({
    queryKey: ['notifications'],
  });

  return null;
}
