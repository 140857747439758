import { useJsApiLoader, type Libraries } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';

import { InputAutocomplete, type TestAttributes } from '@f4s/ui';

const libraries: Libraries = ['places'];

interface CityAutocompleteProps extends TestAttributes {
  location?: string;
  onLocationChange?: (location: string | null) => void;
  countryCode?: string;
  onCountryCodeChange?: (countryCode: string | null) => void;
  className?: string;
  disabled?: boolean;
  hideIcon?: boolean;
}

export const CityAutocomplete = ({
  location: externalLocation = '',
  onLocationChange,
  countryCode: externalCountryCode = '', // Restrict searches to inside this country
  className,
  onCountryCodeChange,
  disabled,
  hideIcon,
  ...props
}: CityAutocompleteProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.GOOGLE_API_KEY,
    libraries,
    preventGoogleFontsLoading: true,
  });

  const [_location, setLocation] = useState<string | null>(externalLocation);
  const [_countryCode, setCountryCode] = useState<string | null>(externalCountryCode);

  const [selection, setSelection] = useState<{
    key: string;
    label: string;
    value: google.maps.places.AutocompletePrediction;
  } | null>(null);
  const [suggestions, setSuggestions] = useState<
    { key: string; label: string; value: google.maps.places.AutocompletePrediction }[]
  >([]);
  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | null>(
    null,
  );
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);
  useEffect(() => {
    if (isLoaded) {
      if (!autocompleteServiceRef.current) {
        autocompleteServiceRef.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!placesServiceRef.current) {
        placesServiceRef.current = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
      }
    }
  }, [isLoaded]);

  const handleChange = useCallback(
    (value: string) => {
      if (value === '') {
        setSuggestions([]);
        return;
      }

      if (isLoaded && autocompleteServiceRef.current) {
        autocompleteServiceRef.current.getPlacePredictions(
          {
            input: value,
            types: ['(cities)'],
            componentRestrictions: externalCountryCode
              ? { country: [externalCountryCode] }
              : undefined,
          },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setSuggestions(
                predictions.map((p) => ({
                  key: p.place_id,
                  label: p.description,
                  value: p,
                })),
              );
            } else {
              setSuggestions([]);
            }
          },
        );
      }
    },
    [externalCountryCode, isLoaded],
  );

  const handleSelection = useCallback(
    (
      selectionValue: {
        key: string;
        label: string;
        value: google.maps.places.AutocompletePrediction;
      } | null,
    ) => {
      if (!selectionValue) {
        handleChange('');
        setSelection(null);
        setLocation(null);
        setCountryCode(null);
        onLocationChange?.(null);
        onCountryCodeChange?.(null);
        return;
      }
      setSelection(selectionValue);
      setLocation(selectionValue.value.description);
      onLocationChange?.(selectionValue.value.description);

      if (placesServiceRef.current) {
        // We can automate the country selection from the place search
        placesServiceRef.current.getDetails(
          { placeId: selectionValue.value.place_id },
          (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
              // Extract the country code from the address components
              const countryComponent = place.address_components?.find((component) =>
                component.types.includes('country'),
              );
              if (countryComponent) {
                setCountryCode(countryComponent.short_name); // Set the country code (e.g., 'US', 'FR')
                onCountryCodeChange?.(countryComponent.short_name);
              }
            }
          },
        );
      }
    },
    [onLocationChange, handleChange, onCountryCodeChange],
  );

  // Sync external state change, eg: discard
  useEffect(() => {
    setLocation(externalLocation);
    setSelection((previous) => {
      if (externalLocation !== previous?.label) {
        handleChange(externalLocation);
        return null;
      }
      return previous;
    });
  }, [externalLocation, handleChange]);

  return (
    <InputAutocomplete
      defaultValue={externalLocation}
      onValueChange={handleChange}
      selection={selection}
      selections={suggestions}
      onSelectionChange={handleSelection}
      className={className}
      disabled={disabled}
      hideIcon={hideIcon}
      {...props}
    />
  );
};
