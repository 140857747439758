import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFetcher } from 'react-router-dom';

import { cultures } from '@f4s/shared/common/assessment-cultures.ts';
import { Alert, Icon, InputAutocomplete, Label } from '@f4s/ui';
import type { DistributionData } from '@f4s/widgets';

import { NavLinkButton } from '@/components/nav-link';
import { PageHeaderTitle } from '@/components/page-header';
import { YearOfBirthInput } from '@/components/year-of-birth';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';
import {
  getScoreBuckets,
  HorizontalAxis,
  Slider,
} from '@/modules/dashboard/components/widgets/slider';
import { normalizeDistribution } from '@/modules/dashboard/utils';
import { useCultureAggregate } from '@/modules/modeling/queries';
import {
  useUserMotivations,
  type MotivationUserData,
} from '@/modules/motivation/queries';
import { useLocalPreference } from '@/providers/local-preference';

import type { onboardingAnalysisBlockCompleteLoader } from '../loaders';

type Selection = {
  label: string;
  key: string;
  value: string;
};
const cultureOptions: Selection[] = cultures.map((c) => ({
  label: c.name,
  key: c.code,
  value: c.code,
}));

const latestYear = new Date().getFullYear() - 16;
const years: Selection[] = Array.from({ length: latestYear - 1900 }, (_, index) => {
  const y = String(latestYear - index);
  return {
    label: y,
    key: y,
    value: y,
  };
});

export const OnboardingAnalysisCompletePage = () => {
  const { nextStep, user, aggregateGenerationCultures } =
    usePersistedDataFromLoader<typeof onboardingAnalysisBlockCompleteLoader>();

  const [cultureCode, setCultureCode] = useState<Selection | null>(
    cultureOptions.find((c) => c.value === user.cultureCode) ?? null,
  );
  const [cultureSearch, setCultureSearch] = useState<string>('');
  const filteredCultureSelections = useMemo(() => {
    const rgx = new RegExp(cultureSearch, 'i');
    return cultureOptions.filter((c) => rgx.test(c.label) || rgx.test(c.value));
  }, [cultureSearch]);

  const [year, setYear] = useState<Selection | null>(
    years.find((c) => c.value === user.dateOfBirth?.slice(0, 4)) ?? null,
  );

  const [motivationData, setMotivationData] = useState<MotivationUserData | null>(null);
  const { data: motivationUserData } = useUserMotivations({
    cultureCode: cultureCode?.value,
  });
  useEffect(() => {
    if (motivationUserData) {
      const userData = motivationUserData[0];
      if (userData) {
        setMotivationData({ ...userData, motivations: userData.motivations.slice(0, 3) });
      }
    }
  }, [motivationUserData]);

  const generationModel = useMemo(() => {
    const yearNum = year ? Number(year.value) : 1991;
    if (yearNum < 1965)
      return aggregateGenerationCultures.find(
        (c) => c.uniqueName === 'culture-generation-boomer',
      );
    if (yearNum < 1981)
      return aggregateGenerationCultures.find(
        (c) => c.uniqueName === 'culture-generation-gen-x',
      );
    if (yearNum < 1997)
      return aggregateGenerationCultures.find(
        (c) => c.uniqueName === 'culture-generation-millennial',
      );
    return aggregateGenerationCultures.find(
      (c) => c.uniqueName === 'culture-generation-gen-z',
    );
  }, [aggregateGenerationCultures, year]);

  const distributionMode = useLocalPreference({
    preferenceName: 'distributionStyle',
    defaultValue: 'standard',
  });
  const [distributionData, setDistributionData] = useState<DistributionData[]>([]);
  const { data: generationAggregate } = useCultureAggregate({
    cultureCode: cultureCode?.value,
    modelId: generationModel?.id,
  });
  useEffect(() => {
    if (generationModel && generationAggregate) {
      const data = generationAggregate.flatMap((g) => {
        if (!g.relativeDistribution) return [];

        const normBinDist = normalizeDistribution({
          relativeDistribution: g.relativeDistribution,
          sampleSize: g.sampleSize,
          normalizationMode: distributionMode ?? undefined,
        });

        return {
          name: generationModel.name,
          code: g.code,
          distribution: normBinDist,
          quartiles: g.quartiles,
        };
      });
      setDistributionData(data);
    }
  }, [distributionMode, generationAggregate, generationModel]);

  const fetcher = useFetcher();
  const handleNext = useCallback(() => {
    fetcher.submit(
      {
        redirectTo: nextStep,
        cultureCode: cultureCode?.value ?? null,
        yearOfBirth: year?.value ?? null,
      },
      { encType: 'application/json', method: 'POST' },
    );
  }, [cultureCode?.value, fetcher, nextStep, year?.value]);

  return (
    <div className="flex flex-col gap-4 p-4 sm:p-6">
      <div className="flex flex-col gap-2">
        <PageHeaderTitle
          title={`You've finished the analysis!`}
          className="whitespace-normal"
        />
        <p>
          Here&apos;s a preview of your results compared to the{' '}
          <span className="font-semibold">
            {generationModel?.name?.replace('Generation: ', '')}
          </span>{' '}
          distribution:
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <div className="animate-fade-in-and-up flex flex-col gap-4 opacity-0">
          {motivationData && (
            <>
              {motivationData.motivations.map((m) => (
                <div key={m.id} className="bg-card relative h-16 rounded-lg">
                  <Slider
                    motivation={m}
                    scoreBuckets={getScoreBuckets({
                      motivation: m,
                      datasets: [
                        {
                          userData: motivationData,
                          definition: {
                            selectionType: 'user',
                            comparisonType: 'individual',
                            modelId: null,
                          },
                        },
                      ],
                    })}
                    distributions={distributionData.filter((d) => d.code === m.code)}
                    models={[]}
                  />
                </div>
              ))}
              <HorizontalAxis />
            </>
          )}

          <p className="text-pretty">
            One last thing - we need some final information to show you your results
            correctly.
          </p>

          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-[auto_1fr] items-center gap-2 gap-x-4">
              <Label>Work culture</Label>
              <InputAutocomplete
                onValueChange={setCultureSearch}
                selection={cultureCode}
                selections={filteredCultureSelections}
                placeholder="Select a culture..."
                onSelectionChange={setCultureCode}
                hideIcon
                data-testid="onboarding-analysis-complete-culture-autocomplete-input"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-[auto_1fr] items-center gap-2 gap-x-4">
              <Label>Year of birth</Label>
              <YearOfBirthInput
                year={year}
                onYearChange={setYear}
                data-testid="onboarding-analysis-complete-year-autocomplete-input"
              />
            </div>
          </div>
          <Alert variant="ghost" icon={<Icon.Info size={16} />} className="mt-2">
            <div className="flex flex-col">
              <p>
                <span className="inline md:block">
                  This information is used to standardize the motivation models we provide
                  to you.{' '}
                </span>
                <span className="inline md:block">
                  All results are displayed relative to your selected culture. Your year
                  of birth is kept confidential and is not shared with any third parties,
                  including employers.
                </span>
              </p>
            </div>
          </Alert>
        </div>
      </div>
      <div className="flex justify-end gap-3">
        <NavLinkButton
          to={nextStep}
          disabled={!cultureCode || !year}
          preventDefault
          onClick={handleNext}
          data-testid="onboarding-analysis-complete-continue-button"
        >
          Continue
        </NavLinkButton>
      </div>
    </div>
  );
};
