import { apiClient, queryClient } from '@f4s/api-client';

export const updateQueryFavorites = async ({
  add,
  remove,
}: {
  add: number[];
  remove: number[];
}) => {
  await apiClient.patch('/api/v4/queries/favorite', { add, remove });
  await queryClient.invalidateQueries({ queryKey: ['queries', 'user'] });
};
