import type { VariantProps } from 'cva';
import { sample } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { useFetcher } from 'react-router-dom';

import { Badge, Button, DialogTitle, Icon, type buttonVariants } from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import { DialogDrawer } from '@/components/dialog-drawer';
import { PromptButton } from '@/components/prompt-button';
import { shuffle } from '@/lib/utils';
import type { MentionData } from '@/modules/ask-marlee/queries';
import { useQueriesByCategory, type Query } from '@/modules/queries/queries';
import { useReportCreateAction } from '@/modules/report/actions';
import { useDemoGroups } from '@/modules/user/queries';
import type { WorkspaceDemoInviteActionData } from '@/modules/workspace/actions';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

export const useDemoMemberModal = ({
  variant = 'secondary',
}: {
  variant?: VariantProps<typeof buttonVariants>['variant'];
  onClick?: () => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: ReactNode | null;
} = {}) => {
  const { workspace: matchedWorkspace } = useMatchedWorkspace();
  const { data: demoGroups } = useDemoGroups();
  const [mentionSuggestions, setMentionSuggestions] = useState<
    MentionData[] | undefined
  >();
  const { data: suggestedQueriesByCategory } = useQueriesByCategory(mentionSuggestions);

  const [hasInvited, setHasInvited] = useState<boolean>(false);
  const fetcher = useFetcher<WorkspaceDemoInviteActionData>();
  useEffect(() => {
    if (fetcher.data && fetcher.state === 'idle') {
      const demoSuggestions = fetcher.data.teams.flatMap((t) => [
        {
          type: 'selection' as const,
          selection: t,
          id: `selection-${t.id}`,
          display: t.name,
          workspaces: matchedWorkspace ? [matchedWorkspace] : [],
        },
        ...t.members.map((tm) => ({
          type: 'user' as const,
          user: tm.user,
          id: `user-${tm.userId}`,
          display: tm.user.fullName,
          avatarUrl: tm.user.avatarUrl,
          workspaces: matchedWorkspace ? [matchedWorkspace] : [],
        })),
      ]);
      setMentionSuggestions(shuffle(demoSuggestions));
      setHasInvited(true);
    }
  }, [fetcher.data, fetcher.state, matchedWorkspace]);

  const handleDemoInvite = useCallback(() => {
    if (matchedWorkspace) {
      fetcher.submit(
        {},
        { action: `/spaces/${matchedWorkspace.slug}/demo`, method: 'POST' },
      );
    }
  }, [fetcher, matchedWorkspace]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDone = useCallback(() => {
    setIsOpen(false);
  }, []);

  const createReport = useReportCreateAction();
  const handlePromptClick = useCallback(
    (item: Query) => {
      setIsOpen(false);
      createReport({ prompt: item.prompt, askMarleeTemplateId: item.id });
    },
    [createReport],
  );

  const body = useMemo(() => {
    if (!matchedWorkspace) return null;

    if (hasInvited) {
      const queries: Query[] = [];
      const connectSelectionQuery = sample(
        suggestedQueriesByCategory
          ?.find((qc) => qc.name === 'connect')
          ?.queries.filter((q) => q.context.some((c) => c.type === 'selection')) ?? [],
      );
      if (connectSelectionQuery) queries.push(connectSelectionQuery);
      const collaborateOtherQuery = sample(
        suggestedQueriesByCategory
          ?.find((qc) => qc.name === 'collaborate')
          ?.queries.filter((q) => q.context.some((c) => c.type === 'user' && !c.isMe)) ??
          [],
      );
      if (collaborateOtherQuery) queries.push(collaborateOtherQuery);
      return (
        <>
          <p>Copy about what to do next with the newly invited demo members</p>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            {queries?.map((q) => (
              <PromptButton
                key={q.id}
                query={q}
                onClick={handlePromptClick}
                variant="onPortrait"
              />
            ))}
          </div>
          <div className="flex justify-end">
            <Button onClick={handleDone}>Done</Button>
          </div>
        </>
      );
    }

    return (
      <>
        <p>Copy about adding demo teams and members</p>
        {demoGroups?.map((g) => (
          <div key={g.name} className="flex flex-col gap-1">
            <p className="font-semibold">{g.name}</p>
            <div className="flex flex-wrap gap-1">
              {g.members.map((m) => (
                <Badge
                  key={m.id}
                  variant="outline"
                  size="sm"
                  className="h-7 gap-2 !pl-1 !pr-2"
                >
                  <ProfileAvatar
                    initials={m.initials}
                    avatarUrl={m.avatarUrl}
                    size="xxs"
                    className="h-5 max-h-5 w-5 max-w-5 text-xs"
                  />
                  {m.fullName}
                </Badge>
              ))}
            </div>
          </div>
        ))}
        <div className="flex justify-end">
          <Button onClick={handleDemoInvite}>Add demo members</Button>
        </div>
      </>
    );
  }, [
    demoGroups,
    handleDemoInvite,
    handleDone,
    handlePromptClick,
    hasInvited,
    matchedWorkspace,
    suggestedQueriesByCategory,
  ]);

  const modalTrigger = useMemo(() => {
    return (
      <Button
        variant={variant}
        className="min-w-9 shrink-0 px-2 py-2 md:px-4"
        onClick={handleOpenClick}
      >
        <div className="shrink-0">
          <Icon.UserPlus size={18} weight="duotone" />
        </div>
        <div className="hidden md:flex">Add demo members</div>
      </Button>
    );
  }, [handleOpenClick, variant]);

  const modalBody = useMemo(
    () => (
      <DialogDrawer
        dialogClassName="min-h-0"
        open={isOpen}
        onOpenChange={setIsOpen}
        headerContent={
          <DialogTitle>Add demo members to {matchedWorkspace?.name}</DialogTitle>
        }
        asChild
        bodyContent={<div className="flex flex-col gap-6">{body}</div>}
      />
    ),
    [body, isOpen, matchedWorkspace?.name],
  );

  return { modalTrigger, modalBody };
};
