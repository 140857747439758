'use client';

import {
  queryOptions,
  useQuery,
  type InvalidateQueryFilters,
} from '@tanstack/react-query';
import { z } from 'zod';

import { formatInitials, formatName, languageCodeSchema } from '@f4s/types';
import { jsonSchema } from '@f4s/types/utils';

import { apiClient } from './api-client';
import { HTTPError, NotLoggedInError } from './errors';
import { queryClient } from './query-client';

// Use zod to refine our API response to only the properties we need.
const userSchema = z
  .object({
    id: z.number(),
    emailAddress: z.string(),
    created: z.string(),
    properties: z.record(jsonSchema),
    roles: z.array(z.string()), // This is meant to be permissions
    emailAddresses: z.array(
      z.object({
        id: z.number(),
        emailAddress: z.string(),
        isPrimary: z.boolean().nullish(),
        isVerified: z.boolean().nullish(),
        type: z.enum(['personal', 'work']),
        createdAt: z.string(),
        updatedAt: z.string().nullish(),
      }),
    ),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    avatarUrl: z.string().nullish(),
    languageCode: languageCodeSchema,
    profileImageUrl: z.string().nullish(),
    dateOfBirth: z.string().nullish(),
    countryCode: z.string().nullish(),
    location: z.string().nullish(),
    stateCode: z.string().nullish(),
    cultureCode: z.string().nullish(),
    cultureId: z.number().nullish(),
    companyName: z.string().nullish(),
    gender: z.enum(['m', 'f', 't']).nullish(),
    // Job Role related
    roleType: z.string().nullish(),
    roleName: z.string().nullish(),
    // Support related
    intercomEmailHash: z.string().nullish(),
    // Admin masquerading
    adminUserId: z.number().optional(),
    phoneNumber: z.string().nullish(),
    appVersion: z.number(),
  })
  .nullable()
  .transform((u) => {
    if (!u) return null;
    return {
      ...u,
      avatarUrl: u.avatarUrl ?? u.profileImageUrl ?? null,
      profileImageUrl: u.profileImageUrl ?? u.avatarUrl ?? null,
      roleType: u.properties?.['jobArea'],
      roleName: u.properties?.['jobTitle'],
      firstName: u.firstName ?? null,
      lastName: u.lastName ?? null,
      gender: u.gender ?? null,
      fullName: formatName(u),
      initials: formatInitials(u),
    };
  });

export type MaybeUser = z.infer<typeof userSchema>;
export type User = Exclude<MaybeUser, null>;

export const authUserQuery = queryOptions({
  queryKey: ['users', 'me'],
  queryFn: async () => {
    let user: MaybeUser = null;
    try {
      const response = await apiClient.get('/api/v4/users/me');
      user = userSchema.parse(response);
    } catch (error) {
      if (error instanceof HTTPError && error.code === 401) {
        // Unauthorized - user is logged out
      } else {
        console.error(error);
      }
    }
    return user;
  },
});

const fetchAuthUser = () => queryClient.fetchQuery(authUserQuery);
const useAuthUser = () => useQuery(authUserQuery);

class AuthProvider {
  private static instance: AuthProvider;

  user: User | null = null;
  pendingLoginActions = false;
  pendingLogoutActions = false;

  async invalidateAllExceptMe({
    refetchType = 'active',
  }: {
    refetchType?: InvalidateQueryFilters['refetchType'];
  } = {}) {
    return queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.join(' ') !== 'users me',
      refetchType,
    });
  }

  shouldRunLoginActions() {
    if (this.pendingLoginActions) {
      this.pendingLoginActions = false;
      return true;
    }
    return false;
  }
  shouldRunLogoutActions() {
    if (this.pendingLogoutActions) {
      this.pendingLogoutActions = false;
      return true;
    }
    return false;
  }

  async setUser(
    data:
      | { user: User | null; response?: undefined }
      | { user?: undefined; response: unknown },
  ) {
    let user = data.user;
    if (data.response) {
      try {
        user = userSchema.parse(data.response);
      } catch (error) {
        console.warn('Error parsing user response', error);
      }
    }
    const lastUser = this.user;
    this.user = user !== undefined ? user : lastUser;

    // Store the updated user back to the query cache
    await queryClient.setQueryData(['users', 'me'], this.user);

    // Check for logged in transition
    if (this.user && this.user.id !== lastUser?.id) {
      await this.invalidateAllExceptMe();
      this.pendingLoginActions = true;
    }

    // Check has logged out
    if (lastUser && this.user === null) {
      queryClient.clear(); // Clear all query data
      queryClient.setQueryData(['users', 'me'], null); // Set user to null
      await this.invalidateAllExceptMe(); // Invalidate all except the user we already know is null
      this.pendingLogoutActions = true;
    }

    return this.user;
  }

  async getMaybeUser() {
    const user = await fetchAuthUser();
    return this.setUser({ user });
  }

  async getUser() {
    const user = await this.getMaybeUser();
    if (!user) throw new NotLoggedInError('User is not defined');
    return user;
  }

  async refreshUser() {
    await queryClient.invalidateQueries(authUserQuery);
    return this.getUser();
  }

  async signin(data: { emailAddress: string; password: string }) {
    const response = await apiClient.post('/auth/v3/login', data);
    const user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User sign-in failed');
    return user;
  }
  async signinWithToken(data: { token: string }) {
    const response = await apiClient.post('/auth/v3/token', data);
    const user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User sign-in failed');
    return user;
  }
  async signinWithCode(data: { code: string }) {
    const response = await apiClient.post('/auth/v3/token/code', data);
    const user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User sign-in failed');
    return user;
  }

  async updateUser(userData: Partial<User>) {
    const response = await apiClient.patch('/api/v4/users/me', userData);
    const user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User update failed');
    return user;
  }

  async updateUserAvatar(imageData: FormData) {
    const response = await apiClient.post('/api/v4/users/me/avatar', imageData, {
      isFormData: true,
    });
    const user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User update failed');
    return user;
  }

  // TODO: Use zod schema from server/modules/user-v2/schema.ts
  // Very simple signup
  async signup({
    emailAddress,
    referralCode,
    signupFlow,
  }: {
    emailAddress: string;
    referralCode?: string;
    signupFlow: 'marlee-default' | 'marlee-mini';
  }) {
    let user = await this.getMaybeUser();
    if (user) {
      // User is already signed in, return them.
      return user;
    }
    // Simple signup with only email, for now.
    const response = await apiClient.post('/auth/v3/signup', {
      emailAddress,
      referralCode,
      properties: {
        signupFlow,
      },
    });
    user = await this.setUser({ response });
    if (!user) throw new NotLoggedInError('User sign-up failed');
    return user;
  }

  async signout() {
    try {
      await apiClient.delete('/auth/v3/');
      await this.setUser({ user: null });
    } catch {
      // Shouldn't get to here, but just in case log it so sentry sees it
      console.error('User is already logged out');
    }
    return null;
  }

  public static getInstance() {
    if (!AuthProvider.instance) {
      AuthProvider.instance = new AuthProvider();
    }
    return AuthProvider.instance;
  }
}
export const authProvider = AuthProvider.getInstance();

// Hooks
export const useMaybeUser = () => {
  const { data: user, isLoading } = useAuthUser();
  const isLoggedIn = Boolean(user);
  return { user, isLoggedIn, isLoading };
};

export const useUser = () => {
  const maybeUser = useMaybeUser();
  if (!maybeUser.user) {
    throw new NotLoggedInError('User is not defined');
  }
  return { ...maybeUser, user: maybeUser.user };
};
