import type { RouteObject } from 'react-router-dom';

import { onboardingProfileUpdateAction } from './actions';
import {
  onboardingProfileCompleteLoader,
  onboardingProfileIndexLoader,
  onboardingProfileLoader,
} from './loaders';
import { OnboardingProfileUpdatePage } from './pages/update';

export const onboardingProfileRoutes: RouteObject = {
  path: 'profile',
  children: [
    { index: true, loader: onboardingProfileIndexLoader },
    {
      path: 'update',
      loader: onboardingProfileLoader,
      Component: OnboardingProfileUpdatePage,
      action: onboardingProfileUpdateAction,
    },
    {
      path: 'complete',
      loader: onboardingProfileCompleteLoader,
    },
  ],
};
