/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

export type ComponentData<T> = Exclude<
  Awaited<T extends (...args: any) => infer R ? R : T>,
  Promise<Response> | Response
>;

export function useDataFromLoader<T>() {
  return useLoaderData() as ComponentData<T>;
}

export function usePersistedDataFromLoader<T>() {
  const data = useDataFromLoader<T>();
  const [persistedData, setPersistedData] = useState<ComponentData<T>>(data);

  useEffect(() => {
    if (data) {
      setPersistedData(data);
    }
  }, [data]);

  return persistedData;
}
