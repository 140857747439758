import { useLocation } from 'react-router-dom';

import { Button, cn, Icon, type ButtonProps } from '@f4s/ui';

export const OauthIcons = ({
  redirectTo,
  className,
  onClick,
  ...props
}: {
  redirectTo?: string;
  className?: string;
  hideEmail?: boolean;
  variant?: ButtonProps['variant'];
  width?: ButtonProps['width'];
  size?: ButtonProps['size'];
  onClick?: () => void;
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const appRedirect = redirectTo ?? params.get('redirectTo');
  const redir = appRedirect ? import.meta.env.BASE_URL + appRedirect : '/app';
  const site = window.location.origin;

  return (
    <>
      <Button asChild className={cn(className)} {...props}>
        <a
          href={`/auth/v3/google?redir=${redir}&site=${site}`}
          onClick={onClick}
          data-testid="login-oauth-link-google"
        >
          <Icon.GoogleLogo className="size-full" />
        </a>
      </Button>
      <Button asChild className={cn(className)} {...props}>
        <a
          href={`/auth/v3/facebook?redir=${redir}&site=${site}`}
          onClick={onClick}
          data-testid="login-oauth-link-facebook"
        >
          <Icon.FacebookLogo className="size-full" />
        </a>
      </Button>
      {/* <Button asChild className={cn(className)} {...props}>
        <a href={`/auth/v3/apple?redir=${redir}&site=${site}`}>
          <div>🍎</div>
        </a>
      </Button>
      <Button asChild className={cn(className)} {...props}>
        <a href={`/auth/v3/twitter?redir=${redir}&site=${site}`}>
          <div>X</div>
        </a>
      </Button> */}
    </>
  );
};
