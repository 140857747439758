import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { apiClient, HTTPError, queryClient } from '@f4s/api-client';
import { extractTitleBody } from '@f4s/shared';
import { type ReportSection } from '@f4s/types';
import { cn } from '@f4s/ui';

import { CreditsDialog } from '@/modules/credits/components/credits-dialog';
import { creditQuery } from '@/modules/credits/queries';
import { getOnboardingProgressQuery } from '@/modules/onboarding/queries';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';
import { useLocalPreference } from '@/providers/local-preference';

import { Section } from '../components/section';
import { Messages } from './report-component.messages';

export class EarlyResponseError extends Error {
  type?: string;
  missingMotivations?: string[];
  constructor({
    message,
    type,
    missingMotivations,
  }: {
    message: string;
    type?: string;
    missingMotivations?: string[];
  }) {
    super(message);
    this.type = type;
    this.missingMotivations = missingMotivations;
  }
}

export const AskMarleeResponse = ({
  questionData,
  autoAsk,
  hideTitle,
  onDone,
  className,
  sectionClassName,
  animationMode: suppliedAnimationMode,
}: {
  questionData: {
    prompt: string;
    questionId?: number;
    templateId?: number;
    reportId?: number;
    reportSectionId?: number;
    widgetId?: number;
    motivationGroupId?: number;
    modelIds?: number[];
    motivationList?: string[];
    // boardType?: 'strength' | 'weakness' | 'motivationGroup' | 'insights';
  };
  autoAsk?: boolean;
  hideTitle?: boolean;
  onDone?: (questionId?: number, setions?: Partial<ReportSection>[]) => void;
  className?: string;
  sectionClassName?: string;
  animationMode?: string;
}) => {
  const { workspace } = useMatchedWorkspace();
  const [shouldAsk, setShouldAsk] = useState<boolean>(false);
  const [_isProcessing, setProcessing] = useState<boolean>(false);
  const [isRequestDone, setRequestDone] = useState<boolean>(false);
  const [isDone, setDone] = useState<boolean>(false);
  const [error, setError] = useState<EarlyResponseError | HTTPError | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);
  const abortControllerRef = useRef<AbortController | undefined>(undefined);
  const [sections, setSections] = useState<Partial<ReportSection>[]>([]);
  const [question, setQuestion] = useState<
    { id: number; question: string } | undefined
  >();
  const preferredAnimationMode = useLocalPreference({
    preferenceName: 'marleeAnimation',
    defaultValue: 'paragraph',
  });
  const animationMode = suppliedAnimationMode ?? preferredAnimationMode;

  const [sectionIndex, setSectionIndex] = useState<number>(0);

  const [shouldStart, setShouldStart] = useState<boolean>(false);

  useEffect(() => {
    if (questionData.prompt && autoAsk && !isRequestDone) {
      timeoutRef.current = setTimeout(() => {
        setShouldAsk(true);
      }, 100);
    } else {
      clearTimeout(timeoutRef.current);
      setShouldAsk(false);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [autoAsk, isRequestDone, questionData.prompt]);

  useEffect(() => {
    const abortController = new AbortController();
    const askMarlee = async () => {
      const apiBasePath = workspace ? `/api/v4/workspaces/${workspace.id}` : '/api/v3';
      setProcessing(true);
      const newQuestion = (await apiClient.post(
        `${apiBasePath}/ask-marlee/createPrompt`,
        {
          ...questionData,
          questionInputType: questionData.templateId ? 'preSelected' : 'freeText',
        },
      )) as {
        id: number;
        question: string;
        shortCircuitType?: string;
        missingMotivations?: string[];
        response?: string;
      };
      // There's a race getting the db records created and updating the status,
      // so we just give this some time. It's not that important
      setTimeout(
        () => queryClient.invalidateQueries(getOnboardingProgressQuery(workspace?.id)),
        5000,
      );

      setQuestion({ id: newQuestion.id, question: newQuestion.question });
      // Check if we have an early response
      if (newQuestion.shortCircuitType) {
        setShouldAsk(false);
        setError(
          new EarlyResponseError({
            message: newQuestion.response ?? '',
            type: newQuestion.shortCircuitType,
            missingMotivations: newQuestion.missingMotivations,
          }),
        );
      } else {
        // We need to get a new answer from GPT and stream the results back to the client
        // Streamed Response
        abortControllerRef.current = abortController;
        const newAnswer = (await apiClient.newRequest({
          method: 'POST',
          url: `${apiBasePath}/ask-marlee/getAnswerStreamed`,
          body: { questionId: newQuestion.id },
          abortController,
          streamed: true,
        })) as ReadableStream<string>;

        queryClient.invalidateQueries(
          creditQuery({ type: 'askmarlee', workspaceId: workspace?.id }),
        );

        if (newAnswer instanceof ReadableStream) {
          setShouldStart(true);
          const reader = newAnswer.getReader();
          let completed = false;
          do {
            const chunk = await reader.read();
            setSections((prevState) => {
              if (chunk.value) {
                // Format the chunk data
                const newSections: Partial<ReportSection>[] = [];
                const parts = chunk.value
                  .split('\n')
                  .map((p) => p.trim())
                  .filter(Boolean);
                for (const part of parts) {
                  const { title, body } = extractTitleBody(part);
                  newSections.push({
                    title,
                    body,
                  });
                }
                return [...prevState, ...newSections];
              }
              return prevState;
            });
            completed = chunk.done;
          } while (!completed);
        }
        queryClient.invalidateQueries(
          creditQuery({ type: 'askmarlee', workspaceId: workspace?.id }),
        );
      }
      setRequestDone(true);
      setProcessing(false);
    };
    if (shouldAsk) {
      askMarlee().catch((error_) => {
        setError(error_);
      });
    }

    return () => {
      try {
        abortController.abort('Initiated new request');
      } catch (error_) {
        console.warn('Error aborting ask marlee request', error_);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(questionData), shouldAsk]);

  const handleDone = useCallback(() => {
    setSectionIndex((previousIndex) => previousIndex + 1);
  }, []);

  // onDone handling
  useEffect(() => {
    if (isRequestDone && sectionIndex === sections.length) {
      setDone(true);
      onDone?.(question?.id, sections);
    }
  }, [isRequestDone, onDone, question?.id, sectionIndex, sections]);

  const errorComponent = useMemo(() => {
    if (!error) return null;
    if (error instanceof HTTPError && error?.code === 402) {
      return <CreditsDialog open={true} source="query" />;
    } else if (error instanceof EarlyResponseError) {
      if (
        questionData.reportId &&
        ['missingMotivations', 'teamEmpty', 'notConnected'].includes(error.type ?? '')
      ) {
        // We handle these errors in the report UI
        return null;
      }
      console.warn('Caught an early response error', error);
      return <div className="flex flex-col gap-4">{error.message}</div>;
    } else {
      throw error;
    }
  }, [error, questionData.reportId]);

  return (
    <div className={cn('flex flex-col gap-5', className)}>
      {sections.map((section, i) => (
        <Section
          key={i}
          shouldStart={shouldStart && sectionIndex === i}
          section={section}
          onDone={handleDone}
          type={questionData.reportSectionId ? 'subsection' : 'section'}
          hideTitle={hideTitle}
          disableAnimation={animationMode === 'paragraph'}
          className={sectionClassName}
        />
      ))}
      {errorComponent}
      {!isDone && shouldAsk && (
        <div className="bg-card border-foreground/10 mt-0.5 flex h-6 w-12 items-center justify-center rounded-lg border">
          <div className="dot-flashing">
            <span className="sr-only">{Messages.generatingResponse()}</span>
          </div>
        </div>
      )}
    </div>
  );
};
