import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { rootLoader } from '@/modules/root/loaders';
import { fetchPublicTalentPools } from '@/modules/talent/pool/queries';
import { fetchTalentProfiles } from '@/modules/talent/profile/queries';
import { fetchWorkspaces } from '@/modules/workspace/queries';

/* -------------------------------------------------------------------------- */
/*                              TALENT ONBOARDING                             */
/* -------------------------------------------------------------------------- */
// Layout
export const onboardingTalentLayoutLoader = async () => {
  const user = await authProvider.getMaybeUser();
  const workspaces = user ? await fetchWorkspaces() : [];
  const talentPools = await fetchPublicTalentPools();
  return { user, workspaces, talentPools };
};

// Entrypoint
export const onboardingTalentIndexLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  return redirect('/onboarding/talent/profile');
};
// Talent onboarding common loader
export const onboardingTalentLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const profiles = await fetchTalentProfiles();

  return { user: rootLoaderResponse.user, profile: profiles[0] };
};

// Talent onboarding done
export const onboardingTalentCompleteLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  // Talent onboarding complete, where to next?
  return redirect('/talent');
};
