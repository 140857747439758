import { groupBy } from 'lodash-es';
import { matchPath, redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { MagicLinkType } from '@f4s/types';

import { randomSample } from '@/lib/utils';
import { fetchLocalPreference, setLocalPreference } from '@/providers/local-preference';

import type { MentionData } from '../ask-marlee/queries';
import { fetchMagicLink } from '../invite/queries';
import { fetchOnboardingProgress } from '../onboarding/queries';
import { fetchQueriesByCategory } from '../queries/queries';
import { rootLoader } from '../root/loaders';
import {
  fetchThirdPartyLinksForOrg,
  thirdPartyLinksForOrgQuery,
} from '../settings/queries';
import { WORKSPACE_LOCAL_PREFERENCE_NAME } from './components/workspace-switcher';
import {
  fetchNewWorkspaceUsers,
  fetchSlackDetails,
  fetchWorkspace,
  fetchWorkspaces,
} from './queries';

export const workspacesLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const workspaces = await fetchWorkspaces();
  return { workspaces };
};

export type WorkspacesLoaderData = Exclude<
  Awaited<ReturnType<typeof workspacesLoader>>,
  Promise<Response> | Response
>;

export const createWorkspaceLoader = async () => {
  const user = await authProvider.getUser();
  const workspaces = await fetchWorkspaces();
  const initialInvites = fetchNewWorkspaceUsers();

  return { user, initialInvites, workspaces };
};

export const workspaceLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const user = await authProvider.getUser();
  const workspaces = await fetchWorkspaces();
  const { workspaceSlug } = params as { workspaceSlug: string };

  // Handle numeric path
  if (Number.isInteger(Number(workspaceSlug))) {
    const workspace = workspaces.find(({ id }) => id === Number.parseInt(workspaceSlug));
    if (!workspace) throw redirect('/spaces');

    const url = new URL(request.url);
    const match = matchPath(
      `${import.meta.env.BASE_URL}/spaces/:workspaceSlug/*`,
      url.pathname,
    );

    if (match) {
      return redirect(`/spaces/${workspace.slug}/${match.params['*']}${url.search}`);
    }
  }

  const workspace = await fetchWorkspace(workspaceSlug);
  if (!workspace) {
    throw redirect('/spaces');
  }

  // Set local preference for workspace here too, this supports browser back/forward behavior
  setLocalPreference({
    preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
    value: Number(workspace.id),
  });

  return { user, workspace };
};
export type WorkspaceLoaderData = Exclude<
  Awaited<ReturnType<typeof workspaceLoader>>,
  Promise<Response> | Response
>;

export const workspaceLandingLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const workspaceLoaderResponse = await workspaceLoader({ request, params });
  if (workspaceLoaderResponse instanceof Response) return workspaceLoaderResponse;

  // TODO: Clean this up
  const { workspace, user } = workspaceLoaderResponse;
  const mentionData: MentionData[] = [];
  if (workspace) {
    mentionData.push({
      id: `workspace-${workspace.id}`,
      display: workspace.name,
      avatarUrl: workspace.avatarUrl,
      type: 'workspace' as const,
      workspace,
    });
    const randomMembers = randomSample(
      workspace.members,
      Math.min(5, workspace.members.length),
    ).filter((m) => m.userId !== user.id);

    for (const member of randomMembers) {
      mentionData.push({
        id: `user-${member.userId}`,
        display: member.user.fullName,
        avatarUrl: member.user.avatarUrl,
        type: 'user' as const,
        user: member.user,
        workspaces: [workspace],
      });
    }
  }
  const queriesByCategory = await fetchQueriesByCategory(mentionData);
  const jtbdQuery = queriesByCategory
    .find((c) => c.name === 'favourites')
    ?.queries.find(
      (q) => q.questionType !== 'debrief' && !q.favourites.some((f) => f.hasUsed),
    );

  const progress = await fetchOnboardingProgress(workspaceLoaderResponse.workspace.id);

  return { ...workspaceLoaderResponse, progress, jtbdQuery };
};
export type WorkspaceLandingLoaderData = Exclude<
  Awaited<ReturnType<typeof workspaceLandingLoader>>,
  Promise<Response> | Response
>;

export const workspaceSettingsIndexLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const workspaceLoaderResponse = await workspaceLoader({ request, params });
  if (workspaceLoaderResponse instanceof Response) return workspaceLoaderResponse;
  const { workspace } = workspaceLoaderResponse;
  if (!workspace.isAdmin) {
    return redirect('../account/profile');
  }
  return redirect('details');
};

export const workspaceSettingsLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const workspaceLoaderResponse = await workspaceLoader({ request, params });
  if (workspaceLoaderResponse instanceof Response) return workspaceLoaderResponse;
  const { workspace } = workspaceLoaderResponse;

  const magicLink = await fetchMagicLink({
    type: MagicLinkType.organization,
    id: workspace.id,
    enabled: true,
  });

  return {
    ...workspaceLoaderResponse,
    permissions: {
      ...workspace.permissions,
      magicLink: magicLink?.isActive ?? false,
    },
  };
};
export type WorkspaceSettingsLoaderData = Exclude<
  Awaited<ReturnType<typeof workspaceSettingsLoader>>,
  Promise<Response> | Response
>;

export const workspaceIntegrationsLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const workspaceLoaderResponse = await workspaceLoader({ request, params });
  if (workspaceLoaderResponse instanceof Response) return workspaceLoaderResponse;
  const { workspace } = workspaceLoaderResponse;

  const integrations = await fetchThirdPartyLinksForOrg(workspace.id);
  const integrationMap = groupBy(integrations, (i) => i.service);

  const slack = await fetchSlackDetails(workspace.id);
  return { ...workspaceLoaderResponse, integrations: integrationMap, slack };
};
export type WorkspaceIntegrationsLoaderData = Exclude<
  Awaited<ReturnType<typeof workspaceIntegrationsLoader>>,
  Promise<Response> | Response
>;

export const workspaceSlackIntegrationAuthLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const workspaceLoaderResponse = await workspaceLoader({ request, params });
  if (workspaceLoaderResponse instanceof Response) return workspaceLoaderResponse;
  const { workspace } = workspaceLoaderResponse;

  try {
    const url = new URL(request.url);
    const code = url.searchParams.get('code');
    await apiClient.post(`/api/v3/slack/workspace/${workspace.id}`, { code });
    await queryClient.invalidateQueries(thirdPartyLinksForOrgQuery(workspace.id));

    return redirect('../../integrations');
  } catch (error) {
    return { ...workspaceLoaderResponse, error: (error as Error).message };
  }
};
export type WorkspaceSlackIntegrationAuthLoaderData = Exclude<
  Awaited<ReturnType<typeof workspaceSlackIntegrationAuthLoader>>,
  Promise<Response> | Response
>;

export const workspaceMyProfileLoader = async ({ params }: LoaderFunctionArgs) => {
  const { workspaceSlug } = params as { workspaceSlug: string };
  const workspace = await fetchWorkspace(workspaceSlug);
  const user = await authProvider.getUser();
  const member = workspace?.members.find((m) => m.userId === user?.id);

  return redirect(`../members/${member?.id ?? ''}`);
};

export const workspaceRedirect = (redirectTo: string) => async () => {
  const workspaces = await fetchWorkspaces();
  const lastWorkspaceId = await fetchLocalPreference<number>({
    preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
  });
  const workspace =
    (lastWorkspaceId && workspaces.find((w) => w.id === lastWorkspaceId)) ||
    workspaces[0];
  if (!workspace) return redirect('/');
  return redirect(`/spaces/${workspace.slug}/${redirectTo.replace(/^\//, '')}`);
};
