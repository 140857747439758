export const Messages = {
  /** description */
  yourPriorityQueries: () => 'Your priority queries',

  /** description */
  connect: () => 'Connect',

  /** description */
  motivate: () => 'Motivate',

  /** description */
  collaborate: () => 'Collaborate',

  /** description */
  develop: () => 'Develop',

  /** description */
  debrief: () => 'Debrief',

  hiring: () => 'Hiring',

  behired: () => 'Be Hired',

  /** description */
  other: () => 'Other',
};
