import type { VariantProps } from 'cva';
import {
  Fragment,
  useCallback,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { Mention, MentionsInput, type MentionsInputStyle } from 'react-mentions';

import { Icon } from '..';
import { cn } from '../lib/utils';
import { Button, type buttonVariants } from './button';
import { CardWithScroll } from './card';

export type MentionData = {
  id: string;
  display: string;
  focused?: boolean;
};

interface Props<T extends FunctionComponent> {
  data: MentionData[];
  prompt: string;
  Wrapper?: T;
  WrapperProps?: ComponentPropsWithoutRef<T>;
  onChange: (value: string) => void;
  onAdd: (id: string, display: string) => void;
  onSubmit: () => void;
  onShowSuggestions?: (isOpen: boolean) => void;
  placeholder?: string;
  isDisabled?: boolean;
  displaySuggestionItem?: (entry: MentionData) => ReactNode;
  displaySuggestionContainerContent?: (children: ReactNode) => ReactNode;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  showSubmitButton?: boolean;
  submitVariant?: VariantProps<typeof buttonVariants>['variant'];
  IconComponent?: ReactNode;
  className?: string;
  suggestionClassName?: string;
  isButtonDisabled?: boolean;
}

const mentionInputStyle = (isDisabled?: boolean): MentionsInputStyle => {
  const cursorStyle = isDisabled ? { cursor: 'not-allowed' } : { cursor: 'auto' };

  return {
    'width': '100%',
    'height': '100%',
    'backgroundColor': 'transparent',

    'control': {
      fontSize: 14,
      fontWeight: 'normal',
      width: '100%',
      height: 'auto',
      backgroundColor: 'transparent',
      position: 'relative',
    },

    '&singleLine': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',

      input: {
        border: 'none',
        boxShadow: 'none',
        padding: '0.25rem 0.5rem',
        position: 'relative',
        ...cursorStyle,
        // '&::placeholder': {
        //   color: 'rgba(125,125,125,0.5)', // Add your desired placeholder color here
        // },
      },

      highlighter: {
        display: 'flex',
        position: 'absolute',
        padding: '0.25rem 0.5rem',
        border: 'none',
        pointerEvents: 'none',
      },
    },

    'suggestions': {
      // For some reason, react-mention position calculations are broken since Chrome v126
      // Forcing static and zero-ed left+top here
      position: 'static',
      left: '0',
      top: '0',
      backgroundColor: 'transparent',
      marginTop: '0px',
      list: {
        // maxHeight: '384px',
      },
      item: {
        borderRadius: '8px',
      },
    },
  };
};

const SuggestionContainer = ({
  displaySuggestionContainerContent,
  onOpen,
  children,
}: {
  displaySuggestionContainerContent?: (children: ReactNode) => ReactNode;
  onOpen?: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  useEffect(() => {
    onOpen?.(true);
    return () => {
      onOpen?.(false);
    };
  }, [onOpen]);

  return (
    <CardWithScroll className="p-1 shadow-lg">
      {displaySuggestionContainerContent
        ? displaySuggestionContainerContent(children)
        : children}
    </CardWithScroll>
  );
};

export const ReactMentionInput = <T extends FunctionComponent>({
  data,
  prompt,
  onChange,
  onAdd,
  onSubmit,
  onShowSuggestions,
  placeholder,
  isDisabled,
  displaySuggestionItem,
  displaySuggestionContainerContent,
  onKeyDown,
  showSubmitButton = true,
  submitVariant = 'outline',
  IconComponent,
  className,
  suggestionClassName,
  Wrapper,
  WrapperProps,
  isButtonDisabled,
}: Props<T>) => {
  const [container, setContainer] = useState<HTMLDivElement>();
  const containerCallbackRef = useCallback((element: HTMLDivElement | null) => {
    setContainer(element ?? undefined);
  }, []);

  const onPromptChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const renderSuggestionContainer = useCallback(
    (children: ReactNode) => (
      <SuggestionContainer
        onOpen={onShowSuggestions}
        displaySuggestionContainerContent={displaySuggestionContainerContent}
      >
        {children}
      </SuggestionContainer>
    ),
    [displaySuggestionContainerContent, onShowSuggestions],
  );

  const renderSuggestion = useCallback(
    (entry: MentionData) => (
      <div
        className={cn(
          'flex flex-row items-center gap-2 rounded-lg py-2.5 pl-3.5 pr-2.5',
          entry.focused && 'bg-accent',
        )}
      >
        {displaySuggestionItem ? displaySuggestionItem(entry) : entry.display}
      </div>
    ),
    [displaySuggestionItem],
  );

  const generateData = useCallback(
    (search: string) => {
      const results: MentionData[] = [];
      for (const d of data) {
        const display = d.display || String(d.id);
        const searchValue = display.toLowerCase().indexOf(search.toLowerCase());
        if (searchValue !== -1) {
          results.push(d);
        }
      }
      return results.length < 20 ? results : results.slice(0, 19);
    },
    [data],
  );

  const WrapperOrFragment = Wrapper ?? Fragment;

  return (
    <div
      className={cn(
        'bg-card ring-border/5 focus-within:ring-primary/40 lofi:focus-within:ring-primary relative flex flex-col items-center justify-between rounded-xl ring-1 transition-all duration-300 focus-within:ring-4',
        className,
      )}
    >
      <WrapperOrFragment {...(Wrapper ? WrapperProps : {})}>
        <div className="flex h-8 w-full min-w-0 flex-1 items-start gap-2 px-4 py-5">
          <div className="shrink-0 p-1.5 md:p-1">{IconComponent}</div>
          <form autoComplete="off" className="mt-1 flex h-auto w-full min-w-0 flex-1">
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              className="hidden"
              spellCheck="false"
            />
            <MentionsInput
              singleLine={false}
              value={prompt}
              onChange={(event) => onPromptChange(event.target.value)}
              style={mentionInputStyle(isDisabled)}
              suggestionsPortalHost={container}
              customSuggestionsContainer={renderSuggestionContainer}
              placeholder={placeholder}
              onKeyDown={onKeyDown}
              allowSpaceInQuery
              disabled={isDisabled}
              spellCheck={false}
              className="[&_*::placeholder]:!text-muted-foreground [&_*]:!m-0 [&_*]:!border-none [&_*]:!text-base [&_*]:!outline-none md:[&_*]:!text-sm"
            >
              <Mention
                className="text-elderberry-600 dark:text-elderberry-400 relative z-20"
                trigger="@"
                appendSpaceOnAdd
                data={generateData}
                onAdd={(id: number | string, display: string) =>
                  onAdd(String(id), display)
                }
                displayTransform={(_id: number | string, display: string) =>
                  `@${display}`
                }
                renderSuggestion={(
                  suggestion,
                  _search,
                  _highlightedDisplay,
                  _index,
                  focused,
                ) =>
                  renderSuggestion({
                    id: String(suggestion.id),
                    display: suggestion.display ?? String(suggestion.id),
                    focused,
                  })
                }
              />
            </MentionsInput>
          </form>
        </div>
      </WrapperOrFragment>
      <div className="relative z-30 w-full">
        <div
          ref={containerCallbackRef}
          className={cn('absolute top-0 w-full', suggestionClassName)}
        />
      </div>

      <div className="border-border/5 flex w-full flex-col items-center justify-between gap-2 border-t p-2 sm:flex-row">
        <div className="text-muted-foreground lofi:opacity-0 flex w-full flex-col sm:flex-row items-center justify-center gap-1 px-2 py-1 text-xs sm:justify-start sm:py-0">
          <div className="flex-1 whitespace-normal text-balance text-center font-medium sm:text-left">
            <span>Use </span>
            <kbd className="bg-card ring-border/10 pointer-events-none inline-block select-none rounded px-1 pb-1 pt-0.5 leading-none shadow-sm ring-1">
              @
            </kbd>
            <span> to find connections, or try an example query below.</span>
          </div>
          <div className="flex flex-row gap-1 flex-1 justify-end">
              <Icon.Lock size={16} />
              <span>Private to you</span>
          </div>
        </div>
        {showSubmitButton && (
          <Button
            disabled={!prompt.trim() || isDisabled || isButtonDisabled}
            type="submit"
            onClick={onSubmit}
            variant={submitVariant || 'default'}
            className="w-full shrink-0 transition-all duration-500 sm:w-auto"
            size="default"
          >
            <Icon.Sparkles size={18} />
            <span>Generate</span>
            <Icon.Coins size={18} />
            <span>1</span>
          </Button>
        )}
      </div>
    </div>
  );
};
