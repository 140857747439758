import { queryOptions, useQuery } from '@tanstack/react-query';

import { queryClient } from '@f4s/api-client';
import { assessmentLanguageSchema } from '@f4s/types';

const assessmentLanguageQueryOptions = queryOptions({
  queryKey: ['i18n', 'assessment'],
  queryFn: async () =>
    assessmentLanguageSchema.parse(
      localStorage.assessmentLanguage ?? document.documentElement.lang.toLowerCase(),
    ),
});

export const useAssessmentLanguage = () => useQuery(assessmentLanguageQueryOptions);
export const fetchAssessmentLanguage = () =>
  queryClient.fetchQuery(assessmentLanguageQueryOptions);

export const setAssessmentLanguage = (code: string) => {
  const language = assessmentLanguageSchema.parse(code);
  localStorage.assessmentLanguage = language;
  queryClient.setQueryData(assessmentLanguageQueryOptions.queryKey, language);
};
