import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import {
  refreshReport,
  reportAssessmentUpdateAction,
  reportCreateAction,
  reportFeedbackAction,
  reportFeedbackCommentAction,
  shareReport,
  updateReportAction,
} from './actions';
import {
  exampleReportLoader,
  reportAssessmentLoader,
  reportAssessmentQuestionLoader,
  reportInviteLoader,
  reportLatestLoader,
  reportListLoader,
  reportLoader,
  reportNextAssessmentLoader,
} from './loaders';

export const reportRoutes: RouteObject = {
  path: 'queries',
  handle: handle({ type: 'queries', breadcrumb: SimpleBreadcrumb({ title: 'Queries' }) }),
  children: [
    {
      index: true,
      loader: reportListLoader,
      async lazy() {
        const { Reports } = await import('./pages/list');
        return { Component: Reports };
      },
    },
    {
      path: 'latest',
      loader: reportLatestLoader,
    },
    {
      path: 'new',
      action: reportCreateAction,
    },
    {
      path: ':reportId',
      handle: { crumb: { type: 'report' } },
      async lazy() {
        const { ReportLayout } = await import('./pages/layout');
        return { Component: ReportLayout };
      },
      children: [
        {
          index: true,
          loader: reportLoader,
          async lazy() {
            const { Report } = await import('./pages/page');
            return { Component: Report };
          },
        },
        {
          path: 'example',
          loader: exampleReportLoader,
          async lazy() {
            const { Example } = await import('./pages/example');
            return { Component: Example };
          },
        },
        {
          path: 'update',
          action: updateReportAction,
        },
        {
          path: 'assessment',
          children: [
            {
              index: true,
              loader: reportNextAssessmentLoader,
            },
            {
              path: ':blockSlug',
              children: [
                {
                  index: true,
                  loader: reportAssessmentLoader,
                },
                {
                  path: 'question/:questionNo',
                  loader: reportAssessmentQuestionLoader,
                  action: reportAssessmentUpdateAction,
                  async lazy() {
                    const { Assessment } = await import('../assessment/pages/assessment');
                    return { Component: Assessment };
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'invite',
          loader: reportInviteLoader,
          async lazy() {
            const { ReportInvitePage } = await import('./pages/pending-invite');
            return { Component: ReportInvitePage };
          },
        },
        {
          path: 'share',
          action: shareReport,
        },
        {
          path: 'feedback',
          action: reportFeedbackAction,
        },
        {
          path: 'feedback/comment',
          action: reportFeedbackCommentAction,
        },
      ],
    },
    {
      path: 'refresh',
      action: refreshReport,
    },
  ],
};
