import { useQuery } from '@tanstack/react-query';
import type { Params } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';

import { getWorkspaceIdFromSlug } from '@/modules/workspace/queries';

import type { TalentProfile } from '../profile/queries';

export type TalentPool = {
  id: number;
  externalId: string;
  name: string;
  isPublic: boolean;
  users: {
    user: {
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      avatarUrl?: string | null;
    };
    profile: TalentProfile;
  }[];
  hasJoined: boolean;
};
export type TalentPoolDetails = TalentPool;

export const publicPoolsQuery = {
  queryKey: ['talent', 'pools', 'public', 'list'],
  queryFn: async () => apiClient.get('/api/v4/talent/pools') as Promise<TalentPool[]>,
};
export const fetchPublicTalentPools = () => queryClient.fetchQuery(publicPoolsQuery);
export const usePublicTalentPools = () => useQuery(publicPoolsQuery);

export const workspacePoolsQuery = ({ workspaceId }: { workspaceId: number }) => ({
  queryKey: ['talent', 'pools', 'workspace', workspaceId, 'list'],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${workspaceId}/talent/pools`) as Promise<
      TalentPool[]
    >,
});
export const fetchWorkspaceTalentPools = ({ workspaceId }: { workspaceId: number }) =>
  queryClient.fetchQuery(workspacePoolsQuery({ workspaceId }));
export const useWorkspaceTalentPools = ({ workspaceId }: { workspaceId: number }) =>
  useQuery(workspacePoolsQuery({ workspaceId }));

export const publicPoolQuery = ({ poolId }: { poolId: number }) => ({
  queryKey: ['talent', 'pools', 'public', poolId],
  queryFn: async () =>
    apiClient.get(`/api/v4/talent/pools/${poolId}`) as Promise<TalentPoolDetails>,
});
export const fetchPublicTalentPool = ({ poolId }: { poolId: number }) =>
  queryClient.fetchQuery(publicPoolQuery({ poolId }));
export const usePublicTalentPool = ({ poolId }: { poolId: number }) =>
  useQuery(publicPoolQuery({ poolId }));

export const workspacePoolQuery = ({
  workspaceId,
  poolId,
}: {
  workspaceId: number;
  poolId: number;
}) => ({
  queryKey: ['talent', 'pools', 'workspace', workspaceId, poolId],
  queryFn: async () =>
    apiClient.get(
      `/api/v4/workspaces/${workspaceId}/talent/pools/${poolId}`,
    ) as Promise<TalentPoolDetails>,
});
export const fetchWorkspaceTalentPool = (params: {
  workspaceId: number;
  poolId: number;
}) => queryClient.fetchQuery(workspacePoolQuery(params));
export const useWorkspaceTalentPool = (params: { workspaceId: number; poolId: number }) =>
  useQuery(workspacePoolQuery(params));

export const getPoolIdFromSlug = async (params: Params) => {
  const { workspaceSlug, poolId: incomingPoolId } = z
    .object({
      workspaceSlug: z.string().optional(),
      poolId: z.string().transform((s) => {
        const n = Number(s);
        return Number.isNaN(n) ? s : n;
      }),
    })
    .parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  let poolId = incomingPoolId;
  if (typeof incomingPoolId === 'string') {
    const talentPools = workspaceId
      ? await fetchWorkspaceTalentPools({ workspaceId })
      : await fetchPublicTalentPools();
    const pool = talentPools.find((p) => p.externalId === incomingPoolId);
    if (pool) {
      poolId = pool.id;
    }
  }
  return { workspaceId, poolId: Number(poolId) };
};
