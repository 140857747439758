import type { ReactNode } from 'react';

import { cn, Icon } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';
import { usePersonalConnections } from '@/modules/_legacy/connections/queries';
import { useLegacyGroups } from '@/modules/_legacy/groups/queries';
import { useDashboards } from '@/modules/dashboard/queries';
import { useReports } from '@/modules/report/queries';

export const useShowMigrationHub = () => {
  const { data: connections } = usePersonalConnections();
  const { data: reports } = useReports();
  const { data: dashboards } = useDashboards();

  if (connections && connections.length > 0) return true;
  if (reports && reports.length > 0) return true;
  return !!(dashboards && dashboards.length > 0);
};

const BaseLink = ({
  to,
  isCollapsed,
  className,
  children,
}: {
  to: string;
  isCollapsed?: boolean;
  className?: string;
  children?: ReactNode;
}) => {
  return (
    <NavLinkButton
      to={`/migrate/${to}`}
      activeClassName="text-foreground bg-card hover:bg-card ring-1 ring-border/5 shadow-sm"
      variant="ghost"
      className={cn(
        'hover:text-foreground hover:bg-secondary flex w-full items-center justify-start gap-2 truncate lg:w-auto',
        isCollapsed &&
          'hover:text-elderberry-100 flex-col gap-1 text-xs font-normal text-white',
        className,
      )}
    >
      {children}
    </NavLinkButton>
  );
};

export const ResultsLink = (props: { isCollapsed?: boolean; className?: string }) => {
  return (
    <BaseLink to="my-results" {...props}>
      <Icon.SlidersHorizontal
        weight="duotone"
        size={props.isCollapsed ? 22 : 16}
        className="shrink-0"
      />
      <span className="truncate">Results</span>
    </BaseLink>
  );
};

export const TeamsLink = (props: { isCollapsed?: boolean; className?: string }) => {
  const { data } = useLegacyGroups();
  return (
    data &&
    data.length > 1 && (
      <BaseLink to="teams" {...props}>
        <Icon.UsersThree
          weight="duotone"
          size={props.isCollapsed ? 22 : 16}
          className="shrink-0"
        />
        <span className="truncate">Teams to move</span>
      </BaseLink>
    )
  );
};

export const ConnectionsLink = (props: { isCollapsed?: boolean; className?: string }) => {
  const { data } = usePersonalConnections();
  return (
    data &&
    data.length > 1 && (
      <BaseLink to="connections" {...props}>
        <Icon.Users
          weight="duotone"
          size={props.isCollapsed ? 22 : 16}
          className="shrink-0"
        />
        <span className="flex-1 truncate">Connections to move</span>
      </BaseLink>
    )
  );
};

export const QueriesLink = (props: { isCollapsed?: boolean; className?: string }) => {
  const { data } = useReports();

  return (
    data &&
    data.length > 0 && (
      <BaseLink to="queries" {...props}>
        <Icon.Sparkles
          weight="duotone"
          size={props.isCollapsed ? 22 : 16}
          className="shrink-0"
        />
        <span className="truncate">Queries</span>
      </BaseLink>
    )
  );
};

export const BoardsLink = (props: { isCollapsed?: boolean; className?: string }) => {
  const { data } = useDashboards();
  return (
    data &&
    data.length > 0 && (
      <BaseLink to="boards" {...props}>
        <Icon.SquaresFour
          weight="duotone"
          size={props.isCollapsed ? 22 : 16}
          className="shrink-0"
        />
        <span className="truncate">Boards</span>
      </BaseLink>
    )
  );
};

export const MigrateNav = () => {
  const { data: connections } = usePersonalConnections();
  const { data: reports } = useReports();
  const { data: dashboards } = useDashboards();

  const availableOptions = [
    connections && connections.length > 0,
    reports && reports.length > 0,
    dashboards && dashboards.length > 0,
  ].filter(Boolean).length;

  if (availableOptions === 1) {
    return (
      <div className="font-serif text-base font-semibold">
        {connections && connections.length > 0 && 'Connections to move'}
        {reports && reports.length > 0 && 'Queries'}
        {dashboards && dashboards.length > 0 && 'Boards'}
      </div>
    );
  }

  return (
    <nav className="bg-muted flex w-full flex-wrap gap-px rounded-lg p-px md:w-fit md:flex-nowrap">
      <ConnectionsLink />
      {/* <TeamsLink /> */}
      <QueriesLink />
      <BoardsLink />
    </nav>
  );
};
