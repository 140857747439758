import { tv } from 'tailwind-variants';

import { formatInitials } from '@f4s/types';
import { Button, cn, Icon } from '@f4s/ui';

import { ProfileAvatarStack } from '@/components/avatar';

import { type Query } from '../modules/queries/queries';

const promptButton = tv({
  base: 'bg-card text-foreground ring-border/5 hover:text-foreground h-full min-h-[150px] overflow-hidden rounded-xl p-0 backdrop-blur-xl transition-all duration-200 hover:shadow-md',
  variants: {
    variant: {
      default: 'text-foreground ring-border/5 hover:text-foreground/60',
      onPortrait: 'hover:bg-card',
      highlight:
        'bg-radial-elderberry dark ring-inset hover:brightness-125 hover:saturate-[120%]',
    },
  },
});

const categoryBadge = tv({
  base: 'bg-secondary lofiHome:bg-sand-200 ring-border/5 rounded-full px-2.5 py-0.5 text-[10px] font-medium tracking-tight ring-1 ring-inset',
  variants: {
    variant: {
      onPortrait: '',
      default: '',
      highlight: 'bg-elderberry-400 ring-white/5',
    },
  },
});

const avatarStack = tv({
  base: 'bg-secondary ring-border/5 text-xs ring-1 ring-inset',
  variants: {
    variant: {
      onPortrait: '',
      default: '',
      highlight: 'bg-elderberry-400',
    },
  },
});

export const PROMPT_REGEX = /@\[(.*?)]\(.*?\)/g;

export const PromptButton = ({
  query,
  onClick,
  variant = 'default',
  className,
  titleClassName,
}: {
  query: Query;
  onClick?: (item: Query) => void;
  variant?: 'highlight' | 'onPortrait' | 'default';
  className?: string;
  titleClassName?: string;
}) => {
  const persons = query.context.flatMap((c) => (c.type === 'user' ? c : []));
  const placeholders = query.context.filter((c) => c.type === 'placeholder');

  const avatars = persons.map((p) => ({
    avatarUrl: p.avatarUrl,
    initials: formatInitials(p),
  }));
  placeholders.forEach(() => {
    avatars.unshift({
      avatarUrl: undefined,
      initials: '＠',
    });
  });

  return (
    <Button
      variant="outline"
      className={cn(
        promptButton({
          variant: variant,
          className:
            query.canAsk && variant === 'highlight' ? 'bg-radial-elderberry' : '',
        }),
        className,
        'rounded-lg',
      )}
      onClick={() => onClick?.(query)}
    >
      <div className="flex h-full w-full flex-col items-start gap-2 p-4">
        <div
          className={cn(
            'flex-1 pb-2 text-left font-serif text-base font-medium [word-break:break-word]',
            titleClassName,
          )}
        >
          {query.prompt.replaceAll(PROMPT_REGEX, '$1')}
        </div>
        <div className="flex w-full items-end justify-between">
          <div className="flex items-center gap-2">
            <div className={categoryBadge({ variant })}>{query.categoryTitle}</div>
            <div className="flex items-center gap-1 text-[10px] font-medium">
              <Icon.Coins size={14} />
              <span>1</span>
            </div>
          </div>

          <ProfileAvatarStack className={avatarStack({ variant })} avatars={avatars} />
        </div>
      </div>
    </Button>
  );
};
