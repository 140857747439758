import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';
import { assessmentUpdate } from '@/modules/assessment/actions';
import {
  assessmentLoader,
  assessmentQuestionLoader,
  assessmentsLoader,
} from '@/modules/assessment/loaders';
import { fetchAggregateCultures } from '@/modules/modeling/queries';
import { fetchUserMotivations } from '@/modules/motivation/queries';

const SKIP_ANALYSIS_CUTOFF = new Date('2025-03-26');

export const onboardingAnalysisIndexLoader = async ({ request }: LoaderFunctionArgs) => {
  const { user, assessments, assessmentLanguage } = await assessmentsLoader();

  // Do we need to do the analysis step?
  const fullAssessment = assessments.find((a) => a.slug === 'full');
  if (fullAssessment?.isAnswered) {
    if (!fullAssessment.isSaved) {
      // In practice we should not get here, but just in case submit the answers
      console.warn(
        'Attempting to submit unsaved analysis answers from onboardingAnalysisIndexLoader',
      );
      try {
        const answers = z
          .object({ questionNumber: z.number(), answerOrder: z.number().array() })
          .array()
          .parse(JSON.parse(localStorage.getItem('assessmentBlockData') ?? '[]'));
        await assessmentUpdate(answers);
      } catch (error) {
        console.error('Error submitting pending answers', error);
      }
    }
    // The user has completed the full analysis, we don't need to show the analysis in onboarding
    return redirect('complete');
  }

  const canSkip = user && new Date(user.created) < SKIP_ANALYSIS_CUTOFF;
  sendEvent('signUp_next', { signUpStep: 'analysis-intro' });

  // Where are we mounted?
  const url = new URL(request.url);
  if (url.pathname.includes('/onboarding/talent/')) {
    // If we're in talent onboarding, we want them to complete the full assessment
    return { canSkip: false, miniAnalysis: undefined, isTalentOnboarding: true };
  }

  // Get and set getOnboardingAnalysisSlug lets our random mini analysis selection to be stable with the session
  // NOTE: Disable mini assessment for now
  const miniAnalysis = undefined as (typeof assessments)[0] | undefined;

  // let miniAnalysisSlug = getOnboardingAnalysisSlug();
  // if (!miniAnalysisSlug) {
  //   const incompleteAssessments = assessments.filter(
  //     (a) => a.slug !== 'full' && !a.isAnswered,
  //   );
  //   const [randomMiniAnalysis] = randomSample(incompleteAssessments, 1);
  //   if (randomMiniAnalysis) {
  //     miniAnalysisSlug = randomMiniAnalysis.slug;
  //     setOnboardingAnalysisSlug(miniAnalysisSlug);
  //   }
  // }

  // const miniAnalysis = miniAnalysisSlug
  //   ? assessments.find((a) => a.slug === miniAnalysisSlug && !a.isAnswered)
  //   : undefined;

  return { canSkip, assessmentLanguage, miniAnalysis, isTalentOnboarding: false };
};

export const onboardingAnalysisBlockIndexLoader = async () => {
  return redirect('question');
};

export const onboardingAnalysisBlockQuestionIndexLoader = async (
  args: LoaderFunctionArgs,
) => {
  const { assessment } = await assessmentLoader(args);
  console.log(assessment);
  if (!assessment) return redirect('../..');
  if (assessment.isAnswered) return redirect('../complete');

  const nextQuestionIndex = assessment.questions.findIndex((q) => !q.isAnswered);
  const nextPath = `${nextQuestionIndex === -1 ? 1 : nextQuestionIndex + 1}`;
  return redirect(nextPath);
};

export const onboardingAnalysisBlockQuestionLoader = async (args: LoaderFunctionArgs) => {
  const assessmentQuestionLoaderResponse = await assessmentQuestionLoader(args);
  const { assessment, currentQuestionNo, assessmentLanguage, currentQuestion } =
    assessmentQuestionLoaderResponse;
  if (!assessment) return redirect('../..');
  if (!currentQuestion) return redirect('..');

  let previousStep = currentQuestionNo > 1 ? `../${currentQuestionNo - 1}` : `../../..`;
  let nextStep =
    currentQuestionNo !== assessment.questions.length
      ? `../${currentQuestionNo + 1}`
      : `../../complete`;

  // Progress celebration Routing
  if (assessment.slug === 'full') {
    if (
      currentQuestionNo % 10 === 0 &&
      currentQuestionNo !== assessment.questions.length
    ) {
      nextStep = `../../progress/${currentQuestionNo / 10}`;
    } else if (currentQuestionNo > 10 && currentQuestionNo % 10 === 1) {
      previousStep = `../../progress/${(currentQuestionNo - 1) / 10}`;
    }
  }

  return {
    assessment,
    assessmentLanguage,
    currentQuestion,
    currentQuestionNo,
    previousStep,
    nextStep,
  };
};

export const onboardingAssessmentProgressIndexLoader = async () => redirect('..');
export const onboardingAssessmentProgressLoader = async ({
  request,
  params,
}: LoaderFunctionArgs) => {
  const { progressNo } = z.object({ progressNo: z.coerce.number() }).parse(params);
  const previousQuestion = progressNo * 10;
  const assessmentLoaderResponse = await assessmentLoader({
    request,
    params,
  });
  const { assessment } = assessmentLoaderResponse;
  if (!assessment) return redirect('/');

  const nextStep =
    previousQuestion < assessment.questions.length
      ? `../../question/${previousQuestion + 1}`
      : '../../complete';
  const previousStep = `../../question/${previousQuestion}`;

  return {
    assessment,
    progressNo,
    nextStep,
    previousStep,
  };
};

export const onboardingAnalysisBlockCompleteLoader = async (args: LoaderFunctionArgs) => {
  const assessmentLoaderResponse = await assessmentLoader(args);
  const { assessment } = assessmentLoaderResponse;
  if (!assessment) return redirect('/');
  const nextStep = '../../complete';
  const user = await authProvider.getUser();
  const [motivations] = await fetchUserMotivations({
    cultureCode: user.cultureCode ?? undefined,
  });

  const aggregateCultures = await fetchAggregateCultures();
  const aggregateGenerationCultures = aggregateCultures.filter((c) =>
    c.uniqueName?.startsWith('culture-generation'),
  );

  sendEvent('signUp_next', { signUpStep: 'analysis-complete' });
  return { assessment, user, nextStep, motivations, aggregateGenerationCultures };
};

export const onboardingAnalysisCompleteLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  // Where are we mounted?
  const url = new URL(request.url);
  if (url.pathname.includes('/onboarding/talent/')) {
    return redirect('/onboarding/talent/complete');
  }
  return redirect('/onboarding/complete');
};
