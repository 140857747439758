import { useCallback, useMemo, useState, type ChangeEvent } from 'react';

import { Input, InputAutocomplete } from '@f4s/ui';

import { JobRoles, JobTitlesSortedByRoles } from '@/lib/onboarding-constants';

type Selection = {
  label: string;
  key: string;
  value: string;
};

const jobAreaSelections = JobRoles.map((a) => ({ label: a, key: a, value: a }));

export const JobAreaTitle = ({
  jobArea: externalJobArea,
  onJobAreaChange,
  jobTitle: externalJobTitle,
  onJobTitleChange,
}: {
  jobArea: string | null;
  onJobAreaChange: (jobArea: string | null) => void;
  jobTitle: string | null;
  onJobTitleChange: (jobTitle: string | null) => void;
}) => {
  // Job Area
  const jobAreaSelection =
    jobAreaSelections.find((a) => a.value === externalJobArea) ?? null;
  const [jobArea, setJobArea] = useState<Selection | null>(jobAreaSelection ?? null);
  const [jobAreaSearch, setJobAreaSearch] = useState<string>('');

  const filteredJobAreaSelections = useMemo(() => {
    const rgx = new RegExp(jobAreaSearch, 'i');
    return jobAreaSelections.filter((a) => rgx.test(a.value));
  }, [jobAreaSearch]);

  // Job Title
  const jobTitleSelections = useMemo(() => {
    return [...((jobArea && JobTitlesSortedByRoles[jobArea.value]) || []), 'Other'].map(
      (t) => ({
        label: t,
        key: t,
        value: t,
      }),
    );
  }, [jobArea]);
  const jobTitleSelection =
    jobTitleSelections.find((a) => a.value === externalJobTitle) ??
    ((externalJobTitle && jobTitleSelections.find((a) => a.value === 'Other')) || null);
  const [jobTitle, setJobTitle] = useState<Selection | null>(jobTitleSelection ?? null);
  const [jobTitleSearch, setJobTitleSearch] = useState<string>('');

  const filteredJobTitleSelections = useMemo(() => {
    const rgx = new RegExp(jobTitleSearch, 'i');
    return jobTitleSelections.filter((t) => rgx.test(t.value));
  }, [jobTitleSearch, jobTitleSelections]);

  // Custom Job Title
  const [customJobTitle, setCustomJobTitle] = useState<string>(externalJobTitle ?? '');

  const handleJobArea = useCallback(
    (newJobArea: Selection | null) => {
      setJobArea(newJobArea);
      onJobAreaChange(newJobArea?.value ?? null);
      // Reset job title
      setJobTitle(null);
      setJobTitleSearch('');
      // Reset custom job title
      setCustomJobTitle('');
      onJobTitleChange(null);
    },
    [onJobAreaChange, onJobTitleChange],
  );

  const handleJobTitle = useCallback(
    (newJobTitle: Selection | null) => {
      setJobTitle(newJobTitle);
      // Reset custom job title
      setCustomJobTitle('');
      if (newJobTitle?.value === 'Other') {
        onJobTitleChange(null);
      } else {
        onJobTitleChange(newJobTitle?.value ?? null);
      }
    },
    [onJobTitleChange],
  );

  const handleCustomJobTitle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setCustomJobTitle(newValue);
      onJobTitleChange(newValue);
    },
    [onJobTitleChange],
  );

  return (
    <div className="flex flex-col gap-2">
      <InputAutocomplete
        placeholder="Select a job area..."
        onValueChange={setJobAreaSearch}
        selection={jobArea}
        selections={filteredJobAreaSelections}
        onSelectionChange={handleJobArea}
        hideIcon
        data-testid="job-area-autocomplete-input"
      />
      {jobArea && jobArea?.value !== 'Other' && (
        <InputAutocomplete
          placeholder="Select a job title..."
          onValueChange={setJobTitleSearch}
          selection={jobTitle}
          selections={filteredJobTitleSelections}
          onSelectionChange={handleJobTitle}
          hideIcon
          data-testid="job-title-autocomplete-input"
        />
      )}
      {(jobArea?.value === 'Other' || jobTitle?.value === 'Other') && (
        <div className="col-span-2">
          <Input
            value={customJobTitle}
            onChange={handleCustomJobTitle}
            placeholder="Enter a custom job title..."
            data-testid="job-title-custom-autocomplete-input"
          />
        </div>
      )}
    </div>
  );
};
