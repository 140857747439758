import * as echarts from 'echarts/core';
import { useCallback, useMemo, useRef } from 'react';

export const useEcharts = ({
  extensions = [],
}: {
  extensions: Parameters<typeof echarts.use>[0];
}) => {
  const observerRef = useRef<ResizeObserver | null>(null);
  const chartRef = useRef<echarts.ECharts | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);

  // Passing extensions in allows us to do tree-shaking
  // They need to be memoized otherwise re-renders break the chart instance

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const extensionsMemoized = useMemo(() => extensions, []);

  const refCallback = useCallback(
    (container: HTMLElement) => {
      containerRef.current = container;
      if (container !== null) {
        echarts.use(extensionsMemoized);
        chartRef.current = echarts.init(container, 'root', {
          width: container.clientWidth,
          height: container.clientHeight,
        });

        observerRef.current = observeSizeChange(container, () => {
          chartRef.current?.resize({
            width: container.clientWidth,
            height: container.clientHeight,
          });
        });
      } else {
        chartRef.current?.dispose();
      }
    },
    [extensionsMemoized],
  );

  return [chartRef, refCallback, containerRef] as const;
};

const observeSizeChange = (
  container: HTMLElement,
  cb: ({ entries, hidden }: { entries: ResizeObserverEntry[]; hidden?: boolean }) => void,
) => {
  const resizeObserver =
    window.ResizeObserver &&
    new window.ResizeObserver((entries: ResizeObserverEntry[]) => {
      const isHidden = container && container.offsetParent === null;
      if (!isHidden) {
        cb({ entries });
      } else {
        cb({ entries, hidden: true });
      }
    });
  if (resizeObserver) resizeObserver.observe(container);
  return resizeObserver;
};
