import type { RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { workspaceRequestAction } from './actions';
import { requestListLoader, requestLoader } from './loaders';

export const workspaceRequestRoutes: RouteObject = {
  path: 'requests',
  handle: handle({
    type: 'requests',
    breadcrumb: SimpleBreadcrumb({ title: 'Join Requests' }),
  }),
  children: [
    {
      index: true,
      loader: requestListLoader,
      async lazy() {
        const { WorkspaceRequestList } = await import('./pages/list');
        return { Component: WorkspaceRequestList };
      },
    },
    {
      path: ':requestId',
      loader: requestLoader,
      action: workspaceRequestAction,
      async lazy() {
        const { WorkspaceRequestPage } = await import('./pages/page');
        return { Component: WorkspaceRequestPage };
      },
    },
  ],
};
