import { useCallback, useState } from 'react';

import { Button, cn, Dialog, DialogClose, DialogTrigger, Icon } from '@f4s/ui';

import { QueryList } from '@/modules/queries/components/query-list';
import { useQueriesByCategory } from '@/modules/queries/queries';
import { ModalDialogContent } from '@/modules/root/components/modal-outlet';

import { useMentionSuggestions } from '../hooks/use-mention-suggestions';
import { AskMarleeInput } from './ask-marlee-input';

export const AskMarleePopup = ({
  modalPortal,
  className,
  isCollapsed,
}: {
  modalPortal?: HTMLDivElement | null | undefined;
  className?: string;
  isCollapsed?: boolean;
}) => {
  const [open, setIsOpen] = useState<boolean>(false);
  const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);

  const handleOnShowSuggestions = useCallback((isOpen: boolean) => {
    setSuggestionsOpen(isOpen);
  }, []);

  const mentionSuggestions = useMentionSuggestions(); // Context aware suggestions.
  const { data: queryCategories } = useQueriesByCategory(mentionSuggestions);

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className={cn(
            '!flex w-full max-w-full justify-between overflow-hidden px-1.5',
            className,
          )}
        >
          <div className="flex min-w-0 flex-1 justify-center gap-2">
            <div className="flex items-center justify-center">
              <Icon.Sparkles size={18} weight="duotone" className="shrink-0" />
            </div>

            {!isCollapsed && <div className="truncate whitespace-nowrap">Ask Marlee</div>}
          </div>
        </Button>
      </DialogTrigger>
      <ModalDialogContent modalPortal={modalPortal} hideClose={true}>
        <div className="flex h-full flex-col gap-4 p-4">
          <AskMarleeInput
            onSubmit={() => {
              setIsOpen(false);
            }}
            onNavigate={() => setIsOpen(false)}
            onShowSuggestions={handleOnShowSuggestions}
            submitVariant="default"
          />
          <div className="relative">
            {suggestionsOpen && (
              <div className="absolute -bottom-2 -left-2 -right-2 -top-2 z-20 backdrop-blur" />
            )}
            {queryCategories && (
              <QueryList
                queryCategories={queryCategories}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            )}
          </div>
          <DialogClose asChild>
            <Button variant="outline" className="w-full">
              Close
            </Button>
          </DialogClose>
        </div>
      </ModalDialogContent>
    </Dialog>
  );
};
