import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, authProvider, queryClient, type User } from '@f4s/api-client';
import { languageCodeSchema } from '@f4s/types';

export async function userCultureAction({ request }: ActionFunctionArgs) {
  const requestData = (await request.json()) as { cultureId: number };
  const response = await apiClient.post('/api/v3/modeling/cultures/user', requestData);

  // Invalidate caches
  await queryClient.invalidateQueries({ queryKey: ['questionnaire'] });
  await queryClient.invalidateQueries({ queryKey: ['motivations'] });
  await queryClient.invalidateQueries({ queryKey: ['modeling'] });
  await queryClient.invalidateQueries({ queryKey: ['dashboard', 'data'] });
  return response;
}

export async function userLanguageAction({ request }: ActionFunctionArgs) {
  const requestData = z
    .object({ languageCode: languageCodeSchema })
    .parse(await request.json());
  const user = await authProvider.updateUser(requestData);

  // Invalidate caches
  // Changing language will need to refresh a lot of queries that contain copy, for now refresh all queries
  // We definitely need the following
  await queryClient.invalidateQueries({ queryKey: ['i18n'] });
  await queryClient.invalidateQueries({ queryKey: ['questionnaire'] });

  return user;
}

export type UpdateUserRequest = Partial<User>;

export async function updateUserAction({ request }: ActionFunctionArgs) {
  const { userData, redirectTo } = (await request.json()) as {
    userData: Partial<User>;
    redirectTo?: string;
  };
  const response: { error: string | null } = { error: null };

  const { id: userId } = userData;
  if (!userId) {
    response.error = 'No userId was provided';
    return response;
  }

  try {
    await authProvider.updateUser(userData);
  } catch (error) {
    response.error = (error as { message: string }).message;
    return response;
  }

  if (redirectTo) return redirect(redirectTo);
  return response;
}
export type UpdateUserActionData = Exclude<
  Awaited<ReturnType<typeof updateUserAction>>,
  Promise<Response> | Response
>;

export function useUpdateUser() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateUserAction>>>();
  return {
    ...fetcher,
    submit: (data: { userData: UpdateUserRequest; redirectTo?: string }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
      }),
  };
}
