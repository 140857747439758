const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
/**
 * Format a number as an ordinal, e.g. `3` to `3rd`.
 * @param {number} number To format
 */
export const ordinalize = (number: number) => {
  const category = englishOrdinalRules.select(number);
  switch (category) {
    case 'one': {
      return `${number}st`;
    }

    case 'two': {
      return `${number}nd`;
    }

    case 'few': {
      return `${number}rd`;
    }

    default: {
      return `${number}th`;
    }
  }
};
