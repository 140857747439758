import { useState, type ReactNode } from 'react';

import {
  cn,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
  useMediaQuery,
} from '@f4s/ui';

export const TooltipDrawer = ({
  children,
  content,
  asChild,
  className,
  triggerClassName,
  tooltipAlign,
  tooltipClassName,
  drawerClassName,
}: {
  children?: ReactNode;
  content: ReactNode;
  asChild?: boolean;
  className?: string;
  triggerClassName?: string;
  tooltipClassName?: string;
  tooltipAlign?: 'start' | 'center' | 'end';
  drawerClassName?: string;
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    // Use a drawer
    return (
      <Drawer open={drawerOpen} onOpenChange={setDrawerOpen} noBodyStyles handleOnly>
        <DrawerTrigger asChild={asChild} className={triggerClassName}>
          {children}
        </DrawerTrigger>
        <DrawerContent className={cn('', className, drawerClassName)}>
          <DrawerHeader>{content}</DrawerHeader>
        </DrawerContent>
      </Drawer>
    );
  }
  // Use a tooltip
  return (
    <Tooltip open={tooltipOpen} onOpenChange={setTooltipOpen}>
      <TooltipTrigger asChild={asChild} className={triggerClassName}>
        {children}
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          className={cn(
            'max-w-sm shadow-md ring-1',
            className,
            tooltipClassName,
          )}
          align={tooltipAlign}
        >
          {content}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
