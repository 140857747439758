import { useOutlet, useOutletContext } from 'react-router-dom';

import { TalentLayout } from '@/modules/hubs/pages/talent-layout';
import { ModalOutlet } from '@/modules/root/components/modal-outlet';
import { TalentPoolList } from '@/modules/talent/pool/pages/list';

export const LayoutTalentBackground = () => {
  return (
    <TalentLayout>
      <TalentPoolList />
      <div className="fixed inset-0 z-50 backdrop-blur-sm" />
      <ModalOutlet
        useDrawer={false}
        outlet={useOutlet(useOutletContext())}
        disableClickOutside
        className="min-h-0"
      />
    </TalentLayout>
  );
};
