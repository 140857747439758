export const JobRoles = [
  'Engineering',
  'Finance and Operations',
  'Leadership',
  'Marketing',
  'People and Culture',
  'Product',
  'Sales',
  'Supporting Startup Success',
  'Other',
] as const;

export type JobRolesType = (typeof JobRoles)[number];

export const JobTitlesSortedByRoles: Record<string, string[]> = {
  'Marketing': [
    'Growth Manager',
    'E-commerce Manager',
    'Digital Marketing Manager',
    'Social Media Manager',
    'Content Producer',
    'Content Writer',
    'Brand Manager',
    'Marketing Lead/Head of Marketing',
  ],
  'Sales': [
    'Account Manager',
    'Sales Representative',
    'Business Development Representative',
    'Customer Specialist',
    'Partnerships Specialist',
    'Chief Commercial Officer',
    'Head Of Partnerships',
    'Head Of Sales',
  ],
  'People and Culture': [
    'Human Resources Partner',
    'Talent Acquisition Specialist',
    'Business Partner',
    'People Lead',
    'Human Resources Business Partner',
    'Employee Experience/People Analytics Specialist',
  ],
  'Product': [
    'Scrum Master',
    'Product Manager',
    'Product Designer',
    'Head of Product/Lead Product Manager',
  ],
  'Engineering': [
    'Software Engineer',
    'DevOps Engineer',
    'Artificial Intelligence Specialist',
    'Data Scientist',
    'Engineering Lead/Head of Engineering',
    'QA',
    'Sales Engineer',
  ],
  'Leadership': ['Founder', 'Co-founder', 'CEO', 'CTO', 'CFO', 'COO', 'CPO'],
  'Finance and Operations': [
    'Customer Support Specialist',
    'Customer Support Team Lead',
    'Risk/Complaints/Compliance Officer',
    'Collections/Payments/Billing Officer',
    'Head of Operations',
    'Operations/Performance Analyst',
    'Operations Manager',
  ],
  'Supporting Startup Success': [
    'Angel-Investor',
    'VC',
    'Founder',
    'Adviser',
    'Accelerator Program Manager',
  ],
};

export const enum OnboardingSessionKeys {
  TalentPool = 'onboardingTalentPoolId',
  MiniAssessmentBlock = 'onboardingMiniAssessmentBlock',
  Invite = 'inviteTokens',
  MagicLink = 'magiclink',
  InvitedSpace = 'invitedSpaceId',

  AssessmentBlockData = 'assessmentBlockData',
  AssessmentCulture = 'assessmentCulture',
}
