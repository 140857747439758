import { useMatches, type UIMatch } from 'react-router-dom';

import type { ComponentData } from '@/lib/hooks/loader';

import type { workspaceLoader, workspacesLoader } from '../loaders';

type HandleForWorkspaces = { type: 'workspaces' };
type WorkspacesMatch = UIMatch<
  Pick<ComponentData<typeof workspacesLoader>, 'workspaces'>,
  HandleForWorkspaces
>;

type HandleForWorkspaceCreate = { type: 'workspaceCreate' };
type WorkspaceCreateMatch = UIMatch<null, HandleForWorkspaceCreate>;

type HandleForWorkspace = { type: 'workspace' };
type WorkspaceMatch = UIMatch<ComponentData<typeof workspaceLoader>, HandleForWorkspace>;

type HandleForPersonal = { type: 'personal' };
type PersonalMatch = UIMatch<null, HandleForPersonal>;

type HandleForMigrate = { type: 'migrate' };
type MigrateMatch = UIMatch<null, HandleForMigrate>;

export type MatchedWorkspace =
  | {
      pathname: string;
      workspace: undefined;
    }
  | ({
      pathname: string;
      workspace: ComponentData<typeof workspaceLoader>['workspace'];
    } & Omit<ComponentData<typeof workspaceLoader>, 'workspace'>);

export const useMatchedWorkspace = (): MatchedWorkspace => {
  const matches = useMatches();
  const workspaceMatch = matches.find(
    (m): m is WorkspaceMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'workspace',
  );

  if (workspaceMatch) {
    return {
      pathname: workspaceMatch.pathname,
      ...workspaceMatch.data,
    };
  }

  const workspaceCreateMatch = matches.find(
    (m): m is WorkspaceCreateMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'workspaceCreate',
  );
  if (workspaceCreateMatch) {
    return { pathname: workspaceCreateMatch.pathname, workspace: undefined };
  }

  const workspacesMatch = matches.find(
    (m): m is WorkspacesMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'workspaces',
  );
  if (workspacesMatch) {
    return {
      pathname: '/spaces',
      workspace: undefined,
    };
  }

  const personalMatch = matches.find(
    (m): m is PersonalMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'personal',
  );
  if (personalMatch) {
    return { pathname: '/personal', workspace: undefined };
  }

  const migrateMatch = matches.find(
    (m): m is MigrateMatch =>
      m.handle != null && // loose equality nullish check
      typeof m.handle === 'object' &&
      'type' in m.handle &&
      m.handle.type === 'migrate',
  );
  if (migrateMatch) {
    return { pathname: '/migrate', workspace: undefined };
  }

  return { pathname: '', workspace: undefined };
};
