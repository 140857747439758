import { useFetcher, type ActionFunctionArgs } from 'react-router-dom';

import { apiClient } from '@f4s/api-client';
import type { CoachingStatus, StartProgram } from '@f4s/types';

import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

export const startProgramAction = async ({ request }: ActionFunctionArgs) => {
  const data = await request.json();
  await apiClient.post<CoachingStatus>(`/api/v3/coaching/queue/programs/${data.id}`, {});

  return window.open('/app/coaching/chat', '_blank');
};

export function useStartProgram() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof startProgramAction>>>();
  const { pathname } = useMatchedWorkspace();

  return {
    ...fetcher,
    submit: ({ data }: { data: StartProgram }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
        action: `/${pathname}/coaching/programs/${data.id}/start`,
      }),
  };
}
