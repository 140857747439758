import { LineChart, type LineSeriesOption } from 'echarts/charts';
import {
  GridComponent,
  MarkLineComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapPiecewiseComponent,
  type GridComponentOption,
  type MarkLineComponentOption,
  type TooltipComponentOption,
  type VisualMapComponentOption,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { type PiecewiseVisualMapOption } from 'echarts/types/dist/shared.js';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ordinalize } from '@f4s/shared';
import { cn, colors, motivationDefinitions } from '@f4s/ui';

import { useEcharts } from '../lib/useEcharts';

type EChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | GridComponentOption
  | LineSeriesOption
  | VisualMapComponentOption
  | PiecewiseVisualMapOption
  | MarkLineComponentOption
>;

export type DistributionsRefType = {
  chartRef: echarts.ECharts | null;
};

export type DistributionData = {
  name?: string;
  code: string;
  quartiles?: [number, number, number];
  distribution: [score: number, count: number, value: number][];
};

type DistributionsProps = {
  distributionData: DistributionData[];
  className?: string;
  darkMode?: boolean;
};

const Distributions = forwardRef<DistributionsRefType, DistributionsProps>(
  ({ distributionData, className, darkMode }, ref) => {
    const [chartRef, containerRef] = useEcharts({
      extensions: [
        TooltipComponent,
        GridComponent,
        LineChart,
        CanvasRenderer,
        UniversalTransition,
        VisualMapComponent,
        VisualMapPiecewiseComponent,
        MarkLineComponent,
      ],
    });
    const timeoutRef = useRef<NodeJS.Timeout>(undefined);
    useImperativeHandle(
      ref,
      () => ({
        chartRef: chartRef.current!,
      }),
      [chartRef],
    );

    const [notMerge, setNotMerge] = useState<boolean>(false);
    const [_distributionLength, setDistributionLength] = useState<number>(
      distributionData.length,
    );

    const plotData = useMemo(() => {
      const data: (DistributionData & {
        name: string;
        color: (typeof colors)[keyof typeof colors];
      })[] = [];

      for (const distribution of distributionData) {
        const motivation = motivationDefinitions.find(
          (m) => m.code === distribution.code,
        );
        if (motivation) {
          data.push({
            ...distribution,
            name: distribution.name ?? motivation.name,
            color: colors[motivation.group],
          });
        }
      }

      // The following state handling is required to alter the data merging of the chart.
      // We need to skip merging when the number of distributions (series) changes, so the chart
      // properly removes them from the canvas - otherwise it treats the absence of data as no-change
      setDistributionLength((previousLength) => {
        setNotMerge(distributionData.length !== previousLength);
        return distributionData.length;
      });

      return data;
    }, [distributionData]);

    useEffect(() => {
      const chart = chartRef.current;

      const lineSeries: {
        visualMap?: VisualMapComponentOption;
        series: LineSeriesOption;
      }[] = plotData.map((series, i) => {
        const colorKey = 500 - i * 200;
        const color =
          colorKey in series.color
            ? series.color[colorKey as keyof typeof series.color]
            : series.color[500];
        return {
          series: {
            name: series.name,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 1,
              color: series.color[500],
              type: i % 2 === 0 ? 'solid' : 'dotted',
            },
            tooltip: {
              valueFormatter: (value) => value + '%',
            },
            itemStyle: {
              color: color,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.1,
              // color: color,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: color,
                },
                {
                  offset: 1,
                  color: color + '22',
                },
              ]),
            },
            markLine: {
              symbol: ['none', 'none'],
              tooltip: { formatter: 'TEST' },

              label: {
                show: false,
                formatter: 'Median',
                fontSize: 11,
                position: 'insideMiddleTop',
                color: darkMode ? '#ffffff' : '#000000',
                width: 50,
                overflow: 'break',
              },
              data: [{ xAxis: series.quartiles![1], name: `${series.name} Median` }],
              lineStyle: {
                color: color,
              },
              silent: false,
              emphasis: { disabled: false, label: { show: true } },
            },
            emphasis: {
              focus: 'series',
            },
            data: series.distribution,
            encode: {
              y: [1],
              x: [0],
              tooltip: [2],
            },
          },
        };
      });

      const quartileSeries: {
        visualMap: VisualMapComponentOption;
        series: LineSeriesOption;
      }[] =
        plotData.length === 1
          ? plotData
              .filter((series) => series.quartiles)
              .map((series, index) => {
                const color = series.color[500];
                return {
                  visualMap: {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: lineSeries.length + index,
                    zlevel: 5,
                    z: 5,
                    pieces: [
                      {
                        min: series.quartiles![0],
                        max: series.quartiles![2],
                        color: color + '22',
                      },
                    ],
                  },
                  series: {
                    name: series.name + 'quartiles',
                    smooth: true,
                    show: false,
                    label: { show: false },
                    select: { disabled: true },
                    tooltip: { show: false },
                    emphasis: { disabled: true },
                    silent: true,
                    type: 'line',
                    lineStyle: {
                      width: 1,
                      color: '#00000000',
                    },
                    itemStyle: {
                      color: '#00000000',
                    },
                    showSymbol: false,
                    areaStyle: {},
                    data: series.distribution,
                  },
                };
              })
          : [];

      const visualMaps = quartileSeries.map((s) => s.visualMap);
      const series = [
        ...lineSeries.map((s) => s.series),
        ...quartileSeries.map((s) => s.series),
      ];

      const option: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {
              formatter: (params) =>
                `Result: ${ordinalize(Number(params.value))} Percentile`,
              backgroundColor: '#6a7985',
            },
          },
        },
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: false,
          width: '100%',
          height: '100%',
        },
        xAxis: [
          {
            type: 'value',
            name: 'Percentile',
            boundaryGap: [0, 0],
            maxInterval: 25,
            minInterval: 25,
            min: 0,
            max: 100,
            splitLine: { lineStyle: { color: 'rgba(128,128,128,0.25)' }, show: false },
            axisTick: { show: false },
            axisLine: { show: false },
            show: false,
            nameGap: 0,
          },
        ],
        yAxis: [
          {
            type: 'value',
            boundaryGap: [0, 0],
            offset: 0,
            axisLine: { onZero: false, show: false },
            minInterval: 1,
            splitLine: { lineStyle: { color: 'rgba(128,128,128,0.25)' }, show: false },
            show: false,
          },
        ],
        visualMap: visualMaps,
        series,
      };

      // Add a slight delay so first-mount is still animated
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () =>
          chart?.setOption(option, {
            notMerge: notMerge,
            lazyUpdate: true,
            // replaceMerge: ['series', 'visualMap'],
          }),
        10,
      );
      return () => {
        clearTimeout(timeoutRef.current);
      };
    }, [chartRef, plotData, notMerge, darkMode]);

    return containerRef ? (
      <div className={cn('flex h-[272px] w-full flex-col', className)}>
        <figure className="min-h-0 flex-1" ref={containerRef} />
      </div>
    ) : null;
  },
);
Distributions.displayName = 'Motivations';

export { Distributions };
