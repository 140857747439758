type loadedCallback = () => unknown;
const loadedFiles = new Set<string>();
const loadingCallbacks = new Map<string, loadedCallback[]>();

export function loadJsFile(filename: string, callback: loadedCallback) {
  if (loadedFiles.has(filename)) {
    callback?.();
    return;
  }
  if (callback) {
    const pending = loadingCallbacks.get(filename);
    if (pending) {
      loadingCallbacks.set(filename, [...pending, callback]);
      return;
    }
    loadingCallbacks.set(filename, [callback]);
  }

  const onLoad = () => {
    loadedFiles.add(filename);
    const allCallbacks = loadingCallbacks.get(filename);
    try {
      allCallbacks?.forEach((call) => call());
    } catch (error) {
      console.warn(error);
    }
  };

  const fileRef = document.createElement('script');
  fileRef.type = 'text/javascript';
  fileRef.setAttribute('type', 'text/javascript');
  fileRef.setAttribute('src', filename);
  fileRef.addEventListener('load', onLoad);
  if (typeof fileRef !== 'undefined') {
    // Assert because we always have a <head> element
    document.querySelectorAll('head')[0]!.append(fileRef);
  }
}

export function loadJsFilePromise(filename: string) {
  return new Promise((resolve) => {
    loadJsFile(filename, () => resolve(true));
  });
}
