import { useCallback, useMemo } from 'react';

import { type CreditDialogSource } from '@f4s/types/analytics';
import { Button, Divider } from '@f4s/ui';

import { DialogDrawer } from '@/components/dialog-drawer';
import { NavLinkButton } from '@/components/nav-link';
import { sendEvent } from '@/lib/analytics';
import { InviteModal } from '@/modules/workspace/components/workspace-invite-modal';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { Messages } from '../credits.messages';
import { useCredits } from '../queries';

export const CreditsDialog = ({
  open,
  onOpenChange,
  trigger,
  source,
}: {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: React.ReactNode;
  source: CreditDialogSource;
}) => {
  const { workspace, pathname } = useMatchedWorkspace();
  const plansPath = workspace
    ? `${pathname}/settings/billing/plans`
    : '/account/billing/plans';
  const { data: credits, isSuccess, isLoading } = useCredits('askmarlee');

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen && credits) {
        sendEvent('credit_dialogShown', {
          source,
          totalCreditsRemaining: credits?.totalCredits,
        });
      }
      onOpenChange?.(isOpen);
    },
    [credits, onOpenChange, source],
  );

  const headerContent = useMemo(() => {
    if (isLoading || !isSuccess) return null;

    const hasCredits = (credits.totalCredits ?? 0) > 0;
    const title = hasCredits
      ? Messages.titleWithCredits(credits.totalCredits, '')
      : Messages.titleNoCredits();

    return <h2 className="font-serif text-2xl font-semibold leading-9">{title}</h2>;
  }, [isLoading, isSuccess, credits]);

  const bodyContent = useMemo(() => {
    const trackClick = (button: string) => () =>
      sendEvent('credit_dialogClicked', { source, button });

    if (isLoading || !isSuccess) return null;

    return (
      <div className="flex flex-col gap-8">
        <section className="flex flex-col gap-2">
          {/* <h3 className="font-serif text-lg font-semibold">
            {Messages.howCreditsWorkTitle()}
          </h3> */}
          {Messages.howCreditsWorkBody()}
        </section>
        <section className="flex flex-col gap-2">
          <h3 className="font-serif text-lg font-semibold tracking-tight">
            {Messages.getMoreCredits()}
          </h3>

          <Divider />

          <div className="flex items-center gap-2 py-2">
            <div className="text-left">
              <h4 className="font-medium">{Messages.feedbackTitle()}</h4>
              <span className="text-muted-foreground">{Messages.feedbackBody()}</span>
            </div>
            <NavLinkButton
              to="https://forms.gle/ZKEJZcxboCbRhJKP9"
              target="_blank"
              className="ml-auto w-28"
              onClick={trackClick('feedback')}
            >
              {Messages.feedbackCTA()}
            </NavLinkButton>
          </div>

          <Divider />

          <div className="flex items-center justify-between gap-2 py-2">
            <div className="text-left">
              <h4 className="font-medium">{Messages.inviteTitle()}</h4>
              <span className="text-muted-foreground">{Messages.inviteBody()}</span>
            </div>
            <InviteModal variant="default" onClick={trackClick('invite')} />
          </div>

          <Divider />

          <div className="flex items-center gap-2 pt-2">
            <div className="text-left">
              <h4 className="font-medium">{Messages.upgradeTitle()}</h4>
              <span className="text-muted-foreground">{Messages.upgradeBody()}</span>
            </div>
            <NavLinkButton
              to={plansPath}
              variant="default"
              className="ml-auto w-28"
              onClick={() => {
                trackClick('upgrade');
                onOpenChange?.(false);
              }}
            >
              {Messages.upgradeCTA()}
            </NavLinkButton>
          </div>
        </section>
      </div>
    );
  }, [isLoading, isSuccess, plansPath, source, onOpenChange]);

  return (
    <DialogDrawer
      open={open}
      onOpenChange={handleOpenChange}
      headerContent={headerContent}
      bodyContent={bodyContent}
      asChild
    >
      {trigger === undefined ? (
        <Button variant="secondary">{Messages.getMoreCredits()}</Button>
      ) : (
        trigger
      )}
    </DialogDrawer>
  );
};
