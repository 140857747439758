import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';
import { type AssessmentBlock } from '@f4s/types';

import { randomSample } from '@/lib/utils';

import { fetchAndClearAutoAsk, type MentionData } from '../ask-marlee/queries';
import { assessmentQuestionLoader, assessmentsLoader } from '../assessment/loaders';
import { type Assessment } from '../assessment/queries';
import { fetchPendingSentInvites } from '../invite/queries';
import { fetchMotivationDetails } from '../motivation/queries';
import {
  fetchQueries,
  fetchQueriesByCategory,
  fetchSuggestedMotivationQueries,
} from '../queries/queries';
import { fetchDemoUsers } from '../user/queries';
import { getWorkspaceFromParams, getWorkspaceIdFromParams } from '../workspace/queries';
import { fetchReport, fetchReports } from './queries';

export const reportLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { reportId } = params as { reportId: string };

  const [report, user, motivationDetails, suggestedMotivationQueries, queryTemplates] =
    await Promise.all([
      fetchReport({ reportId: Number(reportId), workspaceId }),
      authProvider.getUser(),
      fetchMotivationDetails(),
      fetchSuggestedMotivationQueries(),
      fetchQueries(),
    ]);
  if (!report) return redirect('..');

  const autoAsk = fetchAndClearAutoAsk();
  const queryTemplate = queryTemplates.find(
    (q) => q.id === report.askMarleeQuestion?.templateId,
  );
  const shareCode = report.shares?.find((s) => s.scope === 'anyoneWithLink')?.code;
  const shareLink = shareCode
    ? `${window.location.origin}/app/users/${user.id}/report/${shareCode}`
    : null;

  let pendingAssessment: Assessment | undefined = undefined;
  if (report.pendingReason === 'motivation') {
    // If the question is a template question, and maps to an existing suggested completion
    pendingAssessment = suggestedMotivationQueries.find(
      (s) => s.query.id === report.askMarleeTemplateId,
    )?.assessment;
    // Otherwise we need to use the intent of the question

    // Currently returning the full assessment
    if (!pendingAssessment) {
      const { assessments } = await assessmentsLoader();
      pendingAssessment = assessments.find((a) => a.slug === 'full');
    }
  }

  const pendingSentInvites = await fetchPendingSentInvites();
  return {
    user,
    report,
    motivationDetails,
    autoAsk,
    queryTemplate,
    suggestedMotivationQueries,
    pendingAssessment,
    pendingSentInvites,
    shareLink,
  };
};
export type ReportLoaderData = Exclude<
  Awaited<ReturnType<typeof reportLoader>>,
  Promise<Response> | Response
>;

export const reportListLoader = async ({ params }: LoaderFunctionArgs) => {
  const user = await authProvider.getUser();
  const workspace = await getWorkspaceFromParams(params);
  const reports = await fetchReports({ workspaceId: workspace?.id });

  const mentionData: MentionData[] = [];
  if (workspace) {
    mentionData.push({
      id: `workspace-${workspace.id}`,
      display: workspace.name,
      avatarUrl: workspace.avatarUrl,
      type: 'workspace' as const,
      workspace,
    });
    const randomMembers = randomSample(
      workspace.members,
      Math.min(5, workspace.members.length),
    ).filter((m) => m.userId !== user.id);

    for (const member of randomMembers) {
      mentionData.push({
        id: `user-${member.userId}`,
        display: member.user.fullName,
        avatarUrl: member.user.avatarUrl,
        type: 'user' as const,
        user: member.user,
        workspaces: [workspace],
      });
    }
  }

  const queriesByCategory = await fetchQueriesByCategory(mentionData);
  return { user, reports, queriesByCategory, workspace };
};

export const reportLatestLoader = async () => {
  const reports = await fetchReports();
  return redirect(`../${reports[0]?.id ?? ''}`);
};

export const exampleReportLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const reportLoaderResponse = await reportLoader({ request, params });
  const demoUsers = await fetchDemoUsers();

  return { ...reportLoaderResponse, demoUsers };
};
export type ExampleReportLoaderData = Exclude<
  Awaited<ReturnType<typeof exampleReportLoader>>,
  Promise<Response> | Response
>;

export const reportNextAssessmentLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const reportLoaderResponse = await reportLoader({
    request,
    params,
  });
  if (reportLoaderResponse instanceof Response) return reportLoaderResponse;
  const { report } = reportLoaderResponse;

  return { report };
};

export const reportAssessmentLoader = async ({ params }: LoaderFunctionArgs) => {
  const { blockSlug } = params as { blockSlug: AssessmentBlock };
  const { assessments } = await assessmentsLoader();
  const assessment = assessments.find((m) => m.slug === blockSlug);
  if (!assessment) return redirect('../..');

  const nextQuestionNumber = assessment.questions.findIndex((q) => !q.isAnswered);
  const nextPath = `question/${nextQuestionNumber === -1 ? 1 : nextQuestionNumber + 1}`;
  return redirect(nextPath);
};

export const reportAssessmentQuestionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const reportLoaderResponse = await reportLoader({
    request,
    params,
  });
  if (reportLoaderResponse instanceof Response) return reportLoaderResponse;
  const assessmentQuestionLoaderResponse = await assessmentQuestionLoader({
    request,
    params,
  });
  const { report, user } = reportLoaderResponse;
  const { assessment, currentQuestion, currentQuestionNo } =
    assessmentQuestionLoaderResponse;

  if (!assessment || !currentQuestion) return redirect('../../..');

  const previousStep =
    currentQuestionNo > 1 ? `../question/${currentQuestionNo - 1}` : `../../..`;
  const nextStep =
    currentQuestionNo !== assessment.questions.length
      ? `../question/${currentQuestionNo + 1}`
      : `../../..`;

  return {
    previousStep,
    nextStep,
    report,
    user,
    assessment,
    currentQuestion,
    currentQuestionNo,
  };
};

export type ReportAssessmentQuestionLoaderData = Exclude<
  Awaited<ReturnType<typeof reportAssessmentQuestionLoader>>,
  Promise<Response> | Response
>;

export const reportInviteLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const reportLoaderResponse = await reportLoader({
    request,
    params,
  });
  if (reportLoaderResponse instanceof Response) return reportLoaderResponse;
  const { report, user } = reportLoaderResponse;
  const nextStep = '..';

  return { report, nextStep, user };
};
export type ReportInviteLoaderData = Exclude<
  Awaited<ReturnType<typeof reportInviteLoader>>,
  Promise<Response> | Response
>;
