import { useQuery, type UndefinedInitialDataOptions } from '@tanstack/react-query';
import type { Params } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import { slugify } from '@/lib/utils';

import type { Member } from '../member/schema';
import { type MinimalUser, type User } from '../user/schema';
import { fetchOutgoingRequests } from '../workspace-requests/queries';

export type WorkspaceEntitlements = {
  'teams': boolean;
  'paid': boolean;
  'comparison-limit': number; // -1 = unlimited
};

export type WorkspaceAccess = {
  id: number;
  userId: number;
  user: MinimalUser;
  type: 'admin' | 'support' | 'consultant';
};
export type WorkspaceAccessInvite = {
  id: number;
  createdAt: string;
  type: string;
  status: 'pending';
  fromUser: MinimalUser;
  toUser: MinimalUser | null;
  sentToEmailAddress: string | null;
};

export type WorkspaceMemberInvite = {
  createdAt: string;
  fromUser: MinimalUser | null;
  toUser: (MinimalUser & { _count?: { questionnaires: number } }) | null; // TODO: clean up this assessmentCompleted handling at some point
  sentToEmailAddress: string | null;
};

type WorkspaceResponse = {
  id: number;
  name: string;
  companyName: string | null;
  isDiscoverable: boolean;
  avatarUrl: string | null;
  creator: MinimalUser;
  creatorId: number;
  createdAt: string;
  domains: { domainName: string }[];
  isFavorite: boolean;
  isAdmin: boolean;
  isMember: boolean;
  isPending: boolean;
  permissions: {
    allMembersCanInvite: boolean;
    memberViewEachOther: boolean;
    autoApproveJoinRequest: boolean;
  };
  members: {
    id: number;
    user_id: number;
    users: MinimalUser;
    assessmentCompleted: boolean;
  }[];
  memberCount: number;
  access: WorkspaceAccess[];
  entitlements: WorkspaceEntitlements;
};

export type Workspace = Omit<WorkspaceResponse, 'members' | 'creator'> & {
  slug: string;
  creator: User;
  members: Member[];
  isDummy?: boolean; // For dummy workspace handling
};

export const workspacesQuery = {
  queryKey: ['workspaces'],
  queryFn: async () => {
    const workspaces = (await apiClient.get('/api/v4/workspaces')) as WorkspaceResponse[];
    const slugSet = new Set<string>();

    return workspaces.map((w) => {
      let slug = slugify(w.name);
      if (slugSet.has(slug)) {
        slug += `-${w.id}`;
      }
      slugSet.add(slug);

      return {
        ...w,
        access: [],
        members: [],
        slug,
        creator: {
          ...w.creator,
          fullName: formatName(w.creator),
          initials: formatInitials(w.creator),
        },
      } as Workspace;
    });
  },
};

export const fetchWorkspaces = () => queryClient.fetchQuery(workspacesQuery);
export const useWorkspaces = () => useQuery(workspacesQuery);

export const workspaceQuery = (workspaceSlug?: string) => ({
  queryKey: ['workspace', workspaceSlug],
  queryFn: async () => {
    if (!workspaceSlug) return null;
    const workspaces = await fetchWorkspaces();
    const workspaceId = workspaces.find((w) => w.slug === workspaceSlug)?.id;
    if (!workspaceId) return null;
    const workspace = (await apiClient.get(
      `/api/v4/workspaces/${workspaceId}`,
    )) as WorkspaceResponse | null;
    if (!workspace) return null;

    const members: Member[] = workspace.members.map((u) => ({
      id: u.id,
      userId: u.user_id,
      user: {
        ...u.users,
        fullName: formatName(u.users),
        initials: formatInitials(u.users),
      },
      assessmentCompleted: u.assessmentCompleted,
    }));

    if (workspace.isAdmin) {
      // Also fetch pending invites who are also direct connections
      const pendingMembers = await fetchWorkspaceInvitePendingConnected(workspace.id);
      const pseudoMembers = pendingMembers.flatMap((m) =>
        // Filter out pending members who are, for some reason, already full members
        m.toUser && !members.some((member) => member.userId === m.toUser!.id)
          ? {
              id: -m.toUser.id, // WARNING: Setting a negative member id, equal to the negative of the userId
              userId: m.toUser.id,
              user: {
                ...m.toUser,
                fullName: formatName(m.toUser),
                initials: formatInitials(m.toUser),
              },
              isPending: true,
              completedAssessment: m.toUser._count?.questionnaires
                ? m.toUser._count.questionnaires > 0
                : undefined,
            }
          : [],
      );
      members.push(...pseudoMembers);
    }

    return {
      ...workspace,
      slug: workspaceSlug,
      members,
      creator: {
        ...workspace.creator,
        fullName: formatName(workspace.creator),
        initials: formatInitials(workspace.creator),
      },
    } as Workspace;
  },
});

export const fetchWorkspace = (workspaceSlug?: string) =>
  queryClient.fetchQuery(workspaceQuery(workspaceSlug));
export const useWorkspace = (workspaceSlug?: string) =>
  useQuery(workspaceQuery(workspaceSlug));

// For admins to see pending member invites to a space
const workspaceInvitePendingQuery = (workspaceId: number) => ({
  queryKey: ['workspace', 'invite', 'pending', workspaceId],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${workspaceId}/members/pending-invite`) as Promise<
      WorkspaceMemberInvite[]
    >,
});
export const fetchWorkspaceInvitePending = (workspaceId: number) =>
  queryClient.fetchQuery(workspaceInvitePendingQuery(workspaceId));
export const useWorkspaceInvitePending = (workspaceId: number) =>
  useQuery(workspaceInvitePendingQuery(workspaceId));

// For admins to see pending member invites who they are directly connected to
export const workspaceInvitePendingConnectedQuery = (workspaceId: number) => ({
  queryKey: ['workspace', 'invite', 'pending', 'connected', workspaceId],
  queryFn: async () =>
    apiClient.get(
      `/api/v4/workspaces/${workspaceId}/members/pending-invite/connected`,
    ) as Promise<WorkspaceMemberInvite[]>,
});
export const fetchWorkspaceInvitePendingConnected = (workspaceId: number) =>
  queryClient.fetchQuery(workspaceInvitePendingConnectedQuery(workspaceId));
export const useWorkspaceInvitePendingConnected = (workspaceId: number) =>
  useQuery(workspaceInvitePendingConnectedQuery(workspaceId));

const slackDetailsQuery = (workspaceId: number) => ({
  queryKey: ['/api/v3/slack', workspaceId],
  queryFn: async () =>
    apiClient
      .get(`/api/v3/slack/workspace/${workspaceId}`)
      .then(z.object({ redirectUri: z.string(), clientId: z.string() }).parse),
});

export const fetchSlackDetails = (workspaceId: number) =>
  queryClient.fetchQuery(slackDetailsQuery(workspaceId));

export const getWorkspaceIdFromSlug = async (workspaceSlug?: string) => {
  if (!workspaceSlug) return undefined;
  const workspaces = await fetchWorkspaces();
  return workspaces.find((w) => w.slug === workspaceSlug)?.id;
};

export const getWorkspaceSlugFromId = async (workspaceId?: number) => {
  if (!workspaceId) return undefined;
  const workspaces = await fetchWorkspaces();
  return workspaces.find((w) => w.id === workspaceId)?.slug;
};

export const getWorkspaceFromParams = async (params: Params) => {
  // TODO: rename route param to workspaceSlug
  const { workspaceSlug } = z
    .object({ workspaceSlug: z.string().optional() })
    .parse(params);
  return fetchWorkspace(workspaceSlug);
};

export const getWorkspaceSlugFromParams = (params: Params) => {
  const { workspaceSlug } = z
    .object({ workspaceSlug: z.string().optional() })
    .parse(params);
  return workspaceSlug;
};

export const getWorkspaceIdFromParams = async (params: Params) => {
  // TODO: rename route param to workspaceSlug
  const { workspaceSlug } = z
    .object({ workspaceSlug: z.string().optional() })
    .parse(params);
  return getWorkspaceIdFromSlug(workspaceSlug);
};

export const setNewWorkspaceUsers = (users: User[]) => {
  queryClient.setQueryData<User[]>(['workspace', 'new', 'users'], users);
};

export const fetchNewWorkspaceUsers = () => {
  return queryClient.getQueryData<User[]>(['workspace', 'new', 'users']) ?? [];
};

export const clearNewWorkspaceUsers = () => {
  setNewWorkspaceUsers([]);
  return queryClient.getQueryData<User[]>(['workspace', 'new', 'users']);
};

export const fetchAndClearNewWorkspaceUsers = () => {
  const users = fetchNewWorkspaceUsers();
  clearNewWorkspaceUsers();
  return users;
};

export const suggestedWorkspacesQuery = {
  queryKey: ['workspaces', 'suggestions'],
  queryFn: async () => {
    const workspaces = (await apiClient.get(
      '/api/v4/workspaces/suggestions',
    )) as WorkspaceResponse[];
    const slugSet = new Set<string>();

    return workspaces.map((w) => {
      let slug = slugify(w.name);
      if (slugSet.has(slug)) {
        slug += `-${w.id}`;
      }
      slugSet.add(slug);

      return {
        ...w,
        access: [],
        members: [],
        slug,
        creator: {
          ...w.creator,
          fullName: formatName(w.creator),
          initials: formatInitials(w.creator),
        },
      } satisfies Workspace;
    });
  },
};

export const fetchSuggestedWorkspaces = () =>
  queryClient.fetchQuery(suggestedWorkspacesQuery);
export const useSuggestedWorkspaces = () => useQuery(suggestedWorkspacesQuery);

const MIN_SEARCH_LENGTH = 3;
export type SpaceSearchResponse = {
  id: number;
  name: string;
  companyName: string | null;
  avatarUrl: string | null;
};
export const findSuggestedWorkspacesQuery = ({
  search,
  enabled = true,
}: {
  search: string;
  enabled?: boolean;
}) =>
  ({
    queryKey: [
      'workspaces',
      'suggestions',
      'search',
      search.length < MIN_SEARCH_LENGTH ? 'empty' : search,
    ],
    queryFn: async ({ signal }) => {
      if (search.length < MIN_SEARCH_LENGTH) return [];
      const myWorkspaces = await fetchWorkspaces();
      const suggestedWorkspaces = await fetchSuggestedWorkspaces();
      const outgoingRequests = await fetchOutgoingRequests();

      const foundWorkspaces = (await apiClient.post(
        '/api/v4/workspaces/suggestions',
        {
          name: search,
        },
        { signal },
      )) as SpaceSearchResponse[];

      return foundWorkspaces.map((fw) => {
        const suggested = suggestedWorkspaces.find((sw) => sw.id === fw.id);
        return {
          ...fw,
          isMember: myWorkspaces.some((w) => w.id === fw.id),
          isPending: outgoingRequests.some((r) => r.organization.id === fw.id),
          permissions: {
            autoApproveJoinRequest: !!suggested?.permissions.autoApproveJoinRequest,
          },
        };
      });
    },
    enabled,
  }) satisfies UndefinedInitialDataOptions;

export const fetchFindSuggestedWorkspaces = (params: {
  search: string;
  enabled?: boolean;
}) => queryClient.fetchQuery(findSuggestedWorkspacesQuery(params));
export const useFindSuggestedWorkspaces = (params: {
  search: string;
  enabled?: boolean;
}) => useQuery(findSuggestedWorkspacesQuery(params));
