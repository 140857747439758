import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';
import { fetchLocalPreference } from '@/providers/local-preference';

import { OnboardingSessionKeys } from '../../lib/onboarding-constants';
import { rootLoader } from '../root/loaders';
import { fetchTalentProfiles } from '../talent/profile/queries';
import { WORKSPACE_LOCAL_PREFERENCE_NAME } from '../workspace/components/workspace-switcher';
import { fetchWorkspaces } from '../workspace/queries';
import { updateUserFromPreSignup } from './actions';
import { checkIsNew, checkSignupCreated, getLoginMethod } from './utils';

/* -------------------------------------------------------------------------- */
/*                              ONBOARDING START                              */
/* -------------------------------------------------------------------------- */
export const onboardingIndexLoader = async (args: LoaderFunctionArgs) => {
  const user = await authProvider.getMaybeUser();
  if (!user) {
    return redirect('/signup');
  }

  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  // New signup
  if (checkIsNew()) {
    const loginMethod = getLoginMethod();
    checkSignupCreated({ signUpConnection: loginMethod ?? 'unknown' });

    // Check for data to sync from pre-signup
    await updateUserFromPreSignup();

    // Figure out where they need to go
    const onboardingTalentPoolId = sessionStorage.getItem(
      OnboardingSessionKeys.TalentPool,
    );

    if (onboardingTalentPoolId) {
      // Start talent pool onboarding
      return redirect('/onboarding/talent');
    }
    // Start space onboarding
    return redirect('/onboarding/spaces');
  }

  // Not a new user. Where do they need to go?
  const [workspaces, talentProfiles] = await Promise.all([
    fetchWorkspaces(),
    fetchTalentProfiles(),
  ]);

  if (workspaces.length > 0) {
    // Go to main app
    const lastWorkspaceId = await fetchLocalPreference<number>({
      preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
    });
    const workspace =
      (lastWorkspaceId && workspaces.find((w) => w.id === lastWorkspaceId)) ||
      workspaces[0];
    if (workspace) return redirect(`/spaces/${workspace.slug}`);
  }

  if (talentProfiles.length > 0) {
    // Go to talent hub
    return redirect('/talent');
  }

  // Even though they are an existing user, they do not have a space, and should go back through space onboarding
  return redirect('/onboarding/spaces');
};

/* -------------------------------------------------------------------------- */
/*                             ONBOARDING COMPLETE                            */
/* -------------------------------------------------------------------------- */
export const onboardingCompleteLoader = async (args: LoaderFunctionArgs) => {
  const rootLoaderResponse = await rootLoader(args);
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;

  const [workspaces, talentProfiles] = await Promise.all([
    fetchWorkspaces(),
    fetchTalentProfiles(),
  ]);

  if (workspaces.length === 0 && talentProfiles.length > 0) {
    // Go to talent hub
    sendEvent('signUp_completed', { signUpStep: 'talentHub' });
    return redirect('/talent');
  }

  // Go to main app
  const lastWorkspaceId = await fetchLocalPreference<number>({
    preferenceName: WORKSPACE_LOCAL_PREFERENCE_NAME,
  });
  const workspace =
    (lastWorkspaceId && workspaces.find((w) => w.id === lastWorkspaceId)) ||
    workspaces[0];

  if (workspace) {
    sendEvent('signUp_completed', { signUpStep: 'spaces' });
    return redirect(`/spaces/${workspace.slug}`);
  }

  return redirect('/onboarding/spaces');
};
