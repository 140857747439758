import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import { apiClient, queryClient } from '@f4s/api-client';
import type {
  CultureModel,
  InsightModel,
  ModelMatchRequest,
  ModelMatchResponse,
} from '@f4s/types';

import { mapMotivations, type MotivationRes } from '../motivation/queries';

type ModelQueryOpts = {
  usePercentiles?: boolean;
  cultureModelId?: number;
  cultureCode?: string;
};
const modelsQuery = ({ usePercentiles = true, ...rest }: ModelQueryOpts = {}) => {
  return {
    queryKey: ['modeling', 'models', { usePercentiles, ...rest }],
    queryFn: async () =>
      apiClient.get(
        `/api/v3/modeling/models?${queryString.stringify({ usePercentiles, ...rest })}`,
      ) as Promise<InsightModel[]>,
  };
};
export const fetchModelData = (opts?: ModelQueryOpts) =>
  queryClient.fetchQuery(modelsQuery(opts));
export const useModelData = (opts?: ModelQueryOpts) => useQuery(modelsQuery(opts));

const culturesQuery = {
  queryKey: ['modeling', 'cultures'],
  queryFn: async () =>
    apiClient.get(`/api/v3/modeling/cultures`) as Promise<CultureModel[]>,
};
export const fetchCultureData = () => queryClient.fetchQuery(culturesQuery);
export const useCultureData = () => useQuery(culturesQuery);

type CultureAggregateReqBody = {
  modelId?: number;
  cultureModelId?: number;
  cultureCode?: string;
};
const cultureAggregateQuery = (data: CultureAggregateReqBody) => ({
  queryKey: ['modeling', 'cultures', 'aggregate', data],
  queryFn: async () => {
    if (!data.modelId) return [];
    const aggregateMotivations = (await apiClient.post(
      `/api/v3/modeling/cultures/aggregate`,
      data,
    )) as MotivationRes[];
    return mapMotivations(aggregateMotivations);
  },
});
export const fetchCultureAggregate = (data: CultureAggregateReqBody) =>
  queryClient.fetchQuery(cultureAggregateQuery(data));
export const useCultureAggregate = (data: CultureAggregateReqBody) =>
  useQuery(cultureAggregateQuery(data));

const aggregateCulturesQuery = {
  queryKey: ['modeling', 'cultures', 'aggregate'],
  queryFn: async () =>
    apiClient.get(`/api/v3/modeling/cultures/aggregate`) as Promise<CultureModel[]>,
};
export const fetchAggregateCultures = () =>
  queryClient.fetchQuery(aggregateCulturesQuery);
export const useAggregateCultures = () => useQuery(aggregateCulturesQuery);

const matchQuery = (params: ModelMatchRequest) => ({
  queryKey: ['modeling', 'match', params],
  queryFn: async () =>
    apiClient.post(`/api/v3/modeling/matches`, params) as Promise<ModelMatchResponse>,
});
export const fetchModelMatchData = (params: ModelMatchRequest) =>
  queryClient.fetchQuery(matchQuery(params));
export const useModelMatchData = (params: ModelMatchRequest) =>
  useQuery(matchQuery(params));

const workspaceModelListQuery = (params: { workspaceId: number | string }) => ({
  queryKey: ['modeling', 'workspace', params.workspaceId],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${params.workspaceId}/models`) as Promise<
      InsightModel[]
    >,
});
export const fetchWorkspaceModelList = (
  params: Parameters<typeof workspaceModelListQuery>[0],
) => queryClient.fetchQuery(workspaceModelListQuery(params));
export const useWorkspaceModelList = (
  params: Parameters<typeof workspaceModelListQuery>[0],
) => useQuery(workspaceModelListQuery(params));

const workspaceModelQuery = (params: {
  workspaceId: number | string;
  modelId: number | string;
}) => ({
  queryKey: ['modeling', 'workspace', params],
  queryFn: async () =>
    apiClient.get(
      `/api/v4/workspaces/${params.workspaceId}/models/${params.modelId}`,
    ) as Promise<InsightModel>,
});
export const fetchWorkspaceModel = (params: Parameters<typeof workspaceModelQuery>[0]) =>
  queryClient.fetchQuery(workspaceModelQuery(params));
export const useWorkspaceModel = (params: Parameters<typeof workspaceModelQuery>[0]) =>
  useQuery(workspaceModelQuery(params));
