import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from 'react';

import type { TestAttributes } from '../lib/interfaces';
import { cn } from '../lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './command';
import { Popover, PopoverAnchor, PopoverContent } from './popover';
import { ScrollBar } from './scroll-area';

interface InputAutocompleteProps<T> extends TestAttributes {
  selection: { label: string; key: string; value: T } | null;
  selections: { label: string; key: string; value: T }[];
  onValueChange?: (value: string) => void;
  onSelectionChange: (selection: { label: string; key: string; value: T } | null) => void;
  selectionsRenderer?: (args: {
    selections: { label: string; key: string; value: T }[];
    handleSelection: (key: string) => void;
  }) => ReactElement;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  hideIcon?: boolean;
}

export const InputAutocomplete = <T,>({
  defaultValue = '',
  selection,
  selections,
  onValueChange,
  onSelectionChange,
  selectionsRenderer,
  placeholder = 'Search for a value...',
  className,
  disabled,
  hideIcon,
  ...props
}: InputAutocompleteProps<T>) => {
  const [value, setValue] = useState<string>(selection?.label ?? defaultValue);
  useEffect(() => {
    // Sync external state change, eg: discard
    setValue(selection?.label ?? defaultValue);
  }, [selection?.label, defaultValue]);

  const [open, setOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleValueChange = useCallback(
    (val: string) => {
      setValue(val);
      onValueChange?.(val);
    },
    [onValueChange],
  );

  const handleSelection = useCallback(
    (key: string) => {
      const newSelection = selections.find((s) => s.key === key);
      setValue(newSelection?.label ?? '');
      onSelectionChange(newSelection ?? null);
      setOpen(false);
    },
    [onSelectionChange, selections],
  );

  const handleClose = useCallback(() => {
    let prevSelectionLabel = selection?.label ?? defaultValue;
    setValue((prevValue) => {
      if (prevValue === '') {
        prevSelectionLabel = '';
        onSelectionChange(null);
        return prevValue;
      }
      return prevSelectionLabel;
    });
  }, [defaultValue, onSelectionChange, selection?.label]);

  const handleOpen = useCallback(
    (shouldOpen: boolean) => {
      if (!shouldOpen) {
        handleClose();
      }
      setOpen(shouldOpen);
    },
    [handleClose],
  );

  const handleToggle = useCallback(() => {
    setOpen((isOpen) => {
      const shouldOpen = !!value || !isOpen;
      if (!shouldOpen) {
        handleClose();
      }
      return shouldOpen;
    });
  }, [handleClose, value]);

  const selectionList = useMemo(() => {
    if (selectionsRenderer) {
      return selectionsRenderer({ selections, handleSelection });
    }
    return (
      <CommandGroup>
        <CommandEmpty>Start typing to see suggestions</CommandEmpty>
        {selections.map((s) => (
          <CommandItem
            key={s.key}
            value={s.key}
            onSelect={handleSelection}
            className="hover:cursor-pointer"
          >
            {s.label}
          </CommandItem>
        ))}
      </CommandGroup>
    );
  }, [handleSelection, selections, selectionsRenderer]);

  return (
    <Popover open={open} onOpenChange={handleOpen}>
      <Command shouldFilter={false} className="h-auto overflow-visible bg-transparent">
        <PopoverAnchor>
          <CommandInput
            placeholder={placeholder}
            className={cn(
              'placeholder:text-muted-foreground/60 h-auto rounded-none py-0 disabled:opacity-100',
              className,
            )}
            value={value}
            onValueChange={handleValueChange}
            onFocus={() => handleOpen(true)}
            onKeyDown={(e) => handleOpen(e.key !== 'Escape' && e.key !== 'Tab')}
            onMouseDown={handleToggle}
            ref={inputRef}
            disabled={disabled}
            hideIcon={hideIcon}
            data-testid={props['data-testid']}
          />
        </PopoverAnchor>

        {/* This needs to be hear to appease cmdk */}
        {!open && <CommandList aria-hidden="true" className="hidden" />}

        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          onWheel={(e) => {
            e.stopPropagation();
          }}
          onInteractOutside={(e) => {
            // Ignore interaction with the CommandInput element
            if (e.target instanceof Element && e.target.isEqualNode(inputRef.current)) {
              e.preventDefault();
            }
          }}
          avoidCollisions
          collisionPadding={{ bottom: 48, top: 48 }}
        >
          <ScrollAreaPrimitive.Root className={cn('h-full w-full overflow-hidden')}>
            <ScrollAreaPrimitive.Viewport className="size-full max-h-[--radix-popper-available-height]">
              <CommandList
                data-testid={
                  props['data-testid'] ? props['data-testid'] + '-list' : undefined
                }
              >
                {selectionList}
              </CommandList>
            </ScrollAreaPrimitive.Viewport>
            <ScrollBar className="z-20" />
          </ScrollAreaPrimitive.Root>
        </PopoverContent>
      </Command>
    </Popover>
  );
};
