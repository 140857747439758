import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Badge,
  DataGrid,
  DataGridHeader,
  DataGridHeaderItem,
  DataGridItem,
  DataGridRow,
  Icon,
  Input,
} from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';

import type { TalentPool } from '../queries';

export const useTalentPoolList = ({ talentPools }: { talentPools: TalentPool[] }) => {
  const [filteredPools, setFilteredPools] = useState(talentPools);
  const [filter, setFilter] = useState<string>('');

  useEffect(() => {
    let sorted = [...talentPools].sort((a, b) => {
      // First sort by hasJoined (true values first)
      if (a.hasJoined !== b.hasJoined) {
        return a.hasJoined ? -1 : 1;
      }
      // Then sort alphabetically by name
      return a.name.localeCompare(b.name);
    });

    if (filter) {
      sorted = sorted.filter((p) => p.name.toLowerCase().includes(filter.toLowerCase()));
    }

    setFilteredPools(sorted);
  }, [filter, talentPools]);

  const renderRow = useCallback((row: TalentPool) => {
    return (
      <DataGridRow asChild>
        <NavLinkButton
          className="relative grid-cols-2 gap-0 rounded-none px-4"
          variant="ghost"
          to={`/talent/pools/${row.externalId}`}
        >
          <DataGridItem>{row.name}</DataGridItem>
          <DataGridItem className="flex-1 text-right">
            {row.hasJoined ? (
              <Badge variant="success" size="xs">
                Joined
              </Badge>
            ) : null}
          </DataGridItem>
        </NavLinkButton>
      </DataGridRow>
    );
  }, []);

  return useMemo(() => {
    return {
      list: (
        <DataGrid
          rowHeight={48}
          rows={filteredPools ?? []}
          className="min-h-0 grid-cols-[minmax(auto,1fr)_minmax(auto,1fr)_minmax(auto,1fr)]"
          rowHeader={
            <DataGridHeader>
              <DataGridHeaderItem>Name</DataGridHeaderItem>
              <DataGridHeaderItem>
                <span className="sr-only">Joined</span>
              </DataGridHeaderItem>
            </DataGridHeader>
          }
          rowRender={renderRow}
        />
      ),
      filter: (
        <Input
          placeholder="Search pools"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          className="max-w-60"
          icon={<Icon.MagnifyingGlass size={18} />}
        />
      ),
    };
  }, [filteredPools, filter, renderRow]);
};
