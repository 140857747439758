import { clsx } from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import {
  Button,
  Card,
  cn,
  Dialog,
  DialogClose,
  DialogPortal,
  DialogTitle,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerPortal,
  DrawerTitle,
  Hidden,
  Icon,
  RadixDialogContent,
  ScrollArea,
  useMediaQuery,
} from '@f4s/ui';

import { ScrollContainer } from '@/components/scroll-container';
import { useScrollRestoration } from '@/lib/hooks/use-scroll-restoration';
import { type RootContextType } from '@/modules/root/types';

export const ModalOutlet = ({
  outlet,
  color,
  className,
  useDrawer,
  fullHeight,
  disableClickOutside,
}: {
  outlet: ReactElement | null;
  color?: string;
  className?: string;
  useDrawer?: boolean;
  fullHeight?: boolean;
  disableClickOutside?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useScrollRestoration();
  const { modalPortal } = useOutletContext<RootContextType>();
  const [currentOutlet, setCurrentOutlet] = useState<ReactElement | null>(null);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isDrawerClosing, setDrawerClosing] = useState<boolean>(false);
  useEffect(() => {
    if (isDrawerClosing) return;
    setDrawerOpen(!!outlet);
  }, [isDrawerClosing, outlet]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => {
    clearTimeout(timeoutRef.current);

    if (!outlet) {
      timeoutRef.current = setTimeout(() => {
        setCurrentOutlet(null);
        setDrawerClosing(false);
        setIsFirstRender(true);
      }, 500);
      return;
    } else {
      setCurrentOutlet(outlet);
    }
    // We want to delay display on first render to trigger transition
    setIsFirstRender(false);

    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setCurrentOutlet(null);
        setDrawerClosing(false);
      }, 500);
    };
  }, [location.pathname, outlet]);

  const handleDrawerClose = useCallback(
    (open: boolean) => {
      if (disableClickOutside) return;
      setDrawerClosing(true);
      setDrawerOpen(open);
      if (!open && outlet) {
        navigate('.', { preventScrollReset: true });
      }
    },
    [disableClickOutside, navigate, outlet],
  );

  const handleClickOutside = useCallback(() => {
    if (disableClickOutside) return;
    navigate('.', { preventScrollReset: true });
  }, [disableClickOutside, navigate]);

  return (useDrawer ?? isMobile) ? (
    <Drawer
      open={drawerOpen}
      onOpenChange={handleDrawerClose}
      noBodyStyles
      repositionInputs={false}
      handleOnly={!isMobile}
      dismissible={!disableClickOutside}
      modal
    >
      <DrawerPortal forceMount>
        <DrawerContent
          onOpenAutoFocus={(e) => {
            // Prevent some autofocus, would be nice to not need to do this
            e.preventDefault();
            if (e.target && 'focus' in e.target) {
              (e.target as HTMLDivElement).focus();
            }
          }}
          className={cn(
            'mt-10 max-h-[calc(100%_-_4rem)] !select-text flex-col',
            fullHeight && 'h-full',
          )}
        >
          <Hidden>
            {/* TODO: These need to be sorted out for accessibility, currently suppressing console errors */}
            <DrawerTitle>Drawer</DrawerTitle>
            <DrawerDescription>This is a modal</DrawerDescription>
          </Hidden>
          <ScrollArea ref={scrollRef} className="h-full w-full min-w-full max-w-full">
            {currentOutlet}
          </ScrollArea>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  ) : (
    <Dialog open={!!currentOutlet || !!outlet} modal={true}>
      <ModalDialogContent
        onClick={handleClickOutside}
        color={color}
        manual={true}
        shouldDisplay={(outlet && !isFirstRender) || false}
        modalPortal={modalPortal}
        className={className}
        hideClose={disableClickOutside}
      >
        {currentOutlet}
      </ModalDialogContent>
    </Dialog>
  );
};

export const ModalDialogContent = forwardRef<
  HTMLDivElement,
  {
    color?: string;
    onClick?: MouseEventHandler<HTMLElement>;
    children?: ReactNode;
    manual?: boolean;
    shouldDisplay?: boolean;
    modalPortal?: HTMLDivElement | null;
    noOverflow?: boolean;
    className?: string;
    hideClose?: boolean;
  }
>(
  (
    {
      color,
      onClick,
      children,
      manual,
      shouldDisplay,
      modalPortal,
      noOverflow,
      className,
      hideClose,
    },
    ref,
  ) => {
    return (
      <DialogPortal container={modalPortal}>
        <RadixDialogContent
          className={clsx(
            'bg-foreground/60 absolute inset-0 z-[100] flex h-full w-full flex-col items-center transition-opacity !duration-500 will-change-[opacity,contents] [&_div[data-radix-popper-content-wrapper]]:!z-[110]',
            manual
              ? shouldDisplay
                ? 'pointer-events-auto opacity-100'
                : 'opacity-0'
              : 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
          )}
          ref={ref}
        >
          <ScrollContainer
            className={cn('[&>div>div]:relative', noOverflow && '[&>div>div]:max-h-full')}
          >
            <DialogClose
              onClick={(e) => onClick?.(e)}
              className={cn(
                'absolute h-full w-full',
                hideClose && 'hover:cursor-default',
              )}
            />
            {/* Appease radix's dialog component by including a dummy dialog title - suppress errors in sentry */}
            <Hidden>
              <DialogTitle>Popup modal content</DialogTitle>
            </Hidden>
            <div
              className={cn(
                'flex h-full w-full flex-col px-2 pb-8 pt-2 sm:p-8',
                noOverflow && 'overflow-hidden',
              )}
            >
              <div
                className={cn(
                  'z-[75] mx-auto flex min-h-[50vh] w-full max-w-screen-sm',
                  className,
                  color,
                )}
              >
                <Card
                  className="bg-background relative flex w-full flex-col border-none shadow-lg hover:cursor-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {!hideClose && (
                    <DialogClose onClick={(e) => onClick?.(e)} asChild>
                      <Button
                        variant="link"
                        size="icon-sm"
                        className="absolute right-2 top-2 z-50 rounded-full"
                      >
                        <Icon.X />
                      </Button>
                    </DialogClose>
                  )}
                  {children}
                </Card>
              </div>
            </div>
          </ScrollContainer>
        </RadixDialogContent>
      </DialogPortal>
    );
  },
);
ModalDialogContent.displayName = 'ModalDialogContent';
