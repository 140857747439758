import { useOutlet, useOutletContext } from 'react-router-dom';

import { Card } from '@f4s/ui';

import MarleeLogo from '@/assets/marlee-logo.svg?react';
import { MinimalLayout } from '@/components/layout-minimal';

export const LayoutSignupMinimal = () => {
  return (
    <MinimalLayout hideHeader>
      <Card className="bg-background sm:bg-card sm:ring-border/5 flex w-full flex-col items-center gap-8 rounded-none p-6 !pb-12 shadow-none ring-0 sm:max-w-xl sm:rounded-xl sm:p-12 sm:shadow-sm">
        <div className="flex items-center justify-center px-4">
          <MarleeLogo className="text-foreground h-[32px] w-auto dark:text-white" />
        </div>
        {useOutlet(useOutletContext())}
      </Card>
    </MinimalLayout>
  );
};
