import type { RouteObject } from 'react-router-dom';

import { LayoutSignupMinimal } from '../signup/pages/layout';
import { codeRequestAction, loginAction } from './actions';
import { loginLoader } from './loader';
import { LoginPage } from './pages/login';

export const loginRoutes: RouteObject = {
  Component: LayoutSignupMinimal,
  children: [
    {
      path: 'login',
      Component: LoginPage,
      loader: loginLoader,
      action: loginAction,
      children: [{ path: 'code', action: codeRequestAction }],
    },
  ],
};
