import { redirect, type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import { startProgramAction } from './actions';
import { coachingProgramLoader, coachingProgramsListLoader } from './loaders';

export const coachingRoutes: RouteObject = {
  path: 'coaching',
  handle: handle({
    type: 'coaching',
    breadcrumb: SimpleBreadcrumb({ title: 'Coaching' }),
  }),
  children: [
    { index: true, loader: () => redirect('programs') },
    // {
    //   path: 'chat',
    //   loader: chatLoader,
    //   async lazy() {
    //     const { ChatPage } = await import('./pages/chat');
    //     return { Component: ChatPage };
    //   },
    // },
    {
      path: 'programs',
      children: [
        {
          index: true,
          handle: { crumb: { title: 'Programs' } },
          async lazy() {
            const { CoachingPrograms } = await import('./pages/list');
            return { Component: CoachingPrograms };
          },
          loader: coachingProgramsListLoader,
        },
        {
          path: ':programId',
          async lazy() {
            const { CoachingProgramDetails } = await import('./pages/details');
            return { Component: CoachingProgramDetails };
          },
          loader: coachingProgramLoader,
          handle: { crumb: { type: 'coachingProgram' } },
          children: [
            {
              path: 'start',
              action: startProgramAction,
            },
          ],
        },
      ],
    },
  ],
};
