import { useDataFromLoader } from '@/lib/hooks/loader';

import { JtbdDetailPage } from '../components/jtbd-detail-page';
import type { signupLoader } from '../loaders';

export const SignupCollaboratePage = () => {
  const { category, queries, selectedQueryIds } =
    useDataFromLoader<typeof signupLoader>();

  return (
    <JtbdDetailPage
      title="Great! You're here to enable seamless teamwork."
      subtitle="Now, let's refine that, what specific areas would help you collaborate more
        effectively? Select as many as you'd like."
      category={category}
      queries={queries}
      selectedQueryIds={selectedQueryIds}
    />
  );
};
