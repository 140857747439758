'use client';

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCallback, useState, type ReactElement } from 'react';
import { createPortal } from 'react-dom';

import { type QuestionOptions } from '@f4s/types';

import { AnswerItem } from './answer-item';

type Props = {
  answers: QuestionOptions[];
  onChange: (newOrder: QuestionOptions[]) => void;
};

export const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1) as [T];
  result.splice(endIndex, 0, removed);
  return result;
};

export const AnswerList = ({ answers, onChange }: Props): ReactElement => {
  const [currentlyDragging, setCurrentlyDragging] = useState<
    QuestionOptions | undefined
  >();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // TODO: Simplify sorting logic to work better with IDs
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over || active.id === over.id) return;

      const oldIndex = answers.findIndex((answer) => answer.text === active.id);
      const newIndex = answers.findIndex((answer) => answer.text === over.id);

      if (oldIndex === -1 || newIndex === -1) return;

      const newOrder = reorder(answers, oldIndex, newIndex);

      onChange(newOrder);
      setCurrentlyDragging(undefined);
    },
    [answers, onChange],
  );

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      const dragId = event.active.id;
      setCurrentlyDragging(answers.find((answer) => answer.text === dragId));
    },
    [answers],
  );

  return (
    <DndContext onDragEnd={onDragEnd} onDragStart={onDragStart} sensors={sensors}>
      <SortableContext
        items={answers.map((answer) => ({ ...answer, id: answer.text }))}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex flex-col gap-2">
          {answers.map(({ text }) => (
            <AnswerItem key={text} text={text} />
          ))}
        </div>
      </SortableContext>
      {createPortal(
        <DragOverlay>
          {currentlyDragging && <AnswerItem text={currentlyDragging.text} />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};
