import { type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import {
  fetchAndClearShouldEdit,
  fetchTalentLegacyTags,
  fetchTalentProfile,
  fetchTalentProfiles,
} from './queries';

export const talentProfilesLoader = async () => {
  const talentProfiles = await fetchTalentProfiles();
  return { talentProfiles };
};

export const talentProfileLoader = async ({ params }: LoaderFunctionArgs) => {
  const { profileId } = params as {
    profileId: string;
  };
  const user = await authProvider.getUser();
  const legacyTags = await fetchTalentLegacyTags();
  const talentProfile = await fetchTalentProfile({ profileId: Number(profileId) });
  const shouldEdit = fetchAndClearShouldEdit();
  return { talentProfile, legacyTags, user, shouldEdit };
};

export const talentProfileNewLoader = async () => {
  const user = await authProvider.getUser();
  const legacyTags = await fetchTalentLegacyTags();
  return { user, talentProfile: undefined, legacyTags, shouldEdit: true };
};
