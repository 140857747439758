import { redirect, useFetcher, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  talentOpeningCreateReqSchema,
  type TalentOpeningCreateReq,
  type TalentOpeningUpdateReq,
} from '@f4s/types';

import { getWorkspaceIdFromSlug } from '@/modules/workspace/queries';

import type { TalentOpeningDetails } from './queries';

// Create new talent opening
export const createTalentOpeningAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const body = await request.json();
  const data = talentOpeningCreateReqSchema.parse(body);

  const { workspaceSlug } = z.object({ workspaceSlug: z.string() }).parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) throw new Error('Workspace ID is undefined');

  // TODO: zod parse data
  const talentOpening = (await apiClient.post(
    `/api/v4/workspaces/${workspaceId}/talent/openings`,
    data,
  )) as TalentOpeningDetails;

  await queryClient.invalidateQueries({
    queryKey: ['talent', 'openings', 'workspace', workspaceId],
  });
  // Redirect to newly created opening
  return redirect(`../${talentOpening.id}`);
};

export function useCreateTalentOpening() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof createTalentOpeningAction>>>();
  return {
    ...fetcher,
    submit: (data: TalentOpeningCreateReq) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
      }),
  };
}

// Update talent opening
export const updateTalentOpeningAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { openingId, workspaceSlug } = z
    .object({ openingId: z.coerce.number(), workspaceSlug: z.string() })
    .parse(params);
  const workspaceId = await getWorkspaceIdFromSlug(workspaceSlug);
  if (!workspaceId) throw new Error('Workspace ID is undefined');

  // DELETE
  if (request.method === 'DELETE') {
    await apiClient.delete(
      `/api/v4/workspaces/${workspaceId}/talent/openings/${openingId}`,
    );
    await queryClient.invalidateQueries({
      queryKey: ['talent', 'openings', 'workspace', workspaceId],
    });
    return redirect('..');
  }

  const data = await request.json();
  // TODO: zod parse data
  const talentOpening = (await apiClient.patch(
    `/api/v4/workspaces/${workspaceId}/talent/openings/${openingId}`,
    data,
  )) as TalentOpeningDetails;
  // Update the cache with the returned opening.
  queryClient.setQueryData(
    ['talent', 'openings', 'workspace', workspaceId, openingId],
    talentOpening,
  );

  return talentOpening;
};

export function useUpdateTalentOpening() {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateTalentOpeningAction>>>();
  return {
    ...fetcher,
    submit: ({
      data = null,
      method = 'POST',
    }:
      | {
          data: TalentOpeningUpdateReq;
          method?: 'POST';
        }
      | {
          data?: null;
          method: 'DELETE';
        }) =>
      fetcher.submit(data, {
        method,
        encType: 'application/json',
      }),
  };
}
