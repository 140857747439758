import copy from 'copy-to-clipboard';
import { type ReactNode } from 'react';

import { Button } from './button';
import { useToast } from './use-toast';

type CopyableInputProps = {
  value: string | null;
  icon?: ReactNode;
  onCopy?: () => void;
  onCopyMessage?: string;
  buttonText?: string;
  allowCopyToClipboard?: boolean;
};

export const CopyableInput = ({
  value,
  icon,
  onCopy,
  onCopyMessage,
  buttonText,
  allowCopyToClipboard = true,
}: CopyableInputProps) => {
  const { toast } = useToast();

  return (
    <div className="ring-border/5 !bg-card flex h-9 justify-between rounded-md !pl-2.5 shadow-sm ring-1">
      <div className="text-muted-foreground flex w-full items-center">
        {icon}
        <input
          className="text-muted-foreground w-full overflow-hidden truncate border-none !bg-transparent px-2 py-1.5 text-base md:text-sm"
          type="text"
          value={value ?? 'Loading...'}
          disabled
        />
      </div>
      <Button
        className="shrink-0 !rounded-l-none"
        variant="ghost"
        onClick={() => {
          onCopy?.();
          if (allowCopyToClipboard) {
            copy(value ?? '', { format: 'text/plain' });
            toast({ title: onCopyMessage ?? 'Copied to clipboard' });
          }
        }}
        type="button"
      >
        {buttonText ?? 'Copy link'}
      </Button>
    </div>
  );
};
