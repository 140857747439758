import { useQuery } from '@tanstack/react-query';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import type { MinimalUser } from '@/modules/user/schema';

type GroupStatus =
  | 'inited'
  | 'approved'
  | 'pending'
  | 'rejected'
  | 'removed'
  | 'canceled';

export type LegacyGroup = {
  id: number;
  name: string;
  imageUrl: string | null;
  groupUsers: {
    user: MinimalUser | null;
    isAdmin: boolean;
    isMember: boolean;
    isViewer: boolean;
    status: GroupStatus;
  }[];
  organization: { id: number; name: string } | null;
};

export const legacyGroupsQuery = {
  queryKey: ['users', 'me', 'groups'],
  queryFn: async () => {
    const me = await authProvider.getUser();
    const groups = (await apiClient.get('/api/v4/users/me/groups')) as LegacyGroup[];
    return groups.map((g) => ({
      id: g.id,
      name: g.name,
      members: g.groupUsers.flatMap((m) => {
        if (!m.user) return [];
        const isMe = m.user.id === me.id;
        return {
          id: m.user.id,
          userId: m.user.id,
          user: {
            ...m.user,
            initials: formatInitials(m.user),
            fullName: isMe ? 'Me' : formatName(m.user),
          },
          isAdmin: m.isAdmin,
          isMember: m.isMember,
          isViewer: m.isViewer,
          isMe,
        };
      }),
      workspace: g.organization,
      avatarUrl: g.imageUrl,
    }));
  },
};
export const fetchLegacyGroups = () => queryClient.fetchQuery(legacyGroupsQuery);
export const useLegacyGroups = () => useQuery(legacyGroupsQuery);
