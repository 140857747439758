import { type ComponentPropsWithoutRef, type ReactNode } from 'react';

import { cn, Icon, Tooltip, TooltipContent, TooltipTrigger } from '@f4s/ui';

import { NavLinkButton } from '@/components/nav-link';

export const NavItem = ({
  variant = 'ghost',
  title,
  children,
  className,
  iconClassName,
  IconComponent,
  AvatarComponent,
  isMobile,
  isCollapsed,
  disableActive,
  endOnly,
  truncateLines = 1,
  ...props
}: ComponentPropsWithoutRef<typeof NavLinkButton> & {
  title?: string;
  children?: ReactNode;
  className?: string;
  iconClassName?: string;
  IconComponent?: ReactNode;
  AvatarComponent?: ReactNode;
  isCollapsed?: boolean;
  isMobile?: boolean;
  disableActive?: boolean;
  endOnly?: boolean;
  truncateLines?: number;
}) => {
  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <NavLinkButton
          className={cn(
            'hover:bg-accent relative w-full min-w-0 justify-start gap-2 px-1.5',
            isCollapsed && '!w-auto',
            isMobile && 'h-full min-w-0 items-center justify-center',
            className,
          )}
          activeClassName={disableActive ? '' : 'bg-accent'}
          variant={variant}
          width={isCollapsed ? 'auto' : 'full'}
          childFocus={false}
          end={endOnly}
          size={isCollapsed ? 'icon' : 'default'}
          {...props}
        >
          {IconComponent &&
            (isMobile ? (
              IconComponent
            ) : (
              <Icon.IconWrapper className={iconClassName}>
                {IconComponent}
              </Icon.IconWrapper>
            ))}
          {AvatarComponent && <div className="shrink-0">{AvatarComponent}</div>}
          {!isCollapsed && (
            <div className={cn(truncateLines === 1 ? 'line-clamp-1' : 'line-clamp-2')}>
              {children ?? title}
            </div>
          )}
        </NavLinkButton>
      </TooltipTrigger>
      <TooltipContent
        side="right"
        className={cn('pointer-events-none z-[100]', !isCollapsed && 'hidden')}
      >
        {title}
      </TooltipContent>
    </Tooltip>
  );
};
