/* eslint-disable unicorn/prefer-spread */
const normalize = (str: string) => str.toLowerCase().replaceAll(/\s+/g, '');

export class FuzzySearcher<T extends { key: string }> {
  private trigramCache: Map<string, { trigrams: Set<string>; bigrams: Set<string> }> =
    new Map();
  private resultSet: (T & { normKey: string; score: number })[] = [];
  private originalResultSet: (T & { normKey: string; score: number })[] = [];
  private limit: number | undefined = undefined;
  private resultCache: Map<string, T[]> = new Map(); // LRU cache for search results
  private resultCacheSize: number;

  constructor({
    array,
    limit,
    resultCacheSize = 10,
  }: {
    array: T[];
    limit?: number;
    resultCacheSize?: number;
  }) {
    this.limit = limit === 0 ? undefined : limit;
    this.resultCacheSize = resultCacheSize;

    this.resultSet = array.map((r) => {
      // Pre-compute normalized keys
      const normKey = normalize(r.key);
      // Pre-compute ngrams for each item in the array
      this.trigramCache.set(normKey, {
        trigrams: this.createNgrams(normKey, 3),
        bigrams: this.createNgrams(normKey, 2),
      });
      return { ...r, normKey, score: Infinity };
    });
    this.originalResultSet = this.resultSet.slice();
  }

  private createNgrams(text: string, n: number): Set<string> {
    const ngrams = new Set<string>();
    for (let i = 0; i <= text.length - n; i++) {
      ngrams.add(text.slice(i, i + n));
    }
    return ngrams;
  }

  private calculateNgramScore(ngrams1: Set<string>, ngrams2: Set<string>): number {
    let intersectionSize = 0;
    for (const ngram of ngrams1) {
      if (ngrams2.has(ngram)) {
        intersectionSize += 2;
      }
    }
    return ngrams1.size + ngrams2.size - intersectionSize;
  }

  private calculateCharacterOverlap(input: string, target: string): number {
    let overlap = 0;
    for (const char of input) {
      if (target.includes(char)) overlap++;
    }
    return input.length - overlap; // Lower score is better
  }

  public search({ value, limit = this.limit }: { value: string; limit?: number }): T[] {
    if (!value) {
      return this.originalResultSet.slice(0, limit);
    }
    const normalizedSearch = value.toLowerCase().replaceAll(/\s+/g, '');

    const cacheKey = `${normalizedSearch}-${limit}`;
    const cachedResult = this.resultCache.get(cacheKey);
    if (cachedResult) {
      // Move to the front of the cache.
      this.resultCache.delete(cacheKey);
      this.resultCache.set(cacheKey, cachedResult);
      // Return the cache hit
      return cachedResult;
    }

    const checkValue = value !== normalizedSearch;
    const searchTrigrams = this.createNgrams(normalizedSearch, 3);
    const searchBigrams = this.createNgrams(normalizedSearch, 2);

    for (let i = 0; i < this.resultSet.length; i++) {
      const item = this.resultSet[i]!;

      // Exact match short circuit
      if (normalizedSearch === item.normKey) {
        item.score = Number.NEGATIVE_INFINITY;
        continue;
      }

      const { trigrams: itemTrigrams, bigrams: itemBigrams } = this.trigramCache.get(
        item.normKey,
      )!;

      // Calculate a combined score based on trigram and bigram overlap
      const trigramScore = this.calculateNgramScore(searchTrigrams, itemTrigrams);
      const bigramScore = this.calculateNgramScore(searchBigrams, itemBigrams);
      const characterOverlapScore = this.calculateCharacterOverlap(
        normalizedSearch,
        item.normKey,
      );

      // Prefer matches that start with the search value.
      // Weight exact case and whitespace matches higher.
      let includeBoost = 0;
      if (checkValue && item.key.startsWith(value)) {
        includeBoost = -20;
      } else if (item.normKey.startsWith(normalizedSearch)) {
        includeBoost = -15;
      } else if (checkValue && item.key.includes(value)) {
        includeBoost = -10;
      } else if (item.normKey.includes(normalizedSearch)) {
        includeBoost = -5;
      }

      const score = trigramScore + bigramScore + characterOverlapScore + includeBoost;

      item.score = score;
    }

    // Sort in place, limit creation of new big arrays
    this.resultSet.sort((a, b) => a.score - b.score);

    // Create a new array of limited size
    const result = this.resultSet.slice(0, limit);

    if (this.resultCache.size > this.resultCacheSize) {
      const oldestKey = this.resultCache.keys().next().value!;
      this.resultCache.delete(oldestKey);
    }
    this.resultCache.set(cacheKey, result);

    return result;
  }
}
