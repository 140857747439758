import type { RouteObject } from 'react-router-dom';

import { codeRequestAction, loginAction } from '../login/actions';
import { signupIndexAction, signupJtbdQueryAction } from './actions';
import {
  signupCompleteLoader,
  signupCreateLoader,
  signupIndexLoader,
  signupLoader,
} from './loaders';
import { SignupCollaboratePage } from './pages/collaborate';
import { SignupConnectPage } from './pages/connect';
import { CreatePage } from './pages/create';
import { SignupDevelopPage } from './pages/develop';
import { SignupHirePage } from './pages/hire';
import { LayoutSignupMinimal } from './pages/layout';
import { SignupMatchPage } from './pages/match';
import { SignupMotivatePage } from './pages/motivate';
import { SignupWelcomePage } from './pages/welcome';

export const signupRoutes: RouteObject = {
  path: 'signup',
  Component: LayoutSignupMinimal,
  children: [
    // SIGNUP START
    {
      index: true,
      loader: signupIndexLoader,
      action: signupIndexAction,
      Component: SignupWelcomePage,
    },

    // SIGNUP JTBD
    {
      path: 'collaborate',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupCollaboratePage,
    },
    {
      path: 'motivate',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupMotivatePage,
    },
    {
      path: 'connect',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupConnectPage,
    },
    {
      path: 'develop',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupDevelopPage,
    },
    {
      path: 'hire',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupHirePage,
    },
    {
      path: 'match',
      loader: signupLoader,
      action: signupJtbdQueryAction,
      Component: SignupMatchPage,
    },

    // SIGNUP COMPLETE
    { path: 'complete', loader: signupCompleteLoader },

    {
      path: 'create',
      Component: CreatePage,
      loader: signupCreateLoader,
      action: loginAction,
      children: [{ path: 'code', action: codeRequestAction }],
    },
  ],
};
