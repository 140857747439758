import { useCallback } from 'react';

import { NavLinkButton } from '@/components/nav-link';
import { PageHeaderTitle } from '@/components/page-header';
import { usePersistedDataFromLoader } from '@/lib/hooks/loader';
import { AssessmentLanguageSelector } from '@/modules/assessment/components/assessment/language-selector';

import { setAnalysisDeferred } from '../../utils';
import type { onboardingAnalysisIndexLoader } from '../loaders';

export const OnboardingAnalysisIntroPage = () => {
  const { canSkip, assessmentLanguage } =
    usePersistedDataFromLoader<typeof onboardingAnalysisIndexLoader>();

  const handleSkip = useCallback(() => {
    setAnalysisDeferred();
  }, []);

  return (
    <div className="flex flex-col gap-4 p-4 sm:p-6">
      <PageHeaderTitle
        title={
          canSkip
            ? "You haven't completed the full motivational analysis"
            : "Let's start the motivational analysis!"
        }
        className="whitespace-normal"
      />
      <p>
        These questions help Marlee understand your unique work motivations so Marlee can
        help you achieve your goals.
      </p>

      <div className="flex flex-col gap-1">
        <p className="font-semibold">Make sure you:</p>
        <ul className="">
          <li>🤫 Are in a quiet space</li>
          <li>⏰ Have 15 - 20 uninterrupted minutes free</li>
          <li>❤️ Answer honestly imagining your ideal work environment</li>
        </ul>
      </div>

      <div className="flex justify-between gap-2">
        <AssessmentLanguageSelector
          language={assessmentLanguage ?? 'en-us'}
          className="w-9 sm:w-auto"
        />
        <div className="flex gap-2">
          {canSkip && (
            <NavLinkButton
              to="complete"
              data-testid="onboarding-analysis-skip-button"
              variant="secondary"
              onClick={handleSkip}
            >
              Skip
            </NavLinkButton>
          )}
          <NavLinkButton to="full" data-testid="onboarding-analysis-begin-button">
            Begin
          </NavLinkButton>
        </div>
      </div>
    </div>
  );
};
