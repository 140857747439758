export const clearCache = async (redirectTo?: string) => {
  try {
    localStorage.clear();

    // Clear cache API
    const cacheKeys = await caches.keys();
    for (const cacheKey of cacheKeys) {
      await caches.delete(cacheKey);
    }

    // Clear service worker registrations
    const registrations = await navigator.serviceWorker?.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }

    // Reload the window
    location.replace(redirectTo ?? location.href);
  } catch (error) {
    console.error('Error clearing cache data', error);
    // Reload the window
    location.replace(redirectTo ?? location.href);
  }
};
