import { useMemo } from 'react';
import { useMatches, type UIMatch } from 'react-router-dom';

import { useUser } from '@f4s/api-client';

import type { ComponentData } from '@/lib/hooks/loader';
import type { teamLoader } from '@/modules/team/loaders';

import { type MemberLoaderData, type MembersLoaderData } from '../../member/loaders';
import { type ModelLoaderData } from '../../modeling/loaders';
import {
  type MotivationGroupLoaderData,
  type MotivationLoaderData,
} from '../../motivation/loaders';
import { type WorkspaceLoaderData } from '../../workspace/loaders';
import { useSuggestions, type MentionData } from '../queries';

export const useMentionSuggestions = () => {
  const { user } = useUser();
  const { data: { connectedUsers = [], teams = [], workspaces = [] } = {} } =
    useSuggestions();

  const matches = useMatches() as UIMatch<unknown, { type?: string }>[];

  // The following gives us the current context of wherever ask marlee is being used from
  // TODO: Create a common data structure to pass context in to the AskMarlee component to augment functionality
  return useMemo(() => {
    const suggestionMap = new Map<string, MentionData>();
    // Reverse matches to start from the closest route.
    matches.reverse().forEach((m) => {
      // If the following exist we know what type the data is
      if (m.handle && m.handle.type) {
        switch (m.handle.type) {
          // These could be used to narrow what kinds of questions are asked
          case 'motivation': {
            const motivation = (m.data as MotivationLoaderData).motivation;
            return { type: 'motivation', motivation };
          }
          case 'motivationGroup': {
            const motivationGroup = (m.data as MotivationGroupLoaderData).motivationGroup;
            return { type: 'motivationGroup', motivationGroup };
          }
          case 'model': {
            const model = (m.data as ModelLoaderData).workspaceModel;
            return { type: 'model', model };
          }

          // These can be used to narrow questions and suggested @mentions data
          case 'workspace': {
            const workspace = (m.data as WorkspaceLoaderData)?.workspace;
            if (workspace) {
              suggestionMap.set(`workspace-${workspace.id}`, {
                id: `workspace-${workspace.id}`,
                display: workspace.name,
                avatarUrl: workspace.avatarUrl,
                type: 'workspace' as const,
                workspace,
              });
            }
            return;
          }

          case 'team': {
            const team = (m.data as ComponentData<typeof teamLoader>).team;
            suggestionMap.set(`selection-${team.id}`, {
              id: `selection-${team.id}`,
              display: team.name,
              avatarUrl: null,
              type: 'selection' as const,
              selection: team,
            });
            return;
          }

          case 'members': {
            const members = (m.data as MembersLoaderData).members;
            members.forEach((member) => {
              if (member.userId && member.userId !== user.id) {
                suggestionMap.set(`user-${member.userId}`, {
                  id: `user-${member.userId}`,
                  display: member.user.fullName,
                  avatarUrl: member.user.avatarUrl,
                  type: 'user' as const,
                  user: member.user,
                  workspaces: [], // This will get overwritten by the next set
                });
              }
            });
            return;
          }

          case 'member': {
            const member = (m.data as MemberLoaderData).member;
            if (member.id && member.userId !== user.id) {
              suggestionMap.set(`user-${member.userId}`, {
                id: `user-${member.userId}`,
                display: member.user.fullName,
                avatarUrl: member.user.avatarUrl,
                type: 'user' as const,
                user: member.user,
                workspaces: [], // This will get overwritten by the next set
              });
            }
            return;
          }
        }
      } else {
        return [];
      }
    });

    // As a Map preserves insertion order, if they were previously set in the above switch they will appear earlier in the resulting array
    // The following fills the rest of our suggestions out with all our connections
    // TODO: We could get more complex with these 'out of scope' results and ask users to perform different operations, such as navigation
    connectedUsers.forEach((v) => suggestionMap.set(v.id, v));
    teams.forEach((v) => suggestionMap.set(v.id, v));
    workspaces.forEach((v) => suggestionMap.set(v.id, v));

    return [...suggestionMap.values()];
  }, [connectedUsers, matches, teams, user.id, workspaces]);
};
