import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';

import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

type CreditQueryResponse = {
  periodicCredits: number;
  periodicCreditsRemaining: number;
  bonusCreditsRemaining: number;
};

type CreditQueryArgs = {
  workspaceId?: number;
  type: string;
};

export const creditQuery = ({ workspaceId, type }: CreditQueryArgs) => ({
  queryKey: ['credits', workspaceId, type],
  queryFn: async () => {
    if (workspaceId === 0) {
      // This is a dummy workspace used to populate the wizard background, return dummy numbers
      return {
        periodicCredits: 0,
        periodicCreditsRemaining: 0,
        bonusCreditsRemaining: 0,
        creditsUsed: 0,
        totalCredits: 0,
      };
    }
    const data = (await apiClient.get(
      workspaceId
        ? `/api/v4/workspaces/${workspaceId}/credits/${type}`
        : `/api/v3/credits/${type}`,
    )) as CreditQueryResponse;
    return {
      ...data,
      creditsUsed: data.periodicCredits - data.periodicCreditsRemaining,
      totalCredits: data.periodicCreditsRemaining + data.bonusCreditsRemaining,
    };
  },
});

export const fetchCredits = (args: CreditQueryArgs) =>
  queryClient.fetchQuery(creditQuery(args));
export const useCredits = (type: string) => {
  const match = useMatchedWorkspace();
  return useQuery(creditQuery({ type, workspaceId: match.workspace?.id }));
};
