import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  QuestionAnswerSchema,
  type QuestionAnswer,
} from '@f4s/types/common/questionnaire';

import { overTimeQuery } from '../motivation/queries';

export async function assessmentUpdate(data: QuestionAnswer[]) {
  await apiClient.post('/api/v3/questionnaires/', data);

  // TODO: Verify literally all of these with workspace scope.
  // Invalidate caches
  await queryClient.invalidateQueries({ queryKey: ['assessment'] });
  await queryClient.invalidateQueries({ queryKey: ['questionnaire', 'current'] });
  await queryClient.invalidateQueries({ queryKey: ['report', 'my-results'] });
  await queryClient.invalidateQueries({ queryKey: ['dashboard', 'data'] });
  // Invalidate latest motivations and completion status
  await queryClient.invalidateQueries({ queryKey: ['motivations'] });
  await queryClient.invalidateQueries({ queryKey: ['queries', 'user'] });
  await queryClient.invalidateQueries({ queryKey: ['onboarding-progress'] });

  // Invalidate org members due to assessment status there
  await queryClient.invalidateQueries({ queryKey: ['workspace'] });
  await queryClient.invalidateQueries({ queryKey: ['connections', 'members'] });

  // Invalidate the over-time query
  await queryClient.invalidateQueries(overTimeQuery);

  localStorage.removeItem('assessmentBlockData');
}

export async function assessmentUpdateAction({ request }: ActionFunctionArgs) {
  const { redirectTo, data } = z
    .object({ redirectTo: z.string(), data: QuestionAnswerSchema.array() })
    .parse(await request.json());
  await assessmentUpdate(data);

  return redirect(redirectTo);
}
