import { useCallback } from 'react';
import {
  redirect,
  useFetcher,
  useSubmit,
  type ActionFunctionArgs,
} from 'react-router-dom';
import { z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  reportCreateRequestSchema,
  ReportSchema,
  ShareReportCreateRequestSchema,
  type ReportCreateRequestData,
  type ReportUpdateRequestData,
  type ShareReportCreateRequest,
} from '@f4s/types';
import { QuestionAnswerSchema } from '@f4s/types/common/questionnaire';

import { sendEvent } from '@/lib/analytics';

import { setAutoAsk } from '../ask-marlee/queries';
import { assessmentUpdate } from '../assessment/actions';
import { useMatchedWorkspace } from '../workspace/hooks/use-workspace-match';
import { getWorkspaceIdFromParams } from '../workspace/queries';
import { reportQuery, reportsQuery } from './queries';

const updateFeedback = async (data: {
  questionId: number;
  feedbackIsPositive?: boolean | null;
  feedbackComment?: string | null;
  templateId?: number | null;
}) => {
  await apiClient.post('/api/v3/ask-marlee/updateQuestion', data);
  return null;
};

export const shareReport = async ({ request, params }: ActionFunctionArgs) => {
  const jsonData = await request.json();
  const workspaceId = await getWorkspaceIdFromParams(params);
  const data = ShareReportCreateRequestSchema.parse(jsonData);

  const response = (await apiClient.post('/api/v3/share-v2', data)) as { code: string };
  await queryClient.invalidateQueries(
    reportQuery({ reportId: data.reportId, workspaceId }),
  );
  return response.code;
};

export const useReportShareAction = () => {
  const fetcher = useFetcher<Awaited<ReturnType<typeof shareReport>>>();
  const { pathname } = useMatchedWorkspace();

  return {
    ...fetcher,
    submit: (data: ShareReportCreateRequest) =>
      fetcher.submit(data, {
        action: `${pathname}/queries/${data.reportId}/share`,
        method: 'POST',
        encType: 'application/json',
      }),
  };
};

export const reportFeedbackAction = async ({ request, params }: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { reportId } = z.object({ reportId: z.coerce.number() }).parse(params);
  const jsonData = await request.json();
  const data = z
    .object({
      questionId: z.number(),
      feedbackIsPositive: z.boolean().nullish(),
      templateId: z.number().nullish(),
    })
    .parse(jsonData);

  sendEvent('askMarlee_feedback', {
    feedbackResponse: data.feedbackIsPositive ? 'positive' : 'negative',
    templateId: data.templateId,
  });
  await updateFeedback(data);
  await queryClient.invalidateQueries(reportQuery({ reportId, workspaceId }));
  return null;
};

export const reportFeedbackCommentAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { reportId } = z.object({ reportId: z.coerce.number() }).parse(params);
  const formData = await request.formData();
  const jsonData = Object.fromEntries(formData.entries());

  const data = z
    .object({
      questionId: z.coerce.number(),
      feedbackComment: z.string().nullish(),
      templateId: z.coerce.number().nullish(),
    })
    .parse(jsonData);

  await updateFeedback(data);
  await queryClient.invalidateQueries(reportQuery({ reportId, workspaceId }));
  return null;
};

export const reportCreateAction = async ({ request, params }: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const formData = await request.formData();
  const jsonData = Object.fromEntries(formData.entries());

  const data = reportCreateRequestSchema.parse(jsonData);

  const response = await apiClient.post(
    workspaceId ? `/api/v4/workspaces/${workspaceId}/reports` : '/api/v4/reports',
    data,
  );
  const reportData = ReportSchema.parse(response);

  await queryClient.setQueryData(
    reportQuery({ reportId: reportData.id, workspaceId }).queryKey,
    reportData,
  ); // Add the newly created report to the individual report cache
  await queryClient.invalidateQueries(reportsQuery({ workspaceId }));
  // Set autoAsk for use after redirecting;
  setAutoAsk(true);
  return redirect(
    workspaceId
      ? `/spaces/${params.workspaceSlug}/queries/${reportData.id}`
      : `/personal/queries/${reportData.id}`,
  );
};
export type ReportCreateActionData = Exclude<
  Awaited<ReturnType<typeof reportCreateAction>>,
  Promise<Response> | Response
>;

export const useReportCreateAction = () => {
  const { pathname = '/personal' } = useMatchedWorkspace() ?? {};
  const submit = useSubmit();
  return useCallback(
    (data: ReportCreateRequestData) =>
      submit(data, { action: `${pathname}/queries/new`, method: 'POST' }),
    [pathname, submit],
  );
};

// TODO: consider mounting as a child of :reportId
export const refreshReport = async ({ request, params }: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const jsonData = await request.json();
  const data = z.object({ reportId: z.number() }).parse(jsonData);
  await queryClient.invalidateQueries(
    reportQuery({ reportId: data.reportId, workspaceId }),
  );

  return null;
};

export const useReportRefresh = () => {
  const fetcher = useFetcher();
  return (reportId: number) =>
    fetcher.submit(
      { reportId },
      {
        method: 'POST',
        encType: 'application/json',
        action: '../refresh',
      },
    );
};

export const reportAssessmentUpdateAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { reportId } = z.object({ reportId: z.coerce.number() }).parse(params);
  const { redirectTo, data } = z
    .object({ redirectTo: z.string(), data: QuestionAnswerSchema.array() })
    .parse(await request.json());
  await assessmentUpdate(data);
  // TODO: Consider taking this return value and injecting into the cache instead of invalidating
  await apiClient.patch(
    workspaceId
      ? `/api/v4/workspaces/${workspaceId}/reports/${reportId}`
      : `/api/v4/reports/${reportId}`,
    {
      status: 'ready',
    } as ReportUpdateRequestData,
  );
  setAutoAsk(true);
  await queryClient.invalidateQueries(reportQuery({ reportId, workspaceId }));

  return redirect(redirectTo);
};

export const updateReportAction = async ({ request, params }: ActionFunctionArgs) => {
  const workspaceId = await getWorkspaceIdFromParams(params);
  const { prompt, reportId } = z
    .object({ prompt: z.string(), reportId: z.number() })
    .parse(await request.json());

  // TODO: Consider taking this return value and injecting into the cache instead of invalidating
  await apiClient.patch(
    workspaceId
      ? `/api/v4/workspaces/${workspaceId}/reports/${reportId}`
      : `/api/v4/reports/${reportId}`,
    {
      title: prompt.replaceAll(/@\[(.*?)]\(.*?\)/g, '$1'),
      prompt,
      status: 'ready',
    } as ReportUpdateRequestData,
  );
  setAutoAsk(true);
  await queryClient.invalidateQueries(reportsQuery({ workspaceId }));
  await queryClient.invalidateQueries(reportQuery({ reportId, workspaceId }));

  return redirect('..');
};

export const useUpdateReportAction = () => {
  const fetcher = useFetcher<Awaited<ReturnType<typeof updateReportAction>>>();
  return {
    ...fetcher,
    submit: (data: { reportId: number; prompt: string }) =>
      fetcher.submit(data, {
        method: 'POST',
        encType: 'application/json',
        action: `../update`,
      }),
  };
};
