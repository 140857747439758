import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, authProvider, queryClient } from '@f4s/api-client';
import { talentProfileCreateReqSchema } from '@f4s/types';

import {
  talentProfileQuery,
  talentProfilesQuery,
  type TalentProfileDetails,
} from '@/modules/talent/profile/queries';

export const onboardingTalentProfileUpdateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const formData = await request.formData();

  const redirectTo = z.string().nullish().parse(formData.get('redirectTo'));

  // User data
  const userData = z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      countryCode: z.string(),
    })
    .partial()
    .parse(JSON.parse(formData.get('userData') as string));

  if (Object.keys(userData).length > 0) {
    // update user details
    try {
      await authProvider.updateUser(userData);
    } catch (error) {
      console.error('Error update user details', error);
    }
  }

  // Profile data
  const profileData = z
    .object({
      name: z.string().default('My Talent Profile'),
      countryCode: talentProfileCreateReqSchema.shape.countryCode.nullish(),
      isAuthorized: z.boolean().default(true),
      location: talentProfileCreateReqSchema.shape.location.nullish(),
      availability: talentProfileCreateReqSchema.shape.availability.nullish(),
    })
    .parse(JSON.parse(formData.get('profileData') as string));

  const profileId = z.coerce.number().nullish().parse(formData.get('profileId'));
  let talentProfile: TalentProfileDetails | undefined = undefined;
  // Update or create a talent profile
  if (Object.keys(profileData).length > 0) {
    talentProfile = (
      profileId
        ? await apiClient.patch(`/api/v4/talent/profiles/${profileId}`, profileData)
        : await apiClient.post(`/api/v4/talent/profiles`, profileData)
    ) as TalentProfileDetails;
  }

  // Upload the user's CV
  const file = formData.get('file');
  if (talentProfile && file) {
    const imageFormData = new FormData();
    imageFormData.append('file', file);

    talentProfile = (await apiClient.post(
      `/api/v4/talent/profiles/${talentProfile.id}/upload`,
      imageFormData,
      { isFormData: true },
    )) as TalentProfileDetails;
  }

  if (talentProfile) {
    // Set and clear some caches
    queryClient.setQueryData(
      talentProfileQuery({ profileId: talentProfile.id }).queryKey,
      talentProfile,
    );
    await queryClient.invalidateQueries(talentProfilesQuery);
  }

  return redirect(redirectTo ?? '/');
};
