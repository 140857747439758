import { redirect, type ActionFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, authProvider, type User } from '@f4s/api-client';

import { setIsNew, setLoginMethod } from '../utils';

export type LoginError = {
  method: 'email' | 'code' | 'password' | 'unknown';
  message: string;
} | null;
type LoginResult = {
  user: User | null;
  isNew: boolean;
  askForPassword: boolean;
  askForCode: boolean;
  error?: LoginError;
};

const defaultLoginResult: LoginResult = {
  user: null,
  isNew: false,
  askForPassword: false,
  askForCode: false,
  error: null,
};

export const loginAction = async ({ request }: ActionFunctionArgs) => {
  const json = await request.json();

  const { success, data } = z
    .object({
      emailAddress: z.string().trim().email(),
      password: z.string().optional(),
      code: z.string().length(6).optional(),
      redirectTo: z.string().optional(),
    })
    .safeParse(json);
  if (!success || !data) {
    return {
      ...defaultLoginResult,
      error: { method: 'email', message: 'Invalid email address' } as LoginError,
    };
  }
  const { redirectTo = '/', ...postData } = data;

  try {
    const res = (await apiClient.post('/auth/v3/check', postData)) as LoginResult;
    if (res.user) {
      setLoginMethod('email');
      // Logged in
      const user = await authProvider.setUser({ response: res.user });
      if (user) {
        setIsNew(res.isNew);
        return redirect(redirectTo);
      }
    }

    if (data.password || data.code) {
      // Invalid login attempt
      return {
        ...res,
        error: {
          method: data.code ? 'code' : 'password',
          message: 'Invalid login attempt',
        } as LoginError,
      };
    }

    // Checked email for required login type
    return { ...res, error: null };
  } catch (error) {
    console.error('Error checking email', error);
    return {
      ...defaultLoginResult,
      error: { method: 'unknown', message: 'Error checking email' } as LoginError,
    };
  }
};

export type LoginActionData = Exclude<
  Awaited<ReturnType<typeof loginAction>>,
  Promise<Response> | Response
>;

export const codeRequestAction = async ({ request }: ActionFunctionArgs) => {
  const json = await request.json();
  const { success, data } = z
    .object({
      emailAddress: z.string().trim().email(),
    })
    .safeParse(json);
  if (!success || !data) {
    return { error: 'Invalid email' };
  }

  try {
    await apiClient.post('/auth/v3/token/request', data);
    return { error: null };
  } catch (error) {
    console.error('Error sending code by email', error);
    return { error: 'Error sending code by email' };
  }
};
