import { type RouteObject } from 'react-router-dom';

import {
  acceptInviteAction,
  acceptMagicLinkAction,
  disableMagicLinkAction,
  enableMagicLinkAction,
  rejectInviteAction,
  sendInvitesAction,
} from './actions';
import {
  connectedInviteLoader,
  invitationLoader,
  invitationTokenLoader,
  magicLinkLoader,
} from './loaders';

export const inviteRoutes: RouteObject = {
  path: 'invite',
  children: [
    {
      path: 'view/:token/connected',
      loader: connectedInviteLoader,
      async lazy() {
        const { ConnectedPage } = await import('./pages/connected');
        return { Component: ConnectedPage };
      },
    },
    {
      path: 'view/:token',
      loader: invitationLoader,
      async lazy() {
        const { InvitePage } = await import('./pages/invite');
        return { Component: InvitePage };
      },
    },
    // Magic link handling
    {
      path: 'link/:token',
      children: [
        {
          index: true,
          loader: magicLinkLoader,
        },
        {
          path: 'accept',
          action: acceptMagicLinkAction,
        },
        // {
        //   path: 'connected',
        //   loader: connectedLinkLoader,
        //   async lazy() {
        //     const { ConnectedPage } = await import('./pages/connected');
        //     return { Component: ConnectedPage };
        //   },
        // },
      ],
    },

    // User came in via invite link
    {
      path: ':token/:action?',
      loader: invitationTokenLoader,
    },

    {
      path: 'send',
      action: sendInvitesAction,
    },
    {
      path: 'accept',
      action: acceptInviteAction,
    },
    {
      path: 'reject',
      action: rejectInviteAction,
    },

    // TODO: This should move to the workspace
    {
      path: 'magic-link',
      action: enableMagicLinkAction,
      children: [
        {
          path: 'disable',
          action: disableMagicLinkAction,
        },
      ],
    },
  ],
};
