import { type LoaderFunctionArgs } from 'react-router-dom';

import { authProvider } from '@f4s/api-client';

import { fetchPersonalConnections } from '@/modules/_legacy/connections/queries';

import { fetchAllSentInvites } from '../invite/queries';
import { fetchPublicProfile, fetchPublicResource, type PublicResource } from './queries';

export const publicProfileLoader = async ({ params }: LoaderFunctionArgs) => {
  if (!params.userId) throw new Error('No user id provided');
  const userId = Number.parseInt(params.userId);

  const user = await authProvider.getMaybeUser();
  const profile = await fetchPublicProfile(userId);
  const response = { profile, isConnected: false, isInvited: false };

  if (user) {
    const personalConnections = await fetchPersonalConnections();
    const invites = await fetchAllSentInvites();
    response.isConnected = personalConnections.some((conn) => conn.id === profile.id);
    response.isInvited = invites.some((inv) => inv.toUser?.id === profile.id);
  }

  return response;
};

export type PublicProfileLoaderData = Exclude<
  Awaited<ReturnType<typeof publicProfileLoader>>,
  Promise<Response> | Response
>;

export const publicResourceLoader =
  <T extends PublicResource>(resourceType: T) =>
  async (args: LoaderFunctionArgs) => {
    const { code } = args.params;
    if (!code) throw new Error('No code provided');

    const maybeUser = await authProvider.getMaybeUser();
    let isConnected = false;
    const data = await fetchPublicResource(resourceType, code);
    const shareUser = data.owner.user;

    if (maybeUser) {
      const personalConnections = await fetchPersonalConnections();

      isConnected = shareUser
        ? personalConnections.some((connection) => connection.id === shareUser.id)
        : false;
    }

    return {
      data,
      shareCode: code,
      shareUser,
      isLoggedIn: Boolean(maybeUser),
      isConnected,
    };
  };

export type PublicResourceLoaderData = Exclude<
  Awaited<ReturnType<typeof publicResourceLoader>>,
  Promise<Response> | Response
>;
