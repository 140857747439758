import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { apiClient, authProvider } from '@f4s/api-client';

import { fetchMagicLinkByToken, type MagicLinkWithOrg } from '../invite/queries';
import { rootLoader } from '../root/loaders';
import { fetchWorkspaces } from '../workspace/queries';
import {
  fetchInviteReceived,
  fetchInviteSent,
  fetchInvitesReceived,
  fetchInvitesSent,
  type Invitation,
  type InvitationWithOrg,
} from './queries';

export const inviteHubLoader = async () => {
  const user = await authProvider.getMaybeUser();
  const workspaces = user ? await fetchWorkspaces() : [];
  return { user, workspaces };
};

// Logged in
export const invitesReceivedLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const { user } = rootLoaderResponse;
  const invites = await fetchInvitesReceived();

  return { user, invites };
};
export const inviteReceivedLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const { user } = rootLoaderResponse;
  const { inviteId } = z.object({ inviteId: z.coerce.number() }).parse(params);
  const invite = await fetchInviteReceived(inviteId);

  return { user, invite };
};

export const invitesSentLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const { user } = rootLoaderResponse;
  const invites = await fetchInvitesSent();

  return { user, invites };
};

export const inviteSentLoader = async ({ request, params }: LoaderFunctionArgs) => {
  // Root loader will handle redirects
  const rootLoaderResponse = await rootLoader({ request, params });
  if (rootLoaderResponse instanceof Response) return rootLoaderResponse;
  const { user } = rootLoaderResponse;
  const { inviteId } = z.object({ inviteId: z.coerce.number() }).parse(params);
  const invite = await fetchInviteSent({ inviteId });

  return { user, invite };
};

// Maybe logged in

// Direct invite via emailed token
export const inviteTokenLoader = async ({ params }: LoaderFunctionArgs) => {
  const { token } = z.object({ token: z.string() }).parse(params);
  const user = await authProvider.getMaybeUser();
  // Direct API call, not much point using the query cache here.
  const invite = (await apiClient.get(
    `/api/v4/invitations/token/${token}`,
  )) as Invitation | null;
  if (!invite || !invite.organizations) return redirect('/invites/missing');

  return { user, token, invite: invite as InvitationWithOrg };
};

// Magic link
export const magicTokenLoader = async ({ params }: LoaderFunctionArgs) => {
  const { token } = z.object({ token: z.string() }).parse(params);
  const user = await authProvider.getMaybeUser();
  try {
    const link = await fetchMagicLinkByToken({ token });
    if (!link || !link.organizations) return redirect('/invites/missing');
    return { user, token, link: link as MagicLinkWithOrg };
  } catch (error) {
    console.warn('There was an error finding the token', error);
    return redirect('/invites/missing');
  }
};
