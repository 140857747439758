import { QueryClient as TanstackQueryClient } from '@tanstack/react-query';

import { HTTPError, NotLoggedInError } from './errors';

const MAX_RETRIES = 3;
const NO_RETRY_HTTP_CODES = [401, 402, 403, 404];

class QueryClient {
  private static instance: TanstackQueryClient;

  public static getInstance() {
    if (!QueryClient.instance) {
      QueryClient.instance = new TanstackQueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 60, // 60 minutes
            retry: (failureCount, error) => {
              if (failureCount > MAX_RETRIES) {
                return false;
              }

              if (error instanceof HTTPError) {
                return !NO_RETRY_HTTP_CODES.includes(error.code);
              } else if (error instanceof NotLoggedInError) {
                return false;
              } else {
                console.log(`Aborting retry due to unexpected error: ${String(error)}`);
                return false;
              }
            },
            retryDelay: (failureCount, error) => {
              if (error instanceof HTTPError && error.code === 429) {
                // Cloudflare rate-limit hit, wait at least 10 seconds.
                return 11000;
              }
              return Math.min(1000 * 2 ** failureCount, 30000);
            },
          },
        },
      });
    }
    return QueryClient.instance;
  }
}
export const queryClient = QueryClient.getInstance();

export const refetchEvery = ({
  minutes,
}: {
  minutes: number;
}): { staleTime: number; refetchInterval: number | false } => ({
  staleTime: minutes <= 0 ? Number.POSITIVE_INFINITY : minutes * 60 * 1000,
  refetchInterval: minutes <= 0 ? false : minutes * 60 * 1000,
});
