import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { z } from 'zod';

import { getWorkspaceFromParams } from '../workspace/queries';
import { fetchRequestsList } from './queries';

export const requestListLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');

  const requestList = await fetchRequestsList(workspace.id);
  return { workspace, requestList };
};

export const requestLoader = async ({ params }: LoaderFunctionArgs) => {
  const workspace = await getWorkspaceFromParams(params);
  if (!workspace) return redirect('/');
  const { requestId } = z.object({ requestId: z.coerce.number() }).parse(params);
  const requestList = await fetchRequestsList(workspace.id);
  const request = requestList.find((a) => a.id === requestId);
  if (!request) return redirect('..');

  return { workspace, request };
};
