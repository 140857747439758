import { useDataFromLoader } from '@/lib/hooks/loader';

import { JtbdDetailPage } from '../components/jtbd-detail-page';
import type { signupLoader } from '../loaders';

export const SignupConnectPage = () => {
  const { category, queries, selectedQueryIds } =
    useDataFromLoader<typeof signupLoader>();

  return (
    <JtbdDetailPage
      title="Great! You're here to foster a strong, inclusive team culture"
      subtitle="Now, let's refine that, what would help you and your team feel connected, valued, and supported? Select as many as you'd like."
      category={category}
      queries={queries}
      selectedQueryIds={selectedQueryIds}
    />
  );
};
