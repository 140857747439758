import { type ReactNode } from 'react';

type SectionProps = {
  children: ReactNode;
  className?: string;
};

export function Section({ children, className = '' }: SectionProps) {
  return (
    <section className={`flex w-full flex-col gap-y-4 pt-4 ${className}`}>
      {children}
    </section>
  );
}
