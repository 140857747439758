import { cva, type VariantProps } from 'cva';
import * as React from 'react';

import { cn } from '../lib/utils';

export const inputVariants = cva(
  [
    'focus:!ring-primary placeholder:text-muted-foreground/60 ring-border/5 hover:ring-border/20 bg-card inline-flex h-9 w-full items-center justify-center gap-2 rounded-md border-none px-3 py-1 text-base font-normal shadow-sm ring-1 transition-all duration-100 file:border-0 file:bg-transparent file:text-sm file:font-medium focus:!ring-2 focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 md:text-sm',
  ],
  {
    variants: {
      size: {
        default: 'h-9 rounded-md px-3 py-1',
        sm: 'h-8 rounded-md px-3 py-2 text-xs',
        lg: 'h-10 rounded-lg px-4 py-2',
        xl: 'h-14 rounded-xl px-5 py-2 text-lg',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputVariants> {
  icon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, size, icon, ...props }, ref) => {
    return (
      <div className="relative flex w-full items-center">
        {icon && (
          <div className="text-muted-foreground pointer-events-none absolute left-3 flex h-full items-center">
            {icon}
          </div>
        )}
        <input
          type={type}
          className={cn(inputVariants({ size }), icon ? 'pl-9' : '', className)}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
