import { Messages } from './utils.messages';

export function getPublicCTA({
  user,
  shareUser,
  isLoggedIn,
  isConnected,
}: {
  user?: { id: number } | null;
  shareUser?: {
    id: number;
    firstName: string | null;
    lastName: string | null;
  };
  isLoggedIn: boolean;
  isConnected: boolean;
}) {
  const needsInvite = isLoggedIn && !isConnected;
  if (!isLoggedIn)
    return {
      copy: Messages.notLoggedInCta(shareUser?.firstName),
      buttonCopy: Messages.notLoggedInButtonCta(),
      destination: '/onboarding',
      needsInvite: false,
    };
  // The user is viewing their own share link
  if (user?.id === shareUser?.id)
    return {
      copy: '',
      buttonCopy: Messages.myselfButtonCta(),
      destination: '/',
      needsInvite: false,
    };
  if (needsInvite)
    return {
      copy: Messages.needsInviteCta(shareUser?.firstName),
      buttonCopy: Messages.needsInviteButtonCta(),
      destination: '/',
      needsInvite: true,
    };
  // The user is connected to the share user
  return {
    copy: Messages.connectedCta(shareUser?.firstName),
    buttonCopy: Messages.connectedButtonCta(),
    destination: `/connection/${shareUser?.id}`,
    needsInvite: false,
  };
}
