import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import { LoadClientEnv } from '../env';

LoadClientEnv();

const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);

amplitude.init(window.AMPLITUDE_API_KEY, {
  minIdLength: 1,
  serverUrl: 'https://amplitude-api.f4s.com/2/httpapi',
  appVersion: window.RELEASE_VERSION,
  // Using default autocapture options
  autocapture: {
    elementInteractions: false,
  },
});
window.amplitude = amplitude;

declare global {
  interface Window {
    amplitude: typeof amplitude;
  }
}

// Set the transport to use beacon only when exiting page
window.addEventListener('pagehide', () => {
  amplitude.setTransport('beacon');
  // Sets https transport to use `sendBeacon` API
  amplitude.flush();
});

export { amplitude };

export function ampIdentify<T extends object>(properties: T) {
  const identifyEvent = new amplitude.Identify();
  for (const [key, value] of Object.entries(properties)) {
    identifyEvent.add(key, value);
  }
  amplitude.identify(identifyEvent);
}
