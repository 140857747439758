import { type RouteObject } from 'react-router-dom';

import { handle, SimpleBreadcrumb } from '@/components/breadcrumbs';

import {
  createTalentPoolAction,
  joinTalentPoolAction,
  updateTalentPoolAction,
} from './actions';
import {
  talentPoolLoader,
  talentPoolNewLoader,
  talentPoolNewProfileLoader,
  talentPoolsLoader,
} from './loaders';

export const talentPoolRoutes: RouteObject = {
  path: 'pools',
  children: [
    {
      index: true,
      loader: talentPoolsLoader,
      async lazy() {
        const { TalentPoolList } = await import('./pages');
        return { Component: TalentPoolList };
      },
    },
    {
      path: 'new',
      loader: talentPoolNewLoader,
      action: createTalentPoolAction,
      async lazy() {
        const { TalentPoolPage } = await import('./pages');
        return { Component: TalentPoolPage };
      },
    },
    {
      path: ':poolId',
      loader: talentPoolLoader,
      action: updateTalentPoolAction,
      async lazy() {
        const { TalentPoolPage } = await import('./pages');
        return { Component: TalentPoolPage };
      },
      children: [
        {
          path: 'join',
          action: joinTalentPoolAction,
        },
        {
          path: 'join/new',
          loader: talentPoolNewProfileLoader,
        },
      ],
    },
  ],
};

export const talentPoolWorkspaceRoutes: RouteObject = {
  path: 'pools',
  handle: handle({
    breadcrumb: SimpleBreadcrumb({ title: 'Pools' }),
  }),
  children: [
    {
      index: true,
      loader: talentPoolsLoader,
      async lazy() {
        const { TalentPoolWorkspaceList } = await import('./pages');
        return { Component: TalentPoolWorkspaceList };
      },
    },
    {
      path: 'new',
      handle: handle({
        breadcrumb: SimpleBreadcrumb({ title: 'New' }),
      }),
      loader: talentPoolNewLoader,
      action: createTalentPoolAction,
      async lazy() {
        const { TalentPoolWorkspacePage } = await import('./pages');
        return { Component: TalentPoolWorkspacePage };
      },
    },
    {
      path: ':poolId',
      loader: talentPoolLoader,
      action: updateTalentPoolAction,
      async lazy() {
        const { TalentPoolWorkspacePage } = await import('./pages');
        return { Component: TalentPoolWorkspacePage };
      },
      children: [
        {
          path: 'join',
          action: joinTalentPoolAction,
        },
        {
          path: 'join/new',
          loader: talentPoolNewProfileLoader,
        },
      ],
    },
  ],
};
