import type { RouteObject } from 'react-router-dom';

import { onboardingSpaceJoinAction } from './actions';
import {
  onboardingSpacesCompleteLoader,
  onboardingSpacesCreateLoader,
  onboardingSpacesIndexLoader,
  onboardingSpacesJoinLoader,
} from './loaders';
import { OnboardingSpaceCreatePage } from './pages/create';
import { OnboardingSpaceJoinPage } from './pages/join';

export const onboardingSpaceRoutes: RouteObject = {
  path: 'spaces',
  children: [
    { index: true, loader: onboardingSpacesIndexLoader },
    {
      path: 'join',
      loader: onboardingSpacesJoinLoader,
      action: onboardingSpaceJoinAction,
      Component: OnboardingSpaceJoinPage,
    },
    {
      path: 'create',
      loader: onboardingSpacesCreateLoader,
      Component: OnboardingSpaceCreatePage,
    },
    {
      path: 'complete',
      loader: onboardingSpacesCompleteLoader,
    },
  ],
};
