import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { cn, Separator } from '@f4s/ui';

import MarleeLogo from '@/assets/marlee-logo.svg?react';
import MarleeLogomark from '@/assets/marlee-logomark.svg?react';
import { UserNav } from '@/modules/user/components/user-menu';

export const OnboardingHeader = ({
  title,
  buttons,
  className,
}: {
  title?: ReactNode;
  buttons?: ReactNode;
  className?: string;
}) => {
  return (
    <header className="bg-background/80 ring-border/5 sticky top-0 z-50 flex h-16 w-full flex-none ring-1 backdrop-blur-md lg:z-50">
      <div
        className={cn(
          'm-auto flex h-full max-h-full min-h-full w-full max-w-screen-2xl flex-1 items-center justify-between px-4 lg:px-10',
          className,
        )}
      >
        <div className="flex items-center gap-2 md:gap-4">
          <NavLink to="/">
            <MarleeLogo className="text-elderberry-950 -mr-0.5 ml-0.5 hidden h-7 w-auto flex-shrink-0 md:block dark:text-white" />
            <MarleeLogomark className="text-elderberry-950 -mr-0.5 ml-0.5 block h-7 w-auto flex-shrink-0 md:hidden dark:text-white" />
          </NavLink>
          {title && (
            <>
              <Separator
                orientation="vertical"
                className="bg-border/20 ml-1 hidden h-6 md:block"
              />
              {typeof title === 'string' ? (
                <h1 className="line-clamp-1 font-serif text-lg font-semibold tracking-tight">{title}</h1>
              ) : (
                title
              )}
            </>
          )}
        </div>
        <div className="flex items-center gap-2">
          {buttons}
          <UserNav />
        </div>
      </div>
    </header>
  );
};
