import { useQuery } from '@tanstack/react-query';

import { queryClient } from '@f4s/api-client';

import { shuffle } from '@/lib/utils';

import type { JtbdCategory } from '../utils';

const JTBD_CATEGORIES = [
  {
    slug: 'collaborate',
    title: 'Collaborate',
    label: 'Enable seamless teamwork',
  },
  { slug: 'motivate', title: 'Motivate', label: 'Boost energy and performance' },
  { slug: 'connect', title: 'Connect', label: 'Foster connection and belonging' },
  { slug: 'develop', title: 'Develop', label: 'Cultivate high-performing teams' },
  {
    slug: 'hiring',
    path: 'hire',
    title: 'Hire',
    label: 'Make hiring and role decisions',
  },
  {
    slug: 'behired',
    path: 'match',
    title: 'Be Hired',
    label: 'Find your dream job',
  },
] as { slug: JtbdCategory; path?: string; title: string; label: string }[];

export const jtbdQuery = {
  queryKey: ['signup', 'jtbd'],
  queryFn: async () => shuffle(JTBD_CATEGORIES), // Cached randomization
};

export const fetchJtbdCategories = () => queryClient.fetchQuery(jtbdQuery);
export const useJtbdCategories = () => useQuery(jtbdQuery);
