import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import type { Model, Share } from '@f4s/types';
import { type DashboardLayouts } from '@f4s/ui';

import { fetchFeatureFlag } from '@/providers/feature-flags';

import {
  fetchCultureAggregate,
  fetchModelData,
  fetchModelMatchData,
} from '../modeling/queries';
import {
  fetchTeamMotivations,
  fetchUserMotivations,
  fetchUserOverTimeDates,
  fetchWorkspaceMotivations,
  type MotivationData,
} from '../motivation/queries';
import type { MinimalUser } from '../user/schema';

export type WidgetTypes = 'slider' | 'bubbles' | 'separator' | 'ranking';
export type SelectionTypes =
  | 'user'
  | 'team'
  | 'workspace'
  | 'culture'
  | 'insight'
  | 'xfactor'
  | 'role';
export type ComparisonTypes = 'individual' | 'aggregate' | 'model';

export type ModelDefinitionMotivation = {
  motivation: { id: number; code: string };
  weight: number;
  greenLow: number | null;
  greenHigh: number | null;
  orangeLow: number | null;
  orangeHigh: number | null;
};
export type ModelDefinition = {
  id: number;
  uniqueName: string;
  name: string;
  modelMotivations: ModelDefinitionMotivation[];
};

export type WidgetTemplateDefinition = {
  id: number;
  type: WidgetTypes;
  slug: string;
  name?: string;
  maxSelections: number; // Used to constrain and verify how many selections can exist on a widget
  selectionTypes: SelectionTypes[]; // Used to constrain and verify what selection types are supported
  comparisonTypes: ComparisonTypes[]; // Used to constrain and verify what comparison types are supported
};

export type DashboardInstance = {
  shares: Share[];
};

type DashboardDetails = {
  layouts: DashboardLayouts | null;
  widgets: DashboardWidget[];
  instances: DashboardInstance[];
};

type DashboardMinimal = {
  id: number;
  title: string;
  createdDate: string;
  updatedDate: string;
  description: string | null;
  template: { id: number; slug: string | null; title: string | null } | null;
  slug: string | null;
  owner: { id: number; user: { id: number; firstName: string; lastName: string } | null };
} & Partial<DashboardDetails>;

export type Dashboard = DashboardMinimal & DashboardDetails;

export type DashboardWidget = {
  id: number;
  title: string;
  widgetTemplate: WidgetTemplateDefinition;
  widgetTemplateId: number;
  motivationGroup: { id: number; slug: string; name: string } | null;
  motivationGroupId: number | null;
  askMarleeTemplate: { id: number } | null;
  askMarleeQuestion: { id: number } | null;
  cultureModelId?: number | null;
  data: WidgetData[];
  subtitle?: string;
  description?: string;
  instance?: DashboardWidgetInstanceWithData | null;
  instances?: DashboardWidgetInstance[];
};
export type DashboardWidgetMaybeId = Omit<DashboardWidget, 'id'> & { id?: number };

// Definition of datasets on a widget
export type WidgetData = {
  selectionType: SelectionTypes;
  comparisonType: ComparisonTypes;
  modelId: number | null;
  model?: { id: number; name: string } | null;
  id?: number;
  widgetId?: number;
  userId?: number | null;
  user?: MinimalUser | null;
  teamId?: number | null;
  team?: { id: number; name: string } | null;
  workspaceId?: number | null;
  workspace?: { id: number; name: string } | null;
  atDate?: string | null;
  isFixed?: boolean;
};
// Actual data to render the widget (user motivations etc.)
export type WidgetDataset = {
  definition: WidgetData;
  userData?: Awaited<ReturnType<typeof fetchUserMotivations>>[0];
  aggregateData?: MotivationData[];
  model?: Model;
  ranking?: Awaited<ReturnType<typeof fetchModelMatchData>>['results'];
};
export type WidgetWithData = DashboardWidgetMaybeId & {
  datasets: WidgetDataset[];
};
export type DashboardWidgetInstance = {
  id: number;
  createdDate: string;
};
export type DashboardWidgetInstanceWithData = DashboardWidgetInstance & {
  data: WidgetDataset[];
};

// Dashboard Templates - Selection for user to choose from
export const dashboardTemplatesQuery = {
  queryKey: ['dashboard-templates'],
  queryFn: async () => {
    const templates = (await apiClient.get(
      '/api/v4/dashboards/templates',
    )) as Dashboard[];
    const useOneToOne = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.1-to-1');
    const useOneToMany = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.1-to-M');
    const useGenerations = await fetchFeatureFlag(
      'MarleeWeb.Dashboards.Template.Generations',
    );
    const useGenerationBattle = await fetchFeatureFlag(
      'MarleeWeb.Dashboards.Template.GenerationBattle',
    );
    const useInsights = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.Insights');
    const useXfactor = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.XFactor');
    const useXfactors = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.XFactors');
    const useOverTime = await fetchFeatureFlag('MarleeWeb.Dashboards.Template.OverTime');
    const useTeamCulture = await fetchFeatureFlag(
      'MarleeWeb.Dashboards.Template.TeamComparison',
    );
    const useRoleComparison = await fetchFeatureFlag(
      'MarleeWeb.Dashboards.Template.RoleComparison',
    );

    return templates.filter((t) => {
      switch (t.slug) {
        case 'individual-results': {
          return true;
        }
        case 'one-to-one': {
          return useOneToOne;
        }
        case 'one-to-many': {
          return useOneToMany;
        }
        case 'generation-battle': {
          return useGenerationBattle;
        }
        case 'insights': {
          return useInsights;
        }
        case 'x-factor': {
          return useXfactor;
        }
        case 'over-time': {
          return useOverTime;
        }
        case 'team-culture': {
          return useTeamCulture;
        }
        case 'role-comparison': {
          return useRoleComparison;
        }

        default: {
          if (t.slug?.startsWith('generation')) {
            return useGenerations;
          } else if (t.slug?.startsWith('x-factor-')) {
            return useXfactors;
          }
          return false;
        }
      }
    });
  },
};
export const fetchDashboardTemplates = () =>
  queryClient.fetchQuery(dashboardTemplatesQuery);
export const useDashboardTemplates = () => useQuery(dashboardTemplatesQuery);

export const dashboardTemplateQuery = (templateId?: number) => ({
  queryKey: ['dashboard-templates', templateId],
  queryFn: async () => {
    if (!templateId) return null;
    return apiClient.get(
      `/api/v4/dashboards/templates/${templateId}`,
    ) as Promise<Dashboard | null>;
  },
});
export const fetchDashboardTemplate = (templateId?: number) =>
  queryClient.fetchQuery(dashboardTemplateQuery(templateId));
export const useDashboardTemplate = (templateId?: number) =>
  useQuery(dashboardTemplateQuery(templateId));

// Dashboard Widget Templates - Selection for user to choose from
export const widgetTemplatesQuery = {
  queryKey: ['dashboard-templates', 'widgets'],
  queryFn: async () =>
    apiClient.get('/api/v4/dashboards/widget-templates') as Promise<
      WidgetTemplateDefinition[]
    >,
};
export const fetchWidgetTemplates = () => queryClient.fetchQuery(widgetTemplatesQuery);
export const useWidgetTemplates = () => useQuery(widgetTemplatesQuery);

type WidgetDataParams = {
  widget: DashboardWidgetMaybeId;
  defaultUserId: number;
  defaultUserIds?: number[];
  defaultWorkspaceIds?: number[];
  useDefaultOverTime?: boolean;
  workspaceId?: number;
};
export const widgetDataQuery = ({
  widget,
  defaultUserId,
  defaultUserIds: externalDefaultUserIds,
  defaultWorkspaceIds,
  useDefaultOverTime,
  workspaceId,
}: WidgetDataParams) => ({
  queryKey: [
    'dashboard',
    'data',
    `workspace-${workspaceId}`,
    [widget.data, widget.instance, widget.cultureModelId, widget.motivationGroupId],
    defaultUserId,
    externalDefaultUserIds,
    defaultWorkspaceIds,
    useDefaultOverTime,
  ],
  // eslint-disable-next-line sonarjs/cognitive-complexity
  queryFn: async () => {
    // If there is an active instance, use that data.
    if (widget.instance) return widget.instance.data;

    // Grab the current user's over-time results
    const overTimeDates = useDefaultOverTime
      ? await fetchUserOverTimeDates(defaultUserId)
      : [];
    let overTimeIndex = 0;

    // Map in default data
    let defaultUserIdIndex = 0;
    let defaultWorkspaceIdIndex = 0;
    const defaultUserIds = [defaultUserId];
    if (externalDefaultUserIds) {
      defaultUserIds.push(...externalDefaultUserIds);
    }
    const data = widget.data.map((d) => {
      if (d.selectionType === 'user' && !d.userId) {
        if (useDefaultOverTime && !d.atDate) {
          return {
            ...d,
            userId: defaultUserId,
            atDate: overTimeDates[overTimeIndex++] ?? null,
          };
        }
        const userId =
          defaultUserIds[defaultUserIdIndex++ % defaultUserIds.length] ?? defaultUserId;
        return { ...d, userId };
      }
      if (d.selectionType === 'workspace' && !d.workspaceId) {
        return {
          ...d,
          workspaceId:
            defaultWorkspaceIds?.[defaultWorkspaceIdIndex++ % defaultWorkspaceIds.length],
        };
      }
      return d;
    });

    // ({ ...d, userId: d.userId ?? defaultUserId, atDate: d.atDate ??  }));
    const datasets: WidgetDataset[] = [];

    // Handle getting ranking information
    if (widget.widgetTemplate.type === 'ranking') {
      const definition = data.find((d) => d.modelId);
      const models = await fetchModelData({
        cultureModelId: widget.cultureModelId ?? undefined,
      });
      const model = definition
        ? models.find((m) => m.id === definition.modelId)
        : undefined;

      // Gather who should be ranked
      const workspaceIds = data.flatMap((d) =>
        d.selectionType === 'workspace' && d.workspaceId ? d.workspaceId : [],
      );
      const userIds = data.flatMap((d) =>
        d.selectionType === 'user' && d.userId ? d.userId : [],
      );
      if (definition?.modelId && (workspaceIds.length > 0 || userIds.length > 0)) {
        const { results: ranking } = await fetchModelMatchData({
          modelId: definition?.modelId,
          workspaceIds: workspaceIds.length > 0 ? workspaceIds : undefined,
          userIds: userIds.length > 0 ? userIds : undefined,
        });
        return [{ definition, model, ranking }] satisfies WidgetDataset[];
      } else if (definition) {
        return [{ definition, model, ranking: [] }] satisfies WidgetDataset[];
      }
      return [];
    }

    // This will build to be a fairly complex use of other query caches.
    // TODO: build more complex query caching to take a list of results and allow them to be returned individually
    for (const d of data) {
      const result: (typeof datasets)[0] = { definition: d };
      if (d.selectionType === 'user' && d.comparisonType === 'individual' && d.userId) {
        const atDate =
          d.atDate && !Number.isNaN(new Date(d.atDate).getTime())
            ? new Date(d.atDate)
            : undefined;
        const [userData] = await fetchUserMotivations({
          userIds: [d.userId],
          modelId: widget.cultureModelId ?? undefined,
          atDate,
        });
        result.userData = userData;
      } else if (
        (d.selectionType === 'culture' ||
          (d.selectionType === 'role' && d.comparisonType === 'aggregate')) &&
        d.modelId
      ) {
        const cultureAggregate = await fetchCultureAggregate({
          modelId: d.modelId,
          cultureModelId: widget.cultureModelId ?? undefined,
        });
        result.aggregateData = cultureAggregate;
      } else if (
        (d.selectionType === 'insight' ||
          d.selectionType === 'role' ||
          d.selectionType === 'xfactor') &&
        d.modelId
      ) {
        const models = await fetchModelData({
          cultureModelId: widget.cultureModelId ?? undefined,
        });
        result.model = models.find((m) => m.id === d.modelId);
      } else if (d.selectionType === 'workspace' && d.workspaceId) {
        const workspaceAggregate = await fetchWorkspaceMotivations({
          workspaceId: d.workspaceId,
          data: { modelId: widget.cultureModelId ?? undefined },
        });
        result.aggregateData = workspaceAggregate;
      } else if (d.selectionType === 'team' && d.teamId && workspaceId) {
        // Selections only exist on workspaces, but we need the current workspace here to hit the correct endpoint
        const teamAggregate = await fetchTeamMotivations({
          workspaceId,
          teamId: d.teamId,
        });
        result.aggregateData = teamAggregate;
      }
      datasets.push(result);
    }
    return datasets;
  },
  keepPreviousData: true,
});
export const fetchWidgetData = (params: WidgetDataParams) =>
  queryClient.fetchQuery(widgetDataQuery(params));
export const useWidgetData = (params: WidgetDataParams) =>
  useQuery(widgetDataQuery(params));

/* -------------------------------------------------------------------------- */
/*                                 Dashboards                                 */
/* -------------------------------------------------------------------------- */

// Dashboards - List the user/workspace's dashboards
export const dashboardsQuery = ({ workspaceId }: { workspaceId?: number } = {}) => ({
  queryKey: ['dashboard', workspaceId, 'list'],
  queryFn: async () => {
    const dashboards = (await apiClient.get(
      workspaceId ? `/api/v4/workspaces/${workspaceId}/dashboards` : '/api/v4/dashboards',
    )) as (Dashboard | DashboardMinimal)[];

    // Some mapping so our types are a little easier to handle
    return dashboards.map((d) => ({
      ...d,
      layouts: d.layouts ?? null,
      widgets: d.widgets ?? [],
      instances: d.instances ?? [],
    })) satisfies Dashboard[];
  },
});

export const fetchDashboards = ({ workspaceId }: { workspaceId?: number } = {}) =>
  queryClient.fetchQuery(dashboardsQuery({ workspaceId }));
export const useDashboards = ({ workspaceId }: { workspaceId?: number } = {}) =>
  useQuery(dashboardsQuery({ workspaceId }));

// Dashboard with details
export const dashboardQuery = ({
  dashboardId,
  workspaceId,
}: {
  dashboardId: number;
  workspaceId?: number;
}) => ({
  queryKey: ['dashboard', workspaceId, dashboardId],
  queryFn: async () =>
    apiClient.get(
      workspaceId
        ? `/api/v4/workspaces/${workspaceId}/dashboards/${dashboardId}`
        : `/api/v4/dashboards/${dashboardId}`,
    ) as Promise<Dashboard | null>,
});
export const fetchDashboard = ({
  dashboardId,
  workspaceId,
}: {
  dashboardId: number;
  workspaceId?: number;
}) => queryClient.fetchQuery(dashboardQuery({ dashboardId, workspaceId }));
export const useDashboard = ({
  dashboardId,
  workspaceId,
}: {
  dashboardId: number;
  workspaceId?: number;
}) => useQuery(dashboardQuery({ dashboardId, workspaceId }));
