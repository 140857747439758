import { NavLink } from '@/components/nav-link';

import { LoginForm } from '../../login/components/login';

export const CreatePage = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <h1 className="text-center font-serif text-2xl font-semibold tracking-tight">
          Create your account
        </h1>
        <p className="text-center">
          Already have an account?{' '}
          <NavLink to="/login" childFocus={false} className="text-primary">
            Login
          </NavLink>
        </p>
      </div>

      <LoginForm redirectTo="/onboarding" />
    </>
  );
};
