import { type z } from 'zod';

import { apiClient, queryClient } from '@f4s/api-client';
import {
  ShareDashboardResponseSchema,
  ShareReportResponseSchema,
} from '@f4s/types/api/module-share';
import { type UserProfile } from '@f4s/types/common/user';

export const publicProfileQuery = (userId: number) => ({
  queryKey: ['public', 'profile', userId],
  queryFn: async () => apiClient.get(`/api/v3/public/share-v2/profile/${userId}`),
});

export const fetchPublicProfile = (userId: number) =>
  queryClient.fetchQuery(publicProfileQuery(userId)) as Promise<UserProfile>;

export const publicResourceTypes = {
  report: ShareReportResponseSchema,
  dashboard: ShareDashboardResponseSchema,
};

export type PublicResource = keyof typeof publicResourceTypes;

export const publicResourceQuery = <T extends PublicResource>(type: T, code: string) => ({
  queryKey: ['public', type, code],
  queryFn: async () => {
    const response = await apiClient.get(`/api/v3/public/share-v2/${code}`);
    return publicResourceTypes[type].parse(response) as z.infer<
      (typeof publicResourceTypes)[T]
    >;
  },
});

export const fetchPublicResource = <T extends PublicResource>(type: T, code: string) =>
  queryClient.fetchQuery(publicResourceQuery<T>(type, code));
