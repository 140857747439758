import { redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { fetchGoals } from '../goal/queries';
import {
  fetchCoachingConfig,
  fetchCoachingPrograms,
  fetchCoachingQueue,
  fetchCoachingRecommendations,
  fetchWebChatUrl,
} from './queries';
import { MarleeBot } from './utils';

export const coachingProgramsListLoader = async () => {
  const programs = await fetchCoachingPrograms();
  const queue = await fetchCoachingQueue();
  const goals = await fetchGoals();

  const programsWithQueue = programs.map((p) => {
    const weeks = queue.find((q) => q.programId === p.id)?.sessionInfo?.weeks;

    return {
      ...p,
      isCompleted: weeks?.slice(-1)[0]?.session1State === 'complete',
      position:
        weeks
          ?.filter((w) => ['queued', 'started'].includes(w.session1State))
          .slice(-1)
          .map((w) => ({
            week: w.week,
            session: ['future', 'queued'].includes(w.session2State) ? 1 : 2,
          }))[0] ?? null,
    };
  });
  const hasProgramInProgress = programsWithQueue.some((p) => p.position);
  const recommendations = await fetchCoachingRecommendations({});
  return { programs: programsWithQueue, hasProgramInProgress, recommendations, goals };
};

export const coachingProgramLoader = async ({ params }: LoaderFunctionArgs) => {
  const { programId } = params as { programId: string };
  const { programs } = await coachingProgramsListLoader();
  const program = Object.values(programs).find((p) => p.slug === programId);
  const hasProgramInProgress = programs.some((p) => p.position);

  if (!program) return redirect('..');
  return { program, programs, hasProgramInProgress };
};

export const chatLoader = async ({ params }: LoaderFunctionArgs) => {
  const BP_STATUS_MAPPING = {
    cancelled: 'PROGRAM-PAUSED',
    startedPaused: 'PROGRAM-PAUSED',
    inProgressPaused: 'PROGRAM-CANCELLED',
    started: 'PROGRAM-STARTED',
  };

  const { program, status } = params as {
    program: string;
    status: keyof typeof BP_STATUS_MAPPING;
  };
  const webChatUrl = await fetchWebChatUrl();
  const config = await fetchCoachingConfig('coach-marlee');
  const bot = MarleeBot({ config, webChatUrl });

  return { program, status: BP_STATUS_MAPPING[status], bot };
};

export type CoachingProgramWithQueueData = Awaited<
  ReturnType<typeof coachingProgramsListLoader>
>['programs'][0];

export type CoachingProgramsListLoaderData = Exclude<
  Awaited<ReturnType<typeof coachingProgramsListLoader>>,
  Promise<Response> | Response
>;

export type CoachingProgramLoaderData = Exclude<
  Awaited<ReturnType<typeof coachingProgramLoader>>,
  Promise<Response> | Response
>;

export type ChatLoaderData = Exclude<
  Awaited<ReturnType<typeof chatLoader>>,
  Promise<Response> | Response
>;
