import { useCallback } from 'react';

import type { ModelRankingResult } from '@f4s/types';
import {
  cn,
  DataGrid,
  DataGridHeader,
  DataGridHeaderItem,
  DataGridItem,
  DataGridRow,
} from '@f4s/ui';

import { ProfileAvatar } from '@/components/avatar';
import { NavLinkButton } from '@/components/nav-link';

import type { WidgetDataset } from '../../queries';
import {
  type WidgetComponents,
  type WidgetFC,
  type WidgetPreviewFC,
} from '../widget-common';

const getRankingsOfInterest = ({
  rankingData,
  usersIdsOfInterest,
}: {
  rankingData: WidgetDataset;
  usersIdsOfInterest: number[];
}) => {
  let rankings: (ModelRankingResult & { isOfInterest?: boolean })[] = [];
  if (rankingData.ranking) {
    if (usersIdsOfInterest.length > 0) {
      rankings = rankingData.ranking.flatMap((r) => {
        if (usersIdsOfInterest.includes(r.userId)) {
          return { ...r, isOfInterest: true };
        }
        return [];
      });
    }

    let i = 0;
    while (rankings.length < 5 && i < rankingData.ranking.length) {
      const r = rankingData.ranking[i];
      if (r && !usersIdsOfInterest.includes(r.userId)) {
        rankings.push(r);
      }
      i++;
    }
  }
  rankings.sort((a, b) => a.rank - b.rank);
  return rankings;
};

const RankingsOfInterest = ({
  rankingData,
  usersIdsOfInterest,
}: {
  rankingData: WidgetDataset;
  usersIdsOfInterest: number[];
}) => {
  const rankings = getRankingsOfInterest({ rankingData, usersIdsOfInterest });
  return (
    <div className="-m-4 h-full">
      <div className="grid items-start gap-x-2 [grid-auto-rows:1fr] [grid-template-columns:auto_auto_minmax(auto,1fr)_auto] [grid-template-rows:auto]">
        <DataGridHeader>
          <DataGridHeaderItem className="!px-0">Member</DataGridHeaderItem>
          <DataGridHeaderItem>
            <span className="sr-only">Name</span>
          </DataGridHeaderItem>
          <DataGridHeaderItem className="!pr-0 text-right">
            Match Score
          </DataGridHeaderItem>
          {/* <DataGridItem className="!pl-0">Rank</DataGridItem> */}
        </DataGridHeader>
        {rankings.map((row) => (
          <DataGridRow
            key={row.userId}
            className={cn('border-border/5 h-auto border-b !py-3 hover:bg-transparent')}
          >
            <DataGridItem className="flex justify-center">
              <ProfileAvatar size="lg" user={{ ...row, id: row.userId }} />
            </DataGridItem>
            <DataGridItem
              className={cn(
                'text-left',
                // row.isOfInterest && 'underline underline-offset-4',
              )}
            >
              {row.firstName} {row.lastName}
            </DataGridItem>
            <DataGridItem className="!pr-0 text-right">
              {row.score}{' '}
              <span className="text-muted-foreground text-xs">#{row.rank}</span>
            </DataGridItem>
            {/* <DataGridItem className="!pl-0 text-left"></DataGridItem> */}
          </DataGridRow>
        ))}
      </div>
    </div>
  );
};

const AllRankings = ({ rankings }: { rankings: ModelRankingResult[] }) => {
  const renderRow = useCallback((row: (typeof rankings)[0]) => {
    return (
      <DataGridRow>
        <DataGridItem className="text-right">{row.rank}</DataGridItem>
        <DataGridItem className="flex justify-center">
          <ProfileAvatar size="lg" user={{ ...row, id: row.userId }} />
        </DataGridItem>
        <DataGridItem className="text-left">
          {row.firstName} {row.lastName}
        </DataGridItem>
        <DataGridItem className="text-right">{row.score}</DataGridItem>
      </DataGridRow>
    );
  }, []);

  return (
    <div className="relative -m-4 min-h-[500px] overflow-hidden rounded-xl">
      <DataGrid
        rowHeight={72}
        rows={rankings?.map((r) => ({ ...r, key: r.userId }))}
        className="min-h-0 gap-x-2 [grid-template-columns:auto_auto_minmax(auto,1fr)__auto]"
        rowHeader={
          <DataGridHeader>
            <DataGridHeaderItem>Rank</DataGridHeaderItem>
            <DataGridHeaderItem className="text-center">Avatar</DataGridHeaderItem>
            <DataGridHeaderItem>Name</DataGridHeaderItem>
            <DataGridHeaderItem>Score</DataGridHeaderItem>
          </DataGridHeader>
        }
        rowRender={renderRow}
      />
    </div>
  );
};

const Subtitle: WidgetFC = () => null;

const Visualization: WidgetFC = ({ widget, isLoading, detailView }) => {
  const [rankingData] = widget.datasets;
  if (isLoading || !rankingData) {
    return null;
  }

  if (!detailView) {
    const usersIdsOfInterest = widget.data.flatMap((d) =>
      d.selectionType === 'user' && d.userId ? d.userId : [],
    );
    return (
      <RankingsOfInterest
        rankingData={rankingData}
        usersIdsOfInterest={usersIdsOfInterest}
      />
    );
  }
  // Detail view, show the full results
  return <AllRankings rankings={rankingData.ranking ?? []} />;
};

const Preview: WidgetPreviewFC = ({ widget, isLoading, hideTitle }) => {
  const [rankingData] = widget.datasets;
  if (isLoading || !rankingData) {
    return null;
  }
  const usersIdsOfInterest = widget.data.flatMap((d) =>
    d.selectionType === 'user' && d.userId ? d.userId : [],
  );
  const rankings = getRankingsOfInterest({ rankingData, usersIdsOfInterest });
  return (
    <div className="flex flex-1 flex-col">
      {!hideTitle && (
        <div className="border-border/5 min-h-[16px] truncate border-b px-1.5 py-1 text-[0.4rem] font-medium leading-[0.5rem] tracking-tight">
          {widget.title}
        </div>
      )}
      <div className="grid h-full items-center gap-x-1 text-[0.4rem] [grid-template-columns:auto_auto_minmax(auto,1fr)_auto] [grid-template-rows:repeat(5,1fr)]">
        {rankings.map((row) => (
          <DataGridRow key={row.userId} className="h-full px-2 last:after:block">
            <DataGridItem className="flex justify-center px-1">
              <ProfileAvatar size="xxs" user={{ ...row, id: row.userId }} />
            </DataGridItem>
            <DataGridItem
              className={cn(
                'px-1 text-left',
                //row.isOfInterest && 'underline underline-offset-2',
              )}
            >
              {row.firstName} {row.lastName}
            </DataGridItem>
            <DataGridItem className="px-1 !pr-0 text-right">
              {row.score} <span className="text-muted-foreground">#{row.rank}</span>
            </DataGridItem>
          </DataGridRow>
        ))}
      </div>
    </div>
  );
};

const Description: WidgetFC = ({ widget, isLoading, detailView }) => {
  if (detailView) return null;
  const [rankingData] = widget.datasets;
  if (isLoading || !rankingData) {
    return null;
  }
  return (
    <div className="flex h-[5.75rem] max-h-[5.75rem] w-full items-center justify-center px-5 py-4">
      {rankingData.ranking && rankingData.ranking.length > 5 && (
        <NavLinkButton variant="outline" to={`widgets/${widget.id}`}>
          Plus {rankingData.ranking.length - 5} more users...
        </NavLinkButton>
      )}
    </div>
  );
};

export const RankingComponents: WidgetComponents = {
  Subtitle,
  Visualization,
  Preview,
  Description,
};
