import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

export type Team = {
  id: number;
  workspaceId: number;
  name: string;
  userId: number;
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
    emailAddress: string;
    gender: 'm' | 'f' | 't' | null;
  };
  avatarUrl: string | null;
  members: { id: number; userId: number }[];
};
export type TeamDetail = Omit<Team, 'members'> & {
  members: {
    id: number;
    userId: number;
    user: {
      id: number;
      firstName: string | null;
      lastName: string | null;
      avatarUrl: string | null;
      emailAddress: string;
      gender: 'm' | 'f' | 't' | null;
    };
  }[];
};

export const teamListQuery = ({ workspaceId }: { workspaceId?: number | string }) => ({
  queryKey: ['team', `workspace-${workspaceId}`, 'list'],
  queryFn: async () =>
    (workspaceId
      ? await apiClient.get(`/api/v4/workspaces/${workspaceId}/teams`)
      : []) as Team[],
});
export const fetchTeamList = (params: Parameters<typeof teamListQuery>[0]) =>
  queryClient.fetchQuery(teamListQuery(params));
export const useTeamList = (params: Parameters<typeof teamListQuery>[0]) =>
  useQuery(teamListQuery(params));

export const mapTeam = (team: TeamDetail) => {
  return {
    ...team,
    user: {
      ...team.user,
      fullName: formatName(team.user),
      initials: formatInitials(team.user),
    },
    members: team.members.map((m) => ({
      ...m,
      user: {
        ...m.user,
        fullName: formatName(m.user),
        initials: formatInitials(m.user),
      },
    })),
  };
};

export const teamQuery = ({
  workspaceId,
  teamId,
}: {
  workspaceId: number | string;
  teamId: number | string;
}) => ({
  queryKey: ['team', `workspace-${workspaceId}`, `team-${teamId}`],
  queryFn: async () => {
    const team = (await apiClient.get(
      `/api/v4/workspaces/${workspaceId}/teams/${teamId}`,
    )) as TeamDetail;
    return mapTeam(team);
  },
});
export const fetchTeam = (params: Parameters<typeof teamQuery>[0]) =>
  queryClient.fetchQuery(teamQuery(params));
export const useTeam = (params: Parameters<typeof teamQuery>[0]) =>
  useQuery(teamQuery(params));
