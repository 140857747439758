import { useQuery } from '@tanstack/react-query';

import { apiClient, queryClient } from '@f4s/api-client';
import { formatInitials, formatName } from '@f4s/types';

import type { MinimalUser } from '../user/schema';
import type { WorkspaceAccessInvite } from '../workspace/queries';

export type AccessTypes = 'admin' | 'consultant' | 'support';
type Access = {
  id: number;
  organizationId: number;
  userId: number;
  user: MinimalUser;
  type: AccessTypes;
};

export const accessListQuery = (workspaceId: number) => ({
  queryKey: ['workspace', workspaceId, 'access', 'list'],
  queryFn: async () => {
    const accessList = (await apiClient.get(
      `/api/v4/workspaces/${workspaceId}/access`,
    )) as Access[];
    // Wrap fullName and initials in
    return accessList.map((a) => ({
      ...a,
      user: { ...a.user, fullName: formatName(a.user), initials: formatInitials(a.user) },
    }));
  },
});
export const fetchAccessList = (workspaceId: number) =>
  queryClient.fetchQuery(accessListQuery(workspaceId));
export const useAccessList = (workspaceId: number) =>
  useQuery(accessListQuery(workspaceId));

export const accessPendingListQuery = (workspaceId: number) => ({
  queryKey: ['workspace', workspaceId, 'access', 'list'],
  queryFn: async () =>
    apiClient.get(`/api/v4/workspaces/${workspaceId}/access/pending-invite`) as Promise<
      WorkspaceAccessInvite[]
    >,
});
export const fetchAccessPendingList = (workspaceId: number) =>
  queryClient.fetchQuery(accessListQuery(workspaceId));
export const useAccessPendingList = (workspaceId: number) =>
  useQuery(accessListQuery(workspaceId));
