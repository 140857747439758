// 👇 Ensure the ambient intercom types are loaded - may be unnecessary if
// @types/intercom-web is included by the project tsconfig
// Not using `import` because the package is not a module
/// <reference types="@types/intercom-web" />

import { type MaybeUser } from '@f4s/api-client';
import { formatName } from '@f4s/types';

import { LoadClientEnv } from './env';
import { loadJsFilePromise } from './third-party-js-loader';

LoadClientEnv();

const i = (...args: unknown[]) => {
  i.c(args);
};
i.q = [] as unknown[];
i.c = (...args: unknown[]) => {
  i.q.push(args);
};

async function loadIntercomJs() {
  window.intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: window.INTERCOM_APP_ID,
    // action_color: '#DEADBEEF',
    // background_color: '#33FF33',
  };
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', intercomSettings);
  } else {
    // @ts-expect-error this is the pre-load implementation given by intercom
    w.Intercom = i;
    return loadJsFilePromise(
      'https://widget.intercom.io/widget/' + window.INTERCOM_APP_ID,
    );
  }
}

function bootIntercom(user: MaybeUser) {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: window.INTERCOM_APP_ID,
    email: user?.emailAddress,
    user_hash: user?.intercomEmailHash ?? undefined,
    name: user ? formatName(user) : undefined,
    // 'Current Plan': user?.plan,
    // action_color: '#DEADBEEF',
    // background_color: '#33FF33',
  });
  window.Intercom('onHide', () => window.Intercom('shutdown'));
  window.Intercom('showNewMessage');
}

export async function showIntercom(user: MaybeUser) {
  if (!window.Intercom) await loadIntercomJs();
  bootIntercom(user);
}
