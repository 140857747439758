export function storeReferralInfo() {
  const params = new URLSearchParams(window.location.search);

  // Save sign-up tracking query params in session storage
  const referralCode =
    params.get('refereeId') || params.get('referralCode') || params.get('from');
  const utmCampaign = params.get('utm_campaign');
  const utmMedium = params.get('utm_medium');
  const utmSource = params.get('utm_source');

  if (referralCode) sessionStorage.setItem('referralCode', referralCode);
  if (utmCampaign) sessionStorage.setItem('utmCampaign', utmCampaign);
  if (utmMedium) sessionStorage.setItem('utmMedium', utmMedium);
  if (utmSource) sessionStorage.setItem('utmSource', utmSource);
}
