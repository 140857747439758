import { useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea,
} from '@f4s/ui';

import { NavLink } from '@/components/nav-link';
import { useMatchedWorkspace } from '@/modules/workspace/hooks/use-workspace-match';

import { useDashboardTemplates } from '../../queries';

interface TemplateCoverProps {
  template: {
    id: number;
    title: string | null;
    slug: string | null;
  };
}

const TemplateCover = ({ template }: TemplateCoverProps) => {
  // THE FOLLOWING IS WIP - NOT USED YET, BUT WILL BE... eventually.
  // const renderImages = () => {
  //   switch (template.slug) {
  //     case 'my-results': case 'insights': {
  //       return (
  //         <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //       );
  //     }

  //     case 'one-to-one': {
  //       return (
  //         <>
  //           <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //           <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //         </>
  //       );
  //     }

  //     case 'one-to-many': {
  //       return (
  //         <>
  //           <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //           <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //           <div className="bg-radial group-over:opacity-80 absolute left-0 top-0 h-16 w-16 rounded-full opacity-30 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //         </>
  //       );
  //     }

  //     case 'role-comparison':
  //     case 'x-factor':
  //     case 'x-factor-startup':
  //     case 'x-factor-startup-critical':
  //     case 'x-factor-builder':
  //     case 'x-factor-builder-critical': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2 bg-green-100">
  //             <Icon.Check className="h-4 w-4 text-green-500" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'over-time': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4 opacity-40" />
  //           </Avatar>
  //           <Icon.ArrowRight className="ml-2 h-4 w-4" />
  //           <Avatar size="sm" className="ml-2">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'team-culture': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'generation-millennials-me':
  //     case 'generation-gen-x-me':
  //     case 'generation-baby-boomers-me':
  //     case 'generation-gen-z-me': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'generation-battle': {
  //       return (
  //         <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 rounded-full opacity-40 blur-md transition-all duration-300 group-hover:blur-sm"></div>
  //       );
  //     }

  //     default: {
  //       return (
  //         <div className="bg-radial group-over:opacity-80 absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 rounded-full opacity-40 blur-md transition-all duration-500 group-hover:blur-sm"></div>
  //       );
  //     }
  //   }
  // };

  // const renderAvatars = () => {
  //   switch (template.slug) {
  //     case 'my-results': {
  //       return (
  //         <Avatar size="sm">
  //           <Icon.User className="h-4 w-4" />
  //         </Avatar>
  //       );
  //     }

  //     case 'one-to-one': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'one-to-many': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2">
  //             <Icon.UsersThree className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'insights':
  //     case 'role-comparison':
  //     case 'x-factor':
  //     case 'x-factor-startup':
  //     case 'x-factor-startup-critical':
  //     case 'x-factor-builder':
  //     case 'x-factor-builder-critical': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2 bg-green-100">
  //             <Icon.Check className="h-4 w-4 text-green-500" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'over-time': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.User className="h-4 w-4 opacity-40" />
  //           </Avatar>
  //           <Icon.ArrowRight className="ml-2 h-4 w-4" />
  //           <Avatar size="sm" className="ml-2">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'team-culture': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'generation-millennials-me':
  //     case 'generation-gen-x-me':
  //     case 'generation-baby-boomers-me':
  //     case 'generation-gen-z-me': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2">
  //             <Icon.User className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     case 'generation-battle': {
  //       return (
  //         <>
  //           <Avatar size="sm">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //           <Avatar size="sm" className="-ml-2">
  //             <Icon.WaveSine className="h-4 w-4" />
  //           </Avatar>
  //         </>
  //       );
  //     }

  //     default: {
  //       return null;
  //     }
  //   }
  // };

  return (
    <>
      {/* <div className="absolute left-4 top-4 z-10 flex items-center justify-start">
        {renderAvatars()}
      </div> */}
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={`/app/board-templates/${template.slug}.png`}
          // alt={template.title}
          className="h-full w-full object-contain object-bottom"
        />
      </div>
    </>
  );
};

export const AllTemplatesModal = ({ trigger }: { trigger?: React.ReactNode | null }) => {
  const { data: defaultTemplates } = useDashboardTemplates();
  const { pathname = '/personal' } = useMatchedWorkspace() ?? {};

  const detailedPreviews = useMemo(() => {
    const templates = defaultTemplates ?? [];

    return templates.flatMap((template) => {
      const widget = template.widgets.find((w) => w.widgetTemplate.type !== 'separator');
      if (!widget) return [];

      return (
        <DialogClose key={template.id} asChild>
          <NavLink
            to={`${pathname}/boards/templates/${template.slug ?? template.id}`}
            className="before:bg-muted group relative z-0 flex h-[200px] flex-row gap-4 before:absolute before:-inset-1 before:-z-10 before:hidden before:rounded-[calc(var(--radius)_+_0.25rem_-_2px)] before:content-[''] hover:before:block"
            variant="outline"
          >
            <div className="ring-border/5 bg-background relative w-[150px] overflow-hidden rounded-lg ring-1 transition-shadow group-hover:shadow-lg">
              <TemplateCover template={template} />
            </div>
            <div className="flex flex-1 flex-col gap-1 pt-1">
              <h2 className="text-pretty text-sm font-semibold">{template.title}</h2>
              <p className="text-muted-foreground line-clamp-6 pr-2 text-sm leading-loose">
                {template.description}
              </p>
            </div>
          </NavLink>
        </DialogClose>
      );
    });
  }, [defaultTemplates, pathname]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        {trigger ?? (
          <Button variant="secondary" size="default">
            <span>View board templates</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="flex max-h-[85vh] flex-col gap-0 p-0 sm:max-w-[900px]">
        <DialogHeader className="px-8 py-6">
          <DialogTitle>Board Templates</DialogTitle>
          <DialogDescription>
            Choose a template to create your new board
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="flex-1">
          <div className="grid grid-cols-1 gap-6 px-8 pb-8 pt-1 md:grid-cols-2">
            {detailedPreviews}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
