import { useQuery } from '@tanstack/react-query';
import { UnleashClient, type IConfig } from 'unleash-proxy-client';

import { queryClient } from '@f4s/api-client';

import { sendEvent } from '@/lib/analytics';

const unleashConfig: IConfig = {
  url: new URL('/feature-flags/proxy', window.location.origin),
  clientKey: 'mt9Bebe82GAgxsqTqY2f',
  appName: 'marleeWeb',
  disableRefresh: true,
  environment: '',
  impressionDataAll: true,
};

let unleashResolve: (value: UnleashClient | PromiseLike<UnleashClient>) => void;
let unleashReject: (reason: string) => void;
const unleash: Promise<UnleashClient> = new Promise((resolve, reject) => {
  unleashResolve = resolve;
  unleashReject = reject;
});

const flagOverrides: Record<string, boolean> = {};

export const initUnleash = () => {
  const client = new UnleashClient(unleashConfig);
  client.on('ready', () => {
    unleashResolve(client);
  });
  client.start().catch((error) => {
    const errorMessage = `Error starting unleash client: ${error}`;
    console.warn(errorMessage);
    unleashReject(errorMessage);
  });

  const params = new URLSearchParams(window.location.search);

  params.forEach((value, key) => {
    // Note: Only supports feature flags starting with 'MarleeWeb.' prefix
    if (key.startsWith('MarleeWeb.')) {
      flagOverrides[key] = JSON.parse(value);
    }
  });
};

export const updateFlagContext = async ({
  userId,
  properties,
}: {
  userId?: string;
  properties?: { createdAt?: string; emailAddress?: string };
}) => {
  const unleashClient = await unleash;
  const currentContext = unleashClient.getContext();
  const ctx = {
    ...currentContext,
    userId,
    properties: {
      ...currentContext.properties,
      ...properties,
    },
  };

  await unleashClient.updateContext(ctx);
  await queryClient.invalidateQueries({ queryKey: ['featureFlags'] });
};

const featureFlagQuery = (name: string) => ({
  queryKey: ['featureFlags', name],
  queryFn: async () => {
    const unleashClient = await unleash;
    // Use unleash client's isEnabled so metrics work
    let isEnabled = unleashClient.isEnabled(name);

    console.log(`Feature flag requested: ${name}, isEnabled: ${isEnabled}`);

    if (typeof flagOverrides[name] === 'boolean') {
      isEnabled = flagOverrides[name];
      console.log(`Overriding feature flag: ${name}, isEnabled: ${isEnabled}`);
    }

    // Events to amplitude for experiment enabled
    if (name.includes('.AB.')) {
      sendEvent('experiment_started', { name, enabled: isEnabled });
    }

    return isEnabled;
  },
});

export const fetchFeatureFlag = async (name: string) =>
  queryClient.fetchQuery(featureFlagQuery(name));

export const useFeatureFlag = (name: string) => {
  const { data: isEnabled = false } = useQuery(featureFlagQuery(name));
  return isEnabled;
};
