import { useCallback, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import {
  UNSAFE_DataRouterContext,
  UNSAFE_DataRouterStateContext,
  UNSAFE_NavigationContext,
  useLocation,
  useMatches,
  useNavigation,
  type GetScrollRestorationKeyFunction,
} from 'react-router-dom';

/**
 * This has been adapted directly from react-router internals
 * The main difference is this hook returns a ref and can reset the scroll position of the component that is given that ref
 */

enum DataRouterHook {
  UseScrollRestoration = 'useScrollRestoration',
}

enum DataRouterStateHook {
  UseScrollRestoration = 'useScrollRestoration',
}

function useDataRouterContext(hookName: DataRouterHook) {
  const ctx = useContext(UNSAFE_DataRouterContext)!;
  if (!ctx)
    throw new Error(
      `${hookName} must be used within a data router.  See https://reactrouter.com/routers/picking-a-router.`,
    );
  return ctx;
}

function useDataRouterState(hookName: DataRouterStateHook) {
  const state = useContext(UNSAFE_DataRouterStateContext);
  if (!state)
    throw new Error(
      `${hookName} must be used within a data router.  See https://reactrouter.com/routers/picking-a-router.`,
    );
  return state;
}

function stripBasename(pathname: string, basename: string): string | null {
  if (basename === '/') return pathname;

  if (!pathname.toLowerCase().startsWith(basename.toLowerCase())) {
    return null;
  }

  // We want to leave trailing slash behavior in the user's control, so if they
  // specify a basename with a trailing slash, we should support it
  const startIndex = basename.endsWith('/') ? basename.length - 1 : basename.length;
  const nextChar = pathname.charAt(startIndex);
  if (nextChar && nextChar !== '/') {
    // pathname does not start with basename/
    return null;
  }

  return pathname.slice(startIndex) || '/';
}

function usePageHide(
  callback: (event: PageTransitionEvent) => unknown,
  options?: { capture?: boolean },
): void {
  const { capture } = options || {};
  useEffect(() => {
    const opts = capture != null ? { capture } : undefined;
    window.addEventListener('pagehide', callback, opts);
    return () => {
      window.removeEventListener('pagehide', callback, opts);
    };
  }, [callback, capture]);
}

const SCROLL_RESTORATION_STORAGE_KEY = 'react-router-scroll-positions';
let savedScrollPositions: Record<string, number> = {};

/**
 * When rendered inside a RouterProvider, will restore scroll positions on navigations
 */
export function useScrollRestoration({
  getKey,
  storageKey,
}: {
  getKey?: GetScrollRestorationKeyFunction;
  storageKey?: string;
} = {}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { router } = useDataRouterContext(DataRouterHook.UseScrollRestoration);
  const { restoreScrollPosition, preventScrollReset } = useDataRouterState(
    DataRouterStateHook.UseScrollRestoration,
  );
  const { basename } = useContext(UNSAFE_NavigationContext);
  const location = useLocation();
  const matches = useMatches();
  const navigation = useNavigation();

  // Trigger manual scroll restoration while we're active
  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  // Save positions on pagehide
  usePageHide(
    useCallback(() => {
      if (navigation.state === 'idle') {
        const key = (getKey ? getKey(location, matches) : null) || location.key;
        savedScrollPositions[key] = scrollRef.current?.scrollTop ?? 0;
      }
      try {
        sessionStorage.setItem(
          storageKey || SCROLL_RESTORATION_STORAGE_KEY,
          JSON.stringify(savedScrollPositions),
        );
      } catch (error) {
        console.warn(
          false,
          `Failed to save scroll positions in sessionStorage, <ScrollRestoration /> will not work properly (${error}).`,
        );
      }
      window.history.scrollRestoration = 'auto';
    }, [storageKey, getKey, navigation.state, location, matches]),
  );

  // Read in any saved scroll locations
  if (typeof document !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      try {
        const sessionPositions = sessionStorage.getItem(
          storageKey || SCROLL_RESTORATION_STORAGE_KEY,
        );
        if (sessionPositions) {
          savedScrollPositions = JSON.parse(sessionPositions);
        }
      } catch {
        // no-op, use default empty object
      }
    }, [storageKey]);

    // Enable scroll restoration in the router
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      const getKeyWithoutBasename: GetScrollRestorationKeyFunction | undefined =
        getKey && basename !== '/'
          ? (layoutLocation, layoutMatches) =>
              getKey(
                // Strip the basename to match useLocation()
                {
                  ...layoutLocation,
                  pathname:
                    stripBasename(layoutLocation.pathname, basename) ||
                    layoutLocation.pathname,
                },
                layoutMatches,
              )
          : getKey;
      const disableScrollRestoration = router?.enableScrollRestoration(
        savedScrollPositions,
        () => scrollRef.current?.scrollTop ?? 0,
        getKeyWithoutBasename,
      );
      return () => disableScrollRestoration && disableScrollRestoration();
    }, [router, basename, getKey]);

    // Restore scrolling when state.restoreScrollPosition changes
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      // Explicit false means don't do anything (used for submissions)
      if (restoreScrollPosition === false) {
        return;
      }

      // been here before, scroll to it
      if (typeof restoreScrollPosition === 'number') {
        scrollRef.current?.scrollTo(0, restoreScrollPosition);
        return;
      }

      // try to scroll to the hash
      if (location.hash) {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const el = document.getElementById(decodeURIComponent(location.hash.slice(1)));
        if (el) {
          el.scrollIntoView();
          return;
        }
      }
      // Don't reset if this navigation opted out
      if (preventScrollReset === true) {
        return;
      }

      // otherwise go to the top on new locations
      scrollRef.current?.scrollTo(0, 0);
    }, [location, restoreScrollPosition, preventScrollReset]);
  }

  return scrollRef;
}
