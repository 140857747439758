export const normalizeDistribution = ({
  relativeDistribution,
  sampleSize,
  normalizationMode,
}: {
  relativeDistribution: [percentile: number, count: number][];
  sampleSize: number;
  normalizationMode?: string;
}) => {
  let lastScore = 0;
  const normBinDist: [score: number, count: number, value: number][] = [];
  for (const [score, count] of relativeDistribution) {
    const normCount = Math.round(10000 * (count / sampleSize)) / 100;
    const binWidth = normalizationMode === 'equal-area' ? score - lastScore : 1;
    if (binWidth > 0) {
      normBinDist.push([score, normCount / binWidth, normCount]);
    } else {
      normBinDist.push([score, normCount, normCount]);
    }
    lastScore = score;
  }
  return normBinDist;
};
