import { useState, type ReactNode } from 'react';
import { NavLink, useOutlet, useOutletContext, useRouteError } from 'react-router-dom';

import { Button, Icon, ScrollArea } from '@f4s/ui';

import MarleeLogomark from '@/assets/marlee-logomark.svg?react';
import { Breadcrumbs } from '@/components/breadcrumbs';
import { useScrollRestoration } from '@/lib/hooks/use-scroll-restoration';
import { UnmasqueradeButton } from '@/modules/admin/components/masquerade-banner';
import { CreditsButton } from '@/modules/credits/components/credits';
import { NotificationButton } from '@/modules/notifications/components/notification-button';
import { UserNav } from '@/modules/user/components/user-menu';
import { SetupGuide } from '@/modules/user/components/user-setup';
import { InviteModal } from '@/modules/workspace/components/workspace-invite-modal';
import { WorkspaceSwitcher } from '@/modules/workspace/components/workspace-switcher';

import { DesktopNav, MobileNav, NavHeader } from '../components/nav';
import { type RootContextType } from '../types';

export const RootLayout = ({ children }: { children?: ReactNode }) => {
  const scrollRef = useScrollRestoration();
  const error = useRouteError();
  const [isNavCollapsed, setNavCollapsed] = useState<boolean>(false);
  const context = useOutletContext<RootContextType>();
  const outlet = useOutlet(context);
  const child = children ?? outlet;

  return (
    <div className="bg-background sand-serif:bg-none flex h-full min-h-full w-full flex-col overflow-hidden bg-[url('/landing/gradient-bg.svg')] bg-top bg-no-repeat">
      <ScrollArea
        ref={scrollRef}
        className="h-full w-full flex-1"
        scrollBarClassName="z-50 !pt-[66px] !pb-[68px] md:!pb-px"
      >
        <header className="bg-background/80 ring-border/5 sticky top-0 z-50 flex h-16 w-full flex-none ring-1 backdrop-blur-md lg:z-50">
          <div className="m-auto flex h-full max-h-full min-h-full w-full max-w-screen-2xl flex-1 items-center gap-4 px-4 md:gap-10 md:px-10">
            <NavHeader
              className="hidden lg:flex"
              isCollapsed={isNavCollapsed}
              onClick={() => {
                setNavCollapsed((previous) => !previous);
              }}
            />

            <NavLink to="/" className="flex shrink-0 gap-2 lg:hidden">
              <MarleeLogomark />
              {/* <Separator orientation="vertical" className="bg-border/20 ml-1 h-6" /> */}
            </NavLink>

            <div className="flex h-full min-w-16 flex-1 items-center md:w-fit md:flex-none lg:hidden">
              <WorkspaceSwitcher />
            </div>

            <div className="flex h-full flex-1 items-center justify-end gap-2">
              <div className="flex h-full flex-1 items-center gap-2 overflow-hidden">
                {isNavCollapsed && (
                  <Button
                    variant="link"
                    className="hidden shrink-0 opacity-60 hover:opacity-100 lg:flex"
                    size="icon"
                    onClick={() => {
                      setNavCollapsed((previous) => !previous);
                    }}
                  >
                    <Icon.Sidebar weight="duotone" size={18} />
                  </Button>
                )}

                <div className="hidden h-full w-full flex-1 items-center gap-2 lg:flex">
                  <Breadcrumbs />
                </div>

                <UnmasqueradeButton />
              </div>

              <div className="flex h-full shrink-0 items-center justify-end gap-2">
                <CreditsButton
                  type="askmarlee"
                  isCollapsed={true}
                  className="h-9 md:hidden"
                />
                <SetupGuide type="button" />
                <InviteModal />

                <NotificationButton />
                <UserNav />
              </div>
            </div>
          </div>
        </header>
        <SetupGuide type="banner" />
        <div
          className="m-auto flex h-full max-h-full min-h-full w-full max-w-screen-2xl flex-1 gap-4 px-4 md:gap-10 md:px-10"
          ref={context.modalCallbackRef}
        >
          <DesktopNav isCollapsed={isNavCollapsed} className="lg:flex" />
          <main className="relative mb-4 flex min-w-0 max-w-full flex-1 flex-col">
            {error ? <pre>{JSON.stringify(error, null, 2)}</pre> : child}
          </main>
        </div>
      </ScrollArea>
      <footer className="lg:hidden">
        <MobileNav />
      </footer>
    </div>
  );
};
